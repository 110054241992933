import "core-js/stable";
import "regenerator-runtime/runtime";
import logdna from "@logdna/browser";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
// import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {asyncWithLDProvider} from "launchdarkly-react-client-sdk";
import React, {StrictMode} from "react";
import ReactDOM from "react-dom/client";

import {App} from "./App";
import {AppConfig, AppConfigType} from "./AppConfig";
import {Authentication} from "./components/authentication/Authentication";
import {Maintenance} from "./components/maintenance/Maintenance";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {createAPIClientMock} from "../core/apiclient/__mocks__/APIClientMock";
import {userDefault} from "../core/apiclient/__mocks__/User.mock";
import {BaseAPIClient} from "../core/apiclient/BaseAPIClient";
import {AuthenticationDataStore} from "../core/stores/AuthenticationDataStore";

import "../typings/global.d";

if (process.env.IS_SERVER_MOCKED === "true") {
  createAPIClientMock({initialMockedData: {users: [userDefault]}});
} else {
  BaseAPIClient.configureClient({
    userAgent: navigator.userAgent,
    shouldRefreshTokens: () => true,
    onRefreshTokens: (session, sub?: string) => {
      const authenticationStore = AuthenticationDataStore.getInstance();
      const newToken = authenticationStore.setAccessToken(session, sub);
      return newToken;
    },
  });
}

const start = async (options: {config: AppConfigType}) => {
  const container = document.getElementById("root");

  const logdnaKey = process.env.LOGDNA_INGESTION_KEY ?? "";

  const logOptions = {
    app: "verve-logic",
  };

  logdna.init(logdnaKey, logOptions);
  logdna.addContext({
    env: process.env.DEPLOY_STAGE,
  });

  const LDProvider = await asyncWithLDProvider({
    clientSideID: options.config.Settings.LaunchDarkly.clientID,
    context: {key: "unidentified-user", name: "Unidentified User"},
    options: {},
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  ReactDOM.createRoot(container as HTMLElement).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <LDProvider>
          <Maintenance>
            <Authentication options={options}>{({user}) => <App config={options.config} user={user} />}</Authentication>
          </Maintenance>
        </LDProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </StrictMode>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", (event) => {
          const serviceWorker: ServiceWorker | null = event.target as ServiceWorker;
          if (serviceWorker?.state === "activated") {
            window.location.reload();
          }
        });

        waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
      }
    },
  });

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals(); (this file was removed in VP-928 as we weren't using this library)
};

start({config: AppConfig});
