import {DownloadOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Card, Drawer, Space, Typography} from "antd";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {Button, ButtonType} from "web/components/common/button/Button";

import styles from "./DashboardListsDrawers.module.scss";
import * as Models from "../../../../../core/models";
import {DeviceModule} from "../../../../../core/modules/DeviceModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {Loading} from "../../../loading/Loading";

export enum DeviceStatus {
  Assigned = "assigned",
  Available = "available",
  OutOfService = "out_of_service",
}

type DashboardDevicesListsDrawerProps = {
  entity: {type: Models.EntityType; id: string};
  status: DeviceStatus;
  onClose: () => void;
  open: boolean;
};

export const DashboardDevicesListsDrawer: FunctionComponent<DashboardDevicesListsDrawerProps> = ({entity, status, onClose, open}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [devices, setDevices] = useState<Models.DeviceShort[]>([]);

  const devicesQuery = useQuery({
    queryKey: ["DashboardDevicesListsDrawer-fetchDevicesData", entity],
    queryFn: () =>
      DeviceModule.devicesShort({
        accessToken: authenticationStore.state.accessToken!,
        ...(entity.type === Models.EntityType.Organization ? {organization_id: entity.id} : {}),
        ...(entity.type === Models.EntityType.Site ? {site_id: entity.id} : {}),
        ...(entity.type === Models.EntityType.Department ? {department_id: entity.id} : {}),
      }),
  });

  useEffect(() => {
    if (!devicesQuery.data) return;

    if (!devicesQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("DashboardDevicesListsDrawer-fetchDevicesError"), devicesQuery.data.correlationId);
      return;
    } else {
      const notArchivedSortedDevices = devicesQuery.data.devices
        .filter((device) => !device.archived_at)
        .sort((a, b) => a.device_tag.localeCompare(b.device_tag));

      switch (status) {
        case DeviceStatus.Assigned:
          setDevices(notArchivedSortedDevices.filter((device) => !!device.assigned_worker));
          break;
        case DeviceStatus.Available:
          setDevices(notArchivedSortedDevices.filter((device) => device.assignable && !device.assigned_worker));
          break;
        case DeviceStatus.OutOfService:
          setDevices(notArchivedSortedDevices.filter((device) => !device.assignable));
          break;
      }
    }
  }, [devicesQuery.data, status]);

  const handleDownloadData = useCallback(() => {
    const headers =
      status === DeviceStatus.Assigned
        ? [
            [
              WebHelper.formatMessage("DashboardDevicesListsDrawer-deviceTagTitle"),
              WebHelper.formatMessage("DashboardDevicesListsDrawer-workerNameTitle"),
            ],
          ]
        : [[WebHelper.formatMessage("DashboardDevicesListsDrawer-deviceTagTitle")]];

    const dataRows = devices.map((device) =>
      status === DeviceStatus.Assigned
        ? [device.device_tag, device.assigned_worker ? device.assigned_worker.displayName : "_"]
        : [device.device_tag]
    );

    const csvContent = headers
      .concat(dataRows)
      .map((row) => row.join(","))
      .join("\n");
    const selectedStatus =
      status === DeviceStatus.Assigned
        ? WebHelper.formatMessage("DashboardDevicesListsDrawer-titleAssigned")
        : status === DeviceStatus.Available
          ? WebHelper.formatMessage("DashboardDevicesListsDrawer-titleAvailable")
          : WebHelper.formatMessage("DashboardDevicesListsDrawer-titleOutOfService");

    WebHelper.downloadFile(
      csvContent,
      WebHelper.formatMessage("DashboardDevicesListsDrawer-csv", {status: selectedStatus}),
      "text/csv;charset=utf-8;"
    );
  }, [devices, status]);

  const drawerTitle = useMemo(() => {
    switch (status) {
      case DeviceStatus.Assigned:
        return (
          <div className={styles.titleWrapper}>
            {WebHelper.formatMessage("DashboardDevicesListsDrawer-titleAssigned")}
            <Button
              type={ButtonType.Default}
              icon={<DownloadOutlined />}
              shape="round"
              style={{borderColor: "#E2E8F0"}}
              onClick={handleDownloadData}
              loading={devicesQuery.isFetching}>
              {WebHelper.formatMessage("Common-exportToCsv")}
            </Button>
          </div>
        );
      case DeviceStatus.Available:
        return (
          <div className={styles.titleWrapper}>
            {WebHelper.formatMessage("DashboardDevicesListsDrawer-titleAvailable")}
            <Button
              type={ButtonType.Default}
              icon={<DownloadOutlined />}
              shape="round"
              style={{borderColor: "#E2E8F0"}}
              onClick={handleDownloadData}
              loading={devicesQuery.isFetching}>
              {WebHelper.formatMessage("Common-exportToCsv")}
            </Button>
          </div>
        );
      case DeviceStatus.OutOfService:
        return (
          <div className={styles.titleWrapper}>
            {WebHelper.formatMessage("DashboardDevicesListsDrawer-titleOutOfService")}
            <Button
              type={ButtonType.Default}
              icon={<DownloadOutlined />}
              shape="round"
              style={{borderColor: "#E2E8F0"}}
              onClick={handleDownloadData}
              loading={devicesQuery.isFetching}>
              {WebHelper.formatMessage("Common-exportToCsv")}
            </Button>
          </div>
        );
    }
  }, [status, handleDownloadData, devicesQuery.isFetching]);

  const devicesCountTitle = useMemo(() => {
    switch (status) {
      case DeviceStatus.Assigned:
        return WebHelper.formatMessage("DashboardDevicesListsDrawer-devicesCountTitleAssigned");
      case DeviceStatus.Available:
        return WebHelper.formatMessage("DashboardDevicesListsDrawer-devicesCountTitleAvailable");
      case DeviceStatus.OutOfService:
        return WebHelper.formatMessage("DashboardDevicesListsDrawer-devicesCountTitleOutOfService");
    }
  }, [status]);

  return (
    <Drawer open={open} width={WebHelper.drawerWidth} destroyOnClose title={drawerTitle} onClose={onClose}>
      <Space className={styles.cardsWrapper} direction="vertical" size={16}>
        {devicesQuery.isFetching && <Loading />}
        {!devicesQuery.isFetching && (
          <>
            <Card className={styles.card}>
              <Space size={30}>
                <Typography.Text strong>{devicesCountTitle}</Typography.Text>
                <Typography.Text>
                  {WebHelper.formatMessage("DashboardDevicesListsDrawer-devicesCount", {devices: devices.length})}
                </Typography.Text>
              </Space>
            </Card>

            {devices.length > 0 && (
              <Card className={styles.card}>
                <Space className={styles.list} direction="vertical" size={16}>
                  <div className={styles.listRow}>
                    <Typography.Text strong>{WebHelper.formatMessage("DashboardDevicesListsDrawer-deviceTagTitle")}</Typography.Text>
                    {status === DeviceStatus.Assigned && (
                      <Typography.Text strong>{WebHelper.formatMessage("DashboardDevicesListsDrawer-workerNameTitle")}</Typography.Text>
                    )}
                  </div>
                  {devices.map((device) => (
                    <div key={device.id} className={styles.listRow}>
                      <Typography.Text>{device.device_tag}</Typography.Text>
                      {status === DeviceStatus.Assigned && device.assigned_worker && (
                        <Typography.Text>{device.assigned_worker.displayName}</Typography.Text>
                      )}
                    </div>
                  ))}
                </Space>
              </Card>
            )}
          </>
        )}
      </Space>
    </Drawer>
  );
};
