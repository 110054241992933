import {ArrowLeftOutlined, FilterOutlined} from "@ant-design/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Button, Typography, Modal, Select} from "antd";
import {ColumnsType} from "antd/lib/table";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {EditableAccessLevel} from "./editable-access-level/EditableAccessLevel";
import styles from "./UserPermissions.module.scss";
import * as Models from "../../../core/models";
import {UserModule} from "../../../core/modules/UserModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {PageTitle} from "../../components/common/page-title/PageTitle";
import {Subheader} from "../../components/common/subheader/Subheader";
import {Table} from "../../components/common/table/Table";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {isEqual, isNil, uniqWith} from "../../utils/FunctionUtils";
import {getColumnSearchProps} from "../../utils/TableUtils";
import {WebHelper} from "../../utils/WebHelper";

const defaultSettings: Models.UserPermissionsTableSettings = {
  pageSize: 10,
  sortColumn: undefined,
  sortOrder: "ascend",
  givenNameFilteredValue: null,
  familyNameFilteredValue: null,
  organizationNameFilteredValue: null,
  accessLevelFilteredValue: null,
  activeFilteredValue: null,
};

export const UserPermissions: FunctionComponent = observer(() => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const navigate = useNavigate();

  const [userPermissionsTableSettings, setUserPermissionsTableSettings] = useState(defaultSettings);
  const [showChangeAccessLevelModal, setShowChangeAccessLevelModal] = useState(false);
  const [showChangeActiveModal, setShowChangeActiveModal] = useState(false);
  const [users, setUsers] = useState<Models.User[]>([]);
  const [currentUser, setCurrentUser] = useState<Models.User | null>(null);
  const [newAccessLevel, setNewAccessLevel] = useState<Models.UserAccessLevel | null>(null);

  const {
    pageSize,
    sortColumn,
    sortOrder,
    givenNameFilteredValue,
    familyNameFilteredValue,
    organizationNameFilteredValue,
    accessLevelFilteredValue,
    activeFilteredValue,
  } = userPermissionsTableSettings;

  const filterOptionsForOrganization = () => {
    const organizations = uniqWith(
      users
        .filter((user) => !isNil(user.organization_id))
        .map((user) => {
          return {text: user.organization_name, value: user.organization_name};
        }),
      isEqual
    );

    const defaultOptions = [
      {
        value: "",
        text: WebHelper.formatMessage("UserPermissions-global"),
      },
    ];

    return defaultOptions.concat(organizations.sort((a, b) => a.text.localeCompare(b.text)));
  };

  const columns: ColumnsType<Models.User> = [
    {
      title: WebHelper.formatMessage("UserPermissions-statusColumnTitle"),
      align: "left",
      dataIndex: "active",
      render: (_, record) => (
        <div className={styles.statusCell}>
          {record.active ? (
            <span className={[styles.circleSolid, styles.activeStatus].join(" ")} />
          ) : (
            <span className={[styles.circleSolid, styles.inactiveStatus].join(" ")} />
          )}
          <Select
            variant="borderless"
            onChange={() => handleChangeUserActive(record)}
            disabled={authenticationStore.state.user?.id === record.id}
            value={record.active}
            className={styles.statusSelect}
            options={[
              {
                value: true,
                label: WebHelper.formatMessage("UserPermissions-active"),
              },
              {
                value: false,
                label: WebHelper.formatMessage("UserPermissions-inactive"),
              },
            ]}
          />
        </div>
      ),
      sorter: (a, b) => Number(a.active) - Number(b.active),
      sortOrder: sortColumn === "active" ? sortOrder : undefined,
      filters: [
        {
          value: true,
          text: WebHelper.formatMessage("UserPermissions-active"),
        },
        {
          value: false,
          text: WebHelper.formatMessage("UserPermissions-inactive"),
        },
      ],
      filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
      filteredValue: activeFilteredValue,
      onFilter: (value, record) => record.active === value,
      filterSearch: true,
    },
    {
      title: WebHelper.formatMessage("UserPermissions-firstName"),
      dataIndex: "given_name",
      align: "left",
      sorter: (a, b) => a.given_name.localeCompare(b.given_name),
      sortOrder: sortColumn === "given_name" ? sortOrder : undefined,
      ...getColumnSearchProps<Models.User>("given_name", givenNameFilteredValue ? givenNameFilteredValue.toString() : ""),
      filteredValue: givenNameFilteredValue,
    },
    {
      title: WebHelper.formatMessage("UserPermissions-lastName"),
      dataIndex: "family_name",
      align: "left",
      sorter: (a, b) => a.family_name.localeCompare(b.family_name),
      sortOrder: sortColumn === "family_name" ? sortOrder : undefined,
      ...getColumnSearchProps<Models.User>("family_name", familyNameFilteredValue ? familyNameFilteredValue.toString() : ""),
      filteredValue: familyNameFilteredValue,
    },
    {
      title: WebHelper.formatMessage("UserPermissions-email"),
      dataIndex: "email",
      align: "left",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortColumn === "email" ? sortOrder : undefined,
    },
    {
      title: WebHelper.formatMessage("UserPermissions-organization"),
      key: "organization_name",
      align: "left",
      render: (_, record) => (record.organization_id ? record.organization_name : WebHelper.formatMessage("UserPermissions-global")),
      filters: filterOptionsForOrganization(),
      filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
      filteredValue: organizationNameFilteredValue,
      onFilter: (value, record) => record.organization_name === value,
      filterSearch: true,
      sorter: (a, b) =>
        (a.organization_name || WebHelper.formatMessage("UserPermissions-global")).localeCompare(
          b.organization_name || WebHelper.formatMessage("UserPermissions-global")
        ),
      sortOrder: sortColumn === "organization_name" ? sortOrder : undefined,
    },
    {
      title: WebHelper.formatMessage("UserPermissions-accessLevel"),
      align: "left",
      dataIndex: "access_level",
      render: (_, record) =>
        authenticationStore.state.user?.id === record.id || !record.active ? (
          <>
            <Typography.Text className={styles.accessLevelDisabled}>
              {WebHelper.getAccessLevelText(record.permissions ? record.permissions[0].level : Models.UserAccessLevel.None)}
            </Typography.Text>
          </>
        ) : (
          <EditableAccessLevel
            actualAccessLevel={record.permissions ? record.permissions[0].level : Models.UserAccessLevel.None}
            user={record}
            onSave={() => setShowChangeAccessLevelModal(true)}
            updateUser={setCurrentUser}
            updateAccessLevel={setNewAccessLevel}
            onUpdateMultiple={() => {
              fetchUsersQuery.refetch();
            }}
          />
        ),
      filters: [
        {
          value: Models.UserAccessLevel.Admin,
          text: WebHelper.formatMessage("UserPermissions-admin"),
        },
        {
          value: Models.UserAccessLevel.Manager,
          text: WebHelper.formatMessage("UserPermissions-manager"),
        },
        {
          value: Models.UserAccessLevel.ReadOnly,
          text: WebHelper.formatMessage("UserPermissions-readOnly"),
        },
        {
          value: Models.UserAccessLevel.None,
          text: WebHelper.formatMessage("UserPermissions-none"),
        },
      ],
      filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
      filteredValue: accessLevelFilteredValue,
      onFilter: (value, record) => (record.permissions ? record.permissions[0].level === value : value === Models.UserAccessLevel.None),
      filterSearch: true,
      sorter: (a, b) =>
        (a.permissions ? a.permissions[0].level : WebHelper.formatMessage("UserPermissions-none")).localeCompare(
          b.permissions ? b.permissions[0].level : WebHelper.formatMessage("UserPermissions-none")
        ),
      sortOrder: sortColumn === "access_level" ? sortOrder : undefined,
    },
  ];

  const fetchUsersQuery = useQuery({
    queryKey: ["UserPermissions-fetchUsers"],
    queryFn: () => UserModule.users({accessToken: authenticationStore.state.accessToken!}),
  });

  useEffect(() => {
    const usersData = fetchUsersQuery.data;

    if (!usersData) return;
    if (!usersData.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("UserPermissions-fetchDataError"), usersData.correlationId);
      return;
    }
    setUsers(usersData.users);
  }, [fetchUsersQuery.data]);

  useEffect(() => {
    appStore.hideAllMessages();
    appStore.sendAnalyticTrack(SegmentKey.UserPermissionsOpened);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeUserActive = (user: Models.User) => {
    setCurrentUser(user);
    setShowChangeActiveModal(true);
  };

  const changeUserActiveMutation = useMutation({
    mutationFn: (currentUser: Models.User) =>
      UserModule.changeUserActive({
        accessToken: authenticationStore.state.accessToken!,
        user_id: currentUser.id,
        active: !currentUser.active,
      }),

    onSuccess: (response) => {
      if (response.success) {
        fetchUsersQuery.refetch();
        setShowChangeActiveModal(false);
        setCurrentUser(null);
      } else {
        WebHelper.showErrorMessage(WebHelper.formatMessage("UserPermissions-changeActiveError"), response.correlationId);
        return;
      }
    },
  });

  const changeUserActive = async () => {
    if (!currentUser) return;
    changeUserActiveMutation.mutate(currentUser);
  };

  const changeUserAccessLevelMutation = useMutation({
    mutationFn: ({currentUser, newAccessLevel}: {currentUser: Models.User; newAccessLevel: Models.UserAccessLevel}) =>
      UserModule.changeUserPermission({
        accessToken: authenticationStore.state.accessToken!,
        level: newAccessLevel,
        resource_id: currentUser.organization_id,
        scope: currentUser.organization_id ? Models.UserScope.Organization : Models.UserScope.Global,
        user_id: currentUser.id,
      }),
    onSuccess: (response) => {
      if (response.success) {
        fetchUsersQuery.refetch();
        setShowChangeAccessLevelModal(false);
      } else {
        WebHelper.showErrorMessage(WebHelper.formatMessage("UserPermissions-changeAccessLevelError"), response.correlationId);
        return;
      }
    },
  });

  const handleChangeUserAccessLevel = async () => {
    if (!currentUser || !newAccessLevel) return;
    changeUserAccessLevelMutation.mutate({currentUser, newAccessLevel});
  };

  const handleBackButton = () => {
    if (document.referrer) navigate(-1);
    else navigate("/");
  };

  const handleSettingsChange = (updatedSettings: Partial<Models.UserPermissionsTableSettings>) =>
    setUserPermissionsTableSettings({...userPermissionsTableSettings, ...updatedSettings});

  return (
    <div>
      <Subheader breadcrumbItems={[{id: "userPermissions", name: WebHelper.formatMessage("UserPermissions-title")}]} singleLevel />
      <div className={styles.content}>
        <PageTitle
          title={WebHelper.formatMessage("UserPermissions-title")}
          icon={[<Button className={styles.backButton} onClick={handleBackButton} icon={<ArrowLeftOutlined />} />]}
          marginBottom={16}
        />
        <Table
          rowsSingleColor
          columns={columns}
          loading={fetchUsersQuery.isFetching}
          dataSource={users}
          onChange={(pagination, filters, sorter, extra) => {
            appStore.sendAnalyticTrack(SegmentKey.UserPermissionsTableSettingsChanged, {pagination, filters, sorter, action: extra.action});
            if (extra.action === "filter") {
              handleSettingsChange({
                givenNameFilteredValue: filters.given_name,
                familyNameFilteredValue: filters.family_name,
                organizationNameFilteredValue: filters.organization_name,
                accessLevelFilteredValue: filters.access_level,
                activeFilteredValue: filters.active,
              });
            }
            if (extra.action === "sort" && !Array.isArray(sorter)) {
              handleSettingsChange({
                sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
                sortOrder: sorter.order,
              });
            }
          }}
          pagination={{
            showSizeChanger: true,
            pageSize,
            onShowSizeChange: (_, size) =>
              handleSettingsChange({
                pageSize: size,
              }),
          }}
          data-cy="userPermissionsTable"
        />
      </div>
      <Modal
        open={showChangeAccessLevelModal}
        destroyOnClose={true}
        title={WebHelper.formatMessage("UserPermissions-changeAccessLevelTitle")}
        okText={WebHelper.formatMessage("Common-confirm")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        okButtonProps={{loading: changeUserAccessLevelMutation.isPending}}
        cancelButtonProps={{disabled: changeUserAccessLevelMutation.isPending}}
        onOk={handleChangeUserAccessLevel}
        onCancel={() => setShowChangeAccessLevelModal(false)}>
        {currentUser && newAccessLevel && (
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(
                WebHelper.formatMessage("UserPermissions-changeAccessLevelText", {
                  givenName: currentUser.given_name,
                  familyName: currentUser.family_name,
                  organizationName: currentUser.organization_id
                    ? currentUser.organization_name
                    : WebHelper.formatMessage("UserPermissions-global"),
                  previousLevel: WebHelper.getAccessLevelText(
                    currentUser.permissions ? currentUser.permissions[0].level : Models.UserAccessLevel.None
                  ),
                  newLevel: WebHelper.getAccessLevelText(newAccessLevel),
                })
              ),
            }}
          />
        )}
      </Modal>
      <Modal
        open={showChangeActiveModal}
        destroyOnClose={true}
        title={WebHelper.formatMessage(currentUser?.active ? "UserPermissions-deactivateUser" : "UserPermissions-reactivateUser")}
        okText={WebHelper.formatMessage(currentUser?.active ? "UserPermissions-deactivateUser" : "UserPermissions-reactivateUser")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        okButtonProps={{loading: changeUserActiveMutation.isPending, shape: "round"}}
        cancelButtonProps={{disabled: changeUserActiveMutation.isPending, shape: "round"}}
        onOk={changeUserActive}
        onCancel={() => {
          setShowChangeActiveModal(false);
          setCurrentUser(null);
        }}>
        {currentUser && (
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(
                WebHelper.formatMessage(currentUser.active ? "UserPermissions-deactivateUserText" : "UserPermissions-reactivateUserText", {
                  givenName: currentUser.given_name,
                  familyName: currentUser.family_name,
                  organizationName: currentUser.organization_id
                    ? currentUser.organization_name
                    : WebHelper.formatMessage("UserPermissions-global"),
                })
              ),
            }}
          />
        )}
      </Modal>
    </div>
  );
});
