import {Card, Drawer, Space, Switch, Table, Typography} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import styles from "./Leaderboard.module.scss";
import * as Models from "../../../../../../core/models";
import useUserPreferences from "../../../../../hooks/useUserPreferences";
import {AppStore, SegmentKey} from "../../../../../stores/AppStore";
import {isNil} from "../../../../../utils/FunctionUtils";
import {WebHelper, lbsToKgs} from "../../../../../utils/WebHelper";
import {LinkButton} from "../../../../common/link-button/LinkButton";
import {WorkerDetail} from "../../../../worker-detail/WorkerDetail";

export type LeaderboardProps = {
  workersByScore: WorkerData[];
  workersByWeight: WorkerData[];
  scoreAverage: number;
  weightAverage: number;
  onWorkerEdit: () => void;
  department: Models.Department;
  printMode?: "bottom" | "top";
  downloadable?: boolean;
};

export type WorkerData = {
  worker: Models.WorkerShort;
  time_series_data: Models.AnalyticsTimeSeries[];
  weightOffloaded: number;
  position: number;
  score: number | null;
};

export const Leaderboard: FunctionComponent<LeaderboardProps> = observer(
  ({department, workersByScore, workersByWeight, scoreAverage, weightAverage, onWorkerEdit, printMode, downloadable}) => {
    const appStore = AppStore.getInstance();
    const [userPreferences] = useUserPreferences();

    const [topWorkers, setTopWorkers] = useState(printMode !== "bottom");
    const [workersByScoreToShow, setWorkersByScoreToShow] = useState<WorkerData[]>([]);
    const [visibleWorker, setVisibleWorker] = useState<Models.WorkerShort>();

    // x <= 10 show all, if 10 < x < 29 show 5, if x > 30 show 10
    const workerQuantity = workersByScore.length <= 10 ? workersByScore.length : workersByScore.length >= 30 ? 10 : 5;

    useEffect(() => {
      if (topWorkers || workersByScore.length <= 10) {
        setWorkersByScoreToShow(workersByScore.slice(0, workerQuantity));
      } else {
        const workers = workersByScore.slice(workersByScore.length - workerQuantity, workersByScore.length);

        setWorkersByScoreToShow(workers.sort((a, b) => b.position - a.position));
      }
    }, [topWorkers, workersByScore, workerQuantity]);

    const handleDrawerClose = () => {
      setVisibleWorker(undefined);
    };

    const handleDelete = () => {
      handleDrawerClose();
      onWorkerEdit();
    };

    //adapt the row height to the quantity of workers to mantain the table size constant
    const rowHeight = 24 + ((10 - Math.max(workerQuantity, 5)) / 5) * 34;

    const nameColumn: ColumnType<WorkerData> = {
      key: "name",
      width: "80%",
      render: (_, record, index) => (
        <div className={styles.nameContainer} style={{minHeight: rowHeight}}>
          <div className={styles.positionContainer}>
            <Typography.Title
              level={index > 0 ? 5 : 4}
              style={index !== 0 && workerQuantity < 10 ? {height: rowHeight, width: rowHeight, borderRadius: "100px"} : {}}
              className={`${styles.position}`}>{`${record.position}${WebHelper.getOrdinal(record.position)}`}</Typography.Title>
          </div>
          <LinkButton strong={index === 0} onClick={() => setVisibleWorker(record.worker)}>
            {record.worker.displayName}
          </LinkButton>
        </div>
      ),
    };

    const scoreColumns: ColumnsType<WorkerData> = [
      nameColumn,
      {
        key: "score",
        width: "20%",
        render: (record) => (
          <div className={`${styles.workerItem} ${styles.value}`} key={`weight-${record.worker.id}`}>
            <Typography.Text strong>{record.score.toLocaleString()}</Typography.Text>
          </div>
        ),
      },
    ];

    const weightColumns: ColumnsType<WorkerData> = [
      nameColumn,
      {
        key: "weightOffloaded",
        width: "20%",
        render: (record) => (
          <div className={`${styles.workerItem} ${styles.value}`} key={`weight-${record.worker.id}`}>
            <Typography.Text strong>
              {userPreferences.data.metricMeasurementUnits
                ? `${lbsToKgs(record.weightOffloaded).toLocaleString()} ${WebHelper.formatMessage("DashboardWorkersMetric-kilogramsUnit")}`
                : `${record.weightOffloaded.toLocaleString()} ${WebHelper.formatMessage("DashboardWorkersMetric-poundsUnit")}`}
            </Typography.Text>
          </div>
        ),
      },
    ];

    return (
      <div className={styles.wrapper}>
        <Card className={styles.card}>
          <Card className={styles.cardContent}>
            <div>
              <div className={styles.cardHeader}>
                <Typography.Title level={5} className={styles.cardTitle}>
                  {WebHelper.formatMessage("DashboardWorkersMetric-safeLiftScore")}
                </Typography.Title>
                <div className={styles.averageContainer}>
                  <Space size={"large"} className={styles.averageText}>
                    <Typography.Text>{WebHelper.formatMessage("DashboardWorkersMetric-departmentAverage")}</Typography.Text>
                    <Typography.Text strong>{scoreAverage.toLocaleString()}</Typography.Text>
                  </Space>
                </div>
                {workersByScore.length > 10 && (
                  <Space className={styles.topFiveContainer}>
                    <Typography.Text>
                      {WebHelper.formatMessage(topWorkers ? "DashboardWorkersMetric-topWorkers" : "DashboardWorkersMetric-bottomWorkers", {
                        qty: workerQuantity,
                      })}
                    </Typography.Text>
                    {!printMode && (
                      <Switch
                        checked={topWorkers}
                        onChange={(checked) => {
                          setTopWorkers(checked);
                          appStore.sendAnalyticTrack(SegmentKey.DashboardWorkerMetricsToggle, {
                            departmentID: department.id,
                            siteID: department.site.id,
                            orgID: department.organization.id,
                            listName: checked ? "Top" : "Bottom",
                          });
                        }}
                      />
                    )}
                  </Space>
                )}
              </div>
              <Table
                dataSource={workersByScoreToShow}
                showHeader={false}
                pagination={false}
                className={styles.table}
                rowKey={(record) => record.worker.id}
                rowClassName={(_, index) =>
                  `${index === 0 ? (!downloadable ? styles.firstRow : styles.downloadFirstRow) : ""} ${styles.cellsBorder} `
                }
                columns={scoreColumns}
              />
            </div>
          </Card>
        </Card>
        {printMode !== "bottom" && (
          <Card className={styles.card}>
            <Card className={styles.cardContent}>
              <div className={styles.cardHeader}>
                <Typography.Title level={5} className={styles.cardTitle}>
                  {WebHelper.formatMessage("DashboardWorkersMetric-weightOffloaded")}
                </Typography.Title>
                <div className={styles.averageContainer}>
                  <Space size={"large"} className={styles.averageText}>
                    <Typography.Text>{WebHelper.formatMessage("DashboardWorkersMetric-departmentAverage")}</Typography.Text>
                    <Typography.Text strong>
                      {userPreferences.data.metricMeasurementUnits
                        ? `${lbsToKgs(weightAverage).toLocaleString()} ${WebHelper.formatMessage("DashboardWorkersMetric-kilogramsUnit")}`
                        : `${weightAverage.toLocaleString()} ${WebHelper.formatMessage("DashboardWorkersMetric-poundsUnit")}`}
                    </Typography.Text>
                  </Space>
                </div>
                {workersByScore.length > 10 && (
                  <div className={styles.topFiveContainer}>
                    <Typography.Text>
                      {WebHelper.formatMessage("DashboardWorkersMetric-topWorkers", {
                        qty: workerQuantity,
                      })}
                    </Typography.Text>
                  </div>
                )}
              </div>
              <Table
                dataSource={workersByWeight.slice(0, workerQuantity)}
                showHeader={false}
                pagination={false}
                className={styles.table}
                rowKey={(record) => record.worker.id}
                rowClassName={(_, index) =>
                  `${index === 0 ? (!downloadable ? styles.firstRow : styles.downloadFirstRow) : ""} ${styles.cellsBorder}`
                }
                columns={weightColumns}
              />
            </Card>
          </Card>
        )}
        <Drawer
          open={!isNil(visibleWorker)}
          width={WebHelper.drawerWidth}
          title={WebHelper.formatMessage("WorkerDetail-drawerTitle")}
          destroyOnClose
          onClose={handleDrawerClose}>
          {!isNil(visibleWorker) && <WorkerDetail workerId={visibleWorker.id} onDelete={handleDelete} onEdit={onWorkerEdit} />}
        </Drawer>
      </div>
    );
  }
);
