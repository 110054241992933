import {OrganizationAPIClient} from "../apiclient/organization/OrganizationAPIClient";
import * as OrganizationTypes from "../apiclient/organization/OrganizationAPIClient.types";

async function organizations(request: OrganizationTypes.OrganizationsRequest): Promise<OrganizationTypes.OrganizationsResponse> {
  return await OrganizationAPIClient.organizations(request);
}

async function organizationsShort(request: OrganizationTypes.OrganizationsRequest): Promise<OrganizationTypes.OrganizationsShortResponse> {
  return await OrganizationAPIClient.organizationsShort(request);
}

async function organization(request: OrganizationTypes.OrganizationRequest): Promise<OrganizationTypes.OrganizationResponse> {
  return await OrganizationAPIClient.organization(request);
}

async function createOrganization(
  request: OrganizationTypes.CreateOrganizationRequest
): Promise<OrganizationTypes.CreateOrganizationResponse> {
  return await OrganizationAPIClient.createOrganization(request);
}

async function deleteOrganization(
  request: OrganizationTypes.DeleteOrganizationRequest
): Promise<OrganizationTypes.DeleteOrganizationResponse> {
  return await OrganizationAPIClient.deleteOrganization(request);
}

async function updateOrganization(
  request: OrganizationTypes.UpdateOrganizationRequest
): Promise<OrganizationTypes.UpdateOrganizationResponse> {
  return await OrganizationAPIClient.updateOrganization(request);
}

export const OrganizationModule = {
  organizations,
  organizationsShort,
  organization,
  createOrganization,
  deleteOrganization,
  updateOrganization,
};
