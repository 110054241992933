import {en_US} from "./locales/en_US";
import {es_ES} from "./locales/es_ES";
import {fr_CA} from "./locales/fr_CA";
import {Locale} from "./locales/Locale";

export type AppConfigType = {
  Settings: {
    Localization: {
      amplifyDefaultLocale: string;
      defaultLocale: Locale;
      locales: Locale[];
    };
    Server: {
      apiClient: {
        baseUrl: string;
        timeout: number;
      };
    };
    AWS: {
      cognito: {
        region: string;
        userPoolId: string;
        userPoolWebClientId: string;
      };
    };
    Segment: {
      writeKey: string;
    };
    LaunchDarkly: {
      clientID: string;
    };
    Git: {
      currentCommitHash: string;
    };
    App: {
      version: string;
      deployStage: string;
    };
  };
  Modules: {};
  Components: {};
};

export const AppConfig: AppConfigType = {
  Settings: {
    Localization: {
      amplifyDefaultLocale: "en",
      defaultLocale: en_US,
      locales: [en_US, es_ES, fr_CA],
    },
    Server: {
      apiClient: {
        baseUrl: process.env.SERVER_BASE_URL!,
        timeout: 30000,
      },
    },
    AWS: {
      cognito: {
        region: process.env.COGNITO_REGION!,
        userPoolId: process.env.COGNITO_USERPOOLID!,
        userPoolWebClientId: process.env.COGNITO_USERPOOLWEBCLIENTID!,
      },
    },
    Segment: {
      writeKey: process.env.SEGMENT_WRITEKEY!,
    },
    LaunchDarkly: {
      clientID: process.env.LAUNCHDARKLY_CLIENT_ID!,
    },
    Git: {
      currentCommitHash: process.env.GIT_CURRENT_COMMIT_HASH!,
    },
    App: {
      version: process.env.VERSION!,
      deployStage: process.env.DEPLOY_STAGE!,
    },
  },
  Modules: {},
  Components: {},
};
