import {Buffer} from "buffer";

import intl from "react-intl-universal";
import {v4 as uuidv4} from "uuid";

import {mergeWith, cloneDeep, isArray, isNil} from "../../web/utils/FunctionUtils";
import {LocaleKey, LocaleParams} from "../locales/Locale";

// AppConfig, Models, Stores can not be imported!

const base64 = require("base-x")("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/");

export class CoreHelper {
  static getUUID(short = true): string {
    if (short) {
      const buf = new Buffer(16);
      const uuidLong = uuidv4(null, buf);

      let result = base64.encode(uuidLong);
      result = result.replace(/\//g, "_");
      result = result.replace(/\+/g, "-");
      result = result.replace(/=/g, "");

      return result;
    } else return uuidv4();
  }

  static formatMessage<TLocaleKey extends LocaleKey>(
    messageId: TLocaleKey,
    variables: LocaleParams[TLocaleKey] | undefined = undefined,
    defaultMessage: string | undefined = undefined,
    parseLineBreaks = false
  ): string {
    let result = intl.formatMessage(
      {
        id: messageId,
        defaultMessage: !isNil(defaultMessage) ? defaultMessage : messageId,
      },
      variables
    );

    if (parseLineBreaks) {
      const lines: string[] = result.split("|");

      result = "";
      lines.forEach((line) => (result.length === 0 ? (result = line) : (result = `${result}\n${line}`)));
    }
    return result;
  }

  static mergeWith(object: any, values: any, updateObject = true, customizer?: (value: any, sourceValue: any, key: any) => any): any {
    if (updateObject) {
      return mergeWith(object, values, !isNil(customizer) ? customizer : this.mergeWithCustomizer);
    } else {
      const clone = cloneDeep(object);
      return mergeWith(clone, values, !isNil(customizer) ? customizer : this.mergeWithCustomizer);
    }
  }

  static mergeWithCustomizer(value: any, sourceValue: any, key: any): any {
    return isArray(value) ? sourceValue : undefined;
  }

  static hasProperty<TKey extends PropertyKey>(value: object, key: TKey): value is {[K in TKey]: unknown} {
    return key in value;
  }

  static async wait(ms: number) {
    return new Promise((r) => setTimeout(r, ms));
  }

  static get isDevelopment(): boolean {
    return process.env.DEPLOY_STAGE === "development";
  }

  static get isProduction(): boolean {
    return process.env.DEPLOY_STAGE === "production";
  }
}
