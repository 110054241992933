import {DownloadOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Button, Card, Drawer, Space, Typography} from "antd";
import {addDays, startOfDay} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";

import styles from "./DashboardListsDrawers.module.scss";
import * as Models from "../../../../../core/models";
import {AnalyticsModule} from "../../../../../core/modules/AnalyticsModule";
import {WorkerModule} from "../../../../../core/modules/WorkerModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {ButtonType} from "../../../../components/common/button/Button";
import {WebHelper} from "../../../../utils/WebHelper";
import {Loading} from "../../../loading/Loading";

export enum WorkerStatus {
  Active = "active",
  Inactive = "inactive",
  Waitlist = "waitlist",
}

type DashboardWorkersListsDrawerProps = {
  entity: {type: Models.EntityType; id: string; timezone: string | undefined};
  status: WorkerStatus;
  onClose: () => void;
  open: boolean;
};

export const DashboardWorkersListsDrawer: FunctionComponent<DashboardWorkersListsDrawerProps> = ({entity, status, onClose, open}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [workers, setWorkers] = useState<Models.WorkerShort[]>([]);

  const analyticsDashboardWorkersQuery = useQuery({
    queryKey: ["DashboardWorkersListsDrawer-fetchAnalyticsDashboardWorkersData", entity],
    queryFn: () => {
      const end_time = fromZonedTime(startOfDay(addDays(new Date(), 1)), "UTC").toISOString();

      return AnalyticsModule.analyticsDashboardWorkerData({
        accessToken: authenticationStore.state.accessToken!,
        end_time,
        interval_type: Models.AnalyticsInterval.Day,
        interval_count: 8,
        resource_type: entity.type,
        resource_id: entity.id,
      });
    },
  });

  const workersShortQuery = useQuery({
    queryKey: ["DashboardWorkersListsDrawer-fetchWorkersData", entity.id],
    queryFn: () => {
      return WorkerModule.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        [`${entity.type}_id`]: entity.id,
      });
    },
    enabled: !!entity.id,
  });

  const handleDownloadData = useCallback(() => {
    const headers = [[WebHelper.formatMessage("DashboardWorkersListsDrawer-workerNameTitle")]];

    const dataRows = workers.map((worker) => [worker.displayName]);

    const csvContent = headers
      .concat(dataRows)
      .map((row) => row.join(","))
      .join("\n");

    const selectedStatus =
      status === WorkerStatus.Active
        ? WebHelper.formatMessage("DashboardWorkersListsDrawer-titleActive")
        : status === WorkerStatus.Waitlist
          ? WebHelper.formatMessage("DashboardWorkersListsDrawer-titleWaitlist")
          : WebHelper.formatMessage("DashboardWorkersListsDrawer-titleInactive");

    WebHelper.downloadFile(
      csvContent,
      WebHelper.formatMessage("DashboardWorkersListsDrawer-csv", {status: selectedStatus}),
      "text/csv;charset=utf-8;"
    );
  }, [workers, status]);

  useEffect(() => {
    if (!workersShortQuery.data) return;

    if (!workersShortQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("DashboardWorkersListsDrawer-fetchWorkersError"),
        workersShortQuery.data.correlationId
      );
      return;
    }

    if (!analyticsDashboardWorkersQuery.data) return;

    if (!analyticsDashboardWorkersQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("DashboardWorkersListsDrawer-fetchWorkersError"),
        analyticsDashboardWorkersQuery.data.correlationId
      );
      return;
    }

    const sortedWaitlistWorkers = workersShortQuery.data?.workers
      ? workersShortQuery.data?.workers
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .filter((worker) => worker.assignable === true && !worker.device_id)
      : [];
    const unfilteredSortedWorkers = analyticsDashboardWorkersQuery.data.data.worker_data
      ? analyticsDashboardWorkersQuery.data.data.worker_data.sort((a, b) => a.worker.displayName.localeCompare(b.worker.displayName))
      : [];

    switch (status) {
      case WorkerStatus.Waitlist:
        setWorkers(sortedWaitlistWorkers);
        break;
      case WorkerStatus.Active:
        setWorkers(
          unfilteredSortedWorkers
            .filter((analyticsDashboardWorker) => analyticsDashboardWorker.active)
            .map((analyticsDashboardWorker) => analyticsDashboardWorker.worker)
        );
        break;
      case WorkerStatus.Inactive:
        setWorkers(
          unfilteredSortedWorkers
            .filter((analyticsDashboardWorker) => analyticsDashboardWorker.inactive)
            .map((analyticsDashboardWorker) => analyticsDashboardWorker.worker)
        );
        break;
    }
  }, [status, analyticsDashboardWorkersQuery.data, workersShortQuery.data]);

  const loading = analyticsDashboardWorkersQuery.isFetching;

  const drawerTitle = useMemo(() => {
    switch (status) {
      case WorkerStatus.Active:
        return (
          <div className={styles.titleWrapper}>
            {WebHelper.formatMessage("DashboardWorkersListsDrawer-titleActive")}
            <Button
              type={ButtonType.Default}
              icon={<DownloadOutlined />}
              shape="round"
              style={{borderColor: "#E2E8F0"}}
              onClick={handleDownloadData}
              loading={loading}>
              {WebHelper.formatMessage("Common-exportToCsv")}
            </Button>
          </div>
        );
      case WorkerStatus.Inactive:
        return (
          <div className={styles.titleWrapper}>
            {WebHelper.formatMessage("DashboardWorkersListsDrawer-titleInactive")}
            <Button
              type={ButtonType.Default}
              icon={<DownloadOutlined />}
              shape="round"
              style={{borderColor: "#E2E8F0"}}
              onClick={handleDownloadData}
              loading={loading}>
              {WebHelper.formatMessage("Common-exportToCsv")}
            </Button>
          </div>
        );
      case WorkerStatus.Waitlist:
        return (
          <div className={styles.titleWrapper}>
            {WebHelper.formatMessage("DashboardWorkersListsDrawer-titleWaitlist")}
            <Button
              type={ButtonType.Default}
              icon={<DownloadOutlined />}
              shape="round"
              style={{borderColor: "#E2E8F0"}}
              onClick={handleDownloadData}
              loading={loading}>
              {WebHelper.formatMessage("Common-exportToCsv")}
            </Button>
          </div>
        );
    }
  }, [status, handleDownloadData, loading]);

  const workersCountTitle = useMemo(() => {
    switch (status) {
      case WorkerStatus.Active:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-workersCountTitleActive");
      case WorkerStatus.Inactive:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-workersCountTitleInactive");
      case WorkerStatus.Waitlist:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-workersCountTitleWaitlist");
    }
  }, [status]);

  return (
    <Drawer open={open} width={WebHelper.drawerWidth} destroyOnClose title={drawerTitle} onClose={onClose}>
      <Space className={styles.cardsWrapper} direction="vertical" size={16}>
        {loading && <Loading />}
        {!loading && (
          <>
            <Card className={styles.card}>
              <Space size={30}>
                <Typography.Text strong>{workersCountTitle}</Typography.Text>
                <Typography.Text>
                  {WebHelper.formatMessage("DashboardWorkersListsDrawer-workersCount", {workers: workers.length})}
                </Typography.Text>
              </Space>
            </Card>

            {workers.length > 0 && (
              <Card className={styles.card}>
                <Space direction="vertical" size={16}>
                  <Typography.Text strong>{WebHelper.formatMessage("DashboardWorkersListsDrawer-workerNameTitle")}</Typography.Text>
                  {workers.map((worker) => (
                    <Typography.Text key={worker.id}>{worker.displayName}</Typography.Text>
                  ))}
                </Space>
              </Card>
            )}
          </>
        )}
      </Space>
    </Drawer>
  );
};
