import {DownloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Input} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import React, {FunctionComponent, useMemo} from "react";
import {ButtonType} from "web/components/common/button/Button";

import styles from "./WorkersFilters.module.scss";
import * as Models from "../../../../../core/models";
import {EntityType} from "../../../../../core/models";
import {TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";

type WorkersFiltersProps = {
  selectedTag: Models.WorkersTagsKey;
  setSelectedTag: (tag: Models.WorkersTagsKey) => void;
  tagCounts: {
    assigned?: number;
    waitlist?: number;
    enrolled?: number;
    unenrolled: number;
    all: number;
  };
  setSearchValue: (searchValue: string) => void;
  entityType: Models.EntityType;
  onFilterChange: (action: string, newSettings?: {quickFilter?: Models.WorkersTagsKey; tableSettings?: TableSettings}) => void;
  handleDownloadData?: () => void;
  loading?: boolean;
};

export const WorkersFilters: FunctionComponent<WorkersFiltersProps> = ({
  selectedTag,
  setSelectedTag,
  tagCounts,
  setSearchValue,
  entityType,
  onFilterChange,
  handleDownloadData,
  loading,
}) => {
  const filters = useMemo(() => {
    switch (entityType) {
      case Models.EntityType.Site:
        return (
          <>
            <CheckableTag
              key={Models.WorkersTagsKey.Assigned}
              checked={selectedTag === Models.WorkersTagsKey.Assigned}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Assigned);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Assigned});
                }
              }}>
              <div id={`${Models.WorkersTagsKey.Assigned}-workersTab`}>{WebHelper.formatMessage("WorkersFilters-assignedTagText")}</div>
              <div className={styles.tagCount}>{tagCounts.assigned}</div>
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.Waitlist}
              checked={selectedTag === Models.WorkersTagsKey.Waitlist}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Waitlist);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Waitlist});
                }
              }}>
              <div id={`${Models.WorkersTagsKey.Waitlist}-workersTab`}>{WebHelper.formatMessage("WorkersFilters-waitlistTagText")}</div>
              <div className={styles.tagCount}>{tagCounts.waitlist}</div>
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.Unenrolled}
              checked={selectedTag === Models.WorkersTagsKey.Unenrolled}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Unenrolled);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Unenrolled});
                }
              }}>
              <div id={`${Models.WorkersTagsKey.Unenrolled}-workersTab`}>{WebHelper.formatMessage("WorkersFilters-unenrolledTagText")}</div>
              <div className={styles.tagCount}>{tagCounts.unenrolled}</div>
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.All}
              checked={selectedTag === Models.WorkersTagsKey.All}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.All);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.All});
                }
              }}>
              <div id={`${Models.WorkersTagsKey.All}-workersTab`}>{WebHelper.formatMessage("WorkersFilters-allTagText")}</div>
              <div className={styles.tagCount}>{tagCounts.all}</div>
            </CheckableTag>
          </>
        );
      case EntityType.WorkerReportGroup:
        return (
          <>
            <CheckableTag
              key={Models.WorkersTagsKey.Enrolled}
              checked={selectedTag === Models.WorkersTagsKey.Enrolled}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Enrolled);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Enrolled});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-enrolledTagText")}
              <div className={styles.tagCount}>{tagCounts.enrolled}</div>
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.Unenrolled}
              checked={selectedTag === Models.WorkersTagsKey.Unenrolled}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Unenrolled);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Unenrolled});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-unenrolledTagText")}
              <div className={styles.tagCount}>{tagCounts.unenrolled}</div>
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.All}
              checked={selectedTag === Models.WorkersTagsKey.All}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.All);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.All});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-allTagText")}
              <div className={styles.tagCount}>{tagCounts.all}</div>
            </CheckableTag>
          </>
        );
      default:
        return;
    }
  }, [entityType, selectedTag, setSelectedTag, onFilterChange, tagCounts]);

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filtersWrapper}>
        <div className={styles.filters}>{filters}</div>
        <Input
          className={styles.search}
          size="small"
          placeholder={WebHelper.formatMessage("WorkersFilters-searchButtonText")}
          prefix={<SearchOutlined />}
          onChange={(event) => setSearchValue(event.target.value)}
          allowClear
        />
      </div>
      <Button
        type={ButtonType.Default}
        icon={<DownloadOutlined />}
        shape="round"
        style={{borderColor: "#E2E8F0"}}
        onClick={handleDownloadData}
        loading={loading ?? false}>
        {WebHelper.formatMessage("Common-exportToCsv")}
      </Button>
    </div>
  );
};
