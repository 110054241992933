import * as Types from "./DepartmentAPIClient.types";
import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";

export class DepartmentAPIClient extends BaseAPIClient {
  static async departments(request: Types.DepartmentsRequest, options: RequestOptions = {}): Promise<Types.DepartmentsResponse> {
    const {accessToken, site_id} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `departments/`,
      headers: {token},
      request,
      options,
      params: {
        ...(site_id ? {site_id} : {}),
      },
    });

    if (requestResponse.success) {
      const {data, headers} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          departments: data.map((department: Models.Department) => Models.Department.fromJSON(department)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
          correlationId: headers["x-correlation-id"],
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async department(request: Types.DepartmentRequest, options: RequestOptions = {}): Promise<Types.DepartmentResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `departments/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data, headers} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          department: Models.Department.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
          correlationId: headers["x-correlation-id"],
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async createDepartment(
    request: Types.CreateDepartmentRequest,
    options: RequestOptions = {}
  ): Promise<Types.CreateDepartmentResponse> {
    const {name, site_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `departments/create`,
      headers: {token},
      request,
      options,
      data: {name, site_id},
    });

    if (requestResponse.success) {
      const {data, headers} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          department: Models.Department.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
          correlationId: headers["x-correlation-id"],
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async deleteDepartment(
    request: Types.DeleteDepartmentRequest,
    options: RequestOptions = {}
  ): Promise<Types.DeleteDepartmentResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `delete`,
      url: `departments/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      return {
        success: true,
      };
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateDepartment(
    request: Types.UpdateDepartmentRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateDepartmentResponse> {
    const {id, name, site_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `departments/${id}`,
      headers: {token},
      request,
      options,
      data: {name, site_id, id},
    });

    if (requestResponse.success) {
      const {data, headers} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          department: Models.Department.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
          correlationId: headers["x-correlation-id"],
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async updateDepartmentTargetUtilization(
    request: Types.UpdateDepartmentTargetUtilizationRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateDepartmentTargetUtilizationResponse> {
    const {department_id, target_utilization, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: "put",
      url: `departments/${department_id}/target_device_utilization`,
      headers: {token},
      request,
      options,
      data: {target_utilization},
    });

    if (requestResponse.success) {
      const {data, headers} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          target_utilization: data,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
          correlationId: headers["x-correlation-id"],
        };
      }
    } else {
      return this.getApiUpdateErrorResponse(requestResponse);
    }
  }

  static async allWorkersReport(
    request: Types.AllWorkersReportRequest,
    options: RequestOptions = {}
  ): Promise<Types.AllWorkersReportResponse> {
    const {accessToken, department_id, start_time, end_time} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      responseType: "blob",
      requestMethod: `post`,
      url: `departments/${department_id}/lifts_report_v2`,
      headers: {token},
      request,
      options,
      data: {start_time, end_time},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          file: data,
        };
      }
    }
    return {
      success: false,
      error: this.genericError(),
    };
  }

  static async demoReport(request: Types.DemoReportRequest, options: RequestOptions = {}): Promise<Types.DemoReportResponse> {
    const {accessToken, department_id, start_time, end_time} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      responseType: "blob",
      requestMethod: `post`,
      url: `departments/${department_id}/demo_report`,
      headers: {token},
      request,
      options,
      data: {start_time, end_time},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          file: data,
        };
      }
    }
    return {
      success: false,
      error: this.genericError(),
    };
  }

  static async departmentsDropdown(
    request: Types.DepartmentsDropdownRequest,
    options: RequestOptions = {}
  ): Promise<Types.DepartmentsDeviceDropdownResponse> {
    const {token} = request.accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `departments/tree_dropdown`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          organizations: data.organizations_info,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }
}
