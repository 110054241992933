import {DownloadOutlined, SearchOutlined} from "@ant-design/icons";
import {useQueries} from "@tanstack/react-query";
import {Button, Input, Modal, Select, Space, Table, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {AnalyticsModule} from "core/modules/AnalyticsModule";
import {AuthenticationDataStore} from "core/stores/AuthenticationDataStore";
import {addDays, addHours, addMinutes, addMonths, addQuarters, addWeeks} from "date-fns";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {Loading} from "web/components/loading/Loading";
import useUserPreferences from "web/hooks/useUserPreferences";
import {AppStore, SegmentKey} from "web/stores/AppStore";
import {isNil, isUndefined} from "web/utils/FunctionUtils";
import {WebHelper, lbsToKgs} from "web/utils/WebHelper";

import styles from "./AnalyticsDetailsModal.module.scss";
import * as Models from "../../../../../core/models";

const defaultSettings: Models.AnalyticsDetailsTableSettings = {
  pageSize: 10,
  sortColumn: "workerDisplayName",
  sortOrder: "descend",
};

type AnalyticsDetailsModalProps = {
  detailsData: {data: (Models.AnalyticsTimeSeries | Models.AnalyticsTimeSeriesIndices)[]; relativeTo: Models.AnalyticsRelativeTo};
  entities: Models.AnalyticsEntity[];
  selectedEntity?: Models.AnalyticsEntity;
  label: string;
  variableToChartSelected: Models.AnalyticsVariableToChart;
  dataType: Models.ChartDataType;
  intervalSelected: Models.AnalyticsInterval;
  intervalHoursSelected: Models.AnalyticsIntervalHours | undefined;
  endDate: Date | null;
  startDate: Date | null;
  open: boolean;
  onClose: () => void;
};

export const AnalyticsDetailsModal: FunctionComponent<AnalyticsDetailsModalProps> = ({
  detailsData,
  entities,
  selectedEntity,
  label,
  variableToChartSelected,
  dataType,
  intervalSelected,
  intervalHoursSelected,
  endDate,
  startDate,
  open,
  onClose,
}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const appStore = AppStore.getInstance();

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const [dataIndex, setDataIndex] = useState(0);
  const [details, setDetails] = useState<Models.AnalyticsDetails>();
  const [detailsFiltered, setDetailsFiltered] = useState<Models.AnalyticsDetail[]>();
  const [detailsFilteredInactive, setDetailsFilteredInactive] = useState<Models.AnalyticsDetail[]>();
  const [detailsFilteredActive, setDetailsFilteredActive] = useState<Models.AnalyticsDetail[]>();
  const [detailFilterText, setDetailFilterText] = useState<string>();

  const analyticsDetailsQueries = detailsData.data
    ? detailsData.data.map((item, index) => ({
        queryKey: [
          "Analytics-fetchDetails",
          item,
          entities && entities[index] ? entities[index].id : undefined,
          selectedEntity ? selectedEntity : undefined,
          detailsData.relativeTo,
        ],
        queryFn: () => {
          if (detailsData.relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit) {
            const start_time = startDate ? new Date(startDate).toISOString() : undefined;
            const end_time = endDate ? new Date(addMinutes(endDate!, -new Date().getTimezoneOffset())).toISOString() : undefined;
            const day = parseInt(label.match(/\d+/)?.[0] ?? "0");
            return AnalyticsModule.analyticsDetails({
              accessToken: authenticationStore.state.accessToken!,
              resource_id: entities[index].id,
              resource_type: entities[index].entityType,
              start_time,
              end_time,
              aggregation_mode: "days_in_suit",
              day: day,
            });
          } else if (detailsData.relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay) {
            const start_time = startDate ? new Date(startDate).toISOString() : undefined;
            const end_time = endDate ? new Date(endDate).toISOString() : undefined;
            return AnalyticsModule.analyticsDetails({
              accessToken: authenticationStore.state.accessToken!,
              resource_id: entities[index].id,
              resource_type: entities[index].entityType,
              start_time,
              end_time,
              time_frame_bounds: {
                from: item.start_time.toLocaleString().slice(0, 5),
                to: item.end_time.toLocaleString().slice(0, 5),
              },
              aggregation_mode: "hours_minutes",
            });
          } else {
            const end_time = new Date(addMinutes(item.end_time!, -new Date().getTimezoneOffset())).toISOString();
            const start_time = new Date(
              intervalSelected === Models.AnalyticsInterval.Day
                ? addDays(end_time, -1)
                : intervalSelected === Models.AnalyticsInterval.Week
                  ? addWeeks(end_time, -1)
                  : intervalSelected === Models.AnalyticsInterval.Quarter
                    ? addQuarters(end_time, -1)
                    : intervalSelected === Models.AnalyticsInterval.Month
                      ? addMonths(end_time, -1)
                      : intervalHoursSelected === Models.AnalyticsIntervalHours.EightHours
                        ? addHours(end_time, -8)
                        : intervalHoursSelected === Models.AnalyticsIntervalHours.FourHours
                          ? addHours(end_time, -4)
                          : intervalHoursSelected === Models.AnalyticsIntervalHours.TwelveHours
                            ? addHours(end_time, -12)
                            : intervalHoursSelected === Models.AnalyticsIntervalHours.TwoHours
                              ? addHours(end_time, -2)
                              : intervalHoursSelected === Models.AnalyticsIntervalHours.OneHours
                                ? addHours(end_time, -1)
                                : intervalHoursSelected === Models.AnalyticsIntervalHours.ThirtyMinutes
                                  ? addMinutes(end_time, -30)
                                  : addMinutes(end_time, -15)
            ).toISOString();
            return AnalyticsModule.analyticsDetails({
              accessToken: authenticationStore.state.accessToken!,
              resource_id: entities[index].id,
              resource_type: entities[index].entityType,
              start_time,
              end_time,
            });
          }
        },
        enabled: !!item,
      }))
    : [];

  const analyticsDetailsQueryResults = useQueries({queries: analyticsDetailsQueries});

  const analyticsDetailsQueryData = useCallback(() => {
    const results: Models.AnalyticsDetails[] = [];
    if (analyticsDetailsQueryResults && !analyticsDetailsQueryResults.some((query) => query.isPending)) {
      analyticsDetailsQueryResults.forEach((result) => {
        if (result.data && result.data.success) {
          results.push(result.data.data);
        }
      });
    }
    return results;
  }, [analyticsDetailsQueryResults]);

  const allData = analyticsDetailsQueryData();

  useEffect(() => {
    if (selectedEntity) {
      const index = entities.findIndex((entity) => entity.id === selectedEntity.id);
      if (index !== -1) {
        setDataIndex(index);
        setDetails(allData[index]);
      }
    }
  }, [allData, entities, selectedEntity]);

  useEffect(() => {
    setDetails(allData[dataIndex]);
  }, [dataIndex, allData]);

  const analyticsDetailsTable: Models.AnalyticsDetailsTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.analyticsDetailsTable ?? {})}),
    [userPreferences]
  );

  const {sortColumn, sortOrder} = analyticsDetailsTable;

  const handleWorkerSearch = useCallback(() => {
    if (detailFilterText && details && details.interval_details_data)
      setDetailsFiltered(
        details.interval_details_data.filter((data) => data.worker_first_name.toLowerCase().includes(detailFilterText.toLowerCase()))
      );
  }, [detailFilterText, details]);

  const handleWorkerSearchActive = useCallback(() => {
    if (detailFilterText && details && details.interval_details_data)
      setDetailsFilteredActive(
        details.interval_details_data.filter(
          (data) => data.is_active && data.worker_first_name.toLowerCase().includes(detailFilterText.toLowerCase())
        )
      );
  }, [detailFilterText, details]);

  const handleWorkerSearchInactive = useCallback(() => {
    if (detailFilterText && details && details.interval_details_data)
      setDetailsFilteredInactive(
        details.interval_details_data.filter(
          (data) => !data.is_active && data.worker_first_name.toLowerCase().includes(detailFilterText.toLowerCase())
        )
      );
  }, [detailFilterText, details]);

  const handleResetWorkerSearch = () => {
    setDetailFilterText(undefined);
    setDetailsFiltered(undefined);
    setDetailsFilteredActive(undefined);
    setDetailsFilteredInactive(undefined);
  };

  const workerColumnSearchDropdown = (active?: boolean) => (
    <div style={{padding: 8}}>
      <Input value={detailFilterText} onChange={(e) => setDetailFilterText(e.target.value)} className={styles.searchInput} />
      <Space>
        <Button
          type="primary"
          onClick={!isUndefined(active) ? (active ? handleWorkerSearchActive : handleWorkerSearchInactive) : handleWorkerSearch}
          icon={<SearchOutlined />}
          size="small">
          {WebHelper.formatMessage("Common-search")}
        </Button>
        <Button onClick={handleResetWorkerSearch} size="small">
          {WebHelper.formatMessage("Common-delete")}
        </Button>
      </Space>
    </div>
  );

  const columns: ColumnsType<Models.AnalyticsDetail> = [
    {
      title: WebHelper.formatMessage("AnalyticsDetails-fullNameColumn"),
      key: "full_name",
      sorter: (a, b) => a.worker_first_name.localeCompare(b.worker_first_name),
      sortOrder: sortColumn === "full_name" ? sortOrder : undefined,
      render: (_, record) => {
        return record.workerDisplayName;
      },
      filterIcon: <SearchOutlined className={!isNil(detailsFiltered) ? styles.searchActive : ""} />,
      filterDropdown: workerColumnSearchDropdown(
        variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ? true : undefined
      ),
    },
  ];

  const columnInactive: ColumnsType<Models.AnalyticsDetail> = [
    {
      title: WebHelper.formatMessage("AnalyticsDetails-fullNameColumn"),
      key: "full_name_inactive",
      sorter: (a, b) => a.worker_first_name.localeCompare(b.worker_first_name),
      sortOrder: sortColumn === "full_name_inactive" ? sortOrder : undefined,
      render: (_, record) => {
        return record.workerDisplayName;
      },
      filterIcon: <SearchOutlined className={!isNil(detailsFiltered) ? styles.searchActive : ""} />,
      filterDropdown: workerColumnSearchDropdown(false),
    },
  ];

  {
    variableToChartSelected === Models.AnalyticsVariableToChart.MovementBreakdown &&
      columns.push(
        {
          title: WebHelper.formatMessage("AnalyticsDetails-totalLiftsColumn"),
          dataIndex: "total_lifts",
          sorter: (a, b) => a.summary_metrics.total_lifts - b.summary_metrics.total_lifts,
          sortOrder: sortColumn === "total_lifts" ? sortOrder : undefined,
          render: (_, {summary_metrics}) => <Typography>{summary_metrics.total_lifts}</Typography>,
        },
        {
          title:
            dataType === Models.ChartDataType.Percentage
              ? WebHelper.formatMessage("AnalyticsDetails-twistedColumn%")
              : WebHelper.formatMessage("AnalyticsDetails-twistedColumn#"),
          dataIndex: "twisted_lifts",
          sorter: (a, b) => a.summary_metrics.twisted_lifts - b.summary_metrics.twisted_lifts,
          sortOrder: sortColumn === "twisted_lifts" ? sortOrder : undefined,
          render: (_, {summary_metrics}) => (
            <Typography>
              {dataType === Models.ChartDataType.Percentage
                ? summary_metrics.total_lifts > 0
                  ? ((summary_metrics.twisted_lifts / summary_metrics.total_lifts) * 100).toFixed(0)
                  : 0
                : summary_metrics.twisted_lifts}
            </Typography>
          ),
        },
        {
          title:
            dataType === Models.ChartDataType.Percentage
              ? WebHelper.formatMessage("AnalyticsDetails-forwardColumn%")
              : WebHelper.formatMessage("AnalyticsDetails-forwardColumn#"),
          dataIndex: "excessive_forward_lifts",
          sorter: (a, b) => a.summary_metrics.excessive_forward_lifts - b.summary_metrics.excessive_forward_lifts,
          sortOrder: sortColumn === "excessive_forward_lifts" ? sortOrder : undefined,
          render: (_, {summary_metrics}) => (
            <Typography>
              {dataType === Models.ChartDataType.Percentage
                ? summary_metrics.total_lifts > 0
                  ? ((summary_metrics.excessive_forward_lifts / summary_metrics.total_lifts) * 100).toFixed(0)
                  : 0
                : summary_metrics.excessive_forward_lifts}
            </Typography>
          ),
        },
        {
          title:
            dataType === Models.ChartDataType.Percentage
              ? WebHelper.formatMessage("AnalyticsDetails-prolongedColumn%")
              : WebHelper.formatMessage("AnalyticsDetails-prolongedColumn#"),
          dataIndex: "prolonged_bend_lifts",
          sorter: (a, b) => a.summary_metrics.prolonged_bend_lifts - b.summary_metrics.prolonged_bend_lifts,
          sortOrder: sortColumn === "prolonged_bend_lifts" ? sortOrder : undefined,
          render: (_, {summary_metrics}) => (
            <Typography>
              {dataType === Models.ChartDataType.Percentage
                ? summary_metrics.total_lifts > 0
                  ? ((summary_metrics.prolonged_bend_lifts / summary_metrics.total_lifts) * 100).toFixed(0)
                  : 0
                : summary_metrics.prolonged_bend_lifts}
            </Typography>
          ),
        },
        {
          title:
            dataType === Models.ChartDataType.Percentage
              ? WebHelper.formatMessage("AnalyticsDetails-sideBendColumn%")
              : WebHelper.formatMessage("AnalyticsDetails-sideBendColumn#"),
          dataIndex: "side_bend_lifts",
          sorter: (a, b) => a.summary_metrics.side_bend_lifts - b.summary_metrics.side_bend_lifts,
          sortOrder: sortColumn === "side_bend_lifts" ? sortOrder : undefined,
          render: (_, {summary_metrics}) => (
            <Typography>
              {dataType === Models.ChartDataType.Percentage
                ? summary_metrics.total_lifts > 0
                  ? ((summary_metrics.side_bend_lifts / summary_metrics.total_lifts) * 100).toFixed(0)
                  : 0
                : summary_metrics.side_bend_lifts}
            </Typography>
          ),
        }
      );
  }

  {
    variableToChartSelected === Models.AnalyticsVariableToChart.Lifts &&
      columns.push(
        {
          title: "Total Lifts",
          dataIndex: "total_lifts",
          sorter: (a, b) => a.summary_metrics.total_lifts - b.summary_metrics.total_lifts,
          sortOrder: sortColumn === "total_lifts" ? sortOrder : undefined,
          render: (_, {summary_metrics}) => <Typography>{summary_metrics.total_lifts}</Typography>,
        },
        {
          title:
            dataType === Models.ChartDataType.Percentage
              ? WebHelper.formatMessage("AnalyticsDetails-safeColumn%")
              : WebHelper.formatMessage("AnalyticsDetails-safeColumn#"),
          dataIndex: "safe_lifts",
          sorter: (a, b) => a.summary_metrics.safe_lifts - b.summary_metrics.safe_lifts,
          sortOrder: sortColumn === "safe_lifts" ? sortOrder : undefined,
          render: (_, {summary_metrics}) => (
            <Typography>
              {dataType === Models.ChartDataType.Percentage
                ? summary_metrics.total_lifts > 0
                  ? ((summary_metrics.safe_lifts / summary_metrics.total_lifts) * 100).toFixed(0)
                  : 0
                : summary_metrics.safe_lifts}
            </Typography>
          ),
        },
        {
          title:
            dataType === Models.ChartDataType.Percentage
              ? WebHelper.formatMessage("AnalyticsDetails-riskyColumn%")
              : WebHelper.formatMessage("AnalyticsDetails-riskyColumn#"),
          dataIndex: "risky_lifts",
          sorter: (a, b) => a.summary_metrics.risky_lifts - b.summary_metrics.risky_lifts,
          sortOrder: sortColumn === "risky_lifts" ? sortOrder : undefined,
          render: (_, {summary_metrics}) => (
            <Typography>
              {dataType === Models.ChartDataType.Percentage
                ? summary_metrics.total_lifts > 0
                  ? ((summary_metrics.risky_lifts / summary_metrics.total_lifts) * 100).toFixed(0)
                  : 0
                : summary_metrics.risky_lifts}
            </Typography>
          ),
        }
      );
  }

  const convertDecimalHoursToTime = (decimalHours: number) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.floor((decimalHours - hours) * 60);
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  {
    variableToChartSelected === Models.AnalyticsVariableToChart.HoursUsed &&
      columns.push({
        title: WebHelper.formatMessage("AnalyticsDetails-totalHours"),
        dataIndex: "hours_used",
        sorter: (a, b) => a.summary_metrics.hours_used - b.summary_metrics.hours_used,
        sortOrder: sortColumn === "hours_used" ? sortOrder : undefined,
        render: (_, {summary_metrics}) => <Typography>{convertDecimalHoursToTime(summary_metrics.hours_used)}</Typography>,
      });
  }

  {
    variableToChartSelected === Models.AnalyticsVariableToChart.WeightOffloaded &&
      columns.push({
        title: WebHelper.formatMessage("AnalyticsDetails-weightOffloadedColumn"),
        dataIndex: "weight_offloaded",
        sorter: (a, b) => a.summary_metrics.weight_offloaded_lbs - b.summary_metrics.weight_offloaded_lbs,
        sortOrder: sortColumn === "weight_offloaded" ? sortOrder : undefined,
        render: (_, {summary_metrics}) => (
          <Typography>
            {userPreferences.data.metricMeasurementUnits
              ? lbsToKgs(summary_metrics.weight_offloaded_lbs).toFixed(0)
              : summary_metrics.weight_offloaded_lbs.toFixed(0)}
          </Typography>
        ),
      });
  }

  const handleInactiveWorkersTableSettingsChange = (updatedTableSettings?: Partial<Models.DashboardWorkersTableSettings>) =>
    setUserPreferences({
      tableSettings: {
        analyticsDetailsTable: {
          ...analyticsDetailsTable,
          ...updatedTableSettings,
        },
      },
    });

  const title = useMemo(() => {
    switch (variableToChartSelected) {
      case Models.AnalyticsVariableToChart.HoursUsed:
        return WebHelper.formatMessage("AnalyticsDetails-totalHours");
      case Models.AnalyticsVariableToChart.WeightOffloaded:
        return WebHelper.formatMessage("AnalyticsDetails-weightOffloaded");
      case Models.AnalyticsVariableToChart.MovementBreakdown:
      case Models.AnalyticsVariableToChart.Lifts:
        return WebHelper.formatMessage("AnalyticsDetails-movementBreakdown");
      case Models.AnalyticsVariableToChart.WorkerActiveStatus:
        return WebHelper.formatMessage("AnalyticsDetails-activeWorkers");
      default:
        return "";
    }
  }, [variableToChartSelected]);

  const handleDownload = useCallback(() => {
    if (!details) return;
    const headers =
      variableToChartSelected === Models.AnalyticsVariableToChart.MovementBreakdown
        ? [
            [
              WebHelper.formatMessage("AnalyticsDetails-fullNameColumn"),
              WebHelper.formatMessage("AnalyticsDetails-totalLiftsColumn"),
              dataType == Models.ChartDataType.Percentage
                ? WebHelper.formatMessage("AnalyticsDetails-twistedColumn%")
                : WebHelper.formatMessage("AnalyticsDetails-twistedColumn#"),
              dataType == Models.ChartDataType.Percentage
                ? WebHelper.formatMessage("AnalyticsDetails-forwardColumn%")
                : WebHelper.formatMessage("AnalyticsDetails-forwardColumn#"),
              dataType == Models.ChartDataType.Percentage
                ? WebHelper.formatMessage("AnalyticsDetails-prolongedColumn%")
                : WebHelper.formatMessage("AnalyticsDetails-prolongedColumn#"),
              dataType == Models.ChartDataType.Percentage
                ? WebHelper.formatMessage("AnalyticsDetails-sideBendColumn%")
                : WebHelper.formatMessage("AnalyticsDetails-sideBendColumn#"),
            ],
          ]
        : variableToChartSelected === Models.AnalyticsVariableToChart.HoursUsed
          ? [[WebHelper.formatMessage("AnalyticsDetails-fullNameColumn"), WebHelper.formatMessage("AnalyticsDetails-totalHours")]]
          : variableToChartSelected === Models.AnalyticsVariableToChart.WeightOffloaded
            ? [
                [
                  WebHelper.formatMessage("AnalyticsDetails-fullNameColumn"),
                  WebHelper.formatMessage("AnalyticsDetails-weightOffloadedColumn"),
                ],
              ]
            : variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus
              ? [[WebHelper.formatMessage("AnalyticsDetails-fullNameColumn"), WebHelper.formatMessage("AnalyticsDetails-isActive")]]
              : [
                  [
                    WebHelper.formatMessage("AnalyticsDetails-fullNameColumn"),
                    WebHelper.formatMessage("AnalyticsDetails-totalLiftsColumn"),
                    dataType == Models.ChartDataType.Percentage
                      ? WebHelper.formatMessage("AnalyticsDetails-safeColumn%")
                      : WebHelper.formatMessage("AnalyticsDetails-safeColumn#"),
                    dataType == Models.ChartDataType.Percentage
                      ? WebHelper.formatMessage("AnalyticsDetails-riskyColumn%")
                      : WebHelper.formatMessage("AnalyticsDetails-riskyColumn#"),
                  ],
                ];

    const dataRows = details.interval_details_data.map((detail) =>
      variableToChartSelected === Models.AnalyticsVariableToChart.MovementBreakdown
        ? [
            detail.workerDisplayName,
            detail.summary_metrics.total_lifts.toString(),
            dataType == Models.ChartDataType.Percentage
              ? detail.summary_metrics.total_lifts > 0
                ? ((detail.summary_metrics.twisted_lifts / detail.summary_metrics.total_lifts) * 100).toFixed(0).toString()
                : "0"
              : detail.summary_metrics.twisted_lifts.toString(),
            dataType == Models.ChartDataType.Percentage
              ? detail.summary_metrics.total_lifts > 0
                ? ((detail.summary_metrics.excessive_forward_lifts / detail.summary_metrics.total_lifts) * 100).toFixed(0).toString()
                : "0"
              : detail.summary_metrics.excessive_forward_lifts.toString(),
            dataType == Models.ChartDataType.Percentage
              ? detail.summary_metrics.total_lifts > 0
                ? ((detail.summary_metrics.prolonged_bend_lifts / detail.summary_metrics.total_lifts) * 100).toFixed(0).toString()
                : "0"
              : detail.summary_metrics.prolonged_bend_lifts.toString(),
            dataType == Models.ChartDataType.Percentage
              ? detail.summary_metrics.total_lifts > 0
                ? ((detail.summary_metrics.side_bend_lifts / detail.summary_metrics.total_lifts) * 100).toFixed(0).toString()
                : "0"
              : detail.summary_metrics.side_bend_lifts.toString(),
          ]
        : variableToChartSelected === Models.AnalyticsVariableToChart.HoursUsed
          ? [detail.workerDisplayName, convertDecimalHoursToTime(detail.summary_metrics.hours_used).toString()]
          : variableToChartSelected === Models.AnalyticsVariableToChart.WeightOffloaded
            ? [
                detail.workerDisplayName,
                userPreferences.data.metricMeasurementUnits
                  ? lbsToKgs(detail.summary_metrics.weight_offloaded_lbs).toString()
                  : detail.summary_metrics.weight_offloaded_lbs.toString(),
              ]
            : variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus
              ? [detail.workerDisplayName, detail.is_active ? WebHelper.formatMessage("Common-yes") : WebHelper.formatMessage("Common-no")]
              : [
                  detail.workerDisplayName,
                  detail.summary_metrics.total_lifts.toString(),
                  dataType == Models.ChartDataType.Percentage
                    ? detail.summary_metrics.total_lifts > 0
                      ? ((detail.summary_metrics.safe_lifts / detail.summary_metrics.total_lifts) * 100).toFixed(0).toString()
                      : "0"
                    : detail.summary_metrics.safe_lifts.toString(),
                  dataType == Models.ChartDataType.Percentage
                    ? detail.summary_metrics.total_lifts > 0
                      ? ((detail.summary_metrics.risky_lifts / detail.summary_metrics.total_lifts) * 100).toFixed(0).toString()
                      : "0"
                    : detail.summary_metrics.risky_lifts.toString(),
                ]
    );

    const csvContent = headers
      .concat(dataRows)
      .map((row) => row.join(","))
      .join("\n");
    WebHelper.downloadFile(
      csvContent,
      `${variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ? WebHelper.formatMessage("AnalyticsDetails-allWorkers") : title} - ${entities[dataIndex].name}`,
      "text/csv;charset=utf-8;"
    );
  }, [variableToChartSelected, dataType, details, title, userPreferences.data.metricMeasurementUnits, dataIndex, entities]);

  const handleSelectChange = (selectedOption: string) => {
    if (selectedOption) {
      const index = entities.findIndex((entity) => entity.id === selectedOption);
      if (index !== -1) {
        setDataIndex(index);
      }
    }
  };

  const handleOnClose = () => {
    setDataIndex(0);
    setDetails(undefined);
    setDetailFilterText(undefined);
    setDetailsFiltered(undefined);
    setDetailsFilteredActive(undefined);
    setDetailsFilteredInactive(undefined);
    onClose();
  };

  const entityOptions = entities.map((entity) => ({
    value: entity.id,
    label: entity.name,
  }));

  return (
    <Modal
      centered
      open={open}
      width={"650px"}
      destroyOnClose
      title={
        <div className={styles.title}>
          {WebHelper.formatMessage("AnalyticsDetails-titleModal")}
          <Select
            className={styles.select}
            options={entityOptions}
            defaultValue={selectedEntity ? selectedEntity.id : entities[0].id}
            onChange={(value) => handleSelectChange(value)}
          />
        </div>
      }
      onCancel={handleOnClose}
      footer={
        <Space>
          <Button
            shape="round"
            disabled={!details}
            loading={analyticsDetailsQueryResults.some((query) => query.isLoading)}
            onClick={handleDownload}
            icon={<DownloadOutlined />}>
            {WebHelper.formatMessage("AnalyticsDetails-downloadDataButton")}
          </Button>
          <Button key="close" shape="round" onClick={onClose}>
            {WebHelper.formatMessage("Common-close")}
          </Button>
        </Space>
      }>
      <>
        {analyticsDetailsQueryResults.some((query) => query.isLoading) ? (
          <Loading />
        ) : (
          <div className={styles.container}>
            <div
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(
                  WebHelper.formatMessage("AnalyticsDetails-description", {day: selectedEntity ? label.split(" - ")[0] : label})
                ),
              }}
              className={styles.description}
            />
            <div className={styles.tableContainer}>
              <Typography.Title level={5} className={styles.tableTitle}>
                {title}
              </Typography.Title>
              {details ? (
                <Table
                  loading={analyticsDetailsQueryResults.some((query) => query.isLoading)}
                  columns={columns}
                  style={{maxHeight: "700px", overflowY: "scroll", overflowX: "hidden"}}
                  dataSource={
                    variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus
                      ? detailsFilteredActive ?? details.interval_details_data.filter((detail) => detail.is_active)
                      : detailsFiltered ?? details.interval_details_data
                  }
                  onChange={(pagination, filters, sorter, extra) => {
                    appStore.analytics.track(SegmentKey.WorkersTableSettingsChanged, {pagination, filters, sorter, action: extra.action});
                    if (extra.action === "sort" && !Array.isArray(sorter)) {
                      handleInactiveWorkersTableSettingsChange({
                        sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
                        sortOrder: sorter.order,
                      });
                    }
                  }}></Table>
              ) : (
                <Loading />
              )}
            </div>
            {variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus &&
              detailsData.relativeTo !== Models.AnalyticsRelativeTo.DaysInTheSuit && (
                <div className={styles.tableContainer}>
                  <Typography.Title level={5} className={styles.tableTitle}>
                    {WebHelper.formatMessage("AnalyticsDetails-inactiveWorkers")}
                  </Typography.Title>
                  {details ? (
                    <Table
                      loading={analyticsDetailsQueryResults.some((query) => query.isLoading)}
                      columns={columnInactive}
                      style={{maxHeight: "700px", overflowY: "scroll", overflowX: "hidden"}}
                      dataSource={detailsFilteredInactive ?? details.interval_details_data.filter((detail) => !detail.is_active)}
                      onChange={(paginationInactive, filtersInactive, sorterInactive, extraInactive) => {
                        appStore.analytics.track(SegmentKey.WorkersTableSettingsChanged, {
                          paginationInactive,
                          filtersInactive,
                          sorterInactive,
                          action: extraInactive.action,
                        });
                        if (extraInactive.action === "sort" && !Array.isArray(sorterInactive)) {
                          handleInactiveWorkersTableSettingsChange({
                            sortColumn: sorterInactive.field?.toString() ?? sorterInactive.columnKey?.toString(),
                            sortOrder: sorterInactive.order,
                          });
                        }
                      }}></Table>
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
          </div>
        )}
      </>
    </Modal>
  );
};
