import {Typography} from "antd";
import React, {FunctionComponent} from "react";

import styles from "./LinkButton.module.scss";

type LinkButtonProps = {
  id?: string;
  onClick: () => void;
  darkBackground?: boolean;
  danger?: boolean;
  children?: React.ReactNode;
  strong?: boolean;
};

export const LinkButton: FunctionComponent<LinkButtonProps> = ({id, darkBackground, onClick, danger, children, strong}) => {
  return (
    <Typography.Text
      id={id}
      strong={strong}
      className={`${styles.commonButton} ${
        danger ? styles.dangerButton : darkBackground ? styles.alternativeButton : styles.defaultButton
      }`}
      onClick={onClick}>
      {children}
    </Typography.Text>
  );
};
