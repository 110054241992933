import {InfoCircleFilled} from "@ant-design/icons";
import {useMutation} from "@tanstack/react-query";
import {Checkbox, Modal, Select, Space, Switch, Typography} from "antd";
import {SiteModule} from "core/modules/SiteModule";
import {AuthenticationDataStore} from "core/stores/AuthenticationDataStore";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";
import {AppStore, SegmentKey} from "web/stores/AppStore";

import styles from "./SiteSummaryReportEditConfigurationModal.module.scss";
import * as Models from "../../../../../../core/models";
import {WebHelper} from "../../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../../common/button/Button";

type SiteSummaryReportEditConfigurationModalProps = {
  orgId: string;
  siteId: string;
  siteConfig: Models.SiteConfiguration | null;
  workerReportGroups: Models.WorkerReportGroup[];
  showEditConfigurationModal: boolean;
  onOk: () => void;
  onCancel: () => void;
  onChange: () => void;
  loading: boolean;
};

export const SiteSummaryReportEditConfigurationModal: FunctionComponent<SiteSummaryReportEditConfigurationModalProps> = observer(
  ({orgId, siteId, siteConfig, workerReportGroups, showEditConfigurationModal, onOk, onCancel, onChange, loading}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();

    const [includeWorkerReportGroups, setIncludeWorkerReportGroups] = useState<boolean>(siteConfig && siteConfig.groups ? true : false);
    const [leaderboard, setLeaderboard] = useState<boolean>(siteConfig ? siteConfig.leaderboard_report_included : false);
    const [activeInactiveWorkersList, setActiveInactiveWorkersList] = useState<boolean>(
      siteConfig ? siteConfig.active_list_report_included : false
    );
    const [newSelectedWorkerGroups, setNewSelectedWorkerGroups] = useState<Models.WorkerReportGroup[]>([]);
    const [weigthOffloaded, setWeigthOffloaded] = useState<boolean>(siteConfig ? siteConfig.weight_offloaded_report_included : false);
    const [safeliftScore, setSafeliftScore] = useState<boolean>(siteConfig ? siteConfig.safelift_score_report_included : false);

    const [siteConfigurationChanges, setSiteConfigurationChanges] = useState(siteConfig ? false : true);

    const updateSiteConfigurationMutation = useMutation({
      mutationFn: async () => {
        let workerGroupIds: {id: string}[] = [];
        workerGroupIds = newSelectedWorkerGroups ? newSelectedWorkerGroups.map((workerGroup) => ({id: workerGroup.id})) : [];
        const workerGroupsFromSiteConfig =
          siteConfig && siteConfig.groups ? siteConfig.groups.map((workerGroup) => ({id: workerGroup.id})) : [];
        const workerGroupsChanged = siteConfig
          ? !(
              workerGroupIds &&
              workerGroupsFromSiteConfig &&
              workerGroupsFromSiteConfig.length === workerGroupIds?.length &&
              workerGroupsFromSiteConfig.every((group, index) => group.id === workerGroupIds[index].id)
            )
          : false;

        appStore.sendAnalyticTrack(SegmentKey.SiteSummaryReportDefaultSettingsChanged, {
          orgID: orgId,
          siteID: siteId,
          userOrg: authenticationStore.state.user?.organization_id,
          reportGroupCount: workerGroupIds ? workerGroupIds.length : 0,
          leaderboardIncluded: leaderboard,
          activeInactiveListIncluded: activeInactiveWorkersList,
        });

        return SiteModule.updateSiteConfiguration({
          accessToken: authenticationStore.state.accessToken!,
          id: siteId,
          site_configuration_id: siteConfig ? siteConfig.id : null,
          weekday_report: Models.Weekday.Sunday, // not yet implemented, so this is the default value
          time_report: "07:00:00", // not yet implemented, so this is the default value
          active_list_report_included: activeInactiveWorkersList,
          leaderboard_report_included: leaderboard,
          weight_offloaded_report_included: weigthOffloaded && activeInactiveWorkersList,
          safelift_score_report_included: safeliftScore && activeInactiveWorkersList,
          override_groups: workerGroupsChanged,
          report_groups: workerGroupsChanged ? (workerGroupIds.length === 0 ? [{}] : workerGroupIds) : [],
        });
      },
      onSuccess: async (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("SiteSummaryReportModal-updateError"), response.correlationId);
        } else {
          appStore.showMessage(WebHelper.formatMessage("SiteSummaryReportModal-updateSuccess"), "success");
          await onChange();
          onOk();
          setSiteConfigurationChanges(false);
        }
      },
    });

    useEffect(() => {
      if (siteConfig) {
        const arraysEqual = () => {
          if ((!newSelectedWorkerGroups || newSelectedWorkerGroups.length === 0) && (!siteConfig.groups || siteConfig.groups.length === 0))
            return true;
          else if (newSelectedWorkerGroups && siteConfig.groups) {
            if (siteConfig.groups.length !== newSelectedWorkerGroups.length) return false;
            for (let i = 0; i < siteConfig.groups.length; i++) {
              if (siteConfig.groups[i].id !== newSelectedWorkerGroups[i].id) return false;
            }
            return true;
          } else return false;
        };
        if (
          !arraysEqual() ||
          activeInactiveWorkersList !== siteConfig.active_list_report_included ||
          leaderboard !== siteConfig.leaderboard_report_included ||
          weigthOffloaded !== siteConfig.weight_offloaded_report_included ||
          safeliftScore !== siteConfig.safelift_score_report_included
        ) {
          setSiteConfigurationChanges(true);
        } else {
          setSiteConfigurationChanges(false);
        }
      }
    }, [
      siteConfig,
      includeWorkerReportGroups,
      leaderboard,
      activeInactiveWorkersList,
      weigthOffloaded,
      safeliftScore,
      newSelectedWorkerGroups,
    ]);

    const handleSelectChange = (value: string[]) => {
      setNewSelectedWorkerGroups(workerReportGroups.filter((group) => value.includes(group.id)));
    };

    const options = workerReportGroups?.map((group) => {
      return {value: group.id, label: group.name};
    });

    return (
      <Modal
        centered
        zIndex={1001}
        destroyOnClose={true}
        width={"500px"}
        open={showEditConfigurationModal}
        title={WebHelper.formatMessage("SiteSummaryReportModal-title")}
        onCancel={onCancel}
        footer={
          <Space>
            <Button shape="round" onClick={onCancel} disabled={loading || updateSiteConfigurationMutation.isPending}>
              {WebHelper.formatMessage("Common-cancel")}
            </Button>
            <Button
              type={ButtonType.Primary}
              shape="round"
              onClick={updateSiteConfigurationMutation.mutate}
              disabled={!siteConfigurationChanges}
              loading={loading || updateSiteConfigurationMutation.isPending}>
              {WebHelper.formatMessage("SiteSummaryReportModal-save")}
            </Button>
          </Space>
        }>
        <Space className={styles.switchWrapper} align="center" size="middle" direction="horizontal">
          <Typography.Text className={styles.switchLabel}>
            {WebHelper.formatMessage("SiteSummaryReportModal-includeWorkerReportGroups")}
          </Typography.Text>
          <Switch checked={includeWorkerReportGroups} onChange={(value) => setIncludeWorkerReportGroups(value)} />
        </Space>
        {includeWorkerReportGroups && (
          <Space className={styles.selectWrapper} align="center" size="middle" direction="horizontal">
            <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("SiteSummaryReportModal-select")}</Typography.Text>
            <Select
              options={options}
              onChange={(value) => handleSelectChange(value)}
              defaultValue={siteConfig && siteConfig.groups ? siteConfig.groups.map((group) => group.id) : undefined}
              className={styles.groupName}
              placeholder={WebHelper.formatMessage("SiteSummaryReportModal-workerGroupsPlaceholder")}
              mode="multiple"
              allowClear
            />
          </Space>
        )}
        <Space size="middle" direction="vertical" style={{padding: "12px 0px 12px"}}>
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportModal-featuresToIncludeMessage")}</Typography.Text>
          <Space className={styles.checkboxWrapper} align="center" size="middle" direction="horizontal">
            <Checkbox checked={leaderboard} onChange={(e) => setLeaderboard(e.target.checked)} />
            <Typography.Text className={styles.checkboxLabel}>
              {WebHelper.formatMessage("SiteSummaryReportModal-leaderboardCheckbox")}
            </Typography.Text>
          </Space>
          <Space className={styles.checkboxWrapper} align="center" size="middle" direction="horizontal">
            <Checkbox checked={activeInactiveWorkersList} onChange={(e) => setActiveInactiveWorkersList(e.target.checked)} />
            <Typography.Text className={styles.checkboxLabel}>
              {WebHelper.formatMessage("SiteSummaryReportModal-activeInactiveWorkersCheckbox")}
            </Typography.Text>
          </Space>
          <Space className={styles.subCheckboxWrapper} align="center" size="middle" direction="horizontal">
            <Checkbox
              disabled={!activeInactiveWorkersList}
              checked={weigthOffloaded && activeInactiveWorkersList}
              onChange={(e) => setWeigthOffloaded(e.target.checked)}
            />
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportModal-displayWorkersWeightOffloadedCheckbox")}</Typography.Text>
          </Space>
          <Space className={styles.subCheckboxWrapper} align="center" size="middle" direction="horizontal">
            <Checkbox
              disabled={!activeInactiveWorkersList}
              checked={safeliftScore && activeInactiveWorkersList}
              onChange={(e) => setSafeliftScore(e.target.checked)}
            />
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportModal-safeliftScoreCheckbox")}</Typography.Text>
          </Space>
        </Space>
        <Space className={styles.infoWrapper} align="center" size="middle" direction="horizontal">
          <InfoCircleFilled />
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportModal-onlyAdminsMessage")}</Typography.Text>
        </Space>
      </Modal>
    );
  }
);
