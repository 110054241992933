import {Space, Spin, Typography} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";

import ActiveInactiveWorkersReportDropdown from "./active-inactive-workers-report-dropdown/ActiveInactiveWorkersReportDropdown";
import styles from "./DashboardWorkersSection.module.scss";
import * as Models from "../../../../../core/models";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {isNil} from "../../../../utils/FunctionUtils";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";
import {Collapse} from "../../../common/collapse/Collapse";
import {ChangeWorkerStatusModal} from "../../workers-tab-content/change-worker-status-modal/ChangeWorkerStatusModal";
import {DeviceNoWorker} from "../../workers-tab-content/device-no-worker/DeviceNoWorker";
import {DashboardWorkersTable} from "../dashboard-workers-table/DashboardWorkersTable";

type AnalyticsDashboardWorkers = {
  active: Models.AnalyticsDashboardWorker[];
  inactive: Models.AnalyticsDashboardWorker[];
};

type DashboardWorkersSectionProps = {
  metrics?: Models.AnalyticsDashboardWorkerData;
  data?: Models.AnalyticsDashboardWorkerData;
  loading: boolean;
  onChange: () => void;
  department: Models.Department;
};

export const DashboardWorkersSection: FunctionComponent<DashboardWorkersSectionProps> = ({
  metrics,
  data,
  loading,
  onChange,
  department,
}) => {
  const appStore = AppStore.getInstance();

  const [visibleWorker, setVisibleWorker] = useState<Models.Worker | Models.WorkerShort>();
  const [showChangeWorkerStatusModal, setShowChangeWorkerStatusModal] = useState(false);
  const [workers, setWorkers] = useState<AnalyticsDashboardWorkers>();
  const [unenrolledWorker, setUnenrolledWorker] = useState<Models.Worker | Models.WorkerShort>();

  useEffect(() => {
    if (!data || !data.worker_data || !metrics || !metrics.worker_data) return;

    const updateWorkerData = (worker: Models.AnalyticsDashboardWorker) => {
      const correspondingWorker = metrics.worker_data.find((dataWorker) => dataWorker.worker.id === worker.worker.id);
      if (correspondingWorker) {
        worker.worker = correspondingWorker.worker;
      }
      return worker;
    };

    const active: Models.AnalyticsDashboardWorker[] = data.worker_data.filter((worker) => worker.active).map(updateWorkerData);

    const inactive: Models.AnalyticsDashboardWorker[] = data.worker_data.filter((worker) => worker.inactive).map(updateWorkerData);
    setWorkers({active, inactive});
  }, [data, metrics]);

  const handleChangeWorkerStatusModalOpen = (worker: Models.Worker | Models.WorkerShort) => {
    setVisibleWorker(worker);
    setShowChangeWorkerStatusModal(true);
  };

  const handleWorkerStatusChange = () => {
    onChange();
    setShowChangeWorkerStatusModal(false);
    setUnenrolledWorker(visibleWorker);
    setVisibleWorker(undefined);
  };

  const handleWorkerStatusChangeCancel = () => {
    setShowChangeWorkerStatusModal(false);
    setVisibleWorker(undefined);
  };

  return (
    <>
      <Collapse
        isOpen={false}
        onChange={(keys) => {
          if (keys.includes("header_panel")) {
            appStore.sendAnalyticTrack(SegmentKey.DashboardWorkerActivityListView, {
              departmentID: department.id,
              siteID: department.site.id,
              orgID: department.organization.id,
            });
          }
        }}
        header={
          <Space className={styles.collapseHeader}>
            <Space>
              <Space>
                <Typography.Title className={`${styles.numberDisplay} ${styles.active}`} level={5}>
                  {loading ? <Spin size="small" spinning /> : workers ? workers.active.length : "-"}
                </Typography.Title>
                <Typography.Title className={styles.panelHeaderText} level={5}>
                  {WebHelper.formatMessage("DashboardTabContent-activeWorkers")}
                </Typography.Title>
              </Space>
              <Space>
                <Typography.Title className={`${styles.numberDisplay} ${styles.inactive}`} level={5}>
                  {loading ? <Spin size="small" spinning /> : workers ? workers.inactive.length : "-"}
                </Typography.Title>
                <Typography.Title className={styles.panelHeaderText} level={5}>
                  {WebHelper.formatMessage("DashboardTabContent-inactiveWorkers")}
                </Typography.Title>
              </Space>
            </Space>

            <div>
              <ActiveInactiveWorkersReportDropdown
                workersActive={workers?.active || []}
                workersInactive={workers?.inactive || []}
                department={department}
              />
            </div>
          </Space>
        }>
        <DashboardWorkersTable
          activeWorkers={workers?.active ?? []}
          inactiveWorkers={workers?.inactive ?? []}
          loading={loading}
          onOpenChangeWorkerStatusModal={handleChangeWorkerStatusModalOpen}
          onEditDevice={onChange}
          onEditTarget={onChange}
          onEditWorker={onChange}
          department={department}
        />
      </Collapse>
      <ChangeWorkerStatusModal
        worker={visibleWorker}
        open={showChangeWorkerStatusModal}
        entryPoint={SegmentEntryPoint.ACTIVE_INACTIVE_TABLE}
        onOk={handleWorkerStatusChange}
        onCancel={handleWorkerStatusChangeCancel}
      />
      {unenrolledWorker?.assigned_device && unenrolledWorker.department && (
        <DeviceNoWorker
          deviceId={unenrolledWorker.assigned_device.id}
          deviceTag={unenrolledWorker.assigned_device.device_tag}
          departmentId={unenrolledWorker.department.id}
          onOk={() => {
            setUnenrolledWorker(undefined);
            onChange();
          }}
          onClose={() => {
            setUnenrolledWorker(undefined);
            onChange();
          }}
          open={!isNil(unenrolledWorker)}
          entryPoint={SegmentEntryPoint.ACTIVE_INACTIVE_TABLE}
        />
      )}
    </>
  );
};
export default DashboardWorkersSection;
