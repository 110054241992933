import {useQuery} from "@tanstack/react-query";
import {Select} from "antd";
import React, {useState, useRef, useEffect, useMemo, useCallback} from "react";

import styles from "./EditableDevice.module.scss";
import * as Models from "../../../../../core/models";
import {DeviceModule} from "../../../../../core/modules/DeviceModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {isNil} from "../../../../utils/FunctionUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import {LinkButton} from "../../../common/link-button/LinkButton";

type EditableDeviceProps = {
  worker: Models.WorkerShort | Models.Worker;
  onSave: (worker: Models.WorkerShort | Models.Worker, device?: Models.DeviceShort) => void;
};

export const EditableDevice: React.FC<EditableDeviceProps> = ({onSave, worker}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [editing, setEditing] = useState(false);
  const [availableDevices, setAvailableDevices] = useState<Models.DeviceShort[]>([]);
  const selectRef = useRef<any>(null);

  const {isFetching: devicesQueryIsFetching, refetch: devicesQueryRefetch} = useQuery({
    queryKey: ["EditableDevice-fetchDevicesData"],
    queryFn: () =>
      DeviceModule.devicesShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: worker.department?.id,
      }),
    enabled: false,
  });

  const toggleEdit = () => {
    setEditing(!editing);
  };

  useEffect(() => {
    if (editing) {
      selectRef.current.focus();
    }
  }, [editing]);

  const handleSave = async (value: string) => {
    toggleEdit();
    if (worker.assigned_device?.id !== value) {
      const selectedDevice = availableDevices.find((device) => device.id === value);
      onSave(worker, selectedDevice);
    }
  };

  const fetchAvailableDevices = useCallback(async () => {
    const response = await devicesQueryRefetch();
    if (response.data?.success) {
      const availableDevicesResponse = response.data.devices.filter(
        (device) => device.assignable && isNil(device.assigned_worker?.id) && !device.archived_at
      );
      setAvailableDevices(availableDevicesResponse);
    } else WebHelper.showErrorMessage(WebHelper.formatMessage("EditableDevice-fetchDevicesError"), response.data?.correlationId);
  }, [devicesQueryRefetch]);

  const assignedDeviceItemOptions = useMemo(() => {
    const options = [
      getSelectOption("", WebHelper.formatMessage("WorkerDetail-noneOption")),
      ...availableDevices.map(({id, device_tag}) => getSelectOption(id, device_tag)),
    ];
    if (worker.assigned_device?.id) options.unshift(getSelectOption(worker.assigned_device.id, worker.assigned_device.device_tag));
    return options;
  }, [availableDevices, worker.assigned_device]);

  return editing ? (
    <Select
      defaultValue={worker.assigned_device?.id ?? ""}
      className={styles.select}
      loading={devicesQueryIsFetching}
      onChange={handleSave}
      options={assignedDeviceItemOptions}
      optionFilterProp="label"
      ref={selectRef}
      onBlur={toggleEdit}
      showSearch
      onDropdownVisibleChange={(open) => {
        if (open) fetchAvailableDevices();
      }}
      dropdownRender={(node) => (devicesQueryIsFetching ? WebHelper.LoadingSpin : node)}
    />
  ) : (
    <LinkButton
      onClick={() => {
        toggleEdit();
      }}>
      {worker.assigned_device?.device_tag ?? WebHelper.formatMessage("DepartmentWorkersTable-unassignedDeviceLabel")}
    </LinkButton>
  );
};

function getSelectOption(id: string | undefined, name: string) {
  return {key: id, label: name, value: id};
}
