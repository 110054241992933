import {Typography} from "antd";
import {LDFlagSet} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent} from "react";

import styles from "./SiteSummaryReportGroupCard.module.scss";
import * as Models from "../../../../../core/models";
import {WebHelper} from "../../../../utils/WebHelper";

type SiteSummaryReportGroupCardProps = {
  flags: LDFlagSet;
  targetUtilization: boolean;
  reportGroup: Models.WorkerReportGroup;
  reportGroupData?: Models.AnalyticsDashboardCard;
};

export const SiteSummaryReportGroupCard: FunctionComponent<SiteSummaryReportGroupCardProps> = ({
  flags,
  targetUtilization,
  reportGroup,
  reportGroupData,
}) => {
  return (
    <>
      {flags.target_utilization_hours && targetUtilization ? (
        <div className={`${styles.targetGroupCard} ${reportGroup.name.length > 20 ? styles.overflowTitle : ""}`}>
          <Typography.Text className={styles.overflowText}>{reportGroup.name}</Typography.Text>
          <div className={styles.targetWorkerStatus}>
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-usageHoursTargetUtilization")}</Typography.Text>
          </div>
          <div className={styles.targetStatusLabelContainer}>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-active")}</Typography.Text>
              <Typography.Text>{reportGroupData?.worker_status.interval_active_count}</Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>
                {WebHelper.formatMessage("SiteSummaryReportPDF-point")} {WebHelper.formatMessage("SiteSummaryReportPDF-onTarget")}
              </Typography.Text>
              <Typography.Text>{reportGroupData?.worker_status.meets_target_count}</Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>
                {WebHelper.formatMessage("SiteSummaryReportPDF-point")} {WebHelper.formatMessage("SiteSummaryReportPDF-aboveTarget")}
              </Typography.Text>
              <Typography.Text>{reportGroupData?.worker_status.above_target_count}</Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>
                {WebHelper.formatMessage("SiteSummaryReportPDF-point")} {WebHelper.formatMessage("SiteSummaryReportPDF-belowTarget")}
              </Typography.Text>
              <Typography.Text>{reportGroupData?.worker_status.below_target_count}</Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>
                {WebHelper.formatMessage("SiteSummaryReportPDF-point")} {WebHelper.formatMessage("SiteSummaryReportPDF-exempt")}
              </Typography.Text>
              <Typography.Text>
                {reportGroupData
                  ? reportGroupData.worker_status.exempt_target_count + reportGroupData.worker_status.unset_target_count
                  : "-"}
              </Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-inactive")}</Typography.Text>
              <Typography.Text>{reportGroupData?.worker_status.interval_inactive_count}</Typography.Text>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.nonTargetGroupCard}>
          <Typography.Text>{reportGroup.name}</Typography.Text>
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-workerStatusCounts")}</Typography.Text>
          <div className={styles.nonTargetStatusLabel}>
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-active")}</Typography.Text>
            <Typography.Text>{reportGroupData?.worker_status.interval_active_count}</Typography.Text>
          </div>
          <div className={styles.nonTargetStatusLabel}>
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-inactive")}</Typography.Text>
            <Typography.Text>{reportGroupData?.worker_status.interval_inactive_count}</Typography.Text>
          </div>
        </div>
      )}
    </>
  );
};
