import {Locale} from "./Locale";
import {en_US as baseCoreLocale} from "../../core/locales/en_US";
import {Locale as CoreLocale} from "../../core/locales/Locale";
import {CoreHelper} from "../../core/utils/CoreHelper";

const uiLocale: Omit<Locale, keyof CoreLocale> = {
  "AboutUs-appVersion": "App version: {version} ({currentCommitHash})",
  "AboutUs-backendVersion": "Backend version: {version} ({currentCommitHash})",
  "AboutUs-copyright": "Copyright © {year}",
  "AboutUs-fetchError": "An error occurred while attempting to get the backend version",
  "AboutUs-subtitle": "About Verve Logic",
  "AccountSettings-department": "Department:",
  "AccountSettings-email": "Email:",
  "AccountSettings-english": "English",
  "AccountSettings-firstName": "First Name:",
  "AccountSettings-french": "French",
  "AccountSettings-jobTitle": "Job Title:",
  "AccountSettings-lastName": "Last Name:",
  "AccountSettings-metricUnits": "Use metric units:",
  "AccountSettings-organization": "Organization:",
  "AccountSettings-organizationGlobal": "GLOBAL",
  "AccountSettings-phoneNumberError":
    "Phone number should start with the + sign and then only contain digits (including the country code).",
  "AccountSettings-preferredLanguage": "Preferred Language:",
  "AccountSettings-profileData": "Profile Data",
  "AccountSettings-spanish": "Spanish",
  "AccountSettings-subscribed": "Subscribe to Admin Email:",
  "AccountSettings-title": "Account Settings",
  "AccountSettings-updateError": "An error occurred while attempting to update the user data",
  "AccountSettings-updateErrorPhoneNumber": "Phone Number Invalid. Please double check and try again",
  "AccountSettings-workAddress": "Work Address:",
  "AccountSettings-workPhone": "Work Phone:",
  "ActiveInactiveSiteSummaryPage-activeWorkers": "Active Workers",
  "ActiveInactiveSiteSummaryPage-daysActiveLast7DaysColumnTitle": "Days Active Last 7 Days",
  "ActiveInactiveSiteSummaryPage-daysSinceLastUsageColumnTitle": "Days Since Last Usage",
  "ActiveInactiveSiteSummaryPage-deviceTagColumnTitle": "Device Tag",
  "ActiveInactiveSiteSummaryPage-employeeIDTitle": "Employee ID",
  "ActiveInactiveSiteSummaryPage-hoursLast7DaysColumnTitle": "Hours Last 7 Days",
  "ActiveInactiveSiteSummaryPage-inactiveWorkers": "Inactive Workers",
  "ActiveInactiveSiteSummaryPage-kilogramsUnit": "kg",
  "ActiveInactiveSiteSummaryPage-nameColumnTitle": "Name",
  "ActiveInactiveSiteSummaryPage-poundsUnit": "lbs",
  "ActiveInactiveSiteSummaryPage-relativeToTargetColumnTitle": "Relative to Target",
  "ActiveInactiveSiteSummaryPage-safeliftScore": "Safelift Score",
  "ActiveInactiveSiteSummaryPage-weeksSinceFirstUseColumnTitle": "Weeks Since First Use",
  "ActiveInactiveSiteSummaryPage-weightOffloaded": "Weight Offloaded",
  "ActiveInactiveWorkersReportDropdown-WorkersActivityReport": "Workers Activity Report",
  "ActiveInactiveWorkersReportDropdown-activeWorkers": "Active Workers",
  "ActiveInactiveWorkersReportDropdown-asPDF": "As PDF",
  "ActiveInactiveWorkersReportDropdown-asXLSX": "As XLSX",
  "ActiveInactiveWorkersReportDropdown-deviceTagColumnTitle": "Device Tag",
  "ActiveInactiveWorkersReportDropdown-daysActiveLast7DaysColumnTitle": "Days Active Last 7 Days",
  "ActiveInactiveWorkersReportDropdown-daysSinceLastUsageColumnTitle": "Days Since Last Usage",
  "ActiveInactiveWorkersReportDropdown-downloadData": "Download Data",
  "ActiveInactiveWorkersReportDropdown-employeeIDTitle": "Employee ID",
  "ActiveInactiveWorkersReportDropdown-hoursLast7DaysColumnTitle": "Hours Last 7 Days",
  "ActiveInactiveWorkersReportDropdown-inactiveWorkers": "Inactive Workers",
  "ActiveInactiveWorkersReportDropdown-nameColumnTitle": "Name",
  "ActiveInactiveWorkersReportDropdown-workerColumnTitle": "Worker",
  "ActiveInactiveWorkersReportDropdown-pageCount": "Page {currentPage} of {totalPages}",
  "ActiveInactiveWorkersReportDropdown-relativeToTargetColumnTitle": "Relative to Target",
  "ActiveInactiveWorkersReportDropdown-weeksSinceFirstUseColumnTitle": "Weeks Since First Use",
  "AddDepartmentForm-title": "Add department",
  "AddOrganizationForm-title": "Add organization",
  "AddSiteForm-title": "Add site",
  "AddWorkersModal-errorMessage": "An error occurred while attempting to update the worker group",
  "AddWorkersModal-noWorkersText": "No workers found",
  "AddWorkersModal-selectWorkersLabel": "Select workers to be added to this group:",
  "AddWorkersModal-successMessage": "Selected workers have been added successfully",
  "AddWorkersModal-title": "Add Workers",
  "AddWorkersModal-workers": "Workers:",
  "AllocateGateway-allocate": "Allocate",
  "AllocateGateway-allocateGatewayButton": "Allocate Gateway",
  "AllocateGateway-allocateGatewayLabel": "Allocate Gateway",
  "AllocateGateway-allocateGatewayModalMessage": "Choose the gateway serial number you would like to allocate to **{departmentName}**",
  "AllocateGateway-allocateGatewayModalTitle": "Allocate Gateway",
  "AllocateGateway-allocateGatewayToDepartmentError": "The Gateway could not be allocated",
  "AllocateGateway-gatewaySerialNumberLabel": "Gateway Serial #:",
  "AllocateSelectedData-confirmation": "Confirmation",
  "AllocateSelectedData-dateRange": "Date Range:",
  "AllocateSelectedData-finished": "Finished",
  "AllocateSelectedData-infoMeesage":
    "Dates are inclusive. Data from both the Start and End Dates will be allocated to the selected worker.",
  "AllocateSelectedData-inProgress": "In Progress",
  "AllocateSelectedData-modalTitle": "Allocate Selected Data",
  "AllocateSelectedData-select": "Select Date Range and Worker",
  "AllocateSelectedData-selectEndDate": "Select End Date",
  "AllocateSelectedData-selectText": "Please select the desired date range and worker to reallocate data of the device",
  "AllocateSelectedData-selectStartDate": "Select Start Date",
  "AllocateSelectedData-selectWorker": "Select Worker",
  "AllocateSelectedData-singleDay": "Single Day",
  "AllocateSelectedData-updateError": "An error occurred while attempting to update the worker assigned to the session",
  "AllocateSelectedData-updateSuccess": "Data of device {deviceTag} was successfully reallocated to worker {workerName}.",
  "AllocateSelectedData-waiting": "Waiting",
  "AllocateSelectedData-worker": "Worker:",
  "AllocateSelectedData-confirmationText":
    "Please confirm you would like to assign all data from the device **{deviceTag}** from **{startDate}** through **{endDate}** to **{workerName}**",
  "AllocationFailed-alreadyAllocatedToCurrentDepartmentError":
    "**{serialNumber}**: Device is already allocated to **{siteName}** - **{departmentName}**",
  "AllocationFailed-alreadyAllocatedToOtherDepartmentError": "**{serialNumber}**: Device is already allocated to another department",
  "AllocationFailed-emptyRowsError": "Rows with empty values were detected",
  "AllocationFailed-firstText": "No devices were allocated to **{siteName}** - **{departmentName}**",
  "AllocationFailed-missingColumnError": "The column {columnName} was not found in the uploaded file",
  "AllocationFailed-noRowsError": "No Serial Numbers were found in the uploaded file",
  "AllocationFailed-secondText": "The following errors occurred:",
  "AllocationFailed-serialNumberNotFoundError": "**{serialNumber}**: Serial Number not found",
  "AllocationFailed-subtitle": "Allocation failed",
  "AllWorkersReportDrawer-contentMessage":
    "The all workers report covers a 7 day period. Please select the desired end date for the report.",
  "AllWorkersReportDrawer-drawerTitle": "All Workers Report",
  "AllWorkersReportDrawer-fileName": "All Workers Report",
  "Analytics-csvColumn-assignedWorkers": "Assigned Workers",
  "Analytics-csvColumn-activeWorkers": "Active Workers",
  "Analytics-csvColumn-activeWorkersPercentage": "Active Workers Percentage",
  "Analytics-csvColumn-endDate": "End Date",
  "Analytics-csvColumn-endTime": "End Time",
  "Analytics-csvColumn-excessiveForwardLifts": "Excessive Forward Lifts",
  "Analytics-csvColumn-hoursUsed": "Hours Used",
  "Analytics-csvColumn-inactiveWorkers": "Inactive Workers",
  "Analytics-csvColumn-inactiveWorkersPercentage": "Inactive Workers Percentage",
  "Analytics-csvColumn-prolongedBendLifts": "Prolonged Bend Lifts",
  "Analytics-csvColumn-resourceID": "Resource ID",
  "Analytics-csvColumn-resourceName": "Resource Name",
  "Analytics-csvColumn-resourceType": "Resource Type",
  "Analytics-csvColumn-riskyLifts": "Risky Lifts",
  "Analytics-csvColumn-riskyLiftsPercentage": "Risky Lifts Percentage",
  "Analytics-csvColumn-safeLifts": "Safe Lifts",
  "Analytics-csvColumn-safeLiftsPercentage": "Safe Lifts Percentage",
  "Analytics-csvColumn-sideBendLifts": "Side Bend Lifts",
  "Analytics-csvColumn-startDate": "Start Date",
  "Analytics-csvColumn-startTime": "Start Time",
  "Analytics-csvColumn-totalLifts": "Total Lifts",
  "Analytics-csvColumn-twistedLifts": "Twisted Lifts",
  "Analytics-csvColumn-weightOffloaded": "Weight Offloaded",
  "Analytics-fetchDataError": "An error occurred while fetching the data",
  "Analytics-noOrgPlaceholderTitle": "Select an organization on the top of this page to start seeing analytics",
  "AnalyticsChart-averageOfLifts": "Average of Lifts per active worker",
  "AnalyticsChart-averageHoursUsed": "Average of Hours Used per active worker",
  "AnalyticsChart-weightOffloadedAverageKgs": "Average Weight Offloaded per active worker (kgs)",
  "AnalyticsChart-weightOffloadedAverageLbs": "Average Weight Offloaded per active worker (lbs)",
  "AnalyticsChart-datasetLabel-activeWorkers": "Active",
  "AnalyticsChart-datasetLabel-excessiveForwardBending": "Excessive Forward Bending",
  "AnalyticsChart-datasetLabel-hoursUsed": "Hours Used",
  "AnalyticsChart-datasetLabel-inactiveWorkers": "Inactive",
  "AnalyticsChart-datasetLabel-prolongedBending": "Prolonged Bending",
  "AnalyticsChart-datasetLabel-risky": "Risky",
  "AnalyticsChart-datasetLabel-safe": "Safe",
  "AnalyticsChart-datasetLabel-sideBending": "Side Bending",
  "AnalyticsChart-datasetLabel-target": "Target",
  "AnalyticsChart-datasetLabel-twisting": "Twisting",
  "AnalyticsChart-dataTypeSelector-label": "Data",
  "AnalyticsChart-dataTypeSelector-percentage": "Percentage",
  "AnalyticsChart-dataTypeSelector-raw": "Raw",
  "AnalyticsChart-dayDaysInSuit": "Day {day}",
  "AnalyticsChart-errorResultMessage": "Error Retrieving Data. Please refresh the page to try again",
  "AnalyticsChart-hoursUnit": "Hours Used",
  "AnalyticsChart-hoursUsed": "Hours Used",
  "AnalyticsChart-liftsUnit": "Lifts",
  "AnalyticsChart-percentageOfLifts": "% of Lifts",
  "AnalyticsChart-percentageOfWorkers": "% of Workers",
  "AnalyticsChart-monthDaysInSuit": "Month {month}",
  "AnalyticsChart-movementBreakdown": "Movement Breakdown",
  "AnalyticsChart-movementSelector": "Movements",
  "AnalyticsChart-poundsUnit": "lbs",
  "AnalyticsChart-quarterDaysInSuit": "Quarter {quarter}",
  "AnalyticsChart-tooltip-totalLifts": "Total: {total} lifts",
  "AnalyticsChart-tooltip-totalWorkers": "Assigned: {assigned} workers",
  "AnalyticsChart-weekDaysInSuit": "Week {week}",
  "AnalyticsChart-weightOffloaded": "Weight Offloaded",
  "AnalyticsChart-weightOffloadedUnitLbs": "Weight Offloaded (lbs)",
  "AnalyticsChart-weightOffloadedUnitKgs": "Weight Offloaded (kgs)",
  "AnalyticsChart-workersUnit": "Workers",
  "AnalyticsDetails-activeWorkers": "Active Workers",
  "AnalyticsDetails-allWorkers": "All Workers",
  "AnalyticsDetails-description": "Here's a detailed breakdown of the data for the interval of **{day}**",
  "AnalyticsDetails-downloadDataButton": "Download Data",
  "AnalyticsDetails-forwardColumn#": "Forward Bends #",
  "AnalyticsDetails-forwardColumn%": "Forward Bends %",
  "AnalyticsDetails-fullNameColumn": "Full Name",
  "AnalyticsDetails-inactiveWorkers": "Inactive Workers",
  "AnalyticsDetails-isActive": "Active",
  "AnalyticsDetails-movementBreakdown": "Movement Breakdown",
  "AnalyticsDetails-prolongedColumn#": "Prolonged Bends #",
  "AnalyticsDetails-prolongedColumn%": "Prolonged Bends %",
  "AnalyticsDetails-riskyColumn#": "Risky Lifts #",
  "AnalyticsDetails-riskyColumn%": "Risky Lifts %",
  "AnalyticsDetails-safeColumn#": "Safe Lifts #",
  "AnalyticsDetails-safeColumn%": "Safe Lifts %",
  "AnalyticsDetails-sideBendColumn#": "Side Bends #",
  "AnalyticsDetails-sideBendColumn%": "Side Bends %",
  "AnalyticsDetails-titleModal": "Data Details",
  "AnalyticsDetails-totalHours": "Total Hours",
  "AnalyticsDetails-totalLiftsColumn": "Total Lifts",
  "AnalyticsDetails-twistedColumn#": "Twists #",
  "AnalyticsDetails-twistedColumn%": "Twisting %",
  "AnalyticsDetails-weightOffloaded": "Weight Offloaded",
  "AnalyticsDetails-weightOffloadedColumn": "Total Weight Offloaded",
  "AnalyticsSettings-afterSpecificDate": "After Specific Date",
  "AnalyticsSettings-afterDate": "After Date:",
  "AnalyticsSettings-allWorkersLabel": "All Workers",
  "AnalyticsSettings-applyFilters": "Apply Configuration",
  "AnalyticsSettings-average": "Average",
  "AnalyticsSettings-beforeSpecificDate": "Before Specific Date",
  "AnalyticsSettings-beforeDate": "Before Date:",
  "AnalyticsSettings-betweenDates": "Between Dates",
  "AnalyticsSettings-calendarDate": "Calendar Days",
  "AnalyticsSettings-calendarDateDescription": "*Calendar Day/s: Graph data by the date and time it was recorded",
  "AnalyticsSettings-checkboxDescription": "Please select movements to display on graph.",
  "AnalyticsSettings-current": "Current",
  "AnalyticsSettings-data": "Data:",
  "AnalyticsSettings-dataFormat": "Data Format:",
  "AnalyticsSettings-dataScope": "Data Scope:",
  "AnalyticsSettings-dataScopeTextDaysInTheSuit": "Filter based on worker's first day in the suit.",
  "AnalyticsSettings-dataScopeDaysInTheSuit": "Display workers who started...",
  "AnalyticsSettings-dataScopeTimeOfDay": "Aggregate data recorded between",
  "AnalyticsSettings-dataScopeCalendarDays": "Graph all data...",
  "AnalyticsSettings-dateRange": "Date Range:",
  "AnalyticsSettings-dateRangeInfo": "Date Range and Data Scope filters workers whose started in the suit between these dates.",
  "AnalyticsSettings-day": "Day",
  "AnalyticsSettings-daysInSuit": "Days in the Suit",
  "AnalyticsSettings-daysInSuitDescription": "*Days in Suit: Graph data by the number of days workers have worn the suit",
  "AnalyticsSettings-displayInGraph": "Display in Graph",
  "AnalyticsSettings-displayMaxData": "Display Max Data:",
  "AnalyticsSettings-displayMaxDataTooltip": "Graphically represent data scope up to 30 intervals (30 days or 30 weeks)",
  "AnalyticsSettings-displayTrendlines": "Display Trendlines:",
  "AnalyticsSettings-eightHour": "8h",
  "AnalyticsSettings-emptyWorkerReportGroupsTooltip": "No Worker Report Groups found",
  "AnalyticsSettings-emptyWorkersTooltip": "No Workers found",
  "AnalyticsSettings-endDate": "To Date:",
  "AnalyticsSettings-entitySelectorTooltip": "You can select up to 5 entities",
  "AnalyticsSettings-excessiveForwardBending": "Excessive Forward B.",
  "AnalyticsSettings-fifteenMinutes": "15min",
  "AnalyticsSettings-filter": "Filter:",
  "AnalyticsSettings-fourHour": "4h",
  "AnalyticsSettings-hoursMinutes": "Hours/Minutes",
  "AnalyticsSettings-hoursUsed": "Hours Used",
  "AnalyticsSettings-individualWorkerLabel": "Individual Worker",
  "AnalyticsSettings-interval": "Interval:",
  "AnalyticsSettings-infoDateRange": "Graph is limited to 100 intervals or less.",
  "AnalyticsSettings-intervalCount": "Interval Count:",
  "AnalyticsSettings-lifts": "Lifts",
  "AnalyticsSettings-loadingFilters": "Loading filters...",
  "AnalyticsSettings-minimumLifts": "Minimum Lifts:",
  "AnalyticsSettings-minimumLiftsTooltip": "Does not graph intervals with less than the selected number lifts",
  "AnalyticsSettings-modalTextAfterDate":
    "The graph cannot display more than 100 intervals. To view data after {maxDateToShow}, please adjust the selected date range under Configure Graph.",
  "AnalyticsSettings-modalTextBeforeDate":
    "The graph cannot display more than 100 intervals. To view data before {maxDateToShow}, please adjust the selected date range under Configure Graph.",
  "AnalyticsSettings-month": "Month",
  "AnalyticsSettings-mostRecent": "Most recent",
  "AnalyticsSettings-movementBreakdown": "Movement Breakdown",
  "AnalyticsSettings-movements": "Movements:",
  "AnalyticsSettings-oneHour": "1h",
  "AnalyticsSettings-percentage": "Percentage",
  "AnalyticsSettings-percentOfTarget": "Percent Of Target",
  "AnalyticsSettings-prolongedBending": "Prolonged Bending",
  "AnalyticsSettings-quarter": "Quarter",
  "AnalyticsSettings-raw": "Raw",
  "AnalyticsSettings-rawSum": "Raw Sum",
  "AnalyticsSettings-realTimeDataInfo": "Sites without Real Time Clock enabled suits do not support sub-day reporting.",
  "AnalyticsSettings-relativeTo": "Relative to:",
  "AnalyticsSettings-risky": "Risky",
  "AnalyticsSettings-safe": "Safe",
  "AnalyticsSettings-select": "Please select",
  "AnalyticsSettings-selectDate": "Select  date:",
  "AnalyticsSettings-selectEndDate": "Select end date",
  "AnalyticsSettings-selectFiltersText": "Please select the desired set of filters to apply to the analytics graph",
  "AnalyticsSettings-selectStartDate": "Select start date",
  "AnalyticsSettings-selectVariableText": "Please select the variable to chart.",
  "AnalyticsSettings-selectVariableMinimumText": "Please select the variable to chart and specify the minimum values to be displayed.",
  "AnalyticsSettings-startDate": "From Date:",
  "AnalyticsSettings-sideBending": "Side Bending",
  "AnalyticsSettings-thirtyMinutes": "30min",
  "AnalyticsSettings-timeOfTheDay": "Time of the Day",
  "AnalyticsSettings-timeOfTheDayDescription":
    "*Time of Day: Aggregate and graph data by the time of day the data was recorded, regardless of the date (i.e. all data logged 9 and 10 am)",
  "AnalyticsSettings-timezoneMessage": "Dates/times are in timezone *{timezone}*",
  "AnalyticsSettings-title": "Configure Graph",
  "AnalyticsSettings-trendlineText": "Data for Trendline from {startDate} to {endDate}: **[y= m x+b]**",
  "AnalyticsSettings-twelveHour": "12h",
  "AnalyticsSettings-twisting": "Twisting",
  "AnalyticsSettings-twoHour": "2h",
  "AnalyticsSettings-variableToChart": "Variable to Chart:",
  "AnalyticsSettings-week": "Week",
  "AnalyticsSettings-weightOffloaded": "Weight Offloaded",
  "AnalyticsSettings-workerActiveStatus": "Worker Active Status",
  "AnalyticsSettings-workerReportGroupsLabel": "Worker Report Groups",
  "AnalyticsSettings-workers": "Workers:",
  "AnalyticsSubheader-downloadDataButton": "Download Data",
  "AnalyticsSubheader-loadingOrganizations": "Loading organizations...",
  "AnalyticsSubheader-selectOrganization": "Select an organization...",
  "AnalyticsSubheader-title": "Analytics",
  "AnalyticsSubheader-titleSeparator": "/",
  "AnalyticsTabContent-activeWorkers": "Active Workers",
  "AnalyticsTabContent-asCsv": "As CSV",
  "AnalyticsTabContent-asImage": "As Image",
  "AnalyticsTabContent-averageMessage": "Average {unit} Per {interval}: **{value}**",
  "AnalyticsTabContent-day": "Day",
  "AnalyticsTabContent-errorMessage": "An error occurred while fetching the data",
  "AnalyticsTabContent-errorTimeoutMessage": "Request timed out. Please try to modify your query to request less data.",
  "AnalyticsTabContent-filtersConfiguration": "Configure Graph",
  "AnalyticsTabContent-hideFiltersLabel": "Hide Filters",
  "AnalyticsTabContent-hoursUnit": "hours",
  "AnalyticsTabContent-liftsUnit": "lifts",
  "AnalyticsTabContent-poundsUnit": "lbs",
  "AnalyticsTabContent-kilogramsUnit": "kgs",
  "AnalyticsTabContent-month": "Month",
  "AnalyticsTabContent-quarter": "Quarter",
  "AnalyticsTabContent-showAllFiltersLabel": "Show All Filters",
  "AnalyticsTabContent-totalValueMessage": "Total {variable} by selected date range: **{value} {unit}**",
  "AnalyticsTabContent-week": "Week",
  "App-accountSettings": "Account Settings",
  "App-deactivatedUserSubtitle1": "Your account has been deactivated.",
  "App-deactivatedUserSubtitle2": "If you think this was done by mistake please contact your site administrator.",
  "App-deactivatedUserTitle": "Account Deactivated",
  "App-deviceManagement": "Device Management",
  "App-fetchMeError": "An error occurred while fetching the user data",
  "App-internetError": "No internet connection, please refresh the page to try again",
  "App-logOut": "Log Out",
  "App-menuItemAboutUs": "About Us",
  "App-menuItemAdmin": "Admin",
  "App-menuItemAnalytics": "Analytics",
  "App-menuItemAssignment": "Assignment",
  "App-menuItemConfiguration": "Configuration",
  "App-menuItemDashboard": "Dashboard",
  "App-menuItemDiagnostics": "Diagnostics",
  "App-menuItemHelpCenter": "Help Center",
  "App-userPendingApprovalMessage1": "Your site administrator has not yet approved your account, this step may take a day or two.",
  "App-userPendingApprovalMessage2": "If you have any questions, please email your site administrator.",
  "App-userPermissions": "User Permissions",
  "BulkCreateDevicesModal-configuration": "Configuration",
  "BulkCreateDevicesModal-confirm": "Confirm",
  "BulkCreateDevicesModal-confirmation": "Confirmation",
  "BulkCreateDevicesModal-errorMessage": "An error occurred while updating the devices, try again later",
  "BulkCreateDevicesModal-finished": "Finished",
  "BulkCreateDevicesModal-inProgress": "In Progress",
  "BulkCreateDevicesModal-modalTitle": "Bulk Device Creation, Configure and Allocate",
  "BulkCreateDevicesModal-next": "Next",
  "BulkCreateDevicesModal-selectDevices": "Select Devices",
  "BulkCreateDevicesModal-successMessage": "Bulk device creation completed successfully",
  "BulkCreateDevicesModal-waiting": "Waiting",
  "BulkCreateStepThree-department": "Department:",
  "BulkCreateStepThree-loadingOrganizations": "Loading organizations...",
  "BulkCreateStepThree-selectDepartment": "Select a single Department to allocate devices.",
  "BulkCreateStepThree-none": "None",
  "BulkCreateStepThree-selectOrganization": "Select an organization...",
  "BulkCreateStepTwo-button1": "Button 1:",
  "BulkCreateStepTwo-button2": "Button 2:",
  "BulkCreateStepTwo-button3": "Button 3:",
  "BulkCreateStepTwo-firmwareVersion": "Firmware Version:",
  "BulkCreateStepTwo-groupCount": "[{count} Devices]: ",
  "BulkCreateStepTwo-groupName": "Group {count}",
  "BulkCreateStepTwo-groupsMessage": "We’ve identified the following groups of devices based on their device type:",
  "BulkCreateStepTwo-noButtonSettings": "No Button Settings found",
  "BulkCreateStepTwo-selectSettingsMessage": "Please select the settings you would like to update the device to:",
  "BulkCreateStepTwo-typeCount": "{name} [{count} Devices]",
  "BulkCreateStepOne-csvRadioText": "Upload list of SNs via CSV file Columns: System Serial Number, Mcu ID",
  "BulkCreateStepOne-mcuIDColumnName": "Mcu ID",
  "BulkCreateStepOne-selectDevices": "Select Device SNs to Create",
  "BulkCreateStepOne-serialNumberColumnName": "System Serial Number",
  "BulkCreateStepOne-uploadFailDevicesTitle": "Not all devices in CSV are available to create.",
  "BulkCreateStepOne-uploadFailText": "CSV file not formatted properly please fix and try again.",
  "BulkCreateStepOne-uploadFailTitle": "Upload Fail",
  "BulkUpdateDevicesModal-configuration": "Configuration",
  "BulkUpdateDevicesModal-confirm": "Confirm",
  "BulkUpdateDevicesModal-confirmation": "Confirmation",
  "BulkUpdateDevicesModal-errorMessage": "An error occurred while updating the devices, try again later",
  "BulkUpdateDevicesModal-finished": "Finished",
  "BulkUpdateDevicesModal-inProgress": "In Progress",
  "BulkUpdateDevicesModal-modalTitle": "Bulk Update Devices",
  "BulkUpdateDevicesModal-next": "Next",
  "BulkUpdateDevicesModal-selectDevices": "Select Devices",
  "BulkUpdateDevicesModal-successMessage": "Device configuration update was successfully scheduled.",
  "BulkUpdateDevicesModal-waiting": "Waiting",
  "BulkUpdateStepOne-allDevicesText": "All devices at given Org/Site/Department",
  "BulkUpdateStepOne-allOrgs": "All organizations",
  "BulkUpdateStepOne-devices": "Devices:",
  "BulkUpdateStepOne-loadingOrganizations": "Loading organizations...",
  "BulkUpdateStepOne-locations": "Locations:",
  "BulkUpdateStepOne-manualDevicesText": "Select a Org/Site/Department then manually enter a subset of SNs to update",
  "BulkUpdateStepOne-noDevicesText": "No devices for this department.",
  "BulkUpdateStepOne-selectDevices": "Select devices to update.",
  "BulkUpdateStepOne-selectOrganization": "Select an organization...",
  "BulkUpdateStepThree-button1": "Button 1:",
  "BulkUpdateStepThree-button2": "Button 2:",
  "BulkUpdateStepThree-button3": "Button 3:",
  "BulkUpdateStepThree-firmwareVersion": "Firmware Version:",
  "BulkUpdateStepThree-groupCount": "[{count} Devices]: ",
  "BulkUpdateStepThree-groupName": "Group {count}",
  "BulkUpdateStepThree-groupsMessage": "We’ve identified the following groups of devices based on their device type:",
  "BulkUpdateStepThree-noButtonSettings": "No Button Settings found",
  "BulkUpdateStepThree-selectSettingsMessage": "Please select the settings you would like to update the device to:",
  "BulkUpdateStepThree-typeCount": "{name} [{count} Devices]",
  "BulkUpdateStepTwo-confirmUpdateAll": "Please confirm you would like to update ALL devices in the following locations:",
  "ChangeDeviceModal-assignDevice": "Assign Device",
  "ChangeDeviceModal-assignDeviceMessage": "Are you sure you want to assign **{workerName}** device **{deviceTag}**?",
  "ChangeDeviceModal-assignDeviceSuccessMessage": "Device {deviceTag} was successfully assigned to {workerName}",
  "ChangeDeviceModal-assignError": "An error ocurred while trying to update the worker",
  "ChangeDeviceModal-changeDevice": "Change Device",
  "ChangeDeviceModal-changeDeviceMessage":
    "Are you sure you want to change device **{deviceTag}** to **{newDeviceTag}** for worker **{workerName}**?",
  "ChangeDeviceModal-unassignDevice": "Unassign Device",
  "ChangeDeviceModal-unassignDeviceMessage": "Are you sure you want to unassign device **{deviceTag}** from worker **{workerName}**?",
  "ChangeWorkerModal-assignWorker": "Assign Worker",
  "ChangeDeviceStatusModal-archive": "Archive:",
  "ChangeDeviceStatusModal-archiveError": "An error occurred while attempting to archive the device",
  "ChangeDeviceStatusModal-archived": "Archived",
  "ChangeDeviceStatusModal-archivedTrueMessage":
    "Changing device to **Archived: True** will make the device visible only from Device Management section.",
  "ChangeDeviceStatusModal-changeStatusError": "An error occurred while attempting to change the status",
  "ChangeDeviceStatusModal-descriptionToInService": "Are you sure you want to change **{deviceTag}** status to **In Service**?",
  "ChangeDeviceStatusModal-descriptionToOutService": "Are you sure you want to change **{deviceTag}** status to **Out Of Service**?",
  "ChangeDeviceStatusModal-notArchived": "Not Archived",
  "ChangeDeviceStatusModal-successMessageToInService": "Device {deviceTag} status was successfully changed to In Service",
  "ChangeDeviceStatusModal-successMessageToOutService": "Device {deviceTag} status was successfully changed to Out Of Service",
  "ChangeDeviceStatusModal-successMessageToOutServiceAndArchived":
    "Device {deviceTag} status was successfully changed to Out Of Service and Archived",
  "ChangeDeviceStatusModal-title": "Change Device Status",
  "ChangeWorkerModal-assignWorkerMessage": "Are you sure you want to assign **{workerName}** to device **{deviceTag}**?",
  "ChangeWorkerModal-assignWorkerSuccessMessage": "Worker {workerName} was successfully assigned to device {deviceTag}",
  "ChangeWorkerModal-assignError": "An error ocurred while trying to update the device",
  "ChangeWorkerModal-changeWorker": "Change Worker",
  "ChangeWorkerModal-changeWorkerMessage":
    "Are you sure you want to change worker **{workerName}** to **{newWorkerName}** for device **{deviceTag}**?",
  "ChangeWorkerModal-unassignWorker": "Unassign Worker",
  "ChangeWorkerModal-unassignWorkerMessage": "Are you sure you want to unassign worker **{workerName}** from device **{deviceTag}**?",
  "ChangeWorkerStatusModal-changeStatusError": "An error occurred while attempting to change the status",
  "ChangeWorkerStatusModal-descriptionEnroll": "Are you sure you want to change the status of worker **{worker}** to **Enrolled**?",
  "ChangeWorkerStatusModal-descriptionUnenroll": "Are you sure you want to change the status of worker **{worker}** to **Unenrolled**?",
  "ChangeWorkerStatusModal-reasonsLabel": "Reason:",
  "ChangeWorkerStatusModal-successMessageEnrolled": "Worker {worker} status was successfully changed to Enrolled",
  "ChangeWorkerStatusModal-successMessageUnenrolled": "Worker {worker} status was successfully changed to Unenrolled",
  "ChangeWorkerStatusModal-title": "Change Worker Status",
  "ControllerSettings-heavyLiftHigh": "Heavy Lift High",
  "ControllerSettings-heavyLiftLow": "Heavy Lift Low",
  "ControllerSettings-heavyLiftMedium": "Heavy Lift Medium",
  "ControllerSettings-squatLiftHigh": "Squat Lift High",
  "ControllerSettings-squatLiftMedium": "Squat Lift Medium",
  "ControllerSettings-squatLiftTransparent": "Squat Lift Transparent",
  "ControllerSettings-universalLiftHigh": "Universal Lift High",
  "ControllerSettings-universalLiftMedium": "Universal Lift Medium",
  "ControllerSettings-universalLiftTransparent": "Universal Lift Transparent",
  "ControllerSettings-universalLowerHigh": "Universal Lower High",
  "ControllerSettings-universalLowerMedium": "Universal Lower Medium",
  "ControllerSettings-universalLowerTransparent": "Universal Lower Transparent",
  "ControllerSettings-floorAssembly70": "Floor Assembly 70",
  "ControllerSettings-floorAssembly90": "Floor Assembly 90",
  "ControllerSettings-floorAssembly110": "Floor Assembly 110",
  "ControllerSettings-floorAssembly130": "Floor Assembly 130",
  "ControllerSettings-floorAssembly150": "Floor Assembly 150",
  "ControllerSettings-floorAssembly170": "Floor Assembly 170",
  "ControllerSettings-floorAssembly190": "Floor Assembly 190",
  "ControllerSettings-floorAssemblyTransparent": "Floor Assembly Transparent",
  "Common-accept": "Accept",
  "Common-add": "Add",
  "Common-addToFavorites": "Add To Favorites",
  "Common-and": "and",
  "Common-area": "Area",
  "Common-at": "at",
  "Common-back": "Back",
  "Common-below": "Below",
  "Common-between": "between",
  "Common-cancel": "Cancel",
  "Common-change": "Change",
  "Common-close": "Close",
  "Common-closeFormAlertMessage": "Are you sure you want to discard your changes without saving?",
  "Common-closeFormAlertTitle": "Discard Changes",
  "Common-confirm": "Confirm",
  "Common-confirmation": "Please type **{confirmationValue}** to confirm:",
  "Common-copyErrorCode": "Copy error code",
  "Common-create": "Create",
  "Common-created": "Created",
  "Common-createOptionsManually": "Manually",
  "Common-createOptionsUploadCSV": "Upload CSV",
  "Common-dateFormatCommonMonthDayYear": "MM-dd-yy",
  "Common-dateFormatMonthDay": "MM/dd",
  "Common-dateFormatMonthDayOnly": "MMM dd",
  "Common-dateFormatMonthDayYear": "MMM dd, yyyy",
  "Common-dateFormatMonthYear": "MMM yyyy",
  "Common-dateFormatQuarterYear": "QQQ yyyy",
  "Common-dateFormatTimeOnly": "hh:mm a",
  "Common-dateFormatWithTimezone": "M/d/yyyy h:mm a zz",
  "Common-dateFormatWithTimezoneNoTime": "M/d/yyyy",
  "Common-dateTimeFormatWithoutTimezone": "yyyy-MM-dd'T'H:mm:ss.SSS'Z'",
  "Common-dateTimeFormatWithoutYear": "MMM dd, hh:mm a",
  "Common-dayAbbreviation": "d",
  "Common-deallocateConfirmationValue": "deallocate",
  "Common-delete": "Delete",
  "Common-deleteConfirmationValue": "delete",
  "Common-description": "Description",
  "Common-discard": "Discard",
  "Common-draggerText": "Click or drag file to this area to upload",
  "Common-duration": "Duration",
  "Common-edit": "Edit",
  "Common-email": "Email",
  "Common-enrolledTooltip": "Enrolled",
  "Common-exempt": "Exempt",
  "Common-exportToCsv": "Export to CSV",
  "Common-extension": "Extension",
  "Common-false": "False",
  "Common-fieldRequiredMessage": "This field is required",
  "Common-fieldUrlFormatMessage": "Enter a valid url",
  "Common-firstName": "First name",
  "Common-gatewayStatusDown": "Offline",
  "Common-gatewayStatusIntermittent": "Intermittent",
  "Common-gatewayStatusNeverSeen": "Never Seen",
  "Common-gatewayStatusUp": "Online",
  "Common-hourAbbreviation": "h",
  "Common-info": "Information",
  "Common-lastName": "Last name",
  "Common-level": "Level",
  "Common-loadingText": "Loading...",
  "Common-meets": "Meets",
  "Common-minuteAbbreviation": "m",
  "Common-name": "Name",
  "Common-next": "Next",
  "Common-no": "No",
  "Common-none": "None",
  "Common-overused": "Overused",
  "Common-remove": "Remove",
  "Common-removeConfirmationValue": "remove",
  "Common-removeFromFavorites": "Remove From Favorites",
  "Common-restart": "Restart",
  "Common-role": "Role",
  "Common-save": "Save",
  "Common-search": "Search",
  "Common-secondAbbreviation": "s",
  "Common-select": "Select",
  "Common-signal": "Signal",
  "Common-source": "Source",
  "Common-status": "Status",
  "Common-title": "Title",
  "Common-to": "to",
  "Common-true": "True",
  "Common-tryAgain": "Try Again",
  "Common-typeHere": "Type here",
  "Common-unassignedLabel": "--",
  "Common-unenrolledTooltip": "Unenrolled",
  "Common-unset": "Unset",
  "Common-warning": "Warning",
  "Common-yes": "Yes",
  "Configuration-configurationPath": "Configuration",
  "Configuration-organizationsPath": "Organizations",
  "ConnectedColumnRender-connected": "Connected",
  "ConnectedColumnRender-neverSeen": "Never Seen",
  "CreateDeviceModal-createDeviceError": "An error occurred while attempting to create the device",
  "CreateDeviceModal-createDeviceSuccess": "Device created successfully",
  "CreateDeviceModal-deviceTagLabel": "Device Tag",
  "CreateDeviceModal-deviceTagValidatorMessage": "Device Tag must include only upper case letters or numbers",
  "CreateDeviceModal-deviceTypeLabel": "Device Type",
  "CreateDeviceModal-duplicateDeviceError":
    "A non-archived device with the same System Serial Number or MCU ID already exists in the system",
  "CreateDeviceModal-fetchDeviceTypesError": "An error occurred while attempting to fetch the available device types",
  "CreateDeviceModal-mcuIDLabel": "MCU ID",
  "CreateDeviceModal-mcuIDValidatorMessage": "MCU ID must be a valid UUID",
  "CreateDeviceModal-modalTitle": "Create Device",
  "CreateDeviceModal-okButtonText": "Create",
  "CreateDeviceModal-systemSerialNumberLabel": "System Serial #",
  "CreateDeviceModal-systemSerialNumberValidatorMessage":
    "System Serial # must be 13 characters long and only include numbers or upper case letters",
  "CreateWorkersReportGroupModal-createError": "An error occurred while attempting to create the workers report group",
  "CreateWorkersReportGroupModal-successMessage": "Worker Report Group created successfully",
  "CreateWorkerReportGroupModal-groupNameLabel": "Group Name:",
  "CreateWorkerReportGroupModal-nameAlreadyExistsValidation": "A group with this name already exists in site {siteName}",
  "CreateWorkerReportGroupModal-nameLengthValidator": "Group names are limited to 40 characters. Please shorten the name.",
  "CreateWorkerReportGroupModal-noWorkersText": "No workers found",
  "CreateWorkerReportGroupModal-selectWorkersLabel": "Select workers to be added to this group:",
  "CreateWorkerReportGroupModal-title": "Create Group",
  "CreationFailed-duplicatedValuesError": "The file contains duplicated values",
  "CreationFailed-employeeIDAlreadyExistsError":
    "**{firstName} {lastName} ({employeeID})**: Employee ID already exists within **{siteName}**",
  "CreationFailed-emptyRowsError": "Rows with empty values were detected",
  "CreationFailed-firstText": "No workers were created for **{siteName}** - **{departmentName}**",
  "CreationFailed-missingColumnError":
    "One or more of the columns (First Name, Last Name and/or Employee ID) were not found in the uploaded file",
  "CreationFailed-nameAlreadyExistsError": "**{firstName} {lastName} ({employeeID})**: Worker name already exists within **{siteName}**",
  "CreationFailed-nameAlreadyExistsErrorWithoutID": "**{firstName} {lastName}**: Worker name already exists within **{siteName}**",
  "CreationFailed-noRowsError": "No workers were found in the uploaded file",
  "CreationFailed-notEnoughInfoError": "**{firstName} {lastName} ({employeeID})**: Profile Incomplete",
  "CreationFailed-secondText": "The following errors occurred:",
  "CreationFailed-subtitle": "Creation Failed",
  "CreationFailedOld-firstText": "No workers were created for **{siteName}** - **{departmentName}**",
  "CreationSuccessful-creationManyText": "{numberOfWorkers} Workers created for **{siteName}** - **{departmentName}**",
  "CreationSuccessful-creationOneText": "1 Worker created for **{siteName}** - **{departmentName}**",
  "CreationSuccessful-title": "Creation Successful",
  "CSVDeviceAllocation-columns": "Columns: System Serial Number",
  "CSVDeviceAllocation-description":
    "Please upload a CSV file of system serial numbers you want to allocate to **{siteName}** - **{departmentName}**.",
  "CSVDeviceAllocation-firstSubtitle":
    "Please upload a CSV file of system serial numbers you want to allocate to **{siteName}** - **{departmentName}**",
  "CSVDeviceAllocation-secondSubtitle": "Columns: System Serial Number",
  "CSVDeviceAllocation-serialNumberColumnName": "System Serial Number",
  "CSVWorkerCreation-employeeID": "Employee ID",
  "CSVWorkerCreation-firstName": "First Name",
  "CSVWorkerCreation-firstSubtitle": "Please upload a CSV file of workers you wish to create in **{siteName}** - **{departmentName}**",
  "CSVWorkerCreation-lastName": "Last Name",
  "CSVWorkerCreation-secondSubtitle": "Columns: First Name, Last Name, Employee ID",
  "Dashboard-activeLabel": "Active",
  "Dashboard-dashboardPath": "Dashboard",
  "Dashboard-deviceStatusSubtitle": "Last 7 Days",
  "Dashboard-deviceStatusTitle": "Device Status",
  "Dashboard-fetchDataError": "An error occurred while attempting to get the dashboard data",
  "Dashboard-inactiveLabel": "Inactive",
  "Dashboard-outOfServiceLabel": "Out of Service",
  "Dashboard-sitesListEndMessage": "No more sites to display",
  "Dashboard-totalUsageTitle": "Total Usage Hours",
  "Dashboard-unassignedLabel": "Unassigned",
  "DashboardCardUsageChart-defaultTitle": "Total Usage Hours",
  "DashboardCardUsageChart-workerReportGroupsTitle": "Average Hours Per Employee With A Device",
  "DashboardCardWrapper-errorMessage": "An error occurred while fetching the data",
  "DashboardCardWrapper-errorResultMessage": "Error Retrieving Data. Please refresh the page to try again",
  "DashboardChildrenCardsSection-workerReportGroups": "{numberOfGroups, plural, =1 {Worker Report Group} other {Worker Report Groups}}",
  "DashboardDevicesListsDrawer-csv": "{status}.csv",
  "DashboardDevicesListsDrawer-deviceTagTitle": "Device Tag",
  "DashboardDevicesListsDrawer-devicesCount": "{devices} Devices",
  "DashboardDevicesListsDrawer-devicesCountTitleAssigned": "Assigned:",
  "DashboardDevicesListsDrawer-devicesCountTitleAvailable": "Available:",
  "DashboardDevicesListsDrawer-devicesCountTitleOutOfService": "Out of Service:",
  "DashboardDevicesListsDrawer-fetchDevicesError": "An error occurred while attempting to get the devices data",
  "DashboardDevicesListsDrawer-titleAssigned": "Assigned Devices",
  "DashboardDevicesListsDrawer-titleAvailable": "Available Devices",
  "DashboardDevicesListsDrawer-titleOutOfService": "Out of Service Devices",
  "DashboardDevicesListsDrawer-workerNameTitle": "Worker Name",
  "DashboardItem-chartsClickText": "Clicking on the charts will navigate to the Site's Analytics",
  "DashboardItem-errorResultMessage": "Error retrieving the site data",
  "DashboardSiteDetails-fetchDataError": "An error occurred while attempting to get the site data",
  "DashboardTabContent-activeWorkers": "Active Workers",
  "DashboardTabContent-demoReport": "Demo Report",
  "DashboardTabContent-departments": "{numberOfDepartments, plural, =0 {Departments} =1 {Department} other {Departments}}",
  "DashboardTabContent-fetchWorkerReportGroupsDataError": "An error occurred while attempting to get the worker report groups data",
  "DashboardTabContent-inactiveWorkers": "Inactive Workers",
  "DashboardTabContent-sites": "{numberOfSites, plural, =0 {Sites} =1 {Site} other {Sites}}",
  "DashboardTabContent-siteSummaryReport": "Site Summary Report",
  "DashboardTabContent-summaryReport": "Summary Report",
  "DashboardDailyUsageBreakdownSection-dailyTotalRowTitle": "Daily Total",
  "DashboardDailyUsageBreakdownSection-downloadCSV": "Download CSV",
  "DashboardDailyUsageBreakdownSection-endDateLabel": "End Date of Week",
  "DashboardDailyUsageBreakdownSection-title": "Daily Usage Breakdown",
  "DashboardDailyUsageBreakdownSection-weeklyTotal": "Weekly Total",
  "DashboardDailyUsageBreakdownSection-worker": "Worker",
  "DashboardWorkersListsDrawer-csv": "{status}.csv",
  "DashboardWorkersListsDrawer-fetchWorkersError": "An error occurred while attempting to get the workers data",
  "DashboardWorkersListsDrawer-titleActive": "Active Workers",
  "DashboardWorkersListsDrawer-titleInactive": "Inactive Workers",
  "DashboardWorkersListsDrawer-titleWaitlist": "Workers Waitlist",
  "DashboardWorkersListsDrawer-workerNameTitle": "Worker Name",
  "DashboardWorkersListsDrawer-workersCount": "{workers} Workers",
  "DashboardWorkersListsDrawer-workersCountTitleActive": "Active:",
  "DashboardWorkersListsDrawer-workersCountTitleInactive": "Inactive:",
  "DashboardWorkersListsDrawer-workersCountTitleWaitlist": "Waitlist:",
  "DashboardWorkersSection-asPDF": "as PDF",
  "DashboardWorkersSection-asXLSX": "as XLSX",
  "DashboardWorkersTable-7DaysColumnTooltip": "Data from {dateFrom} to {dateTo} (does not include data from today)",
  "DashboardWorkersTable-7HoursColumnTargetTooltip":
    "Target Utilization data is based on departmet configuration and assumes a 5 day work week.",
  "DashboardWorkersTable-7HoursColumnTooltip": "Usage Hours Data from {dateFrom} to {dateTo} (does not include data from today)",
  "DashboardWorkersTable-activeWorkersTitle": "Active Workers List",
  "DashboardWorkersTable-assignedDeviceColumnTitle": "Device Tag",
  "DashboardWorkersTable-dataUploadedTodayColumnTitle": "Data Uploaded Today",
  "DashboardWorkersTable-daysActiveLast7DaysColumnTitle": "Days Active Last 7 Days",
  "DashboardWorkersTable-daysSinceLastUsage": "{days} days",
  "DashboardWorkersTable-daysSinceLastUsageColumnTitle": "Days Since Last Use",
  "DashboardWorkersTable-disabledDeviceColumnValue": "Device cannot be changed for Unenrolled workers",
  "DashboardWorkersTable-downloadData": "Download data",
  "DashboardWorkersTable-employeeIDTitle": "Employee ID",
  "DashboardWorkersTable-errorFetchingWorkers": "Error fetching workers",
  "DashboardWorkersTable-hoursLast7DaysColumnTitle": "Hours Last 7 Days",
  "DashboardWorkersTable-inactiveWorkersTitle": "Inactive Workers List",
  "DashboardWorkersTable-nameColumnTitle": "Name",
  "DashboardWorkersTable-relativeToTargetColumnTitle": "Relative to Target",
  "DashboardWorkersTable-statusColumnTitle": "Status",
  "DashboardWorkersTable-targetDeviceUtilization": "Target Device Utilization",
  "DashboardWorkersTable-unassignedDeviceLabel": "--",
  "DashboardWorkersTable-weeksSinceFirstUseColumnTitle": "Weeks Since First Use",
  "DashboardWorkersTable-workerActiveToday": "Worker Active Today",
  "DashboardWorkersTable-WorkersActivityReport": "WorkersActivityReport",
  "DashboardWorkersTable-workerColumnName": "Worker",
  "DashboardWorkersMetric-bottomFive": "Bottom 5",
  "DashboardWorkersMetric-departmentAverage": "Department Average",
  "DashboardWorkersMetric-leaderboardReport": "Leaderboard report",
  "DashboardWorkersMetric-poundsUnit": "lbs",
  "DashboardWorkersMetric-kilogramsUnit": "kgs",
  "DashboardWorkersMetric-safeLiftScore": "SafeLift Score",
  "DashboardWorkersMetric-topWorkers": "Top {qty}",
  "DashboardWorkersMetric-bottomWorkers": "Bottom {qty}",
  "DashboardWorkersMetric-weightOffloaded": "Weight Offloaded",
  "DashboardWorkersMetric-workerMetrics": "Worker Metrics",
  "DatePicker-dateFilterCurrentMoth": "Current month",
  "DatePicker-dateFilterLastDays": "Last {daysDisplayed} days",
  "DatePicker-dateFilterToday": "Today",
  "DatePicker-dateFilterYesterday": "Yesterday",
  "DateRange-dateFilterInputRequiredRule": "You must select a date range",
  "DemoReportDrawer-contentMessage": "The demo report covers a 7 day period. Please select the desired end date for the report.",
  "DemoReportDrawer-drawerTitle": "Demo Report",
  "DemoReportDrawer-fileName": "Demo Report",
  "DepartmentDevicesTable-daysSinceLastUsedColumnTitle": "Last Seen",
  "DepartmentDevicesTable-deviceTagColumnTitle": "Device Tag",
  "DepartmentDevicesTable-disabledWorkerColumnValue": "Worker cannot be changed for Out of Service devices",
  "DepartmentDevicesTable-inServiceTooltip": "In Service",
  "DepartmentDevicesTable-outOfServiceTooltip": "Out of Service",
  "DepartmentDevicesTable-statusColumnTitle": "Status",
  "DepartmentDevicesTable-workerColumnTitle": "Worker",
  "DepartmentDetail-allocateCSVButtonText": "Upload CSV",
  "DepartmentDetail-allocateManualButtonText": "Allocate",
  "DepartmentDetail-deleteAlertMessage": "Are you sure you want to delete this department?",
  "DepartmentDetail-deleteAlertTitle": "Delete Department",
  "DepartmentDetail-deleteError": "The department could not be deleted",
  "DepartmentDetail-devices": "Devices",
  "DepartmentDetail-differentSiteError": "This department belongs to another site",
  "DepartmentDetail-fetchDataError": "An error occurred while attempting to get the department data",
  "DepartmentDetail-notFoundError": "Department not found",
  "DepartmentDetail-updateError": "An error occurred while attempting to update the department data",
  "DepartmentDetail-workerCreationCSVButtonText": "Upload CSV",
  "DepartmentDetail-workerCreationManualButtonText": "Add",
  "DepartmentDetail-workers": "Workers",
  "DepartmentForm-createError": "An error occurred while attempting to create the department",
  "DepartmentForm-nameInputLabel": "Name",
  "DepartmentGatewaysDetail-deallocate": "Deallocate",
  "DepartmentGatewaysDetail-deallocateAlertMessage":
    "Are you sure you want to deallocate gateway **{gatewaySerialNumber}** from department **{departmentName}**?",
  "DepartmentGatewaysDetail-deallocateAlertTitle": "Deallocate gateway",
  "DepartmentGatewaysDetail-deallocateError": "The gateway could not be deallocated",
  "DepartmentGatewaysDetail-fetchGatewaysError": "An error occurred while attempting to get the gateways data",
  "DepartmentGatewaysDetail-gatewayItemFirmware": "Gateway Firmware:",
  "DepartmentGatewaysDetail-gatewayItemLastTimeConnected": "Last Time Connected:",
  "DepartmentGatewaysDetail-gatewayItemStatus": "Status:",
  "DepartmentGatewaysDetail-gatewayStatusDefault": "Unknown",
  "DepartmentGatewaysDetail-title": "Gateway",
  "DepartmentModal-createError": "An error occurred while attempting to create the department",
  "DepartmentModal-createTitle": "Create Department",
  "DepartmentModal-deleteButtonText": "Delete Department",
  "DepartmentModal-deleteError": "The department could not be deleted",
  "DepartmentModal-editTitle": "Edit Department",
  "DepartmentModal-updateError": "An error occurred while attempting to update the department",
  "DepartmentsTable-devicesColumnTitle": "# Devices",
  "DepartmentsTable-nameColumnTitle": "Department Name",
  "DepartmentWorkersTable-assignedDeviceColumnTitle": "Assigned Device",
  "DepartmentWorkersTable-daysSinceFirstUsageColumnTitle": "Days Since First Usage",
  "DepartmentWorkersTable-daysSinceLastUsageColumnTitle": "Days Since Last Usage",
  "DepartmentWorkersTable-deviceColumnTitle": "Device",
  "DepartmentWorkersTable-disabledDeviceColumnValue": "Device cannot be changed for Unenrolled workers",
  "DepartmentWorkersTable-employeeIDColumnTitle": "Employee ID",
  "DepartmentWorkersTable-enrolledColumnTitle": "Enrolled",
  "DepartmentWorkersTable-enrolledFalseLabel": "No",
  "DepartmentWorkersTable-enrolledTrueLabel": "Yes",
  "DepartmentWorkersTable-naLabel": "N/A",
  "DepartmentWorkersTable-nameColumnTitle": "Name",
  "DepartmentWorkersTable-report": "Report",
  "DepartmentWorkersTable-reportColumnTitle": "Report",
  "DepartmentWorkersTable-statusColumnTitle": "Status",
  "DepartmentWorkersTable-totalHoursColumnTitle": "Total Hours",
  "DepartmentWorkersTable-totalLiftsColumnTitle": "Total Lifts",
  "DepartmentWorkersTable-unassignedDeviceLabel": "--",
  "DepartmentWorkersTable-idColumnTitle": "Id",
  "Device-formattedConfigFirmwareVersionUnknown": "unknown",
  "DeviceAllocation-allocateButtonText": "Allocate",
  "DeviceAllocation-allocateDeviceToDepartmentError": "The devices could not be allocated",
  "DeviceAllocation-allocationManySuccessfulText": "{numberOfDevices} Devices allocated to **{siteName} - {departmentName}**",
  "DeviceAllocation-allocationOneSuccessfulText": "1 Device allocated to **{siteName} - {departmentName}**",
  "DeviceAllocation-allocationSuccessfulTitle": "Allocation Successful",
  "DeviceAllocation-contentText": "Please select the serial numbers you want to allocate to **{siteName}** - **{departmentName}**",
  "DeviceAllocation-noDevicesText": "No devices found",
  "DeviceAllocation-title": "Device Allocation",
  "DeviceAllocationModal-allocateDeviceToDepartmentError": "The devices could not be allocated",
  "DeviceAllocationModal-CVSDeviceAllocationButtonText": "Allocate",
  "DeviceAllocationModal-CSVDeviceAllocationSuccessfulMessage": "CSV file successfully uploaded",
  "DeviceAllocationModal-errorMessage": "An error occurred while attempting to get the available devices",
  "DeviceAllocationModal-manualDeviceAllocationOkButtonText": "Allocate",
  "DeviceAllocationModal-manualDeviceAllocationSuccessfulMessage": "Devices successfully allocated to {department}.",
  "DeviceAllocationModal-title": "Allocate Device",
  "DeviceDetail-assignedWorker": "Worker:",
  "DeviceDetail-available": "Available",
  "DeviceDetail-dateFirstUsed": "First Used:",
  "DeviceDetail-dateLastConnected": "Last Connected:",
  "DeviceDetail-dateLastUsed": "Last Used:",
  "DeviceDetail-deallocate": "Deallocate",
  "DeviceDetail-deallocateAlertMessage": "Remove **{deviceTag}** (Serial # **{serialNumber}**) from **{siteName} - {departmentName}**?",
  "DeviceDetail-deallocateAlertTitle": "Device Deallocation",
  "DeviceDetail-deallocateDevice": "Deallocate Device",
  "DeviceDetail-deallocateDeviceMessage":
    "Are you sure you want to remove **{deviceTag}** (System Serial # {systemSerialNumber}) from {departmentName}?",
  "DeviceDetail-deallocateError": "An error occurred while attempting to deallocate the device",
  "DeviceDetail-deallocateSuccessMessage": "Device {deviceTag} was successfully deallocated from {departmentName}.",
  "DeviceDetail-department": "Departament:",
  "DeviceDetail-deviceProfile": "Device Profile",
  "DeviceDetail-deviceStatistics": "Device Stats",
  "DeviceDetail-drawerTitle": "Device Details",
  "DeviceDetail-firmware": "Firmware:",
  "DeviceDetail-inService": "In Service",
  "DeviceDetail-none": "None",
  "DeviceDetail-organization": "Organization:",
  "DeviceDetail-outOfService": "Out of Service",
  "DeviceDetail-rev": "Revision:",
  "DeviceDetail-site": "Site:",
  "DeviceDetail-status": "Status:",
  "DeviceDetail-systemSerialNumber": "System Serial #:",
  "DeviceDetail-tag": "Device Tag:",
  "DeviceDetail-title": "Device Details",
  "DeviceDetail-totalHours": "Total Hours:",
  "DeviceDetail-totalLifts": "Total Lifts:",
  "DeviceDetail-updateError": "An error occurred while attempting to update the device data",
  "DeviceDetail-workerNoLongerHasDevice": "Worker No Longer Has A Device",
  "DeviceDetailOld-assignedWorker": "Assigned Worker:",
  "DeviceDetailOld-dateLastConnected": "Last Connected:",
  "DeviceDetailOld-deviceStatistics": "Device Statistics",
  "DeviceEditModal-archived": "Archived:",
  "DeviceEditModal-archiveDeviceSuccess": "Device was Archived successfully",
  "DeviceEditModal-archivedTrueMessage":
    "Changing device to **Archived: True** will make the device visible only from Device Management section.",
  "DeviceEditModal-inService": "In Service",
  "DeviceEditModal-none": "None",
  "DeviceEditModal-outOfService": "Out Of Service",
  "DeviceEditModal-status": "Status:",
  "DeviceEditModal-systemSerialNumber": "System Serial #:",
  "DeviceEditModal-title": "Edit Device Profile",
  "DeviceEditModal-updateDeviceSuccess": "Device details updated successfully",
  "DeviceEditModal-updateError": "An error occurred while attempting to update the device data",
  "DeviceEditModal-worker": "Worker:",
  "DeviceManagementDevices-bulkUpdateDevices": "Bulk Update Devices",
  "DeviceManagementDevices-createDeviceButtonText": "Create Device",
  "DeviceManagementDevices-deviceConfig": "Devices",
  "DeviceManagementDevices-gateways": "Gateways",
  "DeviceManagementDevices-searchDevices": "Perform a search to start seeing Devices",
  "DeviceManagementDevices-searchGateways": "Perform a search to start seeing Gateways",
  "DeviceManagementDevices-subheaderTitle": "Device Management",
  "DeviceManagementDevices-title": "Device Management",
  "DeviceManagementDevicesTable-activeConfig": "Active Config",
  "DeviceManagementDevicesTable-archivedColumnTitle": "Archived",
  "DeviceManagementDevicesTable-archivedDate": "Archived Date:",
  "DeviceManagementDevicesTable-button1ColumnTitle": "Button 1",
  "DeviceManagementDevicesTable-button2ColumnTitle": "Button 2",
  "DeviceManagementDevicesTable-button3ColumnTitle": "Button 3",
  "DeviceManagementDevicesTable-firmwareRevColumnTitle": "Firmware Rev",
  "DeviceManagementDevicesTable-firmwareRevColumnTooltip": "Only displaying firmware versions present in the current Org/Site/Department",
  "DeviceManagementDevicesTable-firstDateUsed": "First Date Used:",
  "DeviceManagementDevicesTable-hardwareRevColumnTitle": "Hardware Rev",
  "DeviceManagementDevicesTable-hardwareRevColumnTooltip": "Only displaying device types present in the current Org/Site/Department",
  "DeviceManagementDevicesTable-location": " Location:",
  "DeviceManagementDevicesTable-pendingUpdate": "Pending Update",
  "DeviceManagementDevicesTable-updateConfig": "Update Config",
  "DeviceManagementDevicesTable-updateConfigColumnTitle": "Update Config",
  "DeviceManagementSearchBar-deviceTag": "Device Tag:",
  "DeviceManagementSearchBar-deviceTagPlaceholder": "Search by Device Tag",
  "DeviceManagementSearchBar-filter": "Filter:",
  "DeviceManagementSearchBar-loadingOrganizations": "Loading organizations...",
  "DeviceManagementSearchBar-searchButtonTooltip": "Please use at least one of the filters before performing a search",
  "DeviceManagementSearchBar-selectOrganization": "Select an organization...",
  "DeviceManagementSearchBar-serialNumber": "Serial Number:",
  "DeviceManagementSearchBar-serialNumberPlaceholder": "Search by Serial Number",
  "DeviceManagementSearchBar-unallocated": "Unallocated",
  "DeviceManagementStats-connected": "Connected: {connectedDevices}",
  "DeviceManagementStats-connectedColumnTitle": "Last Seen",
  "DeviceManagementStats-csvDevices": "devices - {entityName}.csv",
  "DeviceManagementStats-csvGateways": "gateways - {entityName}.csv",
  "DeviceManagementStats-currentSoftwareColumnTitle": "Current Software",
  "DeviceManagementStats-deviceConnectionStatusLabel": "Device Connection Status:",
  "DeviceManagementStats-disconnected": "Disconnected: {disconnectedDevices}",
  "DeviceManagementStats-down": "Down: {downGateways}",
  "DeviceManagementStats-downColumn": "Down",
  "DeviceManagementStats-failed": "Failed",
  "DeviceManagementStats-firmwareColumnTitle": "Firmware",
  "DeviceManagementStats-forceUpdatePending": "Force Update Pending",
  "DeviceManagementStats-includeArchivedLabel": "Include Archived",
  "DeviceManagementStats-intermittent": "Intermittent: {intermittentGateways}",
  "DeviceManagementStats-intermittentColumn": "Intermittent",
  "DeviceManagementStats-latestSoftware": "Latest Software: {latestVersionGateway}",
  "DeviceManagementStats-matchTarget": "Software Matches Target",
  "DeviceManagementStats-neverSeen": "Never Seen: {neverSeenDevices}",
  "DeviceManagementStats-neverSeenColumn": "Never Seen",
  "DeviceManagementStats-pendingConfigurationUpdatesLabel": "Pending Configuration Updates:",
  "DeviceManagementStats-scheduleUpdatePending": "Schedule Update Pending",
  "DeviceManagementStats-serialNumberColumnTitle": "Gateway Serial #",
  "DeviceManagementStats-systemSerialNumberColumnTitle": "System Serial #",
  "DeviceManagementStats-totalDevicesLabel": "Total Devices:",
  "DeviceManagementStats-totalGatewaysLabel": "Total Gateways:",
  "DeviceManagementStats-up": "Up: {upGateways}",
  "DeviceManagementStats-upColumn": "Up",
  "DeviceManagementStats-updateStatusColumnTitle": "Update Status",
  "DeviceNoWorker-assignErrorMessage": "An error occurred while attempting to assign the device to the worker",
  "DeviceNoWorker-assignNewWorker": "Assign device to a new worker",
  "DeviceNoWorker-assignNewWorker-placeholder": "Select worker",
  "DeviceNoWorker-assignSuccessMessage": "Device {deviceTag} was succesfully assigned to {workerName}",
  "DeviceNoWorker-changeStatusErrorMessage": "An error occurred while attempting to update the device status.",
  "DeviceNoWorker-changeStatusOutOfService": "Change status to 'Out of Service'",
  "DeviceNoWorker-changeStatusSuccessMessage": "Device {deviceTag} status was successfully changed to Out of Service",
  "DeviceNoWorker-firstSubtitle": "**{name}** no longer has an assigned worker.",
  "DeviceNoWorker-leaveUnassigned": "Leave Unassigned",
  "DeviceNoWorker-leaveUnassignedSuccessMessage": "Device {deviceTag} status was succesfully changed to Unassigned",
  "DeviceNoWorker-secondSubtitle": "What would you like to do with this device?",
  "DeviceNoWorker-title": "Device Status",
  "DeviceNoWorker-worker": "Worker:",
  "DevicesTabContent-allocateDeviceButton": "Allocate Device",
  "DevicesTabContent-allTagText": "All",
  "DevicesTabContent-assignedTagText": "Assigned",
  "DevicesTabContent-availableTagText": "Available",
  "DevicesTabContent-connected": "Connected",
  "DevicesTabContent-connectedTagText": "Connected",
  "DevicesTabContent-csv": "{tag} Devices.csv",
  "DevicesTabContent-deviceTag": "Device Tag",
  "DevicesTabContent-deviceUsageReassignmentButton": "Device Usage Reassignment",
  "DevicesTabContent-disconnected": "Disconnected",
  "DevicesTabContent-disconnectedTagText": "Disconnected",
  "DevicesTabContent-inService": "In Service",
  "DevicesTabContent-lastSeen": "Last Seen",
  "DevicesTabContent-neverSeen": "Never Seen",
  "DevicesTabContent-outOfServiceTagText": "Out Of Service",
  "DevicesTabContent-outOfService": "Out Of Service",
  "DevicesTabContent-searchButtonText": "Search Device Tag",
  "DevicesTabContent-status": "Status",
  "DevicesTabContent-worker": "Worker",
  "DeviceLiveCheckoutFilters-allTagText": "All",
  "DeviceLiveCheckoutFilters-bottomDisclaimer": "*Only displaying In Service devices",
  "DeviceLiveCheckoutFilters-checkedOutRecentlyDescription": "Unplugged in last 12 hours and/or logged data in last 12 hours ",
  "DeviceLiveCheckoutFilters-checkedOutRecentlyTagText": "Checked Out Recently",
  "DeviceLiveCheckoutFilters-idleDescription": "Unplugged for more than 12 hours",
  "DeviceLiveCheckoutFilters-idleTagText": "Idle",
  "DeviceLiveCheckoutFilters-notCheckedOutDescription": "Currently connected and no data logged in last 12 hours",
  "DeviceLiveCheckoutFilters-notCheckedOutTagText": "Not Checked Out",
  "DeviceLiveCheckoutFilters-searchButtonText": "Search by Worker or Device Tag",
  "DeviceLiveCheckoutFilters-seeOnlyAssigned": "See only Assigned Devices",
  "DeviceLiveCheckoutTab-checkedOutColumn": "Checked Out",
  "DeviceLiveCheckoutTab-checkedOutRecentlyTagText": "Checked Out Recently",
  "DeviceLiveCheckoutTab-connected": "Connected",
  "DeviceLiveCheckoutTab-csv": "{tag} Devices.csv",
  "DeviceLiveCheckoutTab-dataUploadedColumn": "Data Uploaded Today",
  "DeviceLiveCheckoutTab-deviceColumn": "Device Tag",
  "DeviceLiveCheckoutTab-idleTagText": "Idle",
  "DeviceLiveCheckoutTab-lastSeenColumn": "Last Seen",
  "DeviceLiveCheckoutTab-neverSeen": "Never Seen",
  "DeviceLiveCheckoutTab-notCheckedOutTagText": "Not Checked Out",
  "DeviceLiveCheckoutTab-workerColumn": "Worker",
  "DeviceUsageReassignment-assignedDataSwitch": "See Also Assigned Data",
  "DeviceUsageReassignment-deviceColumn": "Devices",
  "DeviceUsageReassignment-endDate": "End Date of Week",
  "DeviceUsageReassignment-errorFetchingWorkers": "Error fetching workers",
  "DeviceUsageReassignment-errorMessage": "An error occurred while attempting to get the devices data",
  "DeviceUsageReassignment-title": "Device Usage Reassignment",
  "DeviceUsageReassignment-worker": "Worker:",
  "DeviceUsageReassignment-location": "Location",
  "DeviceUsageReassignment-unallocated": "Unallocated",
  "DeviceUsageReassignment-totalUnallocated": "Total Unallocated",
  "DeviceUsageReassignment-loadingOrganizations": "Loading organizations...",
  "DeviceUsageReassignment-selectOrganization": "Select an organization...",
  "LiveCheckedOutTabContent-allTagText": "All",
  "LiveCheckedOutTabContent-allocateDeviceButton": "Allocate Device",
  "LiveCheckedOutTabContent-assignedTagText": "Assigned",
  "LiveCheckedOutTabContent-availableTagText": "Available",
  "LiveCheckedOutTabContent-connectedTagText": "Connected",
  "LiveCheckedOutTabContent-disconnectedTagText": "Disconnected",
  "LiveCheckedOutTabContent-outOfServiceTagText": "Out Of Service",
  "LiveCheckedOutTabContent-searchButtonText": "Search Device Tag",
  "DevicesTable-assignedWorkerColumnTitle": "Assigned Worker",
  "DevicesTable-assignedWorkerFilter-assigned": "Assigned",
  "DevicesTable-assignedWorkerFilter-available": "Available",
  "DevicesTable-assignedWorkerFilter-outOfService": "Out of Service",
  "DevicesTable-connectedColumnTitle": "Last Seen",
  "DevicesTable-connectedLabel": "Connected",
  "DevicesTable-daysSinceFirstUsedColumnTitle": "First Used",
  "DevicesTable-daysSinceLastUsedColumnTitle": "Last Used",
  "DevicesTable-departmentColumnTitle": "Department",
  "DevicesTable-deviceTagColumnTitle": "Device Tag",
  "DevicesTable-disconnectedLabel": "Disconnected",
  "DevicesTable-firmwareColumnTitle": "Firmware",
  "DevicesTable-neverSeenLabel": "Never Seen",
  "DevicesTable-organizationColumnTitle": "Organization",
  "DevicesTable-revColumnTitle": "Revision",
  "DevicesTable-siteColumnTitle": "Site",
  "DevicesTable-systemSerialNumberColumnTitle": "System Serial #",
  "DevicesTable-totalHoursColumnTitle": "Total Hours",
  "DevicesTable-totalLiftsColumnTitle": "Total Lifts",
  "DeviceStatusChart-activeDevices": "Active",
  "DeviceStatusChart-allocatedDevices": "Allocated",
  "DeviceStatusChart-inactiveDevices": "Inactive",
  "DeviceStatusChart-noDevices": "No devices to display",
  "DeviceStatusChart-outOfServiceDevices": "Out of Service",
  "DeviceStatusChart-unassignedDevices": "Unassigned",
  "DeviceStatusChartOld-activeDevices": "Active Devices",
  "DeviceStatusChartOld-allocatedDevices": "Allocated Devices",
  "DeviceStatusChartOld-inactiveDevices": "Inactive Devices",
  "DeviceStatusChartOld-noDevices": "No devices to display",
  "DeviceStatusChartOld-outOfServiceDevices": "Out of Service Devices",
  "DeviceStatusChartOld-unassignedDevices": "Unassigned Devices",
  "DeviceManagementDeviceDetail-archivedAt": "Archived At:",
  "DeviceManagementDeviceDetail-assignedWorker": "Assigned Worker:",
  "DeviceManagementDeviceDetail-button1": "Button 1:",
  "DeviceManagementDeviceDetail-button2": "Button 2:",
  "DeviceManagementDeviceDetail-button3": "Button 3:",
  "DeviceManagementDeviceDetail-configurationTitle": "Configuration",
  "DeviceManagementDeviceDetail-diagnosticsPath": "Device Management",
  "DeviceManagementDeviceDetail-ConnectedStatus-connected": "Connected",
  "DeviceManagementDeviceDetail-ConnectedStatus-disconnected": "Disconnected",
  "DeviceManagementDeviceDetail-ConnectedStatus-neverSeen": "Never Seen",
  "DeviceManagementDeviceDetail-connectionStatus": "Connection Status:",
  "DeviceManagementDeviceDetail-customerInfoTitle": "Customer Info",
  "DeviceManagementDeviceDetail-dateFirstUsed": "First Used:",
  "DeviceManagementDeviceDetail-dateLastConnected": "Last Connected:",
  "DeviceManagementDeviceDetail-dateLastUsed": "Last Used:",
  "DeviceManagementDeviceDetail-department": "Department:",
  "DeviceManagementDeviceDetail-deviceDetailsTitle": "Details",
  "DeviceManagementDeviceDetail-deviceStatisticsTitle": "Statistics",
  "DeviceManagementDeviceDetail-deviceTag": "Device Tag:",
  "DeviceManagementDeviceDetail-firmwareRev": "Firmware Rev:",
  "DeviceManagementDeviceDetail-hardwareRev": "Hardware Rev:",
  "DeviceManagementDeviceDetail-inService": "In Service",
  "DeviceManagementDeviceDetail-mcuId": "MCU ID:",
  "DeviceManagementDeviceDetail-organization": "Organization:",
  "DeviceManagementDeviceDetail-outOfService": "Out of Service",
  "DeviceManagementDeviceDetail-pendingUpdateTooltip": "Update pending: Showing pending configuration details.",
  "DeviceManagementDeviceDetail-revision": "Revision:",
  "DeviceManagementDeviceDetail-sessions": "Sessions",
  "DeviceManagementDeviceDetail-site": "Site:",
  "DeviceManagementDeviceDetail-status": "Status:",
  "DeviceManagementDeviceDetail-systemSerialNumber": "System Serial #:",
  "DeviceManagementDeviceDetail-title": "Device {systemSerialNumber}",
  "DeviceManagementDeviceDetail-totalHours": "Total Hours:",
  "DeviceManagementDeviceDetail-totalLifts": "Total Lifts:",
  "DeviceManagementDeviceDetail-updatePending": "Update Pending:",
  "DeviceManagementDeviceDetail-workerErrorMessage": "An error occurred while attempting to load the worker data",
  "DownloadAllData-columnNameActiveWorkers": "Active Workers",
  "DownloadAllData-columnNameActiveWorkersPercentage": "Active Workers Percentage",
  "DownloadAllData-columnNameAssignedWorkers": "Assigned Workers",
  "DownloadAllData-columnNameEndDate": "End Date",
  "DownloadAllData-columnNameEndTime": "End Time",
  "DownloadAllData-columnNameEnrolledWorkers": "Enrolled Workers",
  "DownloadAllData-columnNameExcessiveForwardLifts": "Excessive Forward Lifts",
  "DownloadAllData-columnNameInactiveWorkers": "Inactive Workers",
  "DownloadAllData-columnNameInactiveWorkersPercentage": "Inactive Workers Percentage",
  "DownloadAllData-columnNameProlongedBendLifts": "Prolonged Bend Lifts",
  "DownloadAllData-columnNameResourceId": "Resource ID",
  "DownloadAllData-columnNameResourceName": "Resource Name",
  "DownloadAllData-columnNameResourceType": "Resource Type",
  "DownloadAllData-columnNameRiskyLifts": "Risky Lifts",
  "DownloadAllData-columnNameRiskyLiftsPercentage": "Risky Lifts Percentage",
  "DownloadAllData-columnNameSafeLifts": "Safe Lifts",
  "DownloadAllData-columnNameSafeLiftsPercentage": "Safe Lifts Percentage",
  "DownloadAllData-columnNameSideBendLifts": "Side Bend Lifts",
  "DownloadAllData-columnNameStartDate": "Start Date",
  "DownloadAllData-columnNameStartTime": "Start Time",
  "DownloadAllData-columnNameTimeZone": "Timezone",
  "DownloadAllData-columnNameTotalLifts": "Total Lifts",
  "DownloadAllData-columnNameTwistedLifts": "Twisted Lifts",
  "DownloadAllData-columnNameHoursUsed": "Hours Used",
  "DownloadAllData-columnNameWeightOffloaded": "Weight Offloaded",
  "DownloadAllData-dateRange": "Date Range",
  "DownloadAllData-department": "Department",
  "DownloadAllData-download": "Download",
  "DownloadAllData-interval": "Interval",
  "DownloadAllData-organization": "Organization",
  "DownloadAllData-reportName": "All Data Report",
  "DownloadAllData-scope": "Scope",
  "DownloadAllData-site": "Site",
  "EditableDevice-fetchDevicesError": "An error occurred while attempting to get the available devices",
  "EditableWorker-fetchWorkersError": "An error occurred while attempting to get the available workers",
  "EditableWorker-none": "None",
  "EditCustomPermissionsModal-admin": "Admin",
  "EditCustomPermissionsModal-errorOrganizationMessage": "An error occurred while attempting to get the user organization.",
  "EditCustomPermissionsModal-infoMessage": "All rows must have one selection in order to save configuration.",
  "EditCustomPermissionsModal-manager": "Manager",
  "EditCustomPermissionsModal-none": "None",
  "EditCustomPermissionsModal-readonly": "Read Only",
  "EditCustomPermissionsModal-subtitle": "First, select organization, then site(s) and lastly department(s) configurations.",
  "EditCustomPermissionsModal-title": "Edit Custom Permissions",
  "EditSessionAssignment-newWorker": "New Worker:",
  "EditSessionAssignment-none": "None",
  "EditSessionAssignment-previousWorker": "Previous Worker:",
  "EditSessionAssignment-sessionID": "Session ID:",
  "EditSessionAssignment-startTime": "Start Time:",
  "EditSessionAssignment-subtitle": "Please select the worker you want to assign the following session to:",
  "EditSessionAssignment-systemSerialNumber": "System Serial #:",
  "EditSessionAssignment-title": "Edit Session Assignment",
  "EditSessionAssignment-updateError": "An error occurred while attempting to update the worker assigned to the session",
  "EntityDetail-address": "Address:",
  "EntityDetail-assignedDevices": "# Assigned Devices:",
  "EntityDetail-availableDevices": "# Available Devices:",
  "EntityDetail-devices": "# of Devices:",
  "EntityDetail-disconnectedDevices": "# Disconnected Devices:",
  "EntityDetail-gatewayStatus": "Gateway Status:",
  "EntityDetail-iconUrl": "Icon url:",
  "EntityDetail-name": "Name:",
  "EntityDetail-siteName": "Site Name:",
  "EntityDetail-departmentName": "Department Name:",
  "EntityDetail-noGateway": "No Gateway",
  "EntityDetail-outOfServiceDevices": "# Out of Service Devices:",
  "EntityDetail-shortName": "Short Name:",
  "EntityDetail-timezone": "Timezone:",
  "EntityDetail-title": "{entity} Details",
  "EntityDetail-title-department": "Department",
  "EntityDetail-title-organization": "Organization",
  "EntityDetail-title-site": "Site",
  "EntityDetailsSider-createDepartmentButton": "Create Department",
  "EntityDetailsSider-createSiteButton": "Create Site",
  "EntityDetailsSider-departmentsTitle": "Departments",
  "EntityDetailsSider-sitesTitle": "Sites",
  "EntityDetailsSiderCard-departmentAndDeviceCount": "{departments} Departments / {devices} Devices",
  "EntityDetailsSiderCard-descriptionAddress": "Address: {address}",
  "EntityDetailsSiderCard-descriptionTimezone": "Timezone: {tz}",
  "EntityDetailsSiderCard-deviceCount": "{devices} Devices",
  "EntityDetailsSiderCard-gatewayStatus": "Gateway Status",
  "EntityDetailsSiderCard-gatewayDateSinceOffline": "({date})",
  "EntityDetailsSiderCard-DepartmentAndDeviceCount": "{departments} Departments / {devices} Devices",
  "EntityDetailsSiderCard-siteDepartmentAndDeviceCount": "{sites} Sites / {departments} Departments / {devices} Devices",
  "EntityDetailsSiderCard-siteDepartmentInformationOrg":
    "Since this Organization has only one Site and Department, information is displayed for both at the Department level.",
  "EntityDetailsSiderCard-siteDepartmentInformation":
    "Since this Site only has one Department, information is displayed for both at the Department Level",
  "EntityDetailsSiderCard-view": "View",
  "EntityDetails-analyticsTabName": "Analytics",
  "EntityDetails-dashboardTabName": "Dashboard",
  "EntityDetails-devicesTabName": "Devices",
  "EntityDetails-navigationMenu": "Navigation Menu",
  "EntityDetails-workerReportGroupsTabName": "Worker Report Groups",
  "EntityDetails-workersTabName": "Workers",
  "EntityDetails-liveCheckedOutTabName": "Live Checked Out",
  "ErrorScreen-backHomeButton": "Back Home",
  "ErrorScreen-correlationId": "(Correlation ID: {correlationID})",
  "ErrorScreen-defaultErrorText": "Error",
  "ErrorScreen-internalServerErrorDescription": "There was an error loading the page’s data",
  "ErrorScreen-internalServerErrorText": "Server Error",
  "ErrorScreen-notFoundErrorDescription": "The {entityType} does not exist",
  "ErrorScreen-notFoundErrorText": "Not Found Error",
  "ErrorScreen-reloadPageButton": "Reload Page",
  "FetchAllocateGatewayData-errorMessage": "An error occurred while attempting to get the available gateways",
  "FetchAnalyticsDashboardWorkersData-errorMessage": "An error occurred while attempting to get the workers data",
  "FetchDashboardDailyUsageBreakdownSectionWorkersData-errorMessage": "An error occurred while attempting to get the workers data",
  "FetchDepartmentDetailPageData-errorMessage": "An error occurred while attempting to get the department data",
  "FetchDeviceAllocationData-errorMessage": "An error occurred while attempting to get the available devices",
  "FetchDeviceManagementDeviceDetailPageData-errorMessage": "An error occurred while attempting to get the device data",
  "FetchDeviceManagementDeviceDetailPageData-refreshErrorMessage": "The sessions data could not be refreshed after the update",
  "FetchDiagnosticsDevicesPageData-errorMessage": "An error occurred while attempting to get the devices data",
  "FetchOrganizationsPageData-errorMessage": "An error occurred while attempting to get the organizations data",
  "FetchStatistics-errorMessage": "An error occurred while attempting to get the statistics",
  "FetchWorkersData-errorMessage": "An error occurred while attempting to get the required data",
  "GatewayAlertBlock-intermittentGateway":
    "Gateway {serialNumber} at {siteName}-{departmentName} has an intermittent connection. The connection status and usage data for devices on this gateway may be inaccurate",
  "GatewayAlertBlock-offlineGateway":
    "Gateway {serialNumber} at {siteName}-{departmentName} has been offline since {lastEventDate}. The connection status and usage data for devices on this gateway may be inaccurate",
  "GatewayAlertBlock-offlineIntermitentGateways":
    "Gateways {listsText} are having connection issues. The connection status and usage data for devices on those gateways may be inaccurate",
  "GatewayAlertBlock-offlineIntermitentGatewaysList": "{serialNumberList} at {siteName}-{departmentName}",
  "GatewayEditModal-currentSoftware": "Current Software:",
  "GatewayEditModal-editTargetSoftware": "Edit Target Software",
  "GatewayEditModal-fetchVersionsDataError": "An error occurred while attempting to get the software versions data",
  "GatewayEditModal-gatewaySerial": "Gateway Serial # :",
  "GatewayEditModal-latest": "Latest",
  "GatewayEditModal-scheduleButton": "Schedule",
  "GatewayEditModal-selectTargetSoftware": "Select Target Software",
  "GatewayEditModal-targetSoftware": "Target Software:",
  "GatewayEditModal-title": "Edit Gateway",
  "GatewayEditModal-updateError": "An error occurred while attempting to update the gateway's software version",
  "GatewayEditModal-updateNowButton": "Update Now",
  "GatewayEditModal-updateSuccessForced": "A forced Gateway Software update is now pending.",
  "GatewayEditModal-updateSuccessScheduled": "The gateway is scheduled to update overnight.",
  "GatewayItem-deallocateGateway": "Deallocate Gateway",
  "GatewayDetails-departmentLabel": "Department:",
  "GatewayDetails-gatewaySoftwareLabel": "Current Software:",
  "GatewayDetails-gatewaySerialNumber": "Gateway Serial # {serialNumber}",
  "GatewayDetails-lastTimeConnectedLabel": "Last Time Connected:",
  "GatewayDetails-location": "Location",
  "GatewayDetails-organizationLabel": "Organization:",
  "GatewayDetails-siteLabel": "Site:",
  "GatewayDetails-title": "Gateway Details",
  "GatewaysTable-currentSoftwareColumnTitle": "Current Software",
  "GatewaysTable-downLabel": "Down",
  "GatewaysTable-failed": "Failed",
  "GatewaysTable-firmwareColumnTitle": "Firmware",
  "GatewaysTable-forceUpdatePending": " Force Update Pending",
  "GatewaysTable-gatewayDetailsDrawerTitle": "Gateway",
  "GatewaysTable-intermittentLabel": "Intermittent",
  "GatewaysTable-lastSeenColumnTitle": "Last Seen",
  "GatewaysTable-matchTarget": "Software Matches Target",
  "GatewaysTable-neverSeenLabel": "Never Seen",
  "GatewaysTable-scheduleUpdatePending": " Schedule Update Pending",
  "GatewaysTable-serialNumberColumnTitle": "Gateway Serial #",
  "GatewaysTable-updateStatusColumnTitle": "Update Status ",
  "GatewaysTable-upLabel": "Up",
  "GatewaysTableLastSeenColumnRender-neverSeen": "Never Seen",
  "GatewayUpdateConfigurationModal-currentSoftware": "Current Software :",
  "GatewayUpdateConfigurationModal-description": "If you wish to update the below gateway to the target software please select update now.",
  "GatewayUpdateConfigurationModal-gatewaySerial": "Gateway Serial # :",
  "GatewayUpdateConfigurationModal-targetSoftware": "Target Software :",
  "GatewayUpdateConfigurationModal-title": "Update Gateway Configuration",
  "GatewayUpdateConfigurationModal-updateError": "An error occurred while attempting to update the gateway's software version",
  "GatewayUpdateConfigurationModal-updateNowButton": "Update Now",
  "GatewayUpdateConfigurationModal-updatePending": "A Forced Gateway Software Update is now pending.",
  "Header-acknowledgeErrorMessage": "An error occurred while attempting to acknowledge the notifications",
  "Header-clearNotifications": "Clear",
  "Header-errorMessage": "An error occurred while attempting to get the notifications",
  "Header-noNotifications": "No Notifications",
  "Header-notifications": "Notifications",
  "Header-today": "Today",
  "Header-unallocatedData": "Unallocated Data",
  "Header-unallocatedDataDevices": "{devices} Devices each have 8+ hours of unallocated data recently",
  "Header-unallocatedDataOneDevice": "1 Device has 8+ hours of unallocated data recently",
  "Header-viewAll": "View All",
  "IndividualWorkerReportDrawer-contentMessage":
    "The worker report for **{worker}** covers a 7 day period. Please select the desired end date for the report.",
  "IndividualWorkerReportDrawer-drawerTitle": "Worker Report",
  "IndividualWorkerReportDrawer-fileName": "Worker Report",
  "LeaderboardDownloadDropdown-downloadAll": "All",
  "LeaderboardDownloadDropdown-downloadBottom": "Coaching Opportunities",
  "LeaderboardDownloadDropdown-downloadData": "Download data",
  "LeaderboardDownloadDropdown-downloadError": "An error occurred while attempting to download the report",
  "LeaderboardDownloadDropdown-downloadTop": "Top Leaderboard",
  "LeaderboardDownloadDropdown-title": "Leaderboard",
  "LeaderboardDownloadDropdown-workerMetrics": "Worker Metrics",
  "LiveCheckedOutDeviceTable-dataUploadedTodayColumnTitle": "Data uploaded today",
  "LiveCheckedOutDeviceTable-deviceTagColumnTitle": "Device tag",
  "LiveCheckedOutDeviceTable-workerColumnTitle": "Worker",
  "LiveCheckedOutDeviceTable-checkedOutColumnTitle": "Checked Out",
  "LiveCheckedOutDeviceTable-lastSeenColumnTitle": "Last Seen",
  "ManualDeviceAllocation-description": "Please select the serial numbers you want to allocate to **{site}** - **{department}**.",
  "ManualDeviceAllocation-devicesLabel": "Devices:",
  "ManualWorkerCreation-bothNamesRequiredValidation": "If either First Name or Last Name are specified, both must be",
  "ManualWorkerCreation-contentText": "Please list the workers you would like to create in **{siteName}** - **{departmentName}**",
  "ManualWorkerCreation-empIdAlreadyExistsValidation": "Worker with this Employee ID already exists in site {siteName}",
  "ManualWorkerCreation-ifNoNameRequireEmpIdValidation": "If both First Name and Last Name are omitted, Employee ID must be specified",
  "ManualWorkerCreation-nameAlreadyExistsValidation": "Worker with this name already exists in site {siteName}",
  "ManualWorkerCreation-title": "Worker Creation",
  "Notifications-title": "Notifications",
  "Notifications-unallocatedData": "Unallocated Data",
  "Organizations-allOrganizationsPath": "All Organizations",
  "Organizations-allOrganizationsTitle": "All Organizations",
  "Organizations-allTagText": "All",
  "Organizations-createOrganizationButtonText": "Create Organization",
  "Organizations-downloadData": "Download Data",
  "Organizations-favoriteTagText": "Favorites",
  "Organizations-organizationsListEndMessage": "No more organizations to display",
  "Organizations-searchButtonText": "Search",
  "OrganizationCard-deviceStatusChartTitle": "Device Status",
  "OrganizationCard-oneDeviceText": "1 Device in Total",
  "OrganizationCard-siteDepartmentAndDeviceCount":
    "{sites} {sites, plural, =1 {Site} other {Sites}} / {departments} {departments, plural, =1 {Department} other {Departments}} / {devices} {devices, plural, =1 {Device} other {Devices}}",
  "OrganizationCard-totalDevicesText": "{devices} Total Devices",
  "OrganizationCard-usageChartTitle": "Total Usage Hours Per Week",
  "OrganizationDetail-deleteAlertMessage": "Are you sure you want to delete this organization?",
  "OrganizationDetail-deleteAlertTitle": "Delete Organization",
  "OrganizationDetail-deleteError": "The organization could not be deleted",
  "OrganizationDetail-fetchDataError": "An error occurred while attempting to get the organization data",
  "OrganizationDetail-notFoundError": "Organization not found",
  "OrganizationDetail-tableTitle": "Sites list",
  "OrganizationDetail-updateError": "An error occurred while attempting to update the organization data",
  "OrganizationForm-addressInputLabel": "Address",
  "OrganizationForm-createError": "An error occurred while attempting to create the organization",
  "OrganizationForm-iconUrlInputLabel": "Icon url",
  "OrganizationForm-nameInputLabel": "Name",
  "OrganizationForm-shortNameInputLabel": "Short Name",
  "OrganizationForm-shortNameInputPatternRuleMessage":
    "Short Name can only contain lowercase letters, numbers or - but not as starting or ending character",
  "OrganizationModal-createError": "An error occurred while attempting to create the organization",
  "OrganizationModal-createTitle": "Create Organization",
  "OrganizationModal-deleteButtonText": "Delete Organization",
  "OrganizationModal-deleteError": "The organization could not be deleted",
  "OrganizationModal-editTitle": "Edit Organization",
  "OrganizationModal-updateError": "An error occurred while attempting to update the organization",
  "OrganizationsTable-departmentsColumnTitle": "# Departments",
  "OrganizationsTable-devicesColumnTitle": "# Devices",
  "OrganizationsTable-nameColumnTitle": "Organization Name",
  "OrganizationsTable-sitesColumnTitle": "# Sites",
  "OrganizationSiteModal-editTitle": "Edit Organization and Site",
  "OrganizationSiteModal-deleteAlertMessage": "Are you sure you want to delete this site?",
  "OrganizationSiteModal-deleteAlertTitle": "Delete Site",
  "OrganizationSiteModal-deleteButtonText": "Delete Site",
  "OrganizationSiteModal-deleteError": "An error occurred while attempting to delete the site",
  "OrganizationSiteModal-updateOrganizationError": "An error occurred while attempting to update the organization",
  "OrganizationSiteModal-updateSiteError": "An error occurred while attempting to update the site",
  "OverridePendingUpdateConfirmationModal-message": "Are you sure you want to override pending update?",
  "OverridePendingUpdateConfirmationModal-title": "Override Pending Update",
  "PendingUpdateDetail-buttonOne": "Button 1:",
  "PendingUpdateDetail-buttonThree": "Button 3:",
  "PendingUpdateDetail-buttonTwo": "Button 2:",
  "PendingUpdateDetail-drawerSubtitle": "Pending update for the following device:",
  "PendingUpdateDetail-drawerTitle": "Pending Update",
  "PendingUpdateDetail-firmware": "Firmware Rev:",
  "PendingUpdateDetail-hardwareRev": "Hardware Rev:",
  "PendingUpdateDetail-overridePendingUpdateButton": "Override Pending Update",
  "PendingUpdateDetail-systemSerialNumber": "System  Serial #:",
  "RemoveFromFavoritesModal-doNotShowMessageAgainText": "Do not show this message again.",
  "RemoveFromFavoritesModal-message": "Are you sure you want to remove **{organization}** from favorites?",
  "RenameWorkerGroupModal-errorMessage": "An error ocurred while trying to update the worker report group",
  "RenameWorkerGroupModal-inputLabel": "Group Name:",
  "RenameWorkerGroupModal-nameLengthValidator": "Group names are limited to 40 characters. Please shorten the name.",
  "RenameWorkerGroupModal-rename": "Rename",
  "RenameWorkerGroupModal-successMessage": "Group name was successfully changed",
  "RenameWorkerGroupModal-title": "Rename Group",
  "ReportDownload-allButtonText": "Download All Workers Report",
  "ReportDownload-allMessage":
    "The all workers report covers a 7 day period. Please select the desired end date of the report you wish to download.",
  "ReportDownload-allTitle": "All Workers Report",
  "ReportDownload-demoButtonText": "Download Demo Report",
  "ReportDownload-demoMessage":
    "The demo report covers a 7 day period. Please select the desired end date of the report you wish to download.",
  "ReportDownload-demoTitle": "Demo Report",
  "ReportDownload-department": "Department:",
  "ReportDownload-downloadError": "An error occurred while attempting to download the report",
  "ReportDownload-individualButtonText": "Download Single Worker Report",
  "ReportDownload-individualMessage":
    "The worker report covers a 7 day period. Please select the desired end date of the report you wish to download.",
  "ReportDownload-individualTitle": "Worker Report",
  "ReportDownload-noDepartmentError": "There are no departments in this site to download the report.",
  "ReportDownload-reportEndDate": "Report End Date:",
  "ReportDownload-summaryButtonText": "Download Department Report",
  "ReportDownload-summaryMessage":
    "The department summary report covers a 7 day period. Please select the desired end date of the report you wish to download.",
  "ReportDownload-summaryTitle": "Summary Report",
  "ReportDownload-worker": "**Worker:** {displayName}",
  "ReportDrawer-fetchReportError": "An error occurred while attempting to fetch the report data",
  "ReportDrawerContent-downloadButtonText": "Download",
  "ReportDrawerContent-previewButtonText": "Preview",
  "ReportDrawerContent-generateReportButtonText": "Generate Report",
  "ReportDrawerContent-reportEndDate": "Report End Date:",
  "ReportDrawerContent-filterReport": "Filter report:",
  "ReportDrawerContent-allWorkers": "All Workers",
  "ReportDrawerContent-workerReportGroups": "Groups",
  "ReportPreviewModal-title": "Preview",
  "ReportPreviewModal-searchButtonText": "Search",
  "ReportPreviewModal-download": "Download",
  "WorkerSafeliftReportPreviewModal-all": "Download All Active and Inactive Workers",
  "WorkerSafeliftReportPreviewModal-allActive": "Download All Active Workers",
  "WorkerSafeliftReportPreviewModal-allWorkers": "All Workers",
  "WorkerSafeliftReportPreviewModal-allWorkersNoData": "No Workers from {departmentName} were active between {startDate} and {endDate}",
  "WorkerSafeliftReportPreviewModal-displayInactiveWorkers": "Display Inactive Workers:",
  "WorkerSafeliftReportPreviewModal-download": "Download",
  "WorkerSafeliftReportPreviewModal-downloadError": "An error occurred while attempting download the report",
  "WorkerSafeliftReportPreviewModal-generatingReport": "Generating report...",
  "WorkerSafeliftReportPreviewModal-groupNoData":
    "No Workers from {departmentName} - {groupName} were active between {startDate} and {endDate}",
  "WorkerSafeliftReportPreviewModal-individual": "Selected Worker",
  "WorkerSafeliftReportPreviewModal-internalError": "Sorry, something went wrong. Internal error. Please try again later.",
  "WorkerSafeliftReportPreviewModal-safeliftReport": "Safelift report",
  "WorkerSafeliftReportPreviewModal-searchButtonText": "Search",
  "WorkerSafeliftReportPreviewModal-timeoutError": "Sorry, something went wrong. Timeout error. Please try again later.",
  "WorkerSafeliftReportPreviewModal-title": "Preview",
  "WorkerSafeliftReportPreviewModal-workerNoData": "{workerName} was not active between {startDate} and {endDate}",
  "SearchFilter-inputPlaceholder": "Search text",
  "SearchFilter-resetButton": "Reset",
  "SearchFilter-searchButton": "Search",
  "SessionLog-download": "Download",
  "SessionLog-duration": "Duration:",
  "SessionLog-entries": "Entries:",
  "SessionLog-errors": "Errors",
  "SessionLog-fetchDataError": "An error occurred while attempting to get the logs data",
  "SessionLog-lifts": "Lifts:",
  "SessionLog-log": "Log:",
  "SessionLog-none": "None",
  "SessionLog-sessionID": "Session ID:",
  "SessionLog-startTime": "Start Time:",
  "SessionLog-systemSerialNumber": "System Serial #:",
  "SessionLog-title": "JSON Logs",
  "SessionLog-worker": "Worker:",
  "SessionsTable-createdLabel": "Created",
  "SessionsTable-duration": "Duration",
  "SessionsTable-entries": "Entries",
  "SessionsTable-errors": "Errors",
  "SessionsTable-hourAbbreviation": "h",
  "SessionsTable-id": "Session ID",
  "SessionsTable-lifts": "Lifts",
  "SessionsTable-logs": "Logs",
  "SessionsTable-minuteAbbreviation": "m",
  "SessionsTable-none": "None",
  "SessionsTable-processedLabel": "Processed",
  "SessionsTable-secondAbbreviation": "s",
  "SessionsTable-startTime": "Start Time",
  "SessionsTable-status": "Status",
  "SessionsTable-uploadedLabel": "Uploaded",
  "SessionsTable-uuid": "Session UUID",
  "SessionsTable-worker": "Worker",
  "SignIn-emailInputLabel": "Email",
  "SignIn-emailInputRequiredRuleMessage": "Email field is required",
  "SignIn-errorMessage": "Could not sign in",
  "SignIn-passwordInputLabel": "Password",
  "SignIn-passwordInputRequiredRuleMessage": "Password field is required",
  "SignIn-submit": "Log In",
  "SiteDepartmentModal-deleteAlertMessage": "Are you sure you want to delete this department?",
  "SiteDepartmentModal-deleteAlertTitle": "Delete Department",
  "SiteDepartmentModal-deleteButtonText": "Delete Department",
  "SiteDepartmentModal-title": "Edit Site and Department",
  "SiteDepartmentModal-orgIncludedTitle": "Edit Organization, Site and Department",
  "SiteDetail-deleteAlertMessage": "Are you sure you want to delete this site?",
  "SiteDetail-deleteAlertTitle": "Delete Site",
  "SiteDetail-deleteError": "The site could not be deleted",
  "SiteDetail-differentOrganizationError": "This site belongs to another organization",
  "SiteDetail-fetchDataError": "An error occurred while attempting to get the site data",
  "SiteDetail-notFoundError": "Site not found",
  "SiteDetail-tableTitle": "Departments list",
  "SiteDetail-updateError": "An error occurred while attempting to update the site data",
  "SiteForm-addressInputLabel": "Address",
  "SiteForm-createError": "An error occurred while attempting to create the site",
  "SiteForm-nameInputLabel": "Name",
  "SiteForm-timezoneInputLabel": "Timezone",
  "SiteModal-createError": "An error occurred while attempting to create the site",
  "SiteModal-createTitle": "Create Site",
  "SiteModal-deleteButtonText": "Delete Site",
  "SiteModal-editTitle": "Edit Site",
  "SiteModal-updateError": "An error occurred while attempting to update the site",
  "SiteSummaryLeaderboardPage-departmentAverage": "Department Average",
  "SiteSummaryLeaderboardPage-kilogramsUnit": "kgs",
  "SiteSummaryLeaderboardPage-poundsUnit": "lbs",
  "SiteSummaryLeaderboardPage-safeLiftScore": "SafeLift Score",
  "SiteSummaryLeaderboardPage-weightOffloaded": "Weight Offloaded",
  "SiteSummaryReportDrawer-activeInactiveWorkersCheckbox": "Active/Inactive Workers List",
  "SiteSummaryReportDrawer-contentMessage":
    "The site summary report covers a 7 day period. Please select the desired end date for the report.",
  "SiteSummaryReportDrawer-displayWorkersWeightOffloadedCheckbox": "Display Workers Weight Offloaded",
  "SiteSummaryReportDrawer-editConfigurationButton": " Edit Default Configuration ",
  "SiteSummaryReportDrawer-errorMessage": "An error occurred while fetching the data",
  "SiteSummaryReportDrawer-featuresToIncludeMessage": "Features to include on the report.",
  "SiteSummaryReportDrawer-includeWorkerReportGroups": "Include Worker Report groups:",
  "SiteSummaryReportDrawer-leaderboardCheckbox": "Leaderboard",
  "SiteSummaryReportDrawer-onlyAdminsMessage": "Only Administrators can edit.",
  "SiteSummaryReportDrawer-reportEndDate": "Report End Date:",
  "SiteSummaryReportDrawer-safeliftScoreCheckbox": "Display Safelift Score",
  "SiteSummaryReportDrawer-select": "Select:",
  "SiteSummaryReportDrawer-updateConfigButton": "Update Configuration",
  "SiteSummaryReportDrawer-updateError": "An error occurred while attempting to update the site's summary report configuration",
  "SiteSummaryReportDrawer-updateSuccess": "Site's summary report configuration update successful",
  "SiteSummaryReportModal-activeInactiveWorkersCheckbox": "Active/Inactive Workers List",
  "SiteSummaryReportModal-contentMessage":
    "The site summary report covers a 7 day period. Please select the desired end date for the report.",
  "SiteSummaryReportModal-displayWorkersWeightOffloadedCheckbox": "Display Workers Weight Offloaded",
  "SiteSummaryReportModal-errorMessage": "An error occurred while fetching the data",
  "SiteSummaryReportModal-featuresToIncludeMessage": "Features to include on the report.",
  "SiteSummaryReportModal-includeWorkerReportGroups": "Include Worker Report groups:",
  "SiteSummaryReportModal-leaderboardCheckbox": "Leaderboard",
  "SiteSummaryReportModal-safeliftScoreCheckbox": "Display Safelift Score",
  "SiteSummaryReportModal-save": "Save",
  "SiteSummaryReportModal-select": "Select:",
  "SiteSummaryReportModal-onlyAdminsMessage": "Only Administrators can edit.",
  "SiteSummaryReportModal-title": "Edit Default Configuration",
  "SiteSummaryReportModal-updateError": "An error occurred while attempting to update the site's summary report configuration",
  "SiteSummaryReportModal-updateSuccess": "Site's summary report configuration update successful",
  "SiteSummaryReportModal-workerGroupsPlaceholder": "Worker groups",
  "SiteSummaryReportPDF-aboveTarget": "Above Target",
  "SiteSummaryReportPDF-active": "Active",
  "SiteSummaryReportPDF-activeWorker": "Active Worker",
  "SiteSummaryReportPDF-belowTarget": "Below Target",
  "SiteSummaryReportPDF-exempt": "Exempt/Unset",
  "SiteSummaryReportPDF-fileName": "Site Summary Report.pdf",
  "SiteSummaryReportPDF-footer": "Page {currentPage} of {totalPages}",
  "SiteSummaryReportPDF-headerDates": "{startDate} to {endDate}",
  "SiteSummaryReportPDF-inactive": "Inactive",
  "SiteSummaryReportPDF-inactiveWorker": "Inactive Worker",
  "SiteSummaryReportPDF-kilogramsUnit": "kgs",
  "SiteSummaryReportPDF-onTarget": "On Target",
  "SiteSummaryReportPDF-point": "•",
  "SiteSummaryReportPDF-poundsUnit": "lbs",
  "SiteSummaryReportPDF-totalUsageHours": "Total Usage Hours",
  "SiteSummaryReportPDF-usageHoursTargetUtilization": "Worker Usage Hours Status",
  "SiteSummaryReportPDF-totalWeightOffloaded": "All Time Weight Offloaded: ",
  "SiteSummaryReportPDF-weeklySummaryReport": "Weekly Summary Report",
  "SiteSummaryReportPDF-weightOffloadedTitle": "Weight Offloaded",
  "SiteSummaryReportPDF-workerReportGroups": "Worker Report Groups",
  "SiteSummaryReportPDF-workerStatusCounts": "Worker Status Counts",
  "SiteSummaryReportPreviewModal-download": "Download",
  "SiteSummaryReportPreviewModal-title": "Preview",
  "SitesTable-departmentsColumnTitle": "# Departments",
  "SitesTable-devicesColumnTitle": "# Devices",
  "SitesTable-nameColumnTitle": "Site Name",
  "SiteWorkersTable-assignedDeviceColumnTitle": "Assigned Device",
  "SiteWorkersTable-daysSinceLastUsageColumnTitle": "Days Since Last Usage",
  "SiteWorkersTable-departmentColumnTitle": "Department",
  "SiteWorkersTable-employeeIDColumnTitle": "Employee ID",
  "SiteWorkersTable-naLabel": "N/A",
  "SiteWorkersTable-nameColumnTitle": "Name",
  "SiteWorkersTable-report": "Report",
  "StatusChart-activeWorkers": "Active",
  "StatusChart-assignedDevices": "Assigned",
  "StatusChart-availableDevices": "Available",
  "StatusChart-deviceStatusTitle": "Devices",
  "StatusChart-inactiveWorkers": "Inactive",
  "StatusChart-moreInformationText": "*Tap over for more information",
  "StatusChart-outOfServiceDevices": "Out of Service",
  "StatusChart-statusChartTitle":
    "{entityType, plural, =0 {Organization} =1 {Site} =2 {Department} =3 {Worker Report Group} other {Organization}} Status",
  "StatusChart-waitlistWorkers": "Waitlist",
  "StatusChart-workerStatusTitle": "Workers",
  "Subheader-actions-allWorkersReport": "All Workers Report",
  "Subheader-actions-demoReport": "Demo Report",
  "Subheader-actions-summaryReport": "Summary Report",
  "SummaryReportDrawer-contentMessage":
    "The department summary report covers a 7 day period. Please select the desired end date for the report.",
  "SummaryReportDrawer-drawerTitle": "Summary Report",
  "SummaryReportDrawer-fileName": "Summary Report",
  "TargetUtilizationHoursModal-adminMessage": "Only Administrators can edit these targets.",
  "TargetUtilizationHoursModal-afterWeek": "After Week {week}:",
  "TargetUtilizationHoursModal-configurationErrorMessage": "An error occurred while attempting to update the configuration",
  "TargetUtilizationHoursModal-configurationSuccessMessage": "Target criteria was successfully configured",
  "TargetUtilizationHoursModal-constantTarget": "Constant Target",
  "TargetUtilizationHoursModal-constantTargetLabel": "Select a fixed target of hours per day across all workers:",
  "TargetUtilizationHoursModal-departmentConfiguration": "Your administrators have set the department {configName} to be:",
  "TargetUtilizationHoursModal-departmentConfigurationConstantTarget":
    "Your administrators have set a department constant target of {hours} hrs/day.",
  "TargetUtilizationHoursModal-departmentConfigurationNoTarget":
    "Your administrators have not configured target utilization hours for this department.",
  "TargetUtilizationHoursModal-departmentConfigurationOnboardingCalendar":
    "Your administrators have set the department Onboarding Calendar to be:",
  "TargetUtilizationHoursModal-hoursDay": "hrs/day",
  "TargetUtilizationHoursModal-hoursPerDay": "Hours per Day",
  "TargetUtilizationHoursModal-infoMessage":
    "Target Utilization Calculation assumes a 5 day work week. Other schedules are currently not supported",
  "TargetUtilizationHoursModal-noTarget": "No Target",
  "TargetUtilizationHoursModal-onboardingCalendar": "Onboarding Calendar",
  "TargetUtilizationHoursModal-onboardingCalendarLabel":
    "Select a target of hours per day that changes based on a 4 week onboarding calendar.",
  "TargetUtilizationHoursModal-subtitle": "Select how the department wants to utilize the target.",
  "TargetUtilizationHoursModal-title": "Target Device Utilization",
  "TargetUtilizationHoursModal-week": "Week {week}:",
  "UnenrollmentReason-other": "Other",
  "UnenrollmentReason-subtitle": "Please check all reasons why **{workerIdentifier}** unenrolled from the program",
  "UnenrollmentReason-title": "Unenrollment Reason",
  "UnenrollmentReason-unenrollmentReasonError": "An error occurred while attempting to log the unenrollment reasons",
  "UnenrollmentReasons-finished": "Finished",
  "UnenrollmentReasons-inProgress": "In Progress",
  "UnenrollmentReasons-openGlossary": "Open Glossary",
  "UnenrollmentReasons-openGlossaryExtraDesktop": "Hover to view details of individual unenrollment reasons or",
  "UnenrollmentReasons-primaryReason": "Primary reason",
  "UnenrollmentReasons-sceondaryReasons": "Secondary reasons",
  "UnenrollmentReasons-subtitleFirstStep":
    "Please check a single **primary reason** why **{workerIdentifier}** is unenrolled from the program.",
  "UnenrollmentReasons-subtitleSecondStep":
    "Please check as multiple **secondary reasons** as necesary for worker **{workerIdentifier}** unenrollment from the program (optional).",
  "UnenrollmentReasons-waiting": "Waiting",
  "UnenrollmentReasonsGlossary-title": "Unenrollment Reasons Glossary",
  "UnenrollmentReasonsGlossary-description": "Description",
  "UnenrollmentReasonsGlossary-descriptionTerminatedQuit": "User left the company (either quit or was fired)",
  "UnenrollmentReasonsGlossary-descriptionUnknown": "Lost contact with user. Do not know why they left program. ",
  "UnenrollmentReasonsGlossary-descriptionBack": "Back discomfort (lower or upper)",
  "UnenrollmentReasonsGlossary-descriptionSuitHeavyShoulderPressure":
    "General shoulder discomfort from pressure caused by the suit weight/pulling",
  "UnenrollmentReasonsGlossary-descriptionShoulderStrapIrritationPointPressure":
    "Specific irritation/point pressure resulting from the shoulder strap poking/rubbing the user",
  "UnenrollmentReasonsGlossary-descriptionHeat": "User is too hot in suit",
  "UnenrollmentReasonsGlossary-descriptionConstriction":
    "User just feels contricted, either in the chest or just general apprehension of wearing something they are not used to ",
  "UnenrollmentReasonsGlossary-descriptionGeneral":
    "User just finds the suit overall uncomfortable and can't point to a single location of discomfort",
  "UnenrollmentReasonsGlossary-descriptionDoesntNeedIt":
    "User just feels they personally don't benefit from the suit (but their job function is still a good fit)",
  "UnenrollmentReasonsGlossary-descriptionTimeHabit":
    "User feels the suit takes too up much time and/or finds it difficult to build the habbit to put it on",
  "UnenrollmentReasonsGlossary-descriptionPreexistingCondition":
    "User has some pre-existing condition that they believe does not align well with the suit (sholder injury, knee injury etc.)",
  "UnenrollmentReasonsGlossary-descriptionOnLeave": "User is on leave from work for a prolonged period of time",
  "UnenrollmentReasonsGlossary-descriptionLimitsOverheadReaching": "The suit limits the users overhead reaching or makes it uncomfortable.",
  "UnenrollmentReasonsGlossary-descriptionThighWrapsDropping":
    "The user needs to constantly re-adjust the thigh wraps and it's too much of an inconvenience",
  "UnenrollmentReasonsGlossary-descriptionExtraHeightWidthOfSuitOnBack":
    "The thickness of the suit prevents the user from moving as they normally would (i.e. they have to bend down more in low racks,  they can't squeeze into small spaces)",
  "UnenrollmentReasonsGlossary-descriptionAssistanceTimingWrongJerky":
    "The user perceives that the suit assistance is not in sync with their movements",
  "UnenrollmentReasonsGlossary-descriptionJobFunctionChange":
    "The user changed jobs and their new job no longer requires an exosuit (i.e. forklift driver)",
  "UnenrollmentReasonsGlossary-descriptionPerformanceProductivityConcerns":
    "The user percieves that the suit is slowing them down and/or negatively impacting their productivity metrics.",
  "UnenrollmentReasonsGlossary-descriptionJobIsNotGoodUseCase":
    "The user percieves that the job function (which we originally thought could be helped) does not benefit from the suit. ",
  "UnenrollmentReasonsGlossary-descriptionOther":
    "Does not fit into any of the above categories or you want to log additional information/description",
  "UnenrollmentReasonsGlossary-exitReason": "Exit Reason",
  "UnenrollmentReasonsGlossary-labelTerminatedQuit": "Terminated/Quit",
  "UnenrollmentReasonsGlossary-labelUnknown": "Unknown",
  "UnenrollmentReasonsGlossary-labelBack": "Back",
  "UnenrollmentReasonsGlossary-labelSuitHeavyShoulderPressure": "Suit heavy/shoulder pressure",
  "UnenrollmentReasonsGlossary-labelShoulderStrapIrritationPointPressure": "Shoulder strap irritation/point pressure",
  "UnenrollmentReasonsGlossary-labelHeat": "Heat",
  "UnenrollmentReasonsGlossary-labelConstriction": "Constriction",
  "UnenrollmentReasonsGlossary-labelGeneral": "General",
  "UnenrollmentReasonsGlossary-labelDoesntNeedIt": "Doesn't need it",
  "UnenrollmentReasonsGlossary-labelTimeHabit": "Time/Habit",
  "UnenrollmentReasonsGlossary-labelPreexistingCondition": "Pre-existing condition",
  "UnenrollmentReasonsGlossary-labelOnLeave": "On Leave",
  "UnenrollmentReasonsGlossary-labelLimitsOverheadReaching": "Limits overhead reaching",
  "UnenrollmentReasonsGlossary-labelThighWrapsDropping": "Thigh Wraps Dropping",
  "UnenrollmentReasonsGlossary-labelExtraHeightWidthOfSuitOnBack": "Extra height/width of suit on back",
  "UnenrollmentReasonsGlossary-labelAssistanceTimingWrongJerky": "Assistance timing wrong/jerky",
  "UnenrollmentReasonsGlossary-labelJobFunctionChange": "Job Function Change",
  "UnenrollmentReasonsGlossary-labelPerformanceProductivityConcerns": "Performance/Productivity Concerns",
  "UnenrollmentReasonsGlossary-labelJobIsNotGoodUseCase": "Job Is Not Good Use Case",
  "UnenrollmentReasonsGlossary-labelOther": "Other",
  "UnenrollmentReasonsGlossary-subTitleUnknown": "Unknown",
  "UnenrollmentReasonsGlossary-subTitleTerminatedQuit": "Terminated/Quit",
  "UnenrollmentReasonsGlossary-subTitleOther": "Other",
  "UnenrollmentReasonsGlossary-subTitleDiscomfort": "Discomfort",
  "UnenrollmentReasonsGlossary-subTitleConvenience": "Convenience",
  "UnenrollmentReasonsGlossary-subTitlePersonal": "Personal",
  "UnenrollmentReasonsGlossary-subTitleSuitLimitations": "Suit Limitations",
  "UnenrollmentReasonsGlossary-subTitleJob": "Job",
  "UnenrollWorkerMainReason-other": "Other",
  "UnenrollWorkerMainReason-terminated": "Terminated/Quit",
  "UnenrollWorkerMainReason-unknown": "Unknown",
  "UnenrollWorkerOtherReason-assistance_time_wrong": "Assistance Timing Wrong/Jerky",
  "UnenrollWorkerOtherReason-back": "Back",
  "UnenrollWorkerOtherReason-constriction": "Constriction",
  "UnenrollWorkerOtherReason-convenience": "Convenience",
  "UnenrollWorkerOtherReason-discomfort": "Discomfort",
  "UnenrollWorkerOtherReason-extra_height_width": "Extra height/width of suit on back",
  "UnenrollWorkerOtherReason-general": "General",
  "UnenrollWorkerOtherReason-heat": "Heat",
  "UnenrollWorkerOtherReason-heavy_shoulder_pressure": "Suit Heavy/Shoulder Pressure",
  "UnenrollWorkerOtherReason-job": "Job",
  "UnenrollWorkerOtherReason-job_function_change": "Job Function Change",
  "UnenrollWorkerOtherReason-limits_overhead_reaching": "Limits overhead reaching",
  "UnenrollWorkerOtherReason-no_need": "Doesn't need it",
  "UnenrollWorkerOtherReason-on_leave": "On Leave",
  "UnenrollWorkerOtherReason-performance_concern": "Performance/Productivity Concerns",
  "UnenrollWorkerOtherReason-personal": "Personal",
  "UnenrollWorkerOtherReason-preexisting_condition": "Pre-Existing Condition",
  "UnenrollWorkerOtherReason-shoulder_point_pressure": "Shoulder Strap irritation/point pressure",
  "UnenrollWorkerOtherReason-suitLimitations": "Suit Limitations",
  "UnenrollWorkerOtherReason-thigh_wraps_dropping": "Thigh wraps dropping",
  "UnenrollWorkerOtherReason-time_habit": "Time/Habit",
  "UnenrollWorkerOtherReason-use_case": "Job Is Not A Good Use Case",
  "Unsubscribe-accountSettings": "Account Settings",
  "Unsubscribe-subText": "Please click the button below to unsubscribe from Administrative Emails on Verve Logic.",
  "Unsubscribe-title": "Unsubscribe from Administrative Emails",
  "Unsubscribe-unsubscribe": "Unsubscribe",
  "Unsubscribe-unsubscribedSubText":
    "You have successfully unsubscribed from Administrative Emails on Verve Logic. To re-subscribe please edit your",
  "UpdateDeviceConfigurationForm-buttonLabel": "Button {number}:",
  "UpdateDeviceConfigurationForm-buttonSettingsSubtitle": "Button Settings",
  "UpdateDeviceConfigurationForm-description": "Please select the settings you would like to update the device to:",
  "UpdateDeviceConfigurationForm-firmwareVersionLabel": "Firmware Version:",
  "UpdateDeviceConfigurationForm-hardwareRevLabel": "Hardware Rev:",
  "UpdateDeviceConfigurationForm-noButtonSettings": "No Button Settings found",
  "UpdateDeviceConfigurationForm-noFirmwareVersions": "No Firmware Versions found",
  "UpdateDeviceConfigurationForm-systemSerialNumberLabel": "System Serial #:",
  "UpdateDeviceConfigurationModal-scheduleUpdateButtonText": "Schedule Update",
  "UpdateDeviceConfigurationModal-title": "Update Device Configuration",
  "UpdateDeviceConfigurationModal-updateError": "An error occurred while attempting to update the device configuration",
  "UpdateDeviceConfigurationModal-updateSuccess": "Device Configuration update was successfully scheduled.",
  "UsageChart-averageUsageHoursLabel": "Average hours",
  "UsageChart-totalUsageHoursLabel": "Total usage hours",
  "UserPermissions-accessLevel": "Access Level",
  "UserPermissions-active": "Active",
  "UserPermissions-admin": "Admin",
  "UserPermissions-changeAccessLevelError": "An error occurred while attempting to update the user access level.",
  "UserPermissions-changeAccessLevelText":
    "Please confirm that you want to change the Access Level of **{givenName} {familyName}** for the **{organizationName}** organization from **{previousLevel}** to **{newLevel}**.",
  "UserPermissions-changeAccessLevelTitle": "Change Access Level",
  "UserPermissions-changeActiveError": "An error occurred while attempting to update the user.",
  "UserPermissions-confirm": "Confirm",
  "UserPermissions-custom": "Custom",
  "UserPermissions-deactivateUser": "Deactivate User",
  "UserPermissions-deactivateUserText":
    "Please confirm that you want to deactivate **{givenName} {familyName}** as part of the **{organizationName}** organization.",
  "UserPermissions-email": "Email",
  "UserPermissions-fetchDataError": "An error occurred while attempting to get the users data",
  "UserPermissions-firstName": "First Name",
  "UserPermissions-global": "Global",
  "UserPermissions-inactive": "Inactive",
  "UserPermissions-lastName": "Last Name",
  "UserPermissions-manager": "Manager",
  "UserPermissions-needToBeAdminOrTrustedUserError": "You need to be an admin or trusted user",
  "UserPermissions-needToBeAnAdminError": "You need to be an admin user",
  "UserPermissions-none": "None",
  "UserPermissions-organization": "Organization",
  "UserPermissions-reactivateUser": "Reactivate User",
  "UserPermissions-reactivateUserText":
    "Please confirm that you want to reactivate **{givenName} {familyName}** to join the **{organizationName}** organization.",
  "UserPermissions-readOnly": "Read-Only",
  "UserPermissions-statusColumnTitle": "Status",
  "UserPermissions-title": "User Permissions",
  "Validation-confirmPasswordError": "Confirm password doesn't match password",
  "WorkerCreationCSVModal-columns": "Columns: First Name, Last Name, Employee ID",
  "WorkerCreationCSVModal-createWorkers": "Create Workers",
  "WorkerCreationCSVModal-description": "Please upload a CSV file of workers you wish to create in **{siteName}** - **{departmentName}**.",
  "WorkerCreationCSVModal-successMessage": "CSV file successfully uploaded",
  "WorkerCreationManualModal-createAnotherWorker": "Create another worker",
  "WorkerCreationManualModal-createError": "An error occurred while attempting to create the worker",
  "WorkerCreationManualModal-createdSuccessMessage": "Worker successfully created",
  "WorkerCreationManualModal-createWorkers": "Create Workers",
  "WorkerDetail-assignedDevice": "Device:",
  "WorkerDetail-cannotDeleteMessage":
    "This worker cannot be deleted as they are assigned to a device and/or sessions. Please remove these assignments and try again.",
  "WorkerDetail-dateFirstUsage": "First Used:",
  "WorkerDetail-dateLastUsage": "Last Used:",
  "WorkerDetail-deleteAlertTitle": "Delete Worker",
  "WorkerDetail-deleteAlertMessage": "Are you sure you want to delete this worker?",
  "WorkerDetail-deleteError": "An error occurred while attempting to delete the worker",
  "WorkerDetail-deleteWorker": "Delete Worker",
  "WorkerDetail-deviceAssignment": "Device Assignment",
  "WorkerDetail-deviceAssignmentError":
    "An error occurred while attempting to update the Assigned Device to this Worker, the other details were updated successfully",
  "WorkerDetail-deviceNoLongerHasWorker": "Device No Longer Has A Worker",
  "WorkerDetail-disabledDevice": "Device cannot be changed for Unenrolled workers",
  "WorkerDetail-drawerTitle": "Worker Details",
  "WorkerDetail-empIdAlreadyExistsValidation": "Worker with this Employee ID already exists in site {siteName}",
  "WorkerDetail-employeeId": "Employee ID:",
  "WorkerDetail-enrolled": "Enrolled",
  "WorkerDetail-errorFetchingWorkers": "Error fetching workers",
  "WorkerDetail-exemptFromUsageTarget": "Exempt From Usage Target:",
  "WorkerDetail-firstName": "First Name:",
  "WorkerDetail-fetchSessionsDataError": "An error occurred while fetching the data",
  "WorkerDetail-ifFirstNameRequireLastNameValidation": "If First Name is specified, Last Name should be too",
  "WorkerDetail-ifLastNameRequireFirstNameValidation": "If Last Name is specified, First Name should be too",
  "WorkerDetail-ifNoNameRequireEmpIdValidation": "If both First Name and Last Name are omitted, Employee ID must be specified",
  "WorkerDetail-lastName": "Last Name:",
  "WorkerDetail-logReasonWorkerUnenrolling": "Please log the reason this worker in unenrolling:",
  "WorkerDetail-name": "Name:",
  "WorkerDetail-nameAlreadyExistsValidation": "Worker with this name already exists in site {siteName}",
  "WorkerDetail-noneOption": "None",
  "WorkerDetail-preferredLanguage": "Preferred Language:",
  "WorkerDetail-reportGroups": "Report Groups:",
  "WorkerDetail-reportGroupsNumber": "{quantity} more...",
  "WorkerDetail-status": "Status:",
  "WorkerDetail-targetHoursStartDate": "Target Hours Start Date:",
  "WorkerDetail-title": "Worker Details",
  "WorkerDetail-totalHours": "Total Hours:",
  "WorkerDetail-totalLifts": "Total Lifts:",
  "WorkerDetail-unenrolled": "Unenrolled",
  "WorkerDetail-updateError": "An error occurred while attempting to update the worker data",
  "WorkerDetail-usageTargetEnabled": "Enabled",
  "WorkerDetail-usageTargetDisabled": "Disabled",
  "WorkerDetail-workerProfile": "Worker Profile",
  "WorkerDetail-workerStatistics": "Worker Stats",
  "WorkerDetail-workerStatus": "Worker Status",
  "WorkerDetailOld-assignedDevice": "Assigned Device:",
  "WorkerDetailOld-dateFirstUsage": "First Usage:",
  "WorkerDetailOld-dateLastUsage": "Last Usage:",
  "WorkerDetailOld-employeeId": "Employee ID:",
  "WorkerDetailOld-firstName": "First Name:",
  "WorkerDetailOld-lastName": "Last Name:",
  "WorkerDetailOld-status": "Status:",
  "WorkerDetailOld-totalHours": "Total Hours:",
  "WorkerDetailOld-totalLifts": "Total Lifts:",
  "WorkerDetailOld-workerStatistics": "Worker Statistics",
  "WorkerDeviceAssignmentModal-changeDeviceError": "An error occurred while attempting to update the worker data",
  "WorkerDeviceAssignmentModal-device": "Device:",
  "WorkerDeviceAssignmentModal-subtitle": "Select device for **{workerName}**",
  "WorkerDeviceAssignmentModal-updateSuccessMessage": "Worker successfully updated",
  "WorkerEditModal-assignedDevice": "Device:",
  "WorkerEditModal-changeStatusError": "An error occurred while attempting to change the status",
  "WorkerEditModal-employeeId": "Employee ID:",
  "WorkerEditModal-enrolled": "Enrolled",
  "WorkerEditModal-exemptFromUsageTarget": "Exempt From Usage Target:",
  "WorkerEditModal-firstName": "First Name:",
  "WorkerEditModal-lastName": "Last Name:",
  "WorkerEditModal-reason": "Reason:",
  "WorkerEditModal-status": "Status:",
  "WorkerEditModal-targetHoursStartDate": "Target Hours Start Date:",
  "WorkerEditModal-title": "Edit Worker Profile",
  "WorkerEditModal-unenrolled": "Unenrolled",
  "WorkerEditModal-updateError": "An error occurred while attempting to update the worker data",
  "WorkerEditModal-updateSuccessMessage": "Worker successfully updated",
  "WorkerEditModal-workerReportGroups": "Report Groups:",
  "WorkersFilters-allTagText": "All",
  "WorkersFilters-assignedTagText": "Assigned",
  "WorkersFilters-enrolledTagText": "Enrolled",
  "WorkersFilters-searchButtonText": "Search Workers",
  "WorkersFilters-unenrolledTagText": "Unenrolled",
  "WorkersFilters-waitlistTagText": "Waitlist",
  "WorkerForm-createError": "An error occurred while attempting to create the workers",
  "WorkerForm-deviceInputLabel": "Device",
  "WorkerForm-employeeIdInputLabel": "Employee ID",
  "WorkerForm-exemptFromUsageTarget": "Exempt From Usage Target",
  "WorkerForm-firstNameInputLabel": "First Name",
  "WorkerForm-lastNameInputLabel": "Last Name",
  "WorkerForm-noneOption": "None",
  "WorkerForm-preferredLanguageInputLabel": "Preferred Language",
  "WorkerForm-targetHoursStartDate": "Target Hours Start Date",
  "WorkerForm-workerReportGroupsInputLabel": "Report Groups",
  "WorkerNoDevice-assignErrorMessage": "An error occurred while attempting to assign the worker to the device",
  "WorkerNoDevice-assignNewDevice": "Assign a new device",
  "WorkerNoDevice-assignNewDevice-placeholder": "Select",
  "WorkerNoDevice-assignNewDeviceSuccessMessage": "Worker {workerName} was successfully assigned device {deviceTag}",
  "WorkerNoDevice-assignSuccessMessage": "Assigned successful",
  "WorkerNoDevice-device": "Device:",
  "WorkerNoDevice-firstSubtitle": "**{name}** no longer has an assigned device.",
  "WorkerNoDevice-leaveUnassigned": "Leave Unassigned",
  "WorkerNoDevice-leaveUnassignedSuccessMessage": "Worker {workerName} was left without an assigned device",
  "WorkerNoDevice-reason": "Reason:",
  "WorkerNoDevice-secondSubtitle": "What would you like to do with this worker?",
  "WorkerNoDevice-title": "Worker Status",
  "WorkerNoDevice-unenrollWorker": "Unenroll Worker",
  "WorkerNoDevice-unenrollWorkerSuccessMessage": "Worker {workerName} was successfully unenrolled",
  "WorkerNoDeviceOld-unenrollWorker": "Unenroll Worker. Reason:",
  "WorkerPreferredLanguage-enUS": "English - United States",
  "WorkerPreferredLanguage-esMX": "Spanish - Mexico",
  "WorkerPreferredLanguage-frCA": "French - Canada",
  "WorkerPreferredLanguage-htHT": "Creole - Haiti",
  "WorkerPreferredLanguage-none": "None",
  "WorkerPreferredLanguage-ptBR": "Portuguese - Brazil",
  "WorkerReportGroupCard-numberOfWorkers": "{numberOfWorkers} {numberOfWorkers, plural, =0 {workers} =1 {worker} other {workers}}",
  "WorkerReportGroupDetail-workerColumn": "Worker",
  "WorkerReportGroupDetail-departmentColumn": "Department",
  "WorkerReportGroupDetail-statusColumn": "Status",
  "WorkerReportGroupDetail-allTagText": "All",
  "WorkerReportGroupDetail-enrolledTagText": "Enrolled",
  "WorkerReportGroupDetail-unenrolledTagText": "Unenrolled",
  "WorkerReportGroupDetail-csv": "{tag} worker report group {name}.csv",
  "WorkerReportGroupDetail-addWorkers": "Add Workers",
  "WorkerReportGroupDetail-allOrganizations": "All Organizations",
  "WorkerReportGroupDetail-deleteAlertMessage": "Are you sure you want to delete **{groupName}**?",
  "WorkerReportGroupDetail-deleteAlertTitle": "Delete Group",
  "WorkerReportGroupDetail-deleteError": "The worker report group could not be deleted",
  "WorkerReportGroupDetail-deleteSuccess": "{groupName} was successfully deleted",
  "WorkerGroupDetail-errorMessage": "An error occurred while attempting to load the worker data",
  "WorkerReportGroupDetail-fetchDataError": "An error occurred while attempting to get the worker report group data",
  "WorkerReportGroupDetail-removeWorkerAlertMessage": "Are you sure you want to remove worker **{workerName}** from this group?",
  "WorkerReportGroupDetail-removeWorkerError": "The worker could not be removed from the group",
  "WorkerReportGroupDetail-removeWorkerModalTitle": "Remove Worker",
  "WorkerReportGroupDetail-removeWorkerSuccess": "Worker {workerName} was successfully removed from the group",
  "WorkerReportGroupDetail-rename": "Rename",
  "WorkerReportGroupsDisplay-emptyStateText": "No groups created yet, click on Create New Group to add a new one",
  "WorkerReportGroupsDisplay-fetchWorkerReportGroupsDataError": "An error occurred while attempting to get the worker report groups data",
  "WorkerReportGroupsDisplay-resultAltText": "User Group Icon",
  "WorkerReportGroupsTabContent-createNewGroupButton": "Create New Group",
  "WorkerReportGroupWorkersTable-department": "Department",
  "WorkerReportGroupWorkersTable-remove": "Remove",
  "WorkerReportGroupWorkersTable-status": "Status",
  "WorkerReportGroupWorkersTable-worker": "Worker",
  "WorkersTabContent-allTagText": "All",
  "WorkersTabContent-allWorkersReportButtonText": "All Workers Report",
  "WorkersTabContent-assignedTagText": "Assigned",
  "WorkersTabContent-createWorkersButtonText": "Create Workers",
  "WorkersTabContent-csv": "{tag} workers.csv",
  "WorkersTabContent-deleteSuccess": "Worker successfully deleted",
  "WorkersTabContent-deviceColumnTitle": "Device",
  "WorkersTabContent-employeeIDColumnTitle": "Employee ID",
  "WorkersTabContent-enrolledTagText": "Enrolled",
  "WorkersTabContent-fetchWorkersDataError": "An error occurred while attempting to get the workers data",
  "WorkersTabContent-nameColumnTitle": "Name",
  "WorkersTabContent-statusColumnTitle": "Status",
  "WorkersTabContent-unenrolledTagText": "Unenrolled",
  "WorkersTabContent-waitlistTagText": "Waitlist",
};

const coreLocale: Partial<CoreLocale> = {};

CoreHelper.mergeWith(baseCoreLocale, CoreHelper.mergeWith(coreLocale, uiLocale));
