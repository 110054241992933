import {SessionAPIClient} from "../apiclient/session/SessionAPIClient";
import * as SessionTypes from "../apiclient/session/SessionAPIClient.types";

async function sessions(request: SessionTypes.ShortSessionsRequest): Promise<SessionTypes.ShortSessionsResponse> {
  return await SessionAPIClient.sessions(request);
}

async function updateSessionAssignment(
  request: SessionTypes.UpdateSessionAssignmentRequest
): Promise<SessionTypes.UpdateSessionAssignmentResponse> {
  return await SessionAPIClient.updateSessionAssignment(request);
}

async function sessionLog(request: SessionTypes.SessionLogRequest): Promise<SessionTypes.SessionLogResponse> {
  return await SessionAPIClient.sessionLog(request);
}

async function assignSessions(request: SessionTypes.AssignSessionsRequest): Promise<SessionTypes.AssignSessionsResponse> {
  return await SessionAPIClient.assignSessions(request);
}

export const SessionModule = {
  sessions,
  updateSessionAssignment,
  sessionLog,
  assignSessions,
};
