import * as Types from "./UserAPIClient.types";
import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";

export class UserAPIClient extends BaseAPIClient {
  static async fetchMe(request: Types.FetchMeRequest, options: RequestOptions = {}): Promise<Types.FetchMeResponse> {
    const {sub, accessToken} = request;
    const {token} = accessToken;
    if (!sub) {
      return {
        success: false,
        error: this.genericError(),
      };
    }

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `users/`,
      headers: {token},
      request,
      options,
      params: {
        sub,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          user: Models.User.fromJSON(data[0]),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateMyUserProfile(
    request: Types.UpdateMyUserProfileRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateMyUserProfileResponse> {
    const {id, job_title, department, phone_number, address, accessToken, subscribed} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `users/${id}/profile`,
      headers: {token},
      request,
      options,
      data: {job_title, department, phone_number, address, subscribed},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          user: Models.User.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async updateUserSubscription(request: Types.UpdateUserSubscriptionRequest): Promise<Types.UpdateUserSubscriptionResponse> {
    const {id, subscribe, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `users/${id}/subscribe`,
      headers: {token},
      request,
      data: {subscribe},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          user: Models.User.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async users(request: Types.UsersRequest, options: RequestOptions = {}): Promise<Types.UsersResponse> {
    const {
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `users/`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;

      if (data) {
        return {
          success: true,
          users: data.map((user: Models.User) => Models.User.fromJSON(user)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateUser(request: Types.UpdateUserRequest, options: RequestOptions = {}): Promise<Types.UpdateUserResponse> {
    const {id, sub, active, email, organization_id, preferences, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `users/${id}`,
      headers: {token},
      request,
      options,
      data: {id, sub, active, email, organization_id, preferences},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          user: Models.User.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async changeUserPermission(
    request: Types.ChangeUserPermissionRequest,
    options: RequestOptions = {}
  ): Promise<Types.ChangeUserPermissionResponse> {
    const {level, scope, user_id, resource_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `users/${user_id}/permissions`,
      headers: {token},
      request,
      options,
      data: {level, scope, resource_id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          user: Models.User.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async changeUserPermissionMultiple(
    request: Types.ChangeUserPermissionMultipleRequest,
    options: RequestOptions = {}
  ): Promise<Types.ChangeUserPermissionResponse> {
    const {permissions, accessToken} = request;
    const {token} = accessToken;

    const data = permissions.map((item) => ({
      scope: item.scope,
      resource_id: item.resource_id,
      level: item.level,
    }));

    const user_id = permissions[0].user_id;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `users/${user_id}/permissions`,
      headers: {token},
      request,
      options,
      data: data,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          user: Models.User.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async changeUserActive(
    request: Types.ChangeUserActiveRequest,
    options: RequestOptions = {}
  ): Promise<Types.ChangeUserActiveResponse> {
    const {active, user_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `users/${user_id}/active`,
      headers: {token},
      request,
      options,
      data: {active},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          user: Models.User.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async getNotifications(request: Types.NotifcationsRequest, options: RequestOptions = {}): Promise<Types.NotificationsResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `notifications/${id}/list?acknowledged=false`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          notifications: data.map((notification: Models.Notification) => Models.Notification.fromJSON(notification)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async acknowledgeNotifications(
    request: Types.AcknowledgeNotifcationsRequest,
    options: RequestOptions = {}
  ): Promise<Types.AcknowledgeNotificationsResponse> {
    const {notification_ids, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `notifications/acknowledge`,
      headers: {token},
      request,
      options,
      data: {notification_ids},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }
}
