import {Button, Tooltip} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import styles from "./ChangeWorkerStatusButton.module.scss";
import workerEnrolledStatusIcon from "../../../assets/images/common/Worker_Enrolled_Status_Icon.svg";
import workerUnenrolledStatusIcon from "../../../assets/images/common/Worker_Unenrolled_Status_Icon.svg";
import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../utils/WebHelper";

export type ChangeWorkerStatusButtonProps = {
  worker: Models.WorkerShort | Models.Worker;
  onOpenChangeWorkerStatusModal: (worker: Models.WorkerShort | Models.Worker) => void;
};

export const ChangeWorkerStatusButton: FunctionComponent<ChangeWorkerStatusButtonProps> = observer((props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  return (
    <Button
      className={props.worker.assignable ? styles.enrolledIcon : styles.unenrolledIcon}
      type="text"
      onClick={() => props.onOpenChangeWorkerStatusModal(props.worker)}
      disabled={
        !(authenticationStore.permissionLevel(Models.UserScope.Department, props.worker.department?.id) === Models.UserAccessLevel.Admin) &&
        !(authenticationStore.permissionLevel(Models.UserScope.Department, props.worker.department?.id) === Models.UserAccessLevel.Manager)
      }
      icon={
        props.worker.assignable ? (
          <Tooltip title={WebHelper.formatMessage("Common-enrolledTooltip")}>
            <img src={workerEnrolledStatusIcon} alt={WebHelper.formatMessage("Common-enrolledTooltip")} />
          </Tooltip>
        ) : (
          <Tooltip title={WebHelper.formatMessage("Common-unenrolledTooltip")}>
            <img src={workerUnenrolledStatusIcon} alt={WebHelper.formatMessage("Common-unenrolledTooltip")} />
          </Tooltip>
        )
      }
    />
  );
});
