import {DownloadOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {useQueries, useQuery} from "@tanstack/react-query";
import {Dropdown, Modal, Typography} from "antd";
import {WorkerModule} from "core/modules/WorkerModule";
import {WorkerReportGroupModule} from "core/modules/WorkerReportGroupModule";
import {
  isAfter,
  addDays,
  differenceInDays,
  format,
  secondsToHours,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfQuarter,
  endOfWeek,
  endOfMonth,
  addQuarters,
  endOfQuarter,
  addMonths,
  addWeeks,
  differenceInMonths,
} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import {useFlags} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {GatewayAlertBlock} from "web/components/gateway-alert-block/GatewayAlertBlock";

import {AnalyticsDetailsModal} from "./analytics-details-modal/AnalyticsDetailsModal";
import {AnalyticsFilterOptions} from "./analytics-settings/AnalyticsSettings";
import {AnalyticsSettingsDrawer} from "./analytics-settings-drawer/AnalyticsSettingsDrawer";
import styles from "./AnalyticsTabContent.module.scss";
import GraphIcon from "../../../../assets/images/common/GraphIcon.svg";
import * as Models from "../../../../core/models";
import {AnalyticsVariableToChart} from "../../../../core/models";
import {AnalyticsModule} from "../../../../core/modules/AnalyticsModule";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {useAvailableHeight} from "../../../hooks/useAvailableHeight";
import useUserPreferences from "../../../hooks/useUserPreferences";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {concat, round, isEqual, isUndefined} from "../../../utils/FunctionUtils";
import {SEGMENT_NOT_APPLICABLE_VALUE} from "../../../utils/SegmentHelper";
import {lbsToKgs, WebHelper} from "../../../utils/WebHelper";
import {AnalyticsChart} from "../../analytics-chart/AnalyticsChart";
import {Button, ButtonType} from "../../common/button/Button";

type AnalyticsTabContentProps = {
  organization?: Models.Organization;
  site?: Models.Site;
  department?: Models.Department;
  setTimeZone?: (timezone: string) => void;
};

type CheckboxValueType = string | number | boolean;

const defaultSettings: Models.AnalyticsSettings = {
  variableToChartSelected: Models.AnalyticsVariableToChart.HoursUsed,
  intervalSelected: Models.AnalyticsInterval.Day,
  startDate: addDays(new Date(), -6),
  endDate: new Date(),
  checkedMostRecent: false,
  dataType: Models.ChartDataType.Raw,
  workerStatusTypesSelected: [Models.WorkerStatusType.Active, Models.WorkerStatusType.Inactive],
  safetyTypesSelected: [Models.ChartMovementType.Safe, Models.ChartMovementType.Risky],
  movementTypesSelected: [
    Models.ChartMovementType.ExcessiveForwardBending,
    Models.ChartMovementType.ProlongedBending,
    Models.ChartMovementType.Twisting,
    Models.ChartMovementType.SideBending,
  ],
  relativeTo: Models.AnalyticsRelativeTo.CalendarDate,
  intervalHoursSelected: Models.AnalyticsIntervalHours.FifteenMinutes,
  intervalCount: 100,
  minimumLifts: 0,
  dataScope: Models.DataScope.BetweenDates,
  showTrendlines: false,
};

export const AnalyticsTabContent: FunctionComponent<AnalyticsTabContentProps> = ({organization, site, department}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const appStore = AppStore.getInstance();

  const flags = useFlags();

  const [userPreferences, setUserPreferences] = useUserPreferences();
  const chartRef = useRef<ChartJSOrUndefined<"bar", number[], string> | undefined>(null);

  const refTabContent = useRef<HTMLDivElement>(null);
  const refAnalyticsSettings = useRef<HTMLDivElement>(null);

  const [filterValue, setFilterValue] = useState<string[] | undefined>([AnalyticsFilterOptions.AllWorkers]);
  const [dataType, setDataType] = useState<Models.ChartDataType>(
    userPreferences.data.analyticsSettings?.dataType || defaultSettings.dataType
  );
  const [workerStatusTypesSelected, setWorkerStatusTypesSelected] = useState<Models.WorkerStatusType[]>(
    userPreferences.data.analyticsSettings?.workerStatusTypesSelected || defaultSettings.workerStatusTypesSelected
  );
  const [movementTypesSelected, setMovementTypesSelected] = useState<Models.ChartMovementType[]>(
    userPreferences.data.analyticsSettings?.movementTypesSelected || defaultSettings.movementTypesSelected
  );
  const [safetyTypesSelected, setSafetyTypesSelected] = useState<Models.ChartMovementType[]>(
    userPreferences.data.analyticsSettings?.safetyTypesSelected || defaultSettings.safetyTypesSelected
  );

  const [selectedWorkerGroupId, setSelectedWorkerGroupId] = useState<string | null>();
  const [selectedWorkerId, setSelectedWorkerId] = useState<string | null>();
  const [entities, setEntities] = useState<Models.AnalyticsEntity[]>(
    department
      ? [{id: department.id, name: department.name, entityType: Models.EntityType.Department}]
      : site
        ? [{id: site.id, name: site.name, entityType: Models.EntityType.Site}]
        : organization
          ? [{id: organization.id, name: organization.name, entityType: Models.EntityType.Organization}]
          : []
  );
  const [entitySelected, setEntitySelected] = useState<Models.AnalyticsEntity | undefined>(undefined);

  const [newData, setNewData] = useState<Models.AnalyticsTimeSeries[][] | Models.AnalyticsTimeSeriesIndices[][] | undefined>([]);
  const [detailsData, setDetailsData] = useState<
    {data: (Models.AnalyticsTimeSeries | Models.AnalyticsTimeSeriesIndices)[]; relativeTo: Models.AnalyticsRelativeTo} | undefined
  >(undefined);
  const [intervalLabel, setIntervalLabel] = useState("");
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [isApplyChanges, setIsApplyChanges] = useState(false);
  const [isActiveScroll, setIsActiveScroll] = useState(false);
  const [calendarDaysModalOpen, setCalendarDaysModalOpen] = useState(false);
  const [startDateToShow, setStartDateToShow] = useState("");
  const [endDateToShow, setEndDateToShow] = useState("");

  const filterValueEntity = department
    ? [{value: `department-${department.id}`, label: department.name}]
    : site
      ? [{value: `site-${site.id}`, label: site.name}]
      : organization
        ? [{value: `organization-${organization.id}`, label: organization.name}]
        : [];

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.AnalyticsOpened, {
      orgID: organization ? organization.id : site ? site.organization_id : undefined,
      siteID: site ? site.id : undefined,
      departmentID: department ? department.id : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartHeight = useAvailableHeight(refTabContent, refAnalyticsSettings);

  const SelectedWorkerGroupOrWorkerNameQuery = useQuery({
    queryKey: ["WorkerGroupOrWorkerName", selectedWorkerId, selectedWorkerGroupId],
    queryFn: async () => {
      let name = null;

      if (selectedWorkerId) {
        name = await WorkerModule.worker({id: selectedWorkerId, accessToken: authenticationStore.state.accessToken!}).then((data: any) => {
          if (data.success) return data.worker.fullName;
        });
      } else if (selectedWorkerGroupId) {
        name = await WorkerReportGroupModule.workerReportGroup({
          id: selectedWorkerGroupId,
          accessToken: authenticationStore.state.accessToken!,
        }).then((data: any) => {
          if (data.success) return data.workerReportGroup.name;
        });
      }
      return name;
    },
    enabled: false,
  });

  useEffect(() => {
    if (!!site && !department && selectedWorkerId && !selectedWorkerGroupId) {
      setFilterValue([AnalyticsFilterOptions.AllWorkers]);
      setSelectedWorkerGroupId(null);
      setSelectedWorkerId(null);
    }
  }, [site, department, selectedWorkerGroupId, selectedWorkerId]);

  const adjustAnalyticsSettings = (
    analyticsSettings: Models.AnalyticsSettings,
    organization: Models.Organization | undefined,
    site: Models.Site | undefined
  ): Models.AnalyticsSettings => {
    const shouldUpdateSettings =
      (site && !site.real_time_data_enabled) || (!site && organization && organization.sites?.some((site) => !site.real_time_data_enabled));

    if (shouldUpdateSettings) {
      if (
        analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay ||
        (analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.CalendarDate &&
          analyticsSettings.intervalSelected === Models.AnalyticsInterval.Hours)
      ) {
        return {
          ...analyticsSettings,
          relativeTo: Models.AnalyticsRelativeTo.CalendarDate,
          intervalSelected: Models.AnalyticsInterval.Day,
        };
      }
    }

    return analyticsSettings;
  };

  const analyticsSettings: Models.AnalyticsSettings = useMemo(() => {
    const baseSettings = {
      ...defaultSettings,
      ...(userPreferences.data.analyticsSettings ?? {}),
    };

    return adjustAnalyticsSettings(baseSettings, organization, site);
  }, [userPreferences, organization, site]);

  const handleSelectedMovementsChange = (checkedValues: CheckboxValueType[]) => {
    setMovementTypesSelected(checkedValues as Models.ChartMovementType[]);
  };

  const handleSelectedSafetyTypes = (checkedValues: CheckboxValueType[]) => {
    setSafetyTypesSelected(checkedValues as Models.ChartMovementType[]);
  };

  const handleSelectedWorkersChange = (checkedValues: CheckboxValueType[]) => {
    setWorkerStatusTypesSelected(checkedValues as Models.WorkerStatusType[]);
  };

  const saveFilterSelection = async (values: string[] | undefined) => {
    const selectedOption = values?.[0];
    const workerReportGroupId = values?.[1];
    let workerId = values?.[2];

    setFilterValue(values);

    switch (selectedOption) {
      case AnalyticsFilterOptions.AllWorkers:
        setSelectedWorkerGroupId(null);
        setSelectedWorkerId(null);
        break;
      case AnalyticsFilterOptions.WorkerReportGroups:
        setSelectedWorkerGroupId(workerReportGroupId ?? null);
        setSelectedWorkerId(workerId ?? null);
        break;
      case AnalyticsFilterOptions.IndividualWorkers:
        workerId = values?.[1];
        setSelectedWorkerGroupId(null);
        setSelectedWorkerId(workerId ?? null);
        break;
    }
  };

  const getSelectedResourceType = useCallback((): Models.EntityType => {
    return selectedWorkerId
      ? Models.EntityType.Worker
      : selectedWorkerGroupId
        ? Models.EntityType.WorkerReportGroup
        : department
          ? Models.EntityType.Department
          : site
            ? Models.EntityType.Site
            : Models.EntityType.Organization;
  }, [selectedWorkerId, department, site, selectedWorkerGroupId]);

  const getSelectedResourceID = useCallback((): string | null => {
    return selectedWorkerId
      ? selectedWorkerId
      : selectedWorkerGroupId
        ? selectedWorkerGroupId
        : department
          ? department.id
          : site
            ? site.id
            : organization
              ? organization.id
              : null;
  }, [selectedWorkerId, department, site, organization, selectedWorkerGroupId]);

  const calculateIntervalCount = (settings: Models.AnalyticsSettings, startDate: Date | null, endDate: Date | null) => {
    const {intervalSelected, intervalHoursSelected} = settings;
    let intervalCount = 45;

    if (settings.relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay) {
      switch (intervalHoursSelected) {
        case Models.AnalyticsIntervalHours.TwelveHours:
          intervalCount = 2;
          break;
        case Models.AnalyticsIntervalHours.EightHours:
          intervalCount = 3;
          break;
        case Models.AnalyticsIntervalHours.FourHours:
          intervalCount = 6;
          break;
        case Models.AnalyticsIntervalHours.TwoHours:
          intervalCount = 12;
          break;
        case Models.AnalyticsIntervalHours.OneHours:
          intervalCount = 24;
          break;
        case Models.AnalyticsIntervalHours.ThirtyMinutes:
          intervalCount = 24 * 2;
          break;
        case Models.AnalyticsIntervalHours.FifteenMinutes:
          intervalCount = 24 * 4;
          break;
      }
    } else {
      if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Hours) {
        switch (intervalHoursSelected) {
          case Models.AnalyticsIntervalHours.TwelveHours:
            intervalCount = (differenceInDays(endDate, startOfDay(startDate)) + 1) * 2;
            break;
          case Models.AnalyticsIntervalHours.EightHours:
            intervalCount = (differenceInDays(endDate, startOfDay(startDate)) + 1) * 3;
            break;
          case Models.AnalyticsIntervalHours.FourHours:
            intervalCount = (differenceInDays(endDate, startOfDay(startDate)) + 1) * 6;
            break;
          case Models.AnalyticsIntervalHours.TwoHours:
            intervalCount = (differenceInDays(endDate, startOfDay(startDate)) + 1) * 12;
            break;
          case Models.AnalyticsIntervalHours.OneHours:
            intervalCount = (differenceInDays(endDate, startOfDay(startDate)) + 1) * 24;
            break;
          case Models.AnalyticsIntervalHours.ThirtyMinutes:
            intervalCount = (differenceInDays(endDate, startOfDay(startDate)) + 1) * 24 * 2;
            break;
          case Models.AnalyticsIntervalHours.FifteenMinutes:
            intervalCount = (differenceInDays(endDate, startOfDay(startDate)) + 1) * 24 * 4;
            break;
        }
      } else {
        if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Day)
          intervalCount = differenceInDays(endDate, startOfDay(startDate)) + 1;
        if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Week) {
          intervalCount = Math.ceil(differenceInDays(endOfWeek(endDate), startDate) / 7);
        }
        if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Month) {
          intervalCount = Math.round(differenceInMonths(endOfMonth(endDate), startDate)) + 1;
        }
        if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Quarter) {
          intervalCount = Math.ceil(differenceInMonths(endDate, startDate) / 3) + 1;
        }
      }
    }
    if (
      settings.relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit ||
      settings.relativeTo === Models.AnalyticsRelativeTo.CalendarDate
    ) {
      if (settings.dataScope === Models.DataScope.BeforeSpecificDate) intervalCount = 100;
      if (settings.dataScope === Models.DataScope.AfterSpecificDate) {
        if (endDate) {
          switch (intervalSelected) {
            case Models.AnalyticsInterval.Day:
              intervalCount = endDate
                ? differenceInDays(new Date(), endDate) > 100
                  ? 100
                  : differenceInDays(new Date(), startOfDay(endDate)) + 1
                : 100;
              break;
            case Models.AnalyticsInterval.Week:
              const intervalOfWeeks = endDate ? Math.ceil(differenceInDays(new Date(), startOfWeek(endDate)) / 7) : 100;
              intervalCount = intervalOfWeeks < 100 ? intervalOfWeeks : 100;
              break;
            case Models.AnalyticsInterval.Month:
              const intervalsOfMonths = endDate ? Math.ceil(differenceInDays(new Date(), startOfMonth(endDate)) / 30) : 100;
              intervalCount = intervalsOfMonths < 100 ? intervalsOfMonths : 100;
              break;
            case Models.AnalyticsInterval.Quarter:
              const intervalQuarters = endDate ? Math.ceil(differenceInDays(new Date(), startOfQuarter(endDate)) / 90) : 100;
              intervalCount = intervalQuarters < 100 ? intervalQuarters : 100;

              break;
          }
          switch (intervalHoursSelected) {
            case Models.AnalyticsIntervalHours.TwelveHours:
              intervalCount = endDate
                ? differenceInDays(new Date(), endDate) > 100
                  ? 100
                  : (differenceInDays(new Date(), startOfDay(endDate)) + 1) * 2
                : 100;
              break;
            case Models.AnalyticsIntervalHours.EightHours:
              intervalCount = endDate
                ? differenceInDays(new Date(), endDate) > 100
                  ? 100
                  : (differenceInDays(new Date(), startOfDay(endDate)) + 1) * 3
                : 100;
              break;
            case Models.AnalyticsIntervalHours.FourHours:
              intervalCount = endDate
                ? differenceInDays(new Date(), endDate) > 100
                  ? 100
                  : (differenceInDays(new Date(), startOfDay(endDate)) + 1) * 6
                : 100;
              break;
            case Models.AnalyticsIntervalHours.TwoHours:
              intervalCount = endDate
                ? differenceInDays(new Date(), endDate) > 100
                  ? 100
                  : (differenceInDays(new Date(), startOfDay(endDate)) + 1) * 12
                : 100;
              break;
            case Models.AnalyticsIntervalHours.OneHours:
              intervalCount = endDate
                ? differenceInDays(new Date(), endDate) > 100
                  ? 100
                  : (differenceInDays(new Date(), startOfDay(endDate)) + 1) * 24
                : 100;
              break;
            case Models.AnalyticsIntervalHours.ThirtyMinutes:
              intervalCount = endDate
                ? differenceInDays(new Date(), endDate) > 3
                  ? 100
                  : (differenceInDays(new Date(), startOfDay(endDate)) + 1) * 48
                : 100;
              break;
            case Models.AnalyticsIntervalHours.FifteenMinutes:
              intervalCount = endDate
                ? differenceInDays(new Date(), endDate) > 2
                  ? 100
                  : (differenceInDays(new Date(), startOfDay(endDate)) + 1) * 96
                : 100;
              break;
          }
        }
      }
    }
    return intervalCount;
  };

  const intervalCount = useMemo(
    () =>
      analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit
        ? analyticsSettings.intervalCount
        : calculateIntervalCount(analyticsSettings, analyticsSettings.startDate, analyticsSettings.endDate),
    [analyticsSettings]
  );

  const analyticsCalendarQueries =
    analyticsSettings && entities
      ? entities.map((entity) => ({
          queryKey: ["AnalyticsTabContent-calendarQueries", entity.id, analyticsSettings],
          queryFn: () => {
            const resourceId = getSelectedResourceID();
            const {endDate, intervalSelected, dataScope, intervalHoursSelected} = analyticsSettings ?? {
              endDate: defaultSettings.endDate,
              intervalSelected: defaultSettings.intervalSelected,
            };

            const days =
              intervalSelected === Models.AnalyticsInterval.Hours
                ? intervalHoursSelected === Models.AnalyticsIntervalHours.TwelveHours
                  ? intervalCount / 2
                  : intervalHoursSelected === Models.AnalyticsIntervalHours.EightHours
                    ? intervalCount / 3
                    : intervalHoursSelected === Models.AnalyticsIntervalHours.FourHours
                      ? intervalCount / 6
                      : intervalHoursSelected === Models.AnalyticsIntervalHours.TwoHours
                        ? intervalCount / 12
                        : intervalHoursSelected === Models.AnalyticsIntervalHours.OneHours
                          ? intervalCount / 24
                          : intervalHoursSelected === Models.AnalyticsIntervalHours.ThirtyMinutes
                            ? intervalCount / 48
                            : intervalHoursSelected === Models.AnalyticsIntervalHours.FifteenMinutes
                              ? intervalCount / 96
                              : intervalCount
                : intervalCount;

            if (resourceId && endDate) {
              let endTime = fromZonedTime(
                startOfDay(addDays(endDate, dataScope === Models.DataScope.AfterSpecificDate ? days : 1)),
                "UTC"
              ).toISOString();
              if (intervalSelected === Models.AnalyticsInterval.Week)
                endTime = fromZonedTime(
                  addWeeks(endDate, dataScope === Models.DataScope.AfterSpecificDate ? intervalCount : 0),
                  "UTC"
                ).toISOString();
              else if (intervalSelected === Models.AnalyticsInterval.Month) {
                endTime = isAfter(new Date(), addMonths(endDate, intervalCount))
                  ? fromZonedTime(
                      startOfMonth(startOfDay(addMonths(endDate, dataScope === Models.DataScope.AfterSpecificDate ? intervalCount : 1))),
                      "UTC"
                    ).toISOString()
                  : fromZonedTime(
                      endOfMonth(addMonths(endDate, dataScope === Models.DataScope.AfterSpecificDate ? intervalCount - 1 : 0)),
                      "UTC"
                    ).toISOString();
              } else if (intervalSelected === Models.AnalyticsInterval.Quarter) {
                endTime = isAfter(new Date(), addQuarters(endDate, intervalCount))
                  ? fromZonedTime(
                      startOfDay(addDays(endDate, dataScope === Models.DataScope.AfterSpecificDate ? intervalCount : 1)),
                      "UTC"
                    ).toISOString()
                  : startOfDay(
                      endOfQuarter(addQuarters(endDate, dataScope === Models.DataScope.AfterSpecificDate ? intervalCount - 1 : 0))
                    ).toISOString();
              }

              return AnalyticsModule.analyticsTimeSeriesData({
                accessToken: authenticationStore.state.accessToken!,
                organization_id: entity.entityType === Models.EntityType.Organization ? entity.id : undefined,
                site_id: entity.entityType === Models.EntityType.Site ? entity.id : undefined,
                department_id: entity.entityType === Models.EntityType.Department ? entity.id : undefined,
                group_id: entity.entityType === Models.EntityType.WorkerReportGroup ? entity.id : undefined,
                worker_id: entity.entityType === Models.EntityType.Worker ? entity.id : undefined,
                end_time: endTime,
                interval_type: intervalSelected === Models.AnalyticsInterval.Hours ? intervalHoursSelected! : intervalSelected,
                interval_count: intervalCount,
                offset_minutes: -new Date().getTimezoneOffset(),
              });
            } else return Promise.resolve(null);
          },
          enabled: analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.CalendarDate,
        }))
      : [];

  const anaylticsCalendarDateQueries = useQueries({queries: analyticsCalendarQueries});

  const analyticsCalendarDateData = () => {
    const results: any = [];
    if ((site || organization) && anaylticsCalendarDateQueries && !anaylticsCalendarDateQueries.some((query) => query.isPending)) {
      anaylticsCalendarDateQueries.forEach((result) => {
        if (result.data && result.data.success) {
          const modifiedTimeSeriesData = result.data.data.time_series_data.map((item: Models.AnalyticsTimeSeries) => {
            item.start_time = item.start_time;
            item.end_time = item.end_time;
            return item;
          });
          results.push(modifiedTimeSeriesData);
          setIsActiveScroll(false);
        } else {
          if (!Array.isArray(result.data?.error) && result.data?.error?.message === "timeout")
            WebHelper.showErrorMessage(WebHelper.formatMessage("AnalyticsTabContent-errorTimeoutMessage"), result.data?.correlationId);
          else WebHelper.showErrorMessage(WebHelper.formatMessage("AnalyticsTabContent-errorMessage"), result.data?.correlationId);
          return undefined;
        }
      });
    }
    return results;
  };

  const analyticsDaysInSuitQueries =
    analyticsSettings && entities
      ? entities.map((entity) => ({
          queryKey: ["AnalyticsTabContent-DaysInSuitQueries", entity.id, analyticsSettings],
          queryFn: () => {
            const resourceId = getSelectedResourceID();
            const {startDate, endDate, intervalSelected} = analyticsSettings ?? {
              startDate: defaultSettings.startDate,
              endDate: defaultSettings.endDate,
              intervalSelected: defaultSettings.intervalSelected,
            };

            if (resourceId) {
              return AnalyticsModule.analyticsTimeSeriesByDaysInSuit({
                accessToken: authenticationStore.state.accessToken!,
                resource_id: entity.id,
                resource_type: entity.entityType,
                start_time:
                  analyticsSettings.dataScope === Models.DataScope.BetweenDates
                    ? startDate
                      ? fromZonedTime(startOfDay(startDate), "UTC").toISOString()
                      : undefined
                    : analyticsSettings.dataScope === Models.DataScope.AfterSpecificDate && endDate
                      ? fromZonedTime(startOfDay(addDays(endDate, 1)), "UTC").toISOString()
                      : undefined,
                end_time:
                  analyticsSettings.dataScope === Models.DataScope.BetweenDates && endDate
                    ? fromZonedTime(startOfDay(addDays(endDate, 1)), "UTC").toISOString()
                    : analyticsSettings.dataScope === Models.DataScope.BeforeSpecificDate && endDate
                      ? fromZonedTime(startOfDay(addDays(endDate, 1)), "UTC").toISOString()
                      : undefined,
                interval_type: intervalSelected,
                interval_count: analyticsSettings.intervalCount,
                offset_minutes: -new Date().getTimezoneOffset(),
              });
            } else return Promise.resolve(null);
          },
          enabled: analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit,
        }))
      : [];

  const analyticsDaysInSuitDateQueries = useQueries({queries: analyticsDaysInSuitQueries});

  const analyticsDaysInSuitData = () => {
    const results: any = [];
    if ((site || organization) && analyticsDaysInSuitDateQueries && !analyticsDaysInSuitDateQueries.some((query) => query.isPending)) {
      analyticsDaysInSuitDateQueries.forEach((result) => {
        if (result.data && result.data.success) {
          results.push(result.data.data.time_series_indices);
          setIsActiveScroll(false);
        } else {
          if (!Array.isArray(result.data?.error) && result.data?.error?.message === "timeout")
            WebHelper.showErrorMessage(WebHelper.formatMessage("AnalyticsTabContent-errorTimeoutMessage"), result.data?.correlationId);
          else WebHelper.showErrorMessage(WebHelper.formatMessage("AnalyticsTabContent-errorMessage"), result.data?.correlationId);
          return undefined;
        }
      });
    }
    return results;
  };

  const analyticsTimeOfTheDayQueries =
    analyticsSettings && entities
      ? entities.map((entity) => ({
          queryKey: ["AnalyticsTabContent-TimeOfTheDay", entity.id, analyticsSettings],
          queryFn: () => {
            const resourceId = getSelectedResourceID();
            const {startDate, endDate, intervalHoursSelected} = analyticsSettings ?? {
              startDate: defaultSettings.startDate,
              endDate: defaultSettings.endDate,
              intervalSelected: defaultSettings.intervalSelected,
            };

            if (resourceId && intervalHoursSelected) {
              return AnalyticsModule.analyticsTimeSeriesIntraDay({
                accessToken: authenticationStore.state.accessToken!,
                resource_id: entity.id,
                resource_type: entity.entityType,
                start_time: startDate ? fromZonedTime(startDate, "UTC")?.toISOString() : undefined,
                end_time: endDate ? fromZonedTime(startOfDay(addDays(endDate, 1)), "UTC").toISOString() : undefined,
                interval_type: intervalHoursSelected,
                offset_minutes: -new Date().getTimezoneOffset(),
              });
            } else return Promise.resolve(null);
          },
          enabled: analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay,
        }))
      : [];

  const analyticsTimeOfTheDayDateQueries = useQueries({queries: analyticsTimeOfTheDayQueries});

  const analyticsTimeOfTheDayData = () => {
    if (analyticsTimeOfTheDayDateQueries && !analyticsTimeOfTheDayDateQueries.some((query) => query.isPending)) {
      const results: any[] = [];
      for (const result of analyticsTimeOfTheDayDateQueries) {
        if (result.data && result.data.success) {
          setIsActiveScroll(false);
          results.push(result.data.data.intra_day_indices);
        } else {
          if (!Array.isArray(result.data?.error) && result.data?.error?.message === "timeout")
            WebHelper.showErrorMessage(WebHelper.formatMessage("AnalyticsTabContent-errorTimeoutMessage"), result.data?.correlationId);
          else WebHelper.showErrorMessage(WebHelper.formatMessage("AnalyticsTabContent-errorMessage"), result.data?.correlationId);
          return undefined;
        }
      }
      return results;
    }
    return undefined;
  };

  const prevDataRef = useRef(null);

  useEffect(() => {
    if (!analyticsSettings.endDate && !analyticsSettings.startDate) {
      return;
    }
    let newData: any;
    if (analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.CalendarDate) {
      newData = analyticsCalendarDateData();
    } else if (analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit) {
      newData = analyticsDaysInSuitData();
    } else if (analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay) {
      newData = analyticsTimeOfTheDayData();
    }
    if (isUndefined(newData)) return;

    const prevData = prevDataRef.current;

    if (!isEqual(prevData, newData)) {
      {
        isScroll
          ? setNewData((currentData) => {
              return mergeData(prevData, newData);
            })
          : setNewData(newData);
      }
      prevDataRef.current = newData;
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsSettings]);

  function mergeData(currentData: any, newDataFromAPI: any) {
    const combinedData = currentData ? [...currentData] : [];
    if (currentData) {
      newDataFromAPI.forEach((newDataItem: any) => {
        if (!currentData.some((item: any) => item.start_time === newDataItem.start_time)) {
          combinedData.push(newDataItem);
        } else {
          const index = currentData.findIndex((item: any) => item.id === newDataItem.id);
          combinedData[index] = newDataItem;
        }
      });
    } else {
      return newDataFromAPI;
    }
    return combinedData;
  }

  const chartVariable = useMemo(() => {
    switch (analyticsSettings.variableToChartSelected) {
      case Models.AnalyticsVariableToChart.HoursUsed:
        return "usage_sec";
      case Models.AnalyticsVariableToChart.WeightOffloaded:
        return "weight_offloaded_lbs";
      case Models.AnalyticsVariableToChart.Lifts:
      case Models.AnalyticsVariableToChart.MovementBreakdown:
        return "total_lifts";
      case Models.AnalyticsVariableToChart.WorkerActiveStatus:
        return "active_workers";
      default:
        return "";
    }
  }, [analyticsSettings.variableToChartSelected]);

  const totalText = useMemo(() => {
    const totalValue =
      newData && chartVariable !== ""
        ? newData.flat().reduce((accumulator, currentValue) => accumulator + (currentValue[chartVariable] || 0), 0)
        : 0;
    switch (analyticsSettings.variableToChartSelected) {
      case Models.AnalyticsVariableToChart.HoursUsed:
        return WebHelper.formatMessage("AnalyticsTabContent-totalValueMessage", {
          variable: WebHelper.formatMessage("AnalyticsSettings-hoursUsed"),
          unit: WebHelper.formatMessage("AnalyticsTabContent-hoursUnit"),
          value: secondsToHours(totalValue).toLocaleString(),
        });
      case Models.AnalyticsVariableToChart.WeightOffloaded:
        return WebHelper.formatMessage("AnalyticsTabContent-totalValueMessage", {
          variable: WebHelper.formatMessage("AnalyticsSettings-weightOffloaded"),
          unit: userPreferences.data.metricMeasurementUnits
            ? WebHelper.formatMessage("AnalyticsTabContent-kilogramsUnit")
            : WebHelper.formatMessage("AnalyticsTabContent-poundsUnit"),
          value: (userPreferences.data.metricMeasurementUnits ? lbsToKgs(totalValue) : totalValue).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 0,
          }),
        });
      case Models.AnalyticsVariableToChart.Lifts:
      case Models.AnalyticsVariableToChart.MovementBreakdown:
        return WebHelper.formatMessage("AnalyticsTabContent-totalValueMessage", {
          variable: WebHelper.formatMessage("AnalyticsSettings-lifts"),
          unit: WebHelper.formatMessage("AnalyticsTabContent-liftsUnit"),
          value: totalValue.toLocaleString(),
        });
      case Models.AnalyticsVariableToChart.WorkerActiveStatus:
        const totalPercentage = newData
          ? newData.flat().reduce((accumulator, currentValue) => accumulator + currentValue.activeWorkersPercentage, 0)
          : 0;
        const averageValue = newData ? (newData.flat().length > 0 ? round(totalValue / newData.flat().length, 2) : 0) : 0;
        const averagePercentage = newData ? (newData.flat().length > 0 ? round(totalPercentage / newData.flat().length, 2) : 0) : 0;

        return WebHelper.formatMessage("AnalyticsTabContent-averageMessage", {
          unit: WebHelper.formatMessage("AnalyticsTabContent-activeWorkers"),
          interval:
            analyticsSettings.intervalSelected === Models.AnalyticsInterval.Day
              ? WebHelper.formatMessage("AnalyticsTabContent-day")
              : analyticsSettings.intervalSelected === Models.AnalyticsInterval.Month
                ? WebHelper.formatMessage("AnalyticsTabContent-month")
                : analyticsSettings.intervalSelected === Models.AnalyticsInterval.Quarter
                  ? WebHelper.formatMessage("AnalyticsTabContent-quarter")
                  : WebHelper.formatMessage("AnalyticsTabContent-week"),
          value:
            analyticsSettings.relativeTo !== Models.AnalyticsRelativeTo.DaysInTheSuit
              ? `${averageValue} (${averagePercentage}%)`
              : `${averageValue}`,
        });
      default:
        return "";
    }
  }, [
    analyticsSettings.variableToChartSelected,
    analyticsSettings.relativeTo,
    analyticsSettings.intervalSelected,
    chartVariable,
    newData,
    userPreferences,
  ]);

  const trendlineText = useMemo(() => {
    if (analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit)
      return WebHelper.formatMessage("AnalyticsSettings-trendlineText", {
        startDate: "Day 1",
        endDate: `Day ${analyticsSettings.intervalCount}`,
      });
    const startDate = startDateToShow ? startDateToShow.toString() : "";
    const endDate = endDateToShow ? endDateToShow.toString() : "";
    return WebHelper.formatMessage("AnalyticsSettings-trendlineText", {startDate, endDate});
  }, [startDateToShow, endDateToShow, analyticsSettings.intervalCount, analyticsSettings.relativeTo]);

  const buildDownloadFileName = useCallback(
    (levelName: string, extension: "png" | "csv", resourceName?: string) => {
      let levelText = levelName;

      //if levelname ==  name, no worker or group is selected
      let resourceText = resourceName && resourceName != levelName ? `:${resourceName}` : "";

      if (resourceName) {
        //AnalyticsData-workerGroupOrWorkerName.csv
        if (site && !department) {
          resourceText = resourceName;
          levelText = "";
        }
      }

      return `AnalyticsData-${levelText}${resourceText}.${extension}`;
    },
    [department, site]
  );

  const handleSettingsChange = useCallback(
    async (
      updatedSettings?: Partial<Models.AnalyticsSettings>,
      dataSelected?: Models.ChartDataType,
      checkedValues?: CheckboxValueType[],
      checkedMostRecent?: boolean,
      entity?: Models.AnalyticsEntity[]
    ) => {
      setEntities(entity ?? []);
      let newAnalyticsSettings = {...analyticsSettings, ...updatedSettings};

      const extendNewAnalytics = (obj: any) => {
        newAnalyticsSettings = {
          ...newAnalyticsSettings,
          ...obj,
        };
      };
      if (dataSelected) {
        setDataType(dataSelected);
        extendNewAnalytics({dataType: dataSelected});
      }

      if (checkedMostRecent !== undefined) {
        extendNewAnalytics({checkedMostRecent: checkedMostRecent, endDate: updatedSettings?.endDate ?? null});
      }

      switch (newAnalyticsSettings.variableToChartSelected) {
        case Models.AnalyticsVariableToChart.Lifts:
          handleSelectedSafetyTypes(checkedValues ? checkedValues : safetyTypesSelected);
          extendNewAnalytics({safetyTypesSelected: checkedValues as Models.ChartMovementType[]});
          break;
        case Models.AnalyticsVariableToChart.MovementBreakdown:
          handleSelectedMovementsChange(checkedValues ? checkedValues : movementTypesSelected ?? []);
          extendNewAnalytics({movementTypesSelected: checkedValues as Models.ChartMovementType[]});
          break;
        case Models.AnalyticsVariableToChart.WorkerActiveStatus:
          handleSelectedWorkersChange(checkedValues ? checkedValues : workerStatusTypesSelected ?? []);
          extendNewAnalytics({workerStatusTypesSelected: checkedValues as Models.WorkerStatusType[]});
          break;
        default:
          break;
      }

      setUserPreferences({analyticsSettings: newAnalyticsSettings});
      const newSettings = {...analyticsSettings, ...updatedSettings};

      appStore.sendAnalyticTrack(SegmentKey.ViewAnalyticsPage, {
        orgID: organization?.id,
        siteID: getSelectedResourceType() === Models.EntityType.Organization ? SEGMENT_NOT_APPLICABLE_VALUE : site?.id,
        departmentID: getSelectedResourceType() === Models.EntityType.Department ? department?.id : SEGMENT_NOT_APPLICABLE_VALUE,
        resourceType: getSelectedResourceType(),
        resourceID: getSelectedResourceID(),
        startDate: newSettings.startDate ? WebHelper.formatDateNoTime(newSettings.startDate) : "",
        endDate: newSettings.endDate ? WebHelper.formatDateNoTime(newSettings.endDate) : "",
        endDateWeekday: newSettings.endDate ? format(newSettings.endDate, "EEEE") : "",
        variable: newSettings.variableToChartSelected,
        interval: newSettings.intervalSelected,
        data:
          newSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts ||
          newSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown ||
          newSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? dataSelected
            : SEGMENT_NOT_APPLICABLE_VALUE,
        checkbox:
          newSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? checkedValues
            : newSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts
              ? checkedValues
              : newSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown
                ? checkedValues
                : SEGMENT_NOT_APPLICABLE_VALUE,
      });
    },
    [
      analyticsSettings,
      setUserPreferences,
      appStore,
      organization?.id,
      getSelectedResourceType,
      site?.id,
      department?.id,
      getSelectedResourceID,
      safetyTypesSelected,
      movementTypesSelected,
      workerStatusTypesSelected,
    ]
  );

  const handleDownloadData = useCallback(async () => {
    const resourceType = getSelectedResourceType();
    const resourceID = getSelectedResourceID();
    const levelName = department ? department.name : site ? site.name : organization ? organization.name : "";
    let resourceName = levelName;

    appStore.sendAnalyticTrack(SegmentKey.DownloadAnalyticsData, {
      orgID: organization?.id,
      siteID: getSelectedResourceType() === Models.EntityType.Organization ? SEGMENT_NOT_APPLICABLE_VALUE : site?.id,
      departmentID: getSelectedResourceType() === Models.EntityType.Department ? department?.id : SEGMENT_NOT_APPLICABLE_VALUE,
      resourceType: getSelectedResourceType(),
      resourceID: getSelectedResourceID(),
      startDate: analyticsSettings.startDate ? WebHelper.formatDateNoTime(analyticsSettings.startDate) : "",
      endDate: analyticsSettings.endDate ? WebHelper.formatDateNoTime(analyticsSettings.endDate) : "",
      endDateWeekday: analyticsSettings.endDate ? format(analyticsSettings.endDate, "EEEE") : "",
      interval: analyticsSettings.intervalSelected,
    });

    if (selectedWorkerGroupId || selectedWorkerId) {
      resourceName = (await SelectedWorkerGroupOrWorkerNameQuery.refetch()).data;
    }

    const weightOffloadedColumnTitle = `${WebHelper.formatMessage("Analytics-csvColumn-weightOffloaded")} (${WebHelper.formatMessage(
      userPreferences.data.metricMeasurementUnits ? "AnalyticsTabContent-kilogramsUnit" : "AnalyticsTabContent-poundsUnit"
    )})`;

    const headers = [
      [
        WebHelper.formatMessage("Analytics-csvColumn-resourceType"),
        WebHelper.formatMessage("Analytics-csvColumn-resourceID"),
        WebHelper.formatMessage("Analytics-csvColumn-resourceName"),
        WebHelper.formatMessage("Analytics-csvColumn-startDate"),
        WebHelper.formatMessage("Analytics-csvColumn-startTime"),
        WebHelper.formatMessage("Analytics-csvColumn-endDate"),
        WebHelper.formatMessage("Analytics-csvColumn-endTime"),
        WebHelper.formatMessage("Analytics-csvColumn-hoursUsed"),
        WebHelper.formatMessage("Analytics-csvColumn-totalLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-riskyLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-safeLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-safeLiftsPercentage"),
        WebHelper.formatMessage("Analytics-csvColumn-riskyLiftsPercentage"),
        weightOffloadedColumnTitle,
        WebHelper.formatMessage("Analytics-csvColumn-excessiveForwardLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-prolongedBendLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-twistedLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-sideBendLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-assignedWorkers"),
        WebHelper.formatMessage("Analytics-csvColumn-activeWorkers"),
        WebHelper.formatMessage("Analytics-csvColumn-activeWorkersPercentage"),
        WebHelper.formatMessage("Analytics-csvColumn-inactiveWorkers"),
        WebHelper.formatMessage("Analytics-csvColumn-inactiveWorkersPercentage"),
      ],
    ];

    const timezone = department ? department.site.tz_location : site ? site.tz_location : WebHelper.getLocalTimezoneFromBrowser();
    if (!newData) return;
    const dataRows = newData.flat().map((item) => {
      return [
        resourceType,
        resourceID,
        resourceName.replaceAll(",", " -"),
        typeof item.start_time === "string" ? item.start_time : WebHelper.formatDateNoTime(item.start_time, timezone),
        typeof item.start_time === "string" ? item.start_time : WebHelper.formatTimeOnly(item.start_time, timezone),
        typeof item.end_time === "string" ? item.end_time : WebHelper.formatDateNoTime(item.end_time, timezone),
        typeof item.end_time === "string" ? item.end_time : WebHelper.formatTimeOnly(item.end_time, timezone),
        (item.usage_sec / 3600).toFixed(2),
        item.total_lifts,
        item.riskyLifts,
        item.safe_lifts,
        item.safeLiftsPercentage,
        item.riskyLiftsPercentage,
        userPreferences.data.metricMeasurementUnits ? lbsToKgs(item.weight_offloaded_lbs) : item.weight_offloaded_lbs,
        item.excessive_forward_lifts,
        item.prolonged_bend_lifts,
        item.twisted_lifts,
        item.side_bend_lifts,
        item.assigned_workers,
        item.active_workers,
        item.activeWorkersPercentage,
        item.inactiveWorkers,
        item.inactiveWorkersPercentage,
      ];
    });

    const csvContent = concat(headers, dataRows)
      .map((row) => row.join(","))
      .join("\n");

    WebHelper.downloadFile(csvContent, buildDownloadFileName(levelName, "csv", resourceName), "application/csv");
  }, [
    getSelectedResourceType,
    getSelectedResourceID,
    department,
    site,
    organization,
    appStore,
    analyticsSettings.startDate,
    analyticsSettings.endDate,
    analyticsSettings.intervalSelected,
    selectedWorkerGroupId,
    selectedWorkerId,
    newData,
    userPreferences,
    buildDownloadFileName,
    SelectedWorkerGroupOrWorkerNameQuery,
  ]);

  const handleDownloadImage = async () => {
    if (chartRef.current) {
      appStore.sendAnalyticTrack(SegmentKey.DownloadAnalyticsGraph, {
        orgID: organization?.id,
        siteID: getSelectedResourceType() === Models.EntityType.Organization ? SEGMENT_NOT_APPLICABLE_VALUE : site?.id,
        departmentID: getSelectedResourceType() === Models.EntityType.Department ? department?.id : SEGMENT_NOT_APPLICABLE_VALUE,
        resourceType: getSelectedResourceType(),
        resourceID: getSelectedResourceID(),
        startDate: analyticsSettings.startDate ? WebHelper.formatDateNoTime(analyticsSettings.startDate) : "",
        endDate: analyticsSettings.endDate ? WebHelper.formatDateNoTime(analyticsSettings.endDate) : "",
        endDateWeekday: analyticsSettings.endDate ? format(analyticsSettings.endDate, "EEEE") : "",
        variable: analyticsSettings.variableToChartSelected,
        interval: analyticsSettings.intervalSelected,
        data:
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts ||
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown ||
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? dataType
            : SEGMENT_NOT_APPLICABLE_VALUE,
        checkbox:
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts
            ? movementTypesSelected
            : analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown
              ? movementTypesSelected
              : SEGMENT_NOT_APPLICABLE_VALUE,
      });

      const a = document.createElement("a");
      const levelName = department ? department.name : site ? site.name : organization ? organization.name : "";
      let resourceName = levelName;

      if (selectedWorkerGroupId || selectedWorkerId) {
        resourceName = (await SelectedWorkerGroupOrWorkerNameQuery.refetch()).data;
      }

      a.href = chartRef.current.toBase64Image();
      a.download = await buildDownloadFileName(levelName, "png", resourceName);
      a.click();
    }
  };

  const downloadOptions = [
    {
      label: WebHelper.formatMessage("AnalyticsTabContent-asCsv"),
      key: "csv",
      onClick: () => handleDownloadData(),
    },
    {
      label: WebHelper.formatMessage("AnalyticsTabContent-asImage"),
      key: "image",
      onClick: () => handleDownloadImage(),
    },
  ];

  const handleOnClickChart = (
    data: (Models.AnalyticsTimeSeries | Models.AnalyticsTimeSeriesIndices)[],
    label: string,
    relativeTo: Models.AnalyticsRelativeTo,
    entitySelected?: Models.AnalyticsEntity
  ) => {
    setIsDetailModalOpen(true);
    const details = {
      data: data,
      relativeTo: relativeTo,
    };
    setDetailsData(details);
    setIntervalLabel(label);
    entitySelected && setEntitySelected(entitySelected);
  };

  return (
    <div className={styles.tabContent} ref={refTabContent}>
      <GatewayAlertBlock />
      <div ref={refAnalyticsSettings}>
        <Button
          shape="round"
          type={ButtonType.Primary}
          style={{margin: "16px", marginLeft: "20px"}}
          icon={<img src={GraphIcon} alt={WebHelper.formatMessage("AnalyticsTabContent-filtersConfiguration")} />}
          onClick={() => setFilterDrawerOpen(true)}>
          {WebHelper.formatMessage("AnalyticsTabContent-filtersConfiguration")}
        </Button>
        <AnalyticsSettingsDrawer
          organization={organization}
          site={site}
          department={department}
          onSettingsChange={handleSettingsChange}
          dataSelected={dataType}
          analyticsSettings={analyticsSettings}
          variableToChartSelected={analyticsSettings.variableToChartSelected}
          intervalSelected={analyticsSettings.intervalSelected}
          intervalCount={analyticsSettings.intervalCount}
          intervalHoursSelected={analyticsSettings.intervalHoursSelected}
          dataScopeSelected={analyticsSettings.dataScope}
          relativeToSelected={analyticsSettings.relativeTo}
          movementTypesSelected={movementTypesSelected}
          workerStatusTypesSelected={workerStatusTypesSelected}
          liftTypesSelected={safetyTypesSelected}
          endDate={analyticsSettings.endDate}
          startDate={analyticsSettings.startDate}
          saveFilterSelection={saveFilterSelection}
          filterValueEntity={filterValueEntity}
          filterValue={filterValue}
          open={filterDrawerOpen}
          showTrendlinesSelected={analyticsSettings.showTrendlines}
          onClose={() => setFilterDrawerOpen(false)}
          isScroll={isScroll}
          setIsScroll={setIsScroll}
          setIsApplyChanges={setIsApplyChanges}
          calculateIntervalCount={calculateIntervalCount}
        />
        <div className={styles.chartHeader}>
          <div className={styles.totals}>
            {entities && entities.length === 1 && (
              <div
                dangerouslySetInnerHTML={{
                  __html: WebHelper.parseMarkdown(totalText),
                }}
                className={styles.totalText}
              />
            )}
            {flags.analytics_trendlines && startDateToShow && endDateToShow && (
              <div className={styles.trendlineText}>
                <InfoCircleOutlined />
                <div
                  dangerouslySetInnerHTML={{
                    __html: WebHelper.parseMarkdown(trendlineText),
                  }}
                  style={{marginLeft: "-5px"}}
                />
              </div>
            )}
          </div>
          <Dropdown menu={{items: downloadOptions}} placement="bottom" trigger={["click"]}>
            <Button type={ButtonType.Primary} shape="round" icon={<DownloadOutlined />} disabled={false}>
              {WebHelper.formatMessage("AnalyticsSubheader-downloadDataButton")}
            </Button>
          </Dropdown>
        </div>
      </div>
      <div style={{height: chartHeight}}>
        <AnalyticsChart
          allData={newData}
          entities={entities}
          onSettingsChange={handleSettingsChange}
          variableSelected={analyticsSettings.variableToChartSelected}
          intervalSelected={analyticsSettings.intervalSelected}
          intervalSelectedHours={analyticsSettings.intervalHoursSelected}
          timeZone={site ? site.tz_location : department ? department.site.tz_location : ""}
          selectedMovements={movementTypesSelected}
          safetyTypesSelected={safetyTypesSelected}
          selectedWorkerStatuses={workerStatusTypesSelected}
          dataScopeSelected={analyticsSettings.dataScope}
          dataType={dataType}
          endDate={analyticsSettings.endDate}
          startDate={analyticsSettings.startDate}
          chartRef={chartRef}
          metricMeasurementUnits={userPreferences.data.metricMeasurementUnits}
          intervals={intervalCount}
          minimumLifts={analyticsSettings.minimumLifts}
          relativeTo={analyticsSettings.relativeTo}
          loadingQueries={
            analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.CalendarDate
              ? anaylticsCalendarDateQueries?.some((query) => query.isPending)
              : analyticsSettings.relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay
                ? analyticsTimeOfTheDayDateQueries?.some((query) => query.isPending)
                : analyticsDaysInSuitDateQueries?.some((query) => query.isPending)
          }
          showTrendlines={analyticsSettings.showTrendlines}
          handleOnClickChart={handleOnClickChart}
          isScroll={isScroll}
          setIsScroll={setIsScroll}
          isApplyChanges={isApplyChanges}
          setIsApplyChanges={setIsApplyChanges}
          isActiveScroll={isActiveScroll}
          setIsActiveScroll={setIsActiveScroll}
          isDetailModalOpen={isDetailModalOpen}
          calendarDaysModalOpen={calendarDaysModalOpen}
          setCalendarDaysModalOpen={setCalendarDaysModalOpen}
          setStartDateToShow={setStartDateToShow}
          setEndDateToShow={setEndDateToShow}
        />
      </div>
      {detailsData && (
        <AnalyticsDetailsModal
          detailsData={detailsData}
          entities={entities}
          selectedEntity={
            (analyticsSettings.variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
              analyticsSettings.variableToChartSelected === Models.AnalyticsVariableToChart.Lifts) &&
            entities &&
            entities.length > 1
              ? entitySelected
              : undefined
          }
          open={isDetailModalOpen}
          label={intervalLabel}
          variableToChartSelected={analyticsSettings.variableToChartSelected}
          intervalSelected={analyticsSettings.intervalSelected}
          intervalHoursSelected={analyticsSettings.intervalHoursSelected}
          endDate={analyticsSettings.endDate}
          startDate={analyticsSettings.startDate}
          dataType={dataType}
          onClose={() => setIsDetailModalOpen(false)}
        />
      )}
      <Modal
        open={calendarDaysModalOpen}
        width={"380px"}
        destroyOnClose
        onCancel={() => setCalendarDaysModalOpen(false)}
        centered
        footer={null}
        zIndex={1001}
        title={WebHelper.formatMessage("AnalyticsSettings-calendarDate")}>
        <Typography.Text>
          {analyticsSettings.dataScope === Models.DataScope.BeforeSpecificDate
            ? WebHelper.formatMessage("AnalyticsSettings-modalTextBeforeDate", {maxDateToShow: startDateToShow})
            : WebHelper.formatMessage("AnalyticsSettings-modalTextBeforeDate", {maxDateToShow: endDateToShow})}
        </Typography.Text>
      </Modal>
    </div>
  );
};

export default AnalyticsTabContent;
