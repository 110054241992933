import {InfoCircleOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Button, Card, Checkbox, Col, Drawer, Radio, Row, Select, Slider, Space, Switch, Tooltip, TreeSelect, Typography} from "antd";
import {OrganizationModule} from "core/modules/OrganizationModule";
import {
  addDays,
  addMinutes,
  addMonths,
  addQuarters,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  getQuarter,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  startOfWeek,
} from "date-fns";
import {toZonedTime} from "date-fns-tz";
import {useFlags} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {ButtonType} from "web/components/common/button/Button";
import useUserPreferences from "web/hooks/useUserPreferences";
import {isEmpty} from "web/utils/FunctionUtils";

import styles from "./AnalayticsSettingsDrawer.module.scss";
import * as Models from "../../../../../core/models";
import {WorkerModule} from "../../../../../core/modules/WorkerModule";
import {WorkerReportGroupModule} from "../../../../../core/modules/WorkerReportGroupModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {AppConfig} from "../../../../AppConfig";
import {WebHelper} from "../../../../utils/WebHelper";
import {DatePicker} from "../../../date-picker/DatePicker";
import {RangePicker} from "../../../range-picker/RangePicker";

export enum AnalyticsFilterOptions {
  AllWorkers = "all_workers",
  WorkerReportGroups = "worker_report_groups",
  IndividualWorkers = "individual_workers",
}

type CheckboxValueType = string | number | boolean;

type Option = {
  value: string;
  title: string;
  children?: Option[];
  real_time_data_enabled?: boolean;
};

const formatDate = (date: Date) => date.toISOString().split("T")[0];

type AnalyticsSettingsDrawerProps = {
  open: boolean;
  onClose: () => void;
  organization?: Models.Organization;
  site?: Models.Site;
  department?: Models.Department;
  onSettingsChange: (
    updatedSettings?: Partial<Models.AnalyticsSettings>,
    dataSelected?: Models.ChartDataType,
    movementTypes?: CheckboxValueType[],
    mostRecentCheckbox?: boolean,
    entity?: Models.AnalyticsEntity[]
  ) => void;
  analyticsSettings: Models.AnalyticsSettings;
  dataSelected?: Models.ChartDataType;
  variableToChartSelected: Models.AnalyticsVariableToChart;
  intervalSelected: Models.AnalyticsInterval;
  intervalCount: number;
  intervalHoursSelected: Models.AnalyticsIntervalHours | undefined;
  movementTypesSelected: Models.ChartMovementType[];
  liftTypesSelected: Models.ChartMovementType[];
  workerStatusTypesSelected: Models.WorkerStatusType[];
  startDate: Date | null;
  endDate: Date | null;
  saveFilterSelection: (values: string[] | undefined) => void;
  filterValueEntity: {value: string; label: string}[] | undefined;
  filterValue: string[] | undefined;
  dataScopeSelected: Models.DataScope;
  relativeToSelected: Models.AnalyticsRelativeTo;
  showTrendlinesSelected: boolean;
  isScroll: boolean;
  setIsScroll: (isScroll: boolean) => void;
  setIsApplyChanges: (isApplyChanges: boolean) => void;
  calculateIntervalCount: (settings: Models.AnalyticsSettings, startDate: Date | null, endDate: Date | null) => number;
};

export const AnalyticsSettingsDrawer: FunctionComponent<AnalyticsSettingsDrawerProps> = ({
  site,
  organization,
  open,
  onClose,
  onSettingsChange,
  dataSelected,
  variableToChartSelected,
  intervalSelected,
  movementTypesSelected,
  analyticsSettings,
  startDate,
  endDate,
  intervalCount,
  filterValueEntity,
  intervalHoursSelected,
  dataScopeSelected,
  relativeToSelected,
  workerStatusTypesSelected,
  liftTypesSelected,
  showTrendlinesSelected,
  setIsScroll,
  setIsApplyChanges,
  calculateIntervalCount,
}) => {
  const MAX_BARS_TO_DISPLAY = AppConfig.Components.AnalyticsChart.maxBarsToDisplay;

  const authenticationStore = AuthenticationDataStore.getInstance();

  const [userPreferences] = useUserPreferences();
  const {orgId} = useParams();
  const [selectedEntity, setSelectedEntity] = useState<{value: string; label: string}[]>(filterValueEntity ?? []);
  const [datepickerKey, setDatepickerKey] = useState(0);
  const [hoursMinutesSelected, setHoursMinutesSelected] = useState(intervalHoursSelected);
  const [minimumLifts, setMinimumLifts] = useState(0);
  const [variableToChart, setVariableToChart] = useState(variableToChartSelected);
  const [newDataSelected, setNewDataSelected] = useState(dataSelected);
  const [newIntervalSelected, setNewIntervalSelected] = useState(intervalSelected);
  const [intervalCountSelected, setIntervalCountSelected] = useState(intervalCount);
  const [startDateSelected, setStartDateSelected] = useState(startDate);
  const [endDateSelected, setEndDateSelected] = useState(endDate);
  const [movementTypes, setMovementTypes] = useState(movementTypesSelected);
  const [liftTypes, setLiftTypes] = useState(liftTypesSelected);
  const [workerTypes, setWorkerTypes] = useState(workerStatusTypesSelected);
  const [relativeTo, setRelativeTo] = useState<Models.AnalyticsRelativeTo>(relativeToSelected);
  const [dataScope, setDataScope] = useState<Models.DataScope>(dataScopeSelected);
  const [cascaderEntityFilterOptions, setCascaderEntityFilterOptions] = useState<Option[]>();
  const [showTrendlines, setShowTrendlines] = useState(showTrendlinesSelected);
  const [isMixedRealTimeOrganizaton, setIsMixedRealTimeOrganizaton] = useState(false);
  const [loading, setLoading] = useState(true);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const flags = useFlags();

  useEffect(() => {
    if (open) {
      setTimeout(() => setTooltipOpen(true), 100);
    } else {
      setTooltipOpen(false);
    }
  }, [open]);

  const [mostRecentIntervalChecked, setMostRecentIntervalChecked] = useState(
    userPreferences.data.analyticsSettings?.checkedMostRecent !== undefined
      ? userPreferences.data.analyticsSettings?.checkedMostRecent
      : false
  );

  const workerReportGroupsQuery = useQuery({
    queryKey: ["AnalyticsSettings-fetchWorkerReportGroups", site?.id],
    queryFn: () =>
      WorkerReportGroupModule.workerReportGroups({
        accessToken: authenticationStore.state.accessToken!,
        site_id: site!.id,
      }),
    enabled: !!site?.id,
  });

  const handleOnClose = () => {
    setVariableToChart(variableToChartSelected);
    setNewDataSelected(dataSelected);
    setNewIntervalSelected(intervalSelected);
    setStartDateSelected(startDate);
    setEndDateSelected(endDate);
    setMovementTypes(movementTypesSelected);
    setLiftTypes(liftTypesSelected);
    setWorkerTypes(workerStatusTypesSelected);
    setRelativeTo(relativeToSelected);
    setDataScope(dataScopeSelected);
    setShowTrendlines(showTrendlinesSelected);
    setMostRecentIntervalChecked(
      userPreferences.data.analyticsSettings?.checkedMostRecent !== undefined
        ? userPreferences.data.analyticsSettings?.checkedMostRecent
        : false
    );
    onClose();
  };

  useEffect(() => {
    const response = workerReportGroupsQuery.data;
    if (response) {
      if (!response.success)
        WebHelper.showErrorMessage(
          WebHelper.formatMessage("WorkerReportGroupsDisplay-fetchWorkerReportGroupsDataError"),
          response.correlationId
        );
    }
  }, [workerReportGroupsQuery.data]);

  const organizationQuery = useQuery({
    queryKey: ["AnalyticsSettingsDrawer-fetchOrganizationData", orgId],
    queryFn: () => {
      if (orgId) {
        return OrganizationModule.organization({
          accessToken: authenticationStore.state.accessToken!,
          id: orgId,
        });
      } else return;
    },
    enabled: !!orgId,
  });

  const fetchWorkerReportGroups = async (siteId: string) => {
    return WorkerReportGroupModule.workerReportGroups({
      accessToken: authenticationStore.state.accessToken!,
      site_id: siteId,
    });
  };

  const fetchDepartmentWorkers = async (departmentId: string) => {
    return WorkerModule.workersShort({
      accessToken: authenticationStore.state.accessToken!,
      department_id: departmentId,
    });
  };

  useEffect(() => {
    if (organizationQuery.data) {
      const fetchWorkerGroups = async (siteId: string, isSiteDisabled: boolean, real_time_data_enabled: boolean) => {
        const workerGroups = (await fetchWorkerReportGroups(siteId)).workerReportGroups;
        if (!workerGroups) return [];
        return workerGroups
          ?.map((group) => ({
            title: group.name,
            value: `workerGroup-${group.id}`,
            disabled: isSiteDisabled,
            real_time_data_enabled: real_time_data_enabled,
          }))
          ?.sort((a, b) => a.title.localeCompare(b.title));
      };

      const fetchWorkers = async (departmentId: string, isSiteDisabled: boolean, real_time_data_enabled: boolean) => {
        const workers = (await fetchDepartmentWorkers(departmentId)).workers;
        return workers
          ?.map((worker) => ({
            title: worker.fullName,
            value: `worker-${worker.id ?? worker.fullName}`,
            disabled: isSiteDisabled,
            real_time_data_enabled: real_time_data_enabled,
          }))
          ?.sort((a, b) => a.title.localeCompare(b.title));
      };

      const createOptions = async (organization: any): Promise<Option[]> => {
        if (!organization.sites) return [{title: organization.name, value: `organization-${organization.id}`}];
        setIsMixedRealTimeOrganizaton(
          organization.sites.some((site: Models.SiteInOrganization) => site.real_time_data_enabled === true) &&
            organization.sites.some((site: Models.SiteInOrganization) => site.real_time_data_enabled === false)
        );
        const sites = await Promise.all(
          organization.sites.map(async (site: Models.SiteInOrganization) => {
            const isSiteDisabled =
              (relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay ||
                (relativeTo === Models.AnalyticsRelativeTo.CalendarDate && intervalSelected === Models.AnalyticsInterval.Hours)) &&
              !site.real_time_data_enabled;
            const departments = site.departments
              ? await Promise.all(
                  site.departments.map(async (dept) => {
                    const workers = await fetchWorkers(dept.id, isSiteDisabled, site.real_time_data_enabled);
                    return {
                      key: new Date(),
                      title: dept.name,
                      value: `department-${dept.id}`,
                      disabled: isSiteDisabled,
                      children: workers,
                      real_time_data_enabled: site.real_time_data_enabled,
                    };
                  })
                )
              : [];
            departments.sort((a, b) => a.title.localeCompare(b.title));
            const workerGroups = await fetchWorkerGroups(site.id, isSiteDisabled, site.real_time_data_enabled);
            return {
              title: site.name,
              value: `site-${site.id}`,
              disabled: isSiteDisabled,
              children: [...departments, ...workerGroups],
              real_time_data_enabled: site.real_time_data_enabled,
            };
          })
        );
        if (sites && sites.length > 1) sites.sort((a, b) => a.title.localeCompare(b.title));
        return [{title: organization.name, value: `organization-${organization.id}`, children: sites}];
      };
      setLoading(true);
      createOptions(organizationQuery.data.organization).then(async (options) => {
        setCascaderEntityFilterOptions(options);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationQuery.data]);

  const updateDisabledState = (options: Option[], relativeTo: string, intervalSelected: string): Option[] => {
    const updateOptions = (options: Option[]): Option[] => {
      return options.map((option) => {
        const isSiteDisabled =
          (relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay ||
            (relativeTo === Models.AnalyticsRelativeTo.CalendarDate && intervalSelected === Models.AnalyticsInterval.Hours)) &&
          !option.real_time_data_enabled;

        if (isSiteDisabled) {
          if (selectedEntity.find((entity) => entity.value == option.value)) {
            setSelectedEntity(selectedEntity.filter((entity) => entity.value !== option.value));
          }
        }
        const updatedOption = {
          ...option,
          disabled: isSiteDisabled,
          children: option.children ? updateOptions(option.children) : undefined,
        };

        return updatedOption;
      });
    };

    return updateOptions(options);
  };

  useEffect(() => {
    if (cascaderEntityFilterOptions && cascaderEntityFilterOptions.length > 0) {
      const updatedOptions = updateDisabledState(cascaderEntityFilterOptions, relativeTo, newIntervalSelected);
      setCascaderEntityFilterOptions(updatedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relativeTo, newIntervalSelected]);

  const variableToChartOptions = useMemo(
    () => [
      {
        label: WebHelper.formatMessage("AnalyticsSettings-hoursUsed"),
        value: Models.AnalyticsVariableToChart.HoursUsed,
      },
      {label: WebHelper.formatMessage("AnalyticsSettings-lifts"), value: Models.AnalyticsVariableToChart.Lifts},
      {
        label: WebHelper.formatMessage("AnalyticsSettings-weightOffloaded"),
        value: Models.AnalyticsVariableToChart.WeightOffloaded,
      },
      {
        label: WebHelper.formatMessage("AnalyticsSettings-movementBreakdown"),
        value: Models.AnalyticsVariableToChart.MovementBreakdown,
      },
      {
        label: WebHelper.formatMessage("AnalyticsSettings-workerActiveStatus"),
        value: Models.AnalyticsVariableToChart.WorkerActiveStatus,
      },
    ],
    []
  );

  const relativeToChartOptions = useMemo(() => {
    const options = [
      {
        label: WebHelper.formatMessage("AnalyticsSettings-calendarDate"),
        value: Models.AnalyticsRelativeTo.CalendarDate,
      },
      {
        label: WebHelper.formatMessage("AnalyticsSettings-daysInSuit"),
        value: Models.AnalyticsRelativeTo.DaysInTheSuit,
      },
    ];

    if (organization && organization.sites?.some((site) => site.real_time_data_enabled)) {
      options.push({
        label: WebHelper.formatMessage("AnalyticsSettings-timeOfTheDay"),
        value: Models.AnalyticsRelativeTo.TimeOfTheDay,
      });
    }

    return options;
  }, [organization]);

  const dataRangeChartOptions = useMemo(
    () => [
      {
        label: WebHelper.formatMessage("AnalyticsSettings-afterSpecificDate"),
        value: Models.DataScope.AfterSpecificDate,
      },
      {
        label: WebHelper.formatMessage("AnalyticsSettings-beforeSpecificDate"),
        value: Models.DataScope.BeforeSpecificDate,
      },
      {label: WebHelper.formatMessage("AnalyticsSettings-betweenDates"), value: Models.DataScope.BetweenDates},
    ],
    []
  );

  const hoursMinutesOptions = {
    1: {
      label: WebHelper.formatMessage("AnalyticsSettings-fifteenMinutes"),
      value: Models.AnalyticsIntervalHours.FifteenMinutes,
    },
    2: {
      label: WebHelper.formatMessage("AnalyticsSettings-thirtyMinutes"),
      value: Models.AnalyticsIntervalHours.ThirtyMinutes,
    },
    3: {label: WebHelper.formatMessage("AnalyticsSettings-oneHour"), value: Models.AnalyticsIntervalHours.OneHours},
    4: {label: WebHelper.formatMessage("AnalyticsSettings-twoHour"), value: Models.AnalyticsIntervalHours.TwoHours},
    5: {label: WebHelper.formatMessage("AnalyticsSettings-fourHour"), value: Models.AnalyticsIntervalHours.FourHours},
    6: {
      label: WebHelper.formatMessage("AnalyticsSettings-eightHour"),
      value: Models.AnalyticsIntervalHours.EightHours,
    },
    7: {
      label: WebHelper.formatMessage("AnalyticsSettings-twelveHour"),
      value: Models.AnalyticsIntervalHours.TwelveHours,
    },
  };

  const intervalOptions = useMemo(() => {
    const options = [
      {label: WebHelper.formatMessage("AnalyticsSettings-day"), value: Models.AnalyticsInterval.Day},
      {label: WebHelper.formatMessage("AnalyticsSettings-week"), value: Models.AnalyticsInterval.Week},
      {label: WebHelper.formatMessage("AnalyticsSettings-month"), value: Models.AnalyticsInterval.Month},
      {label: WebHelper.formatMessage("AnalyticsSettings-quarter"), value: Models.AnalyticsInterval.Quarter},
    ];
    if (
      relativeTo === Models.AnalyticsRelativeTo.CalendarDate &&
      organization &&
      organization.sites?.some((site) => site.real_time_data_enabled)
    ) {
      options.unshift({
        label: WebHelper.formatMessage("AnalyticsSettings-hoursMinutes"),
        value: Models.AnalyticsInterval.Hours,
      });
    }
    return options;
  }, [relativeTo, organization]);

  const handleApplyChanges = () => {
    const analyticsEntities: Models.AnalyticsEntity[] = [];
    selectedEntity.forEach((entity) => {
      if (entity.value.startsWith("organization-")) {
        analyticsEntities.push({
          entityType: Models.EntityType.Organization,
          id: entity.value.substring(entity.value.indexOf("organization-") + "organization-".length, entity.value.length),
          name: entity.label,
        });
      } else if (entity.value.startsWith("site-")) {
        analyticsEntities.push({
          entityType: Models.EntityType.Site,
          id: entity.value.substring(entity.value.indexOf("site-") + "site-".length, entity.value.length),
          name: entity.label,
        });
      } else if (entity.value.startsWith("department-")) {
        analyticsEntities.push({
          entityType: Models.EntityType.Department,
          id: entity.value.substring(entity.value.indexOf("department-") + "department-".length, entity.value.length),
          name: entity.label,
        });
      } else if (entity.value.startsWith("worker-")) {
        analyticsEntities.push({
          entityType: Models.EntityType.Worker,
          id: entity.value.substring(entity.value.indexOf("worker-") + "worker-".length, entity.value.length),
          name: entity.label,
        });
      } else if (entity.value.startsWith("workerGroup-")) {
        analyticsEntities.push({
          entityType: Models.EntityType.WorkerReportGroup,
          id: entity.value.substring(entity.value.indexOf("workerGroup-") + "workerGroup-".length, entity.value.length),
          name: entity.label,
        });
      }
    });
    setIsScroll(false);
    setIsApplyChanges(true);
    onSettingsChange(
      {
        relativeTo: relativeTo,
        startDate: startDateSelected ?? null,
        variableToChartSelected: variableToChart,
        intervalSelected: newIntervalSelected,
        intervalCount: intervalCountSelected,
        endDate: endDateSelected ?? null,
        intervalHoursSelected: newIntervalSelected === Models.AnalyticsInterval.Hours ? hoursMinutesSelected : undefined,
        checkedMostRecent: mostRecentIntervalChecked,
        minimumLifts,
        dataScope,
        showTrendlines,
      },
      newDataSelected,
      variableToChart === Models.AnalyticsVariableToChart.MovementBreakdown
        ? movementTypes
        : variableToChart === Models.AnalyticsVariableToChart.Lifts
          ? liftTypes
          : workerTypes,
      mostRecentIntervalChecked,
      analyticsEntities
    );
    onClose();
  };

  const getKeyByValue = (value: Models.AnalyticsIntervalHours | undefined): number => {
    for (const key in hoursMinutesOptions) {
      if (hoursMinutesOptions[key as unknown as keyof typeof hoursMinutesOptions].value === value) {
        return Number(key);
      }
    }
    return 1;
  };

  const disabledMaxRange = (current: Date, {from}: {from: Date | undefined}, pickerType: string) => {
    const today = site ? toZonedTime(new Date(), site.tz_location) : new Date();

    if (!from || relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay || relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit) {
      return isAfter(current, today);
    }

    let minDate: Date;
    let maxDate: Date;

    switch (pickerType) {
      case "week":
        minDate = addWeeks(from, -99);
        maxDate = addWeeks(from, 99);
        break;
      case "month":
        minDate = addMonths(from, -99);
        maxDate = addMonths(from, 99);
        break;
      case "quarter":
        minDate = addQuarters(from, -99);
        maxDate = addQuarters(from, 99);
        break;
      default:
        if (newIntervalSelected === Models.AnalyticsInterval.Hours) {
          let intervalInMinutes = 0;
          switch (hoursMinutesSelected) {
            case Models.AnalyticsIntervalHours.TwelveHours:
              intervalInMinutes = 12 * 60;
              break;
            case Models.AnalyticsIntervalHours.EightHours:
              intervalInMinutes = 8 * 60;
              break;
            case Models.AnalyticsIntervalHours.FourHours:
              intervalInMinutes = 4 * 60;
              break;
            case Models.AnalyticsIntervalHours.TwoHours:
              intervalInMinutes = 2 * 60;
              break;
            case Models.AnalyticsIntervalHours.OneHours:
              intervalInMinutes = 1 * 60;
              break;
            case Models.AnalyticsIntervalHours.ThirtyMinutes:
              intervalInMinutes = 30;
              break;
            case Models.AnalyticsIntervalHours.FifteenMinutes:
              intervalInMinutes = 15;
              break;
            default:
              break;
          }
          minDate = addMinutes(from, -100 * intervalInMinutes);
          maxDate = addMinutes(from, 100 * intervalInMinutes);
        } else {
          minDate = addDays(from, -99);
          maxDate = addDays(from, 99);
        }
        break;
    }
    return isBefore(current, minDate) || isAfter(current, maxDate) || isAfter(current, today);
  };

  return (
    <Drawer
      open={open}
      width={WebHelper.drawerWidth}
      destroyOnClose
      title={WebHelper.formatMessage("AnalyticsSettings-title")}
      onClose={(reason) => {
        const targetElement = reason.target as HTMLElement;
        const isCloseButton =
          targetElement.getAttribute("aria-label") === "Close" ||
          targetElement.classList.contains("ant-drawer-close") ||
          targetElement.parentElement?.getAttribute("aria-label") === "Close" ||
          targetElement.parentElement?.classList.contains("ant-drawer-close");

        if (isCloseButton || targetElement instanceof SVGElement) {
          handleOnClose();
        } else {
          if (!(loading || (dataScope === Models.DataScope.BetweenDates ? !endDateSelected || !startDateSelected : !endDateSelected)))
            handleApplyChanges();
          else handleOnClose();
        }
      }}
      className={styles.drawerWrapper}>
      <Card className={styles.cardWrapper}>
        <Space className={styles.cardContent} direction="vertical" size="large">
          <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-selectFiltersText")}</Typography.Text>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className={styles.filterItem}>
                <Typography.Text className={styles.filterLabel}>{WebHelper.formatMessage("AnalyticsSettings-relativeTo")}</Typography.Text>
                <Select
                  allowClear={false}
                  onChange={(value) => {
                    setRelativeTo(value);
                    setEndDateSelected(null);
                    setMostRecentIntervalChecked(false);
                    setStartDateSelected(null);
                    if (value === Models.AnalyticsRelativeTo.TimeOfTheDay) setNewIntervalSelected(Models.AnalyticsInterval.Hours);
                    if (value === Models.AnalyticsRelativeTo.DaysInTheSuit) setNewIntervalSelected(Models.AnalyticsInterval.Day);
                    if (
                      value === Models.AnalyticsRelativeTo.DaysInTheSuit &&
                      variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus
                    )
                      setNewDataSelected(Models.ChartDataType.Raw);
                  }}
                  options={relativeToChartOptions}
                  value={relativeTo}
                  dropdownAlign={{
                    points: ["tl", "bl"],
                    offset: [0, 4],
                  }}
                  className={styles.filter}
                />
              </div>
            </Col>
            <Typography.Text className={styles.textWrapper}>
              {relativeTo === Models.AnalyticsRelativeTo.CalendarDate
                ? WebHelper.formatMessage("AnalyticsSettings-calendarDateDescription")
                : relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay
                  ? WebHelper.formatMessage("AnalyticsSettings-timeOfTheDayDescription")
                  : WebHelper.formatMessage("AnalyticsSettings-daysInSuitDescription")}
            </Typography.Text>
            <Col span={24}>
              <div className={styles.filterItem}>
                <div className={styles.filterLabel}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-filter")}</Typography.Text>
                  <Tooltip title={WebHelper.formatMessage("AnalyticsSettings-entitySelectorTooltip")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
                <TreeSelect
                  showSearch
                  treeNodeFilterProp="title"
                  loading={loading}
                  disabled={loading}
                  className={`${styles.treeWrapper} ${isEmpty(selectedEntity) ? styles.highlight : ""}`}
                  value={loading ? undefined : selectedEntity}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  treeCheckable
                  treeCheckStrictly={true}
                  placeholder={WebHelper.formatMessage("AnalyticsSettings-select")}
                  allowClear
                  multiple
                  onChange={(values) => {
                    if (values?.length > 5) {
                      values.pop();
                    } else {
                      setSelectedEntity(values);
                    }
                  }}
                  treeData={cascaderEntityFilterOptions}
                />
              </div>
            </Col>
            {isMixedRealTimeOrganizaton &&
              (relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay ||
                (relativeTo === Models.AnalyticsRelativeTo.CalendarDate && newIntervalSelected === Models.AnalyticsInterval.Hours)) && (
                <div className={styles.infoRealTimeDateRange}>
                  <InfoCircleOutlined />
                  <Typography>{WebHelper.formatMessage("AnalyticsSettings-realTimeDataInfo")}</Typography>
                </div>
              )}
            {relativeTo !== Models.AnalyticsRelativeTo.TimeOfTheDay && relativeTo !== Models.AnalyticsRelativeTo.DaysInTheSuit && (
              <IntervalSelect
                intervalOptions={intervalOptions}
                intervalSelected={newIntervalSelected}
                onChange={(interval) => {
                  setNewIntervalSelected(interval);
                  setEndDateSelected(null);
                  setMostRecentIntervalChecked(false);
                }}
                setDatepickerKey={setDatepickerKey}
              />
            )}
            {(relativeTo === Models.AnalyticsRelativeTo.CalendarDate || relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay) &&
              newIntervalSelected === Models.AnalyticsInterval.Hours && (
                <Col span={24}>
                  <div className={styles.filterItem}>
                    <Slider
                      min={1}
                      marks={hoursMinutesOptions}
                      value={getKeyByValue(hoursMinutesSelected)}
                      max={7}
                      step={null}
                      onChange={(value) => {
                        setHoursMinutesSelected(hoursMinutesOptions[value as keyof typeof hoursMinutesOptions].value);
                        if (endDateSelected && startDateSelected) {
                          if (
                            calculateIntervalCount(
                              {
                                ...analyticsSettings,
                                ...{
                                  endDate: endDateSelected,
                                  startDate: startDateSelected,
                                  relativeTo: relativeToSelected,
                                  dataScope: dataScope,
                                  intervalSelected: Models.AnalyticsInterval.Hours,
                                  intervalHoursSelected: hoursMinutesOptions[value as keyof typeof hoursMinutesOptions].value,
                                },
                              },
                              startDateSelected,
                              endDateSelected
                            ) > (value !== 1 ? 100 : 200)
                          )
                            setEndDateSelected(null);
                        }
                      }}
                      tooltip={{
                        formatter: (value) => {
                          if (value) {
                            return hoursMinutesOptions[value as keyof typeof hoursMinutesOptions].label;
                          }
                          return ``;
                        },
                      }}
                      style={{width: "100%"}}
                    />
                  </div>
                </Col>
              )}
            {relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit && (
              <Col span={24}>
                <div className={styles.filterItem}>
                  <div className={styles.filterLabel}>
                    <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-intervalCount")}</Typography.Text>
                  </div>
                  <Slider
                    min={1}
                    value={intervalCountSelected}
                    max={100}
                    onChange={(value) => {
                      setIntervalCountSelected(value);
                    }}
                    tooltip={{
                      open: tooltipOpen,
                      placement: "bottom",
                      arrow: false,
                      overlayStyle: {zIndex: "1000"},
                      overlayInnerStyle: {
                        color: "black",
                        backgroundColor: "transparent",
                        fontSize: "14px",
                        boxShadow: "none",
                        marginTop: "-2px",
                      },
                      formatter: (value) => {
                        if (newIntervalSelected === Models.AnalyticsInterval.Day) {
                          return `${value} days`;
                        } else if (newIntervalSelected === Models.AnalyticsInterval.Week) {
                          return `${value} weeks`;
                        } else if (newIntervalSelected === Models.AnalyticsInterval.Month) {
                          return `${value} months`;
                        } else if (newIntervalSelected === Models.AnalyticsInterval.Quarter) {
                          return `${value} quarters`;
                        }
                        return ``;
                      },
                    }}
                    style={{width: "100%", marginRight: "12px"}}
                  />
                </div>
              </Col>
            )}
            <div className={styles.datePicker}>
              {relativeTo !== Models.AnalyticsRelativeTo.CalendarDate && (
                <Typography.Text>
                  {relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit
                    ? WebHelper.formatMessage("AnalyticsSettings-dataScopeTextDaysInTheSuit")
                    : WebHelper.formatMessage("AnalyticsSettings-dataScopeTimeOfDay")}
                </Typography.Text>
              )}
              {(relativeTo === Models.AnalyticsRelativeTo.CalendarDate || relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit) && (
                <div className={styles.dateRange}>
                  <Typography.Text>
                    {relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit
                      ? WebHelper.formatMessage("AnalyticsSettings-dataScopeDaysInTheSuit")
                      : WebHelper.formatMessage("AnalyticsSettings-dataScopeCalendarDays")}
                  </Typography.Text>
                  <div className={styles.filterItem}>
                    <Select
                      options={dataRangeChartOptions}
                      value={dataScope}
                      onChange={(value) => {
                        setDataScope(value);
                        setEndDateSelected(null);
                        setMostRecentIntervalChecked(false);
                        setStartDateSelected(null);
                      }}
                      style={{width: "100%"}}
                    />
                  </div>
                </div>
              )}
              <div>
                {(dataScope === Models.DataScope.BetweenDates || relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay) && (
                  <div className={styles.dateRange}>
                    <Typography.Text className={styles.filterDateLabel}>
                      {WebHelper.formatMessage("AnalyticsSettings-dateRange")}
                    </Typography.Text>
                    <RangePicker
                      value={[startDateSelected, endDateSelected]}
                      picker={
                        newIntervalSelected === Models.AnalyticsInterval.Quarter
                          ? "quarter"
                          : newIntervalSelected === Models.AnalyticsInterval.Month
                            ? "month"
                            : newIntervalSelected === Models.AnalyticsInterval.Week
                              ? "week"
                              : "date"
                      }
                      onCalendarChange={(dates) => {
                        if (dates) {
                          dates[0] &&
                            setStartDateSelected(newIntervalSelected === Models.AnalyticsInterval.Week ? startOfWeek(dates[0]) : dates[0]);

                          dates[0] && dates[1] && isAfter(dates[0], dates[1])
                            ? setEndDateSelected(null)
                            : dates[1] &&
                              setEndDateSelected(newIntervalSelected === Models.AnalyticsInterval.Week ? endOfWeek(dates[1]) : dates[1]);
                        } else {
                          setStartDateSelected(null);
                          setEndDateSelected(null);
                        }
                      }}
                      disabledDate={(currentDate, {from}) =>
                        disabledMaxRange(
                          currentDate,
                          {from},
                          newIntervalSelected === Models.AnalyticsInterval.Quarter
                            ? "quarter"
                            : newIntervalSelected === Models.AnalyticsInterval.Month
                              ? "month"
                              : newIntervalSelected === Models.AnalyticsInterval.Week
                                ? "week"
                                : "date"
                        )
                      }
                      format={(value) => {
                        if (newIntervalSelected === Models.AnalyticsInterval.Week && value) {
                          return format(value, "yyyy-MM-dd");
                        } else if (newIntervalSelected === Models.AnalyticsInterval.Month && value) {
                          return format(value, "MMM yyyy");
                        } else if (newIntervalSelected === Models.AnalyticsInterval.Quarter && value) {
                          return format(value, "yyyy-QQQ");
                        }
                        return format(value, "yyyy-MM-dd");
                      }}
                      cellRender={
                        newIntervalSelected === Models.AnalyticsInterval.Quarter
                          ? (current) => <div className="ant-picker-cell-inner">{format(current, "QQQ")}</div>
                          : undefined
                      }
                      className={
                        dataScope === Models.DataScope.BetweenDates && (!endDateSelected || !startDateSelected) ? styles.highlight : ""
                      }
                    />
                  </div>
                )}
                {dataScope !== Models.DataScope.BetweenDates && relativeTo !== Models.AnalyticsRelativeTo.TimeOfTheDay && (
                  <div className={styles.filterItem}>
                    <Typography.Text className={styles.filterDateLabel}>
                      {(relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit ||
                        relativeTo === Models.AnalyticsRelativeTo.CalendarDate) &&
                      dataScope === Models.DataScope.AfterSpecificDate
                        ? WebHelper.formatMessage("AnalyticsSettings-afterDate")
                        : dataScope === Models.DataScope.BeforeSpecificDate
                          ? WebHelper.formatMessage("AnalyticsSettings-beforeDate")
                          : WebHelper.formatMessage("AnalyticsSettings-endDate")}
                    </Typography.Text>
                    <div className={styles.filterEndDate}>
                      <DatePicker
                        key={`${datepickerKey}-endDate`}
                        picker={newIntervalSelected == Models.AnalyticsInterval.Day ? undefined : (newIntervalSelected as any)} // type mismatch, setting it as any fixes the problem
                        value={
                          //most recent selects the most recent complete interval
                          mostRecentIntervalChecked ? new Date() : endDateSelected
                        }
                        cellRender={
                          newIntervalSelected === Models.AnalyticsInterval.Quarter
                            ? (date) => {
                                //workaround to show Q-QuarterNumber when opening calendar
                                if (typeof date !== "string") return `Q-${getQuarter(date)}`;
                                return "";
                              }
                            : undefined
                        }
                        placeholder={WebHelper.formatMessage("AnalyticsSettings-selectEndDate")}
                        format={(date) =>
                          newIntervalSelected == Models.AnalyticsInterval.Quarter
                            ? `${getYear(date)}-Q${getQuarter(date)}`
                            : newIntervalSelected == Models.AnalyticsInterval.Month
                              ? date.toLocaleDateString(AppConfig.Settings.Localization.defaultLocale.code.replace("_", "-"), {
                                  month: "short",
                                  year: "numeric",
                                })
                              : formatDate(date)
                        }
                        onChange={(date) => {
                          if (date === null) {
                            setEndDateSelected(null);
                            setMostRecentIntervalChecked(false);
                            return;
                          }
                          let lastDayOfInterval;

                          if (newIntervalSelected == Models.AnalyticsInterval.Week)
                            if (dataScope === Models.DataScope.BeforeSpecificDate) lastDayOfInterval = addDays(endOfWeek(date), -1);
                            else lastDayOfInterval = startOfWeek(date);
                          else lastDayOfInterval = date;

                          setEndDateSelected(lastDayOfInterval);
                        }}
                        disabledDate={(date) => {
                          if (relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit) {
                            return startDateSelected ? isBefore(date, startDateSelected) : false;
                          }
                          return (
                            isAfter(date, endOfDay(new Date())) ||
                            (relativeTo === Models.AnalyticsRelativeTo.CalendarDate &&
                              ((startDateSelected && isEqual(date, startDateSelected)) ||
                                (startDateSelected && isBefore(date, startDateSelected)) ||
                                (newIntervalSelected === Models.AnalyticsInterval.Hours &&
                                  startDateSelected !== null &&
                                  isAfter(
                                    date,
                                    addDays(
                                      startDateSelected,
                                      -MAX_BARS_TO_DISPLAY /
                                        (hoursMinutesSelected
                                          ? hoursMinutesSelected === Models.AnalyticsIntervalHours.FifteenMinutes
                                            ? -40
                                            : hoursMinutesSelected === Models.AnalyticsIntervalHours.ThirtyMinutes
                                              ? -20
                                              : hoursMinutesSelected === Models.AnalyticsIntervalHours.OneHours
                                                ? -10
                                                : hoursMinutesSelected === Models.AnalyticsIntervalHours.TwoHours
                                                  ? -5
                                                  : hoursMinutesSelected === Models.AnalyticsIntervalHours.FourHours
                                                    ? -4
                                                    : -2
                                          : 2)
                                    )
                                  )) ||
                                (newIntervalSelected === Models.AnalyticsInterval.Day &&
                                  startDateSelected !== null &&
                                  isAfter(date, addDays(startDateSelected, MAX_BARS_TO_DISPLAY))) ||
                                (newIntervalSelected === Models.AnalyticsInterval.Week &&
                                  startDateSelected !== null &&
                                  isAfter(date, addWeeks(startDateSelected, MAX_BARS_TO_DISPLAY))) ||
                                (newIntervalSelected === Models.AnalyticsInterval.Month &&
                                  startDateSelected !== null &&
                                  isAfter(date, addMonths(startDateSelected, MAX_BARS_TO_DISPLAY))) ||
                                (newIntervalSelected === Models.AnalyticsInterval.Quarter &&
                                  startDateSelected !== null &&
                                  isAfter(date, addQuarters(startDateSelected, MAX_BARS_TO_DISPLAY)))))
                          );
                        }}
                        onOpenChange={(open) => {
                          if (open) {
                            setMostRecentIntervalChecked(false);
                            setEndDateSelected(null);
                          }
                        }}
                        style={{
                          width: `${
                            relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit && dataScope === Models.DataScope.AfterSpecificDate
                              ? "100%"
                              : "125px"
                          }`,
                        }}
                        className={!endDateSelected ? styles.highlight : ""}
                      />
                      {!(relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit && dataScope === Models.DataScope.AfterSpecificDate) && (
                        <Checkbox
                          checked={mostRecentIntervalChecked}
                          onChange={(event) => {
                            setMostRecentIntervalChecked(event.target.checked);
                            if (event.target.checked) {
                              const targetDate = startOfDay(new Date());
                              let lastDayOfInterval = targetDate;

                              if (targetDate && newIntervalSelected == Models.AnalyticsInterval.Day)
                                lastDayOfInterval = endOfDay(targetDate);
                              if (targetDate && newIntervalSelected == Models.AnalyticsInterval.Week)
                                lastDayOfInterval = endOfWeek(targetDate);
                              if (targetDate && newIntervalSelected == Models.AnalyticsInterval.Month)
                                lastDayOfInterval = endOfMonth(targetDate);
                              if (targetDate && newIntervalSelected == Models.AnalyticsInterval.Quarter)
                                lastDayOfInterval = endOfQuarter(targetDate);
                              setEndDateSelected(lastDayOfInterval);
                              if (startDateSelected && relativeTo !== Models.AnalyticsRelativeTo.DaysInTheSuit) {
                                const newAnalyticsSettings = {
                                  ...analyticsSettings,
                                  ...{
                                    endDate: lastDayOfInterval,
                                    startDate: startDateSelected,
                                    intervalSelected: newIntervalSelected,
                                    intervalHoursSelected:
                                      newIntervalSelected === Models.AnalyticsInterval.Hours ? hoursMinutesSelected : undefined,
                                  },
                                };
                                const intervals = calculateIntervalCount(newAnalyticsSettings, startDateSelected, lastDayOfInterval);
                                if (intervals > 100) setStartDateSelected(null);
                              }
                            } else setEndDateSelected(null);
                          }}>
                          <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-current")}</Typography.Text>
                        </Checkbox>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {relativeTo === Models.AnalyticsRelativeTo.CalendarDate && dataScope === Models.DataScope.BetweenDates && (
                <div className={styles.infoDateRange}>
                  <InfoCircleOutlined />
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-infoDateRange")}</Typography.Text>
                </div>
              )}
            </div>
          </Row>
        </Space>
      </Card>
      <Card className={styles.cardWrapper}>
        <Space className={styles.cardContent} direction="vertical" size="large">
          <Typography.Text>
            {variableToChart === Models.AnalyticsVariableToChart.Lifts ||
            variableToChart === Models.AnalyticsVariableToChart.MovementBreakdown
              ? WebHelper.formatMessage("AnalyticsSettings-selectVariableMinimumText")
              : WebHelper.formatMessage("AnalyticsSettings-selectVariableText")}
          </Typography.Text>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className={styles.filterItem}>
                <Typography.Text className={styles.filterLabel}>
                  {WebHelper.formatMessage("AnalyticsSettings-variableToChart")}
                </Typography.Text>
                <Select
                  options={variableToChartOptions}
                  value={variableToChart}
                  onChange={(value) => {
                    setVariableToChart(value);
                    switch (value) {
                      case Models.AnalyticsVariableToChart.HoursUsed:
                        setNewDataSelected(Models.ChartDataType.Raw);
                        break;
                      case Models.AnalyticsVariableToChart.Lifts:
                        setNewDataSelected(Models.ChartDataType.Percentage);
                        setLiftTypes([Models.ChartMovementType.Safe, Models.ChartMovementType.Risky]);
                        break;
                      case Models.AnalyticsVariableToChart.WeightOffloaded:
                        setNewDataSelected(Models.ChartDataType.Raw);
                        break;
                      case Models.AnalyticsVariableToChart.MovementBreakdown:
                        setNewDataSelected(Models.ChartDataType.Percentage);
                        break;
                      case Models.AnalyticsVariableToChart.WorkerActiveStatus:
                        setNewDataSelected(Models.ChartDataType.Raw);
                        setWorkerTypes([Models.WorkerStatusType.Active, Models.WorkerStatusType.Inactive]);
                        break;
                    }
                  }}
                  className={styles.filter}
                />
              </div>
            </Col>
            {(variableToChart === Models.AnalyticsVariableToChart.Lifts ||
              variableToChart === Models.AnalyticsVariableToChart.MovementBreakdown) && (
              <Col span={24}>
                <div className={styles.filterItem}>
                  <div className={styles.filterLabel}>
                    <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-minimumLifts")}</Typography.Text>
                    <Tooltip title={WebHelper.formatMessage("AnalyticsSettings-minimumLiftsTooltip")}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                  <Slider
                    min={0}
                    max={1000}
                    step={10}
                    value={minimumLifts}
                    onChange={(value) => setMinimumLifts(value)}
                    style={{width: "90%"}}
                  />
                </div>
              </Col>
            )}
            {!(
              relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit &&
              variableToChart === Models.AnalyticsVariableToChart.WorkerActiveStatus
            ) && (
              <Col span={24}>
                <div className={styles.filterItem}>
                  <Typography.Text className={styles.filterLabel}>
                    {WebHelper.formatMessage("AnalyticsSettings-dataFormat")}
                  </Typography.Text>
                  <Radio.Group
                    value={newDataSelected}
                    onChange={(event) => {
                      setNewDataSelected(event.target.value);
                    }}>
                    <Space direction="vertical">
                      {variableToChart !== Models.AnalyticsVariableToChart.HoursUsed &&
                        variableToChart !== Models.AnalyticsVariableToChart.WeightOffloaded && (
                          <Radio value={Models.ChartDataType.Percentage}>{WebHelper.formatMessage("AnalyticsSettings-percentage")}</Radio>
                        )}
                      <Radio value={Models.ChartDataType.Raw}>{WebHelper.formatMessage("AnalyticsSettings-rawSum")}</Radio>
                      {variableToChart !== Models.AnalyticsVariableToChart.WorkerActiveStatus && (
                        <Radio value={Models.ChartDataType.Average}>{WebHelper.formatMessage("AnalyticsSettings-average")}</Radio>
                      )}
                    </Space>
                  </Radio.Group>
                </div>
              </Col>
            )}
            {(variableToChart === Models.AnalyticsVariableToChart.Lifts ||
              variableToChart === Models.AnalyticsVariableToChart.MovementBreakdown) && (
              <Typography.Text className={styles.textWrapper}>
                {WebHelper.formatMessage("AnalyticsSettings-checkboxDescription")}
              </Typography.Text>
            )}
            {variableToChart === Models.AnalyticsVariableToChart.WorkerActiveStatus && (
              <Checkbox.Group
                className={styles.checkboxFilter}
                value={relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit ? [Models.WorkerStatusType.Active] : workerTypes}
                onChange={(value) => setWorkerTypes(value)}>
                {relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit && (
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-workers")}</Typography.Text>
                )}
                <Checkbox
                  value={Models.WorkerStatusType.Active}
                  className={`${styles.checkboxColor} ${styles.active} ${relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit ? styles.disabled : ""} `}
                  disabled={relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-activeWorkers")}</Typography.Text>
                </Checkbox>
                {relativeTo !== Models.AnalyticsRelativeTo.DaysInTheSuit && (
                  <Checkbox value={Models.WorkerStatusType.Inactive} className={`${styles.checkboxColor} ${styles.inactive}`}>
                    <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-inactiveWorkers")}</Typography.Text>
                  </Checkbox>
                )}
              </Checkbox.Group>
            )}
            {variableToChart === Models.AnalyticsVariableToChart.Lifts && (
              <Checkbox.Group className={styles.checkboxFilter} value={liftTypes} onChange={(value) => setLiftTypes(value)}>
                <Checkbox value={Models.ChartMovementType.Safe} className={`${styles.checkboxColor} ${styles.safe}`}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-safe")}</Typography.Text>
                </Checkbox>
                <Checkbox value={Models.ChartMovementType.Risky} className={`${styles.checkboxColor} ${styles.risky}`}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-risky")}</Typography.Text>
                </Checkbox>
              </Checkbox.Group>
            )}
            {variableToChart === Models.AnalyticsVariableToChart.MovementBreakdown && (
              <Checkbox.Group className={styles.checkboxFilter} value={movementTypes} onChange={(value) => setMovementTypes(value)}>
                <Checkbox
                  value={Models.ChartMovementType.ExcessiveForwardBending}
                  className={`${styles.checkboxColor} ${styles.excessiveForwardBending}`}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-excessiveForwardBending")}</Typography.Text>
                </Checkbox>
                <Checkbox
                  value={Models.ChartMovementType.ProlongedBending}
                  className={`${styles.checkboxColor} ${styles.prolongedBending}`}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-prolongedBending")}</Typography.Text>
                </Checkbox>
                <Checkbox value={Models.ChartMovementType.Twisting} className={`${styles.checkboxColor} ${styles.twisting}`}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-twisting")}</Typography.Text>
                </Checkbox>
                <Checkbox value={Models.ChartMovementType.SideBending} className={`${styles.checkboxColor} ${styles.sideBending}`}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-sideBending")}</Typography.Text>
                </Checkbox>
              </Checkbox.Group>
            )}
            {flags.analytics_trendlines && (
              <Col span={24}>
                <div className={styles.filterItem}>
                  <Typography.Text className={styles.filterLabel}>
                    {WebHelper.formatMessage("AnalyticsSettings-displayTrendlines")}
                  </Typography.Text>
                  <Switch
                    checked={showTrendlines}
                    onChange={(checked) => {
                      setShowTrendlines(checked);
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Space>
      </Card>
      <Button
        key="submit"
        type={ButtonType.Primary}
        disabled={
          loading ||
          (dataScope === Models.DataScope.BetweenDates || relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay
            ? !endDateSelected || !startDateSelected
            : !endDateSelected) ||
          isEmpty(selectedEntity)
        }
        loading={loading}
        shape="round"
        onClick={() => handleApplyChanges()}>
        {WebHelper.formatMessage("AnalyticsSettings-applyFilters")}
      </Button>
      <Button key="cancel" type={ButtonType.Default} shape="round" onClick={() => handleOnClose()}>
        {WebHelper.formatMessage("Common-cancel")}
      </Button>
    </Drawer>
  );
};

type IntervalSelectProps = {
  intervalOptions: {label: string; value: Models.AnalyticsInterval}[];
  intervalSelected: Models.AnalyticsInterval;
  onChange: (interval: Models.AnalyticsInterval) => void;
  setDatepickerKey: (b: number) => void;
};

const IntervalSelect: FunctionComponent<IntervalSelectProps> = ({intervalOptions, intervalSelected, onChange, setDatepickerKey}) => {
  return (
    <Col span={24}>
      <div className={styles.filterItem}>
        <Typography.Text className={styles.filterLabel}>{WebHelper.formatMessage("AnalyticsSettings-interval")}</Typography.Text>
        <Select
          options={intervalOptions}
          value={intervalSelected}
          onChange={(value) => {
            setDatepickerKey(new Date().getTime());
            onChange(value);
          }}
          className={styles.filter}
        />
      </div>
    </Col>
  );
};
