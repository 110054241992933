import {DownloadOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Typography, Select, Space} from "antd";
import {addDays, differenceInDays, endOfDay, isAfter, startOfDay} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import React, {FunctionComponent, useMemo, useState, useEffect} from "react";

import styles from "./DownloadAllData.module.scss";
import * as Models from "../../../core/models";
import {AnalyticsModule} from "../../../core/modules/AnalyticsModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {concat, round} from "../../utils/FunctionUtils";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {DatePicker} from "../date-picker/DatePicker";

export type DownloadAllDataProps = {};

export const DownloadAllData: FunctionComponent<DownloadAllDataProps> = () => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [intervalSelected, setIntervalSelected] = useState(Models.AnalyticsInterval.Day);
  const [scopeSelected, setScopeSelected] = useState(Models.EntityType.Organization);
  const [startDate, setStartDate] = useState<Date | null>(addDays(endOfDay(new Date()), -1));
  const [endDate, setEndDate] = useState<Date | null>(addDays(endOfDay(new Date()), -1));

  const intervalOptions = useMemo(
    () => [
      {label: WebHelper.formatMessage("AnalyticsSettings-day"), value: Models.AnalyticsInterval.Day},
      {label: WebHelper.formatMessage("AnalyticsSettings-week"), value: Models.AnalyticsInterval.Week},
    ],
    []
  );

  const scopeOptions = useMemo(
    () => [
      {label: WebHelper.formatMessage("DownloadAllData-organization"), value: Models.EntityType.Organization},
      {label: WebHelper.formatMessage("DownloadAllData-site"), value: Models.EntityType.Site},
      {label: WebHelper.formatMessage("DownloadAllData-department"), value: Models.EntityType.Department},
    ],
    []
  );

  const analyticsQuery = useQuery({
    queryKey: ["AnalyticsTabContent-fetchData", scopeSelected, intervalSelected, authenticationStore.state.accessToken],
    queryFn: () => {
      if (startDate && endDate) {
        const timezone = "";
        const endTime = fromZonedTime(startOfDay(addDays(endDate, 1)), timezone ?? WebHelper.getLocalTimezoneFromBrowser()).toISOString();

        return AnalyticsModule.analyticsMultiTimeSeriesData({
          accessToken: authenticationStore.state.accessToken!,
          resource_type: scopeSelected,
          end_time: endTime,
          interval_type: intervalSelected,
          interval_count:
            intervalSelected === Models.AnalyticsInterval.Week
              ? Math.ceil(differenceInDays(endDate, startDate) / 7)
              : differenceInDays(endDate, startDate) + 1,
        });
      } else return null;
    },
    enabled: false,
  });

  useEffect(() => {
    if (!analyticsQuery.data) return;

    if (!analyticsQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("AnalyticsTabContent-errorMessage"), analyticsQuery.data.correlationId);
      return;
    } else {
      const headers = [
        [
          WebHelper.formatMessage("DownloadAllData-columnNameResourceType"),
          WebHelper.formatMessage("DownloadAllData-columnNameResourceId"),
          WebHelper.formatMessage("DownloadAllData-columnNameResourceName"),
          WebHelper.formatMessage("DownloadAllData-columnNameStartDate"),
          WebHelper.formatMessage("DownloadAllData-columnNameStartTime"),
          WebHelper.formatMessage("DownloadAllData-columnNameEndDate"),
          WebHelper.formatMessage("DownloadAllData-columnNameEndTime"),
          WebHelper.formatMessage("DownloadAllData-columnNameHoursUsed"),
          WebHelper.formatMessage("DownloadAllData-columnNameTotalLifts"),
          WebHelper.formatMessage("DownloadAllData-columnNameRiskyLifts"),
          WebHelper.formatMessage("DownloadAllData-columnNameSafeLifts"),
          WebHelper.formatMessage("DownloadAllData-columnNameSafeLiftsPercentage"),
          WebHelper.formatMessage("DownloadAllData-columnNameRiskyLiftsPercentage"),
          WebHelper.formatMessage("DownloadAllData-columnNameWeightOffloaded"),
          WebHelper.formatMessage("DownloadAllData-columnNameExcessiveForwardLifts"),
          WebHelper.formatMessage("DownloadAllData-columnNameProlongedBendLifts"),
          WebHelper.formatMessage("DownloadAllData-columnNameTwistedLifts"),
          WebHelper.formatMessage("DownloadAllData-columnNameSideBendLifts"),
          WebHelper.formatMessage("DownloadAllData-columnNameAssignedWorkers"),
          WebHelper.formatMessage("DownloadAllData-columnNameActiveWorkers"),
          WebHelper.formatMessage("DownloadAllData-columnNameActiveWorkersPercentage"),
          WebHelper.formatMessage("DownloadAllData-columnNameInactiveWorkers"),
          WebHelper.formatMessage("DownloadAllData-columnNameInactiveWorkersPercentage"),
        ],
      ];

      const dataRows = analyticsQuery.data.data.flatMap((obj) =>
        obj.time_series_data.map((tsData) => {
          return [
            obj.resource_type,
            obj.id,
            obj.name.length > 40 ? `${obj.name.replace(",", "").substring(0, 40)}...` : obj.name.replace(",", ""),
            WebHelper.formatDateNoTime(new Date(tsData.start_time)),
            WebHelper.formatTimeOnly(new Date(tsData.start_time)),
            WebHelper.formatDateNoTime(new Date(tsData.end_time)),
            WebHelper.formatTimeOnly(new Date(tsData.end_time)),
            (tsData.usage_sec / 3600).toFixed(2),
            tsData.total_lifts,
            tsData.total_lifts - tsData.safe_lifts,
            tsData.safe_lifts,
            tsData.total_lifts > 0 ? round((tsData.safe_lifts / tsData.total_lifts) * 100, 1) : 0,
            tsData.total_lifts > 0 ? round(((tsData.total_lifts - tsData.safe_lifts) / tsData.total_lifts) * 100, 1) : 0,
            tsData.weight_offloaded_lbs,
            tsData.excessive_forward_lifts,
            tsData.prolonged_bend_lifts,
            tsData.twisted_lifts,
            tsData.side_bend_lifts,
            tsData.assigned_workers,
            tsData.active_workers,
            tsData.assigned_workers > 0 ? round((tsData.active_workers * 100) / tsData.assigned_workers, 1) : 0,
            tsData.assigned_workers - tsData.active_workers,
            tsData.assigned_workers > 0 ? round(((tsData.assigned_workers - tsData.active_workers) * 100) / tsData.assigned_workers, 1) : 0,
          ];
        })
      );

      const csvContent = concat(headers, dataRows)
        .map((row) => row.join(","))
        .join("\n");

      WebHelper.downloadFile(csvContent, `${WebHelper.formatMessage("DownloadAllData-reportName")}.csv`, "application/csv");
    }
  }, [analyticsQuery.data]);

  return (
    <>
      <div className={styles.filterWrapper}>
        <Typography.Text className={styles.filterLabel} strong>
          {WebHelper.formatMessage("DownloadAllData-scope")}
        </Typography.Text>

        <Select className={styles.filter} options={scopeOptions} value={scopeSelected} onChange={(value) => setScopeSelected(value)} />
      </div>

      <div className={styles.filterWrapper}>
        <Typography.Text className={styles.filterLabel} strong>
          {WebHelper.formatMessage("DownloadAllData-interval")}
        </Typography.Text>

        <Select
          className={styles.filter}
          options={intervalOptions}
          value={intervalSelected}
          onChange={(value) => setIntervalSelected(value)}
        />
      </div>

      <div className={styles.filterWrapper}>
        <Typography.Text className={styles.filterLabel} strong>
          {WebHelper.formatMessage("DownloadAllData-dateRange")}
        </Typography.Text>

        <DatePicker.RangePicker
          className={styles.filter}
          value={[startDate, endDate]}
          onCalendarChange={(dates) => {
            setStartDate(dates?.[0] ?? null);
            setEndDate(dates?.[1] ?? null);
          }}
          onOpenChange={(open) => {
            if (open) {
              setStartDate(null);
              setEndDate(null);
            }
          }}
          disabledDate={(date) => isAfter(date, new Date())}
        />
      </div>
      <Space className={styles.buttonWrapper}>
        <Button
          type={ButtonType.Primary}
          shape="round"
          icon={<DownloadOutlined />}
          loading={analyticsQuery.isFetching}
          onClick={() => {
            analyticsQuery.refetch();
            appStore.sendAnalyticTrack(SegmentKey.AllOrganizationsDataDownloaded, {
              resourceType: scopeSelected,
              interval: intervalSelected,
              startDate: startDate,
              endDate: endDate,
            });
          }}>
          {WebHelper.formatMessage("DownloadAllData-download")}
        </Button>
      </Space>
    </>
  );
};
