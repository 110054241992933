import {FilterValue, SortOrder} from "antd/lib/table/interface";

import {AnalyticsSettings} from "./AnalyticsType";

export enum DeviceManagementTabKeys {
  DeviceUsageStats = "deviceUsageStats",
  DeviceErrors = "deviceErrors",
  DeviceConfig = "deviceConfig",
  Gateways = "gateways",
}

export enum WorkersTagsKey {
  Assigned = "assigned",
  Waitlist = "waitlist",
  Enrolled = "enrolled",
  Unenrolled = "unenrolled",
  All = "all",
}

export enum DevicesTagsKey {
  Assigned = "assigned",
  Available = "available",
  OutOfService = "outofservice",
  Connected = "connected",
  Disconnected = "disconnected",
  All = "all",
}

export enum DevicesLiveCheckoutTagsKey {
  DeviceCheckedOut = "device_checked_out",
  DeviceRecentlyUsed = "device_recently_used",
  DeviceNotCheckedOut = "device_not_checked_out",
  DeviceIdle = "device_idle",
  All = "all",
}

export type DepartmentDevicesTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
  connectedFilteredValue: FilterValue | null;
  deviceTagFilteredValue: FilterValue | null;
  systemSerialNumberFilteredValue: FilterValue | null;
  revisionFilteredValue: FilterValue | null;
  firmwareFilteredValue: FilterValue | null;
  assignedWorkerFilteredValue: FilterValue | null;
};

export type DeviceManagementDevicesTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
  connectedFilteredValue: FilterValue | null;
  hardwareRevFilteredValue: FilterValue | null;
  firmwareRevFilteredValue: FilterValue | null;
  configFilteredValue: FilterValue | null;
};

export type GatewayTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
  connectedFilteredValue: FilterValue | null;
  firmwareFilteredValue: FilterValue | null;
  currentSoftwareFilteredValue: FilterValue | null;
  statusFilteredValue: FilterValue | null;
};

export type OrganizationsTableSettings = {
  pageSize: number;
};

export type SessionsTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
  statusFilteredValue: FilterValue | null;
  idFilteredValue: FilterValue | null;
  uuidFilteredValue: FilterValue | null;
  assignedWorkerFilteredValue: FilterValue | null;
};

export type UserPermissionsTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
  givenNameFilteredValue: FilterValue | null;
  familyNameFilteredValue: FilterValue | null;
  organizationNameFilteredValue: FilterValue | null;
  accessLevelFilteredValue: FilterValue | null;
  activeFilteredValue: FilterValue | null;
};

export type DepartmentWorkersTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
  fullNameFilteredValue: FilterValue | null;
  employeeIDFilteredValue: FilterValue | null;
  assignedDeviceFilteredValue: FilterValue | null;
  enrolledFilteredValue: FilterValue | null;
};

export type DepartmentWorkersTabTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
};

export type DepartmentDevicesTabTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
};

export type LiveCheckedOutDevicesTabTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
};

export type SiteWorkersTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
  fullNameFilteredValue: FilterValue | null;
  employeeIDFilteredValue: FilterValue | null;
  assignedDeviceFilteredValue: FilterValue | null;
  departmentNameFilteredValue: FilterValue | null;
};

export type DashboardWorkersTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
};

export type WorkerReportGroupWorkersTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
};

export type DashboardDailyUsageBreakdownTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
};

export type AnalyticsDetailsTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
};

export type DeviceUsageReassignmentTableSettings = {
  pageSize: number;
  sortColumn: string | undefined;
  sortOrder: SortOrder | undefined;
};

export enum SupportedLanguages {
  en_US = "en-US",
  es_ES = "es-ES",
  fr_CA = "fr-CA",
}

export type UserPreferences = {
  preferred_language?: SupportedLanguages;
  tableSettings?: {
    analyticsDetailsTable?: AnalyticsDetailsTableSettings;
    departmentModeDevices?: DepartmentDevicesTableSettings;
    deviceManagementModeDevices?: DeviceManagementDevicesTableSettings;
    gateways?: GatewayTableSettings;
    organizations?: OrganizationsTableSettings;
    sessions?: SessionsTableSettings;
    usersPermissions?: UserPermissionsTableSettings;
    departmentWorkers?: DepartmentWorkersTableSettings;
    departmentWorkersTab?: DepartmentWorkersTabTableSettings;
    departmentDevicesTab?: DepartmentDevicesTabTableSettings;
    liveCheckedOutDevicesTab?: DepartmentDevicesTabTableSettings;
    siteWorkers?: SiteWorkersTableSettings;
    workerReportGroupWorkers?: WorkerReportGroupWorkersTableSettings;
    dashboardActiveWorkersTable?: DashboardWorkersTableSettings;
    dashboardInactiveWorkersTable?: DashboardWorkersTableSettings;
    dashboardDailyUsageBreakdownTable?: DashboardDailyUsageBreakdownTableSettings;
    deviceUsageReassignmentTable?: DeviceUsageReassignmentTableSettings;
  };
  deviceManagementActiveTabKey?: DeviceManagementTabKeys;
  departmentWorkersTabSelectedTag?: WorkersTagsKey;
  departmentDevicesTabSelectedTag?: DevicesTagsKey;
  LiveCheckedOutDevicesTabSelectedTag?: DevicesLiveCheckoutTagsKey;
  LiveCheckedOutDevicesTabShowOnlyAssigned?: boolean;
  analyticsSettings?: AnalyticsSettings;
  favorites?: {organizationIds: string[]};
  shouldShowRemoveFromFavoritesModal?: boolean;
  workerReportGroupDetailSelectedTag?: WorkersTagsKey;
  metricMeasurementUnits?: boolean;
};
