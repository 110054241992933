import {useMutation} from "@tanstack/react-query";
import {Form, Modal, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState, useMemo} from "react";
import {useNavigate} from "react-router-dom";

import {DepartmentForm, DepartmentFormFields} from "./department-form/DepartmentForm";
import styles from "./DepartmentModal.module.scss";
import * as Models from "../../../core/models";
import {UserScope} from "../../../core/models";
import {DepartmentModule} from "../../../core/modules/DepartmentModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {LinkButton} from "../common/link-button/LinkButton";
import {ConfirmationModal, ConfirmationModalSizes} from "../confirmation-modal/ConfirmationModal";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";

type DepartmentModalProps = {
  data: {mode: "create"; siteId: string} | {mode: "edit"; department: Models.Department};
  onClose: () => void;
  onSave: () => void;
  onDelete?: () => void;
  open: boolean;
};

export const DepartmentModal: FunctionComponent<DepartmentModalProps> = observer(({data, onClose, onSave, onDelete, open}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const navigate = useNavigate();

  const [form] = Form.useForm<DepartmentFormFields>();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const {mutate: updateDepartmentMutate, isPending: updateDepartmentIsLoading} = useMutation({
    mutationFn: (mutationParams: {formValues: DepartmentFormFields; department: Models.Department}) => {
      const {name} = mutationParams.formValues;
      const {id, site} = mutationParams.department;
      return DepartmentModule.updateDepartment({
        accessToken: authenticationStore.state.accessToken!,
        id,
        name,
        site_id: site.id,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("DepartmentModal-updateError"), response.correlationId);
        return;
      }

      onSave();
    },
  });

  const {mutate: createDepartmentMutate, isPending: createDepartmentIsLoading} = useMutation({
    mutationFn: (mutationParams: {formValues: DepartmentFormFields; siteId: string}) => {
      const {name} = mutationParams.formValues;
      return DepartmentModule.createDepartment({
        accessToken: authenticationStore.state.accessToken!,
        name,
        site_id: mutationParams.siteId,
      });
    },
    onSuccess: (data) => {
      if (!data.success) WebHelper.showErrorMessage(WebHelper.formatMessage("DepartmentModal-createError"), data.correlationId);
      else {
        const {id} = data.department;
        appStore.sendAnalyticTrack(SegmentKey.AddedNewDepartment, {
          orgID: data.department.organization.id,
          siteID: data.department.site.id,
          departmentID: id,
        });
        form.resetFields();
        authenticationStore
          .fetchMe({
            accessToken: authenticationStore.state.accessToken!,
            sub: authenticationStore.state.user!.sub!,
          })
          .then(() => {
            // The following lines need to be in this specific order so that
            // the navigate hook does not stop the execution of the onSave method
            navigate(`/organizations/${data.department.organization.id}/sites/${data.department.site.id}/departments/${id}`);
            onSave();
          });

        // --- End ---
      }
    },
  });

  const handleCancel = () => {
    if (form.isFieldsTouched() && !showDiscardModal) setShowDiscardModal(true);
    else {
      setShowDiscardModal(false);
      onClose();
      form.resetFields();
    }
  };

  const handleOnFieldsChange = () => {
    if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
      setIsFormDisabled(true);
    } else {
      setIsFormDisabled(false);
    }
  };

  const deleteDepartment = useMutation({
    mutationFn: (department: Models.Department) => {
      return DepartmentModule.deleteDepartment({
        accessToken: authenticationStore.state.accessToken!,
        id: department.id,
      });
    },
    onSuccess: (data) => {
      if (data.success) {
        // The following lines need to be in this specific order so that
        // the navigate hook does not stop the execution of the onDelete method
        navigate(location.pathname.substring(0, location.pathname.indexOf("/departments")));
        if (onDelete) onDelete();
        // --- End ---
      } else WebHelper.showErrorMessage(WebHelper.formatMessage("DepartmentModal-deleteError"), data.correlationId);
    },
  });

  const handleDeleteOk = () => {
    if (data.mode !== "edit") return;
    if (deleteDepartment.isPending || !data.department.id) return;
    deleteDepartment.mutate(data.department);
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then((formValues) => {
        switch (data.mode) {
          case "create":
            createDepartmentMutate({siteId: data.siteId, formValues});
            break;
          case "edit":
            updateDepartmentMutate({department: data.department, formValues});
        }
      })
      // This catch prevents AntD from throwing an error to the console when form validations fail
      .catch(() => {});
  };

  const title = useMemo(() => {
    switch (data.mode) {
      case "create":
        return <div id="createTitle-departmentModal">{WebHelper.formatMessage("DepartmentModal-createTitle")}</div>;
      case "edit":
        return <div id="editTitle-departmentModal">{WebHelper.formatMessage("DepartmentModal-editTitle")}</div>;
    }
  }, [data.mode]);

  const mutationLoading = updateDepartmentIsLoading || createDepartmentIsLoading;

  return (
    <>
      <Modal
        centered
        open={open}
        title={title}
        destroyOnClose={true}
        afterClose={() => form.resetFields()}
        onOk={handleOk}
        okText={WebHelper.formatMessage("Common-save")}
        okButtonProps={{shape: "round", loading: mutationLoading, disabled: mutationLoading || isFormDisabled}}
        cancelButtonProps={{shape: "round", disabled: mutationLoading}}
        onCancel={handleCancel}
        footer={
          data.mode === "edit"
            ? [
                <div
                  className={styles.footer}
                  key="footer"
                  style={
                    !(authenticationStore.permissionLevel(UserScope.Department) === Models.UserAccessLevel.Admin)
                      ? {justifyContent: "flex-end"}
                      : {}
                  }>
                  {authenticationStore.permissionLevel(UserScope.Department) === Models.UserAccessLevel.Admin && (
                    <LinkButton id="deleteDepartmentButton-modalEntity" key="delete" onClick={() => setShowDeleteModal(true)}>
                      {WebHelper.formatMessage("DepartmentModal-deleteButtonText")}
                    </LinkButton>
                  )}
                  <div className={styles.buttonsWrapper}>
                    <Button key="back" shape="round" disabled={mutationLoading} onClick={handleCancel}>
                      {WebHelper.formatMessage("Common-cancel")}
                    </Button>
                    <Button
                      id={`saveButton-departmentModal`}
                      key="submit"
                      type={ButtonType.Primary}
                      shape="round"
                      disabled={mutationLoading || isFormDisabled}
                      loading={mutationLoading}
                      onClick={handleOk}>
                      {WebHelper.formatMessage("Common-save")}
                    </Button>
                  </div>
                </div>,
              ]
            : undefined
        }>
        <DepartmentForm
          form={form}
          initialValues={data.mode === "edit" ? data.department : undefined}
          loading={mutationLoading}
          onFieldsChange={handleOnFieldsChange}
        />
      </Modal>
      <ConfirmationModal
        showConfirmationModal={showDeleteModal}
        loading={deleteDepartment.isPending}
        title={WebHelper.formatMessage("DepartmentDetail-deleteAlertTitle")}
        message={<Typography.Text>{WebHelper.formatMessage("DepartmentDetail-deleteAlertMessage")}</Typography.Text>}
        onOk={handleDeleteOk}
        onCancel={() => setShowDeleteModal(false)}
        okButtonText={WebHelper.formatMessage("Common-delete")}
        referenceConfirmationValue={WebHelper.formatMessage("Common-deleteConfirmationValue")}
        width={ConfirmationModalSizes.Small}
      />
      <DiscardChangesModal onDiscard={handleCancel} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
    </>
  );
});
