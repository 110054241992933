import {PlusOutlined} from "@ant-design/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Modal, Select, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useState} from "react";

import styles from "./AllocateGateway.module.scss";
import * as Models from "../../../../core/models";
import {GatewayModule} from "../../../../core/modules/GatewayModule";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {isNil} from "../../../utils/FunctionUtils";
import {WebHelper} from "../../../utils/WebHelper";
import {Button, ButtonType} from "../../common/button/Button";

type AllocateGatewayProps = {
  department: Models.Department;
  onAllocate: () => void;
};

export const AllocateGateway: FunctionComponent<AllocateGatewayProps> = observer((props) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [gatewaysSelectVisible, setGatewaysSelectVisible] = useState(false);

  const [availableGateways, setAvailableGateways] = useState<Models.Gateway[]>([]);

  const [gatewayIdToAllocate, setGatewayIdToAllocate] = useState<string | null>(null);

  useEffect(() => {
    appStore.hideAllMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {isFetching: gatewaysQueryIsFetching, refetch: gatewaysQueryRefetch} = useQuery({
    queryKey: ["AllocateGateway-fetchGatewaysData"],
    queryFn: () => {
      return GatewayModule.gateways(
        {
          accessToken: authenticationStore.state.accessToken!,
          department_id: "null",
        },
        false
      );
    },
    enabled: false,
  });

  const fetchAvailableGateways = useCallback(async () => {
    props.onAllocate();
    const response = await gatewaysQueryRefetch();

    if (!response.data) return;

    if (response.data.success) {
      setAvailableGateways(response.data.gateways);
    } else {
      WebHelper.showErrorMessage(WebHelper.formatMessage("FetchAllocateGatewayData-errorMessage"), response.data.correlationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewaysQueryRefetch]);

  useEffect(() => {
    if (isNil(availableGateways.find((gateway) => gateway.id === gatewayIdToAllocate))) setGatewayIdToAllocate(null);
  }, [availableGateways, gatewayIdToAllocate]);

  const {mutate: allocateGatewayMutate, isPending: allocateGatewayIsLoading} = useMutation({
    mutationFn: (gatewayIdToAllocate: string) =>
      GatewayModule.allocateGatewayToDepartment({
        accessToken: authenticationStore.state.accessToken!,
        id: gatewayIdToAllocate,
        department_id: props.department.id,
      }),
    onSuccess: (response) => {
      if (response.success) {
        appStore.sendAnalyticTrack(SegmentKey.GatewayAllocated, {
          orgID: props.department.organization.id,
          siteID: props.department.site.id,
          departmentID: props.department.id,
          gatewayID: gatewayIdToAllocate,
        });
        setGatewaysSelectVisible(false);
        setGatewayIdToAllocate(null);
        props.onAllocate();
      } else {
        WebHelper.showErrorMessage(WebHelper.formatMessage("FetchAllocateGatewayData-errorMessage"), response.correlationId);
      }
    },
  });

  const handleSave = async () => {
    if (!gatewayIdToAllocate) return;
    allocateGatewayMutate(gatewayIdToAllocate);
  };

  const handleCancel = () => {
    if (allocateGatewayIsLoading) return;

    setGatewaysSelectVisible(false);
    setGatewayIdToAllocate(null);
  };

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={() => setGatewaysSelectVisible(true)}
        type={ButtonType.Primary}
        shape="round"
        data-cy="allocateGatewayButton">
        {WebHelper.formatMessage("AllocateGateway-allocateGatewayButton")}
      </Button>
      <Modal
        open={gatewaysSelectVisible}
        title={WebHelper.formatMessage("AllocateGateway-allocateGatewayModalTitle")}
        onCancel={handleCancel}
        footer={
          <Space>
            <Button shape="round" onClick={handleCancel}>
              {WebHelper.formatMessage("Common-cancel")}
            </Button>
            <Button
              danger
              type={ButtonType.Primary}
              shape="round"
              loading={allocateGatewayIsLoading}
              disabled={allocateGatewayIsLoading || !gatewayIdToAllocate}
              onClick={handleSave}>
              {WebHelper.formatMessage("AllocateGateway-allocate")}
            </Button>
          </Space>
        }>
        <Space className={styles.allocateGateway}>
          <Space className={styles.detailSpace}>
            <div
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(
                  WebHelper.formatMessage("AllocateGateway-allocateGatewayModalMessage", {
                    departmentName: props.department.name,
                  })
                ),
              }}
            />
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AllocateGateway-gatewaySerialNumberLabel")}</Typography.Text>
            <Select
              className={styles.select}
              options={availableGateways.map(({id, serial_number}) => ({key: id, label: serial_number, value: id}))}
              optionFilterProp="label"
              optionLabelProp="label"
              onSelect={setGatewayIdToAllocate}
              onDropdownVisibleChange={(open) => {
                if (open) {
                  fetchAvailableGateways();
                }
              }}
              dropdownRender={(node) => (gatewaysQueryIsFetching ? WebHelper.LoadingSpin : node)}
              showSearch
              loading={gatewaysQueryIsFetching}
              disabled={allocateGatewayIsLoading}
              value={gatewayIdToAllocate}
            />
          </Space>
        </Space>
      </Modal>
    </>
  );
});
