import {useMutation, useQuery} from "@tanstack/react-query";
import {Select, Modal, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo, useState, useEffect} from "react";

import styles from "./WorkerDeviceAssignmentModal.module.scss";
import * as Models from "../../../core/models";
import {DeviceModule} from "../../../core/modules/DeviceModule";
import {WorkerModule} from "../../../core/modules/WorkerModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {isNil} from "../../utils/FunctionUtils";
import {SegmentEntryPoint, SegmentResourceType} from "../../utils/SegmentHelper";
import {WebHelper} from "../../utils/WebHelper";
import {DeviceNoWorker} from "../entity-details/workers-tab-content/device-no-worker/DeviceNoWorker";

export type WorkerDeviceAssignmentModalProps = {
  worker: Models.Worker;
  onClose: () => void;
  open: boolean;
  onUpdate: (worker?: Models.Worker) => void;
};

export const WorkerDeviceAssignmentModal: FunctionComponent<WorkerDeviceAssignmentModalProps> = observer(
  ({worker, open, onClose, onUpdate}) => {
    const authenticationStore = AuthenticationDataStore.getInstance();
    const appStore = AppStore.getInstance();

    const [selectedDeviceIdValue, setSelectedDeviceIdValue] = useState<string>("");
    const [showDeviceNoWorker, setShowDeviceNoWorker] = useState(false);
    const [previousWorker, setPreviousWorker] = useState<Models.Worker>();

    useEffect(() => {
      setSelectedDeviceIdValue(worker.assigned_device?.id ?? "");
    }, [worker]);

    const changeWorkerDeviceMutation = useMutation({
      mutationFn: () =>
        WorkerModule.assignDeviceToWorker({
          accessToken: authenticationStore.state.accessToken!,
          worker_id: worker.id,
          assignable: worker.assignable,
          device_id: selectedDeviceIdValue === "" ? undefined : selectedDeviceIdValue,
        }),
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerDeviceAssignmentModal-changeDeviceError"), response.correlationId);
          return;
        }
        appStore.showMessage(WebHelper.formatMessage("WorkerDeviceAssignmentModal-updateSuccessMessage"), "success");
        appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceAssignment, {
          departmentID: response.worker.department.id,
          orgID: response.worker.organization?.id,
          siteID: response.worker.site?.id,
          resourceType: SegmentResourceType.WORKER,
          resourceID: response.worker.id,
          endAssigned: !!response.worker.assigned_device,
          startAssigned: !!worker.assigned_device,
          entryPoint: SegmentEntryPoint.EDIT_WORKER_MODAL,
        });

        if (
          worker.assigned_device &&
          (isNil(response.worker.assigned_device) ||
            (response.worker.assigned_device && response.worker.assigned_device.id !== worker.assigned_device.id))
        ) {
          setPreviousWorker(worker);
          setShowDeviceNoWorker(true);
        }
        onUpdate(response.worker);
        onClose();
      },
    });

    const {
      isFetching: devicesQueryIsFetching,
      data: devicesQueryData,
      refetch: devicesQueryRefetch,
    } = useQuery({
      queryKey: ["WorkerEditModal-fetchDevicesData"],
      queryFn: () =>
        DeviceModule.devicesShort({
          accessToken: authenticationStore.state.accessToken!,
          department_id: worker.department.id,
        }),
    });

    const assignWorkerToDeviceMutation = useMutation({
      mutationFn: (deviceId: string) =>
        DeviceModule.assignWorkerToDevice({
          accessToken: authenticationStore.state.accessToken!,
          device_id: deviceId,
          worker_id: worker.id,
          assignable: true,
        }),
    });

    const availableDevices = useMemo(() => {
      return devicesQueryData?.devices?.filter(
        (device) =>
          (device.assignable && isNil(device.assigned_worker?.id) && !device.archived_at) || worker.assigned_device?.id === device.id
      );
    }, [devicesQueryData?.devices, worker.assigned_device?.id]);

    const assignedDeviceItemOptions = useMemo(() => {
      if (!availableDevices) return [];
      const options = [
        getSelectOption("", WebHelper.formatMessage("WorkerDetail-noneOption")),
        ...availableDevices
          .filter((device) => device.id !== selectedDeviceIdValue)
          .map(({id, device_tag}) => getSelectOption(id, device_tag)),
      ];
      const selectedDevice = availableDevices.find((device) => device.id === selectedDeviceIdValue);
      if (selectedDevice) {
        options.unshift(getSelectOption(selectedDevice.id, selectedDevice.device_tag));
      }
      return options;
    }, [availableDevices, selectedDeviceIdValue]);

    return (
      <>
        <Modal
          centered
          open={open}
          destroyOnClose
          title={WebHelper.formatMessage("WorkerDetail-deviceAssignment")}
          cancelText={WebHelper.formatMessage("Common-cancel")}
          onCancel={onClose}
          okText={WebHelper.formatMessage("Common-save")}
          cancelButtonProps={{shape: "round", disabled: changeWorkerDeviceMutation.isPending}}
          okButtonProps={{
            shape: "round",
            disabled: selectedDeviceIdValue === worker.assigned_device?.id,
            loading: changeWorkerDeviceMutation.isPending,
          }}
          onOk={() => changeWorkerDeviceMutation.mutate()}>
          <Space className={styles.wrapper}>
            <div
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(
                  WebHelper.formatMessage("WorkerDeviceAssignmentModal-subtitle", {workerName: worker.displayName})
                ),
              }}
            />
            <div className={styles.selectWrapper}>
              <Typography.Text className={styles.selectLabel}>
                {WebHelper.formatMessage("WorkerDeviceAssignmentModal-device")}
              </Typography.Text>
              <Select
                value={selectedDeviceIdValue}
                disabled={assignWorkerToDeviceMutation.isPending}
                className={styles.optionSelector}
                optionFilterProp="label"
                optionLabelProp="label"
                onChange={(deviceId) => setSelectedDeviceIdValue(deviceId)}
                onDropdownVisibleChange={(open) => {
                  if (open) devicesQueryRefetch();
                }}
                showSearch
                loading={devicesQueryIsFetching}
                dropdownRender={(node) => (devicesQueryIsFetching ? WebHelper.LoadingSpin : node)}
                placeholder={WebHelper.formatMessage("WorkerNoDevice-assignNewDevice-placeholder")}
                onClick={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                options={assignedDeviceItemOptions}
              />
            </div>
          </Space>
        </Modal>
        {previousWorker?.assigned_device && (
          <DeviceNoWorker
            entryPoint={SegmentEntryPoint.EDIT_WORKER_MODAL}
            deviceId={previousWorker.assigned_device.id}
            deviceTag={previousWorker.assigned_device.device_tag}
            departmentId={previousWorker.department.id}
            onOk={() => {
              onUpdate();
              setShowDeviceNoWorker(false);
            }}
            open={showDeviceNoWorker}
            onClose={() => setShowDeviceNoWorker(false)}
          />
        )}
      </>
    );
  }
);

function getSelectOption(id: string | undefined, name: string) {
  return {key: id, label: name, value: id};
}
