import {QuestionOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Input, Progress, Space, Tooltip, Typography} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {addDays, differenceInWeeks, format} from "date-fns";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useMemo, useState} from "react";

import targetIcon from "../../../../../../assets/images/common/Target_Icon.svg";
import targetIconBlock from "../../../../../../assets/images/common/Target_Icon_Block.svg";
import targetIconDown from "../../../../../../assets/images/common/Target_Icon_Down.svg";
import targetIconUnset from "../../../../../../assets/images/common/Target_Icon_Unset.svg";
import targetIconUp from "../../../../../../assets/images/common/Target_Icon_Up.svg";
import * as Models from "../../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../../core/stores/AuthenticationDataStore";
import useUserPreferences from "../../../../../hooks/useUserPreferences";
import {AppStore, SegmentKey} from "../../../../../stores/AppStore";
import {isNil} from "../../../../../utils/FunctionUtils";
import {mapAssignedDeviceToColumn} from "../../../../../utils/TableUtils";
import {WebHelper} from "../../../../../utils/WebHelper";
import {ChangeWorkerStatusButton} from "../../../../change-worker-status-button/ChangeWorkerStatusButton";
import {LinkButton} from "../../../../common/link-button/LinkButton";
import {Table} from "../../../../common/table/Table";
import {EditableDevice} from "../../../workers-tab-content/editable-device/EditableDevice";
import styles from "../DashboardWorkersTable.module.scss";

const constants = require("assets/stylesheets/constants");

const defaultSettings: Models.DashboardWorkersTableSettings = {
  pageSize: 10,
  sortColumn: "fullName",
  sortOrder: "ascend",
};

export type ActiveWorkersTableProps = {
  department: Models.Department;
  workers: Models.AnalyticsDashboardWorker[];
  loading: boolean;
  onOpenChangeWorkerStatusModal: (worker: Models.WorkerShort | Models.Worker) => void;
  onChangeDevice: (worker: Models.WorkerShort | Models.Worker, device?: Models.DeviceShort) => void;
  onWorkerSelected: (worker: Models.WorkerShort) => void;
};

export const ActiveWorkersTable: FunctionComponent<ActiveWorkersTableProps> = observer((props) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const flags = useFlags();

  const [workersFiltered, setWorkersFiltered] = useState<Models.AnalyticsDashboardWorker[]>();
  const [workersFilterText, setWorkersFilterText] = useState<string>();

  const showTarget = props.department.target_type !== Models.TargetType.None;

  const handleWorkerSearch = useCallback(() => {
    workersFilterText &&
      setWorkersFiltered(
        props.workers?.filter(
          (worker) =>
            worker.worker.displayName.toLowerCase().includes(workersFilterText.toLowerCase()) ||
            worker.worker.employee_id.toLowerCase().includes(workersFilterText.toLowerCase())
        )
      );
  }, [workersFilterText, props.workers]);

  const dashboardActiveWorkersTable: Models.DashboardWorkersTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.dashboardActiveWorkersTable ?? {})}),
    [userPreferences]
  );

  const handleResetWorkerSearch = () => {
    setWorkersFilterText(undefined);
    setWorkersFiltered(undefined);
  };

  const workerColumnSearchDropdown = (
    <div style={{padding: 8}}>
      <Input value={workersFilterText} onChange={(e) => setWorkersFilterText(e.target.value)} className={styles.searchInput} />
      <Space>
        <Button type="primary" onClick={handleWorkerSearch} icon={<SearchOutlined />} size="small">
          {WebHelper.formatMessage("Common-search")}
        </Button>
        <Button onClick={handleResetWorkerSearch} size="small">
          {WebHelper.formatMessage("Common-delete")}
        </Button>
      </Space>
    </div>
  );

  const {pageSize, sortColumn, sortOrder} = dashboardActiveWorkersTable;

  const workerColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-workerColumnName"),
    key: "worker",
    width: 130,
    sorter: (a, b) => a.worker.fullName.localeCompare(b.worker.fullName),
    sortOrder: sortColumn === "worker" ? sortOrder : undefined,
    filterIcon: <SearchOutlined className={!isNil(workersFiltered) ? styles.searchActive : ""} />,
    filterDropdown: workerColumnSearchDropdown,
    render: (_, record) => (
      <>
        {record.data_uploaded_today && (
          <Tooltip title={WebHelper.formatMessage("DashboardWorkersTable-workerActiveToday")}>
            <span className={`${styles.circleSolid} ${styles.connected}`} />
          </Tooltip>
        )}
        {record.worker.fullName.trimAll() !== "" && record.worker.employee_id ? (
          <LinkButton onClick={() => props.onWorkerSelected(record.worker)}>
            {record.worker.displayName} ({record.worker.employee_id})
          </LinkButton>
        ) : (
          <LinkButton onClick={() => props.onWorkerSelected(record.worker)}>{record.worker.displayName}</LinkButton>
        )}
      </>
    ),
  };

  const statusColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-statusColumnTitle"),
    key: "status",
    width: flags.target_utilization_hours && showTarget ? 60 : 100,
    sorter: (a, b) => (a.worker.assignable === b.worker.assignable ? 0 : a.worker.assignable ? -1 : 1),
    sortOrder: sortColumn === "status" ? sortOrder : undefined,
    render: (_, record) => (
      <ChangeWorkerStatusButton worker={record.worker} onOpenChangeWorkerStatusModal={props.onOpenChangeWorkerStatusModal} />
    ),
  };

  const assignedDeviceColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-assignedDeviceColumnTitle"),
    key: "assigned_device",
    width: 160,
    render: (_, record) =>
      authenticationStore.permissionLevel(Models.UserScope.Department, record.worker.department?.id) === Models.UserAccessLevel.Admin ||
      authenticationStore.permissionLevel(Models.UserScope.Department, record.worker.department?.id) === Models.UserAccessLevel.Manager ? (
        record.worker.assignable ? (
          <EditableDevice worker={record.worker} onSave={props.onChangeDevice} />
        ) : (
          <Tooltip title={WebHelper.formatMessage("DashboardWorkersTable-disabledDeviceColumnValue")}>
            <Typography.Text disabled>
              {mapAssignedDeviceToColumn(record.worker, WebHelper.formatMessage("DashboardWorkersTable-unassignedDeviceLabel"))}
            </Typography.Text>
          </Tooltip>
        )
      ) : (
        mapAssignedDeviceToColumn(record.worker, WebHelper.formatMessage("DashboardWorkersTable-unassignedDeviceLabel"))
      ),
    sorter: (a, b) =>
      (a.worker.assigned_device ? a.worker.assigned_device.device_tag : "--").localeCompare(
        b.worker.assigned_device ? b.worker.assigned_device.device_tag : "--"
      ), //nalabel format
    sortOrder: sortColumn === "assigned_device" ? sortOrder : undefined,
  };

  const weeksSinceFirstUseColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-weeksSinceFirstUseColumnTitle"),
    key: "first_session_start_at",
    sortOrder: sortColumn === "first_session_start_at" ? sortOrder : undefined,
    sorter: (a, b) =>
      differenceInWeeks(new Date(), a.worker.first_session_start_at ?? new Date()) -
      differenceInWeeks(new Date(), b.worker.first_session_start_at ?? new Date()),
    width: flags.target_utilization_hours && showTarget ? 80 : 130,
    render: (_, record) =>
      record.worker.first_session_start_at ? differenceInWeeks(new Date(), record.worker.first_session_start_at) : null,
  };

  const daysActiveLastWeekColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: (
      <div className={styles.columTitleSevenDaysWrapper}>
        {WebHelper.formatMessage("DashboardWorkersTable-daysActiveLast7DaysColumnTitle")}
        <Tooltip
          title={WebHelper.formatMessage("DashboardWorkersTable-7DaysColumnTooltip", {
            dateFrom: format(addDays(new Date(), -7), WebHelper.formatMessage("Common-dateFormatMonthDay")),
            dateTo: format(addDays(new Date(), -1), WebHelper.formatMessage("Common-dateFormatMonthDay")),
          })}
          placement="bottom">
          <QuestionOutlined />
        </Tooltip>
      </div>
    ),
    key: "days_active_last_week",
    render: (_, record) => {
      return flags.target_utilization_hours && showTarget ? (
        <Typography.Text className={styles.hoursText}>{record.days_active_last_7_days}</Typography.Text>
      ) : (
        <div className={styles.progressBarWrapper}>
          <Progress strokeColor={constants.colors.primaryColor} percent={record.days_active_last_7_days * 20} showInfo={false} />
          <Typography.Text className={styles.progressBarText}>{record.days_active_last_7_days}</Typography.Text>
        </div>
      );
    },
    sorter: (a, b) => a.days_active_last_7_days - b.days_active_last_7_days,
    sortOrder: sortColumn === "days_active_last_week" ? sortOrder : undefined,
    width: flags.target_utilization_hours && showTarget ? 100 : undefined,
  };

  const hoursActiveLastWeekColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: (
      <div className={styles.columTitleSevenDaysWrapper}>
        {WebHelper.formatMessage("DashboardWorkersTable-hoursLast7DaysColumnTitle")}
        <Tooltip
          title={
            <span>
              {WebHelper.formatMessage("DashboardWorkersTable-7HoursColumnTooltip", {
                dateFrom: format(addDays(new Date(), -7), WebHelper.formatMessage("Common-dateFormatMonthDay")),
                dateTo: format(addDays(new Date(), -1), WebHelper.formatMessage("Common-dateFormatMonthDay")),
              })}
              {flags.target_utilization_hours && showTarget && (
                <>
                  <br />
                  {WebHelper.formatMessage("DashboardWorkersTable-7HoursColumnTargetTooltip")}
                </>
              )}
            </span>
          }
          placement="bottom">
          <QuestionOutlined />
        </Tooltip>
      </div>
    ),
    key: "hours_active_last_week",
    sorter: (a, b) => parseFloat(a.hours_active_last_7_days) - parseFloat(b.hours_active_last_7_days),
    sortOrder: sortColumn === "hours_active_last_week" ? sortOrder : undefined,
    width: flags.target_utilization_hours && showTarget ? 200 : undefined,
    render: (_, record) => {
      const onTarget = record.worker.on_target;
      return flags.target_utilization_hours && showTarget ? (
        onTarget === Models.OnTarget.Exempt || onTarget === Models.OnTarget.Unenrolled || onTarget === Models.OnTarget.Unset ? (
          <Typography.Text className={styles.hoursTargetText}>{record.hours_active_last_7_days}</Typography.Text>
        ) : (
          <div className={styles.progressBarWrapper}>
            <>
              <Progress
                strokeColor={
                  onTarget === Models.OnTarget.Meets
                    ? constants.colors.progressBar.green
                    : onTarget === Models.OnTarget.Overused
                      ? constants.colors.progressBar.yellow
                      : constants.colors.progressBar.red
                }
                percent={record.worker.lower_bound ? (record.seconds_active_last_7_days / (record.worker.lower_bound * 3600)) * 100 : 0}
                showInfo={false}
              />
              <Typography.Text
                className={`${styles.progressBarTargetText}`}>{`${record.hours_active_last_7_days} / ${record.worker.lower_bound ?? 0}`}</Typography.Text>
            </>
          </div>
        )
      ) : (
        <Tooltip title={WebHelper.getDurationFormattedBySeconds(record.seconds_active_last_7_days)}>
          <Typography.Text className={styles.hoursText}>{record.hours_active_last_7_days}</Typography.Text>
        </Tooltip>
      );
    },
  };

  const dataUploadedTodayColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-dataUploadedTodayColumnTitle"),
    key: "data_uploaded_today",
    sorter: (a, b) => (a.data_uploaded_today === b.data_uploaded_today ? 0 : a.data_uploaded_today ? -1 : 1),
    sortOrder: sortColumn === "data_uploaded_today" ? sortOrder : undefined,
    render: (_, record) => {
      return (
        record.data_uploaded_today && (
          <Typography.Text className={styles.wrapper}>
            <span className={`${styles.circleSolid} ${styles.connected}`} />
          </Typography.Text>
        )
      );
    },
  };

  const order = [
    Models.OnTarget.Below,
    Models.OnTarget.Meets,
    Models.OnTarget.Overused,
    Models.OnTarget.Exempt,
    Models.OnTarget.Unset,
    Models.OnTarget.Unenrolled,
  ];

  const relativeToTargetColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-relativeToTargetColumnTitle"),
    key: "relative_to_target",
    width: 80,
    sorter: (a, b) => {
      const indexA = order.indexOf(a.worker.on_target);
      const indexB = order.indexOf(b.worker.on_target);
      return indexA - indexB;
    },
    sortOrder: sortColumn === "relative_to_target" ? sortOrder : undefined,
    render: (_, record) => {
      const onTarget = record.worker.on_target;
      const icon =
        onTarget === Models.OnTarget.Meets
          ? targetIcon
          : onTarget === Models.OnTarget.Overused
            ? targetIconUp
            : onTarget === Models.OnTarget.Below
              ? targetIconDown
              : onTarget === Models.OnTarget.Unset
                ? targetIconUnset
                : targetIconBlock;

      const tooltipText =
        onTarget === Models.OnTarget.Meets
          ? WebHelper.formatMessage("Common-meets")
          : onTarget === Models.OnTarget.Overused
            ? WebHelper.formatMessage("Common-overused")
            : onTarget === Models.OnTarget.Below
              ? WebHelper.formatMessage("Common-below")
              : onTarget === Models.OnTarget.Unset
                ? WebHelper.formatMessage("Common-unset")
                : WebHelper.formatMessage("Common-exempt");

      return (
        <Tooltip title={tooltipText}>
          <img src={icon} alt="" />
        </Tooltip>
      );
    },
  };

  const activeColumns: ColumnsType<Models.AnalyticsDashboardWorker> = [];

  activeColumns.push(
    workerColumn,
    assignedDeviceColumn,
    statusColumn,
    weeksSinceFirstUseColumn,
    daysActiveLastWeekColumn,
    hoursActiveLastWeekColumn
  );

  if (flags.target_utilization_hours && showTarget) activeColumns.push(relativeToTargetColumn);
  else activeColumns.push(dataUploadedTodayColumn);

  const handleActiveWorkersTableSettingsChange = (updatedTableSettings?: Partial<Models.DashboardWorkersTableSettings>) =>
    setUserPreferences({
      tableSettings: {
        dashboardActiveWorkersTable: {
          ...dashboardActiveWorkersTable,
          ...updatedTableSettings,
        },
      },
    });

  return (
    <Table
      rowsSingleColor
      columns={activeColumns}
      loading={props.loading}
      dataSource={workersFiltered ?? props.workers}
      onChange={(pagination, filters, sorter, extra) => {
        appStore.analytics.track(SegmentKey.WorkersTableSettingsChanged, {pagination, filters, sorter, action: extra.action});
        if (extra.action === "sort" && !Array.isArray(sorter)) {
          handleActiveWorkersTableSettingsChange({
            sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
            sortOrder: sorter.order,
          });
        }
      }}
      pagination={{
        showSizeChanger: true,
        pageSize: pageSize,
        onShowSizeChange: (_, size) =>
          handleActiveWorkersTableSettingsChange({
            pageSize: size,
          }),
      }}
    />
  );
});
