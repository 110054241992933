import {DownloadOutlined, InfoCircleFilled} from "@ant-design/icons";
import {Button, Switch, Typography} from "antd";
import devicePendingConfigIcon from "assets/images/common/Device_PendingConfig_Icon.svg";
import {format, formatInTimeZone} from "date-fns-tz";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {ButtonType} from "web/components/common/button/Button";

import styles from "./DeviceManagementStats.module.scss";
import * as Models from "../../../../../core/models";
import {WebHelper} from "../../../../utils/WebHelper";

export type DeviceManagementStatsProps = {
  selectedEntity?: string[] | null;
  activeTab: Models.DeviceManagementTabKeys;
  devices: Models.DeviceShort[];
  gateways: Models.Gateway[];
  includeArchived: boolean;
  onIncludeArchivedChange: () => void;
  latestVersionGateway?: string;
  loading?: boolean;
  disabled?: boolean;
};

export const DeviceManagementStats: FunctionComponent<DeviceManagementStatsProps> = observer(
  ({selectedEntity, activeTab, devices, gateways, includeArchived, onIncludeArchivedChange, latestVersionGateway, loading, disabled}) => {
    const flags = useFlags();

    const [totalDevices, setTotalDevices] = useState(devices.length);
    const [pendingConfigurationUpdates, setPendingConfigurationUpdates] = useState(0);
    const [connectedDevices, setConnectedDevices] = useState(0);
    const [disconnectedDevices, setDisconnectedDevices] = useState(0);
    const [neverSeenDevices, setNeverSeenDevices] = useState(0);

    const [totalGateways, setTotalGateways] = useState(gateways.length);
    const [upGateways, setUpGateways] = useState(0);
    const [downGateways, setDownGateways] = useState(0);
    const [intermittentGateways, setIntermittentGateways] = useState(0);
    const [neverSeenGateways, setNeverSeenGateways] = useState(0);

    useEffect(() => {
      setTotalDevices(devices.length);

      let pendingConfigurationUpdates = 0;
      let connectedDevices = 0;
      let disconnectedDevices = 0;
      let neverSeenDevices = 0;

      devices.forEach((device) => {
        pendingConfigurationUpdates = device.device_config_pending ? pendingConfigurationUpdates + 1 : pendingConfigurationUpdates;
        switch (device.status) {
          case Models.DeviceStatus.Connected:
            connectedDevices++;
            break;
          case Models.DeviceStatus.Disconnected:
            disconnectedDevices++;
            break;
          case Models.DeviceStatus.NeverSeen:
            neverSeenDevices++;
            break;
          default:
            break;
        }
      });

      setPendingConfigurationUpdates(pendingConfigurationUpdates);
      setConnectedDevices(connectedDevices);
      setDisconnectedDevices(disconnectedDevices);
      setNeverSeenDevices(neverSeenDevices);
    }, [devices]);

    useEffect(() => {
      setTotalGateways(gateways.length);

      let upGateways = 0;
      let downGateways = 0;
      let intermittentGateways = 0;
      let neverSeenGateways = 0;

      gateways.forEach((gateway) => {
        switch (gateway.status) {
          case Models.GatewayStatus.Up:
            upGateways++;
            break;
          case Models.GatewayStatus.Down:
            downGateways++;
            break;
          case Models.GatewayStatus.Intermittent:
            intermittentGateways++;
            break;
          case Models.GatewayStatus.NeverSeen:
            neverSeenGateways++;
            break;
        }
      });

      setUpGateways(upGateways);
      setDownGateways(downGateways);
      setIntermittentGateways(intermittentGateways);
      setNeverSeenGateways(neverSeenGateways);
    }, [gateways]);

    const handleDownloadData = useCallback(() => {
      const headers =
        activeTab === Models.DeviceManagementTabKeys.DeviceConfig
          ? includeArchived
            ? [
                [
                  WebHelper.formatMessage("DeviceManagementStats-connectedColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementStats-systemSerialNumberColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-hardwareRevColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-firmwareRevColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-button1ColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-button2ColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-button3ColumnTitle"),
                  flags.device_config_modification
                    ? WebHelper.formatMessage("DeviceManagementDevicesTable-updateConfigColumnTitle")
                    : WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-archivedColumnTitle"),
                ],
              ]
            : [
                [
                  WebHelper.formatMessage("DeviceManagementStats-connectedColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementStats-systemSerialNumberColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-hardwareRevColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-firmwareRevColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-button1ColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-button2ColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementDevicesTable-button3ColumnTitle"),
                  flags.device_config_modification
                    ? WebHelper.formatMessage("DeviceManagementDevicesTable-updateConfigColumnTitle")
                    : WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate"),
                ],
              ]
          : flags.gateway_software_updates
            ? [
                [
                  WebHelper.formatMessage("DeviceManagementStats-serialNumberColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementStats-connectedColumnTitle"),
                  flags.gateway_software_updates
                    ? WebHelper.formatMessage("DeviceManagementStats-currentSoftwareColumnTitle")
                    : WebHelper.formatMessage("DeviceManagementStats-firmwareColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementStats-updateStatusColumnTitle"),
                ],
              ]
            : [
                [
                  WebHelper.formatMessage("DeviceManagementStats-serialNumberColumnTitle"),
                  WebHelper.formatMessage("DeviceManagementStats-connectedColumnTitle"),
                  flags.gateway_software_updates
                    ? WebHelper.formatMessage("DeviceManagementStats-currentSoftwareColumnTitle")
                    : WebHelper.formatMessage("DeviceManagementStats-firmwareColumnTitle"),
                ],
              ];

      const dataRows =
        activeTab === Models.DeviceManagementTabKeys.DeviceConfig
          ? devices.map((device) =>
              includeArchived
                ? [
                    device.status === Models.DeviceStatus.Connected
                      ? WebHelper.formatMessage("DeviceManagementStats-connected")
                      : device.status === Models.DeviceStatus.Disconnected
                        ? device.most_recent_gateway_event_at
                          ? formatInTimeZone(
                              device.most_recent_gateway_event_at,
                              device.site_tz_location,
                              WebHelper.getFormatStringByDate(device.most_recent_gateway_event_at)
                            ).replaceAll(",", " -")
                          : "_"
                        : WebHelper.formatMessage("DeviceManagementStats-neverSeen"),
                    device.system_serial_number,
                    device.item_number_revision,
                    device.firmware_version,
                    device.button1_name
                      ? WebHelper.getControllerSettingsName(device.button1_name)
                      : WebHelper.formatMessage("Common-unassignedLabel"),
                    device.button2_name
                      ? WebHelper.getControllerSettingsName(device.button2_name)
                      : WebHelper.formatMessage("Common-unassignedLabel"),
                    device.button3_name
                      ? WebHelper.getControllerSettingsName(device.button3_name)
                      : WebHelper.formatMessage("Common-unassignedLabel"),
                    device.device_config_pending
                      ? WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate")
                      : WebHelper.formatMessage("DeviceManagementDevicesTable-updateConfig"),
                    device.archived_at ? WebHelper.formatMessage("Common-yes") : "_",
                  ]
                : [
                    device.status === Models.DeviceStatus.Connected
                      ? WebHelper.formatMessage("ConnectedColumnRender-connected")
                      : device.status === Models.DeviceStatus.Disconnected
                        ? device.most_recent_gateway_event_at
                          ? formatInTimeZone(
                              device.most_recent_gateway_event_at,
                              device.site_tz_location,
                              WebHelper.getFormatStringByDate(device.most_recent_gateway_event_at)
                            ).replaceAll(",", " -")
                          : "_"
                        : WebHelper.formatMessage("ConnectedColumnRender-neverSeen"),
                    device.system_serial_number,
                    device.item_number_revision,
                    device.firmware_version ?? WebHelper.formatMessage("Device-formattedConfigFirmwareVersionUnknown"),
                    device.button1_name
                      ? WebHelper.getControllerSettingsName(device.button1_name)
                      : WebHelper.formatMessage("Common-unassignedLabel"),
                    device.button2_name
                      ? WebHelper.getControllerSettingsName(device.button2_name)
                      : WebHelper.formatMessage("Common-unassignedLabel"),
                    device.button3_name
                      ? WebHelper.getControllerSettingsName(device.button3_name)
                      : WebHelper.formatMessage("Common-unassignedLabel"),
                    device.device_config_pending
                      ? WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate")
                      : WebHelper.formatMessage("DeviceManagementDevicesTable-updateConfig"),
                  ]
            )
          : gateways.map((gateway) =>
              flags.gateway_software_updates
                ? [
                    gateway.serial_number ?? "_",
                    gateway.status === Models.GatewayStatus.NeverSeen
                      ? WebHelper.formatMessage("DeviceManagementStats-neverSeenColumn")
                      : `${WebHelper.formatMessage(`DeviceManagementStats-${gateway.status}Column`)} - ${
                          gateway.most_recent_gateway_event_at
                            ? gateway.site
                              ? formatInTimeZone(
                                  gateway.most_recent_gateway_event_at,
                                  gateway.site.tz_location,
                                  WebHelper.getFormatStringByDate(gateway.most_recent_gateway_event_at)
                                ).replaceAll(",", " -")
                              : format(
                                  gateway.most_recent_gateway_event_at,
                                  WebHelper.getFormatStringByDate(gateway.most_recent_gateway_event_at)
                                ).replaceAll(",", " -")
                            : "_"
                        }`,
                    gateway.current_firmware_version ?? "_",
                    gateway.update_status === Models.UpdateStatus.MatchTarget
                      ? WebHelper.formatMessage("DeviceManagementStats-matchTarget")
                      : gateway.update_status === Models.UpdateStatus.ScheduledUpdatePending
                        ? WebHelper.formatMessage("DeviceManagementStats-scheduleUpdatePending")
                        : gateway.update_status === Models.UpdateStatus.ForcedUpdatePending
                          ? WebHelper.formatMessage("DeviceManagementStats-forceUpdatePending")
                          : WebHelper.formatMessage("DeviceManagementStats-failed"),
                  ]
                : [
                    gateway.serial_number ?? "_",
                    gateway.status === Models.GatewayStatus.NeverSeen
                      ? WebHelper.formatMessage("DeviceManagementStats-neverSeenColumn")
                      : `${WebHelper.formatMessage(`DeviceManagementStats-${gateway.status}Column`)} - ${
                          gateway.most_recent_gateway_event_at
                            ? gateway.site
                              ? formatInTimeZone(
                                  gateway.most_recent_gateway_event_at,
                                  gateway.site.tz_location,
                                  WebHelper.getFormatStringByDate(gateway.most_recent_gateway_event_at)
                                ).replaceAll(",", " -")
                              : format(
                                  gateway.most_recent_gateway_event_at,
                                  WebHelper.getFormatStringByDate(gateway.most_recent_gateway_event_at)
                                ).replaceAll(",", " -")
                            : "_"
                        }`,
                    gateway.current_firmware_version ?? "_",
                  ]
            );

      const csvContent = headers
        .concat(dataRows)
        .map((row) => row.join(","))
        .join("\n");
      const entityName = selectedEntity ? selectedEntity.join("-") : "";
      WebHelper.downloadFile(
        csvContent,
        `${activeTab === Models.DeviceManagementTabKeys.DeviceConfig ? WebHelper.formatMessage("DeviceManagementStats-csvDevices", {entityName}) : WebHelper.formatMessage("DeviceManagementStats-csvGateways", {entityName})}`,
        "text/csv;charset=utf-8;"
      );
    }, [devices, selectedEntity, gateways, activeTab, flags.device_config_modification, flags.gateway_software_updates, includeArchived]);

    return (
      <div className={styles.wrapper}>
        {activeTab === Models.DeviceManagementTabKeys.DeviceConfig && (
          <>
            <div className={styles.statsItem}>
              <div className={styles.statsSubItem}>
                <div>
                  <Typography.Text className={styles.label} strong>
                    {WebHelper.formatMessage("DeviceManagementStats-totalDevicesLabel")}
                  </Typography.Text>
                  {totalDevices}
                </div>
                <div>
                  <Typography.Text className={styles.label}>
                    {WebHelper.formatMessage("DeviceManagementStats-includeArchivedLabel")}
                  </Typography.Text>
                  <Switch checked={includeArchived} onChange={onIncludeArchivedChange} />
                </div>
              </div>
              <div className={styles.statsSubItem}>
                <img
                  className={styles.pendingUpdateIcon}
                  src={devicePendingConfigIcon}
                  alt={WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate")}
                />
                <div>
                  <Typography.Text className={styles.label} strong>
                    {WebHelper.formatMessage("DeviceManagementStats-pendingConfigurationUpdatesLabel")}
                  </Typography.Text>
                  {pendingConfigurationUpdates}
                </div>
              </div>
            </div>
            <div className={styles.statsItem}>
              <div>
                <Typography.Text className={styles.label} strong>
                  {WebHelper.formatMessage("DeviceManagementStats-deviceConnectionStatusLabel")}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.connected}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-connected", {connectedDevices})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.disconnected}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-disconnected", {disconnectedDevices})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.neverSeen}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-neverSeen", {neverSeenDevices})}
                </Typography.Text>
              </div>
            </div>
          </>
        )}
        {activeTab === Models.DeviceManagementTabKeys.Gateways && (
          <>
            <div className={styles.statsItem}>
              <div>
                <Typography.Text className={styles.label} strong>
                  {WebHelper.formatMessage("DeviceManagementStats-totalGatewaysLabel")}
                </Typography.Text>
                {totalGateways}
              </div>
            </div>
            <div className={styles.statsItem}>
              <div>
                <Typography.Text className={styles.label} strong>
                  {WebHelper.formatMessage("DeviceManagementStats-deviceConnectionStatusLabel")}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.up}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-up", {upGateways})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.down}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-down", {downGateways})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.intermittent}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-intermittent", {intermittentGateways})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.neverSeen}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-neverSeen", {neverSeenDevices: neverSeenGateways})}
                </Typography.Text>
              </div>
            </div>
            {flags.gateway_software_updates && (
              <div className={styles.latestSoftware}>
                <InfoCircleFilled style={{marginRight: "6px"}} />
                <Typography.Text className={styles.label}>
                  {latestVersionGateway && WebHelper.formatMessage("DeviceManagementStats-latestSoftware", {latestVersionGateway})}
                </Typography.Text>
              </div>
            )}
          </>
        )}
        <Button
          type={ButtonType.Default}
          icon={<DownloadOutlined />}
          shape="round"
          style={{borderColor: "#E2E8F0", marginLeft: "6px"}}
          onClick={handleDownloadData}
          loading={(!disabled && loading) ?? false}
          disabled={disabled ?? false}>
          {WebHelper.formatMessage("Common-exportToCsv")}
        </Button>
      </div>
    );
  }
);
