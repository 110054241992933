import * as Types from "./SiteAPIClient.types";
import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";

export class SiteAPIClient extends BaseAPIClient {
  static async sites(request: Types.SitesRequest, options: RequestOptions = {}): Promise<Types.SitesResponse> {
    const {
      accessToken: {token},
      organization_id,
    } = request;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `sites/`,
      headers: {token},
      request,
      options,
      params: {
        ...(organization_id ? {organization_id} : {}),
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          sites: data.map((site: Models.Site) => Models.Site.fromJSON(site)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async site(request: Types.SiteRequest, options: RequestOptions = {}): Promise<Types.SiteResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `sites/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          site: Models.Site.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async createSite(request: Types.CreateSiteRequest, options: RequestOptions = {}): Promise<Types.CreateSiteResponse> {
    const {name, address, tz_location, organization_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `sites/create`,
      headers: {token},
      request,
      options,
      data: {name, address, tz_location, organization_id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          site: Models.Site.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async deleteSite(request: Types.DeleteSiteRequest, options: RequestOptions = {}): Promise<Types.DeleteSiteResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `delete`,
      url: `sites/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      return {
        success: true,
      };
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateSite(request: Types.UpdateSiteRequest, options: RequestOptions = {}): Promise<Types.UpdateSiteResponse> {
    const {id, name, address, tz_location, organization_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `sites/${id}`,
      headers: {token},
      request,
      options,
      data: {name, address, tz_location, organization_id, id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          site: Models.Site.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async updateSiteConfiguration(
    request: Types.UpdateSiteConfigurationRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateSiteConfigurationResponse> {
    const {
      id,
      site_configuration_id,
      weekday_report,
      time_report,
      active_list_report_included,
      leaderboard_report_included,
      weight_offloaded_report_included,
      safelift_score_report_included,
      override_groups,
      report_groups,
      accessToken,
    } = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `sites/${id}/config`,
      headers: {token},
      request,
      options,
      data: {
        site_configuration_id,
        weekday_report,
        time_report,
        active_list_report_included,
        leaderboard_report_included,
        weight_offloaded_report_included,
        safelift_score_report_included,
        override_groups,
        report_groups,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }
}
