import {DepartmentAPIClient} from "../apiclient/department/DepartmentAPIClient";
import * as DepartmentTypes from "../apiclient/department/DepartmentAPIClient.types";

async function departments(request: DepartmentTypes.DepartmentsRequest): Promise<DepartmentTypes.DepartmentsResponse> {
  return await DepartmentAPIClient.departments(request);
}

async function department(request: DepartmentTypes.DepartmentRequest): Promise<DepartmentTypes.DepartmentResponse> {
  return await DepartmentAPIClient.department(request);
}

async function createDepartment(request: DepartmentTypes.CreateDepartmentRequest): Promise<DepartmentTypes.CreateDepartmentResponse> {
  return await DepartmentAPIClient.createDepartment(request);
}

async function deleteDepartment(request: DepartmentTypes.DeleteDepartmentRequest): Promise<DepartmentTypes.DeleteDepartmentResponse> {
  return await DepartmentAPIClient.deleteDepartment(request);
}

async function updateDepartment(request: DepartmentTypes.UpdateDepartmentRequest): Promise<DepartmentTypes.UpdateDepartmentResponse> {
  return await DepartmentAPIClient.updateDepartment(request);
}

async function updateDepartmentTargetUtilization(
  request: DepartmentTypes.UpdateDepartmentTargetUtilizationRequest
): Promise<DepartmentTypes.UpdateDepartmentTargetUtilizationResponse> {
  return await DepartmentAPIClient.updateDepartmentTargetUtilization(request);
}

async function allWorkersReport(request: DepartmentTypes.AllWorkersReportRequest): Promise<DepartmentTypes.AllWorkersReportResponse> {
  return await DepartmentAPIClient.allWorkersReport(request);
}

async function demoReport(request: DepartmentTypes.DemoReportRequest): Promise<DepartmentTypes.DemoReportResponse> {
  return await DepartmentAPIClient.demoReport(request);
}

async function departmentsDropdown(
  request: DepartmentTypes.DepartmentsDropdownRequest
): Promise<DepartmentTypes.DepartmentsDeviceDropdownResponse> {
  return await DepartmentAPIClient.departmentsDropdown(request);
}

export const DepartmentModule = {
  departments,
  department,
  createDepartment,
  deleteDepartment,
  updateDepartment,
  updateDepartmentTargetUtilization,
  allWorkersReport,
  demoReport,
  departmentsDropdown,
};
