import {UserAPIClient} from "../apiclient/user/UserAPIClient";
import * as UserTypes from "../apiclient/user/UserAPIClient.types";

async function users(request: UserTypes.UsersRequest): Promise<UserTypes.UsersResponse> {
  return await UserAPIClient.users(request);
}

async function changeUserPermission(request: UserTypes.ChangeUserPermissionRequest): Promise<UserTypes.ChangeUserPermissionResponse> {
  return await UserAPIClient.changeUserPermission(request);
}

async function changeUserPermissionMultiple(
  request: UserTypes.ChangeUserPermissionMultipleRequest
): Promise<UserTypes.ChangeUserPermissionResponse> {
  return await UserAPIClient.changeUserPermissionMultiple(request);
}

async function changeUserActive(request: UserTypes.ChangeUserActiveRequest): Promise<UserTypes.ChangeUserActiveResponse> {
  return await UserAPIClient.changeUserActive(request);
}

async function getNotifications(request: UserTypes.NotifcationsRequest): Promise<UserTypes.NotificationsResponse> {
  return await UserAPIClient.getNotifications(request);
}

async function acknowledgeNotifications(
  request: UserTypes.AcknowledgeNotifcationsRequest
): Promise<UserTypes.AcknowledgeNotificationsResponse> {
  return await UserAPIClient.acknowledgeNotifications(request);
}

export const UserModule = {
  users,
  changeUserPermission,
  changeUserPermissionMultiple,
  changeUserActive,
  getNotifications,
  acknowledgeNotifications,
};
