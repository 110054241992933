import {Col, Row, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, ReactNode} from "react";

import styles from "./RowItem.module.scss";

export const RowItem: FunctionComponent<{label: string; value: string | number | ReactNode}> = observer(({label, value}) => {
  return (
    <Row>
      <Col span={12} className={styles.labelCol}>
        <Typography.Text>{label}</Typography.Text>
      </Col>
      <Col span={12}>{typeof value === "number" || typeof value === "string" ? <Typography.Text>{value}</Typography.Text> : value}</Col>
    </Row>
  );
});
