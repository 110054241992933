import {CloseCircleFilled, DownloadOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Space, Typography, Button, Card} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";

import styles from "./SessionLog.module.scss";
import * as Models from "../../../core/models";
import {SessionModule} from "../../../core/modules/SessionModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {Loading} from "../loading/Loading";

export type SessionLogProps = {
  device: Models.Device;
  session: Models.SessionShort;
};

export const SessionLog: FunctionComponent<SessionLogProps> = ({session, device}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [isError, setIsError] = useState<boolean | null>(null);
  const [log, setLog] = useState<any>();

  const sessionsQuery = useQuery({
    queryKey: ["sessions", session.id],
    queryFn: () => SessionModule.sessionLog({accessToken: authenticationStore.state.accessToken!, session_id: session.id}),
  });

  useEffect(() => {
    if (!sessionsQuery.data) return;

    if (!sessionsQuery.data.success) {
      setIsError(true);
      WebHelper.showErrorMessage(WebHelper.formatMessage("SessionLog-fetchDataError"), sessionsQuery.data.correlationId);
      return;
    }

    setIsError(false);
    setLog(sessionsQuery.data.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsQuery.isSuccess]);

  useEffect(() => {
    appStore.hideAllMessages();
    appStore.sendAnalyticTrack(SegmentKey.SessionDetailOpened, {sessionID: session.id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadLog = () => {
    WebHelper.downloadFile(JSON.stringify(log), `${session.id} ${WebHelper.formatMessage("SessionLog-log")}`, "application/json");
  };

  return (
    <>
      <Card>
        <Space size={8}>
          <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
            <Typography.Text>{WebHelper.formatMessage("SessionLog-systemSerialNumber")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("SessionLog-sessionID")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("SessionLog-startTime")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("SessionLog-worker")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("SessionLog-duration")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("SessionLog-entries")}</Typography.Text>
          </Space>
          <Space direction="vertical" size={16}>
            <Typography.Text>{device.system_serial_number}</Typography.Text>
            <Typography.Text>{session.id}</Typography.Text>
            <Typography.Text>
              {session.start_time ? WebHelper.formatDate(session.start_time, device.site?.tz_location) : null}
            </Typography.Text>
            <Typography.Text>{session.worker_id ? session.displayName : WebHelper.formatMessage("SessionLog-none")}</Typography.Text>
            <Typography.Text>{WebHelper.getDurationFormattedBySeconds(session.duration_sec)}</Typography.Text>
            <Typography.Text>{session.log_lines}</Typography.Text>
          </Space>
        </Space>
      </Card>
      <Card className={styles.sessionErrorsContainer}>
        <Space size={2}>
          <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
            <Typography.Text>{WebHelper.formatMessage("SessionLog-errors")}</Typography.Text>
          </Space>
          <Space direction="vertical" size={16}>
            <Typography.Text>{session.error_count}</Typography.Text>
          </Space>
        </Space>
        {session.errors_group && session.errors_group.session_errors.length > 0 && (
          <>
            {session.errors_group.session_errors.map((error, index) => (
              <div key={index}>
                {error.segments ? (
                  error.segments.map((segment, segIndex) => (
                    <Card key={segIndex} bordered={false} className={styles.errorCard}>
                      <Space size={8} align="start" className={styles.errorInfo}>
                        <Space direction="vertical" size={1}>
                          <Typography.Text className={styles.errorCount}>{segment.count}</Typography.Text>
                        </Space>
                        <Space direction="vertical" size={30}>
                          <Typography.Text>{`${error.code} - ${segment.name}`}</Typography.Text>
                        </Space>
                      </Space>
                    </Card>
                  ))
                ) : (
                  <Card key={index} bordered={false} className={styles.errorCard}>
                    <Space size={8} align="start" className={styles.errorInfo}>
                      <Space direction="vertical" size={1}>
                        <Typography.Text className={styles.errorCount}>{error.count}</Typography.Text>
                      </Space>
                      <Space direction="vertical" size={30}>
                        <Typography.Text>{error.code}</Typography.Text>
                      </Space>
                    </Space>
                  </Card>
                )}
              </div>
            ))}
          </>
        )}
      </Card>
      {isError === null ? (
        <Loading />
      ) : isError ? (
        <div className={styles.errorPlaceholder}>
          <CloseCircleFilled className={styles.errorIcon} />
          <Typography.Text type="danger">{WebHelper.formatMessage("SessionLog-fetchDataError")}</Typography.Text>
        </div>
      ) : (
        <pre className={styles.logWrapper}>
          <code>{JSON.stringify(log, null, 2)}</code>
        </pre>
      )}
      <div className={styles.downloadButtonWrapper}>
        <Button
          type="primary"
          htmlType="submit"
          loading={sessionsQuery.isPending}
          disabled={(!sessionsQuery.isPending && !log) || sessionsQuery.isPending}
          className={styles.downloadButton}
          icon={<DownloadOutlined />}
          onClick={downloadLog}>
          {WebHelper.formatMessage("SessionLog-download")}
        </Button>
      </div>
    </>
  );
};
