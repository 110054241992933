import {DownloadOutlined, SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Button, Drawer, Input, Space, Table as TableAntd, Tooltip, Typography} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {addDays, differenceInDays, startOfDay, format, subDays} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";

import styles from "./DashboardDailyUsageBreakdownSection.module.scss";
import * as Models from "../../../../../core/models";
import {AnalyticsModule} from "../../../../../core/modules/AnalyticsModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import useUserPreferences from "../../../../hooks/useUserPreferences";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {concat, isNil} from "../../../../utils/FunctionUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import {Collapse} from "../../../common/collapse/Collapse";
import {LinkButton} from "../../../common/link-button/LinkButton";
import {Table} from "../../../common/table/Table";
import {DatePicker} from "../../../date-picker/DatePicker";
import {WorkerDetail} from "../../../worker-detail/WorkerDetail";

const defaultSettings: Models.DashboardDailyUsageBreakdownTableSettings = {
  pageSize: 10,
  sortColumn: "worker",
  sortOrder: "ascend",
};

type DashboardUsageBreakdownSectionProps = {
  department: Models.Department;
};

export const DashboardDailyUsageBreakdownSection: FunctionComponent<DashboardUsageBreakdownSectionProps> = ({department}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [endDate, setEndDate] = useState<Date | null>(
    fromZonedTime(subDays(new Date(), 1), department.site.tz_location ?? WebHelper.getLocalTimezoneFromBrowser())
  );

  const [workers, setWorkers] = useState<Models.AnalyticsDashboardWorker[]>();
  const [visibleWorker, setVisibleWorker] = useState<Models.WorkerShort>();

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const [workersFiltered, setWorkersFiltered] = useState<Models.AnalyticsDashboardWorker[]>();
  const [workersFilterText, setWorkersFilterText] = useState<string>();

  const handleWorkerSearch = useCallback(() => {
    workersFilterText
      ? setWorkersFiltered(workers?.filter((worker) => worker.worker.displayName.toLowerCase().includes(workersFilterText.toLowerCase())))
      : setWorkersFiltered(undefined);

    appStore.analytics.track(SegmentKey.DashboardDailyUsageBreakdownTableSettingsChanged, {
      departmentID: department.id,
      siteID: department.site.id,
      orgID: department.organization.id,
      ...userPreferences.data.tableSettings?.dashboardDailyUsageBreakdownTable,
      action: "filter",
      filter: workersFilterText,
    });
  }, [workersFilterText, workers, appStore.analytics, department, userPreferences.data.tableSettings?.dashboardDailyUsageBreakdownTable]);

  const days = useMemo(() => {
    const lastDay = endDate ?? fromZonedTime(new Date(), department.site.tz_location ?? WebHelper.getLocalTimezoneFromBrowser());

    const endDateFormatted = format(lastDay, WebHelper.formatMessage("Common-dateFormatMonthDayYear"));

    const days = [endDateFormatted];

    for (let i = 1; i < 7; i++) {
      days.unshift(format(addDays(lastDay, -i), WebHelper.formatMessage("Common-dateFormatMonthDayYear")));
    }

    return days;
  }, [department, endDate]);

  const dashboardDailyUsageBreakdownTable: Models.DashboardDailyUsageBreakdownTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.dashboardDailyUsageBreakdownTable ?? {})}),
    [userPreferences]
  );

  const {pageSize, sortColumn, sortOrder} = dashboardDailyUsageBreakdownTable;
  const analyticsDashboardWorkersQuery = useQuery({
    queryKey: ["DashboardDailyUsageBreakdownSection-fetchAnalyticsDashboardWorkersData", department.id, endDate],
    queryFn: () => {
      const end_time = fromZonedTime(startOfDay(addDays(endDate!, 1)), "UTC").toISOString();
      return AnalyticsModule.analyticsDashboardWorkerData({
        accessToken: authenticationStore.state.accessToken!,
        end_time,
        interval_type: Models.AnalyticsInterval.Day,
        interval_count: 7,
        resource_type: Models.EntityType.Department,
        resource_id: department.id,
      });
    },
    enabled: !!endDate,
  });
  useEffect(() => {
    if (!analyticsDashboardWorkersQuery.data) return;
    if (!analyticsDashboardWorkersQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("FetchDashboardDailyUsageBreakdownSectionWorkersData-errorMessage"),
        analyticsDashboardWorkersQuery.data.correlationId
      );
      return;
    }
    setWorkers(analyticsDashboardWorkersQuery.data.data.worker_data);
    if (workersFilterText)
      setWorkersFiltered(
        analyticsDashboardWorkersQuery.data.data.worker_data?.filter((worker) =>
          worker.worker.displayName.toLowerCase().includes(workersFilterText.toLowerCase())
        )
      );
    else setWorkersFiltered(undefined);

    // we don't want workersFilterText to be a dependency as this shouldn't be called when writing the filter.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsDashboardWorkersQuery]);

  const handleDrawerClose = () => {
    setVisibleWorker(undefined);
  };

  const handleDelete = () => {
    handleDrawerClose();
    analyticsDashboardWorkersQuery.refetch();
  };

  const handleResetWorkerSearch = () => {
    setWorkersFilterText(undefined);
    setWorkersFiltered(undefined);
  };

  const workerColumnSearchDropdown = (
    <div style={{padding: 8}}>
      <Input value={workersFilterText} onChange={(e) => setWorkersFilterText(e.target.value)} className={styles.searchInput} />
      <Space>
        <Button type="primary" onClick={handleWorkerSearch} icon={<SearchOutlined />} size="small">
          {WebHelper.formatMessage("Common-search")}
        </Button>
        <Button onClick={handleResetWorkerSearch} size="small">
          {WebHelper.formatMessage("Common-delete")}
        </Button>
      </Space>
    </div>
  );

  const workerColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-worker"),
    key: "worker",
    sorter: (a, b) => a.worker.displayName.localeCompare(b.worker.displayName),
    sortOrder: sortColumn === "worker" ? sortOrder : undefined,
    render: (_, record) => <LinkButton onClick={() => setVisibleWorker(record.worker)}>{record.worker.displayName}</LinkButton>,
    filterIcon: <SearchOutlined className={!isNil(workersFiltered) ? styles.searchActive : ""} />,
    filterDropdown: workerColumnSearchDropdown,
  };

  const weeklyTotalColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-weeklyTotal"),
    key: "weekly_total",
    sorter: (a, b) => a.seconds_active_last_7_days_including_today - b.seconds_active_last_7_days_including_today,
    sortOrder: sortColumn === "weekly_total" ? sortOrder : undefined,
    onCell: (_) => {
      return {align: "right"};
    },
    render: (_, record) =>
      record.seconds_active_last_7_days_including_today === 0 ? (
        "-"
      ) : (
        <Tooltip title={WebHelper.getDurationFormattedBySeconds(record.seconds_active_last_7_days_including_today)}>
          <Typography.Text className={styles.numberDisplay}>
            {getUsageHours(record.seconds_active_last_7_days_including_today)}
          </Typography.Text>
        </Tooltip>
      ),
  };

  const daysColumns: ColumnType<Models.AnalyticsDashboardWorker>[] = days.map((day, index) => {
    return {
      title: day,
      key: day,
      sorter: (a, b) => a.time_series_data[index].usage_sec - b.time_series_data[index].usage_sec,
      sortOrder: sortColumn === day ? sortOrder : undefined,
      onCell: (_) => {
        return {align: "right"};
      },
      render: (_, record) => {
        const usageInHours = getUsageHours(record.time_series_data[index].usage_sec);
        return record.time_series_data[index].usage_sec < 0.05 ? (
          "-"
        ) : (
          <Tooltip title={WebHelper.getDurationFormattedBySeconds(record.time_series_data[index].usage_sec)}>
            <Typography.Text className={styles.numberDisplay}>{usageInHours}</Typography.Text>
          </Tooltip>
        );
      },
    };
  });

  const columns: ColumnsType<Models.AnalyticsDashboardWorker> = [workerColumn, ...daysColumns, weeklyTotalColumn];

  const handleTableSettingsChange = (updatedTableSettings?: Partial<Models.DashboardDailyUsageBreakdownTableSettings>) =>
    setUserPreferences({
      tableSettings: {
        dashboardDailyUsageBreakdownTable: {
          ...dashboardDailyUsageBreakdownTable,
          ...updatedTableSettings,
        },
      },
    });

  const handleDownloadData = useCallback(() => {
    const daysFormatted = days.map((day) => day.replace(",", ""));

    appStore.sendAnalyticTrack(SegmentKey.DashboardDailyUsageBreakdownDownload, {
      departmentID: department.id,
      siteID: department.site.id,
      orgID: department.organization.id,
      endDate: endDate ? WebHelper.formatDateNoTime(endDate) : "",
      endDateWeekday: endDate ? format(endDate, "EEEE") : "",
    });

    const headers = [
      [
        WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-worker"),
        ...daysFormatted,
        WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-weeklyTotal"),
      ],
    ];

    const dataRows = workers
      ? workers.map((worker) => {
          return [
            worker.worker.displayName,
            ...[
              days.map((_, index) => {
                const usageInHours = getUsageHours(worker.time_series_data[index].usage_sec);
                return worker.time_series_data[index].usage_sec < 0.05 ? "-" : usageInHours;
              }),
            ],
            worker.seconds_active_last_7_days_including_today < 0.05
              ? "-"
              : getUsageHours(worker.seconds_active_last_7_days_including_today),
          ];
        })
      : [];

    const totalDailyUsage = getTotalDailyUsage(workers ?? []);

    if (dataRows.length > 0) {
      dataRows.push([
        WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-dailyTotalRowTitle"),
        ...[
          totalDailyUsage.map((_, index) => {
            const usageInSeconds = totalDailyUsage[index];
            return usageInSeconds < 0.05 ? "-" : getUsageHours(usageInSeconds);
          }),
        ],
      ]);
    }

    const csvContent = concat(headers, dataRows)
      .map((row) => row.join(","))
      .join("\n");

    WebHelper.downloadFile(csvContent, `DashboardData-DailyUsageBreakdown-${department.name}.csv`, "application/csv");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, department, workers]);

  const loading = analyticsDashboardWorkersQuery.isPending || analyticsDashboardWorkersQuery.isFetching;

  return (
    <Collapse
      header={
        <Typography.Title className={styles.panelHeaderText} level={5}>
          {WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-title")}
        </Typography.Title>
      }
      onChange={(keys) => {
        if (keys.includes("header_panel")) {
          appStore.sendAnalyticTrack(SegmentKey.DashboardDailyUsageBreakdownView, {
            departmentID: department.id,
            siteID: department.site.id,
            orgID: department.organization.id,
            endDate: endDate ? WebHelper.formatDateNoTime(endDate) : "",
            endDateWeekday: endDate ? format(endDate, "EEEE") : "",
          });
        }
      }}
      isOpen={false}>
      <Space className={styles.collapseContent} direction="vertical" size={16}>
        <div className={styles.header}>
          <Space size={4}>
            <Typography.Text>{WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-endDateLabel")}</Typography.Text>
            <DatePicker
              className={styles.endDatePicker}
              value={endDate}
              allowClear={false}
              dateRender={(current) => {
                const isLastSevenDays = endDate && differenceInDays(endDate, current) > 0 && differenceInDays(endDate, current) <= 6;

                return <div className={`ant-picker-cell-inner ${isLastSevenDays && styles.selectedDay}`}>{current.getDate()}</div>;
              }}
              onChange={(date) => {
                appStore.sendAnalyticTrack(SegmentKey.DashboardDailyUsageBreakdownChangeDate, {
                  departmentID: department.id,
                  siteID: department.site.id,
                  orgID: department.organization.id,
                  endDate: date ? WebHelper.formatDateNoTime(date) : "",
                  endDateWeekday: date ? format(date, "EEEE") : "",
                });
                setEndDate(date);
              }}
            />
          </Space>
          <Button
            type="primary"
            shape="round"
            className={styles.downloadButton}
            icon={<DownloadOutlined />}
            disabled={loading}
            onClick={handleDownloadData}>
            {WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-downloadCSV")}
          </Button>
        </div>
        <Table
          columns={columns}
          loading={loading}
          dataSource={workersFiltered ?? workers}
          onChange={(pagination, filters, sorter, extra) => {
            appStore.analytics.track(SegmentKey.DashboardDailyUsageBreakdownTableSettingsChanged, {
              departmentID: department.id,
              siteID: department.site.id,
              orgID: department.organization.id,
              pagination,
              filters: workersFilterText,
              sorter,
              action: extra.action,
            });
            if (extra.action === "sort" && !Array.isArray(sorter)) {
              handleTableSettingsChange({
                sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
                sortOrder: sorter.order,
              });
            }
          }}
          pagination={{
            showSizeChanger: true,
            pageSize: pageSize,
            onShowSizeChange: (_, size) =>
              handleTableSettingsChange({
                pageSize: size,
              }),
          }}
          summary={(pageData) => {
            const usageInDays = getTotalDailyUsage(pageData);

            return (
              <TableAntd.Summary.Row>
                <TableAntd.Summary.Cell index={0}>
                  <Typography.Text strong>
                    {WebHelper.formatMessage("DashboardDailyUsageBreakdownSection-dailyTotalRowTitle")}
                  </Typography.Text>
                </TableAntd.Summary.Cell>
                {usageInDays.map((usageInSeconds, index) => {
                  return (
                    <TableAntd.Summary.Cell key={index} index={index + 1}>
                      <div className={styles.cell}>
                        <Tooltip title={WebHelper.getDurationFormattedBySeconds(usageInSeconds)}>
                          {usageInSeconds < 0.05 ? (
                            "-"
                          ) : (
                            <Typography.Text className={styles.numberDisplay}>{getUsageHours(usageInSeconds)}</Typography.Text>
                          )}
                        </Tooltip>
                      </div>
                    </TableAntd.Summary.Cell>
                  );
                })}
              </TableAntd.Summary.Row>
            );
          }}
        />
      </Space>
      <Drawer
        open={!isNil(visibleWorker)}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("WorkerDetail-drawerTitle")}
        destroyOnClose
        onClose={handleDrawerClose}>
        {!isNil(visibleWorker) && (
          <WorkerDetail workerId={visibleWorker.id} onDelete={handleDelete} onEdit={analyticsDashboardWorkersQuery.refetch} />
        )}
      </Drawer>
    </Collapse>
  );
};

function getUsageHours(usage_sec: number) {
  return Math.round((usage_sec / 3600) * 10) / 10;
}

function getTotalDailyUsage(pageData: readonly Models.AnalyticsDashboardWorker[]) {
  let totalFirstDay = 0;
  let totalSecondDay = 0;
  let totalThirdDay = 0;
  let totalFourthDay = 0;
  let totalFifthDay = 0;
  let totalSixthDay = 0;
  let totalSeventhDay = 0;

  pageData.forEach((record) => {
    totalFirstDay += record.time_series_data[0].usage_sec;
    totalSecondDay += record.time_series_data[1].usage_sec;
    totalThirdDay += record.time_series_data[2].usage_sec;
    totalFourthDay += record.time_series_data[3].usage_sec;
    totalFifthDay += record.time_series_data[4].usage_sec;
    totalSixthDay += record.time_series_data[5].usage_sec;
    totalSeventhDay += record.time_series_data[6].usage_sec;
  });

  return [totalFirstDay, totalSecondDay, totalThirdDay, totalFourthDay, totalFifthDay, totalSixthDay, totalSeventhDay];
}
export default DashboardDailyUsageBreakdownSection;
