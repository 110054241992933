import {InfoCircleOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Cascader, Checkbox, Col, Row, Select, Space, Tooltip, Typography} from "antd";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  getQuarter,
  getYear,
  isAfter,
  isBefore,
  isEqual,
} from "date-fns";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import useUserPreferences from "web/hooks/useUserPreferences";

import styles from "./AnalyticsSettings.module.scss";
import * as Models from "../../../../../core/models";
import {WorkerModule} from "../../../../../core/modules/WorkerModule";
import {WorkerReportGroupModule} from "../../../../../core/modules/WorkerReportGroupModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {AppConfig} from "../../../../AppConfig";
import {isNil} from "../../../../utils/FunctionUtils";
import {WebHelper, calculateBegginingOfPeriod, calculateLastCompletedPeriod} from "../../../../utils/WebHelper";
import {Collapsible} from "../../../collapsible/Collapsible";
import {DatePicker} from "../../../date-picker/DatePicker";

export enum AnalyticsFilterOptions {
  AllWorkers = "all_workers",
  WorkerReportGroups = "worker_report_groups",
  IndividualWorkers = "individual_workers",
}

type Option = {
  value: string;
  label: string | React.ReactNode;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
  disabled?: boolean;
};

type CheckboxValueType = string | number | boolean;

const formatDate = (date: Date) =>
  date.toLocaleDateString(AppConfig.Settings.Localization.defaultLocale.code.replace("_", "-"), {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

type AnalyticsSettingsProps = {
  site?: Models.Site;
  department?: Models.Department;
  onSettingsChange: (
    updatedSettings?: Partial<Models.AnalyticsSettings>,
    dataSelected?: Models.ChartDataType,
    movementTypes?: CheckboxValueType[],
    mostRecentCheckbox?: boolean
  ) => void;
  dataSelected?: Models.ChartDataType;
  variableToChartSelected: Models.AnalyticsVariableToChart;
  intervalSelected: Models.AnalyticsInterval;
  displayMaxData: boolean;
  movementTypesSelected: Models.ChartMovementType[];
  safetyTypesSelected: Models.ChartMovementType[];
  workerStatusTypesSelected: Models.WorkerStatusType[];
  startDate: Date | null;
  endDate: Date | null;
  saveFilterSelection: (values: string[] | undefined) => void;
  filterValue: string[] | undefined;
};

export const AnalyticsSettings: FunctionComponent<AnalyticsSettingsProps> = ({
  site,
  department,
  onSettingsChange,
  dataSelected,
  variableToChartSelected,
  intervalSelected,
  movementTypesSelected,
  safetyTypesSelected,
  workerStatusTypesSelected,
  startDate,
  endDate,
  displayMaxData,
  saveFilterSelection,
  filterValue,
}) => {
  const MAX_BARS_TO_DISPLAY = AppConfig.Components.AnalyticsChart.maxBarsToDisplay;

  const authenticationStore = AuthenticationDataStore.getInstance();

  const [userPreferences] = useUserPreferences();

  const [isFiltersCollapsibleOpen, setIsFiltersCollapsibleOpen] = useState(true);
  const [datepickerKey, setDatepickerKey] = useState(0);

  const [mostRecentIntervalChecked, setMostRecentIntervalChecked] = useState(
    userPreferences.data.analyticsSettings?.checkedMostRecent !== undefined
      ? userPreferences.data.analyticsSettings?.checkedMostRecent
      : false
  );

  const [cascaderFilterOptions, setCascaderFilterOptions] = useState<Option[]>([
    {
      value: AnalyticsFilterOptions.AllWorkers,
      label: WebHelper.formatMessage("AnalyticsSettings-allWorkersLabel"),
    },
  ]);

  const workerReportGroupsQuery = useQuery({
    queryKey: ["AnalyticsSettings-fetchWorkerReportGroups", site?.id],
    queryFn: () =>
      WorkerReportGroupModule.workerReportGroups({
        accessToken: authenticationStore.state.accessToken!,
        site_id: site!.id,
      }),
    enabled: !!site?.id,
  });

  useEffect(() => {
    const response = workerReportGroupsQuery.data;
    if (response) {
      if (!response.success)
        WebHelper.showErrorMessage(
          WebHelper.formatMessage("WorkerReportGroupsDisplay-fetchWorkerReportGroupsDataError"),
          response.correlationId
        );
    }
  }, [workerReportGroupsQuery.data]);

  const departmentWorkersQuery = useQuery({
    queryKey: ["AnalyticsSettings-fetchDepartmentWorkersData", department?.id],
    queryFn: () =>
      WorkerModule.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: department!.id,
      }),
    enabled: !!department?.id,
  });

  const loading = (!!department?.id && departmentWorkersQuery.isPending) || (!!site?.id && workerReportGroupsQuery.isPending);

  const loadOptions = useCallback(() => {
    const workerGroups = workerReportGroupsQuery.data?.workerReportGroups;

    if (!site || !workerGroups) return;

    let workerGroupsFiltered: Models.WorkerReportGroup[] = workerGroups;

    let departmentWorkers: Models.WorkerShort[] = [];

    if (department) {
      departmentWorkers = departmentWorkersQuery.data?.workers ?? [];

      workerGroupsFiltered = workerGroupsFiltered
        .filter((group) => group.workers?.some((worker) => departmentWorkers.find((departmentWorker) => departmentWorker.id === worker.id)))
        .map((group) => {
          return {
            ...group,
            workers:
              group.workers?.filter((worker) => departmentWorkers.find((departmentWorker) => departmentWorker.id === worker.id)) ?? null,
          };
        });
    }

    workerGroupsFiltered = workerGroupsFiltered.filter((group) => group.workers?.length && group.workers.length > 0);

    const options: Option[] = [
      {
        value: AnalyticsFilterOptions.AllWorkers,
        label: WebHelper.formatMessage("AnalyticsSettings-allWorkersLabel"),
      },
      {
        value: AnalyticsFilterOptions.WorkerReportGroups,
        label:
          workerGroupsFiltered.length === 0 ? (
            <Tooltip title={WebHelper.formatMessage("AnalyticsSettings-emptyWorkerReportGroupsTooltip")}>
              {WebHelper.formatMessage("AnalyticsSettings-workerReportGroupsLabel")}
            </Tooltip>
          ) : (
            WebHelper.formatMessage("AnalyticsSettings-workerReportGroupsLabel")
          ),
        children: workerGroupsFiltered
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((workerReportGroup) => ({
            value: workerReportGroup.id,
            label: workerReportGroup.name,
          })),
        disabled: workerGroupsFiltered.length === 0,
      },
    ];

    if (department)
      options.push({
        value: AnalyticsFilterOptions.IndividualWorkers,
        label:
          departmentWorkers.length === 0 ? (
            <Tooltip title={WebHelper.formatMessage("AnalyticsSettings-emptyWorkersTooltip")}>
              {WebHelper.formatMessage("AnalyticsSettings-individualWorkerLabel")}
            </Tooltip>
          ) : (
            WebHelper.formatMessage("AnalyticsSettings-individualWorkerLabel")
          ),
        children: departmentWorkers
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map((worker) => ({
            value: worker.id,
            label: worker.displayName,
            isLeaf: true,
          })),
        disabled: departmentWorkers.length === 0,
      });

    setCascaderFilterOptions(options);
  }, [site, workerReportGroupsQuery.data?.workerReportGroups, department, departmentWorkersQuery.data?.workers]);

  const variableToChartOptions = useMemo(
    () => [
      {label: WebHelper.formatMessage("AnalyticsSettings-hoursUsed"), value: Models.AnalyticsVariableToChart.HoursUsed},
      {label: WebHelper.formatMessage("AnalyticsSettings-lifts"), value: Models.AnalyticsVariableToChart.Lifts},
      {label: WebHelper.formatMessage("AnalyticsSettings-weightOffloaded"), value: Models.AnalyticsVariableToChart.WeightOffloaded},
      {label: WebHelper.formatMessage("AnalyticsSettings-movementBreakdown"), value: Models.AnalyticsVariableToChart.MovementBreakdown},
      {label: WebHelper.formatMessage("AnalyticsSettings-workerActiveStatus"), value: Models.AnalyticsVariableToChart.WorkerActiveStatus},
    ],
    []
  );

  const intervalOptions = useMemo(
    () => [
      {label: WebHelper.formatMessage("AnalyticsSettings-day"), value: Models.AnalyticsInterval.Day},
      {label: WebHelper.formatMessage("AnalyticsSettings-week"), value: Models.AnalyticsInterval.Week},
      {label: WebHelper.formatMessage("AnalyticsSettings-month"), value: Models.AnalyticsInterval.Month},
      {label: WebHelper.formatMessage("AnalyticsSettings-quarter"), value: Models.AnalyticsInterval.Quarter},
    ],
    []
  );

  const dataOptions = useMemo(
    () => [
      {label: WebHelper.formatMessage("AnalyticsSettings-raw"), value: Models.ChartDataType.Raw},
      {label: WebHelper.formatMessage("AnalyticsSettings-percentage"), value: Models.ChartDataType.Percentage},
    ],
    []
  );

  const showDataAndCheckboxGroup = useMemo(
    () =>
      variableToChartSelected === Models.AnalyticsVariableToChart.Lifts ||
      variableToChartSelected === Models.AnalyticsVariableToChart.MovementBreakdown ||
      variableToChartSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus,
    [variableToChartSelected]
  );

  const CheckBoxGroupRow = useMemo(() => {
    let content;
    let title;
    switch (variableToChartSelected) {
      // This case is not yet implemented in the backend
      // case Models.AnalyticsVariableToChart.HoursUsed:
      //   title = WebHelper.formatMessage("AnalyticsSettings-displayInGraph");
      //   content = (
      //     <Checkbox.Group value={workerStatusTypesSelected} onChange={(value) => onSettingsChange(undefined, undefined, value, undefined)}>
      //       <Checkbox value={Models.DisplayInGraphType.HoursUsed} className={`${styles.checkboxColor} ${styles.hoursUsed}`}>
      //         <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-hoursUsed")}</Typography.Text>
      //       </Checkbox>
      //       <Checkbox value={Models.DisplayInGraphType.Target} className={`${styles.checkboxColor} ${styles.target}`}>
      //         <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-target")}</Typography.Text>
      //       </Checkbox>
      //     </Checkbox.Group>
      //   );
      //   break;
      case Models.AnalyticsVariableToChart.WorkerActiveStatus:
        title = WebHelper.formatMessage("AnalyticsSettings-workers");
        content = (
          <Checkbox.Group value={workerStatusTypesSelected} onChange={(value) => onSettingsChange(undefined, undefined, value, undefined)}>
            <Checkbox value={Models.WorkerStatusType.Active} className={`${styles.checkboxColor} ${styles.active}`}>
              <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-activeWorkers")}</Typography.Text>
            </Checkbox>
            <Checkbox value={Models.WorkerStatusType.Inactive} className={`${styles.checkboxColor} ${styles.inactive}`}>
              <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-inactiveWorkers")}</Typography.Text>
            </Checkbox>
          </Checkbox.Group>
        );
        break;
      case Models.AnalyticsVariableToChart.Lifts:
        title = WebHelper.formatMessage("AnalyticsSettings-movements");
        content = (
          <Checkbox.Group value={safetyTypesSelected} onChange={(value) => onSettingsChange(undefined, undefined, value)}>
            <Checkbox value={Models.ChartMovementType.Safe} className={`${styles.checkboxColor} ${styles.safe}`}>
              <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-safe")}</Typography.Text>
            </Checkbox>
            <Checkbox value={Models.ChartMovementType.Risky} className={`${styles.checkboxColor} ${styles.risky}`}>
              <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-risky")}</Typography.Text>
            </Checkbox>
          </Checkbox.Group>
        );
        break;
      case Models.AnalyticsVariableToChart.MovementBreakdown:
        title = WebHelper.formatMessage("AnalyticsSettings-movements");
        content = (
          <Checkbox.Group value={movementTypesSelected} onChange={(value) => onSettingsChange(undefined, undefined, value)}>
            <Checkbox
              value={Models.ChartMovementType.ExcessiveForwardBending}
              className={`${styles.checkboxColor} ${styles.excessiveForwardBending}`}>
              <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-excessiveForwardBending")}</Typography.Text>
            </Checkbox>
            <Checkbox value={Models.ChartMovementType.ProlongedBending} className={`${styles.checkboxColor} ${styles.prolongedBending}`}>
              <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-prolongedBending")}</Typography.Text>
            </Checkbox>
            <Checkbox value={Models.ChartMovementType.Twisting} className={`${styles.checkboxColor} ${styles.twisting}`}>
              <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-twisting")}</Typography.Text>
            </Checkbox>
            <Checkbox value={Models.ChartMovementType.SideBending} className={`${styles.checkboxColor} ${styles.sideBending}`}>
              <Typography.Text>{WebHelper.formatMessage("AnalyticsChart-datasetLabel-sideBending")}</Typography.Text>
            </Checkbox>
          </Checkbox.Group>
        );
        break;
      default:
        return;
    }

    return (
      <Row className={styles.item}>
        <Col lg={4} sm={6} xs={6}>
          <Typography.Text>{title}</Typography.Text>
        </Col>
        {content}
      </Row>
    );
  }, [variableToChartSelected, workerStatusTypesSelected, safetyTypesSelected, movementTypesSelected, onSettingsChange]);

  return (
    <Collapsible
      open={isFiltersCollapsibleOpen}
      onToggle={() => setIsFiltersCollapsibleOpen(!isFiltersCollapsibleOpen)}
      label={
        isFiltersCollapsibleOpen
          ? WebHelper.formatMessage("AnalyticsTabContent-hideFiltersLabel")
          : WebHelper.formatMessage("AnalyticsTabContent-showAllFiltersLabel")
      }
      children={
        <Space direction="vertical" size={16} className={styles.filters}>
          {(site || department) && (
            <Row gutter={[15, 0]} className={styles.item}>
              <Col lg={12} md={24}>
                <Row className={styles.item}>
                  <Col lg={3} md={6}>
                    <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-filter")}</Typography.Text>
                  </Col>
                  <Col lg={20} md={18}>
                    <Cascader
                      allowClear={false}
                      loading={loading}
                      disabled={loading}
                      className={styles.filter}
                      displayRender={(labels) =>
                        loading ? WebHelper.formatMessage("AnalyticsSettings-loadingFilters") : labels.join(" > ")
                      }
                      showSearch
                      onChange={(values) => {
                        saveFilterSelection(values as string[]);
                      }}
                      options={cascaderFilterOptions}
                      popupClassName={styles.cascaderPopup}
                      onDropdownVisibleChange={loadOptions}
                      value={filterValue}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={12} md={24}>
                <IntervalSelect
                  site={site}
                  department={department}
                  intervalOptions={intervalOptions}
                  intervalSelected={intervalSelected}
                  startDate={startDate}
                  endDate={endDate}
                  onSettingsChange={onSettingsChange}
                  daysAvailable={MAX_BARS_TO_DISPLAY}
                  displayMaxData={displayMaxData}
                  intervalType={intervalSelected}
                  mostRecentIntervalChecked={mostRecentIntervalChecked}
                  setDatepickerKey={setDatepickerKey}
                />
              </Col>
            </Row>
          )}
          {!site && !department && (
            <Col lg={18} md={24}>
              <IntervalSelect
                site={site}
                department={department}
                intervalOptions={intervalOptions}
                intervalSelected={intervalSelected}
                startDate={startDate}
                endDate={endDate}
                onSettingsChange={onSettingsChange}
                setDatepickerKey={setDatepickerKey}
                daysAvailable={MAX_BARS_TO_DISPLAY}
                displayMaxData={displayMaxData}
                intervalType={intervalSelected}
                mostRecentIntervalChecked={mostRecentIntervalChecked}
              />
            </Col>
          )}
          <Row gutter={[15, 0]} className={styles.item}>
            <Col lg={12} md={24}>
              <Row className={styles.item}>
                <Col lg={3} md={3}>
                  <Typography.Text>
                    {!displayMaxData
                      ? WebHelper.formatMessage("AnalyticsSettings-dateRange")
                      : WebHelper.formatMessage("AnalyticsSettings-endDate")}
                  </Typography.Text>
                </Col>
                <Col lg={16}>
                  <div className={styles.endDateWrapper}>
                    {!displayMaxData && (
                      <DatePicker
                        cellRender={
                          intervalSelected === Models.AnalyticsInterval.Quarter
                            ? (date) => {
                                //workaround to show Q-QuarterNumber when opening calendar
                                if (typeof date !== "string") return `Q-${getQuarter(date)}`;
                                return "";
                              }
                            : undefined
                        }
                        key={`${datepickerKey}-startDate`}
                        picker={intervalSelected == Models.AnalyticsInterval.Day ? undefined : (intervalSelected as any)} // type mismatch, setting it as any fixes the problem
                        className={styles.dateSelector}
                        value={startDate}
                        placeholder={WebHelper.formatMessage("AnalyticsSettings-selectStartDate")}
                        format={(date) =>
                          intervalSelected == Models.AnalyticsInterval.Quarter
                            ? `${getYear(date)}-Q${getQuarter(date)}`
                            : intervalSelected == Models.AnalyticsInterval.Month
                              ? date.toLocaleDateString(AppConfig.Settings.Localization.defaultLocale.code.replace("_", "-"), {
                                  month: "short",
                                })
                              : formatDate(date)
                        }
                        onChange={(date) => onSettingsChange({startDate: date ? calculateBegginingOfPeriod(intervalSelected, date) : null})}
                        disabled={isNil(endDate)}
                        disabledDate={(date) =>
                          isAfter(date, endOfDay(new Date())) ||
                          (endDate && isAfter(date, endDate)) ||
                          (endDate && isEqual(date, endDate)) ||
                          (intervalSelected === Models.AnalyticsInterval.Day &&
                            endDate !== null &&
                            isBefore(date, addDays(endDate, -MAX_BARS_TO_DISPLAY))) ||
                          (intervalSelected === Models.AnalyticsInterval.Week &&
                            endDate !== null &&
                            isBefore(date, addWeeks(endDate, -MAX_BARS_TO_DISPLAY))) ||
                          (intervalSelected === Models.AnalyticsInterval.Month &&
                            endDate !== null &&
                            isBefore(date, addMonths(endDate, -MAX_BARS_TO_DISPLAY))) ||
                          (intervalSelected === Models.AnalyticsInterval.Quarter &&
                            endDate !== null &&
                            isBefore(date, addQuarters(endDate, -MAX_BARS_TO_DISPLAY)))
                        }
                      />
                    )}
                    <DatePicker
                      cellRender={
                        intervalSelected === Models.AnalyticsInterval.Quarter
                          ? (date) => {
                              //workaround to show Q-QuarterNumber when opening calendar
                              if (typeof date !== "string") return `Q-${getQuarter(date)}`;
                              return "";
                            }
                          : undefined
                      }
                      key={`${datepickerKey}-endDate`}
                      picker={intervalSelected == Models.AnalyticsInterval.Day ? undefined : (intervalSelected as any)} // type mismatch, setting it as any fixes the problem
                      className={`${displayMaxData ? styles.filter : styles.dateSelector}`}
                      value={
                        //most recent selects the most recent complete interval
                        mostRecentIntervalChecked ? calculateLastCompletedPeriod(intervalSelected) : endDate
                      }
                      placeholder={WebHelper.formatMessage("AnalyticsSettings-selectEndDate")}
                      format={(date) =>
                        intervalSelected == Models.AnalyticsInterval.Quarter
                          ? `${getYear(date)}-Q${getQuarter(date)}`
                          : intervalSelected == Models.AnalyticsInterval.Month
                            ? date.toLocaleDateString(AppConfig.Settings.Localization.defaultLocale.code.replace("_", "-"), {
                                month: "short",
                              })
                            : formatDate(date)
                      }
                      onChange={(date) => {
                        let lastDayOfInterval = date;

                        if (date && intervalSelected == Models.AnalyticsInterval.Day) lastDayOfInterval = endOfDay(date);
                        if (date && intervalSelected == Models.AnalyticsInterval.Week) lastDayOfInterval = endOfWeek(date);
                        if (date && intervalSelected == Models.AnalyticsInterval.Month) lastDayOfInterval = endOfMonth(date);
                        if (date && intervalSelected == Models.AnalyticsInterval.Quarter) lastDayOfInterval = endOfQuarter(date);

                        onSettingsChange({
                          endDate: lastDayOfInterval,
                        });
                      }}
                      disabledDate={(date) =>
                        isAfter(date, endOfDay(new Date())) ||
                        (startDate && isEqual(date, startDate)) ||
                        (startDate && isBefore(date, startDate)) ||
                        (intervalSelected === Models.AnalyticsInterval.Day &&
                          startDate !== null &&
                          isAfter(date, addDays(startDate, MAX_BARS_TO_DISPLAY))) ||
                        (intervalSelected === Models.AnalyticsInterval.Week &&
                          startDate !== null &&
                          isAfter(date, addWeeks(startDate, MAX_BARS_TO_DISPLAY))) ||
                        (intervalSelected === Models.AnalyticsInterval.Month &&
                          startDate !== null &&
                          isAfter(date, addMonths(startDate, MAX_BARS_TO_DISPLAY))) ||
                        (intervalSelected === Models.AnalyticsInterval.Quarter &&
                          startDate !== null &&
                          isAfter(date, addQuarters(startDate, MAX_BARS_TO_DISPLAY)))
                      }
                      onOpenChange={(open) => {
                        if (open) {
                          setMostRecentIntervalChecked(false);
                          onSettingsChange({endDate: null, startDate: null, checkedMostRecent: false}, undefined, undefined, false);
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col lg={5}>
                  <div className={styles.mostRecent}>
                    <Checkbox
                      checked={mostRecentIntervalChecked}
                      onChange={(event) => {
                        setMostRecentIntervalChecked(event.target.checked);
                        if (event.target.checked) {
                          const targetDate = calculateLastCompletedPeriod(intervalSelected);
                          onSettingsChange({startDate: null, endDate: targetDate}, undefined, undefined, true);
                        } else onSettingsChange({startDate: null, endDate: null}, undefined, undefined, false);
                      }}>
                      <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-mostRecent")}</Typography.Text>
                    </Checkbox>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={24}>
              <Row className={styles.item}>
                <Col lg={4} xs={6}>
                  <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-variableToChart")}</Typography.Text>
                </Col>
                <Col lg={20} xs={18}>
                  <Select
                    className={styles.secondColumn}
                    options={variableToChartOptions}
                    value={variableToChartSelected}
                    onChange={(value) => {
                      onSettingsChange({variableToChartSelected: value});
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
      }
      collapsibleChildren={
        showDataAndCheckboxGroup ? (
          <Space direction="vertical" size={16} className={styles.filters}>
            {showDataAndCheckboxGroup && (
              <Row gutter={[15, 0]} className={styles.item}>
                <Col lg={12} md={24}>
                  <Row className={styles.item}>
                    <Col lg={3} md={6}>
                      <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-data")}</Typography.Text>
                    </Col>
                    <Col lg={20} md={18}>
                      <Select
                        className={styles.filter}
                        options={dataOptions}
                        value={dataSelected}
                        onChange={(value) => {
                          onSettingsChange(undefined, value, undefined);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} md={24}>
                  {CheckBoxGroupRow}
                </Col>
              </Row>
            )}
          </Space>
        ) : null
      }
    />
  );
};

type IntervalSelectProps = {
  intervalOptions: {label: string; value: Models.AnalyticsInterval}[];
  intervalSelected: Models.AnalyticsInterval;
  startDate: Date | null;
  endDate: Date | null;
  onSettingsChange: (
    updatedSettings?: Partial<Models.AnalyticsSettings>,
    dataSelected?: Models.ChartDataType,
    movementTypes?: CheckboxValueType[],
    checkedMostRecent?: boolean
  ) => void;
  daysAvailable: number;
  displayMaxData: boolean;
  site?: Models.Site;
  department?: Models.Department;
  intervalType: Models.AnalyticsInterval;
  mostRecentIntervalChecked: boolean;
  setDatepickerKey: (b: number) => void;
};

const IntervalSelect: FunctionComponent<IntervalSelectProps> = ({
  intervalOptions,
  intervalSelected,
  startDate,
  endDate,
  onSettingsChange,
  daysAvailable,
  displayMaxData,
  site,
  department,
  mostRecentIntervalChecked,
  setDatepickerKey,
}) => {
  return (
    <Space direction="vertical" size={16} className={styles.filters}>
      <Row gutter={[15, 0]} className={styles.item}>
        <Col lg={site || department ? 4 : 2} md={6}>
          <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-interval")}</Typography.Text>
        </Col>
        <Col lg={site || department ? 11 : 14} md={6} className={`${!(site || department) && styles.orgInterval}`}>
          <Select
            className={styles.secondColumn}
            options={intervalOptions}
            value={intervalSelected}
            onChange={(value) => {
              setDatepickerKey(new Date().getTime());
              onSettingsChange(
                {
                  intervalSelected: value,
                  startDate: null,
                  endDate: mostRecentIntervalChecked ? calculateLastCompletedPeriod(value) : null,
                  checkedMostRecent: mostRecentIntervalChecked,
                },
                undefined,
                undefined,
                mostRecentIntervalChecked
              );
            }}
          />
        </Col>
        <Col
          lg={site || department ? 9 : 8}
          md={10}
          className={`${styles.displayMaxDataCol} ${!(site || department) && styles.orgMaxData}`}>
          <Typography.Text>{WebHelper.formatMessage("AnalyticsSettings-displayMaxData")}</Typography.Text>
          <Tooltip title={WebHelper.formatMessage("AnalyticsSettings-displayMaxDataTooltip")}>
            <InfoCircleOutlined />
          </Tooltip>
          {/*<Switch checked={displayMaxData} onChange={(value) => onSettingsChange({displayMaxData: value})} />*/}
        </Col>
      </Row>
    </Space>
  );
};
