import {isObservableMap, ObservableMap, isObservableArray, isObservableObject} from "mobx";

type EmptyArray = never[];
type EmptyObject = Record<string, never>;
type EmptyString = "";

type Empty = EmptyArray | EmptyObject | EmptyString;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Bottom<T> = T extends string ? EmptyString : T extends any[] ? EmptyArray : T extends object ? EmptyObject : never;

export function isNil<T>(input: T | undefined | null): input is null | undefined {
  return input == null;
}

export function isUndefined<T>(input: T | undefined | null): input is undefined {
  return input === undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty<T extends string | any[] | object | Record<string, unknown>>(
  value: T | Empty | null | undefined
): value is Bottom<T> | null | undefined {
  if (isNil(value)) {
    return true;
  }

  if (Array.isArray(value) || isString(value)) {
    return value.length === 0;
  }

  if (isObject(value) && !isDate(value)) {
    return Object.keys(value).length === 0;
  }

  return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(value: any): value is Record<string, unknown> | object {
  return !isNil(value) && (typeof value === "object" || typeof value === "function") && !Array.isArray(value);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isString(value: any): value is string {
  return value instanceof String || typeof value === "string";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDate(value: any) {
  return value instanceof Date;
}

export function isArray(value: any): value is any[] {
  return Array.isArray(value);
}

export function isEqual(value: any, other: any) {
  if (value === other) {
    return true;
  }

  if (value == null || other == null || typeof value !== "object" || typeof other !== "object") {
    return value !== value && other !== other; // Handles NaN comparison
  }

  const keysValue = Object.keys(value);
  const keysOther = Object.keys(other);

  if (keysValue.length !== keysOther.length) {
    return false;
  }

  for (const key of keysValue) {
    if (!isEqual(value[key], other[key])) {
      return false;
    }
  }

  return true;
}

export function round(n: number, precision: number = 0): number {
  const factor = Math.pow(10, precision);
  return Math.round(n * factor) / factor;
}

export function uniqWith<T>(array: T[], comparator: (a: T, b: T) => boolean): T[] {
  const result: T[] = [];

  for (const value of array) {
    if (!result.some((item) => comparator(value, item))) {
      result.push(value);
    }
  }

  return result;
}

export function concat<T>(...arraysOrValues: (T | T[])[]): T[] {
  const result: T[] = [];

  for (const item of arraysOrValues) {
    if (Array.isArray(item)) {
      result.push(...item);
    } else {
      result.push(item);
    }
  }

  return result;
}

export function pick<T extends Record<string, unknown>, K extends keyof T>(object: T, keys: K[]): Pick<T, K> {
  const result = {} as Pick<T, K>;

  for (const key of keys) {
    if (key in object) {
      result[key] = object[key];
    }
  }

  return result;
}

export function assign<TObject, TSource>(target: TObject, source: TSource): TObject & TSource;
export function assign<TObject, TSource1, TSource2>(target: TObject, source1: TSource1, source2: TSource2): TObject & TSource1 & TSource2;
// Add more overloads as needed
export function assign(target: any, ...sources: any[]): any {
  return Object.assign(target, ...sources);
}

export function cloneDeep<T>(value: T): T {
  if (value === null || typeof value !== "object") {
    return value;
  }

  if (Array.isArray(value) || isObservableArray(value)) {
    const copy: any[] = [];
    for (const item of value) {
      copy.push(cloneDeep(item));
    }
    return copy as unknown as T;
  }

  if (value instanceof Date) {
    return new Date(value.getTime()) as unknown as T;
  }

  if (value instanceof RegExp) {
    return new RegExp(value) as unknown as T;
  }

  if (isObservableMap(value)) {
    const copy = new ObservableMap();
    value.forEach((val, key) => {
      copy.set(key, cloneDeep(val));
    });
    return copy as unknown as T;
  }

  if (isObservableObject(value)) {
    const copy: {[key: string]: any} = {};
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        copy[key] = cloneDeep(value[key]);
      }
    }
    return copy as T;
  }

  const copy: {[key: string]: any} = {};
  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      copy[key] = cloneDeep(value[key]);
    }
  }

  return copy as T;
}
export type Customizer<T> = (objValue: any, srcValue: any, key: string, object: T, source: T, stack: any[]) => any;

export function mergeWith<T extends object>(object: T, source: T, customizer: Customizer<T>, stack: any[] = []): T {
  if (object === source) {
    return object;
  }

  if (typeof object !== "object" || object === null || typeof source !== "object" || source === null) {
    return object;
  }

  const keys = Object.keys(source) as (keyof T)[];
  for (const key of keys) {
    const objValue = object[key];
    const srcValue = source[key];

    const stacked = stack.find(([obj, src]) => obj === objValue && src === srcValue);
    if (stacked) {
      object[key] = stacked[2];
      continue;
    }

    stack.push([objValue, srcValue, object[key]]);

    const newValue = customizer(objValue, srcValue, key as string, object, source, stack);
    if (newValue !== undefined) {
      object[key] = newValue;
    } else {
      if (srcValue instanceof Date) {
        object[key] = new Date(srcValue.getTime()) as unknown as T[keyof T];
      } else if (typeof srcValue === "object" && srcValue !== null) {
        if (Array.isArray(srcValue)) {
          object[key] = mergeWith(objValue || ([] as any), srcValue as any, customizer, stack) as unknown as T[keyof T];
        } else {
          object[key] = mergeWith(objValue || ({} as any), srcValue as any, customizer, stack) as unknown as T[keyof T];
        }
      } else {
        object[key] = srcValue;
      }
    }

    stack.pop();
  }

  return object;
}

export default mergeWith;
