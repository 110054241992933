import {EditOutlined} from "@ant-design/icons";
import {useMutation} from "@tanstack/react-query";
import {Checkbox, Drawer, Form, Input, Modal, Select, Space, Typography} from "antd";
import {Store} from "antd/lib/form/interface";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import useUserPreferences from "web/hooks/useUserPreferences";

import styles from "./AccountSettings.module.scss";
import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppConfig} from "../../AppConfig";
import {isArray} from "../../utils/FunctionUtils";
import {WebHelper} from "../../utils/WebHelper";

export type AccountSettingsProps = {
  visible: boolean;
  onClose?: () => void;
};

export const AccountSettings: FunctionComponent<AccountSettingsProps> = observer(({visible, onClose}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const {user: currentUser} = authenticationStore.state;
  const {isUserAdmin} = authenticationStore;

  const {accountSettings: accountSettingsForm} = AppConfig.Modules.InputValidation;

  const [editMode, setEditMode] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [reload, setReload] = useState(false);

  const [form] = Form.useForm();

  const onSuccess = () => {
    if (reload) location.reload();
    setReload(false);
  };

  const [userPreferences, setUserPreferences] = useUserPreferences(onSuccess);

  const updateMyUserProfileMutation = useMutation({
    mutationFn: (store: Store) =>
      authenticationStore.updateMyUserProfile({
        accessToken: authenticationStore.state.accessToken!,
        id: currentUser!.id,
        job_title: store.job_title,
        department: store.department,
        phone_number: store.phone_number,
        address: store.address,
        subscribed: store.subscribed,
      }),
    onSuccess: async (response) => {
      if (
        (location.pathname.includes("/organizations/") &&
          userPreferences.data.metricMeasurementUnits !== form.getFieldValue(["metric_units"])) ||
        userPreferences.data.preferred_language !== form.getFieldValue(["preferred_language"])
      ) {
        await setReload(true);
        setUserPreferences({
          metricMeasurementUnits: form.getFieldValue(["metric_units"]),
          preferred_language: form.getFieldValue(["preferred_language"]),
        });
      }

      if (!response.success) {
        const invalidPhoneException = "InvalidParameterException: Invalid phone number format.";
        if (!isArray(response.error) && response.error.code === "INTERNAL_SERVER_ERROR" && response.error.details === invalidPhoneException)
          WebHelper.showErrorMessage(WebHelper.formatMessage("AccountSettings-updateErrorPhoneNumber"), response.correlationId);
        else WebHelper.showErrorMessage(WebHelper.formatMessage("AccountSettings-updateError"), response.correlationId);
      } else {
        setEditMode(false);
      }
    },
  });

  const handleClose = useCallback(() => {
    if (form.isFieldsTouched() && editMode) {
      setShowConfirmModal(true);
    } else {
      setEditMode(false);
      if (onClose) onClose();
    }
  }, [onClose, form, editMode]);

  const handleEdit = async (store: Store) => {
    form
      .validateFields()
      .then(() => {
        updateMyUserProfileMutation.mutate(store);
      })
      // this catch prevents antd from throwing an error to console when doesn't pass form validations
      .catch(() => {});
  };

  const handleCancelEdit = () => {
    if (form.isFieldsTouched()) {
      setShowConfirmModal(true);
    } else {
      setEditMode(false);
    }
  };

  const handleCloseEditing = () => {
    if (form.isFieldsTouched() && editMode) {
      handleCancelEdit();
    } else {
      setEditMode(false);
    }
  };

  const handleDiscard = () => {
    setShowConfirmModal(false);
    form.resetFields();
    setEditMode(false);
  };

  const handleOnFieldsChange = () => {
    if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  };

  useEffect(() => {
    if (!editMode) setIsSubmitDisabled(true);
  }, [editMode]);

  if (!currentUser) return null;

  const organizationValue =
    currentUser.organization_id && currentUser.organization_name
      ? currentUser.organization_name
      : WebHelper.formatMessage("AccountSettings-organizationGlobal");

  const languageOptions = useMemo(
    () => [
      {
        label: WebHelper.formatMessage("AccountSettings-english"),
        value: Models.SupportedLanguages.en_US,
      },
      {
        label: WebHelper.formatMessage("AccountSettings-spanish"),
        value: Models.SupportedLanguages.es_ES,
      },
      {
        label: WebHelper.formatMessage("AccountSettings-french"),
        value: Models.SupportedLanguages.fr_CA,
      },
    ],
    []
  );

  const userLanguage = () => {
    if (userPreferences.data.preferred_language) {
      return userPreferences.data.preferred_language === Models.SupportedLanguages.es_ES
        ? WebHelper.formatMessage("AccountSettings-spanish")
        : userPreferences.data.preferred_language === Models.SupportedLanguages.fr_CA
          ? WebHelper.formatMessage("AccountSettings-french")
          : WebHelper.formatMessage("AccountSettings-english");
    }
    const userBrowserLanguage = navigator.language || navigator.languages[0];
    return userBrowserLanguage.split("-")[0] === "es"
      ? WebHelper.formatMessage("AccountSettings-spanish")
      : userBrowserLanguage.split("-")[0] === "fr"
        ? WebHelper.formatMessage("AccountSettings-french")
        : WebHelper.formatMessage("AccountSettings-english");
  };

  return (
    <Drawer
      className={styles.drawer}
      open={visible}
      width={WebHelper.drawerWidth}
      title={WebHelper.formatMessage("AccountSettings-title")}
      destroyOnClose
      onClose={handleClose}
      getContainer={false}>
      <div className={styles.drawerContent}>
        <Space className={styles.detailSpace}>
          <Typography.Text className={styles.profileDataTitle}>{WebHelper.formatMessage("AccountSettings-profileData")}</Typography.Text>
          {!editMode && (
            <EditOutlined
              className={styles.headerIcon}
              onClick={() => {
                setEditMode(true);
              }}
            />
          )}
        </Space>
        <Modal
          destroyOnClose
          okButtonProps={{shape: "round", disabled: isSubmitDisabled, loading: updateMyUserProfileMutation.isPending}}
          cancelButtonProps={{shape: "round", disabled: updateMyUserProfileMutation.isPending}}
          onOk={() => form.submit()}
          okText={WebHelper.formatMessage("Common-save")}
          title={WebHelper.formatMessage("AccountSettings-profileData")}
          onCancel={handleCloseEditing}
          open={editMode}>
          <Space className={styles.formSubtitle}>
            <Typography.Text strong>{`${currentUser.given_name} ${currentUser.family_name}`}</Typography.Text>
          </Space>
          <Form
            labelCol={{sm: {span: 4}, lg: {span: 6}}}
            labelAlign="left"
            labelWrap={true}
            form={form}
            initialValues={currentUser}
            unselectable="off"
            onFieldsChange={handleOnFieldsChange}
            disabled={updateMyUserProfileMutation.isPending}
            onFinish={handleEdit}>
            <Form.Item
              colon={false}
              dependencies={["job_title"]}
              label={<Typography.Text>{WebHelper.formatMessage("AccountSettings-jobTitle")}</Typography.Text>}
              name="job_title"
              rules={WebHelper.formatInputValidationRules(accountSettingsForm.jobTitle.rules)}>
              <Input />
            </Form.Item>
            <Form.Item
              colon={false}
              dependencies={["department"]}
              label={<Typography.Text>{WebHelper.formatMessage("AccountSettings-department")}</Typography.Text>}
              name="department"
              rules={WebHelper.formatInputValidationRules(accountSettingsForm.department.rules)}>
              <Input />
            </Form.Item>
            <Form.Item
              colon={false}
              dependencies={["phone_number"]}
              label={<Typography.Text>{WebHelper.formatMessage("AccountSettings-workPhone")}</Typography.Text>}
              name="phone_number"
              rules={WebHelper.formatInputValidationRules(accountSettingsForm.phoneNumber.rules)}>
              <Input />
            </Form.Item>
            <Form.Item
              colon={false}
              dependencies={["address"]}
              label={<Typography.Text>{WebHelper.formatMessage("AccountSettings-workAddress")}</Typography.Text>}
              name="address"
              rules={WebHelper.formatInputValidationRules(accountSettingsForm.address.rules)}>
              <Input />
            </Form.Item>
            {isUserAdmin && (
              <Form.Item
                colon={false}
                dependencies={["subscribed"]}
                label={<Typography.Text>{WebHelper.formatMessage("AccountSettings-subscribed")}</Typography.Text>}
                name="subscribed"
                valuePropName="checked">
                <Checkbox />
              </Form.Item>
            )}
            <Form.Item
              colon={false}
              dependencies={["metric_units"]}
              label={<Typography.Text>{WebHelper.formatMessage("AccountSettings-metricUnits")}</Typography.Text>}
              name="metric_units"
              valuePropName="checked"
              initialValue={userPreferences.data.metricMeasurementUnits}>
              <Checkbox />
            </Form.Item>
            <Form.Item
              colon={false}
              dependencies={["preferred_language"]}
              label={<Typography.Text>{WebHelper.formatMessage("AccountSettings-preferredLanguage")}</Typography.Text>}
              name="preferred_language"
              initialValue={userPreferences.data.preferred_language ?? Models.SupportedLanguages.en_US}>
              <Select defaultValue={Models.SupportedLanguages.en_US} options={languageOptions} />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          open={showConfirmModal}
          destroyOnClose={true}
          title={WebHelper.formatMessage("Common-closeFormAlertTitle")}
          okText={WebHelper.formatMessage("Common-discard")}
          cancelText={WebHelper.formatMessage("Common-cancel")}
          onOk={handleDiscard}
          onCancel={() => setShowConfirmModal(false)}>
          {WebHelper.formatMessage("Common-closeFormAlertMessage")}
        </Modal>
        <>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-email")}</Typography.Text>
            <Typography.Text>{currentUser.email}</Typography.Text>
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-organization")}</Typography.Text>
            <Typography.Text>{organizationValue}</Typography.Text>
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-firstName")}</Typography.Text>
            <Typography.Text>{currentUser.given_name}</Typography.Text>
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-lastName")}</Typography.Text>
            <Typography.Text>{currentUser.family_name}</Typography.Text>
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-jobTitle")}</Typography.Text>
            <Typography.Text>{currentUser.job_title}</Typography.Text>
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-department")}</Typography.Text>
            <Typography.Text>{currentUser.department}</Typography.Text>
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-workPhone")}</Typography.Text>
            <Typography.Text>{currentUser.phone_number}</Typography.Text>
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-workAddress")}</Typography.Text>
            <Typography.Text>{currentUser.address}</Typography.Text>
          </Space>
          {isUserAdmin && (
            <Space className={styles.detailSpace}>
              <Typography.Text>{WebHelper.formatMessage("AccountSettings-subscribed")}</Typography.Text>
              <Typography.Text>
                {currentUser.subscribed ? WebHelper.formatMessage("Common-yes") : WebHelper.formatMessage("Common-no")}
              </Typography.Text>
            </Space>
          )}
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-metricUnits")}</Typography.Text>
            <Typography.Text>
              {userPreferences.data.metricMeasurementUnits ? WebHelper.formatMessage("Common-yes") : WebHelper.formatMessage("Common-no")}
            </Typography.Text>
          </Space>
          <Space className={styles.detailSpace}>
            <Typography.Text>{WebHelper.formatMessage("AccountSettings-preferredLanguage")}</Typography.Text>
            <Typography.Text>{userLanguage()}</Typography.Text>
          </Space>
        </>
      </div>
    </Drawer>
  );
});
