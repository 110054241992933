import {Space, Typography} from "antd";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import styles from "./Maintenance.module.scss";
import companyIcon from "../../../assets/images/common/Verve_Logo_SafetyOrange.svg";

export type MaintenanceProps = {
  children: React.ReactNode;
};

export const Maintenance: FunctionComponent<MaintenanceProps> = observer((props) => {
  const flags = useFlags();

  return flags.maintenance ? (
    <Space align="center" className={styles.space} direction="vertical">
      <img className={styles.companyIcon} src={companyIcon} alt="Company Logo" />
      <Typography.Text className={styles.subtitle}>Sorry, we are down for maintenance.</Typography.Text>
      <Typography.Text>Please check back soon.</Typography.Text>
    </Space>
  ) : (
    <>{props.children}</>
  );
});
