import {FileTextOutlined} from "@ant-design/icons";
import {Card, Cascader, Space, Typography} from "antd";
import {addDays, differenceInDays, endOfDay, format, isAfter} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import React, {FunctionComponent, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import styles from "./WorkerSafeliftReportDrawerContent.module.scss";
import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";
import {Button, ButtonType} from "../../common/button/Button";
import {DatePicker} from "../../date-picker/DatePicker";

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
  disabled?: boolean;
}

enum ReportEntityOption {
  allWorkers,
  workerReportGroups,
}

type WorkerSafeliftReportDrawerContentProps = {
  loadingDownload: boolean;
  loadingPreview: boolean;
  message: string;
  onPreview: (endDateFormatted: string) => void;
  tz_location?: string;
  reportGroups?: Models.WorkerReportGroup[] | undefined;
  setSelectedEntity?: (a: any) => void;
};

export const WorkerSafeliftReportDrawerContent: FunctionComponent<WorkerSafeliftReportDrawerContentProps> = ({
  loadingDownload,
  loadingPreview,
  message,
  onPreview,
  tz_location,
  reportGroups,
  setSelectedEntity,
}) => {
  const [endDate, setEndDate] = useState<Date>(addDays(endOfDay(new Date()), -1));
  const {departmentId} = useParams();

  const options: Option[] = [
    {
      value: ReportEntityOption.allWorkers,
      label: WebHelper.formatMessage("ReportDrawerContent-allWorkers"),
    },
    {
      value: ReportEntityOption.workerReportGroups,
      label: WebHelper.formatMessage("ReportDrawerContent-workerReportGroups"),
      disabled: !reportGroups || !(reportGroups.length > 0),
      children: reportGroups
        ? reportGroups.map((group) => {
            return {value: group.id, label: group.name};
          })
        : [],
    },
  ];

  const onChange = (value: any) => {
    if (!setSelectedEntity) return;
    if (value[0] == ReportEntityOption.allWorkers) setSelectedEntity({type: Models.EntityType.Department, id: departmentId});
    if (value[0] == ReportEntityOption.workerReportGroups && value[1])
      setSelectedEntity({type: Models.EntityType.WorkerReportGroup, id: value[1]});
  };

  const {endDateFormatted} = useMemo<{endDateFormatted: string}>(() => {
    const formatDate = (date: Date) =>
      tz_location
        ? fromZonedTime(date, "UTC").toISOString()
        : format(date, WebHelper.formatMessage("Common-dateTimeFormatWithoutTimezone"));

    const endDateFormatted = formatDate(endDate);

    return {endDateFormatted};
  }, [endDate, tz_location]);

  return (
    <Card>
      <Space className={styles.cardContent} direction="vertical" size="large">
        <Typography.Text>
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(message),
            }}
          />
        </Typography.Text>
        {reportGroups && setSelectedEntity && (
          <div className={styles.drawerInputRow}>
            <Typography.Text>{WebHelper.formatMessage("ReportDrawerContent-filterReport")}</Typography.Text>
            <Cascader options={options} defaultValue={[ReportEntityOption.allWorkers]} onChange={onChange} placeholder="Please select" />
          </div>
        )}
        <div className={styles.drawerInputRow}>
          <Typography.Text>{WebHelper.formatMessage("ReportDrawerContent-reportEndDate")}</Typography.Text>
          <DatePicker
            allowClear={false}
            disabled={loadingDownload || loadingPreview}
            value={endDate}
            onChange={(date) => date && setEndDate(endOfDay(date))}
            disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
            dateRender={(current) => {
              const isLastSevenDays = differenceInDays(endDate, current) > 0 && differenceInDays(endDate, current) <= 6;

              return <div className={`ant-picker-cell-inner ${isLastSevenDays && styles.selectedDay}`}>{current.getDate()}</div>;
            }}
          />
        </div>
        <Space className={styles.actionButtons} align="center" size="middle">
          <Button
            shape="round"
            type={ButtonType.Primary}
            icon={<FileTextOutlined />}
            loading={loadingPreview}
            disabled={loadingDownload}
            onClick={() => onPreview(endDateFormatted)}>
            {WebHelper.formatMessage("ReportDrawerContent-generateReportButtonText")}
          </Button>
        </Space>
      </Space>
    </Card>
  );
};
