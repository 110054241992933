import {BellFilled, MoreOutlined, UserOutlined} from "@ant-design/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Dropdown, Avatar, Layout, Typography, Divider, MenuProps, Empty} from "antd";
import {ItemType} from "antd/lib/menu/interface";
import companyIcon from "assets/images/common/Verve_Logo_Green.svg";
import {UserModule} from "core/modules/UserModule";
import {AuthenticationDataStore} from "core/stores/AuthenticationDataStore";
import {format, isToday} from "date-fns";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import styles from "./Header.module.scss";
import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";
import {Button} from "../button/Button";

const {Header: HeaderAntd} = Layout;

export type HeaderProps = {
  currentUser: Models.User | undefined;
  deactivatedUser: boolean;
  accountMenuItems: ItemType[];
};

export const Header: FunctionComponent<HeaderProps> = observer((props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const navigate = useNavigate();
  const flags = useFlags();

  const [unallocatedNotifications, setUnallocatedNotifications] = useState<Models.Notification[][]>([]);

  const notificationsQuery = useQuery({
    queryKey: ["Header-fetchNotifications", unallocatedNotifications],
    queryFn: () => {
      return UserModule.getNotifications({
        accessToken: authenticationStore.state.accessToken!,
        id: props.currentUser!.id,
      });
    },
    enabled: !!props.currentUser && !!flags.device_usage_reassignment_button,
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (!notificationsQuery.data) return;
    if (!notificationsQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("Header-errorMessage"), notificationsQuery.data.correlationId);
      return;
    }
    const filteredNotifications = notificationsQuery.data.notifications.filter(
      (notification) => notification.notification_type === Models.NotificationType.UnassignedSession
    );
    const groupedNotifications = filteredNotifications.reduce((acc, notification) => {
      const date = new Date(notification.created_at).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(notification);
      return acc;
    }, {});
    setUnallocatedNotifications(Object.values(groupedNotifications));
  }, [notificationsQuery.data]);

  const acknowledgeNotificationsMutation = useMutation({
    mutationFn: async (acknowledgeNotifications: {id: string}[]) => {
      return UserModule.acknowledgeNotifications({
        accessToken: authenticationStore.state.accessToken!,
        notification_ids: acknowledgeNotifications!,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("Header-acknowledgeErrorMessage"), response.correlationId);
      }
      setUnallocatedNotifications([]);
    },
  });

  const clearUnallocatedNotifications = (id: number) => {
    acknowledgeNotificationsMutation.mutate(unallocatedNotifications[id].map((notification) => ({id: notification.id})));
  };

  const notificationOptions = [
    {
      label: <div className={styles.clearNotification}>{WebHelper.formatMessage("Header-clearNotifications")}</div>,
      key: "clearUnallocated",
      onclick: clearUnallocatedNotifications,
    },
  ];

  const notificationItems: MenuProps["items"] = [
    {
      key: "title",
      type: "group",
      label: (
        <div className={styles.notificationsTitle}>
          <Typography.Title level={3}>{WebHelper.formatMessage("Header-notifications")}</Typography.Title>
          <Button
            style={{backgroundColor: "#f04a1d", color: "white", borderColor: "unset"}}
            shape="round"
            onClick={() => {
              authenticationStore.isUserGlobal
                ? navigate(`/organizations/notifications`)
                : navigate(`/organizations/${props.currentUser?.organization_id}/notifications`);
            }}>
            <div className={styles.buttonText}>{WebHelper.formatMessage("Header-viewAll")}</div>
          </Button>
        </div>
      ),
    },
  ];

  const onClickClear = ({key}: {key: string}, id: number) => {
    if (key === "clearUnallocated") {
      clearUnallocatedNotifications(id);
    }
  };

  if (unallocatedNotifications && unallocatedNotifications.length > 0) {
    notificationItems.push({
      label: (
        <div>
          <Typography className={styles.typeNotificationText}>{WebHelper.formatMessage("Header-unallocatedData")}</Typography>
          <Divider style={{margin: "0px"}} />
        </div>
      ),
      key: "unallocatedDataTitle",
      type: "group",
    });
    unallocatedNotifications.forEach((notificationsForDay, index) => {
      const uniqueDevicesCount = new Set(notificationsForDay.map((notification) => notification.details.device_id)).size;

      notificationItems.push({
        label: (
          <div
            key={index}
            className={styles.notificationLabel}
            role="button"
            tabIndex={0}
            onClick={() => {
              clearUnallocatedNotifications(index);
              authenticationStore.isUserGlobal
                ? navigate(`/organizations/notifications`)
                : navigate(`/organizations/${props.currentUser?.organization_id}/notifications`);
            }}
            onKeyDown={() => {
              clearUnallocatedNotifications(index);
              authenticationStore.isUserGlobal
                ? navigate(`/organizations/notifications`)
                : navigate(`/organizations/${props.currentUser?.organization_id}/notifications`);
            }}>
            <div className={styles.notificationContainer}>
              <Typography.Text className={styles.text}>
                {uniqueDevicesCount > 1
                  ? WebHelper.formatMessage("Header-unallocatedDataDevices", {devices: uniqueDevicesCount})
                  : WebHelper.formatMessage("Header-unallocatedDataOneDevice")}
              </Typography.Text>
              <div className={styles.grey}>
                {isToday(new Date(notificationsForDay[0].created_at))
                  ? WebHelper.formatMessage("Header-today")
                  : format(new Date(notificationsForDay[0].created_at), WebHelper.formatMessage("Common-dateFormatCommonMonthDayYear"))}
              </div>
            </div>
            <Dropdown
              overlayClassName={styles.notificationOptionsDropdown}
              menu={{items: notificationOptions, onClick: (key) => onClickClear(key, index)}}>
              <MoreOutlined className={styles.icon} />
            </Dropdown>
          </div>
        ),
        key: `unallocatedData-${index}`,
        onClick: () => {
          clearUnallocatedNotifications(index);
          authenticationStore.isUserGlobal
            ? navigate(`/organizations/notifications`)
            : navigate(`/organizations/${props.currentUser?.organization_id}/notifications`);
        },
      });
    });
  } else {
    notificationItems.push({
      label: (
        <div>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={WebHelper.formatMessage("Header-noNotifications")} />
        </div>
      ),
      key: "noNotifications",
      type: "group",
    });
  }

  return (
    <HeaderAntd className={styles.header}>
      <Link className={styles.companyIconContainer} to={WebHelper.homePagePath()}>
        <img className={styles.companyIcon} src={companyIcon} alt="Logo" />
      </Link>
      {(props.currentUser || props.deactivatedUser) && (
        <div className={styles.notificationDropdownContainer}>
          {flags.device_usage_reassignment_button && (
            <div className={styles.notifications}>
              <Dropdown
                menu={{items: notificationItems}}
                overlayClassName={styles.notificationDropdown}
                placement="bottomRight"
                trigger={["click"]}>
                <div>
                  <BellFilled
                    className={`${styles.notificationIcon} ${unallocatedNotifications && unallocatedNotifications.length > 0 ? "" : styles.noNotifications}`}
                  />
                  {unallocatedNotifications && unallocatedNotifications.length > 0 && (
                    <div className={styles.notificationBadge}>
                      {unallocatedNotifications.length < 9 ? (
                        <div className={styles.number}>{unallocatedNotifications.length}</div>
                      ) : (
                        <div className={`${styles.number} ${styles.numberPlus}`}>9+</div>
                      )}
                    </div>
                  )}
                </div>
              </Dropdown>
            </div>
          )}
          <Dropdown trigger={["click"]} placement="bottomRight" menu={{items: props.accountMenuItems}}>
            <div className={styles.avatarContainer} data-cy="accountMenuButton">
              <Avatar className={styles.avatar} icon={<UserOutlined />} />
            </div>
          </Dropdown>
        </div>
      )}
    </HeaderAntd>
  );
});
