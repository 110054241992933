import {DownOutlined, LineOutlined, UpOutlined} from "@ant-design/icons";
import {Space, Typography, Popover, Progress, Tooltip} from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip as TooltipChartJS,
  Legend,
  ChartData,
  CoreChartOptions,
  ScaleChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  PluginOptionsByType,
} from "chart.js";
import ChartAnnotation from "chartjs-plugin-annotation";
import {WorkerSafeliftReport} from "core/models";
import {addDays} from "date-fns";
import {useFlags} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent} from "react";
import {Bar} from "react-chartjs-2";
import useUserPreferences from "web/hooks/useUserPreferences";
import {lbsToKgs} from "web/utils/WebHelper";

import {getDateLocaleFromPreferredLanguage, localizeSafeliftText} from "./WorkerSafeliftReportLocales";
import styles from "./WorkerSafeliftReportPDF.module.scss";
import companyIcon from "../../../../assets/images/common/Verve_Logo_SafetyOrange.png";
import ExcessiveTorsoBendingBadImg from "../../../../assets/images/worker_report/Excessive_Torso_Bending_BAD.png";
import ExcessiveTorsoBendingGoodImg from "../../../../assets/images/worker_report/Excessive_Torso_Bending_GOOD.png";
import ProlongedBendingBadImg from "../../../../assets/images/worker_report/Prolonged_Bending_BAD.png";
import ProlongedBendingGoodImg from "../../../../assets/images/worker_report/Prolonged_Bending_GOOD.png";
import SideBendingBadImg from "../../../../assets/images/worker_report/Side_Bending_BAD.png";
import SideBendingGoodImg from "../../../../assets/images/worker_report/Side_Bending_GOOD.png";
import TwistingBadImg from "../../../../assets/images/worker_report/Twisting_BAD.png";
import TwistingGoodImg from "../../../../assets/images/worker_report/Twisting_GOOD.png";
import * as Models from "../../../../core/models";

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, TooltipChartJS, Legend);
ChartJS.register(ChartAnnotation);

interface WeekHoursTooltipContentProps {
  children: React.ReactNode;
}

interface AnnotationOptions {
  annotation: {
    annotations: {
      [key: string]: {
        type: "line";
        yMin: number;
        yMax: number;
        borderColor: string;
        borderWidth: number;
        borderDash: number[];
      };
    };
  };
}

type PartialPluginOptions = Partial<PluginOptionsByType<"bar">>;

type CombinedPluginOptions = PartialPluginOptions & AnnotationOptions;

// Define the extended options type
type ExtendedOptions = CoreChartOptions<"bar"> &
  ElementChartOptions<"bar"> &
  PluginChartOptions<"bar"> &
  DatasetChartOptions<"bar"> &
  ScaleChartOptions<"bar"> & {plugins: CombinedPluginOptions};

type WorkerSafeliftReportPDFProps = {
  reportData: WorkerSafeliftReport;
};

const calculateWeeksDifference = (date: Date): number => {
  const currentDate = new Date();

  const differenceInTime = currentDate.getTime() - date.getTime();

  return Math.floor(differenceInTime / (1000 * 3600 * 24) / 7);
};

const formatDate = (date: Date, lang: string) =>
  date.toLocaleDateString(getDateLocaleFromPreferredLanguage(lang), {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

const formatLabelDate = (date: Date, lang: string) =>
  date.toLocaleDateString(getDateLocaleFromPreferredLanguage(lang), {
    day: "2-digit",
    month: "2-digit",
  });

const formatNumber = (num: number, decimals = 0) =>
  num.toLocaleString("en-US", {style: "decimal", maximumFractionDigits: decimals}) == "NaN" || Math.abs(num) === 0
    ? 0
    : num.toLocaleString("en-US", {style: "decimal", maximumFractionDigits: decimals});

export const WorkerSafeliftReportPDF: FunctionComponent<WorkerSafeliftReportPDFProps> = ({reportData}) => {
  const [userPreferences] = useUserPreferences();
  const weeklyDataToShow = reportData.weekly_data;

  const flags = useFlags();

  while (weeklyDataToShow.length > 0 && weeklyDataToShow[0].usage_sec == 0) weeklyDataToShow.shift();

  const data = weeklyDataToShow.map((item) =>
    Number((flags.new_safelift_report ? item.safe_lift_percentile : (item.safe_lifts / item.total_lifts) * 100).toFixed(1))
  );
  const labels = weeklyDataToShow.map(
    (item) =>
      `${formatLabelDate(new Date(item.start_time), reportData.worker_preferred_language)} - ${formatLabelDate(
        addDays(new Date(item.end_time), -1),
        reportData.worker_preferred_language
      )}`
  );

  const chartData: ChartData<"bar", number[], string> = {
    labels,
    datasets: [
      {
        type: "bar",
        label: localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-safeLifts"),
        data: data,
        maxBarThickness: 30,
        backgroundColor: "rgba(80, 209, 0, 0.4)",
        borderColor: "rgba(80, 209, 0, 0.4)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    // Disabled all animations due to an issue when iterating through thw workers
    // Sometimes it takes the image in mid animation and the data shown in the bars is wrong
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: reportData.department_average_safe_lift_score_24_weeks,
            yMax: reportData.department_average_safe_lift_score_24_weeks,
            borderColor: "black",
            borderWidth: 1,
            borderDash: [6, 6],
          },
          label1: {
            type: "label",
            xValue: labels[labels.length - 1],
            yValue: reportData.department_average_safe_lift_score_24_weeks,
            content: localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-deptAvg"),
            backgroundColor: "rgba(0,0,0,0)",
            color: "black",
            font: {
              size: 12,
            },
            xAdjust: -25,
            yAdjust: 10,
          },
        },
      },
    },
  } as unknown as ExtendedOptions; // Type assertion since it's throwing an error but the chart is working properly

  const WeekHoursPopover: React.FC<WeekHoursTooltipContentProps> = ({children}) => {
    const dayLabels = [
      localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-sundayShort"),
      localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-mondayShort"),
      localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-tuesdayShort"),
      localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-wednesdayShort"),
      localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-thursdayShort"),
      localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-fridayShort"),
      localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-saturdayShort"),
    ];

    return (
      <Popover
        placement="top"
        overlayClassName={styles.popoverOuter}
        content={
          <div className={styles.weekHoursTooltipContent}>
            {reportData.daily_data.map((dayData, i) => (
              <div className={styles.tooltipweekDayItem}>
                <Typography.Text>{dayLabels[new Date(dayData.start_time).getDay()]}</Typography.Text>
                <Typography.Text>{formatNumber(dayData.usage_sec / 3600, 1)}</Typography.Text>
              </div>
            ))}
          </div>
        }>
        {children}
      </Popover>
    );
  };

  const renderChangeTooltipText = (percent: number) => {
    return `${localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-change")}: ${formatNumber(percent, 1)} %`;
  };

  const renderBarVersusText = (affectedLifts: number) => {
    return (
      <Space direction="vertical">
        <Typography.Text className={styles.whiteText}>
          Safe Lifts: {formatNumber(reportData.one_week_summary_metrics.total_lifts - affectedLifts)}
        </Typography.Text>
        <Typography.Text className={styles.whiteText}>Risky Lifts: {formatNumber(affectedLifts)}</Typography.Text>
      </Space>
    );
  };

  const weeksDifference = reportData.overall_summary_metrics.first_date_active
    ? calculateWeeksDifference(reportData.overall_summary_metrics.first_date_active)
    : 1;

  const trendIcon = (value: number) =>
    value > 0.5 ? (
      <UpOutlined style={{color: "rgba(80, 209, 0, 1)"}} />
    ) : value < 0.5 ? (
      <DownOutlined style={{color: "red"}} />
    ) : (
      <LineOutlined />
    );

  const trendProgressIcon = (value: number) => (value > 0.5 ? trendIcon(1) : value < -0.5 ? trendIcon(0) : trendIcon(0.5));

  const progressPercentages = {
    excessive_forward_lifts:
      reportData.one_week_summary_metrics.total_lifts > 0
        ? Math.round(
            100 * (1 - reportData.one_week_summary_metrics.excessive_forward_lifts / reportData.one_week_summary_metrics.total_lifts)
          )
        : 0,
    prolonged_bend_lifts:
      reportData.one_week_summary_metrics.total_lifts > 0
        ? Math.round(100 * (1 - reportData.one_week_summary_metrics.prolonged_bend_lifts / reportData.one_week_summary_metrics.total_lifts))
        : 0,
    twisted_lifts:
      reportData.one_week_summary_metrics.total_lifts > 0
        ? Math.round(100 * (1 - reportData.one_week_summary_metrics.twisted_lifts / reportData.one_week_summary_metrics.total_lifts))
        : 0,
    side_bend_lifts:
      reportData.one_week_summary_metrics.total_lifts > 0
        ? Math.round(100 * (1 - reportData.one_week_summary_metrics.side_bend_lifts / reportData.one_week_summary_metrics.total_lifts))
        : 0,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.workerInfo}>
          <Typography.Title level={1}>{reportData.fullName}</Typography.Title>
          <Typography.Text>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-employee")} {reportData.worker_employee_id}
          </Typography.Text>
          <Typography.Text>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-currentDevice")} {reportData.device_tag}
          </Typography.Text>
          <Typography.Text>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-dateRange", {
              startDate: formatDate(reportData.start_time, reportData.worker_preferred_language),
              endDate: formatDate(addDays(reportData.end_time, -1), reportData.worker_preferred_language),
            })}
          </Typography.Text>
        </div>
        <img className={styles.companyIcon} src={companyIcon} alt="Company Logo" />
      </div>
      <div className={styles.topGrid}>
        <div className={styles.poundsOffloaded}>
          <Typography.Title level={4} className={styles.typgraphyLevel4}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-weightOffloaded")}
          </Typography.Title>
          <Typography.Title level={1} className={styles.typgraphyLevel1}>
            {formatNumber(
              userPreferences.data.metricMeasurementUnits
                ? lbsToKgs(reportData.one_week_summary_metrics.weight_offloaded_lbs)
                : reportData.one_week_summary_metrics.weight_offloaded_lbs
            )}{" "}
            {localizeSafeliftText(
              reportData.worker_preferred_language,
              userPreferences.data.metricMeasurementUnits ? "WorkerSafeliftReportPDF-kilogramsUnit" : "WorkerSafeliftReportPDF-poundsUnit"
            )}
          </Typography.Title>

          {reportData.one_week_summary_metrics.weight_offloaded_ranking < 6 && (
            <Space direction="horizontal">
              <Typography.Title level={3} className={styles.typgraphyLevel3}>
                #{formatNumber(reportData.one_week_summary_metrics.weight_offloaded_ranking)}
              </Typography.Title>
              <Typography.Text className={styles.typgraphyLevel4}>
                {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-onLeaderboard")}
              </Typography.Text>
            </Space>
          )}
        </div>
        <div className={styles.graph}>
          <div className={styles.graphHeaderInfo} style={flags.new_safelift_report ? {gridTemplateColumns: "10fr 5fr 12fr 10fr"} : {}}>
            <Space direction="horizontal" className={styles.safeliftsText}>
              <Typography.Title level={4} className={styles.typgraphyLevel3}>
                {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-safeLifts")}
              </Typography.Title>
            </Space>
            <Tooltip
              placement="bottom"
              title={renderChangeTooltipText(
                flags.new_safelift_report
                  ? reportData.one_week_summary_metrics.safe_lift_percentile_change
                  : reportData.one_week_summary_metrics.safe_lift_score_change
              )}>
              <Space
                direction={flags.new_safelift_report ? "vertical" : "horizontal"}
                className={flags.new_safelift_report ? styles.trend : undefined}>
                <Typography.Text strong className={styles.text}>
                  {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-trend")}{" "}
                </Typography.Text>
                {trendProgressIcon(
                  flags.new_safelift_report
                    ? reportData.one_week_summary_metrics.safe_lift_percentile_change
                    : reportData.one_week_summary_metrics.safe_lift_score_change
                )}
              </Space>
            </Tooltip>
            {flags.new_safelift_report ? (
              <div className={styles.scores}>
                <Space direction="horizontal">
                  <Typography.Text strong className={styles.text}>
                    {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-currentScore")}
                  </Typography.Text>
                  <Typography.Text className={styles.scoreNumber}>
                    {reportData.weekly_data.length > 0 ? formatNumber(reportData.one_week_summary_metrics.safe_lift_percentile) : 0}
                  </Typography.Text>
                </Space>
                <Space direction="horizontal">
                  <Typography.Text strong className={styles.text}>
                    {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-deptAvg")}
                  </Typography.Text>
                  <Typography.Text className={styles.scoreNumber}>
                    {formatNumber(reportData.department_average_safe_lift_percentile_24_weeks)}
                  </Typography.Text>
                </Space>
              </div>
            ) : (
              <Space direction="horizontal">
                <Typography.Text strong className={styles.text}>
                  {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-currentScore")}
                </Typography.Text>
                {reportData.weekly_data.length > 0
                  ? formatNumber(reportData.weekly_data[reportData.weekly_data.length - 1].safe_lift_score)
                  : 0}
              </Space>
            )}
            {flags.new_safelift_report ? (
              <Space direction="horizontal">
                <Typography.Text strong className={styles.textCentered}>
                  {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-saferText", {
                    score: reportData.weekly_data.length > 0 ? formatNumber(reportData.one_week_summary_metrics.safe_lift_percentile) : 0,
                  })}
                </Typography.Text>
              </Space>
            ) : (
              <Space direction="horizontal">
                <Typography.Text strong className={styles.text}>
                  {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-deptAvg")}
                </Typography.Text>
                {formatNumber(reportData.department_average_safe_lift_score_24_weeks)}
              </Space>
            )}
          </div>
          <div className={styles.chart}>
            <Bar data={chartData} options={options} height={100} />
          </div>
          {reportData.one_week_summary_metrics.safe_lift_ranking < 6 && (
            <div className={styles.graphBottomSafeliftRanking}>
              <Typography.Title level={3} className={styles.typgraphyLevel3}>
                #{formatNumber(reportData.one_week_summary_metrics.safe_lift_ranking)}
              </Typography.Title>{" "}
              <Typography.Text className={styles.text}>
                {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-onLeaderboard")}
              </Typography.Text>
            </div>
          )}
        </div>
        <WeekHoursPopover
          children={
            <div className={styles.miniData1}>
              <Typography.Text strong className={styles.typgraphyLevel3}>
                {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-hoursWorn")}
              </Typography.Text>
              {flags.target_utilization_hours &&
              reportData.one_week_summary_metrics.on_target !== Models.OnTarget.Exempt &&
              reportData.one_week_summary_metrics.on_target !== Models.OnTarget.Unset &&
              reportData.one_week_summary_metrics.on_target !== Models.OnTarget.Unenrolled ? (
                <div className={styles.hoursTarget}>
                  <Typography.Title level={3} className={styles.typgraphyLevel3}>
                    {formatNumber(reportData.one_week_summary_metrics.hours_used, 1)}
                  </Typography.Title>
                  <Typography.Title level={3} className={styles.typgraphyLevel3}>
                    /{reportData.one_week_summary_metrics.lower_bound}
                  </Typography.Title>
                </div>
              ) : (
                <Typography.Title level={2} className={styles.typgraphyLevel2}>
                  {formatNumber(reportData.one_week_summary_metrics.hours_used, 1)}
                </Typography.Title>
              )}
            </div>
          }
        />

        <WeekHoursPopover
          children={
            <div className={styles.miniData2}>
              <Typography.Text strong>
                {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-daysWorn")}
              </Typography.Text>
              <Typography.Title level={2} className={styles.typgraphyLevel2}>
                {reportData.daily_data.filter((dayData) => dayData.usage_sec > 0).length}
              </Typography.Title>
            </div>
          }
        />
      </div>
      {flags.new_safelift_report ? (
        <>
          <Typography.Title level={3} className={styles.typgraphyLevel3}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-movementBreakdown")}
          </Typography.Title>
          <div className={styles.barsTitles}>
            <Typography.Text strong>
              {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-negative")}
            </Typography.Text>
            <Typography.Text strong>
              {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-positive")}
            </Typography.Text>
            <Typography.Text strong>
              {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-trend")}
            </Typography.Text>
          </div>
        </>
      ) : (
        <div className={styles.barsTitles}>
          <Typography.Text strong>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-positive")}
          </Typography.Text>
          <Typography.Text strong>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-negative")}
          </Typography.Text>
          <Typography.Text strong>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-trend")}
          </Typography.Text>
        </div>
      )}
      <div className={styles.barsWrapper}>
        {flags.new_safelift_report ? (
          <img src={ExcessiveTorsoBendingBadImg} alt="ExcessiveTorsoBendingBadImg" />
        ) : (
          <img src={ExcessiveTorsoBendingGoodImg} alt="ExcessiveTorsoBendingGoodImg" />
        )}
        {!flags.new_safelift_report && (
          <Typography.Text className={styles.progressText}>
            {formatNumber(
              100 * (1 - reportData.one_week_summary_metrics.excessive_forward_lifts / reportData.one_week_summary_metrics.total_lifts)
            )}
          </Typography.Text>
        )}
        <div className={styles.progress}>
          <Typography.Text className={styles.text}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-excessiveForwardLifts")}
          </Typography.Text>
          <Tooltip placement="bottom" title={renderBarVersusText(reportData.one_week_summary_metrics.excessive_forward_lifts)}>
            {flags.new_safelift_report ? (
              <div className={styles.progressContainer}>
                <Progress
                  style={flags.new_safelift_report ? {width: "85%"} : {width: "100%"}}
                  size={flags.new_safelift_report ? ["100%", 20] : undefined}
                  steps={flags.new_safelift_report ? undefined : 20}
                  strokeColor="rgba(80, 209, 0, 1)"
                  showInfo={false}
                  strokeLinecap="butt"
                  percent={progressPercentages.excessive_forward_lifts}
                  className={styles.progressBar}
                />
                <div
                  className={styles.percentText}
                  style={{
                    left: `calc(8% + ${progressPercentages.excessive_forward_lifts * 0.84}%)`,
                  }}>
                  {progressPercentages.excessive_forward_lifts}
                </div>
              </div>
            ) : (
              <Progress
                style={{width: "100%"}}
                steps={20}
                strokeColor="rgba(80, 209, 0, 1)"
                showInfo={false}
                percent={progressPercentages.excessive_forward_lifts}
              />
            )}
          </Tooltip>
        </div>
        {!flags.new_safelift_report && (
          <Typography.Text className={styles.progressText}>
            {formatNumber(
              100 * (reportData.one_week_summary_metrics.excessive_forward_lifts / reportData.one_week_summary_metrics.total_lifts)
            )}
          </Typography.Text>
        )}
        {flags.new_safelift_report ? (
          <img src={ExcessiveTorsoBendingGoodImg} alt="ExcessiveTorsoBendingGoodImg" />
        ) : (
          <img src={ExcessiveTorsoBendingBadImg} alt="ExcessiveTorsoBendingBadImg" />
        )}
        <Tooltip
          placement="bottom"
          title={renderChangeTooltipText(reportData.one_week_summary_metrics.excessive_forward_lift_score_change)}>
          <div className={styles.trendIcon}>
            {trendProgressIcon(reportData.one_week_summary_metrics.excessive_forward_lift_score_change)}
          </div>
        </Tooltip>
      </div>
      <div className={styles.barsWrapper}>
        {flags.new_safelift_report ? (
          <img src={TwistingBadImg} alt="TwistingBadImg" />
        ) : (
          <img src={TwistingGoodImg} alt="TwistingGoodImg" />
        )}
        {!flags.new_safelift_report && (
          <Typography.Text className={styles.progressText}>
            {formatNumber(100 * (1 - reportData.one_week_summary_metrics.twisted_lifts / reportData.one_week_summary_metrics.total_lifts))}
          </Typography.Text>
        )}
        <div className={styles.progress}>
          <Typography.Text className={styles.text}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-twisting")}
          </Typography.Text>
          <Tooltip placement="bottom" title={renderBarVersusText(reportData.one_week_summary_metrics.twisted_lifts)}>
            {flags.new_safelift_report ? (
              <div className={styles.progressContainer}>
                <Progress
                  style={flags.new_safelift_report ? {width: "85%"} : {width: "100%"}}
                  size={flags.new_safelift_report ? ["100%", 20] : undefined}
                  steps={flags.new_safelift_report ? undefined : 20}
                  strokeColor="rgba(80, 209, 0, 1)"
                  showInfo={false}
                  strokeLinecap="butt"
                  percent={progressPercentages.twisted_lifts}
                  className={styles.progressBar}
                />
                <div
                  className={styles.percentText}
                  style={{
                    left: `calc(8% + ${progressPercentages.twisted_lifts * 0.84}%)`,
                  }}>
                  {progressPercentages.twisted_lifts}
                </div>
              </div>
            ) : (
              <Progress
                style={{width: "100%"}}
                steps={20}
                strokeColor="rgba(80, 209, 0, 1)"
                showInfo={false}
                percent={progressPercentages.twisted_lifts}
              />
            )}
          </Tooltip>
        </div>
        {!flags.new_safelift_report && (
          <Typography.Text className={styles.progressText}>
            {formatNumber(100 * (reportData.one_week_summary_metrics.twisted_lifts / reportData.one_week_summary_metrics.total_lifts))}
          </Typography.Text>
        )}
        {flags.new_safelift_report ? (
          <img src={TwistingGoodImg} alt="TwistingGoodImg" />
        ) : (
          <img src={TwistingBadImg} alt="TwistingBadImg" />
        )}
        <Tooltip placement="bottom" title={renderChangeTooltipText(reportData.one_week_summary_metrics.twisted_lift_score_change)}>
          <div className={styles.trendIcon}>{trendProgressIcon(reportData.one_week_summary_metrics.twisted_lift_score_change)}</div>
        </Tooltip>
      </div>
      <div className={styles.barsWrapper}>
        {flags.new_safelift_report ? (
          <img src={SideBendingBadImg} alt="SideBendingBadImg" />
        ) : (
          <img src={SideBendingGoodImg} alt="SideBendingGoodImg" />
        )}
        {!flags.new_safelift_report && (
          <Typography.Text className={styles.progressText}>
            {formatNumber(
              100 * (1 - reportData.one_week_summary_metrics.side_bend_lifts / reportData.one_week_summary_metrics.total_lifts)
            )}
          </Typography.Text>
        )}
        <div className={styles.progress}>
          <Typography.Text className={styles.text}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-sideBending")}
          </Typography.Text>
          <Tooltip placement="bottom" title={renderBarVersusText(reportData.one_week_summary_metrics.side_bend_lifts)}>
            {flags.new_safelift_report ? (
              <div className={styles.progressContainer}>
                <Progress
                  style={flags.new_safelift_report ? {width: "85%"} : {width: "100%"}}
                  size={flags.new_safelift_report ? ["100%", 20] : undefined}
                  steps={flags.new_safelift_report ? undefined : 20}
                  strokeColor="rgba(80, 209, 0, 1)"
                  showInfo={false}
                  strokeLinecap="butt"
                  percent={progressPercentages.side_bend_lifts}
                  className={styles.progressBar}
                />
                <div
                  className={styles.percentText}
                  style={{
                    left: `calc(8% + ${progressPercentages.side_bend_lifts * 0.84}%)`,
                  }}>
                  {progressPercentages.side_bend_lifts}
                </div>
              </div>
            ) : (
              <Progress
                style={{width: "100%"}}
                steps={20}
                strokeColor="rgba(80, 209, 0, 1)"
                showInfo={false}
                percent={progressPercentages.side_bend_lifts}
              />
            )}
          </Tooltip>
        </div>
        {!flags.new_safelift_report && (
          <Typography.Text className={styles.progressText}>
            {formatNumber(100 * (reportData.one_week_summary_metrics.side_bend_lifts / reportData.one_week_summary_metrics.total_lifts))}
          </Typography.Text>
        )}
        {flags.new_safelift_report ? (
          <img src={SideBendingGoodImg} alt="SideBendingGoodImg" />
        ) : (
          <img src={SideBendingBadImg} alt="SideBendingBadImg" />
        )}
        <Tooltip placement="bottom" title={renderChangeTooltipText(reportData.one_week_summary_metrics.side_bend_lift_score_change)}>
          <div className={styles.trendIcon}>{trendProgressIcon(reportData.one_week_summary_metrics.side_bend_lift_score_change)}</div>
        </Tooltip>
      </div>
      <div className={styles.barsWrapper}>
        {flags.new_safelift_report ? (
          <img src={ProlongedBendingBadImg} alt="ProlongedBendingBadImg" />
        ) : (
          <img src={ProlongedBendingGoodImg} alt="ProlongedBendingGoodImg" />
        )}
        {!flags.new_safelift_report && (
          <Typography.Text className={styles.progressText}>
            {formatNumber(
              100 * (1 - reportData.one_week_summary_metrics.prolonged_bend_lifts / reportData.one_week_summary_metrics.total_lifts)
            )}
          </Typography.Text>
        )}
        <div className={styles.progress}>
          <Typography.Text className={styles.text}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-prolongedBending")}
          </Typography.Text>
          <Tooltip placement="bottom" title={renderBarVersusText(reportData.one_week_summary_metrics.prolonged_bend_lifts)}>
            {flags.new_safelift_report ? (
              <div className={styles.progressContainer}>
                <Progress
                  style={flags.new_safelift_report ? {width: "85%"} : {width: "100%"}}
                  size={flags.new_safelift_report ? ["100%", 20] : undefined}
                  steps={flags.new_safelift_report ? undefined : 20}
                  strokeColor="rgba(80, 209, 0, 1)"
                  showInfo={false}
                  strokeLinecap="butt"
                  percent={progressPercentages.prolonged_bend_lifts}
                  className={styles.progressBar}
                />
                <div
                  className={styles.percentText}
                  style={{
                    left: `calc(8% + ${progressPercentages.prolonged_bend_lifts * 0.84}%)`,
                  }}>
                  {progressPercentages.prolonged_bend_lifts}
                </div>
              </div>
            ) : (
              <Progress
                style={{width: "100%"}}
                steps={20}
                strokeColor="rgba(80, 209, 0, 1)"
                showInfo={false}
                percent={progressPercentages.prolonged_bend_lifts}
              />
            )}
          </Tooltip>
        </div>
        {!flags.new_safelift_report && (
          <Typography.Text className={styles.progressText}>
            {formatNumber(
              100 * (reportData.one_week_summary_metrics.prolonged_bend_lifts / reportData.one_week_summary_metrics.total_lifts)
            )}
          </Typography.Text>
        )}
        {flags.new_safelift_report ? (
          <img src={ProlongedBendingGoodImg} alt="ProlongedBendingGoodImg" />
        ) : (
          <img src={ProlongedBendingBadImg} alt="ProlongedBendingBadImg" />
        )}
        <Tooltip placement="bottom" title={renderChangeTooltipText(reportData.one_week_summary_metrics.prolonged_bend_lift_score_change)}>
          <div className={styles.trendIcon}>{trendProgressIcon(reportData.one_week_summary_metrics.prolonged_bend_lift_score_change)}</div>
        </Tooltip>
      </div>
      <Space direction="horizontal" align="end">
        <Typography.Title level={3} className={styles.typgraphyLevel3}>
          {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-summaryMetrics")}
        </Typography.Title>{" "}
        {reportData.overall_summary_metrics.first_date_active && (
          <Tooltip
            placement="bottom"
            title={`
            ${localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-startDay", {
              date: formatDate(reportData.overall_summary_metrics.first_date_active, reportData.worker_preferred_language),
            })}`}>
            <Typography.Text className={styles.text}>
              {" "}
              {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-weeksInProgram", {
                weekQty: weeksDifference === 0 ? 1 : weeksDifference,
              })}
            </Typography.Text>
          </Tooltip>
        )}
      </Space>

      <div className={styles.BottomMetrics}>
        <div>
          <Typography.Text strong className={styles.text}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-totalHoursWorn")}
          </Typography.Text>
          <Typography.Title level={2} className={styles.typgraphyLevel2}>
            {formatNumber(reportData.overall_summary_metrics.total_hours_used)}
          </Typography.Title>
        </div>
        <div>
          <Typography.Text strong className={styles.text}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-totalDaysWorn")}
          </Typography.Text>
          <Typography.Title level={2} className={styles.typgraphyLevel2}>
            {formatNumber(reportData.overall_summary_metrics.total_days_used)}
          </Typography.Title>
        </div>
        <div>
          <Typography.Text strong className={styles.text}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-totalWeightOffloaded")}
          </Typography.Text>
          <Typography.Title level={2} className={styles.typgraphyLevel2}>
            {formatNumber(
              userPreferences.data.metricMeasurementUnits
                ? lbsToKgs(reportData.overall_summary_metrics.total_weight_offloaded_lbs)
                : reportData.overall_summary_metrics.total_weight_offloaded_lbs
            )}{" "}
            {localizeSafeliftText(
              reportData.worker_preferred_language,
              userPreferences.data.metricMeasurementUnits ? "WorkerSafeliftReportPDF-kilogramsUnit" : "WorkerSafeliftReportPDF-poundsUnit"
            )}
          </Typography.Title>
        </div>
        <div>
          <Typography.Text strong className={styles.text}>
            {localizeSafeliftText(reportData.worker_preferred_language, "WorkerSafeliftReportPDF-changeInSafeliftScore")}
          </Typography.Text>
          <Typography.Title level={2} className={styles.typgraphyLevel2}>
            {reportData.overall_summary_metrics.safe_lift_score_change > 0 ? "+" : ""}
            {formatNumber(
              flags.new_safelift_report
                ? reportData.overall_summary_metrics.safe_lift_percentile_change
                : reportData.overall_summary_metrics.safe_lift_score_change
            )}
          </Typography.Title>
        </div>
      </div>
    </div>
  );
};
