import {useMutation, useQuery} from "@tanstack/react-query";
import {Modal, Space} from "antd";
import {RcFile} from "antd/lib/upload";
import {parse} from "papaparse";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";

import {
  AllocationError,
  AllocationErrorType,
  AllocationFailed,
  SerialNumberError,
  SerialNumberErrorType,
} from "./allocation-failed/AllocationFailed";
import {CSVDeviceAllocation} from "./csv-device-allocation/CSVDeviceAllocation";
import {ManualDeviceAllocation} from "./manual-device-allocation/ManualDeviceAllocation";
import * as Models from "../../../../../core/models";
import {DeviceModule} from "../../../../../core/modules/DeviceModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../common/button/Button";

export enum DeviceAllocationMode {
  CSV = "csv",
  Manual = "manual",
}

type DeviceAllocationModalProps = {
  mode: DeviceAllocationMode | false;
  department: Models.Department;
  onOk: () => void;
  onCancel: () => void;
};

export const DeviceAllocationModal: FunctionComponent<DeviceAllocationModalProps> = ({mode, department, onOk, onCancel}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [availableDevices, setAvailableDevices] = useState<Models.DeviceShort[]>([]);
  const [devicesIdsToAllocate, setDevicesIdsToAllocate] = useState<string[]>([]);
  const [allocationError, setAllocationError] = useState<AllocationError | null>(null);
  const [CSVFileName, setCSVFileName] = useState<string | null>(null);
  const [showAllocationError, setShowAllocationError] = useState(false);

  useEffect(() => {
    appStore.hideAllMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const devicesQuery = useQuery({
    queryKey: ["DeviceAllocationModal-fetchDevicesData"],
    queryFn: () => DeviceModule.devicesShort({accessToken: authenticationStore.state.accessToken!, unallocated: true}),
    enabled: mode !== false,
  });

  useEffect(() => {
    if (!devicesQuery.data) return;

    if (!devicesQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("DeviceAllocationModal-errorMessage"), devicesQuery.data.correlationId);
      return;
    }

    setAvailableDevices(devicesQuery.data.devices.filter((device) => !device.archived_at));
  }, [devicesQuery.data]);

  const {mutate: allocateDevicesToDepartmentMutate, isPending: allocateDevicesToDepartmentIsLoading} = useMutation({
    mutationFn: () => {
      return DeviceModule.allocateDevicesToDepartment({
        accessToken: authenticationStore.state.accessToken!,
        device_ids: devicesIdsToAllocate,
        department_id: department.id,
      });
    },
    onSuccess: (data) => {
      if (!data.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("DeviceAllocationModal-allocateDeviceToDepartmentError"), data.correlationId);
      } else {
        appStore.sendAnalyticTrack(SegmentKey.AllocatedDevices, {
          orgID: department.organization.id,
          siteID: department.site.id,
          departmentID: department.id,
          numberOfDevices: data.devices.length,
        });
        if (mode === DeviceAllocationMode.Manual) {
          appStore.showMessage(
            WebHelper.formatMessage("DeviceAllocationModal-manualDeviceAllocationSuccessfulMessage", {
              department: department.name,
            }),
            "success"
          );
        }
        if (mode === DeviceAllocationMode.CSV) {
          appStore.showMessage(WebHelper.formatMessage("DeviceAllocationModal-CSVDeviceAllocationSuccessfulMessage"), "success");
        }
        handleClean();
        onOk();
      }
    },
  });

  const onUpload = useCallback(
    (file: RcFile) => {
      setCSVFileName(file.name);
      parse<{system_serial_number: string}>(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (headerText) => {
          if (headerText === WebHelper.formatMessage("CSVDeviceAllocation-serialNumberColumnName")) {
            return "system_serial_number";
          }
          return headerText;
        },
        complete: ({data, meta}) => {
          if (data.length === 0) {
            setAllocationError({type: AllocationErrorType.NoRows});
          } else if (!meta.fields?.find((field) => field === "system_serial_number")) {
            setAllocationError({type: AllocationErrorType.MissingColumn});
          } else if (data.find((row) => !row.system_serial_number)) {
            setAllocationError({type: AllocationErrorType.EmptyRows});
          } else {
            const devicesIdsToAllocate: Set<string> = new Set();
            const serialNumbersWithErrors: SerialNumberError[] = [];

            data
              .map((row) => row.system_serial_number)
              .forEach((serialNumber) => {
                const device = devicesQuery.data?.devices?.find((device) => device.system_serial_number === serialNumber);
                if (!device) {
                  serialNumbersWithErrors.push({type: SerialNumberErrorType.NotFound, serialNumber});
                  return;
                }
                if (device.department_id === department.id) {
                  serialNumbersWithErrors.push({type: SerialNumberErrorType.AlreadyAllocatedToCurrent, serialNumber});
                  return;
                }
                if (device.department_id) {
                  serialNumbersWithErrors.push({type: SerialNumberErrorType.AlreadyAllocatedToOther, serialNumber});
                  return;
                }
                devicesIdsToAllocate.add(device.id);
              });

            if (serialNumbersWithErrors.length !== 0) {
              setAllocationError({type: AllocationErrorType.SerialNumbersWithErrors, serialNumbersWithErrors});
            }

            setDevicesIdsToAllocate(Array.from(devicesIdsToAllocate));
          }
        },
      });

      return false;
    },
    [department.id, devicesQuery.data?.devices]
  );

  const handleClean = () => {
    setCSVFileName(null);
    setShowAllocationError(false);
    setAllocationError(null);
    setDevicesIdsToAllocate([]);
  };

  const handleRemoveCSVFile = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setCSVFileName(null);
    setDevicesIdsToAllocate([]);
  };

  const content = useMemo(() => {
    switch (mode) {
      case DeviceAllocationMode.Manual:
        return (
          <ManualDeviceAllocation
            department={department}
            availableDevices={availableDevices}
            setDevicesIdsToAllocate={setDevicesIdsToAllocate}
            loading={devicesQuery.isFetching || allocateDevicesToDepartmentIsLoading}
          />
        );
      case DeviceAllocationMode.CSV:
        return (
          <>
            {!showAllocationError && (
              <CSVDeviceAllocation
                siteName={department.site.name}
                departmentName={department.name}
                fileName={CSVFileName}
                onRemoveFile={handleRemoveCSVFile}
                onUpload={onUpload}
              />
            )}
            {showAllocationError && allocationError && (
              <AllocationFailed allocationError={allocationError} departmentName={department.name} siteName={department.site.name} />
            )}
          </>
        );
      default:
        return;
    }
  }, [
    mode,
    department,
    availableDevices,
    devicesQuery.isFetching,
    allocateDevicesToDepartmentIsLoading,
    showAllocationError,
    CSVFileName,
    onUpload,
    allocationError,
  ]);

  const handleAllocation = () => {
    if (!allocationError) {
      allocateDevicesToDepartmentMutate();
    } else setShowAllocationError(true);
  };

  const handleCancel = () => {
    if (allocateDevicesToDepartmentIsLoading) return;
    handleClean();
    onCancel();
  };

  return (
    <Modal
      centered
      open={mode !== false}
      destroyOnClose
      title={WebHelper.formatMessage("DeviceAllocationModal-title")}
      onCancel={handleCancel}
      footer={
        <Space>
          <Button key="back" shape="round" disabled={allocateDevicesToDepartmentIsLoading} onClick={handleCancel}>
            {WebHelper.formatMessage("Common-cancel")}
          </Button>
          <Button
            key="submit"
            type={ButtonType.Primary}
            shape="round"
            loading={allocateDevicesToDepartmentIsLoading}
            disabled={allocationError ? false : devicesIdsToAllocate.length === 0}
            onClick={mode === DeviceAllocationMode.CSV && showAllocationError ? handleClean : handleAllocation}>
            {WebHelper.formatMessage(
              mode === DeviceAllocationMode.Manual
                ? "DeviceAllocationModal-manualDeviceAllocationOkButtonText"
                : !showAllocationError
                  ? "DeviceAllocationModal-CVSDeviceAllocationButtonText"
                  : "Common-tryAgain"
            )}
          </Button>
        </Space>
      }>
      {content}
    </Modal>
  );
};
