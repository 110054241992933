import {WorkerReportGroupAPIClient} from "../apiclient/workerreportgroup/WorkerReportGroupAPIClient";
import * as WorkerReportGroupTypes from "../apiclient/workerreportgroup/WorkerReportGroupAPIClient.types";

async function workerReportGroups(
  request: WorkerReportGroupTypes.WorkerReportGroupsRequest
): Promise<WorkerReportGroupTypes.WorkerReportGroupsResponse> {
  return await WorkerReportGroupAPIClient.workerReportGroups(request);
}

async function workerReportGroup(
  request: WorkerReportGroupTypes.WorkerReportGroupRequest
): Promise<WorkerReportGroupTypes.WorkerReportGroupResponse> {
  return await WorkerReportGroupAPIClient.workerReportGroup(request);
}

async function updateWorkerReportGroup(
  request: WorkerReportGroupTypes.UpdateWorkerReportGroupRequest
): Promise<WorkerReportGroupTypes.UpdateWorkerReportGroupResponse> {
  return await WorkerReportGroupAPIClient.updateWorkerReportGroup(request);
}

async function deleteWorkerReportGroup(
  request: WorkerReportGroupTypes.DeleteWorkerReportGroupRequest
): Promise<WorkerReportGroupTypes.DeleteWorkerReportGroupResponse> {
  return await WorkerReportGroupAPIClient.deleteWorkerReportGroup(request);
}

async function createWorkerReportGroup(
  request: WorkerReportGroupTypes.CreateWorkerReportGroupRequest
): Promise<WorkerReportGroupTypes.CreateWorkerReportGroupResponse> {
  return await WorkerReportGroupAPIClient.createWorkerReportGroup(request);
}

async function modifyWorkerReportGroupWorkers(
  request: WorkerReportGroupTypes.ModifyWorkerReportGroupWorkersRequest
): Promise<WorkerReportGroupTypes.ModifyWorkerReportGroupWorkersResponse> {
  return await WorkerReportGroupAPIClient.modifyWorkerReportGroupWorkers(request);
}

export const WorkerReportGroupModule = {
  workerReportGroups,
  workerReportGroup,
  updateWorkerReportGroup,
  deleteWorkerReportGroup,
  createWorkerReportGroup,
  modifyWorkerReportGroupWorkers,
};
