import {ClockCircleOutlined, EditOutlined, PlaySquareOutlined, MenuOutlined, StarFilled, StarOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Button as AntdButton, Layout, Tabs, Tooltip, Typography} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, ReactNode, useEffect, useMemo, useState, lazy, Suspense} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AnalyticsTabContent from "web/components/entity-details/analytics-tab-content/AnalyticsTabContent";
import WorkersTabContent from "web/components/entity-details/workers-tab-content/WorkersTabContent";

import styles from "./EntityDetails.module.scss";
import * as Models from "../../../../core/models";
import {EntityType} from "../../../../core/models";
import {DepartmentModule} from "../../../../core/modules/DepartmentModule";
import {GatewayModule} from "../../../../core/modules/GatewayModule";
import {OrganizationModule} from "../../../../core/modules/OrganizationModule";
import {SiteModule} from "../../../../core/modules/SiteModule";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {Button, ButtonType} from "../../../components/common/button/Button";
import {PageTitle} from "../../../components/common/page-title/PageTitle";
import {DepartmentGatewaysDetail} from "../../../components/department-gateways-detail/DepartmentGatewaysDetail";
import {DepartmentModal} from "../../../components/department-modal/DepartmentModal";
import {DashboardTabContent} from "../../../components/entity-details/dashboard-tab-content/DashboardTabContent";
import {DevicesTabContent} from "../../../components/entity-details/devices-tab-content/DevicesTabContent";
import {EntityDetailsSubheader} from "../../../components/entity-details-subheader/EntityDetailsSubheader";
import {ErrorScreen} from "../../../components/error-screen/ErrorScreen";
import {Loading} from "../../../components/loading/Loading";
import {NavigationMenu} from "../../../components/navigation-menu/NavigationMenu";
import useUserPreferences from "../../../hooks/useUserPreferences";
import {WebHelper} from "../../../utils/WebHelper";

const LiveCheckOutTabContent = lazy(() => import("web/components/entity-details/live-check-out-tab-content/LiveCheckOutTabContent"));
const WorkerReportGroupsTabContent = lazy(
  () => import("../../../components/entity-details/worker-report-groups-tab-content/WorkerReportGroupsTabContent")
);
const SiteModal = lazy(() => import("../../../components/site-modal/SiteModal"));
const OrganizationModal = lazy(() => import("../../../components/organization-modal/OrganizationModal"));
const SiteDepartmentEditModal = lazy(() => import("../../../components/site-department-modal/SiteDepartmentEditModal"));
const OrganizationSiteEditModal = lazy(() => import("../../../components/organization-site-edit-modal/OrganizationSiteEditModal"));

export enum EntityDetailsTabKeys {
  Dashboard = "dashboard",
  Analytics = "analytics",
  Workers = "workers",
  Devices = "devices",
  LiveCheckedOut = "liveCheckedOut",
  WorkerReportGroups = "worker_report_groups",
}

const DEFAULT_ACTIVE_KEY = EntityDetailsTabKeys.Dashboard;

type EntityDetailsProps = {};

export const EntityDetails: FunctionComponent<EntityDetailsProps> = observer(({}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const flags = useFlags();
  const location = useLocation();
  const navigate = useNavigate();

  const {orgId, siteId, departmentId} = useParams();
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isGatewaysDetailDrawerVisible, setIsGatewaysDetailDrawerVisible] = useState(false);
  const [activeKey, setActiveKey] = useState(location.state?.activeKey ?? DEFAULT_ACTIVE_KEY);
  const [timeZone, setTimeZone] = useState("");

  const [allSitesHaveOneDepartment, setAllSitesHaveOneDepartment] = useState(false);
  const [oneSite, setOneSite] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const favorites: {organizationIds: string[]} = useMemo(() => userPreferences.data.favorites ?? {organizationIds: []}, [userPreferences]);

  const isFavoriteOrg = orgId ? favorites.organizationIds.includes(orgId) : false;

  const showOrgSiteModal = oneSite && authenticationStore.isUserGlobal;

  const handleSettingsChange = (updatedFavorites: {organizationIds: string[]}) =>
    setUserPreferences({
      favorites: updatedFavorites,
    });

  const entityType: EntityType = useMemo(() => {
    if (departmentId) return EntityType.Department;
    if (siteId) return EntityType.Site;
    return EntityType.Organization;
  }, [departmentId, siteId]);

  const organizationQuery = useQuery({
    queryKey: ["EntityDetails-fetchOrganizationData", orgId],
    queryFn: () => {
      return OrganizationModule.organization({
        accessToken: authenticationStore.state.accessToken!,
        id: orgId!,
      });
    },
    enabled: !!orgId,
  });

  useEffect(() => {
    if (!organizationQuery.data?.organization) return;
    setAllSitesHaveOneDepartment(
      !!(
        organizationQuery.data?.organization &&
        organizationQuery.data?.organization.sites &&
        organizationQuery.data?.organization.sites.length
      ) && organizationQuery.data?.organization.sites.every((site) => site.departments && site.departments.length === 1)
    );
    setOneSite(organizationQuery.data?.organization.sites?.length === 1);
    if (organizationQuery.data?.organization?.sites && organizationQuery.data?.organization?.sites.length === 1 && !departmentId)
      navigate(`/organizations/${organizationQuery.data?.organization.id}/sites/${organizationQuery.data?.organization?.sites[0].id}`);
    if (
      organizationQuery.data?.organization?.sites &&
      organizationQuery.data?.organization?.sites.length === 1 &&
      organizationQuery.data?.organization?.sites[0].departments?.length === 1
    )
      navigate(
        `/organizations/${organizationQuery.data?.organization.id}/sites/${organizationQuery.data?.organization?.sites[0].id}/departments/${organizationQuery.data?.organization?.sites[0].departments[0].id}`
      );

    setDataLoaded(true);
  }, [departmentId, navigate, organizationQuery.data?.organization]);

  const siteQuery = useQuery({
    queryKey: ["EntityDetails-fetchSiteData", siteId],
    queryFn: () => {
      return SiteModule.site({
        accessToken: authenticationStore.state.accessToken!,
        id: siteId!,
      });
    },
    enabled: !!siteId,
  });

  const departmentQuery = useQuery({
    queryKey: ["EntityDetails-fetchDepartmentData", departmentId],
    queryFn: () => {
      return DepartmentModule.department({
        accessToken: authenticationStore.state.accessToken!,
        id: departmentId!,
      });
    },
    enabled: !!departmentId,
  });

  const gatewaysQuery = useQuery({
    queryKey: ["gateways", departmentId],
    queryFn: () => {
      return GatewayModule.gateways({
        accessToken: authenticationStore.state.accessToken!,
        department_id: departmentId,
      });
    },
    enabled: !!departmentId,
    refetchInterval: 5000,
  });

  const tabItems = useMemo(() => {
    let tabItems: {label: ReactNode; key: string; children: ReactNode}[] = [];
    if (departmentId && siteId && departmentQuery.data?.department && siteQuery.data?.site) {
      tabItems = [
        {
          label: <div id="dashboardTab-departmentPage">{WebHelper.formatMessage("EntityDetails-dashboardTabName")}</div>,
          key: EntityDetailsTabKeys.Dashboard,
          children: (
            <DashboardTabContent
              department={departmentQuery.data.department}
              site={allSitesHaveOneDepartment ? siteQuery.data.site : undefined}
              allSitesHaveOneDepartment={allSitesHaveOneDepartment}
              setTimeZone={setTimeZone}
            />
          ),
        },
        {
          label: <div id="analyticsTab-departmentPage">{WebHelper.formatMessage("EntityDetails-analyticsTabName")}</div>,
          key: EntityDetailsTabKeys.Analytics,
          children: (
            <AnalyticsTabContent
              organization={organizationQuery.data?.organization}
              site={siteQuery.data.site}
              department={departmentQuery.data.department}
              setTimeZone={setTimeZone}
            />
          ),
        },
        {
          label: <div id="workersTab-departmentPage">{WebHelper.formatMessage("EntityDetails-workersTabName")}</div>,
          key: EntityDetailsTabKeys.Workers,
          children: (
            <WorkersTabContent
              orgId={orgId}
              siteId={siteId}
              department={departmentQuery.data.department}
              siteName={departmentQuery.data.department.site.name}
              tz_location={siteQuery.data?.site?.tz_location}
            />
          ),
        },
        {
          label: <div id="devicesTab-departmentPage">{WebHelper.formatMessage("EntityDetails-devicesTabName")}</div>,
          key: EntityDetailsTabKeys.Devices,
          children: <DevicesTabContent department={departmentQuery.data.department} />,
        },
      ];

      if (allSitesHaveOneDepartment)
        tabItems.push({
          label: <div id="workerReportGroupsTab-departmentPage">{WebHelper.formatMessage("EntityDetails-workerReportGroupsTabName")}</div>,
          key: EntityDetailsTabKeys.WorkerReportGroups,
          children: (
            <Suspense>
              <WorkerReportGroupsTabContent siteID={siteId} orgID={orgId} />
            </Suspense>
          ),
        });

      if (flags.live_checked_out)
        tabItems.push({
          label: (
            <div id="liveCheckoutTab-departmentPage">
              <PlaySquareOutlined style={{marginRight: "4px"}} />
              {WebHelper.formatMessage("EntityDetails-liveCheckedOutTabName")}
            </div>
          ),
          key: EntityDetailsTabKeys.LiveCheckedOut,
          children: (
            <Suspense>
              <LiveCheckOutTabContent department={departmentQuery.data.department} />
            </Suspense>
          ),
        });
    } else if (siteId && siteQuery.data?.site) {
      tabItems = [
        {
          label: <div id="dashboardTab-sitePage">{WebHelper.formatMessage("EntityDetails-dashboardTabName")}</div>,
          key: EntityDetailsTabKeys.Dashboard,
          children: <DashboardTabContent site={siteQuery.data.site} setTimeZone={setTimeZone} />,
        },
        {
          label: <div id="analyticsTab-sitePage">{WebHelper.formatMessage("EntityDetails-analyticsTabName")}</div>,
          key: EntityDetailsTabKeys.Analytics,
          children: (
            <Suspense>
              <AnalyticsTabContent
                organization={organizationQuery.data?.organization}
                site={siteQuery.data.site}
                setTimeZone={setTimeZone}
              />
            </Suspense>
          ),
        },
        {
          label: <div id="workerReportGroupsTab-sitePage">{WebHelper.formatMessage("EntityDetails-workerReportGroupsTabName")}</div>,
          key: EntityDetailsTabKeys.WorkerReportGroups,
          children: (
            <Suspense>
              <WorkerReportGroupsTabContent siteID={siteId} orgID={orgId} />
            </Suspense>
          ),
        },
      ];
    } else if (orgId && organizationQuery.data?.organization) {
      tabItems = [
        {
          label: <div id="dashboardTab-organizationPage">{WebHelper.formatMessage("EntityDetails-dashboardTabName")}</div>,
          key: EntityDetailsTabKeys.Dashboard,
          children: (
            <Suspense>
              <DashboardTabContent organization={organizationQuery.data.organization} setTimeZone={setTimeZone} />
            </Suspense>
          ),
        },
        {
          label: <div id="analyticsTab-organizationPage">{WebHelper.formatMessage("EntityDetails-analyticsTabName")}</div>,
          key: EntityDetailsTabKeys.Analytics,
          children: (
            <Suspense>
              <AnalyticsTabContent organization={organizationQuery.data?.organization} setTimeZone={setTimeZone} />
            </Suspense>
          ),
        },
      ];
    }

    if (!tabItems.some((item) => item.key === activeKey)) {
      setActiveKey(DEFAULT_ACTIVE_KEY);
    }

    return tabItems;
  }, [
    departmentId,
    orgId,
    siteId,
    activeKey,
    departmentQuery.data?.department,
    siteQuery.data?.site,
    organizationQuery.data?.organization,
    flags.live_checked_out,
    allSitesHaveOneDepartment,
  ]);

  useEffect(() => {
    setIsNavigationMenuOpen(false);
  }, [departmentId, orgId, siteId]);

  const title = useMemo(() => {
    switch (entityType) {
      case EntityType.Department:
        return departmentQuery.data?.department?.name ?? "";
      case EntityType.Site:
        return siteQuery.data?.site?.name ?? "";
      case EntityType.Organization:
        return organizationQuery.data?.organization?.name ?? "";
      default:
        return "";
    }
  }, [entityType, departmentQuery.data?.department?.name, organizationQuery.data?.organization?.name, siteQuery.data?.site?.name]);

  const handleEditModalSave = () => {
    switch (entityType) {
      case EntityType.Department:
        if (allSitesHaveOneDepartment) {
          siteQuery.refetch();
          {
            oneSite && organizationQuery.refetch();
          }
        }
        departmentQuery.refetch();
        break;
      case EntityType.Site:
        if (oneSite) {
          organizationQuery.refetch();
        }
        siteQuery.refetch();
        break;
      case EntityType.Organization:
        organizationQuery.refetch();
        break;
    }

    setIsEditModalOpen(false);
  };

  const handleUpdate = async () => {
    await gatewaysQuery.refetch();
  };

  const isAuthorizedEdit = useMemo(() => {
    switch (entityType) {
      case EntityType.Department:
        return (
          authenticationStore.permissionLevel(Models.UserScope.Department, departmentId) === Models.UserAccessLevel.Admin ||
          authenticationStore.permissionLevel(Models.UserScope.Department, departmentId) === Models.UserAccessLevel.Manager
        );
      case EntityType.Site:
        return (
          authenticationStore.permissionLevel(Models.UserScope.Site, siteId) === Models.UserAccessLevel.Admin ||
          authenticationStore.permissionLevel(Models.UserScope.Site, siteId) === Models.UserAccessLevel.Manager
        );
      case EntityType.Organization:
        return (
          authenticationStore.permissionLevel(Models.UserScope.Organization, orgId) === Models.UserAccessLevel.Admin &&
          authenticationStore.isUserGlobal
        );
      default:
        return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, orgId, siteId, departmentId]);

  const handleDelete = async () => {
    setIsEditModalOpen(false);
    switch (entityType) {
      case EntityType.Department:
        await organizationQuery.refetch();
        await siteQuery.refetch();
        break;
      case EntityType.Site:
        await organizationQuery.refetch();
        break;
    }
  };

  const isError = useMemo(() => {
    return (
      (organizationQuery.data && !organizationQuery.data.success) ||
      (siteQuery.data && !siteQuery.data.success) ||
      (departmentQuery.data && !departmentQuery.data.success)
    );
  }, [organizationQuery.data, siteQuery.data, departmentQuery.data]);

  const errorScreen = useMemo(() => {
    if (organizationQuery.data && !organizationQuery.data.success) {
      return (
        <ErrorScreen
          errorType={organizationQuery.data?.error.code}
          entityType={EntityType.Organization}
          correlationID={organizationQuery.data.correlationId}
        />
      );
    } else if (siteQuery.data && !siteQuery.data.success) {
      return (
        <ErrorScreen errorType={siteQuery.data?.error.code} entityType={EntityType.Site} correlationID={siteQuery.data.correlationId} />
      );
    } else if (departmentQuery.data && !departmentQuery.data.success && departmentQuery.data.error.code) {
      return (
        <ErrorScreen
          errorType={departmentQuery.data?.error.code}
          entityType={EntityType.Department}
          correlationID={departmentQuery.data.correlationId}
        />
      );
    } else {
      return;
    }
  }, [organizationQuery.data, siteQuery.data, departmentQuery.data]);

  const loading = organizationQuery.isFetching || siteQuery.isFetching || departmentQuery.isFetching;

  const timezoneDisplay = () => {
    return (
      <>
        <ClockCircleOutlined />
        <Typography.Text className={styles.timeZoneLabelText}>
          {WebHelper.formatMessage("EntityDetail-timezone")} {timeZone}
        </Typography.Text>
      </>
    );
  };

  return (
    <>
      {isError ? (
        <div className={styles.errorWrapper}>{errorScreen}</div>
      ) : (
        <>
          {dataLoaded && <EntityDetailsSubheader oneOrgOneSite={oneSite} oneSiteOneDepartment={allSitesHaveOneDepartment} />}
          <Layout className={styles.wrapper} hasSider>
            <NavigationMenu
              onGatewayDrawerOpen={() => setIsGatewaysDetailDrawerVisible(true)}
              onSiderCollapse={() => setIsNavigationMenuOpen(false)}
              open={isNavigationMenuOpen}
              onSiteCreated={() => {
                organizationQuery.refetch();
              }}
              onDepartmentCreated={() => {
                organizationQuery.refetch();
                siteQuery.refetch();
              }}
              organizationQuery={{
                data: organizationQuery.data,
                isFetching: organizationQuery.isFetching,
              }}
              siteQuery={{
                data: siteQuery.data,
                isFetching: siteQuery.isFetching,
              }}
              departmentQuery={{
                data: departmentQuery.data,
                isFetching: departmentQuery.isFetching,
              }}
              gatewaysQuery={{data: gatewaysQuery.data}}
            />
            <Content className={styles.content}>
              {loading && <Loading />}
              {!loading && (
                <div className={styles.innerContentSpace}>
                  <PageTitle
                    id={`${title.replaceAll(" ", "_")}Header-${entityType}Page`}
                    title={title}
                    icon={
                      <AntdButton
                        className={styles.menuButton}
                        icon={<MenuOutlined />}
                        type="text"
                        size="large"
                        onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}
                      />
                    }
                    actions={[
                      entityType === EntityType.Organization && authenticationStore.isUserGlobal && (
                        <Tooltip
                          title={
                            isFavoriteOrg
                              ? WebHelper.formatMessage("Common-removeFromFavorites")
                              : WebHelper.formatMessage("Common-addToFavorites")
                          }
                          placement="bottom">
                          <AntdButton
                            className={styles.favoriteStar}
                            shape="round"
                            loading={userPreferences.isLoading}
                            disabled={userPreferences.isLoading}
                            onClick={() => WebHelper.updateFavoriteOrganizations(orgId!, favorites.organizationIds, handleSettingsChange)}
                            icon={isFavoriteOrg ? <StarFilled /> : <StarOutlined />}
                          />
                        </Tooltip>
                      ),
                      isAuthorizedEdit && (
                        <Button
                          id={`editButton-${entityType}Page`}
                          shape="round"
                          type={ButtonType.Primary}
                          icon={<EditOutlined />}
                          onClick={() => setIsEditModalOpen(true)}>
                          {WebHelper.formatMessage("Common-edit")}
                        </Button>
                      ),
                    ]}
                  />
                  <div className={styles.timeZoneLabelMobile}>{timezoneDisplay()}</div>
                  <Tabs
                    tabBarExtraContent={
                      (activeKey === EntityDetailsTabKeys.Analytics || activeKey === EntityDetailsTabKeys.Dashboard) && timeZone !== "" ? (
                        <div className={styles.timeZoneLabel}>{timezoneDisplay()}</div>
                      ) : (
                        <></>
                      )
                    }
                    className={styles.tabContent}
                    items={tabItems}
                    tabBarStyle={{overflowX: "auto"}}
                    activeKey={activeKey}
                    destroyInactiveTabPane={true}
                    onChange={(activeKey) => setActiveKey(activeKey as EntityDetailsTabKeys)}
                  />
                </div>
              )}
            </Content>
            {departmentId && departmentQuery.data?.department && (
              <DepartmentGatewaysDetail
                open={isGatewaysDetailDrawerVisible}
                department={departmentQuery.data.department}
                onClose={() => setIsGatewaysDetailDrawerVisible(false)}
                onUpdate={handleUpdate}
              />
            )}
          </Layout>
          <Suspense>
            {allSitesHaveOneDepartment &&
              entityType === EntityType.Department &&
              departmentQuery.data?.department &&
              siteQuery.data?.site && (
                <SiteDepartmentEditModal
                  organization={showOrgSiteModal ? organizationQuery.data?.organization : undefined}
                  data={{department: departmentQuery.data.department, site: siteQuery.data.site}}
                  onClose={() => setIsEditModalOpen(false)}
                  onSave={handleEditModalSave}
                  onDelete={handleDelete}
                  open={isEditModalOpen}
                />
              )}
            {!allSitesHaveOneDepartment && entityType === EntityType.Department && departmentQuery.data?.department && (
              <DepartmentModal
                data={{mode: "edit", department: departmentQuery.data.department}}
                onClose={() => setIsEditModalOpen(false)}
                onSave={handleEditModalSave}
                onDelete={handleDelete}
                open={isEditModalOpen}
              />
            )}
            {!allSitesHaveOneDepartment &&
              showOrgSiteModal &&
              entityType === EntityType.Site &&
              siteQuery.data?.site &&
              organizationQuery.data?.organization && (
                <OrganizationSiteEditModal
                  key={Date.now()}
                  data={{organization: organizationQuery.data.organization, site: siteQuery.data.site}}
                  onClose={() => setIsEditModalOpen(false)}
                  onSave={handleEditModalSave}
                  onDelete={handleDelete}
                  open={isEditModalOpen}
                />
              )}
            {!showOrgSiteModal && entityType === EntityType.Site && siteQuery.data?.site && (
              <SiteModal
                data={{mode: "edit", site: siteQuery.data.site}}
                onClose={() => setIsEditModalOpen(false)}
                onSave={handleEditModalSave}
                onDelete={handleDelete}
                open={isEditModalOpen}
              />
            )}
            {entityType === EntityType.Organization && organizationQuery.data?.organization && (
              <OrganizationModal
                onClose={() => setIsEditModalOpen(false)}
                onSave={handleEditModalSave}
                open={isEditModalOpen}
                data={{mode: "edit", organization: organizationQuery.data.organization}}
              />
            )}
          </Suspense>
        </>
      )}
    </>
  );
});
