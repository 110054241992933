import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {ErrorGroup, SessionStatus} from "./Session";

export class SessionShort extends BaseModel {
  id: string;
  session_uuid: string;
  device_id: string;
  worker_id: string | null;
  status: SessionStatus;
  total_lifts: number;
  duration_sec: number;
  error_count: number;
  errors_group: ErrorGroup | null;
  log_lines: number;
  start_time: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  init_volt_v: number;
  init_temp_c: number;
  final_volt_c: number;
  final_temp_c: number;
  avg_temp_c: number;
  max_temp_c: number;
  irms_total_a: number;
  i_max_rms_1_min_a: number;
  i_max_rms_10_min_a: number;
  i_max_rms_100_min_a: number;
  cycle_count_battery_1: number;
  cycle_count_battery_2: number;
  first_absolute_soc_battery_1: number;
  first_absolute_soc_battery_2: number;
  last_absolute_soc_battery_1: number;
  last_absolute_soc_battery_2: number;
  lift_file_version: number;
  use_lifts_proto: boolean;
  real_time_valid: boolean;
  worker_first_name: string;
  worker_last_name: string;
  worker_employee_id: string;
  worker_assignable: boolean;
  worker_department_name: string;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      session_uuid: observable,
      device_id: observable,
      worker_id: observable,
      status: observable,
      total_lifts: observable,
      duration_sec: observable,
      error_count: observable,
      errors_group: observable,
      log_lines: observable,
      start_time: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
      init_temp_c: observable,
      final_volt_c: observable,
      final_temp_c: observable,
      avg_temp_c: observable,
      max_temp_c: observable,
      irms_total_a: observable,
      i_max_rms_1_min_a: observable,
      i_max_rms_10_min_a: observable,
      i_max_rms_100_min_a: observable,
      cycle_count_battery_1: observable,
      cycle_count_battery_2: observable,
      first_absolute_soc_battery_1: observable,
      first_absolute_soc_battery_2: observable,
      last_absolute_soc_battery_1: observable,
      last_absolute_soc_battery_2: observable,
      lift_file_version: observable,
      use_lifts_proto: observable,
      real_time_valid: observable,
      worker_first_name: observable,
      worker_last_name: observable,
      worker_employee_id: observable,
      worker_assignable: observable,
      worker_department_name: observable,
    });
  }

  static fixObjectFromJSON(object: SessionShort, json: any) {
    if (json.start_time) object.start_time = new Date(json.start_time);
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);

    if (json.errors_group) {
      object.errors_group = {
        session_errors: json.errors_group.session_errors.map((error: any) => ({
          code: error.code,
          segments: error.segments,
          count: error.count,
        })),
      };
    }
  }

  get fullName(): string {
    return `${this.worker_first_name} ${this.worker_last_name}`;
  }

  get displayName(): string {
    return this.worker_first_name || this.worker_last_name ? this.fullName : this.worker_employee_id;
  }
}
