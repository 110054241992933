import * as Types from "./WorkerReportGroupAPIClient.types";
import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";

export class WorkerReportGroupAPIClient extends BaseAPIClient {
  static async workerReportGroups(
    request: Types.WorkerReportGroupsRequest,
    options: RequestOptions = {}
  ): Promise<Types.WorkerReportGroupsResponse> {
    const {
      accessToken: {token},
      site_id,
    } = request;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `groups/`,
      headers: {token},
      request,
      options,
      params: {
        ...(site_id ? {site_id} : {}),
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;

      if (data) {
        return {
          success: true,
          workerReportGroups: data.map((workerReportGroup: Models.WorkerReportGroup) =>
            Models.WorkerReportGroup.fromJSON(workerReportGroup)
          ),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async workerReportGroup(
    request: Types.WorkerReportGroupRequest,
    options: RequestOptions = {}
  ): Promise<Types.WorkerReportGroupResponse> {
    const {
      id,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `groups/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          workerReportGroup: Models.WorkerReportGroup.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateWorkerReportGroup(
    request: Types.UpdateWorkerReportGroupRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateWorkerReportGroupResponse> {
    const {
      id,
      name,
      site_id,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `groups/${id}`,
      headers: {token},
      request,
      options,
      data: {name, id, site_id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          workerReportGroup: Models.WorkerReportGroup.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async deleteWorkerReportGroup(
    request: Types.DeleteWorkerReportGroupRequest,
    options: RequestOptions = {}
  ): Promise<Types.DeleteWorkerReportGroupResponse> {
    const {
      id,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `delete`,
      url: `groups/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      return {
        success: true,
      };
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async createWorkerReportGroup(
    request: Types.CreateWorkerReportGroupRequest,
    options: RequestOptions = {}
  ): Promise<Types.CreateWorkerReportGroupResponse> {
    const {
      site_id,
      name,
      worker_ids,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `groups/create`,
      headers: {token},
      request,
      options,
      data: {site_id, name, worker_ids},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          workerReportGroup: Models.WorkerReportGroup.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async modifyWorkerReportGroupWorkers(
    request: Types.ModifyWorkerReportGroupWorkersRequest,
    options: RequestOptions = {}
  ): Promise<Types.ModifyWorkerReportGroupWorkersResponse> {
    const {
      id,
      worker_ids,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `groups/${id}/workers`,
      headers: {token},
      request,
      options,
      data: {worker_ids},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          workerReportGroup: Models.WorkerReportGroup.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }
}
