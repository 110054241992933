import {EntityType} from "./EntityType";

export enum AnalyticsVariableToChart {
  HoursUsed = "HoursUsed",
  Lifts = "Lifts",
  WeightOffloaded = "WeightOffloaded",
  MovementBreakdown = "MovementBreakdown",
  WorkerActiveStatus = "WorkerActiveStatus",
}

export enum AnalyticsIntervalHours {
  FifteenMinutes = "15_minutes",
  ThirtyMinutes = "30_minutes",
  OneHours = "1_hours",
  TwoHours = "2_hours",
  FourHours = "4_hours",
  EightHours = "8_hours",
  TwelveHours = "12_hours",
}

export enum AnalyticsInterval {
  Hours = "hours",
  Day = "day",
  Week = "week",
  Month = "month",
  Quarter = "quarter",
}

export enum ChartDataType {
  Raw = "raw",
  Percentage = "percentage",
  Average = "average",
}

export enum AnalyticsRelativeTo {
  CalendarDate = "calendar_date",
  DaysInTheSuit = "days_in_the_suit",
  TimeOfTheDay = "time_of_the_day",
}

export enum DisplayInGraphType {
  HoursUsed = "hours_used",
  Target = "target",
}

export enum DataScope {
  BetweenDates = "between_dates",
  BeforeSpecificDate = "before_specific_date",
  AfterSpecificDate = "after_specific_date",
}

export enum WorkerStatusType {
  Active = "active",
  Inactive = "inactive",
}

export enum ChartMovementType {
  Safe = "safe",
  Risky = "risky",
  ExcessiveForwardBending = "excessive_forward_bending",
  ProlongedBending = "prolonged_bending",
  Twisting = "twisting",
  SideBending = "side_bending",
}

export type AnalyticsEntity = {
  entityType: EntityType;
  id: string;
  name: string;
};

export type AnalyticsSettings = {
  relativeTo: AnalyticsRelativeTo;
  variableToChartSelected: AnalyticsVariableToChart;
  intervalSelected: AnalyticsInterval;
  intervalHoursSelected?: AnalyticsIntervalHours;
  intervalCount: number;
  startDate: Date | null;
  endDate: Date | null;
  checkedMostRecent: boolean;
  dataType: ChartDataType;
  workerStatusTypesSelected: WorkerStatusType[];
  movementTypesSelected: ChartMovementType[];
  safetyTypesSelected: ChartMovementType[];
  minimumLifts: number;
  dataScope: DataScope;
  showTrendlines: boolean;
};
