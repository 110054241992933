import {Result, Spin, Typography} from "antd";
import {BarElement, CategoryScale, ChartEvent, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {
  addDays,
  addMonths,
  endOfMonth,
  addQuarters,
  addWeeks,
  format,
  startOfMonth,
  isAfter,
  endOfWeek,
  addHours,
  addMinutes,
} from "date-fns";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {ForwardedRef, FunctionComponent, useMemo, useEffect, useRef} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";

import styles from "./AnalyticsChart.module.scss";
import * as Models from "../../../core/models";
import {AppStore} from "../../stores/AppStore";
import {lbsToKgs, WebHelper} from "../../utils/WebHelper";

const constants = require("assets/stylesheets/constants");

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const chartPlugin = {
  id: "whiteBackground",
  beforeDraw: (chart: ChartJS) => {
    const context = chart.canvas.getContext("2d");
    if (context) {
      context.save();
      context.globalCompositeOperation = "destination-over";
      context.fillStyle = "white";
      context.fillRect(0, 0, chart.width, chart.height);
      context.restore();
    }
  },
};

const trendlinePlugin = {
  id: "customTrendline",
  afterDatasetsDraw(chart: {data?: any; ctx?: any; scales?: any}) {
    const {
      ctx,
      scales: {x, y},
    } = chart;
    chart.data.datasets.forEach((dataset: {trendlineLinear?: any; data?: any}) => {
      if (!dataset.trendlineLinear) {
        return;
      }

      const {data} = dataset;
      const color = dataset.trendlineLinear.style;
      const lineWidth = 2;

      const xValues = data.map((_: any, index: any) => x.getPixelForValue(index));
      const yValues = data.map((d: any) => y.getPixelForValue(d));

      const lastNonZeroIndex = yValues
        .slice()
        .reverse()
        .findIndex((yValue: number) => yValue > 0);
      const cutoffIndex = lastNonZeroIndex === -1 ? yValues.length - 1 : yValues.length - 1 - lastNonZeroIndex;

      const validXValues = xValues.slice(0, cutoffIndex + 1);
      const validYValues = yValues.slice(0, cutoffIndex + 1);

      const {slope, intercept} = linearRegression(validXValues, validYValues);

      const barWidth = xValues[1] - xValues[0];

      const firstX = validXValues[0] - barWidth / 2;
      let lastX = validXValues[validXValues.length - 1] + barWidth / 2;

      let startY = slope * firstX + intercept;
      let endY = slope * lastX + intercept;

      if (endY < y.top) {
        endY = y.top;
        lastX = (endY - intercept) / slope;
      } else if (endY > y.bottom) {
        endY = y.bottom;
        lastX = (endY - intercept) / slope;
      }

      if (startY < y.top) {
        startY = y.top;
      } else if (startY > y.bottom) {
        startY = y.bottom;
      }

      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = color;
      ctx.setLineDash([5, 5]);

      ctx.moveTo(firstX, startY);
      ctx.lineTo(lastX, endY);

      ctx.stroke();
      ctx.restore();
    });
  },
};

function linearRegression(xValues: any[], yValues: any[]) {
  const n = xValues.length;
  const sumX = xValues.reduce((sum, x) => sum + x, 0);
  const sumY = yValues.reduce((sum, y) => sum + y, 0);
  const sumXY = xValues.reduce((sum, x, i) => sum + x * yValues[i], 0);
  const sumXX = xValues.reduce((sum, x) => sum + x * x, 0);

  const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return {slope, intercept};
}

enum ChartDataType {
  Raw = "raw",
  Percentage = "percentage",
  Average = "average",
}

type CheckboxValueType = string | number | boolean;

const maxIntervalNumber = 30;
const widthByInterval = 50;

export type AnalyticsChartProps = {
  allData: Models.AnalyticsTimeSeries[][] | Models.AnalyticsTimeSeriesIndices[][] | undefined;
  entities: Models.AnalyticsEntity[];
  variableSelected: Models.AnalyticsVariableToChart;
  intervalSelected: Models.AnalyticsInterval;
  intervalSelectedHours?: Models.AnalyticsIntervalHours;
  selectedMovements: Models.ChartMovementType[];
  safetyTypesSelected: Models.ChartMovementType[];
  dataType: ChartDataType;
  startDate: Date | null;
  endDate: Date | null;
  timeZone?: string;
  chartRef: ForwardedRef<ChartJSOrUndefined<"bar", number[], string>> | undefined;
  metricMeasurementUnits?: boolean;
  loadingQueries: boolean;
  intervals: number;
  minimumLifts: number;
  relativeTo: Models.AnalyticsRelativeTo;
  dataScopeSelected: Models.DataScope;
  selectedWorkerStatuses: Models.WorkerStatusType[];
  showTrendlines: boolean;
  isScroll: boolean;
  setIsScroll: (isScroll: boolean) => void;
  onSettingsChange: (
    updatedSettings?: Partial<Models.AnalyticsSettings>,
    dataSelected?: Models.ChartDataType,
    movementTypes?: CheckboxValueType[],
    mostRecentCheckbox?: boolean,
    entity?: Models.AnalyticsEntity[]
  ) => void;
  handleOnClickChart: (
    data: (Models.AnalyticsTimeSeries | Models.AnalyticsTimeSeriesIndices)[],
    label: string,
    relativeTo: Models.AnalyticsRelativeTo,
    entitySelected?: Models.AnalyticsEntity
  ) => void;
  isApplyChanges: boolean;
  setIsApplyChanges: (isApplyChanges: boolean) => void;
  isActiveScroll: boolean;
  setIsActiveScroll: (isActiveScroll: boolean) => void;
  isDetailModalOpen: boolean;
  calendarDaysModalOpen: boolean;
  setCalendarDaysModalOpen: (isOpen: boolean) => void;
  setStartDateToShow: (startDate: string) => void;
  setEndDateToShow: (endDate: string) => void;
};

export const AnalyticsChart: FunctionComponent<AnalyticsChartProps> = observer(
  ({
    allData,
    entities,
    variableSelected,
    intervalSelected,
    intervalSelectedHours,
    selectedMovements,
    safetyTypesSelected,
    selectedWorkerStatuses,
    dataType,
    timeZone,
    startDate,
    endDate,
    chartRef,
    metricMeasurementUnits,
    loadingQueries,
    intervals,
    minimumLifts,
    relativeTo,
    dataScopeSelected,
    showTrendlines,
    onSettingsChange,
    handleOnClickChart,
    isScroll,
    setIsScroll,
    isApplyChanges,
    setIsApplyChanges,
    isActiveScroll,
    setIsActiveScroll,
    isDetailModalOpen,
    calendarDaysModalOpen,
    setCalendarDaysModalOpen,
    setStartDateToShow,
    setEndDateToShow,
  }) => {
    const appStore = AppStore.getInstance();
    const chartContainerRef = React.useRef<HTMLDivElement>(null);

    const flags = useFlags();

    function hexToRgba(hex: string, alpha: any) {
      hex = hex.replace(/^#/, "");

      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return `rgba(${r},${g},${b},${alpha})`;
    }

    const chartLabel = useMemo(() => {
      switch (variableSelected) {
        case Models.AnalyticsVariableToChart.HoursUsed:
          return WebHelper.formatMessage("AnalyticsChart-hoursUsed");
        case Models.AnalyticsVariableToChart.WeightOffloaded:
          return WebHelper.formatMessage("AnalyticsChart-weightOffloaded");
        case Models.AnalyticsVariableToChart.MovementBreakdown:
          return WebHelper.formatMessage("AnalyticsChart-movementBreakdown");
        default:
          return "";
      }
    }, [variableSelected]);

    const chartVariable = useMemo(() => {
      switch (variableSelected) {
        case Models.AnalyticsVariableToChart.HoursUsed:
          return "usage_sec";
        case Models.AnalyticsVariableToChart.WeightOffloaded:
          return "weight_offloaded_lbs";
        default:
          return "";
      }
    }, [variableSelected]);

    const chartUnit = useMemo(() => {
      switch (variableSelected) {
        case Models.AnalyticsVariableToChart.WorkerActiveStatus:
          if (dataType === ChartDataType.Percentage) return WebHelper.formatMessage("AnalyticsChart-percentageOfWorkers");
          else return WebHelper.formatMessage("AnalyticsChart-workersUnit");
        case Models.AnalyticsVariableToChart.Lifts:
        case Models.AnalyticsVariableToChart.MovementBreakdown:
          if (dataType === ChartDataType.Percentage) return WebHelper.formatMessage("AnalyticsChart-percentageOfLifts");
          else if (dataType === ChartDataType.Average) return WebHelper.formatMessage("AnalyticsChart-averageOfLifts");
          else return WebHelper.formatMessage("AnalyticsChart-liftsUnit");
        case Models.AnalyticsVariableToChart.HoursUsed:
          if (dataType === ChartDataType.Average) return WebHelper.formatMessage("AnalyticsChart-averageHoursUsed");
          else return WebHelper.formatMessage("AnalyticsChart-hoursUnit");
        case Models.AnalyticsVariableToChart.WeightOffloaded:
          if (dataType === ChartDataType.Average)
            return WebHelper.formatMessage(
              metricMeasurementUnits ? "AnalyticsChart-weightOffloadedAverageKgs" : "AnalyticsChart-weightOffloadedAverageLbs"
            );
          else
            return WebHelper.formatMessage(
              metricMeasurementUnits ? "AnalyticsChart-weightOffloadedUnitKgs" : "AnalyticsChart-weightOffloadedUnitLbs"
            );
        default:
          return "";
      }
    }, [variableSelected, dataType, metricMeasurementUnits]);

    const datasets = useMemo(() => {
      const result: any = [];
      if (!allData) return;
      switch (variableSelected) {
        case Models.AnalyticsVariableToChart.WorkerActiveStatus: {
          if (entities && entities.length > 1) {
            const numEntities = entities.length;
            const numDays = allData && allData[0] && allData[0].length;
            let intercalatedData = [];
            if (numDays && numEntities) {
              intercalatedData = Array(numDays * numEntities).fill(0);
              for (let entityIndex = 0; entityIndex < numEntities; entityIndex++) {
                for (let dayIndex = 0; dayIndex < numDays; dayIndex++) {
                  const intercalatedIndex = dayIndex * numEntities + entityIndex;
                  if (allData && allData[entityIndex]) intercalatedData[intercalatedIndex] = allData[entityIndex][dayIndex];
                }
              }
            }
            if (selectedWorkerStatuses.includes(Models.WorkerStatusType.Active)) {
              entities.forEach((entity, entityIndex) => {
                allData &&
                  intercalatedData &&
                  result.push({
                    label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-activeWorkers")} - ${entity.name}`,
                    data: intercalatedData.map((data, index) => {
                      if (index % numEntities !== entityIndex) return 0;
                      if (!data) return;
                      return dataType === ChartDataType.Raw ? data.active_workers : data.activeWorkersPercentage.toFixed(1);
                    }),
                    backgroundColor: hexToRgba(constants.colors.analytics.green[entityIndex % numEntities], 0.7),
                    trendlineLinear:
                      flags.analytics_trendlines && showTrendlines
                        ? {
                            style: hexToRgba(constants.colors.analytics.green[entityIndex % numEntities], 0.7),
                          }
                        : null,
                  });
              });
            }
            if (
              selectedWorkerStatuses.includes(Models.WorkerStatusType.Inactive) &&
              relativeTo !== Models.AnalyticsRelativeTo.DaysInTheSuit
            ) {
              entities.forEach((entity, entityIndex) => {
                allData &&
                  result.push({
                    label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-inactiveWorkers")} - ${entity.name}`,
                    data: intercalatedData.map((data, index) => {
                      if (index % numEntities !== entityIndex) return 0;
                      if (!data) return;
                      return dataType === ChartDataType.Raw ? data.inactiveWorkers : data.inactiveWorkersPercentage.toFixed(1);
                    }),
                    backgroundColor: hexToRgba(constants.colors.analytics.yellow[entityIndex % numEntities], 0.7),
                    trendlineLinear:
                      flags.analytics_trendlines && showTrendlines
                        ? {
                            style: hexToRgba(constants.colors.analytics.yellow[entityIndex % numEntities], 0.7),
                          }
                        : null,
                  });
              });
            }
          } else {
            allData.forEach((data, index) => {
              if (!entities[index]) return;
              if (selectedWorkerStatuses.includes(Models.WorkerStatusType.Active)) {
                result.push({
                  label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-activeWorkers")}`,
                  data: data.map((analyticHistory) => {
                    return dataType === ChartDataType.Raw
                      ? analyticHistory.active_workers
                      : analyticHistory.activeWorkersPercentage.toFixed(1);
                  }),
                  backgroundColor: hexToRgba(constants.colors.analytics.green[index], 0.7),
                  trendlineLinear:
                    flags.analytics_trendlines && showTrendlines
                      ? {
                          style: hexToRgba(constants.colors.analytics.green[index], 0.7),
                        }
                      : null,
                });
              }
              if (
                selectedWorkerStatuses.includes(Models.WorkerStatusType.Inactive) &&
                relativeTo !== Models.AnalyticsRelativeTo.DaysInTheSuit
              ) {
                result.push({
                  label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-inactiveWorkers")}`,
                  data: data.map((analyticHistory) => {
                    return dataType === ChartDataType.Raw
                      ? analyticHistory.inactiveWorkers
                      : analyticHistory.inactiveWorkersPercentage.toFixed(1);
                  }),
                  backgroundColor: hexToRgba(constants.colors.analytics.yellow[index], 0.7),
                  trendlineLinear:
                    flags.analytics_trendlines && showTrendlines
                      ? {
                          style: hexToRgba(constants.colors.analytics.yellow[index], 0.7),
                        }
                      : null,
                });
              }
            });
          }

          if (intervalSelected === Models.AnalyticsInterval.Hours) {
            const filteredDatasets = result.filter((dataset: {label: string; data: any[]; backgroundColor: any; trendlineLinear: any}) => {
              return dataset.data.some((value) => value !== 0 && value !== null);
            });
            return filteredDatasets;
          }
          return result;
        }
        case Models.AnalyticsVariableToChart.Lifts:
          if (entities && entities.length > 1) {
            const numEntities = entities.length;
            const numDays = allData && allData[0] && allData[0].length;
            let intercalatedData = [];
            if (numDays && numEntities) {
              intercalatedData = Array(numDays * numEntities).fill(0);
              for (let entityIndex = 0; entityIndex < numEntities; entityIndex++) {
                for (let dayIndex = 0; dayIndex < numDays; dayIndex++) {
                  const intercalatedIndex = dayIndex * numEntities + entityIndex;
                  if (allData && allData[entityIndex]) intercalatedData[intercalatedIndex] = allData[entityIndex][dayIndex];
                }
              }
            }
            if (safetyTypesSelected.includes(Models.ChartMovementType.Safe)) {
              entities.forEach((entity, entityIndex) => {
                allData &&
                  intercalatedData &&
                  result.push({
                    label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-safe")} - ${entity.name}`,
                    data: intercalatedData.map((data, index) => {
                      if (index % numEntities !== entityIndex) return 0;
                      if (!data) return;
                      if (data.safe_lifts < minimumLifts) return 0;
                      return dataType === ChartDataType.Raw
                        ? data.safe_lifts.toFixed(0)
                        : dataType === ChartDataType.Average
                          ? data.averageSafeLifts.toFixed(1)
                          : data.safeLiftsPercentage.toFixed(1);
                    }),
                    backgroundColor: hexToRgba(constants.colors.analytics.blue[entityIndex % numEntities], 0.7),
                    trendlineLinear:
                      flags.analytics_trendlines && showTrendlines
                        ? {
                            style: constants.colors.analytics.blue[entityIndex % numEntities],
                          }
                        : null,
                  });
              });
            }
            if (safetyTypesSelected.includes(Models.ChartMovementType.Risky)) {
              entities.forEach((entity, entityIndex) => {
                allData &&
                  intercalatedData &&
                  result.push({
                    label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-risky")} - ${entity.name}`,
                    data: intercalatedData.map((data, index) => {
                      if (index % numEntities !== entityIndex) return 0;
                      if (!data) return;
                      if (data.riskyLifts < minimumLifts) return 0;
                      return dataType === ChartDataType.Raw
                        ? data.riskyLifts.toFixed(0)
                        : dataType === ChartDataType.Average
                          ? data.averageRiskyLifts.toFixed(1)
                          : data.riskyLiftsPercentage.toFixed(1);
                    }),
                    backgroundColor: hexToRgba(constants.colors.analytics.secondaryOrange[entityIndex % numEntities], 0.7),
                    trendlineLinear:
                      flags.analytics_trendlines && showTrendlines
                        ? {
                            style: constants.colors.analytics.secondaryOrange[entityIndex % numEntities],
                          }
                        : null,
                  });
              });
            }
          } else {
            allData.forEach((data, index) => {
              if (safetyTypesSelected.includes(Models.ChartMovementType.Safe) && entities[index]) {
                result.push({
                  label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-safe")} - ${entities[index].name}`,
                  data: data.map((analyticHistory) => {
                    if (analyticHistory.safe_lifts < minimumLifts) return 0;
                    return dataType === ChartDataType.Raw
                      ? analyticHistory.safe_lifts.toFixed(0)
                      : dataType === ChartDataType.Average
                        ? analyticHistory.averageSafeLifts.toFixed(1)
                        : analyticHistory.safeLiftsPercentage.toFixed(1);
                  }),
                  backgroundColor: hexToRgba(constants.colors.analytics.blue[index], 0.7),
                  trendlineLinear:
                    flags.analytics_trendlines && showTrendlines
                      ? {
                          style: hexToRgba(constants.colors.analytics.blue[index], 0.7),
                        }
                      : null,
                });
              }
            });
            allData.forEach((data, index) => {
              if (safetyTypesSelected.includes(Models.ChartMovementType.Risky) && entities[index]) {
                result.push({
                  label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-risky")} - ${entities[index].name}`,
                  data: data.map((analyticHistory) => {
                    if (analyticHistory.safe_lifts < minimumLifts) return 0;
                    return dataType === ChartDataType.Raw
                      ? analyticHistory.riskyLifts.toFixed(0)
                      : dataType === ChartDataType.Average
                        ? analyticHistory.averageRiskyLifts.toFixed(1)
                        : analyticHistory.riskyLiftsPercentage.toFixed(1);
                  }),
                  backgroundColor: hexToRgba(constants.colors.analytics.secondaryOrange[index], 0.7),
                  trendlineLinear:
                    flags.analytics_trendlines && showTrendlines
                      ? {
                          style: hexToRgba(constants.colors.analytics.secondaryOrange[index], 0.7),
                        }
                      : null,
                });
              }
            });
          }
          return result;
        case Models.AnalyticsVariableToChart.MovementBreakdown:
          if (selectedMovements.includes(Models.ChartMovementType.ExcessiveForwardBending)) {
            allData.forEach((data, index) => {
              if (!entities[index]) return;
              result.push({
                label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-excessiveForwardBending")} - ${entities[index].name}`,
                data: data.map((analyticHistory) => {
                  if (analyticHistory.excessive_forward_lifts < minimumLifts) return 0;
                  return dataType === ChartDataType.Raw
                    ? analyticHistory.excessive_forward_lifts.toFixed(0)
                    : dataType === ChartDataType.Average
                      ? analyticHistory.averageExcessiveForwardLifts.toFixed(1)
                      : analyticHistory.excessiveForwardLiftsPercentage.toFixed(1);
                }),
                backgroundColor: hexToRgba(constants.colors.analytics.greenDark[index], 0.7),
                trendlineLinear:
                  flags.analytics_trendlines && showTrendlines
                    ? {
                        style: hexToRgba(constants.colors.analytics.greenDark[index], 0.7),
                      }
                    : null,
              });
            });
          }
          if (selectedMovements.includes(Models.ChartMovementType.ProlongedBending)) {
            allData.forEach((data, index) => {
              if (!entities[index]) return;
              result.push({
                label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-prolongedBending")} - ${entities[index].name}`,
                data: data.map((analyticHistory) => {
                  if (analyticHistory.prolonged_bend_lifts < minimumLifts) return 0;
                  return dataType === ChartDataType.Raw
                    ? analyticHistory.prolonged_bend_lifts.toFixed(0)
                    : dataType === ChartDataType.Average
                      ? analyticHistory.averageProlongedBendLifts.toFixed(1)
                      : analyticHistory.prolongedBendLiftsPercentage.toFixed(1);
                }),
                backgroundColor: hexToRgba(constants.colors.analytics.blue[index], 0.7),
                trendlineLinear:
                  flags.analytics_trendlines && showTrendlines
                    ? {
                        style: hexToRgba(constants.colors.analytics.blue[index], 0.7),
                      }
                    : null,
              });
            });
          }
          if (selectedMovements.includes(Models.ChartMovementType.Twisting)) {
            allData.forEach((data, index) => {
              if (!entities[index]) return;
              result.push({
                label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-twisting")} - ${entities[index].name}`,
                data: data.map((analyticHistory) => {
                  if (analyticHistory.twisted_lifts < minimumLifts) return 0;
                  return dataType === ChartDataType.Raw
                    ? analyticHistory.twisted_lifts.toFixed(0)
                    : dataType === ChartDataType.Average
                      ? analyticHistory.averageTwistedLifts.toFixed(1)
                      : analyticHistory.twistedLiftsPercentage.toFixed(1);
                }),
                backgroundColor: hexToRgba(constants.colors.analytics.green[index], 0.7),
                trendlineLinear:
                  flags.analytics_trendlines && showTrendlines
                    ? {
                        style: hexToRgba(constants.colors.analytics.green[index], 0.7),
                      }
                    : null,
              });
            });
          }
          if (selectedMovements.includes(Models.ChartMovementType.SideBending)) {
            allData.forEach((data, index) => {
              if (!entities[index]) return;
              result.push({
                label: `${WebHelper.formatMessage("AnalyticsChart-datasetLabel-sideBending")} - ${entities[index].name}`,
                data: data.map((analyticHistory) => {
                  if (analyticHistory.side_bend_lifts < minimumLifts) return 0;
                  return dataType === ChartDataType.Raw
                    ? analyticHistory.side_bend_lifts.toFixed(0)
                    : dataType === ChartDataType.Average
                      ? analyticHistory.averageSideBendLifts.toFixed(1)
                      : analyticHistory.sideBendLiftsPercentage.toFixed(1);
                }),
                backgroundColor: hexToRgba(constants.colors.analytics.yellow[index], 0.7),
                trendlineLinear:
                  flags.analytics_trendlines && showTrendlines
                    ? {
                        style: hexToRgba(constants.colors.analytics.yellow[index], 0.7),
                      }
                    : null,
              });
            });
          }

          if (intervalSelected === Models.AnalyticsInterval.Hours) {
            const filteredDatasets = result.filter((dataset: {label: string; data: any[]; backgroundColor: any; trendlineLinear: any}) => {
              return dataset.data.some((value) => value !== 0 && value !== null);
            });
            return filteredDatasets;
          }
          return result;
        default:
          allData.forEach((data, index) => {
            if (!entities[index]) return;
            result.push({
              label: `${entities[index].name}`,
              data: data.map((analyticHistory) => {
                if (chartVariable && chartVariable in analyticHistory)
                  switch (variableSelected) {
                    case Models.AnalyticsVariableToChart.HoursUsed:
                      if (dataType === ChartDataType.Average)
                        return analyticHistory.active_workers > 0
                          ? (analyticHistory[chartVariable] / 3600 / analyticHistory.active_workers).toFixed(1)
                          : 0;
                      return (analyticHistory[chartVariable] / 3600).toFixed(1);
                    case Models.AnalyticsVariableToChart.WeightOffloaded:
                      if (dataType === ChartDataType.Average)
                        return analyticHistory.active_workers > 0
                          ? metricMeasurementUnits
                            ? lbsToKgs(analyticHistory[chartVariable] / analyticHistory.active_workers).toFixed(1)
                            : (analyticHistory[chartVariable] / analyticHistory.active_workers).toFixed(1)
                          : 0;
                      return metricMeasurementUnits
                        ? lbsToKgs(analyticHistory[chartVariable]).toFixed(1)
                        : analyticHistory[chartVariable].toFixed(1);
                    default:
                      return analyticHistory[chartVariable];
                  }
                return;
              }),
              backgroundColor: hexToRgba(constants.colors.analytics.primaryOrange[index], 0.7),
              trendlineLinear:
                flags.analytics_trendlines && showTrendlines
                  ? {
                      style: hexToRgba(constants.colors.analytics.primaryOrange[index], 0.7),
                    }
                  : null,
            });
          });

          if (intervalSelected === Models.AnalyticsInterval.Hours) {
            const filteredDatasets = result.filter((dataset: {label: string; data: any[]; backgroundColor: any; trendlineLenear: any}) => {
              return dataset.data.some((value) => value !== 0 && value !== null);
            });
            return filteredDatasets;
          }
          return result;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variableSelected, allData, entities, selectedMovements, chartLabel, dataType, chartVariable, safetyTypesSelected]);

    const labels = useMemo(() => {
      const labels = [];

      const dateFormat =
        relativeTo === Models.AnalyticsRelativeTo.TimeOfTheDay
          ? WebHelper.formatMessage("Common-dateFormatTimeOnly")
          : intervalSelected === Models.AnalyticsInterval.Month
            ? WebHelper.formatMessage("Common-dateFormatMonthYear")
            : intervalSelected === Models.AnalyticsInterval.Quarter
              ? WebHelper.formatMessage("Common-dateFormatQuarterYear")
              : intervalSelected === Models.AnalyticsInterval.Week
                ? WebHelper.formatMessage("Common-dateFormatMonthDay")
                : intervalSelected === Models.AnalyticsInterval.Day
                  ? WebHelper.formatMessage("Common-dateFormatMonthDayOnly")
                  : intervalSelectedHours === Models.AnalyticsIntervalHours.FifteenMinutes
                    ? WebHelper.formatMessage("Common-dateFormatTimeOnly")
                    : intervalSelectedHours === Models.AnalyticsIntervalHours.TwelveHours ||
                        intervalSelectedHours === Models.AnalyticsIntervalHours.EightHours ||
                        intervalSelectedHours === Models.AnalyticsIntervalHours.FourHours ||
                        intervalSelectedHours === Models.AnalyticsIntervalHours.TwoHours ||
                        intervalSelectedHours === Models.AnalyticsIntervalHours.OneHours ||
                        intervalSelectedHours === Models.AnalyticsIntervalHours.ThirtyMinutes
                      ? WebHelper.formatMessage("Common-dateTimeFormatWithoutYear")
                      : appStore.isMobile()
                        ? WebHelper.formatMessage("Common-dateFormatMonthDay")
                        : WebHelper.formatMessage("Common-dateFormatMonthDayOnly");

      if (relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit) {
        if (endDate) {
          for (let i = 0; i < intervals; i++) {
            const label = WebHelper.formatMessage("AnalyticsChart-dayDaysInSuit", {day: i + 1});
            if (
              (variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
                variableSelected === Models.AnalyticsVariableToChart.Lifts) &&
              entities &&
              entities.length > 1
            ) {
              entities.forEach((entity) => {
                labels.push(`${label} - ${entity.name}`);
              });
            } else labels.push(label);
          }
        }
      } else {
        if (endDate) {
          const actualEndDate =
            dataScopeSelected === Models.DataScope.AfterSpecificDate
              ? intervalSelected === Models.AnalyticsInterval.Month
                ? addMonths(endDate, intervals - 1)
                : intervalSelected === Models.AnalyticsInterval.Quarter
                  ? addQuarters(endDate, intervals - 1)
                  : intervalSelected === Models.AnalyticsInterval.Week
                    ? addWeeks(endDate, intervals - 1)
                    : intervalSelected === Models.AnalyticsInterval.Hours
                      ? intervalSelectedHours === Models.AnalyticsIntervalHours.TwelveHours
                        ? addDays(endDate, intervals / 2)
                        : intervalSelectedHours === Models.AnalyticsIntervalHours.EightHours
                          ? addDays(endDate, intervals / 3)
                          : intervalSelectedHours === Models.AnalyticsIntervalHours.FourHours
                            ? addDays(endDate, intervals / 6)
                            : intervalSelectedHours === Models.AnalyticsIntervalHours.TwoHours
                              ? addDays(endDate, intervals / 12)
                              : intervalSelectedHours === Models.AnalyticsIntervalHours.OneHours
                                ? addDays(endDate, intervals / 24)
                                : intervalSelectedHours === Models.AnalyticsIntervalHours.ThirtyMinutes
                                  ? addDays(endDate, intervals / 48)
                                  : intervalSelectedHours === Models.AnalyticsIntervalHours.FifteenMinutes
                                    ? addDays(endDate, intervals / 96)
                                    : addDays(endDate, intervals / 12)
                      : addDays(endDate, intervals - 1)
              : endDate;
          for (let i = 0; i < intervals; i++) {
            let startIntervalDate: Date = new Date();
            let endIntervalDate: Date = new Date();
            switch (intervalSelected) {
              case Models.AnalyticsInterval.Week:
                endIntervalDate = addWeeks(endOfWeek(actualEndDate), i * -1);
                startIntervalDate = addDays(addWeeks(endOfWeek(actualEndDate), i * -1 - 1), 1);
                break;
              case Models.AnalyticsInterval.Month:
                endIntervalDate = endOfMonth(addMonths(startOfMonth(actualEndDate), i * -1));
                startIntervalDate = startOfMonth(addMonths(startOfMonth(actualEndDate), i * -1));
                break;
              case Models.AnalyticsInterval.Quarter:
                endIntervalDate = addQuarters(actualEndDate, i * -1);
                startIntervalDate = addQuarters(actualEndDate, i * -1);
                break;
              case Models.AnalyticsInterval.Day:
                endIntervalDate = addDays(actualEndDate, i * -1);
                startIntervalDate = addDays(actualEndDate, i * -1);
                break;
              case Models.AnalyticsInterval.Hours:
                switch (intervalSelectedHours) {
                  case Models.AnalyticsIntervalHours.FifteenMinutes:
                    endIntervalDate = new Date(actualEndDate);
                    endIntervalDate.setMinutes(0, 0, 0);
                    startIntervalDate = new Date(endIntervalDate);
                    startIntervalDate.setMinutes(startIntervalDate.getMinutes() - 15 * (i + 1));
                    endIntervalDate.setMinutes(endIntervalDate.getMinutes() - 15 * i);
                    break;
                  case Models.AnalyticsIntervalHours.ThirtyMinutes:
                    endIntervalDate = addMinutes(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -30
                    );
                    startIntervalDate = addMinutes(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -30 - 30
                    );
                    break;
                  case Models.AnalyticsIntervalHours.OneHours:
                    endIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -1
                    );
                    startIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -1 - 1
                    );
                    break;

                  case Models.AnalyticsIntervalHours.TwoHours:
                    endIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -2
                    );
                    startIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -2 - 2
                    );
                    break;

                  case Models.AnalyticsIntervalHours.FourHours:
                    endIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -4
                    );
                    startIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -4 - 4
                    );
                    break;

                  case Models.AnalyticsIntervalHours.EightHours:
                    endIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -8
                    );
                    startIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -8 - 8
                    );
                    break;

                  case Models.AnalyticsIntervalHours.TwelveHours:
                    endIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -12
                    );
                    startIntervalDate = addHours(
                      addDays(actualEndDate, dataScopeSelected === Models.DataScope.AfterSpecificDate ? 0 : 1),
                      i * -12 - 12
                    );
                    break;
                }
                break;
              default:
                startIntervalDate = actualEndDate;
                endIntervalDate = actualEndDate;
                break;
            }

            if (
              (variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
                variableSelected === Models.AnalyticsVariableToChart.Lifts) &&
              entities &&
              entities.length > 1
            ) {
              const entitiesLabels = [...entities].reverse();
              entitiesLabels.forEach((entity) => {
                const label = `${format(startIntervalDate, dateFormat)} - ${entity.name}`;
                labels.unshift(label);
              });
            } else {
              const label =
                intervalSelected !== Models.AnalyticsInterval.Week
                  ? format(startIntervalDate, dateFormat)
                  : appStore.isMobile()
                    ? `${format(startIntervalDate, dateFormat)}-${format(endIntervalDate, dateFormat)}`
                    : `${format(startIntervalDate, dateFormat)} - ${format(endIntervalDate, dateFormat)}`;
              labels.unshift(label);
            }
          }
        }
      }
      {
        relativeTo === Models.AnalyticsRelativeTo.CalendarDate
          ? labels && setStartDateToShow(labels[0])
          : startDate && setStartDateToShow(format(startDate, WebHelper.formatMessage("Common-dateTimeFormatWithoutYear")));
        relativeTo === Models.AnalyticsRelativeTo.CalendarDate
          ? setEndDateToShow(labels[labels.length - 1])
          : endDate && setEndDateToShow(format(endDate, WebHelper.formatMessage("Common-dateTimeFormatWithoutYear")));
      }
      return labels;
    }, [
      relativeTo,
      intervalSelected,
      intervalSelectedHours,
      appStore,
      startDate,
      endDate,
      intervals,
      setStartDateToShow,
      setEndDateToShow,
      dataScopeSelected,
      entities,
      variableSelected,
    ]);

    useEffect(() => {
      if (isActiveScroll) return;
      const handleScroll = () => {
        if (relativeTo === Models.AnalyticsRelativeTo.DaysInTheSuit || isApplyChanges) return;
        const container = chartContainerRef.current;
        if (dataScopeSelected === Models.DataScope.AfterSpecificDate) {
          if (container && container.scrollLeft + container.clientWidth >= container.scrollWidth) {
            if (endDate && isAfter(new Date(), addDays(endDate, intervals))) {
              setIsScroll(true);
              setIsActiveScroll(true);
              setCalendarDaysModalOpen(true);
            }
          }
        } else if (dataScopeSelected === Models.DataScope.BeforeSpecificDate) {
          if (container && container.scrollLeft === 0) {
            setIsScroll(true);
            setIsActiveScroll(true);
            if (endDate) {
              setCalendarDaysModalOpen(true);
            }
          }
        }
      };

      const container = chartContainerRef.current;
      container?.addEventListener("scroll", handleScroll);

      return () => {
        container?.removeEventListener("scroll", handleScroll);
      };
    }, [
      dataScopeSelected,
      dataType,
      endDate,
      entities,
      intervalSelected,
      intervals,
      minimumLifts,
      onSettingsChange,
      relativeTo,
      safetyTypesSelected,
      selectedMovements,
      selectedWorkerStatuses,
      startDate,
      setIsScroll,
      variableSelected,
      isApplyChanges,
      isScroll,
      isActiveScroll,
      setIsActiveScroll,
      setCalendarDaysModalOpen,
    ]);

    const details = useRef(false);

    useEffect(() => {
      if (isDetailModalOpen) {
        details.current = true;
        return;
      } else if (!isDetailModalOpen && details.current) {
        details.current = false;
        return;
      }
      const container = chartContainerRef.current;
      if (container && dataScopeSelected === Models.DataScope.BeforeSpecificDate && (isApplyChanges || !isScroll)) {
        container.scrollLeft = container.scrollWidth - 100;
      }
    }, [dataScopeSelected, onSettingsChange, isApplyChanges, isScroll, isDetailModalOpen]);

    useEffect(() => {
      {
        !loadingQueries && setIsApplyChanges(false);
      }
    }, [loadingQueries, setIsApplyChanges]);

    const handleOnClick = (event: ChartEvent, elements: any[]) => {
      const details: (Models.AnalyticsTimeSeries | Models.AnalyticsTimeSeriesIndices)[] = [];
      if (entities.length > 0 && elements && elements.length > 0 && allData) {
        const index = elements[0].index;
        const label = labels[index];
        if (
          entities.length > 1 &&
          (variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
            variableSelected === Models.AnalyticsVariableToChart.Lifts)
        ) {
          const numEntities = entities.length;
          const numDays = allData && allData[0] && allData[0].length;
          let intercalatedData = [];
          if (numDays && numEntities) {
            intercalatedData = Array(numDays * numEntities).fill(0);
            for (let entityIndex = 0; entityIndex < numEntities; entityIndex++) {
              for (let dayIndex = 0; dayIndex < numDays; dayIndex++) {
                const intercalatedIndex = dayIndex * numEntities + entityIndex;
                if (allData && allData[entityIndex]) intercalatedData[intercalatedIndex] = allData[entityIndex][dayIndex];
              }
            }
          }
          intercalatedData.forEach((data) => {
            details.push(data);
          });
          handleOnClickChart(details, label, relativeTo, entities[index % entities.length]);
        } else {
          allData.forEach((data) => {
            details.push(data[index]);
          });
        }
        handleOnClickChart(details, label, relativeTo);
      }
    };

    return (
      <div className={styles.chartWrapper}>
        <div className={styles.chartContainer} ref={chartContainerRef}>
          {loadingQueries && !isScroll ? (
            <Spin spinning className={styles.spinner} />
          ) : (
            <div
              className={styles.chartAreaWrapper}
              style={{
                width:
                  intervals * entities.length > maxIntervalNumber
                    ? chartContainerRef?.current?.offsetWidth
                      ? widthByInterval * intervals < chartContainerRef?.current?.offsetWidth
                        ? `${chartContainerRef?.current?.offsetWidth}px`
                        : `${widthByInterval * intervals * (entities.length > 1 ? entities.length / 2 : entities.length)}px`
                      : `${widthByInterval * intervals * (entities.length > 1 ? entities.length / 2 : entities.length)}px`
                    : "100%",
                justifyContent: !allData ? "center" : "",
              }}>
              {isScroll && loadingQueries && (
                <>
                  <Spin spinning className={styles.chartSpinnerLeft} />
                  <Spin spinning className={styles.chartSpinnerRight} />
                </>
              )}
              {allData ? (
                <Bar
                  ref={chartRef}
                  plugins={[chartPlugin, trendlinePlugin]}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    interaction: {
                      mode: "index",
                      intersect: false,
                      axis: "x",
                    },
                    plugins: {
                      title: {
                        display: false,
                      },
                      legend: {
                        display: true,
                      },
                      tooltip: {
                        callbacks: {
                          beforeBody: (tooltipItems): string | string[] => {
                            switch (variableSelected) {
                              case Models.AnalyticsVariableToChart.WorkerActiveStatus:
                                allData.forEach((data, index) => {
                                  const dataIndexWorkers = tooltipItems[0].dataIndex;
                                  const assigned = data[dataIndexWorkers] ? data[dataIndexWorkers].assigned_workers : 0;
                                  return WebHelper.formatMessage("AnalyticsChart-tooltip-totalWorkers", {assigned});
                                });
                              case Models.AnalyticsVariableToChart.Lifts:
                              case Models.AnalyticsVariableToChart.MovementBreakdown:
                                allData.forEach((data) => {
                                  const dataIndexLifts = tooltipItems[0].dataIndex;
                                  const total = data[dataIndexLifts] ? data[dataIndexLifts].total_lifts : 0;
                                  return WebHelper.formatMessage("AnalyticsChart-tooltip-totalLifts", {total});
                                });
                              default:
                                return "";
                            }
                          },
                          label:
                            variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
                            variableSelected === Models.AnalyticsVariableToChart.Lifts
                              ? (item) => {
                                  const datasetIndex = item.datasetIndex % entities.length;
                                  const columnNumber = item.dataIndex;
                                  const entityIndex = columnNumber % entities.length;
                                  if (datasetIndex !== entityIndex) return "";
                                  else return `${item.dataset.label}: ${item.formattedValue} ${chartUnit}`;
                                }
                              : (item) => `${item.dataset.label}: ${item.formattedValue} ${chartUnit}`,
                          footer: function (tooltipItems) {
                            return tooltipItems[0].raw === 0 ? "" : undefined;
                          },
                        },
                      },
                    },
                    onClick: (event, elements) => handleOnClick(event, elements),
                    scales: {
                      y: {
                        stacked:
                          variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
                          variableSelected === Models.AnalyticsVariableToChart.Lifts,
                        ticks: {
                          callback: function (value) {
                            return `${value}`;
                          },
                          font: {size: 14},
                        },
                        title: {
                          display: true,
                          text: chartUnit,
                        },
                      },
                      x: {
                        stacked:
                          variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
                          variableSelected === Models.AnalyticsVariableToChart.Lifts,
                        ticks: {
                          autoSkip: false,
                          font: {size: 14},
                        },
                      },
                    },
                  }}
                  data={{
                    labels: labels,
                    datasets,
                  }}
                  style={{
                    height: "400px",
                    width:
                      variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
                      variableSelected === Models.AnalyticsVariableToChart.Lifts
                        ? `${entities && entities.length === 1 ? 1000 : entities.length === 2 ? 4050 : entities.length === 3 ? 6500 : entities.length === 4 ? 6050 : 7000}px`
                        : "1000px",
                  }}
                />
              ) : (
                <Result
                  className={styles.errorResult}
                  icon={null}
                  extra={<Typography.Text type="secondary">{WebHelper.formatMessage("AnalyticsChart-errorResultMessage")}</Typography.Text>}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
