import {DeviceAPIClient} from "../apiclient/device/DeviceAPIClient";
import * as DeviceTypes from "../apiclient/device/DeviceAPIClient.types";

async function devices(request: DeviceTypes.DevicesRequest): Promise<DeviceTypes.DevicesResponse> {
  return await DeviceAPIClient.devices(request);
}

async function devicesShort(request: DeviceTypes.DevicesRequest): Promise<DeviceTypes.DevicesShortResponse> {
  return await DeviceAPIClient.devicesShort(request);
}

async function device(request: DeviceTypes.DeviceRequest): Promise<DeviceTypes.DeviceResponse> {
  return await DeviceAPIClient.device(request);
}

async function createDevice(request: DeviceTypes.CreateDeviceRequest): Promise<DeviceTypes.CreateDeviceResponse> {
  return await DeviceAPIClient.createDevice(request);
}

async function deleteDevice(request: DeviceTypes.DeleteDeviceRequest): Promise<DeviceTypes.DeleteDeviceResponse> {
  return DeviceAPIClient.deleteDevice(request);
}

async function updateDevice(request: DeviceTypes.UpdateDeviceRequest): Promise<DeviceTypes.UpdateDeviceResponse> {
  return DeviceAPIClient.updateDevice(request);
}

async function updateDeviceConfiguration(
  request: DeviceTypes.UpdateDeviceConfigurationRequest
): Promise<DeviceTypes.UpdateDeviceConfigurationResponse> {
  return DeviceAPIClient.updateDeviceConfiguration(request);
}

async function allocateDeviceToDepartment(
  request: DeviceTypes.AllocateDeviceToDepartmentRequest
): Promise<DeviceTypes.AllocateDeviceToDepartmentResponse> {
  return DeviceAPIClient.allocateDeviceToDepartment(request);
}

async function allocateDevicesToDepartment(
  request: DeviceTypes.AllocateDevicesToDepartmentRequest
): Promise<DeviceTypes.AllocateDevicesToDepartmentResponse> {
  return DeviceAPIClient.allocateDevicesToDepartment(request);
}

async function assignWorkerToDevice(request: DeviceTypes.AssignWorkerToDeviceRequest): Promise<DeviceTypes.AssignWorkerToDeviceResponse> {
  return DeviceAPIClient.assignWorkerToDevice(request);
}

async function bulkCreateDevices(request: DeviceTypes.BulkCreateDevicesRequest): Promise<DeviceTypes.BulkCreateDevicesResponse> {
  return DeviceAPIClient.bulkCreateDevices(request);
}

async function archiveDevice(request: DeviceTypes.ArchiveDeviceRequest): Promise<DeviceTypes.ArchiveDeviceResponse> {
  return DeviceAPIClient.archiveDevice(request);
}

async function deviceGroups(request: DeviceTypes.DeviceGroupsRequest): Promise<DeviceTypes.DeviceGroupsResponse> {
  return await DeviceAPIClient.deviceGroups(request);
}

async function deviceSearchTree(request: DeviceTypes.DeviceSearchTreeRequest): Promise<DeviceTypes.DeviceSearchTreeResponse> {
  return await DeviceAPIClient.deviceSearchTree(request);
}

async function bulkUpdate(request: DeviceTypes.DeviceBulkUpdateRequest): Promise<DeviceTypes.DeviceBulkUpdateResponse> {
  return await DeviceAPIClient.bulkUpdate(request);
}

async function deviceCheck(request: DeviceTypes.DeviceCheckRequest): Promise<DeviceTypes.DeviceCheckResponse> {
  return await DeviceAPIClient.deviceCheck(request);
}

async function createDeviceBulk(request: DeviceTypes.CreateDeviceBulkRequest): Promise<DeviceTypes.CreateDeviceBulkResponse> {
  return await DeviceAPIClient.createDeviceBulk(request);
}

export const DeviceModule = {
  devices,
  devicesShort,
  device,
  createDevice,
  deleteDevice,
  updateDevice,
  updateDeviceConfiguration,
  allocateDeviceToDepartment,
  allocateDevicesToDepartment,
  assignWorkerToDevice,
  bulkCreateDevices,
  archiveDevice,
  deviceGroups,
  deviceSearchTree,
  bulkUpdate,
  deviceCheck,
  createDeviceBulk,
};
