import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {round} from "../../web/utils/FunctionUtils";

export class AnalyticsHistory extends BaseModel {
  interval_start_time: Date;
  interval_end_time: Date;
  total_lifts: number;
  safe_lifts: number;
  total_usage_sec: number;
  weight_offloaded: number;
  excessive_forward_lifts: number;
  prolonged_bend_lifts: number;
  side_bend_lifts: number;
  twisted_lifts: number;

  constructor() {
    super();
    makeObservable(this, {
      interval_start_time: observable,
      interval_end_time: observable,
      total_lifts: observable,
      safe_lifts: observable,
      total_usage_sec: observable,
      weight_offloaded: observable,
      excessive_forward_lifts: observable,
      prolonged_bend_lifts: observable,
      side_bend_lifts: observable,
      twisted_lifts: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsHistory, json: any) {
    if (json.interval_start_time) object.interval_start_time = new Date(json.interval_start_time);
    if (json.interval_end_time) object.interval_end_time = new Date(json.interval_end_time);

    object.weight_offloaded = round(json.weight_offloaded);
  }

  get riskyLifts(): number {
    return this.total_lifts - this.safe_lifts;
  }

  get safeLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.safe_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get riskyLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.riskyLifts / this.total_lifts) * 100, 1) : 0;
  }

  get excessiveForwardLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.excessive_forward_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get prolongedBendLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.prolonged_bend_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get twistedLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.twisted_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get sideBendLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.side_bend_lifts / this.total_lifts) * 100, 1) : 0;
  }
}
