import {DownloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Input} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import React, {FunctionComponent} from "react";

import styles from "./DevicesFilters.module.scss";
import * as Models from "../../../../../core/models";
import {ButtonType} from "../../../../components/common/button/Button";
import {TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";

type DevicesFiltersProps = {
  selectedTag: Models.DevicesTagsKey;
  setSelectedTag: (tag: Models.DevicesTagsKey) => void;
  tagCounts: {
    assigned: number;
    available: number;
    outOfService: number;
    connected: number;
    disconnected: number;
    all: number;
  };
  setSearchValue: (searchValue: string) => void;
  onFilterChange: (action: string, newSettings?: {quickFilter?: Models.DevicesTagsKey; tableSettings?: TableSettings}) => void;
  handleDownloadData?: () => void;
  loading?: boolean;
};

export const DevicesFilters: FunctionComponent<DevicesFiltersProps> = ({
  selectedTag,
  setSelectedTag,
  tagCounts,
  setSearchValue,
  onFilterChange,
  handleDownloadData,
  loading,
}) => {
  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filtersWrapper}>
        <div className={styles.filters}>
          <CheckableTag
            key={Models.DevicesTagsKey.Assigned}
            checked={selectedTag === Models.DevicesTagsKey.Assigned}
            onChange={(checked) => {
              if (checked) {
                setSelectedTag(Models.DevicesTagsKey.Assigned);
                onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.Assigned});
              }
            }}>
            {WebHelper.formatMessage("DevicesTabContent-assignedTagText")}
            <div className={styles.tagCount}>{tagCounts.assigned}</div>
          </CheckableTag>
          <CheckableTag
            key={Models.DevicesTagsKey.Available}
            checked={selectedTag === Models.DevicesTagsKey.Available}
            onChange={(checked) => {
              if (checked) {
                setSelectedTag(Models.DevicesTagsKey.Available);
                onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.Available});
              }
            }}>
            {WebHelper.formatMessage("DevicesTabContent-availableTagText")}
            <div className={styles.tagCount}>{tagCounts.available}</div>
          </CheckableTag>
          <CheckableTag
            key={Models.DevicesTagsKey.OutOfService}
            checked={selectedTag === Models.DevicesTagsKey.OutOfService}
            onChange={(checked) => {
              if (checked) {
                setSelectedTag(Models.DevicesTagsKey.OutOfService);
                onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.OutOfService});
              }
            }}>
            {WebHelper.formatMessage("DevicesTabContent-outOfServiceTagText")}
            <div className={styles.tagCount}>{tagCounts.outOfService}</div>
          </CheckableTag>
          <CheckableTag
            key={Models.DevicesTagsKey.Connected}
            checked={selectedTag === Models.DevicesTagsKey.Connected}
            onChange={(checked) => {
              if (checked) {
                setSelectedTag(Models.DevicesTagsKey.Connected);
                onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.Connected});
              }
            }}>
            {WebHelper.formatMessage("DevicesTabContent-connectedTagText")}
            <div className={styles.tagCount}>{tagCounts.connected}</div>
          </CheckableTag>
          <CheckableTag
            key={Models.DevicesTagsKey.Disconnected}
            checked={selectedTag === Models.DevicesTagsKey.Disconnected}
            onChange={(checked) => {
              if (checked) {
                setSelectedTag(Models.DevicesTagsKey.Disconnected);
                onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.Disconnected});
              }
            }}>
            {WebHelper.formatMessage("DevicesTabContent-disconnectedTagText")}
            <div className={styles.tagCount}>{tagCounts.disconnected}</div>
          </CheckableTag>
          <CheckableTag
            key={Models.DevicesTagsKey.All}
            checked={selectedTag === Models.DevicesTagsKey.All}
            onChange={(checked) => {
              if (checked) {
                setSelectedTag(Models.DevicesTagsKey.All);
                onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.All});
              }
            }}>
            {WebHelper.formatMessage("DevicesTabContent-allTagText")}
            <div className={styles.tagCount}>{tagCounts.all}</div>
          </CheckableTag>
        </div>
        <Input
          className={styles.search}
          size="small"
          placeholder={WebHelper.formatMessage("DevicesTabContent-searchButtonText")}
          prefix={<SearchOutlined />}
          onChange={(event) => setSearchValue(event.target.value)}
          allowClear
        />
      </div>
      <Button
        type={ButtonType.Default}
        icon={<DownloadOutlined />}
        shape="round"
        style={{borderColor: "#E2E8F0"}}
        onClick={handleDownloadData}
        loading={loading ?? false}>
        {WebHelper.formatMessage("Common-exportToCsv")}
      </Button>
    </div>
  );
};
