import {Col, Pagination, Row, Space, Spin, Typography} from "antd";
import React, {FunctionComponent, useMemo, useState} from "react";

import styles from "./DashboardChildrenCardsSection.module.scss";
import * as Models from "../../../../../core/models";
import {AppConfig} from "../../../../AppConfig";
import {WebHelper} from "../../../../utils/WebHelper";
import {Collapse} from "../../../common/collapse/Collapse";
import {DashboardCardWrapper} from "../dashboard-card-wrapper/DashboardCardWrapper";

type DashboardChildrenCardsSectionProps = {
  children:
    | {entities: Models.SiteInOrganization[]; type: "sites"}
    | {entities: Models.DepartmentInSite[]; type: "departments"; siteTimezone: string}
    | {entities: Models.WorkerReportGroup[]; type: "groups"; siteTimezone: string};
  onListsDrawerOpen: (
    key: string,
    drawerType: "devices" | "workers",
    entityType: Models.EntityType,
    id: string,
    timezone: string | undefined
  ) => void;
  onOpen(): void;
  loading?: boolean;
};

export const DashboardChildrenCardsSection: FunctionComponent<DashboardChildrenCardsSectionProps> = ({
  children,
  onListsDrawerOpen,
  loading,
  onOpen,
}) => {
  const CARDS_PER_PAGE = AppConfig.Components.DashboardTabContent.cardsPerPage;
  const [currentPage, setCurrentPage] = useState(1);

  const [Cards, title] = useMemo(() => {
    switch (children.type) {
      case "sites":
        return [
          children.entities.slice((currentPage - 1) * CARDS_PER_PAGE, currentPage * CARDS_PER_PAGE).map((site) => (
            <Col key={site.id} xs={24} sm={24} md={24} lg={12}>
              <DashboardCardWrapper
                entityType={Models.EntityType.Site}
                entityId={site.id}
                timeZone={site.tz_location}
                name={site.name}
                showName
                onListsDrawerOpen={onListsDrawerOpen}
              />
            </Col>
          )),
          WebHelper.formatMessage("DashboardTabContent-sites", {numberOfSites: children.entities.length}),
        ];
      case "departments":
        return [
          children.entities.slice((currentPage - 1) * CARDS_PER_PAGE, currentPage * CARDS_PER_PAGE).map((department) => (
            <Col key={department.id} xs={24} sm={24} md={24} lg={12}>
              <DashboardCardWrapper
                entityType={Models.EntityType.Department}
                entityId={department.id}
                timeZone={children.siteTimezone}
                name={department.name}
                showName
                onListsDrawerOpen={onListsDrawerOpen}
                childrenCard
              />
            </Col>
          )),
          WebHelper.formatMessage("DashboardTabContent-departments", {numberOfDepartments: children.entities.length}),
        ];
      case "groups":
        return [
          children.entities.slice((currentPage - 1) * CARDS_PER_PAGE, currentPage * CARDS_PER_PAGE).map((group) => (
            <Col key={group.id} xs={24} sm={24} md={24} lg={12}>
              <DashboardCardWrapper
                entityType={Models.EntityType.WorkerReportGroup}
                entityId={group.id}
                timeZone={children.siteTimezone}
                name={group.name}
                showName
                onListsDrawerOpen={onListsDrawerOpen}
              />
            </Col>
          )),
          WebHelper.formatMessage("DashboardChildrenCardsSection-workerReportGroups", {numberOfGroups: children.entities.length}),
        ];
      default:
        return [];
    }
  }, [CARDS_PER_PAGE, children, currentPage, onListsDrawerOpen]);

  return (
    <Collapse
      header={
        <Space size={16}>
          <Typography.Title className={styles.numberDisplay} level={5}>
            {loading ? <Spin size="small" spinning /> : children.entities.length ?? "-"}
          </Typography.Title>
          <Typography.Title className={styles.panelHeaderText} level={5}>
            {title}
          </Typography.Title>
        </Space>
      }
      onChange={(keys) => {
        if (keys.includes("header_panel")) {
          onOpen();
        }
      }}
      isOpen={false}
      disabled={children.entities.length === 0}>
      <Row gutter={[16, 16]}>
        {Cards}
        <div className={styles.paginationWrapper}>
          <Pagination current={currentPage} pageSize={CARDS_PER_PAGE} total={children.entities.length} onChange={setCurrentPage} />
        </div>
      </Row>
    </Collapse>
  );
};
export default DashboardChildrenCardsSection;
