import {DownloadOutlined} from "@ant-design/icons";
import {Button, Dropdown, Typography} from "antd";
import {differenceInDays, differenceInWeeks} from "date-fns";
import {jsPDF} from "jspdf";
import {useFlags} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useEffect, useState} from "react";
import {AppStore, SegmentKey} from "web/stores/AppStore";
import {SegmentFileType} from "web/utils/SegmentHelper";
import {WebHelper, convertDivToBase64} from "web/utils/WebHelper";
import * as XLSX from "xlsx";

import styles from "./ActiveInactiveWorkersReportDropdown.module.scss";
import targetIcon from "../../../../../../assets/images/common/Target_Icon.svg";
import targetIconBlock from "../../../../../../assets/images/common/Target_Icon_Block.svg";
import targetIconDown from "../../../../../../assets/images/common/Target_Icon_Down.svg";
import targetIconUnset from "../../../../../../assets/images/common/Target_Icon_Unset.svg";
import targetIconUp from "../../../../../../assets/images/common/Target_Icon_Up.svg";
import companyIcon from "../../../../../../assets/images/common/Verve_Logo_SafetyOrange.png";
import * as Models from "../../../../../../core/models";

const BATCH_SIZE = 15;

export type ActiveInactiveWorkersReportDropdownProps = {
  workersActive: Models.AnalyticsDashboardWorker[];
  workersInactive: Models.AnalyticsDashboardWorker[];
  department: Models.Department;
};

const sortDataByName = (data: any[]) => {
  data.sort((a, b) =>
    a[WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-nameColumnTitle")] >
    b[WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-nameColumnTitle")]
      ? 1
      : -1
  );
};

const ActiveInactiveWorkersReportDropdown: FunctionComponent<ActiveInactiveWorkersReportDropdownProps> = ({
  workersActive,
  workersInactive,
  department,
}) => {
  const appStore = AppStore.getInstance();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeWorkerBatches, setActiveWorkerBatches] = useState<{[key: string]: string | number}[][]>([]);
  const [inactiveWorkerBatches, setInactiveWorkerBatches] = useState<{[key: string]: string | number}[][]>([]);

  const flags = useFlags();

  const generateBatches = () => {
    const activeBatches = [];
    const inactiveBatches = [];
    const [activeWorkerData, inactiveWorkerData] = generateWorkerData();

    if (activeWorkerData) {
      sortDataByName(activeWorkerData);
      for (let i = 0; i < activeWorkerData.length; i += BATCH_SIZE) {
        activeBatches.push(activeWorkerData.slice(i, i + BATCH_SIZE));
      }
    }

    if (inactiveWorkerData) {
      sortDataByName(inactiveWorkerData);
      for (let i = 0; i < workersInactive.length; i += BATCH_SIZE) {
        inactiveBatches.push(inactiveWorkerData.slice(i, i + BATCH_SIZE));
      }
    }

    return [activeBatches, inactiveBatches];
  };

  useEffect(() => {
    const [activeBatches, inactiveBatches] = generateBatches();
    if (activeBatches) setActiveWorkerBatches(activeBatches);
    setInactiveWorkerBatches(inactiveBatches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workersActive, workersInactive]);

  const generateWorkerData = (): [{[key: string]: number | string}[] | undefined, {[key: string]: number | string}[] | undefined] => {
    const activeWorkerData: {[key: string]: number | string}[] | undefined = workersActive.map((worker) => {
      const weeksSinceFirstUse = worker.worker.first_session_start_at
        ? differenceInWeeks(new Date(), worker.worker.first_session_start_at)
        : "";

      const workerInfo = worker.worker;
      const workerDisplay =
        workerInfo.fullName.trimAll() !== "" && workerInfo.employee_id
          ? `${workerInfo.displayName} (${workerInfo.employee_id})`
          : workerInfo.displayName;

      const report = {
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-workerColumnTitle")]: workerDisplay,
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-deviceTagColumnTitle")]: workerInfo.device_tag,
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-weeksSinceFirstUseColumnTitle")]: weeksSinceFirstUse,
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-daysActiveLast7DaysColumnTitle")]: worker.days_active_last_7_days,
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-hoursLast7DaysColumnTitle")]: worker.hours_active_last_7_days,
      };

      if (flags.target_utilization_hours && department.target_type !== Models.TargetType.None) {
        report[WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-relativeToTargetColumnTitle")] = workerInfo.on_target;
        if (workerInfo.on_target !== Models.OnTarget.Exempt && workerInfo.on_target !== Models.OnTarget.Unset)
          report[WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-hoursLast7DaysColumnTitle")] =
            `${worker.hours_active_last_7_days} / ${workerInfo.lower_bound}`;
      }

      return report;
    });

    const inactiveWorkerData: {[key: string]: number | string}[] | undefined = workersInactive.map((worker) => {
      const daysSinceLastUse = worker.worker.most_recent_session_start_at
        ? differenceInDays(new Date(), worker.worker.most_recent_session_start_at)
        : "";

      const weeksSinceFirstUse = worker.worker.first_session_start_at
        ? differenceInWeeks(new Date(), worker.worker.first_session_start_at)
        : "";

      const workerInfo = worker.worker;
      const workerDisplay =
        workerInfo.fullName.trimAll() !== "" && workerInfo.employee_id
          ? `${workerInfo.displayName} (${workerInfo.employee_id})`
          : workerInfo.displayName;

      return {
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-workerColumnTitle")]: workerDisplay,
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-deviceTagColumnTitle")]: workerInfo.device_tag,
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-weeksSinceFirstUseColumnTitle")]: weeksSinceFirstUse,
        [WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-daysSinceLastUsageColumnTitle")]: daysSinceLastUse,
      };
    });

    return [activeWorkerData, inactiveWorkerData];
  };

  const downloadWorkersXLSX = (e: React.MouseEvent<HTMLElement>) => {
    setIsLoading(true);
    e.stopPropagation();
    const [activeWorkerData, inactiveWorkerData] = generateWorkerData();

    activeWorkerData && sortDataByName(activeWorkerData);
    inactiveWorkerData && sortDataByName(inactiveWorkerData);

    appStore.sendAnalyticTrack(SegmentKey.DashboardWorkerActivityListDownload, {
      departmentID: department.id,
      siteID: department.site.id,
      orgID: department.organization.id,
      fileType: SegmentFileType.XLSX,
    });

    const wb = XLSX.utils.book_new();

    if (activeWorkerData?.length && activeWorkerData?.length > 0) {
      const ws = XLSX.utils.json_to_sheet(activeWorkerData);
      XLSX.utils.book_append_sheet(wb, ws, "Active");
    }

    if (inactiveWorkerData?.length && inactiveWorkerData?.length > 0) {
      const ws = XLSX.utils.json_to_sheet(inactiveWorkerData);
      XLSX.utils.book_append_sheet(wb, ws, "Inactive");
    }
    XLSX.writeFile(wb, `${WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-WorkersActivityReport")}.xlsx`);
    setIsLoading(false);
  };

  const generatePDF = async () => {
    setIsLoading(true);
    const letterSheetSize = [612, 792]; //Letter
    const pdf = new jsPDF({
      orientation: "p", //portrait
      unit: "pt", //points
      format: letterSheetSize,
    });

    const hiddenDiv = document.getElementById("hiddenWrapper");
    if (hiddenDiv) {
      const renderPage = async (pageNum: number, prefix: "active" | "inactive") => {
        hiddenDiv.style.visibility = "visible";
        const imgData = await document.getElementById(`${prefix}-${pageNum}`); //create the canvas img
        if (imgData) {
          const [base64Image, scaledHeight] = await convertDivToBase64(imgData, letterSheetSize, false);
          pdf.addImage(base64Image, "PNG", 0, 0, letterSheetSize[0], scaledHeight);
        }
        hiddenDiv.style.visibility = "hidden";
      };

      //active worker pages
      for (let i = 0; i < activeWorkerBatches.length; i++) {
        if (i !== 0) pdf.addPage();
        await renderPage(i, "active");
      }

      //inactive worker pages
      for (let i = 0; i < inactiveWorkerBatches.length; i++) {
        if (i !== 0 || activeWorkerBatches.length > 0) pdf.addPage(); // if active workers pages, add a page
        await renderPage(i, "inactive");
      }

      //add page numbers at the bottom
      const pageCount = pdf.getNumberOfPages();
      const margin = 40;
      pdf.setFontSize(10); //this only affects the text we are going to add next, not the tables
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.text(
          WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-pageCount", {currentPage: i, totalPages: pageCount}),
          margin,
          letterSheetSize[1] - margin,
          {align: "left"}
        );
      }

      await pdf.save(`${department.name}-${WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-WorkersActivityReport")}.pdf`);
      appStore.sendAnalyticTrack(SegmentKey.DashboardWorkerActivityListDownload, {
        departmentID: department.id,
        siteID: department.site.id,
        orgID: department.organization.id,
        fileType: SegmentFileType.PDF,
      });
      setIsLoading(false);
    }
  };

  const downloadMenu = [
    {
      label: (
        <div role="presentation" onClick={(e) => downloadWorkersXLSX(e)}>
          {WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-asXLSX")}
        </div>
      ),
      key: "csv",
    },
    {
      label: (
        <div
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            generatePDF();
          }}>
          {WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-asPDF")}
        </div>
      ),
      key: "pdf",
    },
  ];

  const renderHeader = (prefix: "active" | "inactive") => (
    <div className={styles.header}>
      <img className={styles.companyIcon} src={companyIcon} alt="Company Logo" />
      <Typography.Text strong>
        {WebHelper.formatMessage(
          prefix == "active" ? "ActiveInactiveWorkersReportDropdown-activeWorkers" : "ActiveInactiveWorkersReportDropdown-inactiveWorkers"
        )}
      </Typography.Text>
    </div>
  );
  return (
    <div>
      <Dropdown
        menu={{items: downloadMenu}}
        placement="bottomLeft"
        disabled={isLoading || (workersActive.length == 0 && workersInactive.length == 0)}>
        <Button shape="round" loading={isLoading} icon={<DownloadOutlined />} onClick={(e) => e.stopPropagation()}>
          {WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-downloadData")}
        </Button>
      </Dropdown>
      <div className={styles.hiddenContainer}>
        <div className={styles.hiddenWrapper} id="hiddenWrapper">
          {activeWorkerBatches.map((batch, i) => (
            <div className={styles.page} id={`active-${i}`} key={`active-${i}`}>
              {renderHeader("active")}
              <div
                className={`${styles.tableTitlesActive} ${flags.target_utilization_hours && department.target_type !== Models.TargetType.None ? "" : styles.sixColumns}`}>
                {Object.keys(batch[0]).map((key, j) => {
                  return (
                    <Typography.Text className={styles.cellText} key={`active-title-${i}${j}`}>
                      {key}
                    </Typography.Text>
                  );
                })}
              </div>
              {batch.map((row, k) => (
                <div
                  className={`${styles.tableRowActive} ${flags.target_utilization_hours && department.target_type !== Models.TargetType.None ? "" : styles.sixColumns}`}
                  key={`active-row-${k}${i}`}>
                  {Object.values(row).map((value, j) => {
                    let renderValue: any = value;
                    if (j === 6) {
                      //relative target column
                      const icon =
                        value === Models.OnTarget.Meets
                          ? targetIcon
                          : value === Models.OnTarget.Overused
                            ? targetIconUp
                            : value === Models.OnTarget.Below
                              ? targetIconDown
                              : value === Models.OnTarget.Unset
                                ? targetIconUnset
                                : targetIconBlock;
                      renderValue = <img alt="" src={icon} />;
                    }
                    if (j === 5 && flags.target_utilization_hours && department.target_type !== Models.TargetType.None) {
                      //hours last 7 days column
                      const onTarget = batch[k][WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-relativeToTargetColumnTitle")];
                      const status =
                        onTarget === Models.OnTarget.Meets
                          ? styles.up
                          : onTarget === Models.OnTarget.Overused
                            ? styles.medium
                            : onTarget === Models.OnTarget.Below
                              ? styles.down
                              : "";
                      renderValue = (
                        <>
                          <span className={`${styles.circleSolid} ${status}`} />
                          {`${value}`}
                        </>
                      );
                    }
                    return (
                      <Typography.Text className={styles.cellText} key={`active-rowValue-${i}${k}${j}`}>
                        {renderValue}
                      </Typography.Text>
                    );
                  })}
                </div>
              ))}
            </div>
          ))}
          {inactiveWorkerBatches.map((batch, i) => (
            <div className={styles.page} id={`inactive-${i}`} key={`inactive-${i}`}>
              {renderHeader("inactive")}
              <div className={styles.tableTitlesInactive}>
                {Object.keys(batch[0]).map((key, j) => (
                  <Typography.Text className={styles.cellText} key={`inactive-title-${i}${j}`}>
                    {key}
                  </Typography.Text>
                ))}
              </div>
              {batch.map((row, k) => (
                <div className={styles.tableRowInactive} key={`inactive-row-${i}${k}`}>
                  {Object.values(row).map((value, j) => (
                    <Typography.Text className={styles.cellText} key={`inactive-rowValue-${i}${k}${j}`}>
                      {value}
                    </Typography.Text>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActiveInactiveWorkersReportDropdown;
