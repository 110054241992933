import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import {AnalyticsBrowser} from "@segment/analytics-next";
import {message} from "antd";
import localforage from "localforage";
import {runInAction, ObservableMap, makeObservable, observable, action} from "mobx";
import React, {ReactNode} from "react";

import {en_US} from "../../core/locales/en_US";
import {es_ES} from "../../core/locales/es_ES";
import {fr_CA} from "../../core/locales/fr_CA";
import {AuthenticationDataStore} from "../../core/stores/AuthenticationDataStore";
import {DataStore} from "../../core/stores/DataStore";
import {CoreHelper} from "../../core/utils/CoreHelper";
import {AppConfig} from "../AppConfig";
import {AboutUsProps} from "../components/about-us/AboutUs";
import {AccountSettingsProps} from "../components/account-settings/AccountSettings";
import {cloneDeep, isArray, isNil} from "../utils/FunctionUtils";

const antd_locales = {
  [en_US.code]: require("antd/lib/locale/en_US").default,
  [es_ES.code]: require("antd/lib/locale/es_ES").default,
  [fr_CA.code]: require("antd/lib/locale/fr_CA").default,
};

export type MessageType = "success" | "error" | "info";

export type AppMode = "mobileXs" | "mobileSm" | "mobileMd" | "desktop";

export type InputMode = "touch" | "mouse";

export enum StorageKey {
  ExampleKey = "ExampleKey",
}

export type AppComponentPropsType = {
  AccountSettings: AccountSettingsProps;
  AboutUs: AboutUsProps;
};

export type AppComponentState = {
  visible: boolean;
  props?: any;
};

export type AppStoreState = {
  initializing: boolean;
  initialized: boolean;
  mode: AppMode | undefined;
  inputMode: InputMode;

  messageKeys: string[];
  stateByComponentType: ObservableMap<keyof AppComponentPropsType, AppComponentState>;
};

const storage = localforage.createInstance({
  name: "default",
});

const SEGMENT_GLOBAL_VALUE = "GLOBAL";

export enum SegmentKey {
  AccountSettingsOpened = "Account Settings Opened",
  AddDepartmentOpened = "Add Department Opened",
  AddedNewDepartment = "Added New Department",
  AddedNewOrganization = "Added New Organization",
  AddedNewSite = "Added New Site",
  AddedNewWorkers = "Added New Workers",
  AddOrganizationOpened = "Add Organization Opened",
  AddRemoveWorkerFromGroupSingle = "Add/Remove Worker from Group - Single",
  AddSiteOpened = "Add Site Opened",
  AddWorkerFromGroupBulk = "Add Worker from Group - Bulk",
  AddWorkerOpened = "Add Worker Opened",
  AllocatedDevices = "Allocated Devices",
  AllocateDeviceOpened = "Allocate Device Opened",
  AllOrganizationsFilterChanged = "All Organizations Filter Changed",
  AllOrganizationsDashboardOpened = "All Organizations Dashboard Opened",
  AllOrganizationsDataDownloaded = "All Organizations Data Downloaded",
  AnalyticsOpened = "Analytics Opened",
  ChangeDeviceAssignment = "Change Device Assignment",
  ChangeDeviceStatus = "Change Device Status",
  ChangeWorkerEnrollmentStatus = "Change Worker Enrollment Status",
  CheckedOutViewTableSettingsChanged = "Checked Out View - Table Settings Changed",
  CreateReportGroup = "Create Report Group",
  CreateWorkerBulk = "Create Worker - Bulk",
  CreateWorkerManual = "Create Worker - Manual",
  DashboardDailyUsageBreakdownChangeDate = "Dashboard Daily Usage Breakdown - Change Date",
  DashboardDailyUsageBreakdownDownload = "Dashboard Daily Usage Breakdown - Download",
  DashboardDailyUsageBreakdownTableSettingsChanged = "Dashboard Daily Usage Breakdown - Table Settings Changed",
  DashboardDailyUsageBreakdownView = "Dashboard Daily Usage Breakdown - View",
  DashboardWorkerMetricsDownload = "Dashboard Worker Metrics - Download",
  DashboardSiteDetailOpened = "Dashboard Site Detail Opened",
  DashboardWorkerActivityListDownload = "Dashboard Worker Activity List - Download",
  DashboardWorkerActivityListView = "Dashboard Worker Activity List - View",
  DashboardWorkerMetricsToggle = "Dashboard Worker Metrics - Toggle",
  DashboardWorkerMetricsView = "Dashboard Worker Metrics - View",
  DepartmentDashboardOpened = "Department Dashboard Opened",
  DepartmentDetailOpened = "Department Detail Opened",
  DepartmentDevicesTableSettingsChanged = "Department Devices Table Settings Changed",
  DepartmentGatewaysDetailOpened = "Department Gateways Detail Opened",
  DepartmentsSectionOpened = "Site Dashboard - Departments Section Opened",
  DeviceCreated = "Device Created",
  DeviceDetailOpened = "Device Detail Opened",
  DeviceUsageReassignmentSettingsChanged = "Device Usage Reassignment Settings Changed",
  DevicesTabOpened = "Devices Tab Opened",
  DevicesManagementOpened = "Devices Management Opened",
  DevicesManagementGatewayTabOpened = "Devices Management Gateway Tab Opened",
  DevicesManagementDevicesTabOpened = "Devices Management Devices Tab Opened",
  DiagnosticsDeviceDetailOpened = "Diagnostics Device Detail Opened",
  DiagnosticsDevicesTableSettingsChanged = "Diagnostics Devices Table Settings Changed",
  DownloadAllOrganizationsDataDrawerOpened = "Download All Organizations Data Drawer Opened",
  DownloadAnalyticsData = "Download Analytics Data",
  DownloadAnalyticsGraph = "Download Analytics Graph",
  DownloadSafeliftReportBulk = "Download Safelift Report - Bulk",
  DownloadSafeliftReportSingle = "Download Safelift Report - Single",
  GatewayAllocated = "Gateway Allocated",
  GatewaysTableSettingsChanged = "Gateways Table Settings Changed",
  GroupsSectionOpened = "Site Dashboard - Groups Section Opened",
  IndividualWorkerReportGroupPageOpened = "Individual Worker Report Group Page Opened",
  LiveCheckedOutTabOpened = "Live Checked Out Tab Opened",
  NewAccountCreated = "New Account Created",
  OrganizationDashboardOpened = "Organization Dashboard Opened",
  OrganizationDetailOpened = "Organization Detail Opened",
  OrganizationsOpened = "Organizations Opened",
  OrganizationsTableSettingsChanged = "Organizations Table Settings Changed",
  ReportIssueOpened = "Report Issue Opened",
  SessionDetailOpened = "Session Detail Opened",
  SessionsTableSettingsChanged = "Sessions Table Settings Changed",
  SignOut = "Sign Out",
  SiteDashboardOpened = "Site Dashboard Opened",
  SiteDetailOpened = "Site Detail Opened",
  SitesSectionOpened = "Organization Dashboard - Sites Section Opened",
  SiteSummaryReportView = "Site Summary Report - View",
  SiteSummaryReportDownloaded = "Site Summary Report Downloaded",
  SiteSummaryReportDefaultSettingsChanged = "Site Summary Report Default Settings Changed",
  UserPermissionsOpened = "User Permissions Opened",
  UserPermissionsTableSettingsChanged = "User Permissions Table Settings Changed",
  ViewAnalyticsPage = "View Analytics Page",
  ViewSafeliftReportBulk = "View Safelift Report - Bulk",
  ViewSafeliftReportSingle = "View Safelift Report - Single",
  WorkersTabOpened = "Workers Tab Opened",
  WorkerDetailOpened = "Worker Detail Opened",
  WorkerReportGroupTabOpened = "Worker Report Group Tab Opened",
  WorkerReportGroupWorkersTableSettingsChanged = "Worker Report Group Workers Table Settings Changed",
  WorkersTableSettingsChanged = "  Workers Table Settings Changed",
}

export class AppStore {
  private static instance: AppStore;

  static Intl = {
    antd_locales: antd_locales,
  };

  private initialConfig: any;
  private disposers: (() => void)[] = [];

  mobileXsBreakpoint = 575; // xs breakpoint
  mobileSmBreakpoint = 768; // sm breakpoint
  mobileMdBreakpoint = 992; // md breakpoint

  analytics = AnalyticsBrowser.load({writeKey: AppConfig.Settings.Segment.writeKey});

  dataStore = DataStore.getInstance();

  // STATE
  private initialState: AppStoreState = {
    initializing: false,
    initialized: false,
    mode: this.evaluateMode(),
    inputMode: "touch",

    messageKeys: [],
    stateByComponentType: new ObservableMap(),
  };

  state: AppStoreState = cloneDeep(this.initialState);

  private isTouch = false;
  private inputModeTimer: any | undefined;

  private constructor() {
    makeObservable(this, {
      state: observable,
      initialize: action,
      reset: action,
      setConfig: action,
      resetConfig: action,
      setInputMode: action,
      setMode: action,
      showMessage: action,
      hideMessage: action,
      hideOldestMessage: action,
      hideAllMessages: action,
      showComponent: action,
      hideComponent: action,
      isComponentVisible: action,
    });
  }

  static getInstance(): AppStore {
    if (!this.instance) this.instance = new AppStore();

    return this.instance;
  }

  async initialize() {
    const {dataStore, state} = this;

    state.initialized = false;
    state.initializing = true;

    await dataStore.initialize();
    await this.dataStore.setLocale(AppConfig.Settings.Localization.defaultLocale);

    runInAction(() => {
      state.initializing = false;
      state.initialized = true;
    });

    this.handlerWindowResize = this.handlerWindowResize.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);

    if (document.documentElement) document.documentElement.setAttribute("data-browser", navigator.userAgent);

    window.addEventListener("resize", this.handlerWindowResize);
    document.addEventListener("touchstart", this.handleTouchStart);
    document.addEventListener("mouseover", this.handleMouseOver);

    this.initialConfig = cloneDeep(AppConfig);

    return AppStore.instance;
  }

  async reset() {
    const {dataStore, disposers} = this;

    disposers.forEach((d) => d());
    this.disposers = [];
    await Promise.all([dataStore.reset(), this.cleanStorage()]);

    window.removeEventListener("resize", this.handlerWindowResize);
    document.removeEventListener("touchstart", this.handleTouchStart);
    document.removeEventListener("mouseover", this.handleMouseOver);

    this.analytics.reset();

    this.state = cloneDeep(this.initialState);
  }

  private handleTouchStart() {
    const {inputMode} = this.state;

    clearTimeout(this.inputModeTimer);

    this.isTouch = true;
    if (inputMode !== "touch") this.setInputMode("touch");

    this.inputModeTimer = setTimeout(() => (this.isTouch = false), 500);
  }

  private handleMouseOver() {
    const {isTouch} = this;
    const {inputMode} = this.state;

    if (!isTouch && inputMode === "touch") this.setInputMode("mouse");
  }

  private evaluateMode(): AppMode {
    const {mobileXsBreakpoint, mobileSmBreakpoint, mobileMdBreakpoint} = this;

    let mode: AppMode = "desktop";
    if (window.innerWidth < mobileXsBreakpoint) {
      mode = "mobileXs";
    } else if (window.innerWidth < mobileSmBreakpoint) {
      mode = "mobileSm";
    } else if (window.innerWidth < mobileMdBreakpoint) {
      mode = "mobileMd";
    }

    return mode;
  }

  private handlerWindowResize() {
    const mode = this.evaluateMode();

    this.setMode(mode);
  }

  // region ACTIONS

  setConfig(config: any) {
    console.assert(!isNil(config));

    CoreHelper.mergeWith(AppConfig, config, true, (value: any, sourceValue: any, key: string) => {
      switch (key) {
        case "schemas":
          return sourceValue;
        default:
          return isArray(value) ? sourceValue : undefined;
      }
    });

    this.dataStore.setLocale(AppConfig.Settings.Localization.defaultLocale);
  }

  resetConfig() {
    CoreHelper.mergeWith(AppConfig, this.initialConfig);
  }

  setInputMode(inputMode: InputMode) {
    this.state.inputMode = inputMode;
  }

  setMode(mode: AppMode) {
    console.assert(!isNil(mode));

    this.state.mode = mode;
  }

  isMobile(): boolean {
    return this.state.mode === "mobileXs" || this.state.mode === "mobileSm" || this.state.mode === "mobileMd";
  }

  showMessage(content: string | ReactNode, type: MessageType, duration: number = AppConfig.Modules.App.message.duration): string {
    if (this.state.messageKeys.length >= 5) this.hideOldestMessage();

    const messageKey = CoreHelper.getUUID();

    switch (type) {
      case "success":
        message.open({
          content: content,
          icon: <CheckCircleFilled style={{color: "#000000"}} />,
          duration: duration,
          type: type,
          key: messageKey,
        });
        break;
      case "error":
        message.open({
          content: content,
          icon: <CloseCircleFilled style={{color: "#d8431a"}} />,
          duration: duration,
          type: type,
          key: messageKey,
        });
        break;
      default:
        message.open({content: content, duration: duration, type: type, key: messageKey});
        break;
    }

    this.state.messageKeys.push(messageKey);
    return messageKey;
  }

  hideMessage(messageKey: string) {
    this.state.messageKeys = this.state.messageKeys.filter((value) => value != messageKey);
    message.destroy(messageKey);
  }

  hideOldestMessage() {
    if (this.state.messageKeys.length > 0) {
      const messageKey = this.state.messageKeys[0];
      this.state.messageKeys.splice(0, 1);
      message.destroy(messageKey);
    }
  }

  hideAllMessages() {
    this.state.messageKeys.forEach((messageKey) => message.destroy(messageKey));
    this.state.messageKeys = [];
  }

  showComponent<P extends keyof AppComponentPropsType>(type: P, props?: AppComponentPropsType[P]) {
    const {stateByComponentType} = this.state;

    const componentState = stateByComponentType.get(type);

    if (!isNil(componentState)) {
      componentState.visible = true;
      componentState.props = props;
    } else {
      stateByComponentType.set(type, {
        visible: true,
        props: props,
      });
    }
  }

  hideComponent(type: keyof AppComponentPropsType) {
    const {stateByComponentType} = this.state;

    const componentState = stateByComponentType.get(type);
    if (!isNil(componentState)) componentState.visible = false;
  }

  isComponentVisible(type: keyof AppComponentPropsType): boolean {
    const {stateByComponentType} = this.state;

    const component = stateByComponentType.get(type);

    return !isNil(component) && component.visible;
  }

  // endregion

  getStorageItem<TValue>(item: StorageKey) {
    return storage.getItem<TValue>(item);
  }

  setStorageItem<TValue>(item: StorageKey, value: TValue) {
    return storage.setItem(item, value);
  }

  deleteStorageItem(item: StorageKey) {
    return storage.removeItem(item);
  }

  cleanStorage() {
    return storage.clear();
  }

  sendAnalyticTrack(key: SegmentKey, properties?: any) {
    const authenticationStore = AuthenticationDataStore.getInstance();

    if (window.location.hostname !== "localhost")
      this.analytics.track(key, {
        ...properties,
        userOrg: authenticationStore.state.user?.organization_id ?? SEGMENT_GLOBAL_VALUE,
      });
  }
}
