import {DownloadOutlined} from "@ant-design/icons";
import {Button, Modal} from "antd";
import React, {FunctionComponent, useState} from "react";

import styles from "./SiteSummaryReportPreviewModal.module.scss";
import * as Models from "../../../../../core/models";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {WebHelper} from "../../../../utils/WebHelper";
import {SiteSummaryReportPDF} from "../site-summary-report-pdf/SiteSummaryReportPDF";

type SiteSummaryReportPreviewModalProps = {
  endDate: string;
  site: Models.Site;
  personalSiteConfig: {
    active_list_report_included: boolean;
    leaderboard_report_included: boolean;
    weight_offloaded_report_included: boolean;
    safelift_score_report_included: boolean;
    groups: {
      id: string;
      name: string;
    }[];
  } | null;
  workerReportGroups: Models.WorkerReportGroup[];
  siteAnalyticsData?: Models.AnalyticsDashboardCard;
  siteDepartmentsData: {id: string; data: Models.AnalyticsDashboardCard}[];
  siteGroupsData: {id: string; data: Models.AnalyticsDashboardCard}[];
  open: boolean;
  onCancel: () => void;
  targetUtilization: boolean;
  dataDepartments: {id: string; data: Models.AnalyticsDashboardWorkerData}[];
};

export const SiteSummaryReportPreviewModal: FunctionComponent<SiteSummaryReportPreviewModalProps> = ({
  endDate,
  site,
  personalSiteConfig,
  workerReportGroups,
  siteAnalyticsData,
  siteDepartmentsData,
  siteGroupsData,
  open,
  onCancel,
  targetUtilization,
  dataDepartments,
}) => {
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  return (
    <Modal
      className={styles.modal}
      zIndex={1001}
      open={open}
      title={WebHelper.formatMessage("SiteSummaryReportPreviewModal-title")}
      centered
      footer={null}
      onCancel={onCancel}>
      <div className={styles.wrapper}>
        <Button
          onClick={() => setLoadingDownload(true)}
          loading={loadingDownload}
          shape="round"
          type="primary"
          icon={<DownloadOutlined />}
          className={styles.downloadReportButton}>
          {WebHelper.formatMessage("SiteSummaryReportPreviewModal-download")}
        </Button>
        <div className={styles.contentWrapper}>
          <div className={styles.reportWrapper}>
            <SiteSummaryReportPDF
              endDate={endDate}
              loadingDownload={loadingDownload}
              setLoadingDownload={setLoadingDownload}
              site={site}
              personalSiteConfig={personalSiteConfig}
              workerReportGroups={workerReportGroups}
              siteAnalyticsData={siteAnalyticsData}
              siteDepartmentsData={siteDepartmentsData}
              siteGroupsData={siteGroupsData}
              targetUtilization={targetUtilization}
              dataDepartments={dataDepartments}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
