import {ArrowLeftOutlined, PlusOutlined} from "@ant-design/icons";
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {Button as AntdButton, Dropdown, Empty, Space, Tabs} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useMemo, useState, lazy, Suspense} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PageTitle} from "web/components/common/page-title/PageTitle";

import {DeviceManagementSearchBar} from "./device-management-search-bar/DeviceManagementSearchBar";
import {DeviceManagementStats} from "./device-management-stats/DeviceManagementStats";
import styles from "./DeviceManagementDevices.module.scss";
import syncIcon from "../../../../assets/images/common/Sync-Icon.svg";
import {DeviceManagementSelectOptions} from "../../../../core/apiclient/device/DeviceAPIClient.types";
import * as Models from "../../../../core/models";
import {DeviceManagementTabKeys as TabKeys} from "../../../../core/models/UserPreferences";
import {DeviceModule} from "../../../../core/modules/DeviceModule";
import {GatewayModule} from "../../../../core/modules/GatewayModule";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {Button, ButtonType} from "../../../components/common/button/Button";
import {Subheader} from "../../../components/common/subheader/Subheader";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {isNil} from "../../../utils/FunctionUtils";
import {WebHelper} from "../../../utils/WebHelper";

const GatewaysTable = lazy(() => import("../../../components/gateways-table/GatewaysTable"));
const DeviceManagementDevicesTable = lazy(
  () => import("../../../components/devices-table/device-management-devices-table/DeviceManagementDevicesTable")
);

const BulkCreateDevicesModal = lazy(() => import("../../../components/bulk-create-devices-modal/BulkCreateDevicesModal"));
const BulkUpdateDevicesModal = lazy(() => import("../../../components/bulk-update-devices-modal/BulkUpdateDevicesModal"));
const CreateDeviceModal = lazy(() => import("../../../components/create-device-modal/CreateDeviceModal"));

const defaultActiveKey = TabKeys.DeviceConfig;

const defaultSettingsDeviceManagementDevicesTable: Models.DeviceManagementDevicesTableSettings = {
  pageSize: 10,
  sortColumn: undefined,
  sortOrder: "ascend",
  connectedFilteredValue: null,
  hardwareRevFilteredValue: null,
  firmwareRevFilteredValue: null,
  configFilteredValue: null,
};

const defaultSettingsGatewaysTable: Models.GatewayTableSettings = {
  pageSize: 10,
  sortColumn: undefined,
  sortOrder: "ascend",
  connectedFilteredValue: null,
  firmwareFilteredValue: null,
  currentSoftwareFilteredValue: null,
  statusFilteredValue: null,
};

type DeviceQueryParams = {department_id?: string} | {site_id?: string} | {organization_id?: string};
export type DeviceManagementDevicesProps = {};

export const DeviceManagementDevices: FunctionComponent<DeviceManagementDevicesProps> = observer(() => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const navigate = useNavigate();

  const [userPreferences, setUserPreferences] = useState({
    tableSettings: {
      deviceManagementModeDevices: defaultSettingsDeviceManagementDevicesTable,
      gateways: defaultSettingsGatewaysTable,
    },
    deviceManagementActiveTabKey: defaultActiveKey,
  });

  const deviceManagementActiveTabKey: TabKeys = useMemo(
    () => userPreferences.deviceManagementActiveTabKey ?? defaultActiveKey,
    [userPreferences]
  );

  const [isEditingFilter, setIsEditingFilter] = useState<boolean>(false);
  const [devices, setDevices] = useState<Models.DeviceShort[]>([]);
  const [devicesToShow, setDevicesToShow] = useState<Models.DeviceShort[]>([]);
  const [gateways, setGateways] = useState<Models.Gateway[]>([]);
  const [latestVersion, setLatestVersion] = useState<Models.GatewayVersion>();
  const [latestBinaryVersion, setLatestBinaryVersion] = useState<Models.BinaryVersion>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(
    searchParams.get("orgID")
      ? searchParams.get("orgID")
      : searchParams.get("unallocated") === "true"
        ? DeviceManagementSelectOptions.Unallocated
        : null
  );
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(searchParams.get("siteID"));
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string | null>(searchParams.get("departmentID"));
  const [selectedEntityName, setSelectedEntityName] = useState<string[] | undefined>();
  const [isCreateDeviceModalVisible, setIsCreateDeviceModalVisible] = useState(false);
  const [isBulkUpdateDevicesModalVisible, setIsBulkUpdateDevicesModalVisible] = useState(false);
  const [isBulkCreateDevicesModalVisible, setIsBulkCreateDevicesModalVisible] = useState(false);
  const [serialNumberSearchValue, setSerialNumberSearchValue] = useState(searchParams.get("serialNumber") ?? "");
  const [deviceTagSearchValue, setDeviceTagSearchValue] = useState(searchParams.get("deviceTag") ?? "");
  const [devicesTabIsEmpty, setDevicesTabIsEmpty] = useState(false);
  const [includeArchived, setIncludeArchived] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetchNew, setShouldFetchNew] = useState<boolean>(false);
  const [currentSerialNumber, setCurrentSerialNumber] = useState(serialNumberSearchValue);
  const [currentDeviceTag, setCurrentDeviceTag] = useState(deviceTagSearchValue);
  const [currentEntity, setCurrentEntity] = useState<DeviceQueryParams | null>(
    selectedDepartmentId
      ? {department_id: selectedDepartmentId}
      : selectedSiteId
        ? {site_id: selectedSiteId}
        : selectedOrganizationId
          ? {organization_id: selectedOrganizationId}
          : null
  );

  const noFilters =
    selectedDepartmentId === null &&
    selectedSiteId === null &&
    selectedOrganizationId === null &&
    serialNumberSearchValue === "" &&
    deviceTagSearchValue === "";

  const devicesQuery = useQuery({
    queryKey: ["DeviceManagementDevices-fetchDevicesData", currentEntity],
    queryFn: () => {
      if (isEditingFilter) return;
      return DeviceModule.devicesShort({
        accessToken: authenticationStore.state.accessToken!,
        ...currentEntity,
        device_tag_substring: currentDeviceTag,
        system_serial_number_substring: currentSerialNumber,
      });
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    enabled: (!isNil(currentEntity) || searchParams.size != 0) && (!!currentEntity || !!currentDeviceTag || !!currentSerialNumber),
    refetchInterval: isEditingFilter ? undefined : 5000,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    setShouldFetchNew(false);
    setIsLoading(false);
  }, [devicesQuery.isSuccess]);

  const gatewaysQuery = useQuery({
    queryKey: ["gateways", currentEntity],
    queryFn: () => {
      return GatewayModule.gateways({
        accessToken: authenticationStore.state.accessToken!,
        ...currentEntity,
        serial_number_substring: currentSerialNumber,
      });
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    enabled:
      !isEditingFilter &&
      (!isNil(currentEntity) || searchParams.size != 0) &&
      (!!currentEntity || !!currentDeviceTag || !!currentSerialNumber),
    refetchInterval: 5000,
    placeholderData: keepPreviousData,
  });

  const gatewayVersionQuery = useQuery({
    queryKey: ["gatewayVersion", currentEntity],
    queryFn: () => {
      return GatewayModule.latestGatewaySoftwareVersion({
        accessToken: authenticationStore.state.accessToken!,
        ...currentEntity,
      });
    },
    placeholderData: keepPreviousData,
  });

  const gatewayLatestVersionQuery = useQuery({
    queryKey: ["gatewayLatestVersion", currentEntity],
    queryFn: () => {
      return GatewayModule.gatewaySoftwareVersion({
        accessToken: authenticationStore.state.accessToken!,
        version: latestBinaryVersion!.version,
      });
    },
    enabled: !!latestBinaryVersion,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    setShouldFetchNew(false);
    setIsLoading(false);
  }, [gatewaysQuery.isSuccess]);

  const handleSettingsChange = (updatedSettings: {
    updatedDeviceManagementDevicesTableSettings?: Partial<Models.DeviceManagementDevicesTableSettings>;
    updatedGatewayTableSettings?: Partial<Models.GatewayTableSettings>;
    updatedDeviceManagementActiveTabKey?: TabKeys;
  }) =>
    setUserPreferences({
      tableSettings: {
        deviceManagementModeDevices: {
          ...userPreferences.tableSettings.deviceManagementModeDevices,
          ...updatedSettings.updatedDeviceManagementDevicesTableSettings,
        },
        gateways: {...userPreferences.tableSettings.gateways, ...updatedSettings.updatedGatewayTableSettings},
      },
      deviceManagementActiveTabKey: updatedSettings.updatedDeviceManagementActiveTabKey ?? deviceManagementActiveTabKey,
    });

  const handleBackButton = () => {
    if (document.referrer && isNil(selectedOrganizationId) && isNil(selectedSiteId) && isNil(selectedDepartmentId)) {
      navigate(-1);
    } else navigate("/");
  };

  useEffect(() => {
    if (!devicesQuery.data) return;

    appStore.hideAllMessages();

    if (!devicesQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("FetchDiagnosticsDevicesPageData-errorMessage"), devicesQuery.data.correlationId);
      return;
    }

    setDevices(devicesQuery.data.devices);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesQuery.data]);

  useEffect(() => {
    if (!gatewaysQuery.data) return;

    appStore.hideAllMessages();

    if (!gatewaysQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("FetchDiagnosticsDevicesPageData-errorMessage"), gatewaysQuery.data.correlationId);
      return;
    }

    setGateways(gatewaysQuery.data.gateways);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewaysQuery.data]);

  useEffect(() => {
    if (!gatewayVersionQuery.data) return;

    if (!gatewayVersionQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("FetchDiagnosticsDevicesPageData-errorMessage"),
        gatewayVersionQuery.data.correlationId
      );
      return;
    }
    setLatestBinaryVersion(gatewayVersionQuery.data.versions[0]);
  }, [gatewayVersionQuery.data]);

  useEffect(() => {
    if (!gatewayLatestVersionQuery.data) return;

    if (!gatewayLatestVersionQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("FetchDiagnosticsDevicesPageData-errorMessage"),
        gatewayLatestVersionQuery.data.correlationId
      );
      return;
    }
    setLatestVersion(gatewayLatestVersionQuery.data.version[0]);
  }, [gatewayLatestVersionQuery.data]);

  useEffect(() => {
    if (includeArchived) {
      setDevicesToShow(devices);
    } else {
      setDevicesToShow(devices.filter((device) => device.archived_at === null));
    }
  }, [includeArchived, devices]);

  useEffect(() => {
    if (noFilters) {
      setDevicesTabIsEmpty(true);
    } else {
      devicesQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.DevicesManagementOpened);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldFetchNew) {
      if (deviceManagementActiveTabKey === TabKeys.Gateways) {
        if (!gatewaysQuery.isFetching)
          gatewaysQuery.refetch().then(() => {
            setIsLoading(false);
            setShouldFetchNew(false);
          });
      } else {
        setDevicesTabIsEmpty(false);
        if (!devicesQuery.isFetching)
          devicesQuery.refetch().then(() => {
            setIsLoading(false);
            setShouldFetchNew(false);
          });
      }
    }
  }, [deviceManagementActiveTabKey, devicesQuery, gatewaysQuery, shouldFetchNew]);

  const saveEntitySelection = async (values: string[] | undefined) => {
    const orgID = values?.[0];
    const siteID = values?.[1];
    const departmentID = values?.[2];

    setSelectedOrganizationId(orgID ?? null);
    setSelectedSiteId(siteID ?? null);
    setSelectedDepartmentId(departmentID ?? null);
  };

  const onSearch = async () => {
    if (!noFilters) {
      setIsLoading(true);
      if (selectedOrganizationId === DeviceManagementSelectOptions.Unallocated) {
        setSearchParams({
          unallocated: "true",
          ...(serialNumberSearchValue ? {serialNumber: serialNumberSearchValue} : {}),
          ...(deviceTagSearchValue ? {deviceTag: deviceTagSearchValue} : {}),
        });
        await setCurrentDeviceTag(deviceTagSearchValue ?? undefined);
        await setCurrentSerialNumber(serialNumberSearchValue ?? undefined);
        await setCurrentEntity({organization_id: DeviceManagementSelectOptions.Unallocated});
        setShouldFetchNew(true);
      } else {
        setSearchParams({
          ...(selectedOrganizationId ? {orgID: selectedOrganizationId} : {}),
          ...(selectedSiteId ? {siteID: selectedSiteId} : {}),
          ...(selectedDepartmentId ? {departmentID: selectedDepartmentId} : {}),
          ...(serialNumberSearchValue ? {serialNumber: serialNumberSearchValue} : {}),
          ...(deviceTagSearchValue ? {deviceTag: deviceTagSearchValue} : {}),
        });

        await setCurrentDeviceTag(deviceTagSearchValue ?? undefined);
        await setCurrentSerialNumber(serialNumberSearchValue ?? undefined);
        await setCurrentEntity(
          selectedDepartmentId
            ? {department_id: selectedDepartmentId}
            : selectedSiteId
              ? {site_id: selectedSiteId}
              : selectedOrganizationId
                ? {organization_id: selectedOrganizationId}
                : null
        );
        setShouldFetchNew(true);
      }
    }
  };

  const tabs = useMemo(
    () => [
      {label: WebHelper.formatMessage("DeviceManagementDevices-deviceConfig"), key: TabKeys.DeviceConfig},
      {label: WebHelper.formatMessage("DeviceManagementDevices-gateways"), key: TabKeys.Gateways},
    ],
    []
  );

  const createDevicesOptions = [
    {
      key: "manually",
      label: WebHelper.formatMessage("Common-createOptionsManually"),
      onClick: () => {
        setIsCreateDeviceModalVisible(true);
      },
    },
    {
      key: "uploadCSV",
      label: WebHelper.formatMessage("Common-createOptionsUploadCSV"),
      onClick: () => {
        setIsBulkCreateDevicesModalVisible(true);
      },
    },
  ];

  const handleDeviceCreated = () => {
    if (selectedOrganizationId || serialNumberSearchValue || deviceTagSearchValue) devicesQuery.refetch();
    setIsCreateDeviceModalVisible(false);
  };

  return (
    <>
      <Subheader
        breadcrumbItems={[{id: "deviceManagement", name: WebHelper.formatMessage("DeviceManagementDevices-subheaderTitle")}]}
        singleLevel
      />
      <div className={styles.topSection}>
        <PageTitle
          icon={<AntdButton className={styles.backButton} onClick={handleBackButton} icon={<ArrowLeftOutlined />} />}
          title={WebHelper.formatMessage("DeviceManagementDevices-title")}
          actions={[
            authenticationStore.isUserGlobal && authenticationStore.isUserAdmin && (
              <>
                <Button
                  style={{display: "flex", alignItems: "center", gap: "6px"}}
                  icon={<img src={syncIcon} alt="" />}
                  shape="round"
                  type={ButtonType.Default}
                  onClick={() => setIsBulkUpdateDevicesModalVisible(true)}>
                  {WebHelper.formatMessage("DeviceManagementDevices-bulkUpdateDevices")}
                </Button>
                <Dropdown menu={{items: createDevicesOptions}} placement="bottom" trigger={["click"]}>
                  <Button icon={<PlusOutlined />} shape="round" type={ButtonType.Primary}>
                    {WebHelper.formatMessage("DeviceManagementDevices-createDeviceButtonText")}
                  </Button>
                </Dropdown>
              </>
            ),
          ]}
          marginBottom={0}
        />
      </div>
      <div className={styles.contentWrapper}>
        <Tabs
          items={tabs}
          size={"large"}
          activeKey={deviceManagementActiveTabKey}
          onChange={(tabKey) => {
            handleSettingsChange({updatedDeviceManagementActiveTabKey: tabKey as TabKeys});
            appStore.sendAnalyticTrack(
              tabKey === TabKeys.Gateways ? SegmentKey.DevicesManagementGatewayTabOpened : SegmentKey.DevicesManagementDevicesTabOpened
            );
          }}
        />
        <Space size={16} direction="vertical" className={styles.content}>
          <DeviceManagementSearchBar
            selectedOrganizationId={selectedOrganizationId}
            selectedSiteId={selectedSiteId}
            selectedDepartmentId={selectedDepartmentId}
            onChangeEntity={saveEntitySelection}
            onChangeSerialNumber={setSerialNumberSearchValue}
            onChangeDeviceTag={setDeviceTagSearchValue}
            onSearch={onSearch}
            onLabelChange={(labels: string[] | undefined) => setSelectedEntityName(labels)}
            deviceTagSearchValue={deviceTagSearchValue}
            serialNumberSearchValue={serialNumberSearchValue}
            activeTab={deviceManagementActiveTabKey}
            searchButtonDisabeld={
              (devicesQuery.isFetching && !devicesQuery.isFetched) || (gatewaysQuery.isFetched && !gatewaysQuery.isFetched)
            }
          />
          <DeviceManagementStats
            selectedEntity={selectedEntityName}
            activeTab={deviceManagementActiveTabKey}
            devices={devicesToShow}
            gateways={gateways}
            includeArchived={includeArchived}
            onIncludeArchivedChange={() => setIncludeArchived(!includeArchived)}
            latestVersionGateway={latestVersion ? latestVersion.version : undefined}
            loading={!devicesQuery.isFetched && !devicesQuery.isFetching}
            disabled={devicesTabIsEmpty}
          />
          <div className={styles.tableContent}>
            <Suspense>
              {deviceManagementActiveTabKey === TabKeys.Gateways ? (
                !gatewaysQuery.isFetched && !gatewaysQuery.isFetching ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={WebHelper.formatMessage("DeviceManagementDevices-searchGateways")}
                  />
                ) : (
                  <GatewaysTable
                    gateways={gateways}
                    setIsEditingFilter={setIsEditingFilter}
                    loading={!gatewaysQuery.isFetched || isLoading}
                    tableSettings={userPreferences.tableSettings.gateways}
                    updatePreferences={handleSettingsChange}
                    latestVersionGateway={latestVersion}
                  />
                )
              ) : (!devicesQuery.isFetched && !devicesQuery.isFetching) || devicesTabIsEmpty ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={WebHelper.formatMessage("DeviceManagementDevices-searchDevices")}
                />
              ) : (
                <DeviceManagementDevicesTable
                  devices={devicesToShow}
                  setIsEditingFilter={setIsEditingFilter}
                  onUpdate={devicesQuery.refetch}
                  loading={!devicesQuery.isFetched || isLoading}
                  activeTabKey={deviceManagementActiveTabKey}
                  tableSettings={userPreferences.tableSettings.deviceManagementModeDevices}
                  updatePreferences={handleSettingsChange}
                  includeArchived={includeArchived}
                />
              )}
            </Suspense>
          </div>
        </Space>
      </div>
      <Suspense>
        {isCreateDeviceModalVisible && (
          <CreateDeviceModal
            open={isCreateDeviceModalVisible}
            onClose={() => setIsCreateDeviceModalVisible(false)}
            onDeviceCreated={handleDeviceCreated}
          />
        )}
        {isBulkUpdateDevicesModalVisible && (
          <BulkUpdateDevicesModal open={isBulkUpdateDevicesModalVisible} onClose={() => setIsBulkUpdateDevicesModalVisible(false)} />
        )}
        {isBulkCreateDevicesModalVisible && (
          <BulkCreateDevicesModal open={isBulkCreateDevicesModalVisible} onClose={() => setIsBulkCreateDevicesModalVisible(false)} />
        )}
      </Suspense>
    </>
  );
});
