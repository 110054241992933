import {InfoCircleFilled} from "@ant-design/icons";
import {useMutation} from "@tanstack/react-query";
import {InputNumber, Modal, Radio, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import styles from "./TargetUtilizationHoursModal.module.scss";
import * as Models from "../../../../../../core/models";
import {DepartmentModule} from "../../../../../../core/modules/DepartmentModule";
import {AuthenticationDataStore} from "../../../../../../core/stores/AuthenticationDataStore";
import {AppStore} from "../../../../../stores/AppStore";
import {isNil} from "../../../../../utils/FunctionUtils";
import {WebHelper} from "../../../../../utils/WebHelper";
import {DiscardChangesModal} from "../../../../discard-changes-modal/DiscardChangesModal";

type TargetUtilizationHoursModalProps = {
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
  department: Models.Department;
};

enum RadioOptions {
  NoTarget = "noTarget",
  ConstantTarget = "constantTarget",
  OnboardingCalendar = "onboardingCalendar",
}

export const TargetUtilizationHoursModal: FunctionComponent<TargetUtilizationHoursModalProps> = observer(
  ({open, onSave, onCancel, department}) => {
    const authenticationStore = AuthenticationDataStore.getInstance();
    const appStore = AppStore.getInstance();

    const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);
    const [selectedRadioOption, setSelectedRadioOption] = useState<RadioOptions>(
      department.target_type === Models.TargetType.Week
        ? RadioOptions.OnboardingCalendar
        : department.target_type === Models.TargetType.Constant
          ? RadioOptions.ConstantTarget
          : RadioOptions.NoTarget
    );
    const [constantTargetValue, setConstantTargetValue] = useState<number | null>(
      department.target_type === Models.TargetType.Constant ? department.target_device_utilization.target_utilization[0] : 0
    );
    const [calendarValues, setCalendarValues] = useState<(number | null)[]>(
      department.target_type === Models.TargetType.Week
        ? department.target_device_utilization.target_utilization
        : [null, null, null, null, null]
    );

    useEffect(() => {
      setSelectedRadioOption(
        department.target_type === Models.TargetType.Week
          ? RadioOptions.OnboardingCalendar
          : department.target_type === Models.TargetType.Constant
            ? RadioOptions.ConstantTarget
            : RadioOptions.NoTarget
      );
      setConstantTargetValue(
        department.target_type === Models.TargetType.Constant ? department.target_device_utilization.target_utilization[0] : 0
      );
      setCalendarValues(
        department.target_type === Models.TargetType.Week
          ? department.target_device_utilization.target_utilization
          : [null, null, null, null, null]
      );
    }, [department]);

    const {mutate: updateTargetUtilizationMutate, isPending: updateTargetUtilizationIsLoading} = useMutation({
      mutationFn: (mutationParams: {departmentId: string; targetValues: (number | null)[] | null}) => {
        return DepartmentModule.updateDepartmentTargetUtilization({
          accessToken: authenticationStore.state.accessToken!,
          department_id: mutationParams.departmentId!,
          target_utilization: mutationParams.targetValues,
        });
      },
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(
            WebHelper.formatMessage("TargetUtilizationHoursModal-configurationErrorMessage"),
            response.correlationId
          );
          return;
        }
        appStore.showMessage(WebHelper.formatMessage("TargetUtilizationHoursModal-configurationSuccessMessage"), "success");
        onSave();
      },
    });

    const handleSave = async () => {
      let targetValues = null;
      if (selectedRadioOption == RadioOptions.ConstantTarget) {
        targetValues = [constantTargetValue];
      } else if (selectedRadioOption == RadioOptions.OnboardingCalendar) {
        targetValues = calendarValues;
      }
      updateTargetUtilizationMutate({departmentId: department.id, targetValues});
    };

    const infoMessage = (
      <Space className={styles.infoWrapper} align="center" size="middle" direction="horizontal">
        <InfoCircleFilled className={styles.icon} />
        <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-infoMessage")}</Typography.Text>
      </Space>
    );

    return (
      <>
        <Modal
          centered
          zIndex={1001}
          destroyOnClose={true}
          open={open}
          title={WebHelper.formatMessage("TargetUtilizationHoursModal-title")}
          okText={WebHelper.formatMessage("Common-save")}
          cancelText={WebHelper.formatMessage("Common-cancel")}
          onOk={handleSave}
          onCancel={onCancel}
          footer={authenticationStore.isUserAdmin ? undefined : null}
          okButtonProps={{
            shape: "round",
            loading: updateTargetUtilizationIsLoading,
            disabled:
              (selectedRadioOption === RadioOptions.ConstantTarget && isNil(constantTargetValue)) ||
              (selectedRadioOption === RadioOptions.OnboardingCalendar && calendarValues.some((value) => isNil(value))),
          }}
          cancelButtonProps={{
            shape: "round",
          }}>
          {authenticationStore.isUserAdmin ? (
            <Space direction="vertical">
              <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-subtitle")}</Typography.Text>
              <Radio.Group
                value={selectedRadioOption}
                onChange={(event) => {
                  setSelectedRadioOption(event.target.value);
                }}>
                <Space direction="vertical">
                  <Radio value={RadioOptions.NoTarget}>{WebHelper.formatMessage("TargetUtilizationHoursModal-noTarget")}</Radio>
                  <Radio value={RadioOptions.ConstantTarget}>{WebHelper.formatMessage("TargetUtilizationHoursModal-constantTarget")}</Radio>
                  {selectedRadioOption === RadioOptions.ConstantTarget && (
                    <div className={styles.configurationTargetWrapper}>
                      <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-constantTargetLabel")}</Typography.Text>
                      <div className={styles.numberWrapper}>
                        <InputNumber min={0} precision={2} value={constantTargetValue} onChange={setConstantTargetValue} />
                        <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-hoursPerDay")}</Typography.Text>
                      </div>
                    </div>
                  )}
                  <Radio value={RadioOptions.OnboardingCalendar}>
                    {WebHelper.formatMessage("TargetUtilizationHoursModal-onboardingCalendar")}
                  </Radio>
                  {selectedRadioOption === RadioOptions.OnboardingCalendar && (
                    <div className={styles.configurationTargetWrapper}>
                      <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-onboardingCalendarLabel")}</Typography.Text>
                      <div className={styles.numberWrapper}>
                        <Typography.Text className={styles.weekText}>
                          {WebHelper.formatMessage("TargetUtilizationHoursModal-week", {week: 1})}
                        </Typography.Text>
                        <InputNumber
                          min={0}
                          precision={2}
                          value={calendarValues[0]}
                          onChange={(value) => {
                            const values = [...calendarValues];
                            values[0] = value;
                            setCalendarValues(values);
                          }}
                        />
                        <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-hoursPerDay")}</Typography.Text>
                      </div>
                      <div className={styles.numberWrapper}>
                        <Typography.Text className={styles.weekText}>
                          {WebHelper.formatMessage("TargetUtilizationHoursModal-week", {week: 2})}
                        </Typography.Text>
                        <InputNumber
                          min={calendarValues[0] ?? 0}
                          precision={2}
                          value={calendarValues[1]}
                          onChange={(value) => {
                            const values = [...calendarValues];
                            values[1] = value;
                            setCalendarValues(values);
                          }}
                        />
                        <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-hoursPerDay")}</Typography.Text>
                      </div>
                      <div className={styles.numberWrapper}>
                        <Typography.Text className={styles.weekText}>
                          {WebHelper.formatMessage("TargetUtilizationHoursModal-week", {week: 3})}
                        </Typography.Text>
                        <InputNumber
                          min={calendarValues[1] ?? 0}
                          precision={2}
                          value={calendarValues[2]}
                          onChange={(value) => {
                            const values = [...calendarValues];
                            values[2] = value;
                            setCalendarValues(values);
                          }}
                        />
                        <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-hoursPerDay")}</Typography.Text>
                      </div>
                      <div className={styles.numberWrapper}>
                        <Typography.Text className={styles.weekText}>
                          {WebHelper.formatMessage("TargetUtilizationHoursModal-week", {week: 4})}
                        </Typography.Text>
                        <InputNumber
                          min={calendarValues[2] ?? 0}
                          precision={2}
                          value={calendarValues[3]}
                          onChange={(value) => {
                            const values = [...calendarValues];
                            values[3] = value;
                            setCalendarValues(values);
                          }}
                        />
                        <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-hoursPerDay")}</Typography.Text>
                      </div>
                      <div className={styles.numberWrapper}>
                        <Typography.Text className={styles.weekText}>
                          {WebHelper.formatMessage("TargetUtilizationHoursModal-afterWeek", {week: 4})}
                        </Typography.Text>
                        <InputNumber
                          min={calendarValues[3] ?? 0}
                          precision={2}
                          value={calendarValues[4]}
                          onChange={(value) => {
                            const values = [...calendarValues];
                            values[4] = value;
                            setCalendarValues(values);
                          }}
                        />
                        <Typography.Text>{WebHelper.formatMessage("TargetUtilizationHoursModal-hoursPerDay")}</Typography.Text>
                      </div>
                    </div>
                  )}
                  {selectedRadioOption !== RadioOptions.NoTarget && infoMessage}
                </Space>
              </Radio.Group>
            </Space>
          ) : (
            <>
              <Space direction="vertical">
                <Typography.Text>
                  {department.target_type === Models.TargetType.Constant
                    ? WebHelper.formatMessage("TargetUtilizationHoursModal-departmentConfigurationConstantTarget", {
                        hours: department.target_device_utilization.target_utilization[0],
                      })
                    : department.target_type === Models.TargetType.Week
                      ? WebHelper.formatMessage("TargetUtilizationHoursModal-departmentConfigurationOnboardingCalendar")
                      : WebHelper.formatMessage("TargetUtilizationHoursModal-departmentConfigurationNoTarget")}
                </Typography.Text>
                {department.target_type === Models.TargetType.Week && (
                  <>
                    <Space direction="vertical" className={styles.calendarInfoWrapper}>
                      {department.target_device_utilization.target_utilization.map((target, index) => (
                        <Typography.Text>
                          {index < 4
                            ? `${WebHelper.formatMessage("TargetUtilizationHoursModal-week", {
                                week: index + 1,
                              })} ${target} ${WebHelper.formatMessage("TargetUtilizationHoursModal-hoursDay")}  `
                            : `${WebHelper.formatMessage("TargetUtilizationHoursModal-afterWeek", {
                                week: 4,
                              })} ${target} ${WebHelper.formatMessage("TargetUtilizationHoursModal-hoursDay")}  `}
                        </Typography.Text>
                      ))}
                    </Space>
                  </>
                )}
              </Space>
              {selectedRadioOption !== RadioOptions.NoTarget && infoMessage}
            </>
          )}
        </Modal>
        <DiscardChangesModal
          onDiscard={onCancel}
          onClose={() => setIsDiscardChangesModalVisible(false)}
          open={isDiscardChangesModalVisible}
        />
      </>
    );
  }
);
