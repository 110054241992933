import {useMutation, useQuery} from "@tanstack/react-query";
import {Form, Input, Modal, Select, Switch, Typography} from "antd";
import {NamePath} from "antd/lib/form/interface";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";

import styles from "./WorkerEditModal.module.scss";
import * as Models from "../../../../../core/models";
import {WorkerModule} from "../../../../../core/modules/WorkerModule";
import {WorkerReportGroupModule} from "../../../../../core/modules/WorkerReportGroupModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";
import {DatePicker} from "../../../date-picker/DatePicker";
import {DiscardChangesModal} from "../../../discard-changes-modal/DiscardChangesModal";

export type WorkerEditFormFields = {
  first_name: string;
  last_name: string;
  employee_id: string;
  assigned_device: string;
  assignable: boolean;
  groups: string[];
  preferred_language: string;
  exempt_target?: boolean;
  targetHoursStartDate?: Date;
};

export type WorkerEditModalProps = {
  worker: Models.Worker;
  department: Models.Department;
  open: boolean;
  onClose: () => void;
  onUpdate: (worker?: Models.Worker) => void;
};

export const WorkerEditModal: FunctionComponent<WorkerEditModalProps> = observer(({worker, department, open, onClose, onUpdate}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [siteWorkers, setSiteWorkers] = useState<Models.WorkerShort[]>([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [exemptTarget, setExemptTarget] = useState(worker.exempt_target);

  const flags = useFlags();

  const [form] = Form.useForm<WorkerEditFormFields>();

  const {
    isFetching: workerReportGroupsQueryIsFetching,
    data: workerReportGroupsQueryData,
    refetch: workerReportGroupsQueryRefetch,
  } = useQuery({
    queryKey: ["WorkerEditModal-fetchReportGroupsData"],
    queryFn: () =>
      WorkerReportGroupModule.workerReportGroups({
        accessToken: authenticationStore.state.accessToken!,
        site_id: worker.site?.id,
      }),
  });

  const updateWorkerReportGroups = useMutation({
    mutationFn: () =>
      WorkerModule.updateWorkerReportGroups({
        accessToken: authenticationStore.state.accessToken!,
        id: worker.id,
        group_ids: form.getFieldValue("groups") ?? [],
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerEditModal-updateError"), response.correlationId);
        return;
      }
      appStore.sendAnalyticTrack(SegmentKey.AddRemoveWorkerFromGroupSingle, {
        workerID: worker.id,
        departmentID: worker.department.id,
        entryPoint: SegmentEntryPoint.EDIT_WORKER_MODAL,
        orgID: worker.organization?.id,
        siteID: worker.site?.id,
      });
      appStore.showMessage(WebHelper.formatMessage("WorkerEditModal-updateSuccessMessage"), "success");
      onUpdate(response.worker);
      onClose();
    },
  });

  const updateWorkerMutation = useMutation({
    mutationFn: (values: WorkerEditFormFields) =>
      WorkerModule.updateWorker({
        accessToken: authenticationStore.state.accessToken!,
        id: worker.id,
        department_id: worker.department.id,
        first_name: values.first_name,
        last_name: values.last_name,
        employee_id: values.employee_id,
        assignable: worker.assignable,
        preferred_language: values.preferred_language,
        exempt_target: values.exempt_target ?? false,
        target_hours_start_date: values.targetHoursStartDate ?? null,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerEditModal-updateError"), response.correlationId);
        return;
      }

      if (!form.isFieldTouched("groups")) {
        appStore.showMessage(WebHelper.formatMessage("WorkerEditModal-updateSuccessMessage"), "success");
        onUpdate(response.worker);
        onClose();
      } else {
        try {
          if (form.isFieldTouched("groups")) updateWorkerReportGroups.mutate();
        } catch (e) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerEditModal-updateError"));
        }
      }
    },
  });

  const {
    data: siteWorkersData,
    isSuccess: siteWorkersDataSuccess,
    isFetching: siteWorkersIsFetching,
  } = useQuery({
    queryKey: ["WorkerEditModal-fetchSiteWorkers", worker],
    queryFn: () => WorkerModule.workersShort({accessToken: authenticationStore.state.accessToken!, site_id: worker.site!.id}),
  });

  useEffect(() => {
    if (siteWorkersDataSuccess && siteWorkersData) {
      if (!siteWorkersData.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("FetchWorkersData-errorMessage"), siteWorkersData.correlationId);
        onClose();
        return;
      }

      setSiteWorkers(siteWorkersData.workers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteWorkersDataSuccess]);

  const firstNameValidator = useCallback(
    (getFieldValue: (name: NamePath) => any, firstName: string | undefined) => {
      const formFirstName = firstName?.trim().toLowerCase();
      const formLastName = getFieldValue("last_name")?.trim().toLowerCase();
      const workerCurrentFirstName = worker.first_name.trim().toLowerCase();
      const workerCurrentLastName = worker.last_name.trim().toLowerCase();

      if (!formFirstName && formLastName) {
        return Promise.reject(new Error(WebHelper.formatMessage("WorkerDetail-ifLastNameRequireFirstNameValidation")));
      }
      if (
        siteWorkers.find(
          (siteWorker) =>
            formFirstName &&
            formLastName &&
            (formFirstName !== workerCurrentFirstName || formLastName !== workerCurrentLastName) &&
            formFirstName === siteWorker.first_name.trim().toLowerCase() &&
            formLastName === siteWorker.last_name.trim().toLowerCase()
        )
      ) {
        return Promise.reject(
          new Error(
            WebHelper.formatMessage("WorkerDetail-nameAlreadyExistsValidation", {
              siteName: worker.site!.name,
              departmentName: worker.department.name,
            })
          )
        );
      }
      return Promise.resolve();
    },
    [worker.first_name, worker.last_name, worker.site, worker.department.name, siteWorkers]
  );

  const lastNameValidator = useCallback(
    (getFieldValue: (name: NamePath) => any, lastName: string | undefined) => {
      const formFirstName = getFieldValue("first_name")?.trim().toLowerCase();
      const formLastName = lastName?.trim().toLowerCase();
      const workerCurrentFirstName = worker.first_name.trim().toLowerCase();
      const workerCurrentLastName = worker.last_name.trim().toLowerCase();

      if (!formLastName && formFirstName) {
        return Promise.reject(new Error(WebHelper.formatMessage("WorkerDetail-ifFirstNameRequireLastNameValidation")));
      }
      if (
        siteWorkers.find(
          (worker) =>
            formLastName &&
            formFirstName &&
            (formLastName !== workerCurrentLastName || formFirstName !== workerCurrentFirstName) &&
            formLastName === worker.last_name.trim().toLowerCase() &&
            formFirstName === worker.first_name.trim().toLowerCase()
        )
      ) {
        return Promise.reject(
          new Error(
            WebHelper.formatMessage("WorkerDetail-nameAlreadyExistsValidation", {
              siteName: worker.site!.name,
              departmentName: worker.department.name,
            })
          )
        );
      }
      return Promise.resolve();
    },
    [worker.first_name, worker.last_name, worker.site, worker.department.name, siteWorkers]
  );

  const employeeIdValidator = useCallback(
    (getFieldValue: (name: NamePath) => any, employeeId: string | undefined) => {
      const formEmployeeId = employeeId?.trim().toLowerCase();
      const currentWorkerEmployeeId = worker.employee_id.trim().toLowerCase();

      if (!formEmployeeId && !getFieldValue("first_name") && !getFieldValue("last_name")) {
        return Promise.reject(new Error(WebHelper.formatMessage("WorkerDetail-ifNoNameRequireEmpIdValidation")));
      }
      if (
        siteWorkers.find(
          (worker) =>
            formEmployeeId && formEmployeeId !== currentWorkerEmployeeId && formEmployeeId === worker.employee_id.trim().toLowerCase()
        )
      ) {
        return Promise.reject(
          new Error(
            WebHelper.formatMessage("WorkerDetail-empIdAlreadyExistsValidation", {
              siteName: worker.site!.name,
              departmentName: worker.department.name,
            })
          )
        );
      }
      return Promise.resolve();
    },
    [worker.employee_id, worker.site, worker.department.name, siteWorkers]
  );

  const handleOk = async () => {
    form
      .validateFields()
      .then((formValues) => {
        updateWorkerMutation.mutate(formValues);
      })
      // This catch prevents AntD from throwing an error to the console when form validations fail
      .catch(() => {});
  };

  const handleOnFieldsChange = () => {
    if (
      (flags.target_utilization_hours &&
        department.target_type !== Models.TargetType.None &&
        !department.target_device_utilization &&
        !form.getFieldValue("targetHoursStartDate")) ||
      !form.isFieldsTouched() ||
      (!!form.getFieldsError().filter(({errors}) => errors.length).length &&
        (!form.getFieldError("targetHoursStartDate") || (exemptTarget && !form.getFieldValue("targetHoursStartDate")))) ||
      (!form.getFieldValue("exempt_target") &&
        !form.getFieldValue("targetHoursStartDate") &&
        flags.target_utilization_hours &&
        department.target_type !== Models.TargetType.None &&
        !department.target_device_utilization)
    ) {
      setIsSaveButtonDisabled(true);
    } else {
      setIsSaveButtonDisabled(false);
    }
  };

  const handleCancel = () => {
    if (form.isFieldsTouched() && !showDiscardModal) setShowDiscardModal(true);
    else {
      setShowDiscardModal(false);
      onClose();
      form.resetFields();
    }
  };

  const workerReportGroupsItemOptions = useMemo(() => {
    if (!workerReportGroupsQueryData?.workerReportGroups) return [];

    const options = workerReportGroupsQueryData.workerReportGroups.map(({id, name}) => getSelectOption(id, name));

    return options;
  }, [workerReportGroupsQueryData?.workerReportGroups]);

  const languageOptions = useMemo(() => {
    return [
      {
        key: Models.WorkerPreferredLanguage.en_US,
        label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.en_US),
        value: Models.WorkerPreferredLanguage.en_US,
      },
      {
        key: Models.WorkerPreferredLanguage.pt_BR,
        label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.pt_BR),
        value: Models.WorkerPreferredLanguage.pt_BR,
      },
      {
        key: Models.WorkerPreferredLanguage.fr_CA,
        label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.fr_CA),
        value: Models.WorkerPreferredLanguage.fr_CA,
      },
      {
        key: Models.WorkerPreferredLanguage.es_MX,
        label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.es_MX),
        value: Models.WorkerPreferredLanguage.es_MX,
      },
      {
        key: Models.WorkerPreferredLanguage.ht_HT,
        label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.ht_HT),
        value: Models.WorkerPreferredLanguage.ht_HT,
      },
    ];
  }, []);

  const loading = updateWorkerMutation.isPending || updateWorkerReportGroups.isPending;

  const handleSwitchChange = (checked: boolean) => {
    setExemptTarget(checked);
    form.validateFields(["targetHoursStartDate"]);
  };

  return (
    <>
      <Modal
        styles={{body: {maxHeight: "calc(100vh - 200px)"}}}
        centered
        open={open}
        destroyOnClose={true}
        title={WebHelper.formatMessage("WorkerEditModal-title")}
        okText={WebHelper.formatMessage("Common-save")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          shape: "round",
          disabled: siteWorkersIsFetching || isSaveButtonDisabled,
          loading: loading,
        }}
        cancelButtonProps={{shape: "round", disabled: loading}}>
        <Form
          labelCol={{sm: {span: 6}, lg: {span: 8}}}
          labelAlign="right"
          labelWrap={true}
          form={form}
          disabled={loading}
          initialValues={{
            ...worker,
            assigned_device: worker.assigned_device?.id ?? "",
            groups: worker.groups?.map((group) => group.id),
            exempt_target: worker.exempt_target,
            targetHoursStartDate: worker.target_hours_start_date
              ? new Date(WebHelper.formatDateNoTime(worker.target_hours_start_date))
              : null,
          }}
          onFieldsChange={handleOnFieldsChange}
          unselectable="off">
          <Form.Item
            colon={false}
            dependencies={["last_name"]}
            label={<Typography.Text>{WebHelper.formatMessage("WorkerEditModal-firstName")}</Typography.Text>}
            name="first_name"
            rules={[
              ({getFieldValue}) => ({
                validator: (_, firstName) => firstNameValidator(getFieldValue, firstName),
              }),
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            colon={false}
            dependencies={["first_name"]}
            label={<Typography.Text>{WebHelper.formatMessage("WorkerEditModal-lastName")}</Typography.Text>}
            name="last_name"
            rules={[
              ({getFieldValue}) => ({
                validator: (_, lastName) => lastNameValidator(getFieldValue, lastName),
              }),
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            colon={false}
            dependencies={["first_name", "last_name"]}
            label={<Typography.Text>{WebHelper.formatMessage("WorkerEditModal-employeeId")}</Typography.Text>}
            name="employee_id"
            rules={[
              ({getFieldValue}) => ({
                validator: (_, employeeId) => employeeIdValidator(getFieldValue, employeeId),
              }),
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="preferred_language"
            label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-preferredLanguageInputLabel")}</Typography.Text>}>
            <Select allowClear options={languageOptions} optionFilterProp="label" showSearch />
          </Form.Item>
          {(authenticationStore.permissionLevel(Models.UserScope.Site, worker.site?.id) === Models.UserAccessLevel.Admin ||
            authenticationStore.permissionLevel(Models.UserScope.Site, worker.site?.id) === Models.UserAccessLevel.Manager) && (
            <Form.Item
              colon={false}
              label={<Typography.Text>{WebHelper.formatMessage("WorkerEditModal-workerReportGroups")}</Typography.Text>}
              name="groups">
              <Select
                mode="multiple"
                allowClear
                loading={workerReportGroupsQueryIsFetching}
                options={workerReportGroupsItemOptions}
                optionFilterProp="label"
                showSearch
                onDropdownVisibleChange={(open) => {
                  if (open) workerReportGroupsQueryRefetch();
                }}
                dropdownRender={(node) => (workerReportGroupsQueryIsFetching ? WebHelper.LoadingSpin : node)}
              />
            </Form.Item>
          )}
          {flags.target_utilization_hours && department.target_type && department.target_type !== Models.TargetType.None && (
            <>
              <Form.Item
                colon={false}
                label={<Typography.Text>{WebHelper.formatMessage("WorkerEditModal-exemptFromUsageTarget")}</Typography.Text>}
                name="exempt_target">
                <Switch defaultChecked={worker.exempt_target} value={exemptTarget} onChange={handleSwitchChange} />
              </Form.Item>
              <Form.Item
                colon={false}
                label={<Typography.Text>{WebHelper.formatMessage("WorkerEditModal-targetHoursStartDate")}</Typography.Text>}
                name="targetHoursStartDate"
                initialValue={
                  worker.target_hours_start_date
                    ? new Date(WebHelper.formatDateNoTime(worker.target_hours_start_date, worker.site?.tz_location))
                    : null
                }
                className={`${exemptTarget ? styles.exemptTarget : styles.notExemptTarget}`}
                rules={!exemptTarget ? [{required: true, message: WebHelper.formatMessage("Common-fieldRequiredMessage")}] : []}>
                <DatePicker />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      <DiscardChangesModal onDiscard={handleCancel} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
    </>
  );
});

function getSelectOption(id: string | undefined, name: string) {
  return {key: id, label: name, value: id};
}
