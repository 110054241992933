import {Form, FormInstance, Input, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {AppConfig} from "../../../AppConfig";
import {WebHelper} from "../../../utils/WebHelper";

export type OrganizationFormFields = {
  name: string;
  short_name: string;
  address: string;
  icon_url: string;
};

type OrganizationFormProps = {
  initialValues?: Models.Organization;
  form: FormInstance<OrganizationFormFields>;
  loading: boolean;
  onFieldsChange: () => void;
};

export const OrganizationForm: FunctionComponent<OrganizationFormProps> = observer(({initialValues, form, loading, onFieldsChange}) => {
  const {organizationForm: organizationInputValidation} = AppConfig.Modules.InputValidation;

  return (
    <Form
      form={form}
      labelWrap={true}
      labelCol={{span: 6}}
      wrapperCol={{span: 19}}
      initialValues={initialValues}
      disabled={loading}
      onFieldsChange={onFieldsChange}
      unselectable="off">
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-name")}</Typography.Text>}
        name="name"
        rules={WebHelper.formatInputValidationRules(organizationInputValidation.name.rules)}>
        <Input id="organizationNameInput-organizationModal" />
      </Form.Item>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-shortName")}</Typography.Text>}
        name="short_name"
        rules={WebHelper.formatInputValidationRules(organizationInputValidation.shortName.rules)}>
        <Input id="organizationShortNameInput-organizationModal" />
      </Form.Item>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-address")}</Typography.Text>}
        name="address"
        rules={WebHelper.formatInputValidationRules(organizationInputValidation.address.rules)}>
        <Input id="organizationAddressInput-organizationModal" />
      </Form.Item>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-iconUrl")}</Typography.Text>}
        name="icon_url"
        rules={WebHelper.formatInputValidationRules(organizationInputValidation.iconUrl.rules)}>
        <Input id="organizationIconUrlInput-organizationModal" />
      </Form.Item>
    </Form>
  );
});
