import {Typography} from "antd";
import {addDays, format} from "date-fns";
import {LDFlagSet} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useCallback} from "react";
import {Bar} from "react-chartjs-2";

import styles from "./SiteSummaryReportFirstPage.module.scss";
import * as Models from "../../../../../core/models";
import useUserPreferences from "../../../../../web/hooks/useUserPreferences";
import {WebHelper, lbsToKgs} from "../../../../utils/WebHelper";
import {SiteSummaryReportHeader} from "../site-summary-report-header/SiteSummaryReportHeader";

type SiteSummaryReportFirstPageProps = {
  endDate: string;
  siteName: string;
  targetUtilization: boolean;
  data?: Models.AnalyticsDashboardCard;
  flags: LDFlagSet;
  tooltipsEnabled: boolean;
};

const constants = require("assets/stylesheets/constants");

export const SiteSummaryReportFirstPage: FunctionComponent<SiteSummaryReportFirstPageProps> = ({
  endDate,
  siteName,
  targetUtilization,
  data,
  flags,
  tooltipsEnabled,
}) => {
  const [userPreferences] = useUserPreferences();

  const totalWeightOffloaded = () => {
    if (data) {
      return data.all_time_weight_offloaded_lbs; // Use optional chaining and provide a default value if the property is undefined
    } else {
      return 0;
    }
  };

  const addLabelToLastBarUsageHours = {
    id: "addLabelToLastBarUsageHours",
    afterDatasetsDraw(chart: any) {
      const {ctx, data} = chart;
      const dataset = data.datasets[0];
      const meta = chart.getDatasetMeta(0);

      const lastBarIndex = dataset.data.length - 1;
      const lastBar = meta.data[lastBarIndex];

      ctx.save();

      const fontSize = 11;
      const fontWeight = 600;
      ctx.font = `${fontWeight} ${fontSize}px Chronicle Text G1`;
      ctx.fillStyle = "#000";
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";

      const usage = dataset.data ? dataset.data[7] : 0;
      const label = `${usage.toLocaleString("en-US", {style: "decimal", maximumFractionDigits: 0}) ?? 0} hrs`;
      const position = lastBar.tooltipPosition();
      ctx.fillText(label, position.x, position.y - 10);

      ctx.restore();
    },
  };

  const addLabelToLastBarWeightOffloaded = {
    id: "addLabelToLastBarWeightOffloaded",
    afterDatasetsDraw(chart: any) {
      const {ctx, data} = chart;
      const dataset = data.datasets[0];
      const meta = chart.getDatasetMeta(0);

      const lastBarIndex = dataset.data.length - 1;
      const lastBar = meta.data[lastBarIndex];

      ctx.save();

      const fontSize = 11;
      const fontWeight = 600;
      ctx.font = `${fontWeight} ${fontSize}px Chronicle Text G1`;
      ctx.fillStyle = "#000";
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";

      const weight = dataset.data ? dataset.data[7] : 0;
      const label = userPreferences.data.metricMeasurementUnits
        ? `${lbsToKgs(weight ?? 0).toLocaleString("en-US", {style: "decimal", maximumFractionDigits: 0})} ${WebHelper.formatMessage("SiteSummaryReportPDF-kilogramsUnit")}`
        : `${weight?.toLocaleString("en-US", {style: "decimal", maximumFractionDigits: 0}) ?? 0} ${WebHelper.formatMessage("SiteSummaryReportPDF-poundsUnit")}`;
      const position = lastBar.tooltipPosition();
      ctx.fillText(label, position.x, position.y - 10);

      ctx.restore();
    },
  };

  const utilizationHistory: () => Omit<Models.AnalyticsDashboardHistory, "active_devices">[] = useCallback(() => {
    if (data) {
      const history = data.time_series_data.map((d) => {
        return {
          interval_start_time: d.start_time,
          interval_end_time: d.end_time,
          total_usage_sec: d.usage_sec,
          total_weight_offloaded: d.weight_offloaded_lbs,
        };
      });
      return history;
    } else {
      return [];
    }
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.firstPageWrapper}>
        <SiteSummaryReportHeader endDate={endDate} />
        <div className={styles.siteStatusGrid}>
          <div className={styles.siteName}>
            <Typography.Title level={5}>{`${siteName}`}</Typography.Title>
            <Typography.Title level={5}>{`Status`}</Typography.Title>
          </div>
          {flags.target_utilization_hours && targetUtilization ? (
            <div className={styles.targetStatusWrapper}>
              <div className={styles.targetStatusBigContainer}>
                <div className={styles.targetStatusContainer}>
                  <Typography.Title level={5}>{WebHelper.formatMessage("SiteSummaryReportPDF-activeWorker")}</Typography.Title>
                  <Typography.Title level={3}>{data?.worker_status.interval_active_count}</Typography.Title>
                </div>
                <div className={styles.middleStatusWrapper}>
                  <Typography.Text className={styles.middleStatusTitle}>
                    {WebHelper.formatMessage("SiteSummaryReportPDF-usageHoursTargetUtilization")}
                  </Typography.Text>
                  <div className={styles.targetStatus}>
                    <div className={styles.targetStatusContainer}>
                      <Typography.Title level={5}>{WebHelper.formatMessage("SiteSummaryReportPDF-onTarget")}</Typography.Title>
                      <Typography.Title level={3}>{data?.worker_status.meets_target_count}</Typography.Title>
                    </div>
                    <div className={styles.targetStatusContainer}>
                      <Typography.Title level={5}>{WebHelper.formatMessage("SiteSummaryReportPDF-aboveTarget")}</Typography.Title>
                      <Typography.Title level={3}>{data?.worker_status.above_target_count}</Typography.Title>
                    </div>
                    <div className={styles.targetStatusContainer}>
                      <Typography.Title level={5}>{WebHelper.formatMessage("SiteSummaryReportPDF-belowTarget")}</Typography.Title>
                      <Typography.Title level={3}>{data?.worker_status.below_target_count}</Typography.Title>
                    </div>
                    <div className={styles.targetStatusContainer}>
                      <Typography.Title level={5}>{WebHelper.formatMessage("SiteSummaryReportPDF-exempt")}</Typography.Title>
                      <Typography.Title level={3}>
                        {data && data.worker_status.exempt_target_count + data.worker_status.unset_target_count}
                      </Typography.Title>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.targetStatusContainer}>
                <Typography.Title level={5}>{WebHelper.formatMessage("SiteSummaryReportPDF-inactiveWorker")}</Typography.Title>
                <Typography.Title level={3}>{data?.worker_status.interval_inactive_count}</Typography.Title>
              </div>
            </div>
          ) : (
            <div className={styles.nonTargetStatusWrapper}>
              <div className={styles.nonTargetStatusContainer}>
                <Typography.Title level={5}>{WebHelper.formatMessage("SiteSummaryReportPDF-activeWorker")}</Typography.Title>
                <Typography.Title level={3}>{data?.worker_status.interval_active_count}</Typography.Title>
              </div>
              <div className={styles.nonTargetStatusContainer}>
                <Typography.Title level={5}>{WebHelper.formatMessage("SiteSummaryReportPDF-inactiveWorker")}</Typography.Title>
                <Typography.Title level={3}>{data?.worker_status.interval_inactive_count}</Typography.Title>
              </div>
            </div>
          )}
        </div>
        <div className={styles.totalUsageAndWeightOffloadedGraph}>
          <div className={styles.graphTitle}>
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-totalUsageHours")}</Typography.Text>
          </div>
          <Bar
            className={styles.graphChart}
            height={5}
            data={{
              labels: utilizationHistory().map(
                ({interval_start_time, interval_end_time}) =>
                  `${format(interval_start_time, WebHelper.formatMessage("Common-dateFormatMonthDay"))}-${format(
                    addDays(interval_end_time, -1),
                    WebHelper.formatMessage("Common-dateFormatMonthDay")
                  )}`
              ),
              datasets: [
                {
                  label: WebHelper.formatMessage("UsageChart-totalUsageHoursLabel"),
                  data: utilizationHistory().map(({total_usage_sec}) => total_usage_sec / 3600),
                  backgroundColor: utilizationHistory().map((item, index) =>
                    //last bar with less opacity
                    index === utilizationHistory().length - 1
                      ? constants.colors.singleColorChartLessOpacity
                      : constants.colors.singleColorChart
                  ),
                  maxBarThickness: 20,
                },
              ],
            }}
            options={{
              responsive: true,
              devicePixelRatio: 3,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: tooltipsEnabled,
                },
              },
              scales: {
                x: {
                  ticks: {
                    display: true,
                    font: {size: 9},
                  },
                  grid: {
                    display: false,
                  },
                },
                y: {
                  ticks: {
                    maxTicksLimit: 5,
                    font: {size: 10},
                    padding: 15,
                  },
                  border: {
                    display: false,
                  },
                },
              },
            }}
            {...{plugins: [addLabelToLastBarUsageHours]}}
          />
        </div>
        <div className={styles.totalUsageAndWeightOffloadedGraph}>
          <div className={styles.graphTitle}>
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-weightOffloadedTitle")}</Typography.Text>
          </div>
          <Bar
            className={styles.graphChart}
            height={5}
            data={{
              labels: utilizationHistory().map(
                ({interval_start_time, interval_end_time}) =>
                  `${format(interval_start_time, WebHelper.formatMessage("Common-dateFormatMonthDay"))}-${format(
                    addDays(interval_end_time, -1),
                    WebHelper.formatMessage("Common-dateFormatMonthDay")
                  )}`
              ),
              datasets: [
                {
                  label: WebHelper.formatMessage("SiteSummaryReportPDF-weightOffloadedTitle"),
                  data: utilizationHistory().map(({total_weight_offloaded}) => total_weight_offloaded),
                  backgroundColor: utilizationHistory().map((item, index) =>
                    //last bar with less opacity
                    index === utilizationHistory().length - 1
                      ? constants.colors.secondarySingleColorChartLessOpacity
                      : constants.colors.secondarySingleColorChart
                  ),
                  maxBarThickness: 20,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              devicePixelRatio: 3,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: tooltipsEnabled,
                },
              },
              scales: {
                x: {
                  ticks: {
                    display: true,
                    font: {size: 9},
                  },
                  grid: {
                    display: false,
                  },
                },
                y: {
                  ticks: {
                    maxTicksLimit: 5,
                    font: {size: 10},
                    padding: 15,
                  },
                  border: {
                    display: false,
                  },
                },
              },
            }}
            {...{plugins: [addLabelToLastBarWeightOffloaded]}}
          />
          <div className={styles.totalWeightOffloaded}>
            {WebHelper.formatMessage("SiteSummaryReportPDF-totalWeightOffloaded")}{" "}
            {userPreferences.data.metricMeasurementUnits
              ? `${lbsToKgs(totalWeightOffloaded()).toLocaleString()} ${WebHelper.formatMessage("SiteSummaryReportPDF-kilogramsUnit")}`
              : `${totalWeightOffloaded().toLocaleString()} ${WebHelper.formatMessage("SiteSummaryReportPDF-poundsUnit")}`}
          </div>
        </div>
      </div>
    </div>
  );
};
