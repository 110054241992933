import * as Types from "./AnalyticsAPIClient.types";
import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";

export class AnalyticsAPIClient extends BaseAPIClient {
  static async analyticsHistory(
    request: Types.AnalyticsHistoryRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsHistoryResponse> {
    const {accessToken, start_time, end_time, interval, resource_type, resource_id} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/history`,
      headers: {token},
      request,
      options,
      data: {
        start_time,
        end_time,
        interval,
        resource_type,
        resource_id,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: data.map((analyticHistory: Models.AnalyticsHistory) => Models.AnalyticsHistory.fromJSON(analyticHistory)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsDashboard(
    request: Types.AnalyticsDashboardRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDashboardResponse> {
    const {accessToken, resource_type, resource_id} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/dashboard`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        resource_id,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDashboard.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsDashboardCard(
    request: Types.AnalyticsDashboardCardRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDashboardCardResponse> {
    const {accessToken, resource_type, resource_id, time_series_config, status_interval_bounds} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/dashboard_card`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        resource_id,
        time_series_config,
        status_interval_bounds,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDashboardCard.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsTimeSeriesData(
    request: Types.AnalyticsTimeSeriesDataRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsTimeSeriesDataResponse> {
    const {
      accessToken,
      organization_id,
      site_id,
      department_id,
      group_id,
      worker_id,
      end_time,
      interval_type,
      interval_count,
      offset_minutes,
    } = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/time_series_data`,
      headers: {token},
      request,
      options,
      data: {
        organization_id,
        site_id,
        department_id,
        group_id,
        worker_id,
        end_time,
        interval_type,
        interval_count,
        offset_minutes,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsTimeSeriesData.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsMultiTimeSeriesData(
    request: Types.AnalyticsMultiTimeSeriesDataRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsMultiTimeSeriesDataResponse> {
    const {accessToken, resource_type, end_time, interval_type, interval_count} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/multi_time_series_data`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        end_time,
        interval_type,
        interval_count,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse.data;
      if (data) {
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsDashboardWorkerData(
    request: Types.AnalyticsDashboardWorkerDataRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDashboardWorkerDataResponse> {
    const {accessToken, end_time, interval_type, interval_count, resource_type, resource_id} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/dashboard_worker_data_v2`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        resource_id,
        time_series_config: {
          offset_minutes: -new Date().getTimezoneOffset(),
          end_time,
          interval_type,
          interval_count,
        },
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDashboardWorkerData.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async WorkerSafeliftReport(
    request: Types.WorkerSafeliftReportRequest,
    options: RequestOptions = {}
  ): Promise<Types.WorkerSafeliftReportResponse> {
    const {accessToken, resource_id, resource_type, include_all_enrolled_workers, report_bounds} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/worker_report`,
      headers: {token},
      request,
      options,
      data: {
        resource_id,
        resource_type,
        include_all_enrolled_workers,
        report_bounds: {
          end_time: new Date(report_bounds.end_time),
          offset_minutes: report_bounds.offset_minutes,
        },
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          workerReportData: await data.worker_report_data.map((reportData: Models.WorkerSafeliftReport) =>
            Models.WorkerSafeliftReport.fromJSON(reportData)
          ),
        };
      }
    } else if (requestResponse.message && requestResponse.message.message === "timeout") {
      return {
        success: false,
        error: this.timeoutError(),
      };
    }

    return {
      success: false,
      error: this.genericError(),
    };
  }

  static async analyticsDevicesData(
    request: Types.AnalyticsDevicesDataRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDevicesDataResponse> {
    const {
      accessToken,
      end_time,
      interval_type,
      interval_count,
      resource_id,
      resource_type,
      limit,
      offset,
      device_tag_sort_order,
      included_assigned_data,
      device_tag,
    } = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: resource_id
        ? `analytics/device_data`
        : !included_assigned_data
          ? `analytics/device_data?included_assigned_data=${included_assigned_data}`
          : device_tag_sort_order
            ? `analytics/device_data?limit=${limit}&offset=${offset}&included_assigned_data=${included_assigned_data}&device_tag_sort_order=${device_tag_sort_order === "ascend" ? "asc" : "desc"}`
            : device_tag
              ? `analytics/device_data?device_tag=${device_tag}`
              : `analytics/device_data?limit=${limit}&offset=${offset}&included_assigned_data=${included_assigned_data}`,
      headers: {token},
      request,
      options,
      data: {
        resource_id,
        resource_type,
        time_series_config: {
          offset_minutes: -new Date().getTimezoneOffset(),
          end_time,
          interval_type,
          interval_count,
        },
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDevicesData.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsTimeSeriesByDaysInSuit(
    request: Types.AnalyticsTimeSeriesByDaysInSuitRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsTimeSeriesByDaysInSuitResponse> {
    const {accessToken, resource_type, resource_id, start_time, end_time, interval_type, interval_count, offset_minutes} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/time_series_indices`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        resource_id,
        start_time,
        end_time,
        interval_type,
        interval_count,
        offset_minutes,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsTimeSeriesDataIndices.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsTimeSeriesIntraDay(
    request: Types.AnalyticsTimeSeriesIntraDayRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsTimeSeriesIntraDayResponse> {
    const {accessToken, resource_type, resource_id, start_time, end_time, interval_type, offset_minutes} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/intra_day_data`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        resource_id,
        start_time,
        end_time,
        interval_type,
        offset_minutes,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsTimeSeriesIntraDayData.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsDetails(
    request: Types.AnalyticsDetailsRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDetailsResponse> {
    const {accessToken, resource_id, resource_type, start_time, end_time, aggregation_mode, day, time_frame_bounds} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/interval_details`,
      headers: {token},
      request,
      options,
      data: {
        resource_id,
        resource_type,
        report_bounds: {
          start_time,
          end_time,
          offset_minutes: -new Date().getTimezoneOffset(),
        },
        aggregation_mode,
        day,
        time_frame_bounds,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDetails.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }
}
