import {translate} from "@aws-amplify/ui";
import {Authenticator, Button, Heading, PasswordField, TextField, useAuthenticator, View, translations} from "@aws-amplify/ui-react";
import {Space, Typography} from "antd";
import {Amplify} from "aws-amplify";
import {AuthUser, signIn, signUp, fetchUserAttributes, fetchAuthSession, SignUpInput, SignInInput} from "aws-amplify/auth";
import {I18n} from "aws-amplify/utils";
import {fromUnixTime} from "date-fns";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import styles from "./Authentication.module.scss";
import {CustomTranslationKeys, customTranslations} from "./Locales";
import {PasswordValidationItem} from "./password-validation-item/PasswordValidationItem";
import companyIcon from "../../../assets/images/common/Verve_Logo_SoftGrey.svg";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppConfig, AppConfigType} from "../../AppConfig";
import {AppStore, SegmentKey} from "../../stores/AppStore";

I18n.putVocabularies(translations);
I18n.putVocabularies(customTranslations);

const supportedLanguages = ["en", "es", "fr"];
const userLanguage = navigator.language || navigator.languages[0];
I18n.setLanguage(
  supportedLanguages.includes(userLanguage.split("-")[0]) ? userLanguage : AppConfig.Settings.Localization.amplifyDefaultLocale
);

type AuthenticationProps = {
  children: React.ReactNode | ((props: {user?: AuthUser}) => JSX.Element);
  options: {config: AppConfigType};
};

enum SignUpErrorCodes {
  UsernameExistsException = "UsernameExistsException",
  UserLambdaValidationException = "UserLambdaValidationException",
}

export const Authentication: FunctionComponent<AuthenticationProps> = observer(({children, options}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const cognitoConfig = options.config.Settings.AWS.cognito;
  Amplify.configure({
    Auth: {
      Cognito: {
        // All possible config options in the link below:
        // https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
        // region: cognitoConfig.region,
        userPoolId: cognitoConfig.userPoolId,
        userPoolClientId: cognitoConfig.userPoolWebClientId,
      },
    },
  });

  const components = {
    Header: () => <img className={styles.companyIconSignIn} src={companyIcon} alt="Sign In Logo" />,
    SignIn: {
      Header: () => (
        <Heading className={styles.heading} level={5}>
          {translate(CustomTranslationKeys.SignInHeaderText)}
        </Heading>
      ),
    },
    SignUp: {
      Header: () => (
        <Heading className={styles.heading} level={5}>
          {translate(CustomTranslationKeys.SignUpHeaderText)}
        </Heading>
      ),
      FormFields: () => {
        const {validationErrors} = useAuthenticator();

        const [organizationValue, setOrganizationValue] = useState("");
        const [givenNameValue, setGivenNameValue] = useState("");
        const [familyNameValue, setFamilyNameValue] = useState("");
        const [emailValue, setEmailValue] = useState("");
        const [passwordValue, setPasswordValue] = useState("");

        const [hasLowercase, setHasLowercase] = useState(false);
        const [hasUppercase, setHasUppercase] = useState(false);
        const [hasSpecialChar, setHasSpecialChar] = useState(false);
        const [hasNumber, setHasNumber] = useState(false);
        const [hasMinimumChars, setHasMinimumChars] = useState(false);

        return (
          <>
            <TextField
              order={1}
              name="custom:org_short_name"
              placeholder={translate(CustomTranslationKeys.Organization)}
              label={translate(CustomTranslationKeys.Organization)}
              value={organizationValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrganizationValue(e.target.value)}
            />
            <TextField
              order={2}
              name="given_name"
              placeholder={translate(CustomTranslationKeys.FirstName)}
              label={translate(CustomTranslationKeys.FirstName)}
              isRequired={true}
              value={givenNameValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGivenNameValue(e.target.value)}
            />
            <TextField
              order={3}
              name="family_name"
              placeholder={translate(CustomTranslationKeys.LastName)}
              label={translate(CustomTranslationKeys.LastName)}
              isRequired={true}
              value={familyNameValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFamilyNameValue(e.target.value)}
            />
            <TextField
              order={4}
              name="email"
              placeholder={translate(CustomTranslationKeys.Email)}
              label={translate(CustomTranslationKeys.Email)}
              type="email"
              isRequired={true}
              value={emailValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailValue(e.target.value)}
              descriptiveText={<Typography.Text>{translate(CustomTranslationKeys.EmailDescriptiveText)}</Typography.Text>}
            />
            <PasswordField
              order={5}
              name="password"
              placeholder={translate(CustomTranslationKeys.Password)}
              label={translate(CustomTranslationKeys.Password)}
              isRequired={true}
              value={passwordValue}
              hasError={!!validationErrors.passwordError}
              onChange={(e: any) => {
                const {hasLowercase, hasUppercase, hasSpecialChar, hasNumber, hasMinimumChars} = validatePassword(e.target.value);

                setHasLowercase(hasLowercase);
                setHasUppercase(hasUppercase);
                setHasSpecialChar(hasSpecialChar);
                setHasNumber(hasNumber);
                setHasMinimumChars(hasMinimumChars);

                setPasswordValue(e.target.value);
              }}
            />
            <Space direction="vertical" size={0}>
              <PasswordValidationItem text={translate(CustomTranslationKeys.PasswordValidationLowercase)} isValid={hasLowercase} />
              <PasswordValidationItem text={translate(CustomTranslationKeys.PasswordValidationUppercase)} isValid={hasUppercase} />
              <PasswordValidationItem text={translate(CustomTranslationKeys.PasswordValidationSpecialChar)} isValid={hasSpecialChar} />
              <PasswordValidationItem text={translate(CustomTranslationKeys.PasswordValidationNumber)} isValid={hasNumber} />
              <PasswordValidationItem text={translate(CustomTranslationKeys.PasswordValidationLength)} isValid={hasMinimumChars} />
            </Space>
          </>
        );
      },
    },
    ConfirmSignUp: {
      Footer: () => {
        const {toSignIn} = useAuthenticator();

        return (
          <View textAlign="center">
            <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
              {translate(CustomTranslationKeys.BackToSignIn)}
            </Button>
          </View>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        id: "emailInput-Login",
        labelHidden: false,
      },
      password: {
        id: "passwordInput-Login",
        labelHidden: false,
      },
    },
  };

  const services = {
    async validateCustomSignUp(formData: any) {
      if (!formData.password || Object.values(validatePassword(formData.password)).some((validation) => !validation)) {
        return {
          passwordError: "Password does not meet all requirements",
        };
      }
      return null;
    },
    async handleSignUp(formData: SignUpInput) {
      try {
        const response = await signUp(formData);
        appStore.sendAnalyticTrack(SegmentKey.NewAccountCreated);
        return response;
      } catch (e: any) {
        if (e.code === SignUpErrorCodes.UserLambdaValidationException)
          throw new Error(translate(CustomTranslationKeys.OrganizationNotFoundError));
        if (e.code === SignUpErrorCodes.UsernameExistsException) throw new Error(translate(CustomTranslationKeys.EmailAlreadyExistsError));
        throw new Error(e);
      }
    },
    async handleSignIn(formData: SignInInput) {
      const response = await signIn(formData);
      const attributes = await fetchUserAttributes();
      const session = await fetchAuthSession();

      const userResponse = await authenticationStore.fetchMe({
        accessToken: {
          token: session.tokens?.accessToken?.toString() ?? "",
          userId: session.userSub ?? "",
          refreshToken: "",
          expiresAt: fromUnixTime(session.tokens?.accessToken.payload.exp ?? 0),
        },
        sub: attributes.sub,
      });
      appStore.analytics.identify(attributes.email, {
        email: attributes.email,
        name: `${attributes.given_name} ${attributes.family_name}`,
        organizationName: userResponse.user?.organization_name,
        active: userResponse.user?.active,
      });
      return response;
    },
  };

  return (
    <Authenticator
      variation="modal"
      components={components}
      formFields={formFields}
      services={services}
      loginMechanisms={["email"]}
      signUpAttributes={["family_name", "given_name", "email"]}>
      {({user}) => (typeof children === "function" ? <>{children({user})}</> : <>{children}</>)}
    </Authenticator>
  );
});

const validatePassword = (password: string) => {
  const validationResult = {
    hasLowercase: false,
    hasUppercase: false,
    hasSpecialChar: false,
    hasNumber: false,
    hasMinimumChars: false,
  };

  /[a-z]/g.test(password) && (validationResult.hasLowercase = true);
  /[A-Z]/g.test(password) && (validationResult.hasUppercase = true);
  // https://owasp.org/www-community/password-special-characters
  /[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]/g.test(password) && (validationResult.hasSpecialChar = true);
  /[0-9]/g.test(password) && (validationResult.hasNumber = true);
  password.length >= 8 && (validationResult.hasMinimumChars = true);

  return validationResult;
};
