import {useMutation} from "@tanstack/react-query";
import {Modal, Space, Typography} from "antd";
import line from "assets/images/common/Line.svg";
import stepsFinished from "assets/images/common/Steps-Finished.svg";
import stepsFirst from "assets/images/common/Steps-First.svg";
import stepsSecondProgress from "assets/images/common/Steps-Second-Progress.svg";
import stepsSecondWaiting from "assets/images/common/Steps-Second-Waiting.svg";
import {addDays, startOfDay} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import styles from "./AllocateSelectedDataModal.module.scss";
import {AllocateDataStepOne} from "./steps/allocate-data-step-one/AllocateDataStepOne";
import {AllocateDataStepTwo} from "./steps/allocate-data-step-two/AllocateDataStepTwo";
import * as Models from "../../../core/models";
import {SessionModule} from "../../../core/modules/SessionModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore} from "../../stores/AppStore";
import {isNil} from "../../utils/FunctionUtils";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";

export type WorkerSelected = {
  id: string;
  label: string;
};

type AllocateSelectedDataModalProps = {
  onClose: () => void;
  open: boolean;
  onConfirm: () => void;
  device: Models.DeviceShort;
  departmentId: string | null;
};

export const AllocateSelectedDataModal: FunctionComponent<AllocateSelectedDataModalProps> = observer(
  ({onClose, open, onConfirm, device, departmentId}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();

    const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(Models.UpdateAllocateDataSteps.first);
    const [buttonConfigs, setButtonConfigs] = useState<string[][]>([]);
    const [selectedWorker, setSelectedWorker] = useState<WorkerSelected | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [singleDay, setSingleDay] = useState(false);

    const resetForm = () => {
      setCurrentStep(Models.UpdateAllocateDataSteps.first);
      setSelectedWorker(null);
      setStartDate(null);
      setEndDate(null);
      setButtonConfigs([]);
      setSingleDay(false);
    };

    const allocateDataMutation = useMutation({
      mutationFn: () =>
        SessionModule.assignSessions({
          accessToken: authenticationStore.state.accessToken!,
          worker_id: selectedWorker!.id,
          device_id: device.id,
          start_date: fromZonedTime(startDate!, "UTC").toISOString(),
          end_date: fromZonedTime(singleDay ? addDays(startOfDay(startDate!), 1) : addDays(startOfDay(endDate!), 1), "UTC").toISOString(),
        }),
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("AllocateSelectedData-updateError"), response.correlationId);
          return;
        }
        appStore.showMessage(
          WebHelper.formatMessage("AllocateSelectedData-updateSuccess", {
            workerName: selectedWorker!.label,
            deviceTag: device.device_tag,
          }),
          "success"
        );
        onConfirm();
        onClose();
        resetForm();
      },
    });

    const handleCancel = () => {
      if (currentStep !== Models.UpdateAllocateDataSteps.first && !isDiscardChangesModalVisible) {
        setIsDiscardChangesModalVisible(true);
      } else {
        setIsDiscardChangesModalVisible(false);
        onClose();
        resetForm();
      }
    };

    const handleNext = () => {
      if (currentStep === Models.UpdateAllocateDataSteps.first) setCurrentStep(Models.UpdateAllocateDataSteps.second);
    };

    const handleSave = async () => {
      allocateDataMutation.mutate();
    };

    const handleBack = () => {
      setSelectedWorker(null);
      setCurrentStep(Models.UpdateAllocateDataSteps.first);
    };

    const modalFooter = (
      <>
        {
          <Button shape="round" disabled={allocateDataMutation.isPending} onClick={handleCancel}>
            {WebHelper.formatMessage("Common-cancel")}
          </Button>
        }

        {currentStep === Models.UpdateAllocateDataSteps.first && (
          <Button
            disabled={isNil(startDate) || (isNil(endDate) && !singleDay) || isNil(selectedWorker)}
            type={ButtonType.Primary}
            shape="round"
            onClick={handleNext}>
            {WebHelper.formatMessage("Common-next")}
          </Button>
        )}
        {currentStep === Models.UpdateAllocateDataSteps.second && (
          <>
            <Button shape="round" type={ButtonType.Default} disabled={allocateDataMutation.isPending} onClick={handleBack}>
              {WebHelper.formatMessage("Common-back")}
            </Button>
            <Button
              type={ButtonType.Primary}
              shape="round"
              loading={allocateDataMutation.isPending}
              disabled={buttonConfigs.some((config) => config.some((id) => isNil(id)))}
              onClick={handleSave}>
              {WebHelper.formatMessage("Common-confirm")}
            </Button>
          </>
        )}
      </>
    );

    return (
      <>
        <Modal
          open={open}
          centered
          destroyOnClose={true}
          title={WebHelper.formatMessage("AllocateSelectedData-modalTitle")}
          onCancel={handleCancel}
          className={styles.modal}
          width={"fit-content"}
          footer={modalFooter}>
          <Space className={styles.stepsWrapper}>
            <Space className={styles.stepWrapper}>
              <Space>
                <img src={currentStep === Models.UpdateAllocateDataSteps.first ? stepsFirst : stepsFinished} alt="" />
                <Typography.Text className={styles.stepTitle}>
                  {currentStep === Models.UpdateAllocateDataSteps.first
                    ? WebHelper.formatMessage("AllocateSelectedData-inProgress")
                    : WebHelper.formatMessage("AllocateSelectedData-finished")}
                </Typography.Text>
              </Space>
              <Typography
                className={[
                  styles.stepTitle,
                  currentStep !== Models.UpdateAllocateDataSteps.first ? styles.waitingText : "",
                  styles.reasonText,
                ].join(" ")}>
                {WebHelper.formatMessage("AllocateSelectedData-select")}
              </Typography>
            </Space>
            <img src={line} alt="" className={styles.lineSeparator} />
            <Space className={styles.stepWrapper}>
              <Space>
                <img
                  src={
                    currentStep === Models.UpdateAllocateDataSteps.first
                      ? stepsSecondWaiting
                      : currentStep === Models.UpdateAllocateDataSteps.second
                        ? stepsSecondProgress
                        : stepsFinished
                  }
                  alt=""
                />
                <Typography.Text
                  className={[currentStep === Models.UpdateAllocateDataSteps.first ? styles.waitingText : "", styles.stepTitle].join(" ")}>
                  {currentStep === Models.UpdateAllocateDataSteps.first
                    ? WebHelper.formatMessage("AllocateSelectedData-waiting")
                    : WebHelper.formatMessage("AllocateSelectedData-inProgress")}
                </Typography.Text>
              </Space>
              <Typography.Text
                className={`${currentStep !== Models.UpdateAllocateDataSteps.second ? styles.waitingText : ""} ${styles.reasonText}`}>
                {WebHelper.formatMessage("AllocateSelectedData-confirmation")}
              </Typography.Text>
            </Space>
          </Space>
          <div className={styles.content}>
            {currentStep === Models.UpdateAllocateDataSteps.first && departmentId && (
              <AllocateDataStepOne
                device={device}
                departmentId={departmentId}
                updateSelectedWorker={setSelectedWorker}
                startDate={startDate}
                updateStartDate={setStartDate}
                endDate={endDate}
                updateEndDate={setEndDate}
                singleDay={singleDay}
                updateSingleDay={setSingleDay}
              />
            )}
            {currentStep === Models.UpdateAllocateDataSteps.second && startDate && endDate && selectedWorker && (
              <AllocateDataStepTwo
                deviceTag={device.device_tag}
                startDate={startDate}
                endDate={endDate}
                workerName={selectedWorker.label}
              />
            )}
          </div>
        </Modal>
        <DiscardChangesModal
          onDiscard={handleCancel}
          onClose={() => setIsDiscardChangesModalVisible(false)}
          open={isDiscardChangesModalVisible}
        />
      </>
    );
  }
);
