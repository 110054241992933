import {Typography} from "antd";
import {LDFlagSet} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useEffect, useState} from "react";
import {calculateSafeLiftScore} from "web/components/entity-details/dashboard-tab-content/dashboard-workers-metric/DashboardWorkersMetric";
import {WorkerData} from "web/components/entity-details/dashboard-tab-content/dashboard-workers-metric/leaderboard/Leaderboard";

import {LeaderboardSiteSummaryTable} from "./leaderboard-site-summary-table/LeaderboardSiteSummaryTable";
import styles from "./SiteSummaryLeaderboardPage.module.scss";
import * as Models from "../../../../../core/models";
import {SiteSummaryReportHeader} from "../site-summary-report-header/SiteSummaryReportHeader";

type SiteSummaryLeaderboardPageProps = {
  endDate: string;
  department: Models.DepartmentInSite;
  departmentsAnayltics: {id: string; data: Models.AnalyticsDashboardCard}[];
  flags: LDFlagSet;
  targetUtilization: boolean;
  currentPage: number;
  totalPages: number;
  siteId: string;
  orgId: string;
  data: Models.AnalyticsDashboardWorkerData | undefined;
};

export const SiteSummaryLeaderboardPage: FunctionComponent<SiteSummaryLeaderboardPageProps> = ({
  endDate,
  department,
  departmentsAnayltics,
  flags,
  targetUtilization,
  currentPage,
  totalPages,
  siteId,
  orgId,
  data,
}) => {
  const [workersByWeight, setWorkersByWeight] = useState<WorkerData[]>([]);
  const [workersByScore, setWorkersByScore] = useState<WorkerData[]>([]);

  const [scoreAverage, setScoreAverage] = useState(0);
  const [weightAverage, setWeightAverage] = useState(0);

  useEffect(() => {
    if (!data || !data.worker_data) return;
    const dataWithScores = data.worker_data.map((worker_data) => {
      const totalWeightOffloaded = worker_data.time_series_data.reduce((sum, data) => sum + data.weight_offloaded_lbs, 0);
      const score = calculateSafeLiftScore(worker_data.time_series_data);

      return {...worker_data, score, weightOffloaded: Math.round(totalWeightOffloaded)};
    });

    const dataOrderedByWeight = dataWithScores
      .sort((a, b) => a.worker.displayName.localeCompare(b.worker.displayName))
      .sort((a, b) => b.weightOffloaded - a.weightOffloaded);
    const dataOrderedByScore = dataWithScores
      .filter(
        (
          data
        ): data is {
          // https://stackoverflow.com/a/51577579/15510462
          score: number;
          weightOffloaded: number;
          worker: Models.WorkerShort;
          time_series_data: Models.AnalyticsTimeSeries[];
        } => data.score !== null
      )
      .sort((a, b) => a.worker.displayName.localeCompare(b.worker.displayName))
      .sort((a, b) => b.score - a.score);

    const weightWithPosition = dataOrderedByWeight
      .filter((data) => data.weightOffloaded > 0)
      .map((data, index) => {
        return {...data, position: index + 1};
      });

    const scoreWithPosition = dataOrderedByScore.map((data, index) => {
      return {...data, position: index + 1};
    });

    const scoreAverage = scoreWithPosition.length > 0 ? scoreWithPosition.reduce((a, b) => a + b.score, 0) / scoreWithPosition.length : 0;
    const weightAverage =
      weightWithPosition.length > 0 ? weightWithPosition.reduce((a, b) => a + b.weightOffloaded, 0) / weightWithPosition.length : 0;

    setWorkersByScore(scoreWithPosition);
    setWorkersByWeight(weightWithPosition);
    setScoreAverage(Math.round(scoreAverage));
    setWeightAverage(Math.round(weightAverage));
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.departmentsPageWrapper}>
        <SiteSummaryReportHeader endDate={endDate} />
        <Typography.Title level={5}> {department.name} </Typography.Title>
        <div>
          <LeaderboardSiteSummaryTable
            workersByScore={workersByScore}
            workersByWeight={workersByWeight}
            scoreAverage={scoreAverage}
            weightAverage={weightAverage}
            department={department}
          />
        </div>
      </div>
    </div>
  );
};
