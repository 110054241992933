import {DownloadOutlined} from "@ant-design/icons";
import {Button, Dropdown, Typography} from "antd";
import {jsPDF} from "jspdf";
import React, {FunctionComponent, useState} from "react";

import styles from "./LeaderboardDownloadDropdown.module.scss";
import * as Models from "../../../../../../../core/models";
import {WebHelper, convertDivToBase64} from "../../../../../../../web/utils/WebHelper";
import {AppStore, SegmentKey} from "../../../../../../stores/AppStore";
import {Leaderboard, WorkerData} from "../Leaderboard";

export type LeaderboardDownloadDropdownProps = {
  workersByScore: WorkerData[];
  workersByWeight: WorkerData[];
  scoreAverage: number;
  weightAverage: number;
  onWorkerEdit: () => void;
  department: Models.Department;
};

const LeaderboardDownloadDropdown: FunctionComponent<LeaderboardDownloadDropdownProps> = ({
  workersByScore,
  department,
  workersByWeight,
  scoreAverage,
  weightAverage,
  onWorkerEdit,
}) => {
  const appStore = AppStore.getInstance();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generatePDF = async (mode: "top" | "bottom" | "all") => {
    setIsLoading(true);
    let listName = "";
    const letterSheetSize = [612, 792]; //Letter
    const pdf = new jsPDF({
      orientation: "p", //portrait
      unit: "pt", //points
      format: letterSheetSize,
    });

    const hiddenDiv = document.getElementById("hiddenWrapper");
    if (hiddenDiv) {
      const renderPage = async (pageNum: number) => {
        hiddenDiv.style.visibility = "visible";
        const imgData = await document.getElementById(`page${pageNum}`);
        if (imgData) {
          const [base64Image, scaledHeight] = await convertDivToBase64(imgData, letterSheetSize, true);
          pdf.addImage(base64Image, "JPEG", 0, 0, letterSheetSize[0], scaledHeight);
        }
        hiddenDiv.style.visibility = "hidden";
      };

      switch (mode) {
        case "top":
          listName = WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadTop");
          await renderPage(1);
          break;

        case "bottom":
          listName = WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadBottom");
          await renderPage(2);
          break;

        case "all":
          listName = WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadAll");
          await renderPage(1);
          pdf.addPage();
          await renderPage(2);
          break;

        default:
          break;
      }

      try {
        pdf.save(`${department.name}-${WebHelper.formatMessage("LeaderboardDownloadDropdown-workerMetrics")}-${listName}.pdf`);
        appStore.sendAnalyticTrack(SegmentKey.DashboardWorkerMetricsDownload, {
          orgID: department.organization.id,
          siteID: department.site.id,
          departmentID: department.id,
          listName,
        });
      } catch (e) {
        console.log(e);
        WebHelper.showErrorMessage(WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadError"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const downloadMenu = [
    {
      label: (
        <div
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            generatePDF("top");
          }}>
          {WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadTop")}
        </div>
      ),
      key: "pdf-top",
    },
    {
      label: (
        <div
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            generatePDF("bottom");
          }}>
          {WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadBottom")}
        </div>
      ),
      key: "pdf-bottom",
    },
    {
      label: (
        <div
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            generatePDF("all");
          }}>
          {WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadAll")}
        </div>
      ),
      key: "pdf-all",
    },
  ];

  const renderPageTitle = () => (
    <div className={styles.pageHeader}>
      <Typography.Title level={2}>{WebHelper.formatMessage("LeaderboardDownloadDropdown-title")}</Typography.Title>
      <Typography.Text className={styles.headerPageText}>
        {department.name} - {department.site.name}
      </Typography.Text>
    </div>
  );

  return (
    <div>
      <Dropdown menu={{items: downloadMenu}} placement="bottomLeft">
        <Button shape="round" loading={isLoading} icon={<DownloadOutlined />} onClick={(e) => e.stopPropagation()}>
          {WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadData")}
        </Button>
      </Dropdown>
      <div className={styles.hiddenWrapper} id="hiddenWrapper">
        <div className={styles.page} id="page1">
          {renderPageTitle()}
          <Leaderboard
            workersByScore={workersByScore}
            workersByWeight={workersByWeight}
            scoreAverage={scoreAverage}
            weightAverage={weightAverage}
            onWorkerEdit={onWorkerEdit}
            department={department}
            printMode="top"
            downloadable={true}
          />
        </div>
        <div className={styles.page} id="page2">
          {renderPageTitle()}
          <Leaderboard
            workersByScore={workersByScore}
            workersByWeight={workersByWeight}
            scoreAverage={scoreAverage}
            weightAverage={weightAverage}
            onWorkerEdit={onWorkerEdit}
            department={department}
            printMode="bottom"
            downloadable={true}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaderboardDownloadDropdown;
