import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {round} from "../../web/utils/FunctionUtils";

export class AnalyticsTimeSeriesIndices extends BaseModel {
  usage_sec: number;
  enrolled_workers: number;
  assigned_workers: number;
  active_workers: number;
  weight_offloaded_lbs: number;
  total_lifts: number;
  safe_lifts: number;
  excessive_forward_lifts: number;
  prolonged_bend_lifts: number;
  twisted_lifts: number;
  side_bend_lifts: number;
  start_time: string;
  end_time: string;

  constructor() {
    super();
    makeObservable(this, {
      active_workers: observable,
      assigned_workers: observable,
      enrolled_workers: observable,
      excessive_forward_lifts: observable,
      prolonged_bend_lifts: observable,
      safe_lifts: observable,
      side_bend_lifts: observable,
      total_lifts: observable,
      twisted_lifts: observable,
      usage_sec: observable,
      weight_offloaded_lbs: observable,
      start_time: observable,
      end_time: observable,
    });
  }

  get riskyLifts(): number {
    return this.total_lifts - this.safe_lifts;
  }

  get safeLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.safe_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get riskyLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.riskyLifts / this.total_lifts) * 100, 1) : 0;
  }

  get excessiveForwardLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.excessive_forward_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get prolongedBendLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.prolonged_bend_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get twistedLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.twisted_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get sideBendLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.side_bend_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get activeWorkersPercentage(): number {
    return this.assigned_workers > 0 ? round((this.active_workers / this.assigned_workers) * 100, 1) : 0;
  }

  get inactiveWorkers(): number {
    return this.assigned_workers >= this.active_workers ? this.assigned_workers - this.active_workers : 0;
  }

  get inactiveWorkersPercentage(): number {
    return this.assigned_workers > 0 ? round((this.inactiveWorkers / this.assigned_workers) * 100, 1) : 0;
  }

  get averageSafeLifts(): number {
    return this.active_workers > 0 ? this.safe_lifts / this.active_workers : 0;
  }

  get averageRiskyLifts(): number {
    return this.active_workers > 0 ? this.riskyLifts / this.active_workers : 0;
  }

  get averageExcessiveForwardLifts(): number {
    return this.active_workers > 0 ? this.excessive_forward_lifts / this.active_workers : 0;
  }

  get averageProlongedBendLifts(): number {
    return this.active_workers > 0 ? this.prolonged_bend_lifts / this.active_workers : 0;
  }

  get averageTwistedLifts(): number {
    return this.active_workers > 0 ? this.twisted_lifts / this.active_workers : 0;
  }

  get averageSideBendLifts(): number {
    return this.active_workers > 0 ? this.side_bend_lifts / this.active_workers : 0;
  }
}

export class AnalyticsTimeSeriesDayData extends BaseModel {
  worker_count: number;
  worker_ids: string[];
  time_series_indices: AnalyticsTimeSeriesIndices[];

  constructor() {
    super();
    makeObservable(this, {
      worker_count: observable,
      worker_ids: observable,
      time_series_indices: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsTimeSeriesDayData, json: any) {
    if (json.time_series_indices)
      object.time_series_indices = json.time_series_data.map((timeSeries: AnalyticsTimeSeriesIndices) => {
        return AnalyticsTimeSeriesIndices.fromJSON(timeSeries);
      });
  }
}

export class AnalyticsTimeSeriesIntraDayData extends BaseModel {
  worker_count: number;
  worker_ids: string[];
  intra_day_indices: AnalyticsTimeSeriesIndices[];

  constructor() {
    super();
    makeObservable(this, {
      worker_count: observable,
      worker_ids: observable,
      intra_day_indices: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsTimeSeriesIntraDayData, json: any) {
    if (json.intra_day_indices)
      object.intra_day_indices = json.intra_day_indices.map((timeSeries: AnalyticsTimeSeriesIndices) => {
        return AnalyticsTimeSeriesIndices.fromJSON(timeSeries);
      });
  }
}
