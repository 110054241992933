import {useMutation, useQuery} from "@tanstack/react-query";
import {Space, Typography, Select, Radio, Modal} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useState} from "react";

import styles from "./DeviceNoWorker.module.scss";
import * as Models from "../../../../../core/models";
import {DeviceModule} from "../../../../../core/modules/DeviceModule";
import {WorkerModule} from "../../../../../core/modules/WorkerModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {isNil} from "../../../../utils/FunctionUtils";
import {SegmentEntryPoint, SegmentResourceType} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";

enum RadioOptions {
  LeaveUnassigned = "leaveUnassigned",
  AssignNewWorker = "assignNewWorker",
  ChangeStatusOutOfService = "changeStatusOutOfService",
}

export type DeviceNoWorkerProps = {
  deviceId: string;
  deviceTag: string;
  departmentId: string;
  onOk: () => void;
  open: boolean;
  onClose: () => void;
  entryPoint: SegmentEntryPoint;
};

export const DeviceNoWorker: FunctionComponent<DeviceNoWorkerProps> = observer(
  ({deviceId, deviceTag, departmentId, onOk, open, onClose, entryPoint}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();

    const [selectedOption, setSelectedOption] = useState<RadioOptions>(RadioOptions.LeaveUnassigned);
    const [workerIdToAssign, setWorkerIdToAssign] = useState<string>();
    const [availableWorkers, setAvailableWorkers] = useState<Models.WorkerShort[]>([]);

    const {isFetching: workersQueryIsFetching, refetch: workersQueryRefetch} = useQuery({
      queryKey: ["DeviceNoWorker-fetchWorkerData"],
      queryFn: () => WorkerModule.workersShort({accessToken: authenticationStore.state.accessToken!, department_id: departmentId}),
      enabled: false,
    });

    const assignWorkerToDeviceMutation = useMutation({
      mutationFn: ({workerId, assignable}: {workerId: string | null; assignable: boolean}) =>
        DeviceModule.assignWorkerToDevice({
          accessToken: authenticationStore.state.accessToken!,
          device_id: deviceId,
          worker_id: workerId,
          assignable: assignable,
        }),
    });

    const fetchAvailableWorkers = useCallback(async () => {
      const response = await workersQueryRefetch();
      if (response.data?.success) {
        const availableWorkersResponse = response.data.workers.filter((worker) => worker.assignable && isNil(worker.device_id));
        setAvailableWorkers(availableWorkersResponse);
        if (isNil(availableWorkersResponse.find((worker) => worker.id === workerIdToAssign))) setWorkerIdToAssign(undefined);
      }
    }, [workersQueryRefetch, workerIdToAssign]);

    const handleOk = async () => {
      if (selectedOption === RadioOptions.AssignNewWorker && workerIdToAssign) {
        assignWorkerToDeviceMutation.mutate(
          {workerId: workerIdToAssign, assignable: true},
          {
            onSuccess: (assignResponse) => {
              if (assignResponse.success) {
                appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceAssignment, {
                  departmentID: assignResponse.device?.department?.id,
                  orgID: assignResponse.device?.organization?.id,
                  siteID: assignResponse.device.site?.id,
                  resourceType: SegmentResourceType.DEVICE,
                  resourceID: assignResponse.device.id,
                  endAssigned: !!assignResponse.device.assigned_worker,
                  startAssigned: true,
                  entryPoint: SegmentEntryPoint.EDIT_DEVICE_MODAL,
                });

                const name = availableWorkers.find((worker) => worker.id === workerIdToAssign)?.displayName ?? "";
                onOk();
                appStore.showMessage(
                  WebHelper.formatMessage("DeviceNoWorker-assignSuccessMessage", {deviceTag: deviceTag, workerName: name}),
                  "success"
                );
              } else WebHelper.showErrorMessage(WebHelper.formatMessage("DeviceNoWorker-assignErrorMessage"), assignResponse.correlationId);
              return;
            },
          }
        );
      } else if (selectedOption === RadioOptions.ChangeStatusOutOfService) {
        assignWorkerToDeviceMutation.mutate(
          {workerId: null, assignable: false},
          {
            onSuccess: (assignResponse) => {
              if (assignResponse.success) {
                appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceStatus, {
                  departmentID: assignResponse.device.department?.id,
                  siteID: assignResponse.device.site?.id,
                  orgID: assignResponse.device.organization?.id,
                  endAssignable: assignResponse.device.assignable,
                  entryPoint: entryPoint,
                });

                appStore.showMessage(
                  WebHelper.formatMessage("DeviceNoWorker-changeStatusSuccessMessage", {deviceTag: deviceTag}),
                  "success"
                );
                onOk();
              } else
                WebHelper.showErrorMessage(
                  WebHelper.formatMessage("DeviceNoWorker-changeStatusErrorMessage"),
                  assignResponse.correlationId
                );
              return;
            },
          }
        );
      } else if (selectedOption === RadioOptions.LeaveUnassigned) {
        appStore.showMessage(WebHelper.formatMessage("DeviceNoWorker-leaveUnassignedSuccessMessage", {deviceTag: deviceTag}), "success");
        onOk();
      }
    };

    return (
      <Modal
        centered
        open={open}
        destroyOnClose={true}
        title={WebHelper.formatMessage("DeviceNoWorker-title")}
        cancelButtonProps={{shape: "round", disabled: assignWorkerToDeviceMutation.isPending}}
        okButtonProps={{
          shape: "round",
          disabled: assignWorkerToDeviceMutation.isPending || (selectedOption === RadioOptions.AssignNewWorker && !workerIdToAssign),
        }}
        okText={WebHelper.formatMessage("Common-save")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onOk={handleOk}
        onCancel={onClose}>
        {
          <Space className={styles.wrapper}>
            <div
              className={styles.subtitle}
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(WebHelper.formatMessage("DeviceNoWorker-firstSubtitle", {name: deviceTag})),
              }}
            />
            <Typography.Text className={styles.subtitle}>{WebHelper.formatMessage("DeviceNoWorker-secondSubtitle")}</Typography.Text>
            <Radio.Group
              className={styles.radioGroup}
              value={selectedOption}
              disabled={assignWorkerToDeviceMutation.isPending}
              onChange={(event) => {
                setSelectedOption(event.target.value);
                setWorkerIdToAssign(undefined);
              }}>
              <Space direction="vertical" className={styles.radioWrapper}>
                <Radio value={RadioOptions.LeaveUnassigned} className={styles.optionWrapper}>
                  {WebHelper.formatMessage("DeviceNoWorker-leaveUnassigned")}
                </Radio>

                <Radio value={RadioOptions.AssignNewWorker} className={styles.optionWrapper}>
                  <Typography.Text>{WebHelper.formatMessage("DeviceNoWorker-assignNewWorker")}</Typography.Text>
                </Radio>
                {selectedOption === RadioOptions.AssignNewWorker && (
                  <div className={styles.selectWrapper}>
                    <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("DeviceNoWorker-worker")}</Typography.Text>
                    <Select
                      value={workerIdToAssign}
                      disabled={assignWorkerToDeviceMutation.isPending || selectedOption !== RadioOptions.AssignNewWorker}
                      className={styles.deviceSelector}
                      optionFilterProp="label"
                      optionLabelProp="label"
                      onSelect={setWorkerIdToAssign}
                      showSearch
                      onDropdownVisibleChange={(open) => {
                        if (open) fetchAvailableWorkers();
                      }}
                      dropdownRender={(node) => (workersQueryIsFetching ? WebHelper.LoadingSpin : node)}
                      placeholder={WebHelper.formatMessage("DeviceNoWorker-assignNewWorker-placeholder")}
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      options={availableWorkers.map(({id, displayName}) => ({
                        key: id,
                        label: displayName,
                        value: id,
                      }))}
                    />
                  </div>
                )}
                <Radio value={RadioOptions.ChangeStatusOutOfService} className={styles.optionWrapper}>
                  {WebHelper.formatMessage("DeviceNoWorker-changeStatusOutOfService")}
                </Radio>
              </Space>
            </Radio.Group>
          </Space>
        }
      </Modal>
    );
  }
);
