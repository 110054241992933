import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";

export type SummaryMetrics = {
  total_lifts: number;
  safe_lifts: number;
  risky_lifts: number;
  safe_lift_score: number;
  usage_seconds: number;
  hours_used: number;
  weight_offloaded_lbs: number;
  excessive_forward_lifts: number;
  prolonged_bend_lifts: number;
  twisted_lifts: number;
  side_bend_lifts: number;
};

export class AnalyticsDetail extends BaseModel {
  worker_id: string;
  worker_first_name: string;
  worker_last_name: string;
  is_active: boolean;
  worker_employee_id: string;
  device_serial_number: string;
  device_tag: string;
  start_time: Date;
  end_time: Date;
  offset_minutes: number;
  time_zone: string;
  summary_metrics: SummaryMetrics;

  constructor() {
    super();
    makeObservable(this, {
      worker_id: observable,
      worker_first_name: observable,
      worker_last_name: observable,
      is_active: observable,
      worker_employee_id: observable,
      device_serial_number: observable,
      device_tag: observable,
      start_time: observable,
      end_time: observable,
      offset_minutes: observable,
      time_zone: observable,
      summary_metrics: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsDetail, json: any) {
    if (json.start_time) object.start_time = new Date(json.start_time);
    if (json.end_time) object.end_time = new Date(json.end_time);
  }

  get workerFullName(): string {
    return `${this.worker_first_name} ${this.worker_last_name}`;
  }

  get workerDisplayName(): string {
    return this.worker_first_name || this.worker_last_name ? this.workerFullName : this.worker_employee_id;
  }
}

export class AnalyticsDetails extends BaseModel {
  interval_details_data: AnalyticsDetail[];

  constructor() {
    super();
    makeObservable(this, {
      interval_details_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsDetails, json: any) {
    if (json.interval_details_data)
      object.interval_details_data = json.interval_details_data.map((timeSeries: AnalyticsDetail) => {
        return AnalyticsDetail.fromJSON(timeSeries);
      });
  }
}
