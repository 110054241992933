import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";

export class AnalyticsTimeDevices extends BaseModel {
  start_time: Date;
  end_time: Date;
  offset_minutes: number;
  time_zone: string;
  usage_hours: number;
  allocated_data: boolean;
  unallocated_data: boolean;

  constructor() {
    super();
    makeObservable(this, {
      start_time: observable,
      end_time: observable,
      offset_minutes: observable,
      time_zone: observable,
      usage_hours: observable,
      allocated_data: observable,
      unallocated_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsTimeDevices, json: any) {
    if (json.start_time) object.start_time = new Date(json.start_time);
    if (json.end_time) object.end_time = new Date(json.end_time);
  }
}

export class AnalyticsTimeDevicesData extends BaseModel {
  time_series_data: AnalyticsTimeDevices[];

  constructor() {
    super();
    makeObservable(this, {
      time_series_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsTimeDevicesData, json: any) {
    if (json.time_series_data)
      object.time_series_data = json.time_series_data.map((timeSeries: AnalyticsTimeDevices) => {
        return AnalyticsTimeDevices.fromJSON(timeSeries);
      });
  }
}
