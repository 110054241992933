import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";

export enum UpdateType {
  Forced = "forced",
  Scheduled = "scheduled",
}

export class BinaryVersion extends BaseModel {
  id: string;
  binary_type: string;
  version: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      binary_type: observable,
      version: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: BinaryVersion, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
  }
}
