import {useQuery} from "@tanstack/react-query";
import {Spin} from "antd";
import {DeviceModule} from "core/modules/DeviceModule";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {DepartmentModule} from "../../../../../core/modules/DepartmentModule";
import {OrganizationModule} from "../../../../../core/modules/OrganizationModule";
import {SiteModule} from "../../../../../core/modules/SiteModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";

export type EntityNameProps = {
  id?: string;
  type?: Models.EntityType;
};

export const EntityName: FunctionComponent<EntityNameProps> = observer((props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [name, setName] = useState(props.id);
  const [loading, setLoading] = useState(true);

  const {refetch: deviceQueryRefetch, data: deviceData} = useQuery({
    queryKey: ["EntityName-FetchDevice", props.id],
    queryFn: () => DeviceModule.device({accessToken: authenticationStore.state.accessToken!, id: props.id!}),
    enabled: props.type === Models.EntityType.Device,
  });

  const {refetch: organizationQueryRefetch, data: organizationData} = useQuery({
    queryKey: ["EntityName-FetchOrganization", props.id],
    queryFn: () => OrganizationModule.organization({accessToken: authenticationStore.state.accessToken!, id: props.id!}),
    enabled: props.type === Models.EntityType.Organization,
  });

  const {refetch: siteQueryRefetch, data: siteData} = useQuery({
    queryKey: ["EntityName-FetchSite", props.id],
    queryFn: () => SiteModule.site({accessToken: authenticationStore.state.accessToken!, id: props.id!}),
    enabled: props.type === Models.EntityType.Site,
  });

  const {refetch: departmentQueryRefetch, data: departmentData} = useQuery({
    queryKey: ["EntityName-FetchDepartment", props.id],
    queryFn: () => DepartmentModule.department({accessToken: authenticationStore.state.accessToken!, id: props.id!}),
    enabled: props.type === Models.EntityType.Department,
  });

  useEffect(() => {
    switch (props.type) {
      case Models.EntityType.Organization: {
        if (organizationData && organizationData.organization) {
          setName(organizationData.organization.name);
          setLoading(false);
        } else {
          organizationQueryRefetch().then(({data}) => {
            if (!data) return;
            if (data.success) setName(data.organization.name);
            setLoading(false);
          });
        }
        break;
      }
      case Models.EntityType.Site: {
        if (siteData && siteData.site) {
          setName(siteData.site.name);
          setLoading(false);
        } else {
          siteQueryRefetch().then(({data}) => {
            if (!data) return;
            if (data.success) setName(data.site.name);
            setLoading(false);
          });
        }
        break;
      }
      case Models.EntityType.Department: {
        if (departmentData && departmentData.department) {
          setName(departmentData.department.name);
          setLoading(false);
        } else {
          departmentQueryRefetch().then(({data}) => {
            if (!data) return;
            if (data.success) setName(data.department.name);
            setLoading(false);
          });
        }
        break;
      }
      case Models.EntityType.Device: {
        if (deviceData && deviceData.device) {
          setName(deviceData.device.system_serial_number);
          setLoading(false);
        } else {
          deviceQueryRefetch().then(({data}) => {
            if (!data) return;
            if (data.success) setName(data.device.system_serial_number);
            setLoading(false);
          });
        }
        break;
      }
    }
  }, [
    props.id,
    props.type,
    authenticationStore.state.accessToken,
    organizationData,
    organizationQueryRefetch,
    siteData,
    siteQueryRefetch,
    departmentData,
    departmentQueryRefetch,
    deviceQueryRefetch,
    deviceData,
  ]);

  if (!props.id) {
    return null;
  } else if (!props.type) {
    return <>{props.id}</>;
  }

  return loading ? <Spin size="small" /> : <>{name}</>;
});
