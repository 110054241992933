import {AnalyticsAPIClient} from "../apiclient/analytics/AnalyticsAPIClient";
import * as AnalyticsTypes from "../apiclient/analytics/AnalyticsAPIClient.types";

async function analyticsHistory(request: AnalyticsTypes.AnalyticsHistoryRequest): Promise<AnalyticsTypes.AnalyticsHistoryResponse> {
  return await AnalyticsAPIClient.analyticsHistory(request);
}

async function analyticsDashboard(request: AnalyticsTypes.AnalyticsDashboardRequest): Promise<AnalyticsTypes.AnalyticsDashboardResponse> {
  return await AnalyticsAPIClient.analyticsDashboard(request);
}

async function analyticsDashboardCard(
  request: AnalyticsTypes.AnalyticsDashboardCardRequest
): Promise<AnalyticsTypes.AnalyticsDashboardCardResponse> {
  return await AnalyticsAPIClient.analyticsDashboardCard(request);
}

async function analyticsTimeSeriesData(
  request: AnalyticsTypes.AnalyticsTimeSeriesDataRequest
): Promise<AnalyticsTypes.AnalyticsTimeSeriesDataResponse> {
  return await AnalyticsAPIClient.analyticsTimeSeriesData(request);
}

async function analyticsMultiTimeSeriesData(
  request: AnalyticsTypes.AnalyticsMultiTimeSeriesDataRequest
): Promise<AnalyticsTypes.AnalyticsMultiTimeSeriesDataResponse> {
  return await AnalyticsAPIClient.analyticsMultiTimeSeriesData(request);
}

async function analyticsDashboardWorkerData(
  request: AnalyticsTypes.AnalyticsDashboardWorkerDataRequest
): Promise<AnalyticsTypes.AnalyticsDashboardWorkerDataResponse> {
  return await AnalyticsAPIClient.analyticsDashboardWorkerData(request);
}

async function workerSafeliftReport(
  request: AnalyticsTypes.WorkerSafeliftReportRequest
): Promise<AnalyticsTypes.WorkerSafeliftReportResponse> {
  return await AnalyticsAPIClient.WorkerSafeliftReport(request);
}

async function analyticsDevicesData(
  request: AnalyticsTypes.AnalyticsDevicesDataRequest
): Promise<AnalyticsTypes.AnalyticsDevicesDataResponse> {
  return await AnalyticsAPIClient.analyticsDevicesData(request);
}

async function analyticsTimeSeriesByDaysInSuit(
  request: AnalyticsTypes.AnalyticsTimeSeriesByDaysInSuitRequest
): Promise<AnalyticsTypes.AnalyticsTimeSeriesByDaysInSuitResponse> {
  return await AnalyticsAPIClient.analyticsTimeSeriesByDaysInSuit(request);
}

async function analyticsTimeSeriesIntraDay(
  request: AnalyticsTypes.AnalyticsTimeSeriesIntraDayRequest
): Promise<AnalyticsTypes.AnalyticsTimeSeriesIntraDayResponse> {
  return await AnalyticsAPIClient.analyticsTimeSeriesIntraDay(request);
}

async function analyticsDetails(request: AnalyticsTypes.AnalyticsDetailsRequest): Promise<AnalyticsTypes.AnalyticsDetailsResponse> {
  return await AnalyticsAPIClient.analyticsDetails(request);
}

export const AnalyticsModule = {
  analyticsHistory,
  analyticsDashboard,
  analyticsDashboardCard,
  analyticsTimeSeriesData,
  analyticsMultiTimeSeriesData,
  analyticsDashboardWorkerData,
  workerSafeliftReport,
  analyticsDevicesData,
  analyticsTimeSeriesByDaysInSuit,
  analyticsTimeSeriesIntraDay,
  analyticsDetails,
};
