import {Breadcrumb, Typography, Tooltip} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";

import {EntityName} from "./entity-name/EntityName";
import styles from "./Subheader.module.scss";
import * as Models from "../../../../core/models";

export type SubheaderBreadcrumbItem = {
  url?: string;
  name?: string;
  id: string;
  type?: Models.EntityType;
  secondId?: string;
  secondType?: Models.EntityType;
};

export type SubheaderProps = {
  breadcrumbItems?: SubheaderBreadcrumbItem[];
  singleLevel?: boolean;
};

export const Subheader: FunctionComponent<SubheaderProps> = observer((props) => {
  return (
    <div id="subheaderWrapper" className={styles.container}>
      <Breadcrumb>
        {props.breadcrumbItems?.map((item) => {
          const title = item.name ? (
            item.name
          ) : item.secondId ? (
            <>
              <EntityName id={item.secondId} type={item.secondType} /> {">"} <EntityName id={item.id} type={item.type} />
            </>
          ) : (
            <EntityName id={item.id} type={item.type} />
          );
          return (
            <Breadcrumb.Item key={item.id} className={`${styles.breadcrumbItem} ${props.singleLevel ? styles.singleLevel : ""}`}>
              <Tooltip title={title} placement="bottom" overlayClassName={styles.tooltip}>
                {item.url ? (
                  <Link to={item.url}>{item.name ? item.name : title}</Link>
                ) : (
                  <Typography.Text>{item.name ? item.name : title}</Typography.Text>
                )}
              </Tooltip>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
});
