import {GatewayAPIClient} from "../apiclient/gateway/GatewayAPIClient";
import * as GatewayTypes from "../apiclient/gateway/GatewayAPIClient.types";

async function gateways(request: GatewayTypes.GatewaysRequest, shouldUpdateState = true): Promise<GatewayTypes.GatewaysResponse> {
  return await GatewayAPIClient.gateways(request);
}

async function gateway(request: GatewayTypes.GatewayRequest): Promise<GatewayTypes.GatewayResponse> {
  return await GatewayAPIClient.gateway(request);
}

async function createGateway(request: GatewayTypes.CreateGatewayRequest): Promise<GatewayTypes.CreateGatewayResponse> {
  return await GatewayAPIClient.createGateway(request);
}

async function deleteGateway(request: GatewayTypes.DeleteGatewayRequest): Promise<GatewayTypes.DeleteGatewayResponse> {
  return await GatewayAPIClient.deleteGateway(request);
}

async function updateGateway(request: GatewayTypes.UpdateGatewayRequest): Promise<GatewayTypes.UpdateGatewayResponse> {
  return await GatewayAPIClient.updateGateway(request);
}

async function allocateGatewayToDepartment(
  request: GatewayTypes.AllocateGatewayToDepartmentRequest
): Promise<GatewayTypes.AllocateGatewayToDepartmentResponse> {
  return await GatewayAPIClient.allocateGatewayToDepartment(request);
}

async function latestGatewaySoftwareVersion(
  request: GatewayTypes.LatestGatewaySoftwareRequest
): Promise<GatewayTypes.LatestGatewaySoftwareResponse> {
  return await GatewayAPIClient.latestGatewaySoftwareVersion(request);
}

async function gatewaySoftwareVersions(
  request: GatewayTypes.GatewaySoftwareVersionsRequest
): Promise<GatewayTypes.GatewaySoftwareVersionsResponse> {
  return await GatewayAPIClient.gatewaySoftwareVersions(request);
}

async function gatewaySoftwareVersion(
  request: GatewayTypes.GatewaySoftwareVersionRequest
): Promise<GatewayTypes.GatewaySoftwareVersionResponse> {
  return await GatewayAPIClient.gatewaySoftwareVersion(request);
}

async function updateGatewaySoftwareVersion(
  request: GatewayTypes.UpdateGatewaySoftwareRequest
): Promise<GatewayTypes.UpdateGatewaySoftwareResponse> {
  return await GatewayAPIClient.updateGatewaySoftwareVersion(request);
}

export const GatewayModule = {
  gateways,
  gateway,
  createGateway,
  deleteGateway,
  updateGateway,
  allocateGatewayToDepartment,
  latestGatewaySoftwareVersion,
  gatewaySoftwareVersions,
  gatewaySoftwareVersion,
  updateGatewaySoftwareVersion,
};
