import {CloseCircleFilled} from "@ant-design/icons";
import {Space, Typography} from "antd";
import React, {FunctionComponent, useMemo} from "react";
import {WebHelper} from "web/utils/WebHelper";

import styles from "./CreationFailed.module.scss";
import * as Models from "../../../../../../core/models";

export enum ProfileErrorType {
  NotEnoughInfo,
  EmployeeIDAlreadyExists,
  NameAlreadyExist,
}

export type ProfileError = {
  type: ProfileErrorType;
  worker: Models.BasicWorker;
};

export enum CreationErrorType {
  EmptyRows,
  NoRows,
  MissingColumn,
  DuplicatedValues,
  ProfileErrors,
}

export type CreationError =
  | {
      type: CreationErrorType.EmptyRows | CreationErrorType.MissingColumn | CreationErrorType.NoRows | CreationErrorType.DuplicatedValues;
    }
  | {
      type: CreationErrorType.ProfileErrors;
      workersWithErrors: ProfileError[];
    };

type CreationFailedProps = {
  creationError: CreationError;
  siteName: string;
  departmentName: string;
};

export const CreationFailed: FunctionComponent<CreationFailedProps> = ({creationError, siteName, departmentName}) => {
  const errorsList = useMemo(() => {
    switch (creationError.type) {
      case CreationErrorType.EmptyRows:
        return <Typography.Text>{WebHelper.formatMessage("CreationFailed-emptyRowsError")}</Typography.Text>;
      case CreationErrorType.NoRows:
        return <Typography.Text>{WebHelper.formatMessage("CreationFailed-noRowsError")}</Typography.Text>;
      case CreationErrorType.MissingColumn:
        return <Typography.Text>{WebHelper.formatMessage("CreationFailed-missingColumnError")}</Typography.Text>;
      case CreationErrorType.DuplicatedValues:
        return <Typography.Text>{WebHelper.formatMessage("CreationFailed-duplicatedValuesError")}</Typography.Text>;
      case CreationErrorType.ProfileErrors:
        return creationError.workersWithErrors.map(({type, worker}, index) => {
          switch (type) {
            case ProfileErrorType.NotEnoughInfo:
              return (
                <Typography.Text key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: WebHelper.parseMarkdown(
                        WebHelper.formatMessage("CreationFailed-notEnoughInfoError", {
                          firstName: worker.first_name,
                          lastName: worker.last_name,
                          employeeID: worker.employee_id,
                        })
                      ),
                    }}
                  />
                </Typography.Text>
              );
            case ProfileErrorType.EmployeeIDAlreadyExists:
              return (
                <Typography.Text key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: WebHelper.parseMarkdown(
                        WebHelper.formatMessage("CreationFailed-employeeIDAlreadyExistsError", {
                          firstName: worker.first_name,
                          lastName: worker.last_name,
                          employeeID: worker.employee_id,
                          siteName,
                        })
                      ),
                    }}
                  />
                </Typography.Text>
              );
            case ProfileErrorType.NameAlreadyExist:
              return (
                <Typography.Text key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: WebHelper.parseMarkdown(
                        worker.employee_id
                          ? WebHelper.formatMessage("CreationFailed-nameAlreadyExistsError", {
                              firstName: worker.first_name,
                              lastName: worker.last_name,
                              employeeID: worker.employee_id,
                              siteName,
                            })
                          : WebHelper.formatMessage("CreationFailed-nameAlreadyExistsErrorWithoutID", {
                              firstName: worker.first_name,
                              lastName: worker.last_name,
                              siteName,
                            })
                      ),
                    }}
                  />
                </Typography.Text>
              );
          }
        });
      default:
        return null;
    }
  }, [creationError, siteName]);

  return (
    <div className={styles.contentWrapper}>
      <Typography.Title level={5}>
        <Space size={16}>
          <CloseCircleFilled className={styles.errorIcon} />
          {WebHelper.formatMessage("CreationFailed-subtitle")}
        </Space>
      </Typography.Title>
      <Typography.Text className={styles.text}>
        <div
          dangerouslySetInnerHTML={{
            __html: WebHelper.parseMarkdown(WebHelper.formatMessage("CreationFailed-firstText", {siteName, departmentName})),
          }}
        />
      </Typography.Text>

      <Typography.Text className={styles.text}>{WebHelper.formatMessage("CreationFailed-secondText")}</Typography.Text>

      {errorsList}
    </div>
  );
};
