import {Locale} from "./Locale";
import {fr_CA as baseCoreLocale} from "../../core/locales/fr_CA";
import {Locale as CoreLocale} from "../../core/locales/Locale";
import {CoreHelper} from "../../core/utils/CoreHelper";

const uiLocale: Omit<Locale, keyof CoreLocale> = {
  "AboutUs-appVersion": "Version de l'application: {version} ({currentCommitHash})",
  "AboutUs-backendVersion": "Version du backend: {version} ({currentCommitHash})",
  "AboutUs-copyright": "Copyright {year}",
  "AboutUs-fetchError": "Une erreur s'est produite pendant la tentative d'obtention de la version du backend",
  "AboutUs-subtitle": "À propos de Verve Logic",
  "AccountSettings-department": "Département:",
  "AccountSettings-email": "Courriel:",
  "AccountSettings-english": "Anglais",
  "AccountSettings-firstName": "Prénom:",
  "AccountSettings-french": "Français",
  "AccountSettings-jobTitle": "Titre de l'emploi:",
  "AccountSettings-metricUnits": "Utiliser des unités métriques:",
  "AccountSettings-lastName": "Nom:",
  "AccountSettings-organization": "Organisation:",
  "AccountSettings-organizationGlobal": "MONDIAL",
  "AccountSettings-phoneNumberError":
    "Le numéro de téléphone doit commencer par + et ne contenir que des chiffres (y compris l'indicatif du pays)",
  "AccountSettings-preferredLanguage": "Choix de langue:",
  "AccountSettings-profileData": "Données du profil",
  "AccountSettings-spanish": "Espagnol",
  "AccountSettings-subscribed": "S'abonner au courriel administratif:",
  "AccountSettings-title": "Paramètres du compte",
  "AccountSettings-updateError": "Une erreur s'est produite pendant la tentative de mise à jour des données de l'utilisateur",
  "AccountSettings-updateErrorPhoneNumber": "Numéro de téléphone invalide. Veuillez vérifier et réessayer",
  "AccountSettings-workAddress": "Adresse professionnelle:",
  "AccountSettings-workPhone": "Téléphone au travail:",
  "ActiveInactiveSiteSummaryPage-activeWorkers": "Travailleurs actifs",
  "ActiveInactiveSiteSummaryPage-daysActiveLast7DaysColumnTitle": "Jours actifs au cours des 7 derniers jours",
  "ActiveInactiveSiteSummaryPage-daysSinceLastUsageColumnTitle": "Jours depuis la dernière utilisation",
  "ActiveInactiveSiteSummaryPage-deviceTagColumnTitle": "Étiquette de l'appareil",
  "ActiveInactiveSiteSummaryPage-employeeIDTitle": "ID de l'employé",
  "ActiveInactiveSiteSummaryPage-hoursLast7DaysColumnTitle": "Heures des 7 derniers jours",
  "ActiveInactiveSiteSummaryPage-inactiveWorkers": "Travailleurs inactifs",
  "ActiveInactiveSiteSummaryPage-kilogramsUnit": "kg",
  "ActiveInactiveSiteSummaryPage-nameColumnTitle": "Nom",
  "ActiveInactiveSiteSummaryPage-poundsUnit": "lb",
  "ActiveInactiveSiteSummaryPage-relativeToTargetColumnTitle": "Par rapport à la cible",
  "ActiveInactiveSiteSummaryPage-safeliftScore": "Score Safelift",
  "ActiveInactiveSiteSummaryPage-weeksSinceFirstUseColumnTitle": "Jours depuis la première utilisation",
  "ActiveInactiveSiteSummaryPage-weightOffloaded": "Poids déchargé",
  "ActiveInactiveWorkersReportDropdown-WorkersActivityReport": "Rapport d'activité des travailleurs",
  "ActiveInactiveWorkersReportDropdown-activeWorkers": "Travailleurs actifs",
  "ActiveInactiveWorkersReportDropdown-asPDF": "En PDF",
  "ActiveInactiveWorkersReportDropdown-asXLSX": "En XLSX",
  "ActiveInactiveWorkersReportDropdown-deviceTagColumnTitle": "Étiquette de l'appareil",
  "ActiveInactiveWorkersReportDropdown-daysActiveLast7DaysColumnTitle": "Jours actifs au cours des 7 derniers jours",
  "ActiveInactiveWorkersReportDropdown-daysSinceLastUsageColumnTitle": "Jours depuis la dernière utilisation",
  "ActiveInactiveWorkersReportDropdown-downloadData": "Télécharger les données",
  "ActiveInactiveWorkersReportDropdown-employeeIDTitle": "ID de l'employé",
  "ActiveInactiveWorkersReportDropdown-hoursLast7DaysColumnTitle": "Heures des 7 derniers jours",
  "ActiveInactiveWorkersReportDropdown-inactiveWorkers": "Travailleurs inactifs",
  "ActiveInactiveWorkersReportDropdown-nameColumnTitle": "Nom",
  "ActiveInactiveWorkersReportDropdown-workerColumnTitle": "Travailleur",
  "ActiveInactiveWorkersReportDropdown-pageCount": "Page {currentPage} de {totalPages}",
  "ActiveInactiveWorkersReportDropdown-relativeToTargetColumnTitle": "Par rapport à la cible",
  "ActiveInactiveWorkersReportDropdown-weeksSinceFirstUseColumnTitle": "Jours depuis la première utilisation",
  "AddDepartmentForm-title": "Ajouter un département",
  "AddOrganizationForm-title": "Ajouter une organisation",
  "AddSiteForm-title": "Ajouter un site",
  "AddWorkersModal-errorMessage": "Une erreur s'est produite pendant la tentative de mise à jour du groupe de travailleurs",
  "AddWorkersModal-noWorkersText": "Aucun travailleur trouvé",
  "AddWorkersModal-selectWorkersLabel": "Sélectionnez les travailleurs à ajouter à ce groupe:",
  "AddWorkersModal-successMessage": "Les travailleurs sélectionnés ont été ajoutés avec succès",
  "AddWorkersModal-title": "Ajouter des travailleurs",
  "AddWorkersModal-workers": "Travailleurs:",
  "AllocateGateway-allocate": "Attribuer",
  "AllocateGateway-allocateGatewayButton": "Attribuer la passerelle",
  "AllocateGateway-allocateGatewayLabel": "Attribuer la passerelle",
  "AllocateGateway-allocateGatewayModalMessage":
    "Choisissez le numéro de série de la passerelle que vous souhaitez attribuer au **{departmentName}**",
  "AllocateGateway-allocateGatewayModalTitle": "Attribuer la passerelle",
  "AllocateGateway-allocateGatewayToDepartmentError": "La passerelle n'a pas pu être attribuée",
  "AllocateGateway-gatewaySerialNumberLabel": "# de série de la passerelle:",
  "AllocateSelectedData-confirmation": "Confirmation",
  "AllocateSelectedData-dateRange": "Période:",
  "AllocateSelectedData-finished": "Terminé",
  "AllocateSelectedData-infoMeesage":
    "Les dates sont inclusives. Les données des dates de début et de fin seront attribuées au travailleur sélectionné",
  "AllocateSelectedData-inProgress": "En cours",
  "AllocateSelectedData-modalTitle": "Attribuer les données sélectionnées",
  "AllocateSelectedData-select": "Sélectionnez une période et un travailleur",
  "AllocateSelectedData-selectEndDate": "Date de fin",
  "AllocateSelectedData-selectText":
    "Veuillez sélectionner la période souhaitée et le travailleur pour réattribuer les données de l'appareil",
  "AllocateSelectedData-selectStartDate": "Date de début",
  "AllocateSelectedData-selectWorker": "Sélectionnez un travailleur",
  "AllocateSelectedData-singleDay": "Un jour",
  "AllocateSelectedData-updateError": "Une erreur s'est produite pendant la tentative de mise à jour du travailleur assigné à la session",
  "AllocateSelectedData-updateSuccess":
    "Les données de l'appareil {deviceTag} ont été réattribuées au travailleur {workerName} avec succès",
  "AllocateSelectedData-waiting": "En attente",
  "AllocateSelectedData-worker": "Travailleur:",
  "AllocateSelectedData-confirmationText":
    "Veuillez confirmer que vous souhaitez assigner toutes les données de l'appareil **{deviceTag}** du **{startDate}** au **{endDate}** à **{workerName}**",
  "AllocationFailed-alreadyAllocatedToCurrentDepartmentError":
    "**{serialNumber}**: L'appareil est déjà attribué à **{siteName}** – **{departmentName}**",
  "AllocationFailed-alreadyAllocatedToOtherDepartmentError": "**{serialNumber}**: L'appareil est déjà attribué à un autre département",
  "AllocationFailed-emptyRowsError": "Des lignes avec des valeurs vides ont été détectées",
  "AllocationFailed-firstText": "Aucun appareil n'a été attribué à **{siteName}** – **{departmentName}**",
  "AllocationFailed-missingColumnError": "La colonne {columnName} est introuvable dans le fichier téléchargé",
  "AllocationFailed-noRowsError": "Aucun numéro de série n'a été trouvé dans le fichier téléchargé",
  "AllocationFailed-secondText": "Les erreurs suivantes sont survenues:",
  "AllocationFailed-serialNumberNotFoundError": "**{serialNumber}**: Numéro de série introuvable",
  "AllocationFailed-subtitle": "Échec d'attribution",
  "AllWorkersReportDrawer-contentMessage":
    "Le rapport de tous les travailleurs couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport.",
  "AllWorkersReportDrawer-drawerTitle": "Rapport de tous les travailleurs",
  "AllWorkersReportDrawer-fileName": "Rapport de tous les travailleurs",
  "Analytics-csvColumn-assignedWorkers": "Travailleurs assignés",
  "Analytics-csvColumn-activeWorkers": "Travailleurs actifs",
  "Analytics-csvColumn-activeWorkersPercentage": "Pourcentage des travailleurs actifs",
  "Analytics-csvColumn-endDate": "Date de fin",
  "Analytics-csvColumn-endTime": "Heure de fin",
  "Analytics-csvColumn-excessiveForwardLifts": "Soulèvements excessifs vers l'avant",
  "Analytics-csvColumn-hoursUsed": "Heures utilisées",
  "Analytics-csvColumn-inactiveWorkers": "Travailleurs inactifs",
  "Analytics-csvColumn-inactiveWorkersPercentage": "Pourcentage des travailleurs inactifs",
  "Analytics-csvColumn-prolongedBendLifts": "Soulèvements prolongés en flexion",
  "Analytics-csvColumn-resourceID": "ID de ressource",
  "Analytics-csvColumn-resourceName": "Nom de ressource",
  "Analytics-csvColumn-resourceType": "Type de ressource",
  "Analytics-csvColumn-riskyLifts": "Soulèvements à risques",
  "Analytics-csvColumn-riskyLiftsPercentage": "Pourcentage de soulèvements à risques",
  "Analytics-csvColumn-safeLifts": "Soulèvements sécuritaires",
  "Analytics-csvColumn-safeLiftsPercentage": "Pourcentage de soulèvements sécuritaires",
  "Analytics-csvColumn-sideBendLifts": "Soulèvements en flexion latérale",
  "Analytics-csvColumn-startDate": "Date de début",
  "Analytics-csvColumn-startTime": "Heure de début",
  "Analytics-csvColumn-totalLifts": "Total des soulèvements",
  "Analytics-csvColumn-twistedLifts": "Soulèvements en torsion",
  "Analytics-csvColumn-weightOffloaded": "Poids déchargé",
  "Analytics-fetchDataError": "Une erreur s'est produite pendant la récupération des données",
  "Analytics-noOrgPlaceholderTitle": "Sélectionnez une organisation en haut de cette page pour consulter les données analytiques",
  "AnalyticsChart-averageHoursUsed": "Moyenne d'heures utilisées par travailleur actif",
  "AnalyticsChart-averageOfLifts": "Moyenne de soulèvements par travailleur actif",
  "AnalyticsChart-datasetLabel-activeWorkers": "Actif ",
  "AnalyticsChart-datasetLabel-excessiveForwardBending": "Flexion excessive vers l'avant",
  "AnalyticsChart-datasetLabel-hoursUsed": "Heures utilisées",
  "AnalyticsChart-datasetLabel-inactiveWorkers": "Inactif",
  "AnalyticsChart-datasetLabel-prolongedBending": "Flexion prolongée",
  "AnalyticsChart-datasetLabel-risky": "À risques",
  "AnalyticsChart-datasetLabel-safe": "Sécuritaire",
  "AnalyticsChart-datasetLabel-sideBending": "Flexion latérale",
  "AnalyticsChart-datasetLabel-target": "Cible",
  "AnalyticsChart-datasetLabel-twisting": "Torsion",
  "AnalyticsChart-dataTypeSelector-label": "Données",
  "AnalyticsChart-dataTypeSelector-percentage": "Pourcentage",
  "AnalyticsChart-dataTypeSelector-raw": "Brut",
  "AnalyticsChart-dayDaysInSuit": "Jour {day}",
  "AnalyticsChart-errorResultMessage": "Erreur lors de la recherche de données. Veuillez actualiser la page avant de réessayer",
  "AnalyticsChart-hoursUnit": "Heures utilisées",
  "AnalyticsChart-hoursUsed": "Heures utilisées",
  "AnalyticsChart-liftsUnit": "Soulèvements",
  "AnalyticsChart-monthDaysInSuit": "Mois {month}",
  "AnalyticsChart-movementBreakdown": "Répartition des mouvements",
  "AnalyticsChart-movementSelector": "Mouvements",
  "AnalyticsChart-percentageOfLifts": "% de soulèvements",
  "AnalyticsChart-percentageOfWorkers": "% de travailleurs",
  "AnalyticsChart-poundsUnit": "lb",
  "AnalyticsChart-quarterDaysInSuit": "Trimestre {quarter}",
  "AnalyticsChart-tooltip-totalLifts": "Total: {total} soulèvements",
  "AnalyticsChart-tooltip-totalWorkers": "Assigné: {assigned} travailleurs",
  "AnalyticsChart-weekDaysInSuit": "Semaine {week}",
  "AnalyticsChart-weightOffloaded": "Poids déchargé",
  "AnalyticsChart-weightOffloadedAverageKgs": "Moyenne de poids déchargé par travailleur actif (kg)",
  "AnalyticsChart-weightOffloadedAverageLbs": "Moyenne de poids déchargé par travailleur actif (lb)",
  "AnalyticsChart-weightOffloadedUnitKgs": "Poids déchargé (kg)",
  "AnalyticsChart-weightOffloadedUnitLbs": "Poids déchargé (lb)",
  "AnalyticsChart-workersUnit": "Travailleurs",
  "AnalyticsDetails-activeWorkers": "Travailleurs Actifs",
  "AnalyticsDetails-allWorkers": "Tous les travailleurs",
  "AnalyticsDetails-description": "Voici une répartition détaillée des données pour l'intervalle de **{day}**",
  "AnalyticsDetails-downloadDataButton": "Télécharger les Données",
  "AnalyticsDetails-forwardColumn#": "Flexions Avant #",
  "AnalyticsDetails-forwardColumn%": "Flexions Avant %",
  "AnalyticsDetails-fullNameColumn": "Nom Complet",
  "AnalyticsDetails-inactiveWorkers": "Travailleurs Inactifs",
  "AnalyticsDetails-isActive": "Actif",
  "AnalyticsDetails-movementBreakdown": "Répartition des Mouvements",
  "AnalyticsDetails-prolongedColumn#": "Flexions Prolongées #",
  "AnalyticsDetails-prolongedColumn%": "Flexions Prolongées %",
  "AnalyticsDetails-riskyColumn#": "Levers Risqués #",
  "AnalyticsDetails-riskyColumn%": "Levers Risqués %",
  "AnalyticsDetails-safeColumn#": "Levers Sûrs #",
  "AnalyticsDetails-safeColumn%": "Levers Sûrs %",
  "AnalyticsDetails-sideBendColumn#": "Flexions Latérales #",
  "AnalyticsDetails-sideBendColumn%": "Flexions Latérales %",
  "AnalyticsDetails-titleModal": "Détails des Données",
  "AnalyticsDetails-totalHours": "Total des Heures",
  "AnalyticsDetails-totalLiftsColumn": "Total des Levers",
  "AnalyticsDetails-twistedColumn#": "Torsions #",
  "AnalyticsDetails-twistedColumn%": "Torsion %",
  "AnalyticsDetails-weightOffloaded": "Poids Déchargé",
  "AnalyticsDetails-weightOffloadedColumn": "Poids Total Déchargé",
  "AnalyticsSettings-afterDate": "Après une date:",
  "AnalyticsSettings-afterSpecificDate": "Après une date spécifique",
  "AnalyticsSettings-allWorkersLabel": "Tous les travailleurs",
  "AnalyticsSettings-applyFilters": "Appliquer la configuration",
  "AnalyticsSettings-average": "Moyenne",
  "AnalyticsSettings-beforeDate": "Avant une date:",
  "AnalyticsSettings-beforeSpecificDate": "Avant une date spécifique",
  "AnalyticsSettings-betweenDates": "Entre les périodes",
  "AnalyticsSettings-calendarDate": "Jours de calendrier",
  "AnalyticsSettings-calendarDateDescription":
    "*Jour/s de calendrier : Données du graphique selon la date et l'heure de leur enregistrement.",
  "AnalyticsSettings-checkboxDescription": "Veuillez sélectionner les mouvements à afficher sur le graphique.",
  "AnalyticsSettings-current": "Actuel",
  "AnalyticsSettings-data": "Données:",
  "AnalyticsSettings-dataFormat": "Format des données:",
  "AnalyticsSettings-dataScope": "Portée des données:",
  "AnalyticsSettings-dataScopeTextDaysInTheSuit": "Filtrer en fonction du premier jour de l'ouvrier dans la combinaison.",
  "AnalyticsSettings-dataScopeDaysInTheSuit": "Afficher les ouvriers qui ont commencé...",
  "AnalyticsSettings-dataScopeTimeOfDay": "Agréguer les données enregistrées entre",
  "AnalyticsSettings-dataScopeCalendarDays": "Graphique de toutes les données...",
  "AnalyticsSettings-dateRange": "Période:",
  "AnalyticsSettings-dateRangeInfo":
    "Les filtres de Plage de Dates et de Portée des Données sélectionnent les travailleurs dont le début dans le costume se situe entre ces dates.",
  "AnalyticsSettings-day": "Jour",
  "AnalyticsSettings-daysInSuit": "Jours avec la combinaison",
  "AnalyticsSettings-daysInSuitDescription":
    "*Jours en tenue : Afficher les données en fonction du nombre de jours où les travailleurs ont porté la tenue.",
  "AnalyticsSettings-displayInGraph": "Afficher le graphique",
  "AnalyticsSettings-displayMaxData": "Afficher les données maximales:",
  "AnalyticsSettings-displayTrendlines": "Utiliser des unités métriques:",
  "AnalyticsSettings-displayMaxDataTooltip":
    "Représentation graphique d'un ensemble de données jusqu'à 30 intervalles (30 jours ou 30 semaines)",
  "AnalyticsSettings-eightHour": "8h",
  "AnalyticsSettings-emptyWorkerReportGroupsTooltip": "Aucun groupe de rapport de travailleur trouvé",
  "AnalyticsSettings-emptyWorkersTooltip": "Aucun travailleur trouvé",
  "AnalyticsSettings-endDate": "Jusqu'à la date :",
  "AnalyticsSettings-entitySelectorTooltip": "Vous pouvez sélectionner jusqu'à 5 entités",
  "AnalyticsSettings-excessiveForwardBending": "Flexion excessive vers l'avant",
  "AnalyticsSettings-fifteenMinutes": "15 min",
  "AnalyticsSettings-filter": "Filtre:",
  "AnalyticsSettings-fourHour": "4h",
  "AnalyticsSettings-hoursMinutes": "Heures/Minutes",
  "AnalyticsSettings-hoursUsed": "Heures utilisées",
  "AnalyticsSettings-individualWorkerLabel": "Travailleur Individuel",
  "AnalyticsSettings-interval": "Intervalle:",
  "AnalyticsSettings-infoDateRange": "Le graphique est limité à 100 intervalles ou moins.",
  "AnalyticsSettings-intervalCount": "Quantité D'Intervalles:",
  "AnalyticsSettings-lifts": "Soulèvements",
  "AnalyticsSettings-loadingFilters": "Chargement des filtres...",
  "AnalyticsSettings-minimumLifts": "Soulèvements minimum:",
  "AnalyticsSettings-minimumLiftsTooltip":
    "Ne représente pas graphiquement les intervalles dont le nombre de soulèvements est inférieur au nombre sélectionné",
  "AnalyticsSettings-modalTextAfterDate":
    "Le graphique ne peut pas afficher plus de 100 intervalles. Pour voir les données après le {maxDateToShow}, veuillez ajuster la plage de dates sélectionnée sous Configurer le graphique.",
  "AnalyticsSettings-modalTextBeforeDate":
    "Le graphique ne peut pas afficher plus de 100 intervalles. Pour voir les données avant le {maxDateToShow}, veuillez ajuster la plage de dates sélectionnée sous Configurer le graphique.",
  "AnalyticsSettings-month": "Mois",
  "AnalyticsSettings-mostRecent": "Du plus récent",
  "AnalyticsSettings-movementBreakdown": "Répartition des mouvements",
  "AnalyticsSettings-movements": "Mouvements:",
  "AnalyticsSettings-oneHour": "1h",
  "AnalyticsSettings-percentage": "Pourcentage",
  "AnalyticsSettings-percentOfTarget": "Pourcentage/cible",
  "AnalyticsSettings-prolongedBending": "Flexion prolongée",
  "AnalyticsSettings-quarter": "Trimestre",
  "AnalyticsSettings-raw": "Brut",
  "AnalyticsSettings-rawSum": "Montant brut",
  "AnalyticsSettings-realTimeDataInfo":
    "Les sites sans combinaisons avec horloge en temps réel activée ne prennent pas en charge les rapports infrajournaliers.",
  "AnalyticsSettings-relativeTo": "Relatif à:",
  "AnalyticsSettings-risky": "À risques",
  "AnalyticsSettings-safe": "Sécuritaire",
  "AnalyticsSettings-select": "Veuillez sélectionner",
  "AnalyticsSettings-selectDate": "Sélectionnez une période:",
  "AnalyticsSettings-selectEndDate": "Date de fin",
  "AnalyticsSettings-selectFiltersText": "Veuillez sélectionner l'ensemble des filtres à appliquer au graphique analytique",
  "AnalyticsSettings-selectStartDate": "Date de début",
  "AnalyticsSettings-selectVariableText": "Veuillez sélectionner la variable à représenter sur le graphique.",
  "AnalyticsSettings-selectVariableMinimumText":
    "Veuillez sélectionner la variable à représenter sur le graphique et spécifier les valeurs minimales à afficher.",
  "AnalyticsSettings-sideBending": "Flexion latérale",
  "AnalyticsSettings-startDate": "À partir de la date :",
  "AnalyticsSettings-thirtyMinutes": "30 min",
  "AnalyticsSettings-timeOfTheDay": "Heure de la journée",
  "AnalyticsSettings-timeOfTheDayDescription":
    "*Heure de la journée : Regrouper et afficher les données en fonction de l'heure à laquelle elles ont été enregistrées, indépendamment de la date (par exemple, toutes les données enregistrées à 9h et 10h).",
  "AnalyticsSettings-timezoneMessage": "Les dates/heures sont dans le fuseau horaire *{timezone}*",
  "AnalyticsSettings-title": "Configurer Graphique",
  "AnalyticsSettings-trendlineText": "Données pour la ligne de tendance du {startDate} au {endDate}: **[y= m x+b]**",
  "AnalyticsSettings-twelveHour": "12h",
  "AnalyticsSettings-twisting": "Torsion",
  "AnalyticsSettings-twoHour": "2h",
  "AnalyticsSettings-variableToChart": "Variable du graphique:",
  "AnalyticsSettings-week": "Semaine",
  "AnalyticsSettings-weightOffloaded": "Poids déchargé",
  "AnalyticsSettings-workerActiveStatus": "Statut actif du travailleur",
  "AnalyticsSettings-workerReportGroupsLabel": "Groupes de rapports des travailleurs",
  "AnalyticsSettings-workers": "Travailleurs:",
  "AnalyticsSubheader-downloadDataButton": "Télécharger les données",
  "AnalyticsSubheader-loadingOrganizations": "Chargement des organisations...",
  "AnalyticsSubheader-selectOrganization": "Sélectionnez une organisation...",
  "AnalyticsSubheader-title": "Analytiques",
  "AnalyticsSubheader-titleSeparator": "/",
  "AnalyticsTabContent-activeWorkers": "Travailleurs actifs",
  "AnalyticsTabContent-asCsv": "En CSV",
  "AnalyticsTabContent-asImage": "En tant qu'image",
  "AnalyticsTabContent-averageMessage": "Moyenne de {unit} par {interval}: **{value}**",
  "AnalyticsTabContent-day": "Jour",
  "AnalyticsTabContent-errorMessage": "Une erreur s'est produite pendant la récupération des données",
  "AnalyticsTabContent-errorTimeoutMessage": "La demande a expiré. Veuillez modifier votre requête pour demander moins de données.",
  "AnalyticsTabContent-filtersConfiguration": "Configurer Graphique",
  "AnalyticsTabContent-hideFiltersLabel": "Masquer les filtres",
  "AnalyticsTabContent-hoursUnit": "heures",
  "AnalyticsTabContent-liftsUnit": "Soulèvements",
  "AnalyticsTabContent-poundsUnit": "lb",
  "AnalyticsTabContent-kilogramsUnit": "kg",
  "AnalyticsTabContent-month": "Mois",
  "AnalyticsTabContent-quarter": "Trimestre",
  "AnalyticsTabContent-showAllFiltersLabel": "Afficher tous les filtres",
  "AnalyticsTabContent-totalValueMessage": "{variable} au total par période sélectionnée: **{value} {unit}**",
  "AnalyticsTabContent-week": "Semaine",
  "App-accountSettings": "Paramètres du compte",
  "App-deactivatedUserSubtitle1": "Votre compte a été désactivé.",
  "App-deactivatedUserSubtitle2": "Si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'administrateur de votre site",
  "App-deactivatedUserTitle": "Compte désactivé",
  "App-deviceManagement": "Gestion de l'appareil",
  "App-fetchMeError": "Une erreur s'est produite pendant la récupération des données de l'utilisateur",
  "App-internetError": "Pas de connexion Internet, veuillez actualiser la page avant de réessayer",
  "App-logOut": "Se déconnecter",
  "App-menuItemAboutUs": "À propos de nous",
  "App-menuItemAdmin": "Admin",
  "App-menuItemAnalytics": "Analytiques",
  "App-menuItemAssignment": "Assignation",
  "App-menuItemConfiguration": "Configuration",
  "App-menuItemDashboard": "Tableau de bord",
  "App-menuItemDiagnostics": "Diagnostics",
  "App-menuItemHelpCenter": "Centre d'aide",
  "App-userPendingApprovalMessage1":
    "Votre administrateur de site n'a pas encore approuvé votre compte. Cette étape peut prendre un jour ou deux.",
  "App-userPendingApprovalMessage2": "Si vous avez des questions, veuillez envoyer un courriel à l'administrateur de votre site.",
  "App-userPermissions": "Autorisations de l'utilisateur",
  "BulkCreateDevicesModal-configuration": "Configuration",
  "BulkCreateDevicesModal-confirm": "Confirmer",
  "BulkCreateDevicesModal-confirmation": "Confirmation",
  "BulkCreateDevicesModal-errorMessage": "Une erreur s'est produite pendant la mise à jour des appareils, réessayez plus tard",
  "BulkCreateDevicesModal-finished": "Terminé",
  "BulkCreateDevicesModal-inProgress": "En cours",
  "BulkCreateDevicesModal-modalTitle": "Création groupée d'appareils, configuration et attribution",
  "BulkCreateDevicesModal-next": "Suivant",
  "BulkCreateDevicesModal-selectDevices": "Sélectionnez les appareils",
  "BulkCreateDevicesModal-successMessage": "Création groupée d'appareils complétée avec succès",
  "BulkCreateDevicesModal-waiting": "En attente",
  "BulkCreateStepThree-department": "Département:",
  "BulkCreateStepThree-loadingOrganizations": "Chargement des organisations...",
  "BulkCreateStepThree-selectDepartment": "Sélectionnez un seul département pour attribuer des appareils.",
  "BulkCreateStepThree-none": "Aucun",
  "BulkCreateStepThree-selectOrganization": "Sélectionnez une organisation...",
  "BulkCreateStepTwo-button1": "Bouton 1:",
  "BulkCreateStepTwo-button2": "Bouton 2:",
  "BulkCreateStepTwo-button3": "Bouton 3:",
  "BulkCreateStepTwo-firmwareVersion": "Version du micrologiciel:",
  "BulkCreateStepTwo-groupCount": "[{count} Appareils]:",
  "BulkCreateStepTwo-groupName": "Groupe {count}",
  "BulkCreateStepTwo-groupsMessage": "Nous avons identifié les groupes d'appareils suivants en fonction de leur type:",
  "BulkCreateStepTwo-noButtonSettings": "Aucun paramètre de bouton trouvé",
  "BulkCreateStepTwo-selectSettingsMessage": "Veuillez sélectionner les paramètres que vous souhaitez mettre à jour pour l'appareil:",
  "BulkCreateStepTwo-typeCount": "{name} [{count} Devices]",
  "BulkCreateStepOne-csvRadioText":
    "Télécharger la liste des numéros de série via un fichier CSV Colonnes: Numéro de série du système, ID Mcu",
  "BulkCreateStepOne-mcuIDColumnName": "Mcu ID",
  "BulkCreateStepOne-selectDevices": "Sélectionnez les numéros de série de l'appareil à créer",
  "BulkCreateStepOne-serialNumberColumnName": "Numéro de série du système",
  "BulkCreateStepOne-uploadFailDevicesTitle": "Tous les appareils en CSV ne sont pas disponibles pour la création.",
  "BulkCreateStepOne-uploadFailText": "Le fichier CSV n'est pas correctement formaté, veuillez corriger et réessayer.",
  "BulkCreateStepOne-uploadFailTitle": "Échec de téléchargement",
  "BulkUpdateDevicesModal-configuration": "Configuration",
  "BulkUpdateDevicesModal-confirm": "Confirmer",
  "BulkUpdateDevicesModal-confirmation": "Confirmation",
  "BulkUpdateDevicesModal-errorMessage": "Une erreur s'est produite pendant la mise à jour des appareils, réessayez plus tard",
  "BulkUpdateDevicesModal-finished": "Terminé",
  "BulkUpdateDevicesModal-inProgress": "En cours",
  "BulkUpdateDevicesModal-modalTitle": "Mise à jour groupée des appareils",
  "BulkUpdateDevicesModal-next": "Suivant",
  "BulkUpdateDevicesModal-selectDevices": "Sélectionnez les appareils",
  "BulkUpdateDevicesModal-successMessage": "La mise à jour de la configuration de l'appareil a été planifiée avec succès.",
  "BulkUpdateDevicesModal-waiting": "En attente",
  "BulkUpdateStepOne-allDevicesText": "Tous les appareils d'une org/site/département donné",
  "BulkUpdateStepOne-allOrgs": "Toutes les organisations",
  "BulkUpdateStepOne-devices": "Appareils:",
  "BulkUpdateStepOne-loadingOrganizations": "Chargement des organisations...",
  "BulkUpdateStepOne-locations": "Emplacements:",
  "BulkUpdateStepOne-manualDevicesText":
    "Sélectionnez une organisation/site/département puis saisissez manuellement un sous-ensemble de numéro de série à mettre à jour",
  "BulkUpdateStepOne-noDevicesText": "Aucun appareil pour ce département.",
  "BulkUpdateStepOne-selectDevices": "Sélectionnez les appareils à mettre à jour.",
  "BulkUpdateStepOne-selectOrganization": "Sélectionnez une organisation...",
  "BulkUpdateStepThree-button1": "Bouton 1:",
  "BulkUpdateStepThree-button2": "Bouton 2:",
  "BulkUpdateStepThree-button3": "Bouton 3:",
  "BulkUpdateStepThree-firmwareVersion": "Version du micrologiciel:",
  "BulkUpdateStepThree-groupCount": "[{count} Appareils]:",
  "BulkUpdateStepThree-groupName": "Groupe {count}",
  "BulkUpdateStepThree-groupsMessage": "Nous avons identifié les groupes d'appareils suivants en fonction de leur type:",
  "BulkUpdateStepThree-noButtonSettings": "Aucun paramètre de bouton trouvé",
  "BulkUpdateStepThree-selectSettingsMessage": "Veuillez sélectionner les paramètres que vous souhaitez mettre à jour pour l'appareil:",
  "BulkUpdateStepThree-typeCount": "{name} [{count} Devices]",
  "BulkUpdateStepTwo-confirmUpdateAll":
    "Veuillez confirmer que vous souhaitez mettre à jour TOUS les appareils dans les emplacements suivants:",
  "ChangeDeviceModal-assignDevice": "Assigner l'appareil",
  "ChangeDeviceModal-assignDeviceMessage": "Êtes-vous sûr de vouloir assigner **{workerName}** à l'appareil **{deviceTag}**?",
  "ChangeDeviceModal-assignDeviceSuccessMessage": "L'appareil {deviceTag} a été assigné à {workerName} avec succès",
  "ChangeDeviceModal-assignError": "Une erreur s'est produite pendant la mise à jour du travailleur",
  "ChangeDeviceModal-changeDevice": "Modifier un appareil",
  "ChangeDeviceModal-changeDeviceMessage":
    "Êtes-vous sûr de vouloir remplacer l'appareil **{deviceTag}** par **{newDeviceTag}** pour le travailleur **{workerName}**?",
  "ChangeDeviceModal-unassignDevice": "Attribuer un appareil",
  "ChangeDeviceModal-unassignDeviceMessage":
    "Êtes-vous sûr de vouloir retirer l'attribution de l'appareil **{deviceTag}** au travailleur **{workerName}**?",
  "ChangeWorkerModal-assignWorker": "Assigner un travailleur",
  "ChangeDeviceStatusModal-archive": "Archive:",
  "ChangeDeviceStatusModal-archiveError": "Une erreur s'est produite pendant la tentative d'archivage de l'appareil",
  "ChangeDeviceStatusModal-archived": "Archivé",
  "ChangeDeviceStatusModal-archivedTrueMessage":
    "Modifier l'appareil à **Archivé: Vrai ** rendra l'appareil visible uniquement à partir de la section Gestion des appareils",
  "ChangeDeviceStatusModal-changeStatusError": "Une erreur s'est produite pendant la tentative de modification du statut",
  "ChangeDeviceStatusModal-descriptionToInService": "Êtes-vous sûr de vouloir modifier le statut de **{deviceTag}** à **En service**?",
  "ChangeDeviceStatusModal-descriptionToOutService": "Êtes-vous sûr de vouloir modifier le statut de **{deviceTag}** à **Hors service**?",
  "ChangeDeviceStatusModal-notArchived": "Non archivé",
  "ChangeDeviceStatusModal-successMessageToInService": "Le statut de l'appareil {deviceTag} a été modifié à Hors service avec succès",
  "ChangeDeviceStatusModal-successMessageToOutService": "Le statut de l'appareil {deviceTag} a été modifié à Hors service avec succès",
  "ChangeDeviceStatusModal-successMessageToOutServiceAndArchived":
    "Le statut de l'appareil {deviceTag} a été modifié à Hors service et Archivé avec succès",
  "ChangeDeviceStatusModal-title": "Modifier le statut de l'appareil",
  "ChangeWorkerModal-assignWorkerMessage": "Êtes-vous sûr de vouloir assigner **{workerName}** à l'appareil **{deviceTag}**?",
  "ChangeWorkerModal-assignWorkerSuccessMessage":
    "L'assignation de l'appareil {deviceTag} au travailleur {workerName} a été effectuée avec succès",
  "ChangeWorkerModal-assignError": "Une erreur s'est produite pendant la mise à jour de l'appareil",
  "ChangeWorkerModal-changeWorker": "Modifier le travailleur",
  "ChangeWorkerModal-changeWorkerMessage":
    "Êtes-vous sûr de vouloir remplacer le travailleur **{workerName}** par **{newWorkerName}** pour l'appareil **{deviceTag}**?",
  "ChangeWorkerModal-unassignWorker": "Assigner un travailleur",
  "ChangeWorkerModal-unassignWorkerMessage":
    "Êtes-vous sûr de vouloir retirer l'assignation au travailleur **{workerName}** de l'appareil **{deviceTag}**?",
  "ChangeWorkerStatusModal-changeStatusError": "Une erreur s'est produite pendant la tentative de modification du statut",
  "ChangeWorkerStatusModal-descriptionEnroll": "Êtes-vous sûr de vouloir modifier le statut du travailleur **{worker}** à **Inscrit**?",
  "ChangeWorkerStatusModal-descriptionUnenroll":
    "Êtes-vous sûr de vouloir modifier le statut du travailleur **{worker}** à **Désinscrit**?",
  "ChangeWorkerStatusModal-reasonsLabel": "Motif:",
  "ChangeWorkerStatusModal-successMessageEnrolled": "Le statut du travailleur {worker} a été modifié à Inscrit avec succès",
  "ChangeWorkerStatusModal-successMessageUnenrolled": "Le statut du travailleur {worker} a été modifié à Non inscrit avec succès",
  "ChangeWorkerStatusModal-title": "Modifier le statut du travailleur",
  "ControllerSettings-heavyLiftHigh": "Soulèvement lourd élevé",
  "ControllerSettings-heavyLiftLow": "Soulèvement lourd faible",
  "ControllerSettings-heavyLiftMedium": "Soulèvement lourd moyen",
  "ControllerSettings-squatLiftHigh": "Soulèvement accroupi élevé",
  "ControllerSettings-squatLiftMedium": "Soulèvement accroupi moyen",
  "ControllerSettings-squatLiftTransparent": "Soulèvement transparent accroupi",
  "ControllerSettings-universalLiftHigh": "Soulèvement universel élevé",
  "ControllerSettings-universalLiftMedium": "Soulèvement universel moyen",
  "ControllerSettings-universalLiftTransparent": "Soulèvement transparent universel",
  "ControllerSettings-universalLowerHigh": "Soulèvement universel bas élevé",
  "ControllerSettings-universalLowerMedium": "Soulèvement universel moyen",
  "ControllerSettings-universalLowerTransparent": "Soulèvement transparent universel",
  "ControllerSettings-floorAssembly70": "Assemblage de plancher 70",
  "ControllerSettings-floorAssembly90": "Assemblage de plancher 90",
  "ControllerSettings-floorAssembly110": "Assemblage de plancher 110",
  "ControllerSettings-floorAssembly130": "Assemblage de plancher 130",
  "ControllerSettings-floorAssembly150": "Assemblage de plancher 150",
  "ControllerSettings-floorAssembly170": "Assemblage de plancher 170",
  "ControllerSettings-floorAssembly190": "Assemblage de plancher 190",
  "ControllerSettings-floorAssemblyTransparent": "Assemblage transparente de plancher",
  "Common-accept": "Accepter",
  "Common-add": "Ajouter",
  "Common-addToFavorites": "Ajouter aux Favoris",
  "Common-and": "et",
  "Common-area": "Zone",
  "Common-at": "à",
  "Common-back": "Dos",
  "Common-below": "Inférieur",
  "Common-between": "entre",
  "Common-cancel": "Annuler",
  "Common-change": "Modifier",
  "Common-close": "Fermer",
  "Common-closeFormAlertMessage": "Êtes-vous sûr de vouloir annuler vos modifications sans les enregistrer?",
  "Common-closeFormAlertTitle": "Annuler les modifications",
  "Common-confirm": "Confirmer",
  "Common-confirmation": "Veuillez saisir **{confirmationValue}** pour confirmer:",
  "Common-copyErrorCode": "Copier le code d'erreur",
  "Common-create": "Créer",
  "Common-created": "Créé",
  "Common-createOptionsManually": "Manuellement",
  "Common-createOptionsUploadCSV": "Télécharger en CSV",
  "Common-dateFormatCommonMonthDayYear": "MM-dd-yy",
  "Common-dateFormatMonthDay": "MM/dd",
  "Common-dateFormatMonthDayOnly": "MMM dd",
  "Common-dateFormatMonthDayYear": "MMM dd, yyyy",
  "Common-dateFormatMonthYear": "MMM yyyy",
  "Common-dateFormatQuarterYear": "QQQ yyyy",
  "Common-dateFormatTimeOnly": "hh:mm a",
  "Common-dateFormatWithTimezone": "M/d/yyyy h:mm a zz",
  "Common-dateFormatWithTimezoneNoTime": "M/d/yyyy",
  "Common-dateTimeFormatWithoutTimezone": "yyyy-MM-dd'T'H:mm:ss.SSS'Z'",
  "Common-dateTimeFormatWithoutYear": "MMM dd, hh:mm a",
  "Common-dayAbbreviation": "d",
  "Common-deallocateConfirmationValue": "Retirer l'attribution",
  "Common-delete": "Supprimer",
  "Common-deleteConfirmationValue": "Supprimer",
  "Common-description": "Description",
  "Common-discard": "Annuler",
  "Common-draggerText": "Cliquez ou faites glisser le fichier dans cette zone pour le téléverser",
  "Common-duration": "Durée",
  "Common-edit": "Modifier",
  "Common-email": "Courriel",
  "Common-enrolledTooltip": "Inscrit",
  "Common-exempt": "Exempter",
  "Common-exportToCsv": "Exporter en CSV",
  "Common-extension": "Extension",
  "Common-false": "Faux",
  "Common-fieldRequiredMessage": "Le champ Courriel est obligatoire",
  "Common-fieldUrlFormatMessage": "Entrez une URL valide",
  "Common-firstName": "Prénom",
  "Common-gatewayStatusDown": "Hors ligne",
  "Common-gatewayStatusIntermittent": "Intermittent",
  "Common-gatewayStatusNeverSeen": "Jamais consulté",
  "Common-gatewayStatusUp": "En ligne",
  "Common-hourAbbreviation": "h",
  "Common-info": "Information",
  "Common-lastName": "Nom",
  "Common-level": "Niveau",
  "Common-loadingText": "Chargement...",
  "Common-meets": "Conforme",
  "Common-minuteAbbreviation": "m",
  "Common-name": "Nom",
  "Common-next": "Suivant",
  "Common-no": "Non",
  "Common-none": "Aucun",
  "Common-overused": "Surutilisé",
  "Common-remove": "Supprimer",
  "Common-removeConfirmationValue": "Supprimer",
  "Common-removeFromFavorites": "Retirer des favoris",
  "Common-restart": "Redémarrer",
  "Common-role": "Rôle",
  "Common-save": "Enregistrer",
  "Common-search": "Rechercher",
  "Common-secondAbbreviation": "s",
  "Common-select": "Sélectionner",
  "Common-signal": "Signal",
  "Common-source": "Source",
  "Common-status": "Statut",
  "Common-title": "Titre",
  "Common-to": "à",
  "Common-true": "Vrai",
  "Common-tryAgain": "Réessayez",
  "Common-typeHere": "Saisir ici",
  "Common-unassignedLabel": "--",
  "Common-unenrolledTooltip": "Non inscrit",
  "Common-unset": "Non défini",
  "Common-warning": "Avertissement",
  "Common-yes": "Oui",
  "Configuration-configurationPath": "Configuration",
  "Configuration-organizationsPath": "Organisations",
  "ConnectedColumnRender-connected": "Connecté",
  "ConnectedColumnRender-neverSeen": "Jamais consulté",
  "CreateDeviceModal-createDeviceError": "Une erreur s'est produite pendant la tentative de création de l'appareil",
  "CreateDeviceModal-createDeviceSuccess": "Appareil créé avec succès",
  "CreateDeviceModal-deviceTagLabel": "Étiquette de l'appareil",
  "CreateDeviceModal-deviceTagValidatorMessage": "L'étiquette d'appareil ne doit comporter que des lettres majuscules ou des chiffres",
  "CreateDeviceModal-deviceTypeLabel": "Type d'appareil",
  "CreateDeviceModal-duplicateDeviceError":
    "Un appareil non archivé ayant le même numéro de série système ou le même ID MCU existe déjà dans le système",
  "CreateDeviceModal-fetchDeviceTypesError": "Une erreur s'est produite pendant la récupération des types d'appareils disponibles",
  "CreateDeviceModal-mcuIDLabel": "Mcu ID",
  "CreateDeviceModal-mcuIDValidatorMessage": "ID MCU doit être un UUID valide",
  "CreateDeviceModal-modalTitle": "Créer un appareil",
  "CreateDeviceModal-okButtonText": "Créer",
  "CreateDeviceModal-systemSerialNumberLabel": "# de Série du système",
  "CreateDeviceModal-systemSerialNumberValidatorMessage":
    "Le numéro de série du système doit comporter 13 caractères et inclure uniquement des chiffres ou des lettres majuscules",
  "CreateWorkersReportGroupModal-createError":
    "Une erreur s'est produite pendant la tentative de création du groupe de rapports des travailleurs",
  "CreateWorkersReportGroupModal-successMessage": "Le groupe de rapports des travailleurs a été créé avec succès",
  "CreateWorkerReportGroupModal-groupNameLabel": "Nom du Groupe:",
  "CreateWorkerReportGroupModal-nameAlreadyExistsValidation": "Un groupe portant ce nom existe déjà sur le site {siteName}",
  "CreateWorkerReportGroupModal-nameLengthValidator": "Les noms de groupes sont limités à 40 caractères. Veuillez abréger le nom.",
  "CreateWorkerReportGroupModal-noWorkersText": "Aucun travailleur trouvé",
  "CreateWorkerReportGroupModal-selectWorkersLabel": "Sélectionnez les travailleurs à ajouter à ce groupe:",
  "CreateWorkerReportGroupModal-title": "Créer un groupe",
  "CreationFailed-duplicatedValuesError": "Le fichier contient des valeurs dupliquées",
  "CreationFailed-employeeIDAlreadyExistsError":
    "**{firstName} {lastName} ({employeeID})**: L'ID de l'employé existe déjà à **{siteName}**",
  "CreationFailed-emptyRowsError": "Des lignes avec des valeurs vides ont été détectées",
  "CreationFailed-firstText": "Aucun travailleur créé pour **{siteName}** – **{departmentName}**",
  "CreationFailed-missingColumnError":
    "Une ou plusieurs colonnes (Prénom, Nom et/ou ID de l'employé) sont introuvables dans le fichier téléchargé",
  "CreationFailed-nameAlreadyExistsError": "**{firstName} {lastName} ({employeeID})**: Le nom du travailleur existe déjà à **{siteName}**",
  "CreationFailed-nameAlreadyExistsErrorWithoutID":
    "**{firstName} {lastName} ({employeeID})**: Le nom du travailleur existe déjà à **{siteName}**",
  "CreationFailed-noRowsError": "Aucun travailleur n'a été trouvé dans le fichier téléchargé",
  "CreationFailed-notEnoughInfoError": "**{firstName} {lastName} ({employeeID})**: Profil incomplet",
  "CreationFailed-secondText": "Les erreurs suivantes sont survenues:",
  "CreationFailed-subtitle": "La création a échoué",
  "CreationFailedOld-firstText": "Aucun travailleur créé pour **{siteName}** – **{departmentName}**",
  "CreationSuccessful-creationManyText": "{numberOfWorkers} Travailleurs créés pour **{siteName}** – **{departmentName}**",
  "CreationSuccessful-creationOneText": "1 travailleur créé pour **{siteName}** – **{departmentName}**",
  "CreationSuccessful-title": "Création réussie",
  "CSVDeviceAllocation-columns": "Colonnes: numéro de série du système",
  "CSVDeviceAllocation-description":
    "Veuillez télécharger un fichier CSV contenant les numéros de série du système que vous souhaitez attribuer à **{siteName}** – **{departmentName}**.",
  "CSVDeviceAllocation-firstSubtitle":
    "Veuillez télécharger un fichier CSV contenant les numéros de série du système que vous souhaitez attribuer à **{siteName}** – **{departmentName}**.",
  "CSVDeviceAllocation-secondSubtitle": "Colonnes: numéro de série du système",
  "CSVDeviceAllocation-serialNumberColumnName": "Numéro de série du système",
  "CSVWorkerCreation-employeeID": "ID de l'employé",
  "CSVWorkerCreation-firstName": "Prénom",
  "CSVWorkerCreation-firstSubtitle":
    "Veuillez télécharger un fichier CSV des travailleurs que vous souhaitez créer à **{siteName}** – **{departmentName}**.",
  "CSVWorkerCreation-lastName": "Nom",
  "CSVWorkerCreation-secondSubtitle": "Colonnes: Prénom, Nom, ID de l'employé",
  "Dashboard-activeLabel": "Actif ",
  "Dashboard-dashboardPath": "Tableau de bord",
  "Dashboard-deviceStatusSubtitle": "Les 7 derniers jours",
  "Dashboard-deviceStatusTitle": "Statut de l'appareil",
  "Dashboard-fetchDataError": "Une erreur s'est produite pendant la tentative d'obtention des données du tableau de bord",
  "Dashboard-inactiveLabel": "Inactif",
  "Dashboard-outOfServiceLabel": "Hors service",
  "Dashboard-sitesListEndMessage": "Aucun autre site à afficher",
  "Dashboard-totalUsageTitle": "Total des heures d'utilisation",
  "Dashboard-unassignedLabel": "Non attribué",
  "DashboardCardUsageChart-defaultTitle": "Total des heures d'utilisation",
  "DashboardCardUsageChart-workerReportGroupsTitle": "Moyenne d'heures par employé avec un appareil",
  "DashboardCardWrapper-errorMessage": "Une erreur s'est produite pendant la récupération des données",
  "DashboardCardWrapper-errorResultMessage": "Erreur lors de la recherche de données. Veuillez actualiser la page avant de réessayer",
  "DashboardChildrenCardsSection-workerReportGroups":
    "{numberOfGroups, plural, =1 {Groupe de Rapports des Travailleurs} other {Groupes de Rapports des Travailleurs}}",
  "DashboardDevicesListsDrawer-csv": "{status}.csv",
  "DashboardDevicesListsDrawer-deviceTagTitle": "Étiquette de l'appareil",
  "DashboardDevicesListsDrawer-devicesCount": "{devices} Appareils",
  "DashboardDevicesListsDrawer-devicesCountTitleAssigned": "Assigné:",
  "DashboardDevicesListsDrawer-devicesCountTitleAvailable": "Disponible:",
  "DashboardDevicesListsDrawer-devicesCountTitleOutOfService": "Hors service:",
  "DashboardDevicesListsDrawer-fetchDevicesError": "Une erreur s'est produite pendant la tentative d'obtention des données des appareils",
  "DashboardDevicesListsDrawer-titleAssigned": "Appareils assignés",
  "DashboardDevicesListsDrawer-titleAvailable": "Appareils disponibles",
  "DashboardDevicesListsDrawer-titleOutOfService": "Appareils hors service:",
  "DashboardDevicesListsDrawer-workerNameTitle": "Nom du travailleur",
  "DashboardItem-chartsClickText": "En cliquant sur les graphiques, vous serez redirigé vers Analytiques sur le site.",
  "DashboardItem-errorResultMessage": "Erreur lors de la récupération des données du site",
  "DashboardSiteDetails-fetchDataError": "Une erreur s'est produite pendant la tentative d'obtention des données du site",
  "DashboardTabContent-activeWorkers": "Travailleurs actifs",
  "DashboardTabContent-demoReport": "Rapport de démo",
  "DashboardTabContent-departments": "{numberOfDepartments, plural, =0 {Départements} =1 {Département} other {Départements}}",
  "DashboardTabContent-fetchWorkerReportGroupsDataError":
    "Une erreur s'est produite pendant la tentative d'obtention des données des groupes de rapports des travailleurs",
  "DashboardTabContent-inactiveWorkers": "Travailleurs inactifs",
  "DashboardTabContent-sites": "{numberOfSites, plural, =0 {Sites} =1 {Site} other {Sites}}",
  "DashboardTabContent-siteSummaryReport": "Rapport Sommaire du Site",
  "DashboardTabContent-summaryReport": "Rapport sommaire",
  "DashboardDailyUsageBreakdownSection-dailyTotalRowTitle": "Total quotidien",
  "DashboardDailyUsageBreakdownSection-downloadCSV": "Télécharger en CSV",
  "DashboardDailyUsageBreakdownSection-endDateLabel": "Date de fin hebdomadaire",
  "DashboardDailyUsageBreakdownSection-title": "Répartition quotidienne de l'utilisation",
  "DashboardDailyUsageBreakdownSection-weeklyTotal": "Total hebdomadaire",
  "DashboardDailyUsageBreakdownSection-worker": "Travailleur",
  "DashboardWorkersListsDrawer-csv": "{status}.csv",
  "DashboardWorkersListsDrawer-fetchWorkersError":
    "Une erreur s'est produite pendant la tentative d'obtention des données des travailleurs",
  "DashboardWorkersListsDrawer-titleActive": "Travailleurs actifs",
  "DashboardWorkersListsDrawer-titleInactive": "Travailleurs inactifs",
  "DashboardWorkersListsDrawer-titleWaitlist": "Liste d'attente des travailleurs",
  "DashboardWorkersListsDrawer-workerNameTitle": "Nom du travailleur",
  "DashboardWorkersListsDrawer-workersCount": "{workers} Travailleurs",
  "DashboardWorkersListsDrawer-workersCountTitleActive": "Actif:",
  "DashboardWorkersListsDrawer-workersCountTitleInactive": "Inactif:",
  "DashboardWorkersListsDrawer-workersCountTitleWaitlist": "Liste d'attente:",
  "DashboardWorkersSection-asPDF": "En PDF",
  "DashboardWorkersSection-asXLSX": "En XLSX",
  "DashboardWorkersTable-7DaysColumnTooltip": "Les données du {dateFrom} au {dateTo} (ne comprennent pas les données d'aujourd'hui)",
  "DashboardWorkersTable-7HoursColumnTargetTooltip":
    "Les données d'utilisation cible sont basées sur la configuration du département et reposent sur une semaine ouvrable de 5 jours.",
  "DashboardWorkersTable-7HoursColumnTooltip":
    "Les données sur les heures d'utilisation du {dateFrom} au {dateTo} (ne comprennent pas les données d'aujourd'hui)",
  "DashboardWorkersTable-activeWorkersTitle": "Liste des travailleurs actifs",
  "DashboardWorkersTable-assignedDeviceColumnTitle": "Étiquette de l'appareil",
  "DashboardWorkersTable-dataUploadedTodayColumnTitle": "Données téléchargées aujourd'hui",
  "DashboardWorkersTable-daysActiveLast7DaysColumnTitle": "Jours actifs au cours des 7 derniers jours",
  "DashboardWorkersTable-daysSinceLastUsage": "{days} jours",
  "DashboardWorkersTable-daysSinceLastUsageColumnTitle": "Jours depuis la dernière utilisation",
  "DashboardWorkersTable-disabledDeviceColumnValue": "L'appareil ne peut pas être modifié pour des travailleurs non inscrits",
  "DashboardWorkersTable-downloadData": "Télécharger les données",
  "DashboardWorkersTable-employeeIDTitle": "ID de l'employé",
  "DashboardWorkersTable-errorFetchingWorkers": "Erreur lors de la récupération des travailleurs",
  "DashboardWorkersTable-hoursLast7DaysColumnTitle": "Heures des 7 derniers jours",
  "DashboardWorkersTable-inactiveWorkersTitle": "Liste des travailleurs inactifs",
  "DashboardWorkersTable-nameColumnTitle": "Nom",
  "DashboardWorkersTable-relativeToTargetColumnTitle": "Par rapport à la cible",
  "DashboardWorkersTable-statusColumnTitle": "Statut",
  "DashboardWorkersTable-targetDeviceUtilization": "Utilisation d'appareil cible",
  "DashboardWorkersTable-unassignedDeviceLabel": "--",
  "DashboardWorkersTable-weeksSinceFirstUseColumnTitle": "Jours depuis la première utilisation",
  "DashboardWorkersTable-workerActiveToday": "Travailleur actif aujourd'hui",
  "DashboardWorkersTable-WorkersActivityReport": "RapportDactiviteDesTravailleurs",
  "DashboardWorkersTable-workerColumnName": "Travailleur",
  "DashboardWorkersMetric-bottomFive": "Bas 5",
  "DashboardWorkersMetric-departmentAverage": "Moyenne du département",
  "DashboardWorkersMetric-leaderboardReport": "Rapport de classement",
  "DashboardWorkersMetric-poundsUnit": "lb",
  "DashboardWorkersMetric-kilogramsUnit": "kg",
  "DashboardWorkersMetric-safeLiftScore": "Score Safelift",
  "DashboardWorkersMetric-topWorkers": "{qty} Meilleurs",
  "DashboardWorkersMetric-bottomWorkers": "Bas {qty}",
  "DashboardWorkersMetric-weightOffloaded": "Poids déchargé",
  "DashboardWorkersMetric-workerMetrics": "Données du travailleur",
  "DatePicker-dateFilterCurrentMoth": "Mois en cours",
  "DatePicker-dateFilterLastDays": "Les {daysDisplayed} derniers jours",
  "DatePicker-dateFilterToday": "Aujourd'hui",
  "DatePicker-dateFilterYesterday": "Hier",
  "DateRange-dateFilterInputRequiredRule": "Vous devez sélectionner une période",
  "DemoReportDrawer-contentMessage":
    "Le rapport de démo couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport.",
  "DemoReportDrawer-drawerTitle": "Rapport de démo",
  "DemoReportDrawer-fileName": "Rapport de démo",
  "DepartmentDevicesTable-daysSinceLastUsedColumnTitle": "Consulté récemment",
  "DepartmentDevicesTable-deviceTagColumnTitle": "Étiquette de l'appareil",
  "DepartmentDevicesTable-disabledWorkerColumnValue": "Le travailleur ne peut pas être modifié pour des appareils hors service",
  "DepartmentDevicesTable-inServiceTooltip": "En service",
  "DepartmentDevicesTable-outOfServiceTooltip": "Hors service",
  "DepartmentDevicesTable-statusColumnTitle": "Statut",
  "DepartmentDevicesTable-workerColumnTitle": "Travailleur",
  "DepartmentDetail-allocateCSVButtonText": "Télécharger en CSV",
  "DepartmentDetail-allocateManualButtonText": "Attribuer",
  "DepartmentDetail-deleteAlertMessage": "Êtes-vous sûr de vouloir supprimer ce département?",
  "DepartmentDetail-deleteAlertTitle": "Supprimer le département",
  "DepartmentDetail-deleteError": "Le département n'a pas pu être supprimé",
  "DepartmentDetail-devices": "Appareils",
  "DepartmentDetail-differentSiteError": "Ce département appartient à un autre site",
  "DepartmentDetail-fetchDataError": "Une erreur s'est produite pendant la tentative d'obtention des données du département",
  "DepartmentDetail-notFoundError": "Département introuvable",
  "DepartmentDetail-updateError": "Une erreur s'est produite pendant la tentative de mise à jour des données du département",
  "DepartmentDetail-workerCreationCSVButtonText": "Télécharger en CSV",
  "DepartmentDetail-workerCreationManualButtonText": "Ajouter",
  "DepartmentDetail-workers": "Travailleurs",
  "DepartmentForm-createError": "Une erreur s'est produite pendant le tentative de création du département",
  "DepartmentForm-nameInputLabel": "Nom",
  "DepartmentGatewaysDetail-deallocate": "Retirer l'attribution",
  "DepartmentGatewaysDetail-deallocateAlertMessage":
    "Êtes-vous sûr de vouloir retirer l'attribution de la passerelle **{gatewaySerialNumber}** du département **{departmentName}**?",
  "DepartmentGatewaysDetail-deallocateAlertTitle": "Retirer l'attribution de la passerelle",
  "DepartmentGatewaysDetail-deallocateError": "La passerelle n'a pas pu être retirée de l'attribution",
  "DepartmentGatewaysDetail-fetchGatewaysError":
    "Une erreur s'est produite lors de la tentative de récupération des données des passerelles",
  "DepartmentGatewaysDetail-gatewayItemFirmware": "Micrologiciel de la passerelle:",
  "DepartmentGatewaysDetail-gatewayItemLastTimeConnected": "Dernière connexion:",
  "DepartmentGatewaysDetail-gatewayItemStatus": "Statut:",
  "DepartmentGatewaysDetail-gatewayStatusDefault": "Inconnu",
  "DepartmentGatewaysDetail-title": "Passerelle",
  "DepartmentModal-createError": "Une erreur s'est produite pendant le tentative de création du département",
  "DepartmentModal-createTitle": "Créer un département",
  "DepartmentModal-deleteButtonText": "Supprimer le département",
  "DepartmentModal-deleteError": "Le département n'a pas pu être supprimé",
  "DepartmentModal-editTitle": "Modifier le département",
  "DepartmentModal-updateError": "Une erreur s'est produite pendant la tentative de mise à jour du département",
  "DepartmentsTable-devicesColumnTitle": "# d'appareils",
  "DepartmentsTable-nameColumnTitle": "Nom du département",
  "DepartmentWorkersTable-assignedDeviceColumnTitle": "Appareil assigné",
  "DepartmentWorkersTable-daysSinceFirstUsageColumnTitle": "Jours depuis la première utilisation",
  "DepartmentWorkersTable-daysSinceLastUsageColumnTitle": "Jours depuis la dernière utilisation",
  "DepartmentWorkersTable-deviceColumnTitle": "Appareil",
  "DepartmentWorkersTable-disabledDeviceColumnValue": "L'appareil ne peut pas être modifié pour des travailleurs non inscrits",
  "DepartmentWorkersTable-employeeIDColumnTitle": "ID de l'employé",
  "DepartmentWorkersTable-enrolledColumnTitle": "Inscrit",
  "DepartmentWorkersTable-enrolledFalseLabel": "Non",
  "DepartmentWorkersTable-enrolledTrueLabel": "Oui",
  "DepartmentWorkersTable-naLabel": "N/A",
  "DepartmentWorkersTable-nameColumnTitle": "Nom",
  "DepartmentWorkersTable-report": "Rapport",
  "DepartmentWorkersTable-reportColumnTitle": "Rapport",
  "DepartmentWorkersTable-statusColumnTitle": "Statut",
  "DepartmentWorkersTable-totalHoursColumnTitle": "Total des heures",
  "DepartmentWorkersTable-totalLiftsColumnTitle": "Total des soulèvements",
  "DepartmentWorkersTable-unassignedDeviceLabel": "--",
  "DepartmentWorkersTable-idColumnTitle": "Id",
  "Device-formattedConfigFirmwareVersionUnknown": "Inconnu",
  "DeviceAllocation-allocateButtonText": "Attribuer",
  "DeviceAllocation-allocateDeviceToDepartmentError": "Les appareils n'ont pas pu être attribués",
  "DeviceAllocation-allocationManySuccessfulText": "{numberOfDevices} Appareils attribués à **{siteName} – {departmentName}**",
  "DeviceAllocation-allocationOneSuccessfulText": "1 appareil attribué à **{siteName} – {departmentName}**",
  "DeviceAllocation-allocationSuccessfulTitle": "Attribution réussie",
  "DeviceAllocation-contentText":
    "Veuillez sélectionner les numéros de série que vous souhaitez attribuer à **{siteName}** – **{departmentName}**",
  "DeviceAllocation-noDevicesText": "Aucun appareil trouvé",
  "DeviceAllocation-title": "Attribution/Appareil",
  "DeviceAllocationModal-allocateDeviceToDepartmentError": "Les appareils n'ont pas pu être attribués",
  "DeviceAllocationModal-CVSDeviceAllocationButtonText": "Attribuer",
  "DeviceAllocationModal-CSVDeviceAllocationSuccessfulMessage": "Le fichier CSV a été téléchargé avec succès",
  "DeviceAllocationModal-errorMessage": "Une erreur s'est produite pendant la tentative d'obtention des appareils disponibles",
  "DeviceAllocationModal-manualDeviceAllocationOkButtonText": "Attribuer",
  "DeviceAllocationModal-manualDeviceAllocationSuccessfulMessage": "Appareils attribué à {department} avec succès.",
  "DeviceAllocationModal-title": "Attribuer l'appareil",
  "DeviceDetail-assignedWorker": "Travailleur:",
  "DeviceDetail-available": "Disponible",
  "DeviceDetail-dateFirstUsed": "Première utilisation:",
  "DeviceDetail-dateLastConnected": "Dernière connexion:",
  "DeviceDetail-dateLastUsed": "Dernière utilisation:",
  "DeviceDetail-deallocate": "Retirer l'attribution",
  "DeviceDetail-deallocateAlertMessage": "Supprimer **{deviceTag}** (n° de série **{serialNumber}**) de **{siteName} – {departmentName}**?",
  "DeviceDetail-deallocateAlertTitle": "Retirer l'attribution de l'appareil",
  "DeviceDetail-deallocateDevice": "Retirer l'attribution de l'appareil ",
  "DeviceDetail-deallocateDeviceMessage":
    "Êtes-vous sûr de vouloir supprimer **{deviceTag}** (# de série du système: {systemSerialNumber}) du département {departmentName}?",
  "DeviceDetail-deallocateError": "Une erreur s'est produite pendant la tentative de désattribution de l'appareil",
  "DeviceDetail-deallocateSuccessMessage":
    "L'appareil {deviceTag} a été retiré de l'attribution du département {departmentName} avec succès.",
  "DeviceDetail-department": "Département:",
  "DeviceDetail-deviceProfile": "Profil de l'appareil",
  "DeviceDetail-deviceStatistics": "Statistiques/Appareil",
  "DeviceDetail-drawerTitle": "Détails de l'appareil",
  "DeviceDetail-firmware": "Micrologiciel:",
  "DeviceDetail-inService": "En service",
  "DeviceDetail-none": "Aucun",
  "DeviceDetail-organization": "Organisation:",
  "DeviceDetail-outOfService": "Hors service",
  "DeviceDetail-rev": "Révision:",
  "DeviceDetail-site": "Site:",
  "DeviceDetail-status": "Statut:",
  "DeviceDetail-systemSerialNumber": "# de série du système:",
  "DeviceDetail-tag": "Étiquette de l'appareil:",
  "DeviceDetail-title": "Détails de l'appareil",
  "DeviceDetail-totalHours": "Total des heures:",
  "DeviceDetail-totalLifts": "Total des soulèvements:",
  "DeviceDetail-updateError": "Une erreur s'est produite pendant la tentative de mise à jour des données de l'appareil",
  "DeviceDetail-workerNoLongerHasDevice": "Le travailleur n'a plus d'appareil",
  "DeviceDetailOld-assignedWorker": "Travailleur assigné:",
  "DeviceDetailOld-dateLastConnected": "Dernière connexion:",
  "DeviceDetailOld-deviceStatistics": "Statistiques de l'appareil",
  "DeviceEditModal-archived": "Archivé:",
  "DeviceEditModal-archiveDeviceSuccess": "L'appareil a été archivé avec succès",
  "DeviceEditModal-archivedTrueMessage":
    "Modifier l'appareil à **Archivé: Vrai ** rendra l'appareil visible uniquement à partir de la section Gestion des appareils",
  "DeviceEditModal-inService": "En service",
  "DeviceEditModal-none": "Aucun",
  "DeviceEditModal-outOfService": "Hors service",
  "DeviceEditModal-status": "Statut:",
  "DeviceEditModal-systemSerialNumber": "# de série du système:",
  "DeviceEditModal-title": "Modifier le profil de l'appareil",
  "DeviceEditModal-updateDeviceSuccess": "Les détails de l'appareil ont été mis à jour avec succès",
  "DeviceEditModal-updateError": "Une erreur s'est produite pendant la tentative de mise à jour des données de l'appareil",
  "DeviceEditModal-worker": "Travailleur:",
  "DeviceManagementDevices-bulkUpdateDevices": "Mise à jour groupée des appareils",
  "DeviceManagementDevices-createDeviceButtonText": "Créer un appareil",
  "DeviceManagementDevices-deviceConfig": "Appareils",
  "DeviceManagementDevices-gateways": "Passerelles",
  "DeviceManagementDevices-searchDevices": "Effectuer une recherche pour visualiser les appareils",
  "DeviceManagementDevices-searchGateways": "Effectuez une recherche pour visualiser les passerelles",
  "DeviceManagementDevices-subheaderTitle": "Gestion de l'appareil",
  "DeviceManagementDevices-title": "Gestion de l'appareil",
  "DeviceManagementDevicesTable-activeConfig": "Configuration active",
  "DeviceManagementDevicesTable-archivedColumnTitle": "Archivé",
  "DeviceManagementDevicesTable-archivedDate": "Date d'archivage:",
  "DeviceManagementDevicesTable-button1ColumnTitle": "Bouton 1",
  "DeviceManagementDevicesTable-button2ColumnTitle": "Bouton 2",
  "DeviceManagementDevicesTable-button3ColumnTitle": "Bouton 3",
  "DeviceManagementDevicesTable-firmwareRevColumnTitle": "Rév. du micrologiciel",
  "DeviceManagementDevicesTable-firmwareRevColumnTooltip":
    "Afficher uniquement les versions du firmware présentes dans l'Organisation/Site/Département actuel",
  "DeviceManagementDevicesTable-firstDateUsed": "Première date d'utilisation:",
  "DeviceManagementDevicesTable-hardwareRevColumnTitle": "Rév. du matériel",
  "DeviceManagementDevicesTable-hardwareRevColumnTooltip":
    "Afficher uniquement les types d'appareils présents dans l'Organisation/Site/Département actuel",
  "DeviceManagementDevicesTable-location": "Emplacement:",
  "DeviceManagementDevicesTable-pendingUpdate": "Mise à jour en attente",
  "DeviceManagementDevicesTable-updateConfig": "Mise à jour de la configuration",
  "DeviceManagementDevicesTable-updateConfigColumnTitle": "Mise à jour de la configuration",
  "DeviceManagementSearchBar-deviceTag": "Étiquette de l'appareil:",
  "DeviceManagementSearchBar-deviceTagPlaceholder": "Recherche par étiquette",
  "DeviceManagementSearchBar-filter": "Filtre:",
  "DeviceManagementSearchBar-loadingOrganizations": "Chargement des organisations...",
  "DeviceManagementSearchBar-searchButtonTooltip": "Veuillez utiliser au moins un des filtres avant d'effectuer une recherche",
  "DeviceManagementSearchBar-selectOrganization": "Sélectionnez une organisation...",
  "DeviceManagementSearchBar-serialNumber": "Numéro de série:",
  "DeviceManagementSearchBar-serialNumberPlaceholder": "Recherche par numéro de série",
  "DeviceManagementSearchBar-unallocated": "Non attribué",
  "DeviceManagementStats-connected": "Connecté: {connectedDevices}",
  "DeviceManagementStats-connectedColumnTitle": "Consulté récemment",
  "DeviceManagementStats-csvDevices": "appareils - {entityName}.csv",
  "DeviceManagementStats-csvGateways": "passerelles - {entityName}.csv",
  "DeviceManagementStats-currentSoftwareColumnTitle": "Logiciel actuel",
  "DeviceManagementStats-deviceConnectionStatusLabel": "Statut de connexion/Appareil:",
  "DeviceManagementStats-disconnected": "Déconnecté: {disconnectedDevices}",
  "DeviceManagementStats-down": "Hors service: {downGateways}",
  "DeviceManagementStats-downColumn": "Hors service",
  "DeviceManagementStats-failed": "Échec",
  "DeviceManagementStats-firmwareColumnTitle": "Micrologiciel",
  "DeviceManagementStats-forceUpdatePending": "Mise à jour forcée en attente",
  "DeviceManagementStats-includeArchivedLabel": "Inclure les archives",
  "DeviceManagementStats-intermittent": "Intermittent: {intermittentGateways}",
  "DeviceManagementStats-intermittentColumn": "Intermittent",
  "DeviceManagementStats-latestSoftware": "Dernier logiciel: {latestVersionGateway}",
  "DeviceManagementStats-matchTarget": "Le logiciel correspond à la cible",
  "DeviceManagementStats-neverSeen": "Jamais consulté: {neverSeenDevices}",
  "DeviceManagementStats-neverSeenColumn": "Jamais Consulté",
  "DeviceManagementStats-pendingConfigurationUpdatesLabel": "Mises à jour de la configuration en attente:",
  "DeviceManagementStats-scheduleUpdatePending": "Mise à jour de l'horaire en attente",
  "DeviceManagementStats-serialNumberColumnTitle": "# de série de la passerelle",
  "DeviceManagementStats-systemSerialNumberColumnTitle": "# de Série du système",
  "DeviceManagementStats-totalDevicesLabel": "Total des appareils:",
  "DeviceManagementStats-totalGatewaysLabel": "Total des passerelles:",
  "DeviceManagementStats-up": "Haut: {upGateways}",
  "DeviceManagementStats-upColumn": "Haut",
  "DeviceManagementStats-updateStatusColumnTitle": "Statut de la mise à jour",
  "DeviceNoWorker-assignErrorMessage": "Une erreur s'est produite pendant la tentative d'attribution de l'appareil au travailleur",
  "DeviceNoWorker-assignNewWorker": "Assigner l'appareil à un nouveau travailleur",
  "DeviceNoWorker-assignNewWorker-placeholder": "Sélectionnez un travailleur",
  "DeviceNoWorker-assignSuccessMessage": "L'appareil {deviceTag} a été assigné à {workerName} avec succès",
  "DeviceNoWorker-changeStatusErrorMessage": "Une erreur s'est produite pendant la tentative de mise à jour du statut de l'appareil.",
  "DeviceNoWorker-changeStatusOutOfService": "Modifier le statut à 'Hors service'",
  "DeviceNoWorker-changeStatusSuccessMessage": "Le statut de l'appareil {deviceTag} a été modifié à Hors service avec succès",
  "DeviceNoWorker-firstSubtitle": "**{name}** n'a plus de travailleur assigné.",
  "DeviceNoWorker-leaveUnassigned": "Laissez Non attribué",
  "DeviceNoWorker-leaveUnassignedSuccessMessage": "Le statut de l'appareil {deviceTag} a été modifié à Non attribué avec succès",
  "DeviceNoWorker-secondSubtitle": "Que voudriez-vous faire avec cet appareil?",
  "DeviceNoWorker-title": "Statut de l'appareil",
  "DeviceNoWorker-worker": "Travailleur:",
  "DevicesTabContent-allocateDeviceButton": "Attribuer l'appareil",
  "DevicesTabContent-allTagText": "Tous",
  "DevicesTabContent-assignedTagText": "Assigné",
  "DevicesTabContent-availableTagText": "Disponible",
  "DevicesTabContent-connected": "Connecté",
  "DevicesTabContent-connectedTagText": "Connecté",
  "DevicesTabContent-csv": "{tag} Appareils.csv",
  "DevicesTabContent-deviceTag": "Étiquette de l'appareil",
  "DevicesTabContent-deviceUsageReassignmentButton": "Réassignation de l'utilisation de l'appareil",
  "DevicesTabContent-disconnected": "Déconnecté",
  "DevicesTabContent-disconnectedTagText": "Déconnecté",
  "DevicesTabContent-inService": "En service",
  "DevicesTabContent-lastSeen": "Consulté récemment",
  "DevicesTabContent-neverSeen": "Jamais consulté",
  "DevicesTabContent-outOfService": "Hors service",
  "DevicesTabContent-outOfServiceTagText": "Hors service",
  "DevicesTabContent-searchButtonText": "Recherche par étiquette",
  "DevicesTabContent-status": "Statut",
  "DevicesTabContent-worker": "Travailleur",
  "DeviceLiveCheckoutFilters-allTagText": "Tous",
  "DeviceLiveCheckoutFilters-bottomDisclaimer": "*N'affiche que les appareils en service",
  "DeviceLiveCheckoutFilters-checkedOutRecentlyDescription":
    "Débranché au cours des 12 dernières heures et/ou données enregistrées au cours des 12 dernières heures",
  "DeviceLiveCheckoutFilters-checkedOutRecentlyTagText": "Vérifié récemment",
  "DeviceLiveCheckoutFilters-idleDescription": "Débranché depuis plus de 12 heures",
  "DeviceLiveCheckoutFilters-idleTagText": "Inactif",
  "DeviceLiveCheckoutFilters-notCheckedOutDescription":
    "Actuellement connecté et aucune donnée n'a été enregistrée au cours des 12 dernières heures",
  "DeviceLiveCheckoutFilters-notCheckedOutTagText": "Non vérifié",
  "DeviceLiveCheckoutFilters-searchButtonText": "Recherche par travailleur ou étiquette",
  "DeviceLiveCheckoutFilters-seeOnlyAssigned": "Ne voir que les appareils attribués",
  "DeviceLiveCheckoutTab-checkedOutColumn": "Vérifié",
  "DeviceLiveCheckoutTab-checkedOutRecentlyTagText": "Vérifié récemment",
  "DeviceLiveCheckoutTab-connected": "Connecté",
  "DeviceLiveCheckoutTab-csv": "{tag} Appareils.csv",
  "DeviceLiveCheckoutTab-dataUploadedColumn": "Données téléchargées aujourd'hui",
  "DeviceLiveCheckoutTab-deviceColumn": "Étiquette de l'appareil",
  "DeviceLiveCheckoutTab-idleTagText": "Inactif",
  "DeviceLiveCheckoutTab-lastSeenColumn": "Consulté récemment",
  "DeviceLiveCheckoutTab-neverSeen": "Jamais consulté",
  "DeviceLiveCheckoutTab-notCheckedOutTagText": "Non vérifié",
  "DeviceLiveCheckoutTab-workerColumn": "Travailleur",
  "DeviceUsageReassignment-assignedDataSwitch": "Voir également les données attribuées",
  "DeviceUsageReassignment-deviceColumn": "Appareils",
  "DeviceUsageReassignment-endDate": "Date de fin hebdomadaire",
  "DeviceUsageReassignment-errorFetchingWorkers": "Erreur lors de la récupération des travailleurs",
  "DeviceUsageReassignment-errorMessage": "Une erreur s'est produite pendant la tentative d'obtention des données des appareils",
  "DeviceUsageReassignment-title": "Réassignation de l'utilisation de l'appareil",
  "DeviceUsageReassignment-worker": "Travailleur:",
  "DeviceUsageReassignment-location": "Emplacement",
  "DeviceUsageReassignment-unallocated": "Non attribué",
  "DeviceUsageReassignment-totalUnallocated": "Total non attribué",
  "DeviceUsageReassignment-loadingOrganizations": "Chargement des organisations...",
  "DeviceUsageReassignment-selectOrganization": "Sélectionnez une organisation...",
  "LiveCheckedOutTabContent-allTagText": "Tous",
  "LiveCheckedOutTabContent-allocateDeviceButton": "Attribuer l'appareil",
  "LiveCheckedOutTabContent-assignedTagText": "Assigné",
  "LiveCheckedOutTabContent-availableTagText": "Disponible",
  "LiveCheckedOutTabContent-connectedTagText": "Connecté",
  "LiveCheckedOutTabContent-disconnectedTagText": "Déconnecté",
  "LiveCheckedOutTabContent-outOfServiceTagText": "Hors service",
  "LiveCheckedOutTabContent-searchButtonText": "Recherche par étiquette",
  "DevicesTable-assignedWorkerColumnTitle": "Travailleur assigné",
  "DevicesTable-assignedWorkerFilter-assigned": "Assigné",
  "DevicesTable-assignedWorkerFilter-available": "Disponible",
  "DevicesTable-assignedWorkerFilter-outOfService": "Hors service",
  "DevicesTable-connectedColumnTitle": "Consulté récemment",
  "DevicesTable-connectedLabel": "Connecté",
  "DevicesTable-daysSinceFirstUsedColumnTitle": "Première utilisation",
  "DevicesTable-daysSinceLastUsedColumnTitle": "Dernière utilisation",
  "DevicesTable-departmentColumnTitle": "Département",
  "DevicesTable-deviceTagColumnTitle": "Étiquette de l'appareil",
  "DevicesTable-disconnectedLabel": "Déconnecté",
  "DevicesTable-firmwareColumnTitle": "Micrologiciel",
  "DevicesTable-neverSeenLabel": "Jamais consulté",
  "DevicesTable-organizationColumnTitle": "Organisation",
  "DevicesTable-revColumnTitle": "Révision",
  "DevicesTable-siteColumnTitle": "Site",
  "DevicesTable-systemSerialNumberColumnTitle": "# de Série du système",
  "DevicesTable-totalHoursColumnTitle": "Total des heures",
  "DevicesTable-totalLiftsColumnTitle": "Total des soulèvements",
  "DeviceStatusChart-activeDevices": "Actif ",
  "DeviceStatusChart-allocatedDevices": "Attribué",
  "DeviceStatusChart-inactiveDevices": "Inactif",
  "DeviceStatusChart-noDevices": "Aucun appareil à afficher",
  "DeviceStatusChart-outOfServiceDevices": "Hors service",
  "DeviceStatusChart-unassignedDevices": "Non attribué",
  "DeviceStatusChartOld-activeDevices": "Appareils actifs",
  "DeviceStatusChartOld-allocatedDevices": "Appareils attribués",
  "DeviceStatusChartOld-inactiveDevices": "Appareils inactifs",
  "DeviceStatusChartOld-noDevices": "Aucun appareil à afficher",
  "DeviceStatusChartOld-outOfServiceDevices": "Appareils hors service:",
  "DeviceStatusChartOld-unassignedDevices": "Appareils non attribués",
  "DeviceManagementDeviceDetail-archivedAt": "Archivé vers:",
  "DeviceManagementDeviceDetail-assignedWorker": "Travailleur assigné:",
  "DeviceManagementDeviceDetail-button1": "Bouton 1:",
  "DeviceManagementDeviceDetail-button2": "Bouton 2:",
  "DeviceManagementDeviceDetail-button3": "Bouton 3:",
  "DeviceManagementDeviceDetail-configurationTitle": "Configuration",
  "DeviceManagementDeviceDetail-diagnosticsPath": "Gestion de l'appareil",
  "DeviceManagementDeviceDetail-ConnectedStatus-connected": "Connecté",
  "DeviceManagementDeviceDetail-ConnectedStatus-disconnected": "Déconnecté",
  "DeviceManagementDeviceDetail-ConnectedStatus-neverSeen": "Jamais consulté",
  "DeviceManagementDeviceDetail-connectionStatus": "Statut de connexion:",
  "DeviceManagementDeviceDetail-customerInfoTitle": "Informations sur le client",
  "DeviceManagementDeviceDetail-dateFirstUsed": "Première utilisation:",
  "DeviceManagementDeviceDetail-dateLastConnected": "Dernière connexion:",
  "DeviceManagementDeviceDetail-dateLastUsed": "Dernière utilisation:",
  "DeviceManagementDeviceDetail-department": "Département:",
  "DeviceManagementDeviceDetail-deviceDetailsTitle": "Détails",
  "DeviceManagementDeviceDetail-deviceStatisticsTitle": "Statistiques",
  "DeviceManagementDeviceDetail-deviceTag": "Étiquette de l'appareil:",
  "DeviceManagementDeviceDetail-firmwareRev": "Rév. du micrologiciel:",
  "DeviceManagementDeviceDetail-hardwareRev": "Rév. du matériel:",
  "DeviceManagementDeviceDetail-inService": "En service",
  "DeviceManagementDeviceDetail-mcuId": "MCU ID:",
  "DeviceManagementDeviceDetail-organization": "Organisation:",
  "DeviceManagementDeviceDetail-outOfService": "Hors service",
  "DeviceManagementDeviceDetail-pendingUpdateTooltip": "Mise à jour en attente: Affichage des détails de configuration en attente.",
  "DeviceManagementDeviceDetail-revision": "Révision:",
  "DeviceManagementDeviceDetail-sessions": "Sessions",
  "DeviceManagementDeviceDetail-site": "Site:",
  "DeviceManagementDeviceDetail-status": "Statut:",
  "DeviceManagementDeviceDetail-systemSerialNumber": "# de série du système:",
  "DeviceManagementDeviceDetail-title": "Appareil {systemSerialNumber}",
  "DeviceManagementDeviceDetail-totalHours": "Total des heures:",
  "DeviceManagementDeviceDetail-totalLifts": "Total des soulèvements:",
  "DeviceManagementDeviceDetail-updatePending": "Mise à jour en attente:",
  "DeviceManagementDeviceDetail-workerErrorMessage":
    "Une erreur s'est produite pendant la tentative de chargement des données du travailleur",
  "DownloadAllData-columnNameActiveWorkers": "Travailleurs actifs",
  "DownloadAllData-columnNameActiveWorkersPercentage": "Pourcentage des travailleurs actifs",
  "DownloadAllData-columnNameAssignedWorkers": "Travailleurs assignés",
  "DownloadAllData-columnNameEndDate": "Date de fin",
  "DownloadAllData-columnNameEndTime": "Heure de fin",
  "DownloadAllData-columnNameEnrolledWorkers": "Travailleurs inscrits",
  "DownloadAllData-columnNameExcessiveForwardLifts": "Soulèvements excessifs vers l'avant",
  "DownloadAllData-columnNameInactiveWorkers": "Travailleurs inactifs",
  "DownloadAllData-columnNameInactiveWorkersPercentage": "Pourcentage des travailleurs inactifs",
  "DownloadAllData-columnNameProlongedBendLifts": "Soulèvements prolongés en flexion",
  "DownloadAllData-columnNameResourceId": "ID de ressource",
  "DownloadAllData-columnNameResourceName": "Nom de ressource",
  "DownloadAllData-columnNameResourceType": "Type de ressource",
  "DownloadAllData-columnNameRiskyLifts": "Soulèvements à risques",
  "DownloadAllData-columnNameRiskyLiftsPercentage": "Pourcentage de soulèvements à risques",
  "DownloadAllData-columnNameSafeLifts": "Soulèvements sécuritaires",
  "DownloadAllData-columnNameSafeLiftsPercentage": "Pourcentage de soulèvements sécuritaires",
  "DownloadAllData-columnNameSideBendLifts": "Soulèvements en flexion latérale",
  "DownloadAllData-columnNameStartDate": "Date de début",
  "DownloadAllData-columnNameStartTime": "Heure de début",
  "DownloadAllData-columnNameTimeZone": "Fuseau horaire",
  "DownloadAllData-columnNameTotalLifts": "Total des soulèvements",
  "DownloadAllData-columnNameTwistedLifts": "Soulèvements en torsion",
  "DownloadAllData-columnNameHoursUsed": "Heures utilisées",
  "DownloadAllData-columnNameWeightOffloaded": "Poids déchargé",
  "DownloadAllData-dateRange": "Période",
  "DownloadAllData-department": "Département",
  "DownloadAllData-download": "Télécharger",
  "DownloadAllData-interval": "Intervalle",
  "DownloadAllData-organization": "Organisation",
  "DownloadAllData-reportName": "Rapport complet des données",
  "DownloadAllData-scope": "Champ",
  "DownloadAllData-site": "Site",
  "EditableDevice-fetchDevicesError": "Une erreur s'est produite pendant la tentative d'obtention des appareils disponibles",
  "EditableWorker-fetchWorkersError": "Une erreur s'est produite pendant la tentative de recherche des travailleurs disponibles",
  "EditableWorker-none": "Aucun",
  "EditCustomPermissionsModal-admin": "Admin",
  "EditCustomPermissionsModal-errorOrganizationMessage":
    "Une erreur s'est produite pendant la tentative d'obtention de l'organisation de l'utilisateur",
  "EditCustomPermissionsModal-infoMessage": "Toutes les lignes doivent comporter une sélection afin de sauvegarder la configuration.",
  "EditCustomPermissionsModal-manager": "Directeur",
  "EditCustomPermissionsModal-none": "Aucun",
  "EditCustomPermissionsModal-readonly": "En lecture seulement",
  "EditCustomPermissionsModal-subtitle":
    "D'abord, sélectionnez l'organisation, puis le(s) site(s) et enfin les configurations de département(s).",
  "EditCustomPermissionsModal-title": "Modifier les autorisations personnalisées",
  "EditSessionAssignment-newWorker": "Nouveau travailleur:",
  "EditSessionAssignment-none": "Aucun",
  "EditSessionAssignment-previousWorker": "Travailleur précédent:",
  "EditSessionAssignment-sessionID": "ID de session:",
  "EditSessionAssignment-startTime": "Heure de début:",
  "EditSessionAssignment-subtitle": "Veuillez sélectionner le travailleur auquel vous souhaitez assigner la session suivante:",
  "EditSessionAssignment-systemSerialNumber": "# de série du système:",
  "EditSessionAssignment-title": "Modifier l'assignation de la session",
  "EditSessionAssignment-updateError": "Une erreur s'est produite pendant la tentative de mise à jour du travailleur assigné à la session",
  "EntityDetail-address": "Adresse:",
  "EntityDetail-assignedDevices": "# d'appareils assignés:",
  "EntityDetail-availableDevices": "# d'appareils disponibles:",
  "EntityDetail-devices": "# d'appareils:",
  "EntityDetail-disconnectedDevices": "# d'appareils déconnectés:",
  "EntityDetail-gatewayStatus": "Statut de la passerelle:",
  "EntityDetail-iconUrl": "URL de l'icône:",
  "EntityDetail-name": "Nom:",
  "EntityDetail-siteName": "Nom du site:",
  "EntityDetail-departmentName": "Nom du département:",
  "EntityDetail-noGateway": "Pas de passerelle",
  "EntityDetail-outOfServiceDevices": "# d'appareils hors service:",
  "EntityDetail-shortName": "Nom abrégé:",
  "EntityDetail-timezone": "Fuseau horaire:",
  "EntityDetail-title": "Détails {entity}",
  "EntityDetail-title-department": "Département",
  "EntityDetail-title-organization": "Organisation",
  "EntityDetail-title-site": "Site",
  "EntityDetailsSider-createDepartmentButton": "Créer un département",
  "EntityDetailsSider-createSiteButton": "Créer un site",
  "EntityDetailsSider-departmentsTitle": "Départements",
  "EntityDetailsSider-sitesTitle": "Sites",
  "EntityDetailsSiderCard-departmentAndDeviceCount": "{departments} Départements / {devices} Appareils",
  "EntityDetailsSiderCard-descriptionAddress": "Adresse: {address}",
  "EntityDetailsSiderCard-descriptionTimezone": "Fuseau horaire: {tz}",
  "EntityDetailsSiderCard-deviceCount": "{devices} Appareils",
  "EntityDetailsSiderCard-gatewayStatus": "Statut de la passerelle",
  "EntityDetailsSiderCard-gatewayDateSinceOffline": "({date})",
  "EntityDetailsSiderCard-DepartmentAndDeviceCount": "{departments} Départements / {devices} Appareils",
  "EntityDetailsSiderCard-siteDepartmentAndDeviceCount": "{sites} Sites / {departments} Départements / {devices} Appareils",
  "EntityDetailsSiderCard-siteDepartmentInformationOrg":
    "Puisque cette organisation ne possède qu'un seul site et un seul département, les informations sont affichées pour ces deux entités au niveau du département.",
  "EntityDetailsSiderCard-siteDepartmentInformation":
    "Puisque cette organisation ne possède qu'un seul site et un seul département, les informations sont affichées pour ces deux entités au niveau du département",
  "EntityDetailsSiderCard-view": "Afficher",
  "EntityDetails-analyticsTabName": "Analytiques",
  "EntityDetails-dashboardTabName": "Tableau de bord",
  "EntityDetails-devicesTabName": "Appareils",
  "EntityDetails-navigationMenu": "Menu de navigation",
  "EntityDetails-workerReportGroupsTabName": "Groupes de rapports des travailleurs",
  "EntityDetails-workersTabName": "Travailleurs",
  "EntityDetails-liveCheckedOutTabName": "Vérification en direct",
  "ErrorScreen-backHomeButton": "Retour à la maison",
  "ErrorScreen-correlationId": "(ID de corrélation: {correlationID})",
  "ErrorScreen-defaultErrorText": "Erreur",
  "ErrorScreen-internalServerErrorDescription": "Il y a eu une erreur lors du chargement des données de la page",
  "ErrorScreen-internalServerErrorText": "Erreur du serveur",
  "ErrorScreen-notFoundErrorDescription": "{entityType} inexistant(e)",
  "ErrorScreen-notFoundErrorText": "Erreur introuvable",
  "ErrorScreen-reloadPageButton": "Actualiser la page",
  "FetchAllocateGatewayData-errorMessage": "Une erreur s'est produite pendant la tentative d'obtention des passerelles disponibles",
  "FetchAnalyticsDashboardWorkersData-errorMessage":
    "Une erreur s'est produite pendant la tentative d'obtention des données des travailleurs",
  "FetchDashboardDailyUsageBreakdownSectionWorkersData-errorMessage":
    "Une erreur s'est produite pendant la tentative d'obtention des données des travailleurs",
  "FetchDepartmentDetailPageData-errorMessage": "Une erreur s'est produite pendant la tentative d'obtention des données du département",
  "FetchDeviceAllocationData-errorMessage": "Une erreur s'est produite pendant la tentative d'obtention des appareils disponibles",
  "FetchDeviceManagementDeviceDetailPageData-errorMessage":
    "Une erreur s'est produite pendant la tentative d'obtention des données de l'appareil",
  "FetchDeviceManagementDeviceDetailPageData-refreshErrorMessage":
    "Les données des sessions n'ont pas pu être actualisées après la mise à jour",
  "FetchDiagnosticsDevicesPageData-errorMessage": "Une erreur s'est produite pendant la tentative d'obtention des données des appareils",
  "FetchOrganizationsPageData-errorMessage": "Une erreur s'est produite lors de la tentative d'obtention des données de l'organisation",
  "FetchStatistics-errorMessage": "Une erreur s'est produite pendant la tentative d'obtention des statistiques",
  "FetchWorkersData-errorMessage": "Une erreur s'est produite lors de la tentative d'obtention des données requises",
  "GatewayAlertBlock-intermittentGateway":
    "La passerelle {serialNumber} de {siteName}–{departmentName} présente une connexion intermittente. Le statut de connexion et les données d'utilisation des appareils sur cette passerelle peuvent être inexacts",
  "GatewayAlertBlock-offlineGateway":
    "La passerelle {serialNumber} de {siteName}–{departmentName} est hors ligne depuis le {lastEventDate}. Le statut de connexion et les données d'utilisation des appareils sur cette passerelle peuvent être inexacts",
  "GatewayAlertBlock-offlineIntermitentGateways":
    "Les passerelles {listsText} rencontrent des problèmes de connexion. Le statut de connexion et les données d'utilisation des appareils sur ces passerelles peuvent être inexacts",
  "GatewayAlertBlock-offlineIntermitentGatewaysList": "{serialNumberList} à {siteName}–{departmentName}",
  "GatewayEditModal-currentSoftware": "Logiciel actuel:",
  "GatewayEditModal-editTargetSoftware": "Modifier le logiciel cible",
  "GatewayEditModal-fetchVersionsDataError":
    "Une erreur s'est produite pendant la tentative d'obtention des données sur les versions du logiciel",
  "GatewayEditModal-gatewaySerial": "# de série de la passerelle:",
  "GatewayEditModal-latest": "Dernier",
  "GatewayEditModal-scheduleButton": "Horaire",
  "GatewayEditModal-selectTargetSoftware": "Sélectionnez le logiciel cible",
  "GatewayEditModal-targetSoftware": "Logiciel cible:",
  "GatewayEditModal-title": "Modifier la passerelle",
  "GatewayEditModal-updateError": "Une erreur s'est produite pendant la tentative de mise à jour de la version logicielle de la passerelle",
  "GatewayEditModal-updateNowButton": "Mettre à jour maintenant",
  "GatewayEditModal-updateSuccessForced": "Une mise à jour forcée du logiciel de la passerelle est en attente.",
  "GatewayEditModal-updateSuccessScheduled": "La mise à jour de la passerelle est prévue pendant la nuit.",
  "GatewayItem-deallocateGateway": "Retirer l'attribution de la passerelle",
  "GatewayDetails-departmentLabel": "Département:",
  "GatewayDetails-gatewaySoftwareLabel": "Logiciel actuel:",
  "GatewayDetails-gatewaySerialNumber": "# de série de la passerelle {serialNumber}",
  "GatewayDetails-lastTimeConnectedLabel": "Dernière connexion:",
  "GatewayDetails-location": "Emplacement",
  "GatewayDetails-organizationLabel": "Organisation:",
  "GatewayDetails-siteLabel": "Site:",
  "GatewayDetails-title": "Détails de la passerelle",
  "GatewaysTable-currentSoftwareColumnTitle": "Logiciel actuel",
  "GatewaysTable-downLabel": "Hors service",
  "GatewaysTable-failed": "Échec",
  "GatewaysTable-firmwareColumnTitle": "Micrologiciel",
  "GatewaysTable-forceUpdatePending": " Mise à jour forcée en attente",
  "GatewaysTable-gatewayDetailsDrawerTitle": "Passerelle",
  "GatewaysTable-intermittentLabel": "Intermittent",
  "GatewaysTable-lastSeenColumnTitle": "Consulté récemment",
  "GatewaysTable-matchTarget": "Le logiciel correspond à la cible",
  "GatewaysTable-neverSeenLabel": "Jamais consulté",
  "GatewaysTable-scheduleUpdatePending": " Mise à jour planifiée en attente",
  "GatewaysTable-serialNumberColumnTitle": "# de série de la passerelle",
  "GatewaysTable-updateStatusColumnTitle": "Statut de la mise à jour",
  "GatewaysTable-upLabel": "Haut",
  "GatewaysTableLastSeenColumnRender-neverSeen": "Jamais consulté",
  "GatewayUpdateConfigurationModal-currentSoftware": "Logiciel actuel:",
  "GatewayUpdateConfigurationModal-description":
    "Si vous souhaitez mettre à jour la passerelle ci-dessous vers le logiciel cible, veuillez sélectionner Mettre à jour maintenant.",
  "GatewayUpdateConfigurationModal-gatewaySerial": "# de série de la passerelle:",
  "GatewayUpdateConfigurationModal-targetSoftware": "Logiciel cible:",
  "GatewayUpdateConfigurationModal-title": "Mise à jour de la configuration de passerelle",
  "GatewayUpdateConfigurationModal-updateError":
    "Une erreur s'est produite pendant la tentative de mise à jour de la version logicielle de la passerelle",
  "GatewayUpdateConfigurationModal-updateNowButton": "Mettre à jour maintenant",
  "GatewayUpdateConfigurationModal-updatePending": "Une mise à jour forcée du logiciel de la passerelle est en attente.",
  "Header-acknowledgeErrorMessage": "Une erreur s'est produite lors de la tentative d'accusé réception des notifications",
  "Header-clearNotifications": "Effacer",
  "Header-errorMessage": "Une erreur s'est produite lors de la tentative d'obtention des notifications",
  "Header-noNotifications": "Aucune notification",
  "Header-notifications": "Notifications",
  "Header-today": "Aujourd'hui",
  "Header-unallocatedData": "Données non attribuées",
  "Header-unallocatedDataDevices": "{devices} appareils disposent chacun de 8+ heures de données non allouées récemment",
  "Header-unallocatedDataOneDevice": "1 appareil dispose chacun de 8+ heures de données récemment non allouées",
  "Header-viewAll": "Voir tout",
  "IndividualWorkerReportDrawer-contentMessage":
    "Le rapport du travailleur **{worker}** couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport.",
  "IndividualWorkerReportDrawer-drawerTitle": "Rapport du travailleur",
  "IndividualWorkerReportDrawer-fileName": "Rapport du travailleur",
  "LeaderboardDownloadDropdown-downloadAll": "Tous",
  "LeaderboardDownloadDropdown-downloadBottom": "Opportunités de coaching",
  "LeaderboardDownloadDropdown-downloadData": "Télécharger les données",
  "LeaderboardDownloadDropdown-downloadError": "Une erreur s'est produite pendant la tentative de téléchargement du rapport",
  "LeaderboardDownloadDropdown-downloadTop": "Classement",
  "LeaderboardDownloadDropdown-title": "Classement",
  "LeaderboardDownloadDropdown-workerMetrics": "Données du travailleur",
  "LiveCheckedOutDeviceTable-dataUploadedTodayColumnTitle": "Données téléchargées aujourd'hui",
  "LiveCheckedOutDeviceTable-deviceTagColumnTitle": "Étiquette de l'appareil",
  "LiveCheckedOutDeviceTable-workerColumnTitle": "Travailleur",
  "LiveCheckedOutDeviceTable-checkedOutColumnTitle": "Vérifié",
  "LiveCheckedOutDeviceTable-lastSeenColumnTitle": "Consulté récemment",
  "ManualDeviceAllocation-description":
    "Veuillez sélectionner les numéros de série que vous souhaitez attribuer à **{site}** – **{department}**.",
  "ManualDeviceAllocation-devicesLabel": "Appareils:",
  "ManualWorkerCreation-bothNamesRequiredValidation": "Si Prénom ou Nom est spécifié, les deux champs doivent être renseignés",
  "ManualWorkerCreation-contentText": "Veuillez indiquer les travailleurs que vous souhaitez créer à **{siteName}** – **{departmentName}**",
  "ManualWorkerCreation-empIdAlreadyExistsValidation": "Un travailleur avec cet ID d'employé existe déjà sur le site {siteName}",
  "ManualWorkerCreation-ifNoNameRequireEmpIdValidation": "Si Prénom et Nom sont omis, le numéro d'employé doit être spécifié",
  "ManualWorkerCreation-nameAlreadyExistsValidation": "Un travailleur portant ce nom existe déjà sur le site {siteName}",
  "ManualWorkerCreation-title": "Création d'un travailleur",
  "Notifications-title": "Notifications",
  "Notifications-unallocatedData": "Données non attribuées",
  "Organizations-allOrganizationsPath": "Toutes les organisations",
  "Organizations-allOrganizationsTitle": "Toutes les organisations",
  "Organizations-allTagText": "Tous",
  "Organizations-createOrganizationButtonText": "Créer une organisation",
  "Organizations-downloadData": "Télécharger les données",
  "Organizations-favoriteTagText": "Favoris",
  "Organizations-organizationsListEndMessage": "Aucune autre organisation à afficher",
  "Organizations-searchButtonText": "Rechercher",
  "OrganizationCard-deviceStatusChartTitle": "Statut de l'appareil",
  "OrganizationCard-oneDeviceText": "1 appareil au total",
  "OrganizationCard-siteDepartmentAndDeviceCount":
    "{sites} {sites, plural, =1 {Site} other {Sites}} / {departments} {departments, plural, =1 {Département} other {Départements}} / {devices} {devices, plural, =1 {Appareil} other {Appareils}}",
  "OrganizationCard-totalDevicesText": "Nombre total d'appareils {devices}",
  "OrganizationCard-usageChartTitle": "Total des heures d'utilisation hebdomadaire",
  "OrganizationDetail-deleteAlertMessage": "Êtes-vous sûr de vouloir supprimer cette organisation?",
  "OrganizationDetail-deleteAlertTitle": "Supprimer l'organisation",
  "OrganizationDetail-deleteError": "L'organisation n'a pas pu être supprimée",
  "OrganizationDetail-fetchDataError": "Une erreur s'est produite lors de la tentative d'obtention des données de l'organisation",
  "OrganizationDetail-notFoundError": "Organisation introuvable",
  "OrganizationDetail-tableTitle": "Liste des sites",
  "OrganizationDetail-updateError": "Une erreur s'est produite pendant la tentative de mise à jour des données de l'organisation",
  "OrganizationForm-addressInputLabel": "Adresse",
  "OrganizationForm-createError": "Une erreur s'est produite pendant la tentative de création de l'organisation",
  "OrganizationForm-iconUrlInputLabel": "URL de l'icône",
  "OrganizationForm-nameInputLabel": "Nom",
  "OrganizationForm-shortNameInputLabel": "Nom abrégé",
  "OrganizationForm-shortNameInputPatternRuleMessage":
    "Le nom abrégé doit être composé uniquement de lettres minuscules, de chiffres ou de tirets, sans être placé au début ou à la fin du nom",
  "OrganizationModal-createError": "Une erreur s'est produite pendant la tentative de création de l'organisation",
  "OrganizationModal-createTitle": "Créer une organisation",
  "OrganizationModal-deleteButtonText": "Supprimer l'organisation",
  "OrganizationModal-deleteError": "L'organisation n'a pas pu être supprimée",
  "OrganizationModal-editTitle": "Modifier l'organisation",
  "OrganizationModal-updateError": "Une erreur s'est produite pendant la tentative de mise à jour de l'organisation",
  "OrganizationsTable-departmentsColumnTitle": "# de départements",
  "OrganizationsTable-devicesColumnTitle": "# d'appareils",
  "OrganizationsTable-nameColumnTitle": "Nom de l'organisation",
  "OrganizationsTable-sitesColumnTitle": "# de sites",
  "OrganizationSiteModal-editTitle": "Modifier l'organisation et le site",
  "OrganizationSiteModal-deleteAlertMessage": "Êtes-vous sûr de vouloir supprimer ce site?",
  "OrganizationSiteModal-deleteAlertTitle": "Supprimer le site",
  "OrganizationSiteModal-deleteButtonText": "Supprimer le site",
  "OrganizationSiteModal-deleteError": "Une erreur s'est produite pendant la tentative de suppression du site",
  "OrganizationSiteModal-updateOrganizationError": "Une erreur s'est produite pendant la tentative de mise à jour de l'organisation",
  "OrganizationSiteModal-updateSiteError": "Une erreur s'est produite pendant la tentative de mise à jour du site",
  "OverridePendingUpdateConfirmationModal-message": "Êtes-vous sûr de vouloir annuler la mise à jour en attente?",
  "OverridePendingUpdateConfirmationModal-title": "Remplacer la mise à jour en attente",
  "PendingUpdateDetail-buttonOne": "Bouton 1:",
  "PendingUpdateDetail-buttonThree": "Bouton 3:",
  "PendingUpdateDetail-buttonTwo": "Bouton 2:",
  "PendingUpdateDetail-drawerSubtitle": "Mise à jour en attente pour l'appareil suivant:",
  "PendingUpdateDetail-drawerTitle": "Mise à jour en attente",
  "PendingUpdateDetail-firmware": "Rév. du micrologiciel:",
  "PendingUpdateDetail-hardwareRev": "Rév. du matériel:",
  "PendingUpdateDetail-overridePendingUpdateButton": "Remplacer la mise à jour en attente",
  "PendingUpdateDetail-systemSerialNumber": "N° de série du système:",
  "RemoveFromFavoritesModal-doNotShowMessageAgainText": "Ne plus afficher ce message.",
  "RemoveFromFavoritesModal-message": "Êtes-vous sûr de vouloir supprimer **{organization}** des favoris?",
  "RenameWorkerGroupModal-errorMessage": "Une erreur s'est produite en essayant de mettre à jour le groupe de rapport du travailleur",
  "RenameWorkerGroupModal-inputLabel": "Nom du Groupe:",
  "RenameWorkerGroupModal-nameLengthValidator": "Les noms de groupes sont limités à 40 caractères. Veuillez abréger le nom.",
  "RenameWorkerGroupModal-rename": "Renommer",
  "RenameWorkerGroupModal-successMessage": "Le nom du groupe a été modifié avec succès",
  "RenameWorkerGroupModal-title": "Renommer le groupe",
  "ReportDownload-allButtonText": "Télécharger le rapport Tous les travailleurs",
  "ReportDownload-allMessage":
    "Le rapport de tous les travailleurs couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport que vous souhaitez télécharger.",
  "ReportDownload-allTitle": "Rapport de tous les travailleurs",
  "ReportDownload-demoButtonText": "Télécharger le rapport de démo",
  "ReportDownload-demoMessage":
    "Le rapport de démo couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport que vous souhaitez télécharger.",
  "ReportDownload-demoTitle": "Rapport de démo",
  "ReportDownload-department": "Département:",
  "ReportDownload-downloadError": "Une erreur s'est produite pendant la tentative de téléchargement du rapport",
  "ReportDownload-individualButtonText": "Télécharger le rapport sur un seul travailleur",
  "ReportDownload-individualMessage":
    "Le rapport de tous les travailleurs couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport que vous souhaitez télécharger.",
  "ReportDownload-individualTitle": "Rapport du travailleur",
  "ReportDownload-noDepartmentError": "Il n'y a aucun départements sur ce site pour télécharger le rapport.",
  "ReportDownload-reportEndDate": "Date de fin de rapport:",
  "ReportDownload-summaryButtonText": "Télécharger le rapport du département",
  "ReportDownload-summaryMessage":
    "Le rapport sommaire du département couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport que vous souhaitez télécharger.",
  "ReportDownload-summaryTitle": "Rapport sommaire",
  "ReportDownload-worker": "**Travailleur: ** {displayName}",
  "ReportDrawer-fetchReportError": "Une erreur s'est produite pendant la tentative de récupération des données du rapport",
  "ReportDrawerContent-downloadButtonText": "Télécharger",
  "ReportDrawerContent-previewButtonText": "Aperçu",
  "ReportDrawerContent-generateReportButtonText": "Générer un rapport",
  "ReportDrawerContent-reportEndDate": "Date de fin de rapport:",
  "ReportDrawerContent-filterReport": "Filtrer le rapport:",
  "ReportDrawerContent-allWorkers": "Tous les travailleurs",
  "ReportDrawerContent-workerReportGroups": "Groupes",
  "ReportPreviewModal-title": "Aperçu",
  "ReportPreviewModal-searchButtonText": "Rechercher",
  "ReportPreviewModal-download": "Télécharger",
  "WorkerSafeliftReportPreviewModal-all": "Télécharger tous les travailleurs actifs et inactifs",
  "WorkerSafeliftReportPreviewModal-allActive": "Télécharger tous les travailleurs actifs",
  "WorkerSafeliftReportPreviewModal-allWorkers": "Tous les travailleurs",
  "WorkerSafeliftReportPreviewModal-allWorkersNoData":
    "Aucun travailleur de {departmentName} n'était actif entre le {startDate} et le {endDate}",
  "WorkerSafeliftReportPreviewModal-displayInactiveWorkers": "Afficher les travailleurs inactifs:",
  "WorkerSafeliftReportPreviewModal-download": "Télécharger",
  "WorkerSafeliftReportPreviewModal-downloadError": "Une erreur s'est produite pendant la tentative de téléchargement du rapport",
  "WorkerSafeliftReportPreviewModal-generatingReport": "Génération du rapport...",
  "WorkerSafeliftReportPreviewModal-groupNoData":
    "Aucun travailleur de {departmentName} – {groupName} n'était actif entre le {startDate} et le {endDate}",
  "WorkerSafeliftReportPreviewModal-individual": "Sélectionnez un travailleur",
  "WorkerSafeliftReportPreviewModal-internalError": "Désolé, une erreur s'est produite. Erreur interne. Veuillez réessayer plus tard.",
  "WorkerSafeliftReportPreviewModal-safeliftReport": "Rapport Safelift",
  "WorkerSafeliftReportPreviewModal-searchButtonText": "Rechercher",
  "WorkerSafeliftReportPreviewModal-timeoutError":
    "Désolé, une erreur s'est produite. Le délai d'attente a expiré. Veuillez réessayer plus tard.",
  "WorkerSafeliftReportPreviewModal-title": "Aperçu",
  "WorkerSafeliftReportPreviewModal-workerNoData": "{workerName} n'était pas actif entre le {startDate} et le {endDate}",
  "SearchFilter-inputPlaceholder": "Boîte de recherche",
  "SearchFilter-resetButton": "Réinitialiser",
  "SearchFilter-searchButton": "Rechercher",
  "SessionLog-download": "Télécharger",
  "SessionLog-duration": "Durée:",
  "SessionLog-entries": "Entrées:",
  "SessionLog-errors": "Erreurs",
  "SessionLog-fetchDataError": "Une erreur s'est produite lors de la tentative d'obtention des données des journaux",
  "SessionLog-lifts": "Soulèvements:",
  "SessionLog-log": "Journal:",
  "SessionLog-none": "Aucun",
  "SessionLog-sessionID": "ID de session:",
  "SessionLog-startTime": "Heure de début:",
  "SessionLog-systemSerialNumber": "# de série du système:",
  "SessionLog-title": "Journaux JSON",
  "SessionLog-worker": "Travailleur:",
  "SessionsTable-createdLabel": "Créé",
  "SessionsTable-duration": "Durée",
  "SessionsTable-entries": "Entrées",
  "SessionsTable-errors": "Erreurs",
  "SessionsTable-hourAbbreviation": "h",
  "SessionsTable-id": "ID de session",
  "SessionsTable-lifts": "Soulèvements",
  "SessionsTable-logs": "Journaux",
  "SessionsTable-minuteAbbreviation": "m",
  "SessionsTable-none": "Aucun",
  "SessionsTable-processedLabel": "Traité",
  "SessionsTable-secondAbbreviation": "s",
  "SessionsTable-startTime": "Heure de début",
  "SessionsTable-status": "Statut",
  "SessionsTable-uploadedLabel": "Téléchargé",
  "SessionsTable-uuid": "UUID de session",
  "SessionsTable-worker": "Travailleur",
  "SignIn-emailInputLabel": "Courriel",
  "SignIn-emailInputRequiredRuleMessage": "Le champ Courriel est obligatoire",
  "SignIn-errorMessage": "Impossible de se connecter",
  "SignIn-passwordInputLabel": "Mot de passe",
  "SignIn-passwordInputRequiredRuleMessage": "Le champ Mot de passe est obligatoire",
  "SignIn-submit": "Se connecter",
  "SiteDepartmentModal-deleteAlertMessage": "Êtes-vous sûr de vouloir supprimer ce département?",
  "SiteDepartmentModal-deleteAlertTitle": "Supprimer le département",
  "SiteDepartmentModal-deleteButtonText": "Supprimer le département",
  "SiteDepartmentModal-title": "Modifier le site et le département",
  "SiteDepartmentModal-orgIncludedTitle": "Modifier l'organisation, le site et le département",
  "SiteDetail-deleteAlertMessage": "Êtes-vous sûr de vouloir supprimer ce site?",
  "SiteDetail-deleteAlertTitle": "Supprimer le site",
  "SiteDetail-deleteError": "Le site n'a pas pu être supprimé",
  "SiteDetail-differentOrganizationError": "Ce site appartient à une autre organisation",
  "SiteDetail-fetchDataError": "Une erreur s'est produite pendant la tentative d'obtention des données du site",
  "SiteDetail-notFoundError": "Site introuvable",
  "SiteDetail-tableTitle": "Liste des départements",
  "SiteDetail-updateError": "Une erreur s'est produite pendant la tentative de mise à jour des données du site",
  "SiteForm-addressInputLabel": "Adresse",
  "SiteForm-createError": "Une erreur s'est produite pendant la tentative de création du site",
  "SiteForm-nameInputLabel": "Nom",
  "SiteForm-timezoneInputLabel": "Fuseau horaire",
  "SiteModal-createError": "Une erreur s'est produite pendant la tentative de création du site",
  "SiteModal-createTitle": "Créer un site",
  "SiteModal-deleteButtonText": "Supprimer le site",
  "SiteModal-editTitle": "Modifier le site",
  "SiteModal-updateError": "Une erreur s'est produite pendant la tentative de mise à jour du site",
  "SiteSummaryLeaderboardPage-departmentAverage": "Moyenne du département",
  "SiteSummaryLeaderboardPage-kilogramsUnit": "kg",
  "SiteSummaryLeaderboardPage-poundsUnit": "lb",
  "SiteSummaryLeaderboardPage-safeLiftScore": "Score Safelift",
  "SiteSummaryLeaderboardPage-weightOffloaded": "Poids déchargé",
  "SiteSummaryReportDrawer-activeInactiveWorkersCheckbox": "Liste des travailleurs actifs/inactifs",
  "SiteSummaryReportDrawer-contentMessage":
    "Le rapport sommaire du site couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport.",
  "SiteSummaryReportDrawer-displayWorkersWeightOffloadedCheckbox": "Afficher le poids déchargé des travailleurs",
  "SiteSummaryReportDrawer-editConfigurationButton": " Modifier la configuration par défaut ",
  "SiteSummaryReportDrawer-errorMessage": "Une erreur s'est produite pendant la récupération des données",
  "SiteSummaryReportDrawer-featuresToIncludeMessage": "Fonctionnalités à inclure dans le rapport.",
  "SiteSummaryReportDrawer-includeWorkerReportGroups": "Inclure les groupes de rapports des travailleurs:",
  "SiteSummaryReportDrawer-leaderboardCheckbox": "Classement",
  "SiteSummaryReportDrawer-onlyAdminsMessage": "Seuls les administrateurs peuvent effectuer des modifications.",
  "SiteSummaryReportDrawer-reportEndDate": "Date de fin de rapport:",
  "SiteSummaryReportDrawer-safeliftScoreCheckbox": "Afficher le score Safelift",
  "SiteSummaryReportDrawer-select": "Sélectionner",
  "SiteSummaryReportDrawer-updateConfigButton": "Mise à jour de la configuration",
  "SiteSummaryReportDrawer-updateError":
    "Une erreur s'est produite pendant la tentative de mise à jour de la configuration du rapport sommaire du site",
  "SiteSummaryReportDrawer-updateSuccess": "La mise à jour de la configuration du rapport sommaire du site a été effectuée avec succès",
  "SiteSummaryReportPDF-aboveTarget": "Supérieur à la cible",
  "SiteSummaryReportPDF-active": "Actif ",
  "SiteSummaryReportPDF-activeWorker": "Travailleur actif",
  "SiteSummaryReportPDF-belowTarget": "Inférieur à la cible",
  "SiteSummaryReportPDF-exempt": "Exempté/Non défini",
  "SiteSummaryReportPDF-fileName": "RapportSommaireDuSite.pdf",
  "SiteSummaryReportPDF-footer": "Page {currentPage} de {totalPages}",
  "SiteSummaryReportPDF-headerDates": "du {startDate} au {endDate}",
  "SiteSummaryReportPDF-inactive": "Inactif",
  "SiteSummaryReportPDF-inactiveWorker": "Travailleur inactif",
  "SiteSummaryReportPDF-kilogramsUnit": "kg",
  "SiteSummaryReportPDF-onTarget": "Conforme",
  "SiteSummaryReportPDF-point": "•",
  "SiteSummaryReportPDF-poundsUnit": "lb",
  "SiteSummaryReportPDF-totalUsageHours": "Total des heures d'utilisation",
  "SiteSummaryReportPDF-usageHoursTargetUtilization": "Statut des Heures d'Utilisation des Travailleurs",
  "SiteSummaryReportPDF-totalWeightOffloaded": "Poids total déchargé:",
  "SiteSummaryReportPDF-weeklySummaryReport": "Rapport Sommaire Hebdomadaire",
  "SiteSummaryReportPDF-weightOffloadedTitle": "Poids déchargé",
  "SiteSummaryReportPDF-workerReportGroups": "Groupes de rapports des travailleurs",
  "SiteSummaryReportPDF-workerStatusCounts": "Décompte des statuts d'un travailleur",
  "SiteSummaryReportModal-activeInactiveWorkersCheckbox": "Liste des travailleurs actifs/inactifs",
  "SiteSummaryReportModal-contentMessage":
    "Le rapport sommaire du site couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport.",
  "SiteSummaryReportModal-displayWorkersWeightOffloadedCheckbox": "Afficher le poids déchargé des travailleurs",
  "SiteSummaryReportModal-errorMessage": "Une erreur s'est produite pendant la récupération des données",
  "SiteSummaryReportModal-featuresToIncludeMessage": "Fonctionnalités à inclure dans le rapport.",
  "SiteSummaryReportModal-includeWorkerReportGroups": "Inclure les groupes de rapports des travailleurs:",
  "SiteSummaryReportModal-leaderboardCheckbox": "Classement",
  "SiteSummaryReportModal-onlyAdminsMessage": "Seuls les administrateurs peuvent effectuer des modifications.",
  "SiteSummaryReportModal-safeliftScoreCheckbox": "Afficher le score Safelift",
  "SiteSummaryReportModal-save": "Enregistrer",
  "SiteSummaryReportModal-select": "Sélectionner",
  "SiteSummaryReportModal-title": "Modifier la configuration par défaut",
  "SiteSummaryReportModal-updateError":
    "Une erreur s'est produite pendant la tentative de mise à jour de la configuration du rapport sommaire du site",
  "SiteSummaryReportModal-updateSuccess": "La mise à jour de la configuration du rapport sommaire du site a été effectuée avec succès",
  "SiteSummaryReportModal-workerGroupsPlaceholder": "Groupes de travailleurs",
  "SiteSummaryReportPreviewModal-download": "Télécharger",
  "SiteSummaryReportPreviewModal-title": "Aperçu",
  "SitesTable-departmentsColumnTitle": "# de départements",
  "SitesTable-devicesColumnTitle": "# d'appareils",
  "SitesTable-nameColumnTitle": "Nom du site",
  "SiteWorkersTable-assignedDeviceColumnTitle": "Appareil assigné",
  "SiteWorkersTable-daysSinceLastUsageColumnTitle": "Jours depuis la dernière utilisation",
  "SiteWorkersTable-departmentColumnTitle": "Département",
  "SiteWorkersTable-employeeIDColumnTitle": "ID de l'employé",
  "SiteWorkersTable-naLabel": "N/A",
  "SiteWorkersTable-nameColumnTitle": "Nom",
  "SiteWorkersTable-report": "Rapport",
  "StatusChart-activeWorkers": "Actif ",
  "StatusChart-assignedDevices": "Assigné",
  "StatusChart-availableDevices": "Disponible",
  "StatusChart-deviceStatusTitle": "Appareils",
  "StatusChart-inactiveWorkers": "Inactif",
  "StatusChart-moreInformationText": "*Cliquez pour plus d'informations",
  "StatusChart-outOfServiceDevices": "Hors service",
  "StatusChart-statusChartTitle":
    "{entityType, plural, =0 {Organisation} =1 {Site} =2 {Département} =3 {Groupes de rapports des travailleurs} other {Organisation}} Statut",
  "StatusChart-waitlistWorkers": "Liste d'attente",
  "StatusChart-workerStatusTitle": "Travailleurs",
  "Subheader-actions-allWorkersReport": "Rapport de tous les travailleurs",
  "Subheader-actions-demoReport": "Rapport de démo",
  "Subheader-actions-summaryReport": "Rapport sommaire",
  "SummaryReportDrawer-contentMessage":
    "Le rapport sommaire du département couvre une période de 7 jours. Veuillez sélectionner une date de fin pour le rapport.",
  "SummaryReportDrawer-drawerTitle": "Rapport sommaire",
  "SummaryReportDrawer-fileName": "Rapport sommaire",
  "TargetUtilizationHoursModal-adminMessage": "Seuls les administrateurs peuvent modifier ces cibles.",
  "TargetUtilizationHoursModal-afterWeek": "Après une semaine {week}:",
  "TargetUtilizationHoursModal-configurationErrorMessage":
    "Une erreur s'est produite pendant la tentative de mise à jour de la configuration",
  "TargetUtilizationHoursModal-configurationSuccessMessage": "Les critères cibles ont été configurés avec succès",
  "TargetUtilizationHoursModal-constantTarget": "Cible constante",
  "TargetUtilizationHoursModal-constantTargetLabel": "Sélectionnez une cible fixe d'heures par jour pour tous les travailleurs:",
  "TargetUtilizationHoursModal-departmentConfiguration": "Vos administrateurs ont configuré le département {configName} comme suit:",
  "TargetUtilizationHoursModal-departmentConfigurationConstantTarget":
    "Vos administrateurs ont défini une cible constante pour le département de {hours} heures par jour.",
  "TargetUtilizationHoursModal-departmentConfigurationNoTarget":
    "Vos administrateurs n'ont pas configuré d'heures d'utilisation cible pour ce département.",
  "TargetUtilizationHoursModal-departmentConfigurationOnboardingCalendar":
    "Vos administrateurs ont défini le calendrier d'intégration du département comme suit:",
  "TargetUtilizationHoursModal-hoursDay": "hrs/jour",
  "TargetUtilizationHoursModal-hoursPerDay": "Heures par jour",
  "TargetUtilizationHoursModal-infoMessage":
    "Le calcul de l'utilisation cible repose sur une semaine ouvrable de 5 jours. Les autres horaires ne sont actuellement pas pris en charge",
  "TargetUtilizationHoursModal-noTarget": "Aucune cible",
  "TargetUtilizationHoursModal-onboardingCalendar": "Calendrier d'intégration",
  "TargetUtilizationHoursModal-onboardingCalendarLabel":
    "Sélectionnez une cible d'heures par jour qui varie en fonction d'un calendrier d'intégration de 4 semaines.",
  "TargetUtilizationHoursModal-subtitle": "Sélectionnez comment le département souhaite utiliser la cible.",
  "TargetUtilizationHoursModal-title": "Utilisation d'appareil cible",
  "TargetUtilizationHoursModal-week": "Semaine {week}:",
  "UnenrollmentReason-other": "Autre",
  "UnenrollmentReason-subtitle": "Veuillez vérifier tous les motifs pour lesquels **{workerIdentifier}** est désinscrit au programme",
  "UnenrollmentReason-title": "Motif de désinscription",
  "UnenrollmentReason-unenrollmentReasonError":
    "Une erreur s'est produite pendant la tentative d'enregistrement des motifs de désinscription",
  "UnenrollmentReasons-finished": "Terminé",
  "UnenrollmentReasons-inProgress": "En cours",
  "UnenrollmentReasons-openGlossary": "Ouvrir le glossaire",
  "UnenrollmentReasons-openGlossaryExtraDesktop":
    "Passez la souris pour afficher les détails des motifs de désinscription individuelles ou",
  "UnenrollmentReasons-primaryReason": "Motif principal",
  "UnenrollmentReasons-sceondaryReasons": "Motifs secondaires",
  "UnenrollmentReasons-subtitleFirstStep":
    "Veuillez cocher un seul **motif principal** pour lequel **{workerIdentifier}** est désinscrit au programme.",
  "UnenrollmentReasons-subtitleSecondStep":
    "Veuillez cocher autant de **motifs secondaires** que nécessaire pour la désinscription du travailleur **{workerIdentifier}** au programme (facultatif).",
  "UnenrollmentReasons-waiting": "En attente",
  "UnenrollmentReasonsGlossary-title": "Glossaire des motifs de désinscription",
  "UnenrollmentReasonsGlossary-description": "Description",
  "UnenrollmentReasonsGlossary-descriptionTerminatedQuit": "L'utilisateur a quitté l'entreprise (soit par démission ou licenciement)",
  "UnenrollmentReasonsGlossary-descriptionUnknown": "Perte de connexion avec l'utilisateur. Motif inconnu de déconnexion.",
  "UnenrollmentReasonsGlossary-descriptionBack": "Inconfort au dos (inférieur ou supérieur)",
  "UnenrollmentReasonsGlossary-descriptionSuitHeavyShoulderPressure":
    "Inconfort général à l'épaule dû à la pression causée par le poids/la traction de la combinaison",
  "UnenrollmentReasonsGlossary-descriptionShoulderStrapIrritationPointPressure":
    "Irritation spécifique ou pression ponctuelle résultant de la bandoulière qui pique/frotte l'utilisateur",
  "UnenrollmentReasonsGlossary-descriptionHeat": "L'utilisateur a trop chaud dans sa combinaison",
  "UnenrollmentReasonsGlossary-descriptionConstriction":
    "L'utilisateur se sent simplement restreint, que ce soit au niveau de la poitrine ou par appréhension générale liée au port d'équipement inhabituel",
  "UnenrollmentReasonsGlossary-descriptionGeneral":
    "L'utilisateur trouve simplement la combinaison inconfortable dans son ensemble et ne peut identifier une zone précise d'inconfort",
  "UnenrollmentReasonsGlossary-descriptionDoesntNeedIt":
    "L'utilisateur est d'avis que la combinaison ne lui apporte pas de bénéfice personnel (même si son poste actuel lui convient)",
  "UnenrollmentReasonsGlossary-descriptionTimeHabit":
    "L'utilisateur trouve que la combinaison prend trop de temps et/ou éprouve des difficultés à adopter l'habitude de l'enfiler",
  "UnenrollmentReasonsGlossary-descriptionPreexistingCondition":
    "L'utilisateur a une condition préexistante qui, selon lui, ne correspond pas à la combinaison (blessure à l'épaule, blessure au genou, etc.)",
  "UnenrollmentReasonsGlossary-descriptionOnLeave": "L'utilisateur est en arrêt de travail pour une période prolongée",
  "UnenrollmentReasonsGlossary-descriptionLimitsOverheadReaching":
    "La combinaison limite les mouvements en hauteur de l'utilisateur ou le rend inconfortable.",
  "UnenrollmentReasonsGlossary-descriptionThighWrapsDropping":
    "La nécessité de réajuster constamment les bandes de cuisses constitue une gêne importante pour l'utilisateur",
  "UnenrollmentReasonsGlossary-descriptionExtraHeightWidthOfSuitOnBack":
    "L'épaisseur de la combinaison limite les mouvements normaux de l'utilisateur (c'est-à-dire qu'il doit se pencher davantage pour accéder aux étagères basses et qu'il est difficile de se faufiler dans les espaces restreints)",
  "UnenrollmentReasonsGlossary-descriptionAssistanceTimingWrongJerky":
    "L'utilisateur constate que l'assistance de la combinaison n'est pas synchronisée avec ses mouvements",
  "UnenrollmentReasonsGlossary-descriptionJobFunctionChange":
    "L'utilisateur a changé de travail qui ne nécessite plus l'utilisation d'une exosuit (par exemple, conducteur de chariot élévateur)",
  "UnenrollmentReasonsGlossary-descriptionPerformanceProductivityConcerns":
    "L'utilisateur a l'impression que la combinaison le ralentit et/ou qu'elle a un impact négatif sur sa productivité.",
  "UnenrollmentReasonsGlossary-descriptionJobIsNotGoodUseCase":
    "L'utilisateur constate que la fonction pour le travail, (initialement perçue comme une aide), ne présente aucun avantage pour la combinaison.",
  "UnenrollmentReasonsGlossary-descriptionOther":
    "Ne correspond à aucune des catégories ci-dessus ou vous souhaitez enregistrer des informations/descriptions supplémentaires",
  "UnenrollmentReasonsGlossary-exitReason": "Motif de sortie",
  "UnenrollmentReasonsGlossary-labelTerminatedQuit": "Terminé/Quitter",
  "UnenrollmentReasonsGlossary-labelUnknown": "Inconnu",
  "UnenrollmentReasonsGlossary-labelBack": "Dos",
  "UnenrollmentReasonsGlossary-labelSuitHeavyShoulderPressure": "Combinaison lourde/pression aux épaules",
  "UnenrollmentReasonsGlossary-labelShoulderStrapIrritationPointPressure": "Irritation de la bandoulière/pression ponctuelle",
  "UnenrollmentReasonsGlossary-labelHeat": "Chaleur",
  "UnenrollmentReasonsGlossary-labelConstriction": "Constriction",
  "UnenrollmentReasonsGlossary-labelGeneral": "Général",
  "UnenrollmentReasonsGlossary-labelDoesntNeedIt": "Non nécessaire",
  "UnenrollmentReasonsGlossary-labelTimeHabit": "Heure/Habitude",
  "UnenrollmentReasonsGlossary-labelPreexistingCondition": "Condition préexistante",
  "UnenrollmentReasonsGlossary-labelOnLeave": "En arrêt",
  "UnenrollmentReasonsGlossary-labelLimitsOverheadReaching": "Limite d'atteinte en hauteur",
  "UnenrollmentReasonsGlossary-labelThighWrapsDropping": "Les bandes de cuisses tombent",
  "UnenrollmentReasonsGlossary-labelExtraHeightWidthOfSuitOnBack": "Hauteur/largeur supplémentaire de la combinaison au niveau du dos",
  "UnenrollmentReasonsGlossary-labelAssistanceTimingWrongJerky": "Synchronisation de l'assistance incorrecte/saccadée",
  "UnenrollmentReasonsGlossary-labelJobFunctionChange": "Changement de fonction",
  "UnenrollmentReasonsGlossary-labelPerformanceProductivityConcerns": "Problèmes de performances/productivité",
  "UnenrollmentReasonsGlossary-labelJobIsNotGoodUseCase": "L'emploi n'est pas un bon cas d'utilisation",
  "UnenrollmentReasonsGlossary-labelOther": "Autre",
  "UnenrollmentReasonsGlossary-subTitleUnknown": "Inconnu",
  "UnenrollmentReasonsGlossary-subTitleTerminatedQuit": "Terminé/Quitter",
  "UnenrollmentReasonsGlossary-subTitleOther": "Autre",
  "UnenrollmentReasonsGlossary-subTitleDiscomfort": "Inconfort",
  "UnenrollmentReasonsGlossary-subTitleConvenience": "Confort",
  "UnenrollmentReasonsGlossary-subTitlePersonal": "Personnel",
  "UnenrollmentReasonsGlossary-subTitleSuitLimitations": "Limitations de la combinaison",
  "UnenrollmentReasonsGlossary-subTitleJob": "Emploi",
  "UnenrollWorkerMainReason-other": "Autre",
  "UnenrollWorkerMainReason-terminated": "Terminé/Quitter",
  "UnenrollWorkerMainReason-unknown": "Inconnu",
  "UnenrollWorkerOtherReason-assistance_time_wrong": "Synchronisation de l'assistance incorrecte/saccadée",
  "UnenrollWorkerOtherReason-back": "Dos",
  "UnenrollWorkerOtherReason-constriction": "Constriction",
  "UnenrollWorkerOtherReason-convenience": "Confort",
  "UnenrollWorkerOtherReason-discomfort": "Inconfort",
  "UnenrollWorkerOtherReason-extra_height_width": "Hauteur/largeur supplémentaire de la combinaison au niveau du dos",
  "UnenrollWorkerOtherReason-general": "Général",
  "UnenrollWorkerOtherReason-heat": "Chaleur",
  "UnenrollWorkerOtherReason-heavy_shoulder_pressure": "Combinaison lourde/pression aux épaules",
  "UnenrollWorkerOtherReason-job": "Emploi",
  "UnenrollWorkerOtherReason-job_function_change": "Changement de fonction",
  "UnenrollWorkerOtherReason-limits_overhead_reaching": "Limite d'atteinte en hauteur",
  "UnenrollWorkerOtherReason-no_need": "Non nécessaire",
  "UnenrollWorkerOtherReason-on_leave": "En arrêt",
  "UnenrollWorkerOtherReason-performance_concern": "Problèmes de performances/productivité",
  "UnenrollWorkerOtherReason-personal": "Personnel",
  "UnenrollWorkerOtherReason-preexisting_condition": "Condition préexistante",
  "UnenrollWorkerOtherReason-shoulder_point_pressure": "Irritation de la bandoulière/pression ponctuelle",
  "UnenrollWorkerOtherReason-suitLimitations": "Limitations de la combinaison",
  "UnenrollWorkerOtherReason-thigh_wraps_dropping": "Les bandes de cuisses tombent",
  "UnenrollWorkerOtherReason-time_habit": "Heure/Habitude",
  "UnenrollWorkerOtherReason-use_case": "L'emploi n'est pas un bon cas d'utilisation",
  "Unsubscribe-accountSettings": "Paramètres du compte",
  "Unsubscribe-subText": "Veuillez cliquer sur le bouton ci-dessous pour vous désabonner des courriels administratifs de Verve Logic.",
  "Unsubscribe-title": "Se désabonner des courriels administratifs",
  "Unsubscribe-unsubscribe": "Se désabonner",
  "Unsubscribe-unsubscribedSubText":
    "Vous vous êtes désabonné avec succès des courriels administratifs de Verve Logic. Pour vous réabonner, veuillez modifier votre",
  "UpdateDeviceConfigurationForm-buttonLabel": "Bouton {number}:",
  "UpdateDeviceConfigurationForm-buttonSettingsSubtitle": "Paramètres des boutons",
  "UpdateDeviceConfigurationForm-description": "Veuillez sélectionner les paramètres que vous souhaitez mettre à jour pour l'appareil:",
  "UpdateDeviceConfigurationForm-firmwareVersionLabel": "Version du micrologiciel:",
  "UpdateDeviceConfigurationForm-hardwareRevLabel": "Rév. du matériel:",
  "UpdateDeviceConfigurationForm-noButtonSettings": "Aucun paramètre de bouton trouvé",
  "UpdateDeviceConfigurationForm-noFirmwareVersions": "Aucune version du micrologiciel trouvée",
  "UpdateDeviceConfigurationForm-systemSerialNumberLabel": "# de série du système:",
  "UpdateDeviceConfigurationModal-scheduleUpdateButtonText": "Mise à jour de l'horaire",
  "UpdateDeviceConfigurationModal-title": "Mise à jour de la configuration d'appareil",
  "UpdateDeviceConfigurationModal-updateError":
    "Une erreur s'est produite pendant la tentative de mise à jour de la configuration de l'appareil",
  "UpdateDeviceConfigurationModal-updateSuccess": "La mise à jour de la configuration de l'appareil a été planifiée avec succès.",
  "UsageChart-averageUsageHoursLabel": "Moyenne d'heures",
  "UsageChart-totalUsageHoursLabel": "Total des heures d'utilisation",
  "UserPermissions-accessLevel": "Niveau d'accès",
  "UserPermissions-active": "Actif ",
  "UserPermissions-admin": "Admin",
  "UserPermissions-changeAccessLevelError":
    "Une erreur s'est produite pendant la tentative de mise à jour du niveau d'accès de l'utilisateur.",
  "UserPermissions-changeAccessLevelText":
    "Veuillez confirmer que vous souhaitez modifier le niveau d'accès de **{givenName} {familyName}** pour l'organisation **{organizationName}** de **{previousLevel}** à **{newLevel}**.",
  "UserPermissions-changeAccessLevelTitle": "Modifier le niveau d'accès",
  "UserPermissions-changeActiveError": "Une erreur s'est produite pendant la tentative de mise à jour de l'utilisateur.",
  "UserPermissions-confirm": "Confirmer",
  "UserPermissions-custom": "Personnalisé",
  "UserPermissions-deactivateUser": "Désactiver l'utilisateur",
  "UserPermissions-deactivateUserText":
    "Veuillez confirmer que vous souhaitez désactiver **{givenName} {familyName}** en tant que membre de l'organisation **{organizationName}**.",
  "UserPermissions-email": "Courriel",
  "UserPermissions-fetchDataError": "Une erreur s'est produite pendant le tentative de récupération des données de l'utilisateur",
  "UserPermissions-firstName": "Prénom",
  "UserPermissions-global": "MONDIAL",
  "UserPermissions-inactive": "Inactif",
  "UserPermissions-lastName": "Nom",
  "UserPermissions-manager": "Directeur",
  "UserPermissions-needToBeAdminOrTrustedUserError": "Vous devez être un administrateur ou un utilisateur de confiance",
  "UserPermissions-needToBeAnAdminError": "Vous devez être un administrateur",
  "UserPermissions-none": "Aucun",
  "UserPermissions-organization": "Organisation",
  "UserPermissions-reactivateUser": "Réactiver l'utilisateur",
  "UserPermissions-reactivateUserText":
    "Veuillez confirmer que vous souhaitez réactiver **{givenName} {familyName}** pour rejoindre l'organisation **{organizationName}**.",
  "UserPermissions-readOnly": "En lecture seulement",
  "UserPermissions-statusColumnTitle": "Statut",
  "UserPermissions-title": "Autorisations de l'utilisateur",
  "Validation-confirmPasswordError": "Le mot de passe de confirmation ne correspond pas au mot de passe",
  "WorkerCreationCSVModal-columns": "Colonnes: Prénom, Nom, ID de l'employé",
  "WorkerCreationCSVModal-createWorkers": "Créer des travailleurs",
  "WorkerCreationCSVModal-description":
    "Veuillez télécharger un fichier CSV des travailleurs que vous souhaitez créer à **{siteName}** – **{departmentName}**.",
  "WorkerCreationCSVModal-successMessage": "Le fichier CSV a été téléchargé avec succès",
  "WorkerCreationManualModal-createAnotherWorker": "Créer un autre travailleur",
  "WorkerCreationManualModal-createError": "Une erreur s'est produite pendant la tentative de création du travailleur",
  "WorkerCreationManualModal-createdSuccessMessage": "Travailleur créé avec succès",
  "WorkerCreationManualModal-createWorkers": "Créer des travailleurs",
  "WorkerDetail-assignedDevice": "Appareil:",
  "WorkerDetail-cannotDeleteMessage":
    "Ce travailleur ne peut pas être supprimé car il est assigné à un appareil et/ou à des sessions. Veuillez supprimer ces assignations et réessayer.",
  "WorkerDetail-dateFirstUsage": "Première utilisation:",
  "WorkerDetail-dateLastUsage": "Dernière utilisation:",
  "WorkerDetail-deleteAlertTitle": "Supprimer le travailleur",
  "WorkerDetail-deleteAlertMessage": "Êtes-vous sûr de vouloir supprimer ce travailleur?",
  "WorkerDetail-deleteError": "Une erreur s'est produite pendant la tentative de suppression du travailleur",
  "WorkerDetail-deleteWorker": "Supprimer le travailleur",
  "WorkerDetail-deviceAssignment": "Assignation/Appareil",
  "WorkerDetail-deviceAssignmentError":
    "Une erreur s'est produite pendant la tentative de mise à jour de l'appareil assigné à ce travailleur, les autres détails ont été mis à jour avec succès",
  "WorkerDetail-deviceNoLongerHasWorker": "L'appareil n'est plus assigné à un travailleur",
  "WorkerDetail-disabledDevice": "L'appareil ne peut pas être modifié pour des travailleurs non inscrits",
  "WorkerDetail-drawerTitle": "Détails du travailleur",
  "WorkerDetail-empIdAlreadyExistsValidation": "Un travailleur avec cet ID d'employé existe déjà sur le site {siteName}",
  "WorkerDetail-employeeId": "ID de l'employé:",
  "WorkerDetail-enrolled": "Inscrit",
  "WorkerDetail-errorFetchingWorkers": "Erreur lors de la récupération des travailleurs",
  "WorkerDetail-exemptFromUsageTarget": "Exempté de la cible d'utilisation:",
  "WorkerDetail-firstName": "Prénom:",
  "WorkerDetail-fetchSessionsDataError": "Une erreur s'est produite pendant la récupération des données",
  "WorkerDetail-ifFirstNameRequireLastNameValidation": "Si Prénom est spécifié, Nom doit aussi être renseigné",
  "WorkerDetail-ifLastNameRequireFirstNameValidation": "Si Nom est spécifié, Prénom doit aussi être renseigné",
  "WorkerDetail-ifNoNameRequireEmpIdValidation": "Si Prénom et Nom sont omis, le numéro d'employé doit être spécifié",
  "WorkerDetail-lastName": "Nom:",
  "WorkerDetail-logReasonWorkerUnenrolling": "Veuillez indiquer le motif de désinscription de ce travailleur:",
  "WorkerDetail-name": "Nom:",
  "WorkerDetail-nameAlreadyExistsValidation": "Un travailleur portant ce nom existe déjà sur le site {siteName}",
  "WorkerDetail-noneOption": "Aucun",
  "WorkerDetail-preferredLanguage": "Choix de langue:",
  "WorkerDetail-reportGroups": "Groupes de rapports:",
  "WorkerDetail-reportGroupsNumber": "{quantity} de plus...",
  "WorkerDetail-status": "Statut:",
  "WorkerDetail-targetHoursStartDate": "Date de début des heures cibles:",
  "WorkerDetail-title": "Détails du travailleur",
  "WorkerDetail-totalHours": "Total des heures:",
  "WorkerDetail-totalLifts": "Total des soulèvements:",
  "WorkerDetail-unenrolled": "Non inscrit",
  "WorkerDetail-updateError": "Une erreur s'est produite pendant la tentative de mise à jour des données du travailleur",
  "WorkerDetail-usageTargetEnabled": "Activé",
  "WorkerDetail-usageTargetDisabled": "Désactivé",
  "WorkerDetail-workerProfile": "Profil du travailleur",
  "WorkerDetail-workerStatistics": "Statistiques/Travailleur",
  "WorkerDetail-workerStatus": "Statut du travailleur",
  "WorkerDetailOld-assignedDevice": "Appareil assigné:",
  "WorkerDetailOld-dateFirstUsage": "Première utilisation:",
  "WorkerDetailOld-dateLastUsage": "Dernière utilisation:",
  "WorkerDetailOld-employeeId": "ID de l'employé:",
  "WorkerDetailOld-firstName": "Prénom:",
  "WorkerDetailOld-lastName": "Nom:",
  "WorkerDetailOld-status": "Statut:",
  "WorkerDetailOld-totalHours": "Total des heures:",
  "WorkerDetailOld-totalLifts": "Total des soulèvements:",
  "WorkerDetailOld-workerStatistics": "Statistiques du travailleur",
  "WorkerDeviceAssignmentModal-changeDeviceError":
    "Une erreur s'est produite pendant la tentative de mise à jour des données du travailleur",
  "WorkerDeviceAssignmentModal-device": "Appareil:",
  "WorkerDeviceAssignmentModal-subtitle": "Sélectionnez un appareil pour **{workerName}**",
  "WorkerDeviceAssignmentModal-updateSuccessMessage": "Le travailleur a été mis à jour avec succès",
  "WorkerEditModal-assignedDevice": "Appareil:",
  "WorkerEditModal-changeStatusError": "Une erreur s'est produite pendant la tentative de modification du statut",
  "WorkerEditModal-employeeId": "ID de l'employé:",
  "WorkerEditModal-enrolled": "Inscrit",
  "WorkerEditModal-exemptFromUsageTarget": "Exempté de la cible d'utilisation:",
  "WorkerEditModal-firstName": "Prénom:",
  "WorkerEditModal-lastName": "Nom:",
  "WorkerEditModal-reason": "Motif:",
  "WorkerEditModal-status": "Statut:",
  "WorkerEditModal-targetHoursStartDate": "Date de début des heures cibles:",
  "WorkerEditModal-title": "Modifier le profil du travailleur",
  "WorkerEditModal-unenrolled": "Non inscrit",
  "WorkerEditModal-updateError": "Une erreur s'est produite pendant la tentative de mise à jour des données du travailleur",
  "WorkerEditModal-updateSuccessMessage": "Le travailleur a été mis à jour avec succès",
  "WorkerEditModal-workerReportGroups": "Groupes de rapports:",
  "WorkersFilters-allTagText": "Tous",
  "WorkersFilters-assignedTagText": "Assigné",
  "WorkersFilters-enrolledTagText": "Inscrit",
  "WorkersFilters-searchButtonText": "Rechercher de travailleurs",
  "WorkersFilters-unenrolledTagText": "Non inscrit",
  "WorkersFilters-waitlistTagText": "Liste d'attente",
  "WorkerForm-createError": "Une erreur s'est produite pendant la tentative de création des travailleurs",
  "WorkerForm-deviceInputLabel": "Appareil",
  "WorkerForm-employeeIdInputLabel": "ID de l'employé",
  "WorkerForm-exemptFromUsageTarget": "Exempté de la cible d'utilisation",
  "WorkerForm-firstNameInputLabel": "Prénom",
  "WorkerForm-lastNameInputLabel": "Nom",
  "WorkerForm-noneOption": "Aucun",
  "WorkerForm-preferredLanguageInputLabel": "Choix de langue",
  "WorkerForm-targetHoursStartDate": "Date de début des heures cibles",
  "WorkerForm-workerReportGroupsInputLabel": "Groupes de rapports",
  "WorkerNoDevice-assignErrorMessage": "Une erreur s'est produite pendant la tentative d'assignation du travailleur à l'appareil",
  "WorkerNoDevice-assignNewDevice": "Assigner un nouvel appareil",
  "WorkerNoDevice-assignNewDevice-placeholder": "Sélectionner",
  "WorkerNoDevice-assignNewDeviceSuccessMessage":
    "L'assignation de l'appareil {deviceTag} au travailleur {workerName} a été effectuée avec succès",
  "WorkerNoDevice-assignSuccessMessage": "Assigné avec succès",
  "WorkerNoDevice-device": "Appareil:",
  "WorkerNoDevice-firstSubtitle": "**{name}** n'a plus d'appareil assigné.",
  "WorkerNoDevice-leaveUnassigned": "Laissez Non attribué",
  "WorkerNoDevice-leaveUnassignedSuccessMessage": "Le travailleur {workerName} ne dispose pas d'un appareil assigné",
  "WorkerNoDevice-reason": "Motif:",
  "WorkerNoDevice-secondSubtitle": "Qu'aimeriez-vous faire de ce travailleur?",
  "WorkerNoDevice-title": "Statut du travailleur",
  "WorkerNoDevice-unenrollWorker": "Désinscrire un travailleur",
  "WorkerNoDevice-unenrollWorkerSuccessMessage": "Le travailleur {workerName} a été désinscrit avec succès",
  "WorkerNoDeviceOld-unenrollWorker": "Désinscrire un travailleur. Motif:",
  "WorkerPreferredLanguage-enUS": "Anglais – États Unis",
  "WorkerPreferredLanguage-esMX": "Espagnol – Mexique",
  "WorkerPreferredLanguage-frCA": "Français – Canada",
  "WorkerPreferredLanguage-htHT": "Créole – Haïti",
  "WorkerPreferredLanguage-none": "Aucun",
  "WorkerPreferredLanguage-ptBR": "Portugais – Brésil",
  "WorkerReportGroupCard-numberOfWorkers":
    "{numberOfWorkers} {numberOfWorkers, plural, =0 {travailleurs} =1 {travailleur} other {travailleurs}}",
  "WorkerReportGroupDetail-workerColumn": "Travailleur",
  "WorkerReportGroupDetail-departmentColumn": "Département",
  "WorkerReportGroupDetail-statusColumn": "Statut",
  "WorkerReportGroupDetail-allTagText": "Tous",
  "WorkerReportGroupDetail-enrolledTagText": "Inscrit",
  "WorkerReportGroupDetail-unenrolledTagText": "Non inscrit",
  "WorkerReportGroupDetail-csv": "{tag} Groupes de rapports des travailleurs {name}.csv",
  "WorkerReportGroupDetail-addWorkers": "Ajouter des travailleurs",
  "WorkerReportGroupDetail-allOrganizations": "Toutes les organisations",
  "WorkerReportGroupDetail-deleteAlertMessage": "Êtes-vous sûr de vouloir supprimer **{groupName}**?",
  "WorkerReportGroupDetail-deleteAlertTitle": "Supprimer le groupe",
  "WorkerReportGroupDetail-deleteError": "Le groupe de rapport du travailleur n'a pas pu être supprimé",
  "WorkerReportGroupDetail-deleteSuccess": "{groupName} a été supprimé avec succès",
  "WorkerGroupDetail-errorMessage": "Une erreur s'est produite pendant la tentative de chargement des données du travailleur",
  "WorkerReportGroupDetail-fetchDataError":
    "Une erreur s'est produite pendant la tentative d'obtention des données du groupe de rapport des travailleurs",
  "WorkerReportGroupDetail-removeWorkerAlertMessage": "Êtes-vous sûr de vouloir retirer le travailleur **{workerName}** de ce groupe?",
  "WorkerReportGroupDetail-removeWorkerError": "Le travailleur n'a pas pu être retiré du groupe",
  "WorkerReportGroupDetail-removeWorkerModalTitle": "Retirer le travailleur",
  "WorkerReportGroupDetail-removeWorkerSuccess": "Le travailleur {workerName} a été supprimé du groupe avec succès",
  "WorkerReportGroupDetail-rename": "Renommer",
  "WorkerReportGroupsDisplay-emptyStateText": "Aucun groupe créé pour le moment, cliquez sur Créer un nouveau groupe pour en ajouter un",
  "WorkerReportGroupsDisplay-fetchWorkerReportGroupsDataError":
    "Une erreur s'est produite pendant la tentative d'obtention des données des groupes de rapports des travailleurs",
  "WorkerReportGroupsDisplay-resultAltText": "Icône du groupe des utilisateurs",
  "WorkerReportGroupsTabContent-createNewGroupButton": "Créer un nouveau groupe",
  "WorkerReportGroupWorkersTable-department": "Département",
  "WorkerReportGroupWorkersTable-remove": "Supprimer",
  "WorkerReportGroupWorkersTable-status": "Statut",
  "WorkerReportGroupWorkersTable-worker": "Travailleur",
  "WorkersTabContent-allTagText": "Tous",
  "WorkersTabContent-allWorkersReportButtonText": "Rapport de tous les travailleurs",
  "WorkersTabContent-assignedTagText": "Assigné",
  "WorkersTabContent-createWorkersButtonText": "Créer des travailleurs",
  "WorkersTabContent-csv": "{tag} Travailleurs.csv",
  "WorkersTabContent-deleteSuccess": "Travailleur supprimé avec succès",
  "WorkersTabContent-deviceColumnTitle": "Appareil",
  "WorkersTabContent-employeeIDColumnTitle": "ID de l'employé",
  "WorkersTabContent-enrolledTagText": "Inscrit",
  "WorkersTabContent-fetchWorkersDataError": "Une erreur s'est produite pendant la tentative d'obtention des données des travailleurs",
  "WorkersTabContent-nameColumnTitle": "Nom",
  "WorkersTabContent-statusColumnTitle": "Statut",
  "WorkersTabContent-unenrolledTagText": "Non inscrit",
  "WorkersTabContent-waitlistTagText": "Liste d'attente",
};

const coreLocale: Partial<CoreLocale> = {};

CoreHelper.mergeWith(baseCoreLocale, CoreHelper.mergeWith(coreLocale, uiLocale));
