import {Locale} from "./Locale";
import {es_ES as baseCoreLocale} from "../../core/locales/es_ES";
import {Locale as CoreLocale} from "../../core/locales/Locale";
import {CoreHelper} from "../../core/utils/CoreHelper";

const uiLocale: Omit<Locale, keyof CoreLocale> = {
  "AboutUs-appVersion": "Versión de la aplicación: {version} ({currentCommitHash})",
  "AboutUs-backendVersion": "Versión backend: {version} ({currentCommitHash})",
  "AboutUs-copyright": "Derechos de autor ¬© {year}",
  "AboutUs-fetchError": "Se produjo un error al intentar obtener la versión backend",
  "AboutUs-subtitle": "Acerca de Verve Logic",
  "AccountSettings-department": "Departamento:",
  "AccountSettings-email": "Correo electrónico:",
  "AccountSettings-english": "Inglés",
  "AccountSettings-firstName": "Nombre:",
  "AccountSettings-french": "Francés",
  "AccountSettings-jobTitle": "Puesto:",
  "AccountSettings-lastName": "Apellido:",
  "AccountSettings-metricUnits": "Usar unidades métricas:",
  "AccountSettings-organization": "Organización:",
  "AccountSettings-organizationGlobal": "GLOBAL",
  "AccountSettings-phoneNumberError":
    "El número de teléfono debe comenzar con el signo + y luego contener solo dígitos (incluido el código del país)",
  "AccountSettings-preferredLanguage": "Idioma preferido:",
  "AccountSettings-profileData": "Datos de perfil",
  "AccountSettings-spanish": "Español",
  "AccountSettings-subscribed": "Suscribirse al correo electrónico del administrador:",
  "AccountSettings-title": "Configuraciones de la cuenta",
  "AccountSettings-updateError": "Se produjo un error al intentar obtener los datos de los usuarios",
  "AccountSettings-updateErrorPhoneNumber": "Número de teléfono no válido. Verifique e inténtelo nuevamente",
  "AccountSettings-workAddress": "Dirección del trabajo:",
  "AccountSettings-workPhone": "Teléfono del trabajo:",
  "ActiveInactiveSiteSummaryPage-activeWorkers": "Trabajadores activos",
  "ActiveInactiveSiteSummaryPage-daysActiveLast7DaysColumnTitle": "Días activos en los últimos 7 días",
  "ActiveInactiveSiteSummaryPage-daysSinceLastUsageColumnTitle": "Días desde el último uso",
  "ActiveInactiveSiteSummaryPage-deviceTagColumnTitle": "Etiqueta de dispositivo",
  "ActiveInactiveSiteSummaryPage-employeeIDTitle": "ID de empleado",
  "ActiveInactiveSiteSummaryPage-hoursLast7DaysColumnTitle": "Horas de los últimos 7 días",
  "ActiveInactiveSiteSummaryPage-inactiveWorkers": "Trabajadores inactivos",
  "ActiveInactiveSiteSummaryPage-kilogramsUnit": "kg",
  "ActiveInactiveSiteSummaryPage-nameColumnTitle": "Nombre",
  "ActiveInactiveSiteSummaryPage-poundsUnit": "lb",
  "ActiveInactiveSiteSummaryPage-relativeToTargetColumnTitle": "Relativo al objetivo",
  "ActiveInactiveSiteSummaryPage-safeliftScore": "Puntuación de Safelift",
  "ActiveInactiveSiteSummaryPage-weeksSinceFirstUseColumnTitle": "Días desde el primer uso",
  "ActiveInactiveSiteSummaryPage-weightOffloaded": "Peso descargado",
  "ActiveInactiveWorkersReportDropdown-WorkersActivityReport": "Informe de actividad del trabajador",
  "ActiveInactiveWorkersReportDropdown-activeWorkers": "Trabajadores activos",
  "ActiveInactiveWorkersReportDropdown-asPDF": "Como PDF",
  "ActiveInactiveWorkersReportDropdown-asXLSX": "Como XLSX",
  "ActiveInactiveWorkersReportDropdown-deviceTagColumnTitle": "Etiqueta de dispositivo",
  "ActiveInactiveWorkersReportDropdown-daysActiveLast7DaysColumnTitle": "Días activos en los últimos 7 días",
  "ActiveInactiveWorkersReportDropdown-daysSinceLastUsageColumnTitle": "Días desde el último uso",
  "ActiveInactiveWorkersReportDropdown-downloadData": "Descargar datos",
  "ActiveInactiveWorkersReportDropdown-employeeIDTitle": "ID de empleado",
  "ActiveInactiveWorkersReportDropdown-hoursLast7DaysColumnTitle": "Horas de los últimos 7 días",
  "ActiveInactiveWorkersReportDropdown-inactiveWorkers": "Trabajadores inactivos",
  "ActiveInactiveWorkersReportDropdown-nameColumnTitle": "Nombre",
  "ActiveInactiveWorkersReportDropdown-workerColumnTitle": "Trabajador",
  "ActiveInactiveWorkersReportDropdown-pageCount": "Página {currentPage} de {totalPages}",
  "ActiveInactiveWorkersReportDropdown-relativeToTargetColumnTitle": "Relativo al objetivo",
  "ActiveInactiveWorkersReportDropdown-weeksSinceFirstUseColumnTitle": "Días desde el primer uso",
  "AddDepartmentForm-title": "Agregar departamento",
  "AddOrganizationForm-title": "Agregar organización",
  "AddSiteForm-title": "Agregar sitio",
  "AddWorkersModal-errorMessage": "Se produjo un error al intentar crear los trabajadores",
  "AddWorkersModal-noWorkersText": "No se encontraron trabajadores",
  "AddWorkersModal-selectWorkersLabel": "Seleccione trabajadores para agregar a este grupo:",
  "AddWorkersModal-successMessage": "Los trabajadores seleccionados han sido agregados exitosamente",
  "AddWorkersModal-title": "Agregar trabajadores",
  "AddWorkersModal-workers": "Trabajadores:",
  "AllocateGateway-allocate": "Asignar",
  "AllocateGateway-allocateGatewayButton": "Asignar puerta de enlace",
  "AllocateGateway-allocateGatewayLabel": "Asignar puerta de enlace",
  "AllocateGateway-allocateGatewayModalMessage": "Elija el número de serie de la puerta de enlace que desea asignar a **{departmentName}**",
  "AllocateGateway-allocateGatewayModalTitle": "Asignar puerta de enlace",
  "AllocateGateway-allocateGatewayToDepartmentError": "No se pudo asignar la puerta de enlace",
  "AllocateGateway-gatewaySerialNumberLabel": "N.º de serie de la puerta de enlace:",
  "AllocateSelectedData-confirmation": "Confirmación",
  "AllocateSelectedData-dateRange": "Rango de fechas:",
  "AllocateSelectedData-finished": "Finalizado",
  "AllocateSelectedData-infoMeesage":
    "Las fechas son inclusivas. Los datos de las fechas de inicio y finalización se asignarán al trabajador seleccionado.",
  "AllocateSelectedData-inProgress": "En curso",
  "AllocateSelectedData-modalTitle": "Asignar datos seleccionados",
  "AllocateSelectedData-select": "Seleccione rango de fechas y trabajador",
  "AllocateSelectedData-selectEndDate": "Seleccione fecha de finalización",
  "AllocateSelectedData-selectText": "Seleccione el rango de fechas y el trabajador deseados para reasignar los datos del dispositivo",
  "AllocateSelectedData-selectStartDate": "Seleccione fecha de inicio",
  "AllocateSelectedData-selectWorker": "Seleccione trabajador",
  "AllocateSelectedData-singleDay": "Día único",
  "AllocateSelectedData-updateError": "Se produjo un error al intentar actualizar el trabajador asignado a la sesión",
  "AllocateSelectedData-updateSuccess": "Los datos del dispositivo {deviceTag} se reasignaron exitosamente al trabajador {workerName}.",
  "AllocateSelectedData-waiting": "Espera",
  "AllocateSelectedData-worker": "Trabajador:",
  "AllocateSelectedData-confirmationText":
    "Confirme que desea asignar todos los datos del dispositivo **{deviceTag}** desde **{startDate}** hasta **{endDate}** a **{workerName}**",
  "AllocationFailed-alreadyAllocatedToCurrentDepartmentError":
    "**{serialNumber}**: el dispositivo ya está asignado a **{siteName}** - **{departmentName}**",
  "AllocationFailed-alreadyAllocatedToOtherDepartmentError": "**{serialNumber}**: el dispositivo ya está asignado a otro departamento",
  "AllocationFailed-emptyRowsError": "Se detectaron filas con valores vacíos",
  "AllocationFailed-firstText": "Ningún dispositivo fue asignado a **{siteName} - {departmentName}**",
  "AllocationFailed-missingColumnError": "La columna {columnName} no se encontró en el archivo subido",
  "AllocationFailed-noRowsError": "No se encontraron números de serie en el archivo cargado",
  "AllocationFailed-secondText": "Ocurrieron los siguientes errores:",
  "AllocationFailed-serialNumberNotFoundError": "**{serialNumber}**: número de serie no encontrado",
  "AllocationFailed-subtitle": "Error en la asignación",
  "AllWorkersReportDrawer-contentMessage":
    "El informe de todos los trabajadores cubre un período de 7 días. Seleccione la fecha de finalización deseada para el informe.",
  "AllWorkersReportDrawer-drawerTitle": "Informe de todos los trabajadores",
  "AllWorkersReportDrawer-fileName": "Informe de todos los trabajadores",
  "Analytics-csvColumn-assignedWorkers": "Trabajadores asignados",
  "Analytics-csvColumn-activeWorkers": "Trabajadores activos",
  "Analytics-csvColumn-activeWorkersPercentage": "Porcentaje de trabajadores activos",
  "Analytics-csvColumn-endDate": "Fecha de finalización",
  "Analytics-csvColumn-endTime": "Hora de finalización",
  "Analytics-csvColumn-excessiveForwardLifts": "Levantamientos excesivos hacia adelante",
  "Analytics-csvColumn-hoursUsed": "Horas utilizadas",
  "Analytics-csvColumn-inactiveWorkers": "Trabajadores inactivos",
  "Analytics-csvColumn-inactiveWorkersPercentage": "Porcentaje de trabajadores inactivos",
  "Analytics-csvColumn-prolongedBendLifts": "Levantamientos con flexión prolongada",
  "Analytics-csvColumn-resourceID": "ID de recurso",
  "Analytics-csvColumn-resourceName": "Nombre del recurso",
  "Analytics-csvColumn-resourceType": "Tipo de recurso",
  "Analytics-csvColumn-riskyLifts": "Levantamientos de riesgo",
  "Analytics-csvColumn-riskyLiftsPercentage": "Porcentaje de levantamientos de riesgo",
  "Analytics-csvColumn-safeLifts": "Levantamientos seguros",
  "Analytics-csvColumn-safeLiftsPercentage": "Porcentaje de levantamientos seguros",
  "Analytics-csvColumn-sideBendLifts": "Levantamientos con  flexión lateral",
  "Analytics-csvColumn-startDate": "Fecha de inicio",
  "Analytics-csvColumn-startTime": "Hora de inicio",
  "Analytics-csvColumn-totalLifts": "Levantamientos totales",
  "Analytics-csvColumn-twistedLifts": "Levantamientos con torsión",
  "Analytics-csvColumn-weightOffloaded": "Peso descargado",
  "Analytics-fetchDataError": "Se produjo un error al obtener los datos",
  "Analytics-noOrgPlaceholderTitle": "Seleccione una organización en la parte superior de esta página para comenzar a ver la analítica",
  "AnalyticsChart-averageHoursUsed": "Horas promedio utilizadas por trabajador activo",
  "AnalyticsChart-averageOfLifts": "Levantamientos promedio por trabajador activo",
  "AnalyticsChart-datasetLabel-activeWorkers": "Activo",
  "AnalyticsChart-datasetLabel-excessiveForwardBending": "Flexión hacia adelante excesiva",
  "AnalyticsChart-datasetLabel-hoursUsed": "Horas utilizadas",
  "AnalyticsChart-datasetLabel-inactiveWorkers": "Inactivo",
  "AnalyticsChart-datasetLabel-prolongedBending": "Flexión prolongada",
  "AnalyticsChart-datasetLabel-risky": "De riesgo",
  "AnalyticsChart-datasetLabel-safe": "Seguro",
  "AnalyticsChart-datasetLabel-sideBending": "Flexión lateral",
  "AnalyticsChart-datasetLabel-target": "Objetivo",
  "AnalyticsChart-datasetLabel-twisting": "Torsión",
  "AnalyticsChart-dataTypeSelector-label": "Datos",
  "AnalyticsChart-dataTypeSelector-percentage": "Porcentaje",
  "AnalyticsChart-dataTypeSelector-raw": "Bruto",
  "AnalyticsChart-dayDaysInSuit": "Día {day}",
  "AnalyticsChart-errorResultMessage": "Error al recuperar datos. Actualice la página para volver a intentarlo",
  "AnalyticsChart-hoursUnit": "Horas utilizadas",
  "AnalyticsChart-hoursUsed": "Horas utilizadas",
  "AnalyticsChart-liftsUnit": "Levantamientos",
  "AnalyticsChart-monthDaysInSuit": "Mes {month}",
  "AnalyticsChart-movementBreakdown": "Desglose del movimiento",
  "AnalyticsChart-movementSelector": "Movimientos",
  "AnalyticsChart-percentageOfLifts": "% de levantamientos",
  "AnalyticsChart-percentageOfWorkers": "% de trabajadores",
  "AnalyticsChart-poundsUnit": "lb",
  "AnalyticsChart-quarterDaysInSuit": "Trimestre {quarter}",
  "AnalyticsChart-tooltip-totalLifts": "Total: {total} levantamientos",
  "AnalyticsChart-tooltip-totalWorkers": "Asignados: {assigned}  trabajadores",
  "AnalyticsChart-weekDaysInSuit": "Semana {week}",
  "AnalyticsChart-weightOffloaded": "Peso descargado",
  "AnalyticsChart-weightOffloadedAverageKgs": "Peso promedio descargado por trabajador activo (kg)",
  "AnalyticsChart-weightOffloadedAverageLbs": "Peso promedio descargado por trabajador activo (lb)",
  "AnalyticsChart-weightOffloadedUnitKgs": "Peso descargado (kg)",
  "AnalyticsChart-weightOffloadedUnitLbs": "Peso descargado (lb)",
  "AnalyticsChart-workersUnit": "Trabajadores",
  "AnalyticsDetails-activeWorkers": "Trabajadores Activos",
  "AnalyticsDetails-allWorkers": "Todos los trabajadores",
  "AnalyticsDetails-description": "Aquí hay un desglose detallado de los datos para el intervalo de **{day}**",
  "AnalyticsDetails-downloadDataButton": "Descargar Datos",
  "AnalyticsDetails-forwardColumn#": "Flexiones hacia Adelante #",
  "AnalyticsDetails-forwardColumn%": "Flexiones hacia Adelante %",
  "AnalyticsDetails-fullNameColumn": "Nombre Completo",
  "AnalyticsDetails-inactiveWorkers": "Trabajadores Inactivos",
  "AnalyticsDetails-isActive": "Activo",
  "AnalyticsDetails-movementBreakdown": "Desglose de Movimientos",
  "AnalyticsDetails-prolongedColumn#": "Flexiones Prolongadas #",
  "AnalyticsDetails-prolongedColumn%": "Flexiones Prolongadas %",
  "AnalyticsDetails-riskyColumn#": "Levantamientos Riesgosos #",
  "AnalyticsDetails-riskyColumn%": "Levantamientos Riesgosos %",
  "AnalyticsDetails-safeColumn#": "Levantamientos Seguros #",
  "AnalyticsDetails-safeColumn%": "Levantamientos Seguros %",
  "AnalyticsDetails-sideBendColumn#": "Flexiones Laterales #",
  "AnalyticsDetails-sideBendColumn%": "Flexiones Laterales %",
  "AnalyticsDetails-titleModal": "Detalles de Datos",
  "AnalyticsDetails-totalHours": "Horas Totales",
  "AnalyticsDetails-totalLiftsColumn": "Levantamientos Totales",
  "AnalyticsDetails-twistedColumn#": "Giros #",
  "AnalyticsDetails-twistedColumn%": "Giros %",
  "AnalyticsDetails-weightOffloaded": "Peso Descargado",
  "AnalyticsDetails-weightOffloadedColumn": "Peso Descargado Total",
  "AnalyticsSettings-startDate": "Desde la fecha:",
  "AnalyticsSettings-afterDate": "Después de la fecha",
  "AnalyticsSettings-afterSpecificDate": "Después de una fecha específica",
  "AnalyticsSettings-allWorkersLabel": "Todos los trabajadores",
  "AnalyticsSettings-applyFilters": "Aplicar configuración",
  "AnalyticsSettings-average": "Promedio",
  "AnalyticsSettings-beforeDate": "Antes de la fecha:",
  "AnalyticsSettings-beforeSpecificDate": "Antes de una fecha específica",
  "AnalyticsSettings-betweenDates": "Entre fechas",
  "AnalyticsSettings-calendarDate": "Días de calendario",
  "AnalyticsSettings-calendarDateDescription": "*Día/s de calendario: Datos del gráfico según la fecha y hora en que fueron registrados.",
  "AnalyticsSettings-checkboxDescription": "Seleccione los movimientos para mostrar en el gráfico.",
  "AnalyticsSettings-current": "Actual",
  "AnalyticsSettings-data": "Datos:",
  "AnalyticsSettings-dataFormat": "Formato de datos:",
  "AnalyticsSettings-dataScope": "Alcance de los datos:",
  "AnalyticsSettings-dataScopeTextDaysInTheSuit": "Filtrar según el primer día del trabajador en el traje.",
  "AnalyticsSettings-dataScopeDaysInTheSuit": "Mostrar trabajadores que comenzaron...",
  "AnalyticsSettings-dataScopeTimeOfDay": "Agregar datos registrados entre",
  "AnalyticsSettings-dataScopeCalendarDays": "Graficar todos los datos...",
  "AnalyticsSettings-dateRange": "Rango de fechas:",
  "AnalyticsSettings-dateRangeInfo":
    "El Rango de fechas y el Alcance de los datos seleccionan a los trabajadores que comenzaron en el intervalo entre estas fechas.",
  "AnalyticsSettings-day": "Día",
  "AnalyticsSettings-daysInSuit": "Días con el traje",
  "AnalyticsSettings-daysInSuitDescription":
    "*Días con traje: Grafica los datos según el número de días que los trabajadores han usado el traje.",
  "AnalyticsSettings-displayInGraph": "Mostrar en gráfico",
  "AnalyticsSettings-displayMaxData": "Mostrar datos máximos:",
  "AnalyticsSettings-displayMaxDataTooltip":
    "Representar gráficamente el alcance de los datos hasta en 30 intervalos (30 días o 30 semanas)",
  "AnalyticsSettings-displayTrendlines": "Mostrar líneas de tendencia:",
  "AnalyticsSettings-eightHour": "8 h",
  "AnalyticsSettings-emptyWorkerReportGroupsTooltip": "No se encontraron grupos de informes de trabajadores",
  "AnalyticsSettings-emptyWorkersTooltip": "No se encontraron trabajadores",
  "AnalyticsSettings-endDate": "Hasta la fecha:",
  "AnalyticsSettings-entitySelectorTooltip": "Puede seleccionar hasta 5 entidades",
  "AnalyticsSettings-excessiveForwardBending": "Flexión hacia adelante excesiva",
  "AnalyticsSettings-fifteenMinutes": "15 min",
  "AnalyticsSettings-filter": "Filtrar:",
  "AnalyticsSettings-fourHour": "4 h",
  "AnalyticsSettings-hoursMinutes": "Horas/minutos",
  "AnalyticsSettings-hoursUsed": "Horas utilizadas",
  "AnalyticsSettings-individualWorkerLabel": "Trabajador individual",
  "AnalyticsSettings-interval": "Intervalo:",
  "AnalyticsSettings-infoDateRange": "El gráfico está limitado a 100 intervalos o menos.",
  "AnalyticsSettings-intervalCount": "Cantidad de Intervalos:",
  "AnalyticsSettings-lifts": "Levantamientos",
  "AnalyticsSettings-loadingFilters": "Cargando filtros...",
  "AnalyticsSettings-minimumLifts": "Levantamientos mínimos:",
  "AnalyticsSettings-minimumLiftsTooltip": "No grafica intervalos con menos levantamientos que el número seleccionado",
  "AnalyticsSettings-modalTextAfterDate":
    "El gráfico no puede mostrar más de 100 intervalos. Para ver datos después de {maxDateToShow}, ajuste el rango de fechas seleccionado en Configurar gráfico.",
  "AnalyticsSettings-modalTextBeforeDate":
    "El gráfico no puede mostrar más de 100 intervalos. Para ver datos antes de {maxDateToShow}, ajuste el rango de fechas seleccionado en Configurar gráfico.",
  "AnalyticsSettings-month": "Mes",
  "AnalyticsSettings-mostRecent": "Más reciente",
  "AnalyticsSettings-movementBreakdown": "Desglose del movimiento",
  "AnalyticsSettings-movements": "Movimientos:",
  "AnalyticsSettings-oneHour": "1 h",
  "AnalyticsSettings-percentage": "Porcentaje",
  "AnalyticsSettings-percentOfTarget": "Porcentaje del objetivo",
  "AnalyticsSettings-prolongedBending": "Flexión prolongada",
  "AnalyticsSettings-quarter": "Trimestre",
  "AnalyticsSettings-raw": "Bruto",
  "AnalyticsSettings-rawSum": "Suma bruta",
  "AnalyticsSettings-realTimeDataInfo": "Los sitios sin trajes con reloj en tiempo real habilitado no admiten informes subdiarios.",
  "AnalyticsSettings-relativeTo": "Relativo a:",
  "AnalyticsSettings-risky": "De riesgo",
  "AnalyticsSettings-safe": "Seguro",
  "AnalyticsSettings-select": "Seleccione",
  "AnalyticsSettings-selectDate": "Seleccione fecha:",
  "AnalyticsSettings-selectEndDate": "Seleccione fecha de finalización",
  "AnalyticsSettings-selectFiltersText": "Seleccione el conjunto de filtros que desea aplicar al gráfico de analítica",
  "AnalyticsSettings-selectStartDate": "Seleccione fecha de inicio",
  "AnalyticsSettings-selectVariableText": "Seleccione la variable para graficar.",
  "AnalyticsSettings-selectVariableMinimumText": "Seleccione la variable para graficar y especifique los valores mínimos que se mostrarán.",
  "AnalyticsSettings-sideBending": "Flexión lateral",
  "AnalyticsSettings-thirtyMinutes": "30 min",
  "AnalyticsSettings-timeOfTheDay": "Hora del día",
  "AnalyticsSettings-timeOfTheDayDescription":
    "*Hora del día: Agrupa y grafica los datos según la hora en que fueron registrados, independientemente de la fecha (por ejemplo, todos los datos registrados a las 9 y 10 am).",
  "AnalyticsSettings-timezoneMessage": "Las fechas/horas están en la zona horaria *{timezone}*",
  "AnalyticsSettings-title": "Configurar Gráfica",
  "AnalyticsSettings-trendlineText": "Datos para la línea de tendencia desde {startDate} hasta {endDate}: **[y= m x+b]**",
  "AnalyticsSettings-twelveHour": "12 h",
  "AnalyticsSettings-twisting": "Torsión",
  "AnalyticsSettings-twoHour": "2 h",
  "AnalyticsSettings-variableToChart": "Variable al gráfico:",
  "AnalyticsSettings-week": "Semana",
  "AnalyticsSettings-weightOffloaded": "Peso descargado",
  "AnalyticsSettings-workerActiveStatus": "Estado activo del trabajador",
  "AnalyticsSettings-workerReportGroupsLabel": "Grupos de informes de trabajadores",
  "AnalyticsSettings-workers": "Trabajadores:",
  "AnalyticsSubheader-downloadDataButton": "Descargar datos",
  "AnalyticsSubheader-loadingOrganizations": "Cargando organizaciones...",
  "AnalyticsSubheader-selectOrganization": "Seleccione una organización...",
  "AnalyticsSubheader-title": "Analítica",
  "AnalyticsSubheader-titleSeparator": "/",
  "AnalyticsTabContent-activeWorkers": "Trabajadores activos",
  "AnalyticsTabContent-asCsv": "Como CSV",
  "AnalyticsTabContent-asImage": "Como imagen",
  "AnalyticsTabContent-averageMessage": "{unit} promedio por {interval}:  **{value}**",
  "AnalyticsTabContent-day": "Día",
  "AnalyticsTabContent-errorMessage": "Se produjo un error al obtener los datos",
  "AnalyticsTabContent-errorTimeoutMessage":
    "La solicitud ha expirado. Por favor, intenta modificar tu consulta para solicitar menos datos.",
  "AnalyticsTabContent-filtersConfiguration": "Configurar Gráfica",
  "AnalyticsTabContent-hideFiltersLabel": "Ocultar filtros",
  "AnalyticsTabContent-hoursUnit": "horas",
  "AnalyticsTabContent-liftsUnit": "Levantamientos",
  "AnalyticsTabContent-poundsUnit": "lb",
  "AnalyticsTabContent-kilogramsUnit": "kg",
  "AnalyticsTabContent-month": "Mes",
  "AnalyticsTabContent-quarter": "Trimestre",
  "AnalyticsTabContent-showAllFiltersLabel": "Mostrar todos los filtros",
  "AnalyticsTabContent-totalValueMessage": "Total {variable} por rango de fechas seleccionado: **{value} {unit}**",
  "AnalyticsTabContent-week": "Semana",
  "App-accountSettings": "Configuraciones de la cuenta",
  "App-deactivatedUserSubtitle1": "Su cuenta ha sido desactivada.",
  "App-deactivatedUserSubtitle2": "Si cree que esto se hizo por error, comuníquese con el administrador de su sitio.",
  "App-deactivatedUserTitle": "Cuenta desactivada",
  "App-deviceManagement": "Gestión de dispositivos",
  "App-fetchMeError": "Se produjo un error al obtener los datos del usuario",
  "App-internetError": "No hay conexión a Internet, actualice la página para volver a intentarlo",
  "App-logOut": "Cerrar sesión",
  "App-menuItemAboutUs": "Acerca de nosotros",
  "App-menuItemAdmin": "Admin",
  "App-menuItemAnalytics": "Analítica",
  "App-menuItemAssignment": "Asignación",
  "App-menuItemConfiguration": "Configuración",
  "App-menuItemDashboard": "Panel",
  "App-menuItemDiagnostics": "Diagnóstico",
  "App-menuItemHelpCenter": "Centro de ayuda",
  "App-userPendingApprovalMessage1": "El administrador de su sitio aún no ha aprobado su cuenta; este paso puede tardar uno o dos días.",
  "App-userPendingApprovalMessage2": "Si tiene alguna pregunta, envíe un correo electrónico al administrador del sitio.",
  "App-userPermissions": "Permisos de usuario",
  "BulkCreateDevicesModal-configuration": "Configuración",
  "BulkCreateDevicesModal-confirm": "Confirmar",
  "BulkCreateDevicesModal-confirmation": "Confirmación",
  "BulkCreateDevicesModal-errorMessage": "Se produjo un error al actualizar los dispositivos, inténtelo nuevamente más tarde",
  "BulkCreateDevicesModal-finished": "Finalizado",
  "BulkCreateDevicesModal-inProgress": "En curso",
  "BulkCreateDevicesModal-modalTitle": "Creación, configuración y asignación masiva de dispositivos",
  "BulkCreateDevicesModal-next": "Próximo",
  "BulkCreateDevicesModal-selectDevices": "Seleccionar dispositivos",
  "BulkCreateDevicesModal-successMessage": "La creación masiva de dispositivos se completó con éxito",
  "BulkCreateDevicesModal-waiting": "Espera",
  "BulkCreateStepThree-department": "Departamento:",
  "BulkCreateStepThree-loadingOrganizations": "Cargando organizaciones...",
  "BulkCreateStepThree-selectDepartment": "Seleccione un solo departamento para asignar dispositivos.",
  "BulkCreateStepThree-none": "Ninguno",
  "BulkCreateStepThree-selectOrganization": "Seleccione una organización...",
  "BulkCreateStepTwo-button1": "Botón 1:",
  "BulkCreateStepTwo-button2": "Botón 2:",
  "BulkCreateStepTwo-button3": "Botón 3:",
  "BulkCreateStepTwo-firmwareVersion": "Versión de firmware:",
  "BulkCreateStepTwo-groupCount": "[{count} dispositivos]:",
  "BulkCreateStepTwo-groupName": "Grupo {count}",
  "BulkCreateStepTwo-groupsMessage": "Hemos identificado los siguientes grupos de dispositivos según su tipo de dispositivo:",
  "BulkCreateStepTwo-noButtonSettings": "No se encontraron configuraciones de botones",
  "BulkCreateStepTwo-selectSettingsMessage": "Seleccione la configuración a la que desea actualizar el dispositivo:",
  "BulkCreateStepTwo-typeCount": "{name} [{count} Devices]",
  "BulkCreateStepOne-csvRadioText": "Cargar lista de SN mediante columnas de archivo CSV: número de serie del sistema, ID de Mcu",
  "BulkCreateStepOne-mcuIDColumnName": "Mcu ID",
  "BulkCreateStepOne-selectDevices": "Seleccione los SN del dispositivo para crear",
  "BulkCreateStepOne-serialNumberColumnName": "Número de serie del sistema",
  "BulkCreateStepOne-uploadFailDevicesTitle": "No todos los dispositivos en CSV están disponibles para crear.",
  "BulkCreateStepOne-uploadFailText": "El archivo CSV no está formateado correctamente, corríjalo e inténtelo de nuevo.",
  "BulkCreateStepOne-uploadFailTitle": "Error de carga",
  "BulkUpdateDevicesModal-configuration": "Configuración",
  "BulkUpdateDevicesModal-confirm": "Confirmar",
  "BulkUpdateDevicesModal-confirmation": "Confirmación",
  "BulkUpdateDevicesModal-errorMessage": "Se produjo un error al actualizar los dispositivos, inténtelo nuevamente más tarde",
  "BulkUpdateDevicesModal-finished": "Finalizado",
  "BulkUpdateDevicesModal-inProgress": "En curso",
  "BulkUpdateDevicesModal-modalTitle": "Dispositivos de actualización masiva",
  "BulkUpdateDevicesModal-next": "Próximo",
  "BulkUpdateDevicesModal-selectDevices": "Seleccionar dispositivos",
  "BulkUpdateDevicesModal-successMessage": "La actualización de la configuración del dispositivo se programó correctamente.",
  "BulkUpdateDevicesModal-waiting": "Espera",
  "BulkUpdateStepOne-allDevicesText": "Todos los dispositivos en una organización/sitio/departamento determinado",
  "BulkUpdateStepOne-allOrgs": "Todas las organizaciones",
  "BulkUpdateStepOne-devices": "Dispositivos:",
  "BulkUpdateStepOne-loadingOrganizations": "Cargando organizaciones...",
  "BulkUpdateStepOne-locations": "Ubicaciones:",
  "BulkUpdateStepOne-manualDevicesText":
    "Seleccione una organización/sitio/departamento y luego ingrese manualmente un subconjunto de SN para actualizar",
  "BulkUpdateStepOne-noDevicesText": "No hay dispositivos para este departamento.",
  "BulkUpdateStepOne-selectDevices": "Seleccione dispositivos para actualizar.",
  "BulkUpdateStepOne-selectOrganization": "Seleccione una organización...",
  "BulkUpdateStepThree-button1": "Botón 1:",
  "BulkUpdateStepThree-button2": "Botón 2:",
  "BulkUpdateStepThree-button3": "Botón 3:",
  "BulkUpdateStepThree-firmwareVersion": "Versión de firmware:",
  "BulkUpdateStepThree-groupCount": "[{count} dispositivos]:",
  "BulkUpdateStepThree-groupName": "Grupo {count}",
  "BulkUpdateStepThree-groupsMessage": "Hemos identificado los siguientes grupos de dispositivos según su tipo de dispositivo:",
  "BulkUpdateStepThree-noButtonSettings": "No se encontraron configuraciones de botones",
  "BulkUpdateStepThree-selectSettingsMessage": "Seleccione la configuración a la que desea actualizar el dispositivo:",
  "BulkUpdateStepThree-typeCount": "{name} [{count} Devices]",
  "BulkUpdateStepTwo-confirmUpdateAll": "Confirme que desea actualizar TODOS los dispositivos en las siguientes ubicaciones:",
  "ChangeDeviceModal-assignDevice": "Asignar dispositivo",
  "ChangeDeviceModal-assignDeviceMessage": "¿Está seguro de que desea asignar a **{workerName}** el dispositivo **{deviceTag}**?",
  "ChangeDeviceModal-assignDeviceSuccessMessage": "El dispositivo {deviceTag} se asignó correctamente a {workerName}",
  "ChangeDeviceModal-assignError": "Se produjo un error al intentar crear el trabajador",
  "ChangeDeviceModal-changeDevice": "Cambiar dispositivo",
  "ChangeDeviceModal-changeDeviceMessage":
    "¿Está seguro de que desea cambiar el dispositivo **{deviceTag}** a **{newDeviceTag}** para el trabajador **{workerName}**?",
  "ChangeDeviceModal-unassignDevice": "Cancelar la asignación de dispositivo",
  "ChangeDeviceModal-unassignDeviceMessage":
    "¿Está seguro de que desea cancelar la asignación del dispositivo **{deviceTag}** del trabajador **{workerName}**?",
  "ChangeWorkerModal-assignWorker": "Asignar trabajador",
  "ChangeDeviceStatusModal-archive": "Archivar:",
  "ChangeDeviceStatusModal-archiveError": "Se produjo un error al intentar archivar el dispositivo",
  "ChangeDeviceStatusModal-archived": "Archivado",
  "ChangeDeviceStatusModal-archivedTrueMessage":
    "Cambiar el dispositivo a **Archivado: verdadero** hará que el dispositivo sea visible sólo desde la sección Administración de dispositivos.",
  "ChangeDeviceStatusModal-changeStatusError": "Se produjo un error al intentar cambiar el estado",
  "ChangeDeviceStatusModal-descriptionToInService": "¿Está seguro de que desea cambiar el estado de **{deviceTag}** a **En servicio**?",
  "ChangeDeviceStatusModal-descriptionToOutService":
    "¿Está seguro de que desea cambiar el estado de **{deviceTag}** a **Fuera de servicio**?",
  "ChangeDeviceStatusModal-notArchived": "No archivado",
  "ChangeDeviceStatusModal-successMessageToInService": "El estado del dispositivo {deviceTag} se cambió correctamente a En servicio",
  "ChangeDeviceStatusModal-successMessageToOutService": "El estado del dispositivo {deviceTag} se cambió correctamente a Fuera de servicio",
  "ChangeDeviceStatusModal-successMessageToOutServiceAndArchived":
    "El estado del dispositivo {deviceTag} se cambió correctamente a Fuera de servicio y Archivado",
  "ChangeDeviceStatusModal-title": "Cambiar estado del dispositivo",
  "ChangeWorkerModal-assignWorkerMessage": "¿Está seguro de que desea asignar a **{workerName}** al dispositivo **{deviceTag}**?",
  "ChangeWorkerModal-assignWorkerSuccessMessage": "El trabajador {workerName} fue asignado exitosamente al dispositivo {deviceTag}",
  "ChangeWorkerModal-assignError": "Se produjo un error al intentar crear el dispositivo",
  "ChangeWorkerModal-changeWorker": "Cambiar de trabajador",
  "ChangeWorkerModal-changeWorkerMessage":
    "¿Está seguro de que desea cambiar el trabajador **{workerName}** a **{newWorkerName}** para el dispositivo **{deviceTag}**?",
  "ChangeWorkerModal-unassignWorker": "Cancelar la asignación de trabajador",
  "ChangeWorkerModal-unassignWorkerMessage": "¿Está seguro de que desea asignar a **{workerName}** al dispositivo **{deviceTag}**?",
  "ChangeWorkerStatusModal-changeStatusError": "Se produjo un error al intentar cambiar el estado",
  "ChangeWorkerStatusModal-descriptionEnroll": "¿Está seguro de que desea cambiar el estado del trabajador **{worker}** a **Inscrito**?",
  "ChangeWorkerStatusModal-descriptionUnenroll":
    "¿Está seguro de que desea cambiar el estado del trabajador **{worker}** a **No inscrito**?",
  "ChangeWorkerStatusModal-reasonsLabel": "Razón:",
  "ChangeWorkerStatusModal-successMessageEnrolled": "El estado del trabajador {worker} se cambió exitosamente a Inscrito",
  "ChangeWorkerStatusModal-successMessageUnenrolled": "El estado del trabajador {worker} se cambió exitosamente a No inscrito",
  "ChangeWorkerStatusModal-title": "Cambiar estado de trabajador",
  "ControllerSettings-heavyLiftHigh": "Levantamiento pesado alto",
  "ControllerSettings-heavyLiftLow": "Levantamiento pesado bajo",
  "ControllerSettings-heavyLiftMedium": "Levantamiento pesado medio",
  "ControllerSettings-squatLiftHigh": "Levantamiento de sentadillas alto",
  "ControllerSettings-squatLiftMedium": "Levantamiento de sentadillas medio",
  "ControllerSettings-squatLiftTransparent": "Levantamiento de sentadillas transparente",
  "ControllerSettings-universalLiftHigh": "Levantamiento universal alto",
  "ControllerSettings-universalLiftMedium": "Levantamiento universal medio",
  "ControllerSettings-universalLiftTransparent": "Levantamiento universal transparente",
  "ControllerSettings-universalLowerHigh": "Universal inferior alto",
  "ControllerSettings-universalLowerMedium": "Universal inferior medio",
  "ControllerSettings-universalLowerTransparent": "Universal inferior transparente",
  "ControllerSettings-floorAssembly70": "Ensamblaje de piso 70",
  "ControllerSettings-floorAssembly90": "Ensamblaje de piso 90",
  "ControllerSettings-floorAssembly110": "Ensamblaje de piso 110",
  "ControllerSettings-floorAssembly130": "Ensamblaje de piso 130",
  "ControllerSettings-floorAssembly150": "Ensamblaje de piso 150",
  "ControllerSettings-floorAssembly170": "Ensamblaje de piso 170",
  "ControllerSettings-floorAssembly190": "Ensamblaje de piso 190",
  "ControllerSettings-floorAssemblyTransparent": "Ensamblaje de piso transparente",
  "Common-accept": "Aceptar",
  "Common-add": "Agregar",
  "Common-addToFavorites": "Agregar a favoritos",
  "Common-and": "y",
  "Common-area": "Área",
  "Common-at": "en",
  "Common-back": "Volver",
  "Common-below": "Por debajo",
  "Common-between": "entre",
  "Common-cancel": "Cancelar",
  "Common-change": "Cambiar",
  "Common-close": "Cerrar",
  "Common-closeFormAlertMessage": "¿Está seguro de que desea descartar los cambios sin guardarlos?",
  "Common-closeFormAlertTitle": "Descartar los cambios",
  "Common-confirm": "Confirmar",
  "Common-confirmation": "Escriba **{confirmationValue}** para confirmar:",
  "Common-copyErrorCode": "Copiar código de error",
  "Common-create": "Crear",
  "Common-created": "Creado",
  "Common-createOptionsManually": "Manualmente",
  "Common-createOptionsUploadCSV": "Subir CSV",
  "Common-dateFormatCommonMonthDayYear": "MM-dd-yy",
  "Common-dateFormatMonthDay": "dd/MM",
  "Common-dateFormatMonthDayOnly": "MMM dd",
  "Common-dateFormatMonthDayYear": "MMM dd, aaaa",
  "Common-dateFormatMonthYear": "MMM aaaa",
  "Common-dateFormatQuarterYear": "QQQ aaaa",
  "Common-dateFormatTimeOnly": "hh:mm a",
  "Common-dateFormatWithTimezone": "d/M/aaaa h:mm a zz",
  "Common-dateFormatWithTimezoneNoTime": "d/M/aaaa",
  "Common-dateTimeFormatWithoutTimezone": "aaaa-MM-dd'T'H:mm:ss.SSS'Z'",
  "Common-dateTimeFormatWithoutYear": "MMM dd, hh:mm a",
  "Common-dayAbbreviation": "d",
  "Common-deallocateConfirmationValue": "cancelar asignación",
  "Common-delete": "Eliminar",
  "Common-deleteConfirmationValue": "Eliminar",
  "Common-description": "Descripción",
  "Common-discard": "Descartar",
  "Common-draggerText": "Haga clic o arrastre el archivo a esta área para cargarlo",
  "Common-duration": "Duración",
  "Common-edit": "Editar",
  "Common-email": "Correo electrónico",
  "Common-enrolledTooltip": "Inscrito",
  "Common-exempt": "Exento",
  "Common-exportToCsv": "Exportar a CSV",
  "Common-extension": "Extensión",
  "Common-false": "Falso",
  "Common-fieldRequiredMessage": "El campo de correo electrónico es obligatorio",
  "Common-fieldUrlFormatMessage": "Ingrese una URL válida",
  "Common-firstName": "Nombre",
  "Common-gatewayStatusDown": "Desconectado",
  "Common-gatewayStatusIntermittent": "Intermitente",
  "Common-gatewayStatusNeverSeen": "Nunca visto",
  "Common-gatewayStatusUp": "En línea",
  "Common-hourAbbreviation": "h",
  "Common-info": "Información",
  "Common-lastName": "Apellido",
  "Common-level": "Nivel",
  "Common-loadingText": "Cargando...",
  "Common-meets": "Cumple con",
  "Common-minuteAbbreviation": "m",
  "Common-name": "Nombre",
  "Common-next": "Próximo",
  "Common-no": "No",
  "Common-none": "Ninguno",
  "Common-overused": "Usado en exceso",
  "Common-remove": "Eliminar",
  "Common-removeConfirmationValue": "Eliminar",
  "Common-removeFromFavorites": "Quitar de favoritos",
  "Common-restart": "Reanudar",
  "Common-role": "Función",
  "Common-save": "Guardar",
  "Common-search": "Buscar",
  "Common-secondAbbreviation": "s",
  "Common-select": "Seleccionar",
  "Common-signal": "Señal",
  "Common-source": "Fuente",
  "Common-status": "Estado",
  "Common-title": "Título",
  "Common-to": "a",
  "Common-true": "Verdadero",
  "Common-tryAgain": "Intentar otra vez",
  "Common-typeHere": "Escriba aquí",
  "Common-unassignedLabel": "--",
  "Common-unenrolledTooltip": "No inscrito",
  "Common-unset": "Sin definir",
  "Common-warning": "Advertencia",
  "Common-yes": "Sí",
  "Configuration-configurationPath": "Configuración",
  "Configuration-organizationsPath": "Organizaciones",
  "ConnectedColumnRender-connected": "Conectado",
  "ConnectedColumnRender-neverSeen": "Nunca visto",
  "CreateDeviceModal-createDeviceError": "Se produjo un error al intentar crear el dispositivo",
  "CreateDeviceModal-createDeviceSuccess": "Dispositivo creado exitosamente",
  "CreateDeviceModal-deviceTagLabel": "Etiqueta de dispositivo",
  "CreateDeviceModal-deviceTagValidatorMessage": "La etiqueta del dispositivo debe incluir solo letras mayúsculas o números",
  "CreateDeviceModal-deviceTypeLabel": "Tipo de dispositivo",
  "CreateDeviceModal-duplicateDeviceError":
    "Ya existe en el sistema un dispositivo no archivado con el mismo número de serie del sistema o ID de MCU",
  "CreateDeviceModal-fetchDeviceTypesError": "Se produjo un error al intentar recuperar los tipos de dispositivos disponibles",
  "CreateDeviceModal-mcuIDLabel": "Mcu ID",
  "CreateDeviceModal-mcuIDValidatorMessage": "El ID de MCU debe ser un UUID válido",
  "CreateDeviceModal-modalTitle": "Crear dispositivo",
  "CreateDeviceModal-okButtonText": "Crear",
  "CreateDeviceModal-systemSerialNumberLabel": "El N.° de serie del sistema",
  "CreateDeviceModal-systemSerialNumberValidatorMessage":
    "El N.° de serie del sistema debe tener 13 caracteres y solo incluir números o letras mayúsculas",
  "CreateWorkersReportGroupModal-createError": "Se produjo un error al intentar crear el grupo de informes de trabajadores",
  "CreateWorkersReportGroupModal-successMessage": "Grupo de informes de trabajadores creado exitosamente",
  "CreateWorkerReportGroupModal-groupNameLabel": "Nombre del grupo:",
  "CreateWorkerReportGroupModal-nameAlreadyExistsValidation": "Ya existe un grupo con este nombre en el sitio {siteName}",
  "CreateWorkerReportGroupModal-nameLengthValidator": "Los nombres de los grupos están limitados a 40 caracteres. Reduzca el nombre.",
  "CreateWorkerReportGroupModal-noWorkersText": "No se encontraron trabajadores",
  "CreateWorkerReportGroupModal-selectWorkersLabel": "Seleccione trabajadores para agregar a este grupo:",
  "CreateWorkerReportGroupModal-title": "Crea grupo",
  "CreationFailed-duplicatedValuesError": "El archivo contiene valores duplicados",
  "CreationFailed-employeeIDAlreadyExistsError": "**{firstName} {lastName} ({employeeID})**: el ID de empleado ya existe en **{siteName}**",
  "CreationFailed-emptyRowsError": "Se detectaron filas con valores vacíos",
  "CreationFailed-firstText": "No se creó ningún trabajador para **{siteName}** - **{departmentName}**",
  "CreationFailed-missingColumnError":
    "Una o más de las columnas (Nombre, Apellido o ID de empleado) no se encontraron en el archivo cargado",
  "CreationFailed-nameAlreadyExistsError":
    "**{firstName} {lastName} ({employeeID})**: el nombre del trabajador ya existe en **{siteName}**",
  "CreationFailed-nameAlreadyExistsErrorWithoutID":
    "**{firstName} {lastName} ({employeeID})**: el nombre del trabajador ya existe en **{siteName}**",
  "CreationFailed-noRowsError": "No se encontraron números de serie en el archivo cargado",
  "CreationFailed-notEnoughInfoError": "**{firstName} {lastName} ({employeeID})**: perfil incompleto",
  "CreationFailed-secondText": "Ocurrieron los siguientes errores:",
  "CreationFailed-subtitle": "Error de creación",
  "CreationFailedOld-firstText": "No se creó ningún trabajador para **{siteName}** - **{departmentName}**",
  "CreationSuccessful-creationManyText": "{numberOfWorkers} trabajadores creados para **{siteName}** - **{departmentName}**",
  "CreationSuccessful-creationOneText": "1 trabajador creado para **{siteName}** - **{departmentName}**",
  "CreationSuccessful-title": "Creación exitosa",
  "CSVDeviceAllocation-columns": "Columnas: Número de serie del sistema",
  "CSVDeviceAllocation-description":
    "Cargue un archivo CSV con los números de serie del sistema que desea asignar a **{siteName}** - **{departmentName}**.",
  "CSVDeviceAllocation-firstSubtitle":
    "Cargue un archivo CSV con los números de serie del sistema que desea asignar a **{siteName}** - **{departmentName}**.",
  "CSVDeviceAllocation-secondSubtitle": "Columnas: Número de serie del sistema",
  "CSVDeviceAllocation-serialNumberColumnName": "Número de serie del sistema",
  "CSVWorkerCreation-employeeID": "ID de empleado",
  "CSVWorkerCreation-firstName": "Nombre",
  "CSVWorkerCreation-firstSubtitle": "Cargue un archivo CSV de los trabajadores que desea crear en **{siteName}** - **{departmentName}**.",
  "CSVWorkerCreation-lastName": "Apellido",
  "CSVWorkerCreation-secondSubtitle": "Columnas: Nombre, Apellido, ID de empleado",
  "Dashboard-activeLabel": "Activo",
  "Dashboard-dashboardPath": "Panel",
  "Dashboard-deviceStatusSubtitle": "Últimos 7 días",
  "Dashboard-deviceStatusTitle": "Estado del dispositivo",
  "Dashboard-fetchDataError": "Se produjo un error al intentar obtener los datos del panel",
  "Dashboard-inactiveLabel": "Inactivo",
  "Dashboard-outOfServiceLabel": "Fuera de servicio",
  "Dashboard-sitesListEndMessage": "No hay más sitios para mostrar",
  "Dashboard-totalUsageTitle": "Total de horas de uso",
  "Dashboard-unassignedLabel": "No asignado",
  "DashboardCardUsageChart-defaultTitle": "Total de horas de uso",
  "DashboardCardUsageChart-workerReportGroupsTitle": "Horas promedio por empleado con un dispositivo",
  "DashboardCardWrapper-errorMessage": "Se produjo un error al obtener los datos",
  "DashboardCardWrapper-errorResultMessage": "Error al recuperar datos. Actualice la página para volver a intentarlo",
  "DashboardChildrenCardsSection-workerReportGroups":
    "{numberOfGroups, plural, =1 {Grupo de Informes de Trabajadores} other {Grupos de Informes de Trabajadores}}",
  "DashboardDevicesListsDrawer-csv": "{status}.csv",
  "DashboardDevicesListsDrawer-deviceTagTitle": "Etiqueta de dispositivo",
  "DashboardDevicesListsDrawer-devicesCount": "{devices} dispositivos",
  "DashboardDevicesListsDrawer-devicesCountTitleAssigned": "Asignados:",
  "DashboardDevicesListsDrawer-devicesCountTitleAvailable": "Disponible:",
  "DashboardDevicesListsDrawer-devicesCountTitleOutOfService": "Fuera de servicio:",
  "DashboardDevicesListsDrawer-fetchDevicesError": "Se produjo un error al intentar obtener los datos del dispositivo",
  "DashboardDevicesListsDrawer-titleAssigned": "Dispositivos asignados:",
  "DashboardDevicesListsDrawer-titleAvailable": "Dispositivos disponibles",
  "DashboardDevicesListsDrawer-titleOutOfService": "Dispositivos fuera de servicio:",
  "DashboardDevicesListsDrawer-workerNameTitle": "Nombre del trabajador",
  "DashboardItem-chartsClickText": "Al hacer clic en los gráficos se accederá a las analíticas del sitio",
  "DashboardItem-errorResultMessage": "Error al recuperar los datos del sitio",
  "DashboardSiteDetails-fetchDataError": "Se produjo un error al intentar obtener los datos del sitio",
  "DashboardTabContent-activeWorkers": "Trabajadores activos",
  "DashboardTabContent-demoReport": "Informe de demostración",
  "DashboardTabContent-departments": "{numberOfDepartments, plural, =0 {Departments} =1 {Department} other {Departments}}",
  "DashboardTabContent-fetchWorkerReportGroupsDataError":
    "Se produjo un error al intentar obtener los datos de los grupos de informes de trabajadores",
  "DashboardTabContent-inactiveWorkers": "Trabajadores inactivos",
  "DashboardTabContent-sites": "{numberOfSites, plural, =0 {Sites} =1 {Site} other {Sites}}",
  "DashboardTabContent-siteSummaryReport": "Informe Resumido del Sitio",
  "DashboardTabContent-summaryReport": "Informe resumido",
  "DashboardDailyUsageBreakdownSection-dailyTotalRowTitle": "Total diario",
  "DashboardDailyUsageBreakdownSection-downloadCSV": "Descargar CSV",
  "DashboardDailyUsageBreakdownSection-endDateLabel": "Fecha de finalización de la semana",
  "DashboardDailyUsageBreakdownSection-title": "Desglose del uso diario",
  "DashboardDailyUsageBreakdownSection-weeklyTotal": "Total semanal",
  "DashboardDailyUsageBreakdownSection-worker": "Trabajador",
  "DashboardWorkersListsDrawer-csv": "{status}.csv",
  "DashboardWorkersListsDrawer-fetchWorkersError": "Se produjo un error al intentar obtener los datos de los trabajadores",
  "DashboardWorkersListsDrawer-titleActive": "Trabajadores activos",
  "DashboardWorkersListsDrawer-titleInactive": "Trabajadores inactivos",
  "DashboardWorkersListsDrawer-titleWaitlist": "Lista de espera de trabajadores",
  "DashboardWorkersListsDrawer-workerNameTitle": "Nombre del trabajador",
  "DashboardWorkersListsDrawer-workersCount": "{workers} trabajadores",
  "DashboardWorkersListsDrawer-workersCountTitleActive": "Activo:",
  "DashboardWorkersListsDrawer-workersCountTitleInactive": "Inactivo:",
  "DashboardWorkersListsDrawer-workersCountTitleWaitlist": "Lista de espera:",
  "DashboardWorkersSection-asPDF": "Como PDF",
  "DashboardWorkersSection-asXLSX": "Como XLSX",
  "DashboardWorkersTable-7DaysColumnTooltip": "Datos de {dateFrom} a {dateTo} (no incluye datos de hoy)",
  "DashboardWorkersTable-7HoursColumnTargetTooltip":
    "Los datos de utilización objetivo se basan en la configuración del departamento y suponen una semana laboral de 5 días.",
  "DashboardWorkersTable-7HoursColumnTooltip": "Datos de {dateFrom} a {dateTo} (no incluye datos de hoy)",
  "DashboardWorkersTable-activeWorkersTitle": "Lista de trabajadores activos",
  "DashboardWorkersTable-assignedDeviceColumnTitle": "Etiqueta de dispositivo",
  "DashboardWorkersTable-dataUploadedTodayColumnTitle": "Datos cargados hoy",
  "DashboardWorkersTable-daysActiveLast7DaysColumnTitle": "Días activos en los últimos 7 días",
  "DashboardWorkersTable-daysSinceLastUsage": "{days} días",
  "DashboardWorkersTable-daysSinceLastUsageColumnTitle": "Días desde el último uso",
  "DashboardWorkersTable-disabledDeviceColumnValue": "El dispositivo no se puede cambiar para trabajadores no inscritos",
  "DashboardWorkersTable-downloadData": "Descargar datos",
  "DashboardWorkersTable-employeeIDTitle": "ID de empleado",
  "DashboardWorkersTable-errorFetchingWorkers": "Error al buscar trabajadores",
  "DashboardWorkersTable-hoursLast7DaysColumnTitle": "Horas de los últimos 7 días",
  "DashboardWorkersTable-inactiveWorkersTitle": "Lista de trabajadores inactivos",
  "DashboardWorkersTable-nameColumnTitle": "Nombre",
  "DashboardWorkersTable-relativeToTargetColumnTitle": "Relativo al objetivo",
  "DashboardWorkersTable-statusColumnTitle": "Estado",
  "DashboardWorkersTable-targetDeviceUtilization": "Utilización del dispositivo objetivo",
  "DashboardWorkersTable-unassignedDeviceLabel": "--",
  "DashboardWorkersTable-weeksSinceFirstUseColumnTitle": "Días desde el primer uso",
  "DashboardWorkersTable-workerActiveToday": "Trabajador activo hoy",
  "DashboardWorkersTable-WorkersActivityReport": "InformeDeActividadDelTrabajador",
  "DashboardWorkersTable-workerColumnName": "Trabajador",
  "DashboardWorkersMetric-bottomFive": "Inferior a 5",
  "DashboardWorkersMetric-departmentAverage": "Promedio del departamento",
  "DashboardWorkersMetric-leaderboardReport": "Informe de la tabla de clasificación",
  "DashboardWorkersMetric-poundsUnit": "lb",
  "DashboardWorkersMetric-kilogramsUnit": "kg",
  "DashboardWorkersMetric-safeLiftScore": "Puntuación de Safelift",
  "DashboardWorkersMetric-topWorkers": "{qty} superior",
  "DashboardWorkersMetric-bottomWorkers": "Inferior a {qty}",
  "DashboardWorkersMetric-weightOffloaded": "Peso descargado",
  "DashboardWorkersMetric-workerMetrics": "Métricas del trabajador",
  "DatePicker-dateFilterCurrentMoth": "Mes actual",
  "DatePicker-dateFilterLastDays": "Últimos {daysDisplayed} días",
  "DatePicker-dateFilterToday": "Hoy",
  "DatePicker-dateFilterYesterday": "Ayer",
  "DateRange-dateFilterInputRequiredRule": "Debe seleccionar un rango de fechas",
  "DemoReportDrawer-contentMessage":
    "El informe de demostración cubre un período de 7 días. Seleccione la fecha de finalización deseada para el informe.",
  "DemoReportDrawer-drawerTitle": "Informe de demostración",
  "DemoReportDrawer-fileName": "Informe de demostración",
  "DepartmentDevicesTable-daysSinceLastUsedColumnTitle": "Visto por última vez",
  "DepartmentDevicesTable-deviceTagColumnTitle": "Etiqueta de dispositivo",
  "DepartmentDevicesTable-disabledWorkerColumnValue": "El trabajador no se puede cambiar para dispositivos fuera de servicio",
  "DepartmentDevicesTable-inServiceTooltip": "En servicio",
  "DepartmentDevicesTable-outOfServiceTooltip": "Fuera de servicio",
  "DepartmentDevicesTable-statusColumnTitle": "Estado",
  "DepartmentDevicesTable-workerColumnTitle": "Trabajador",
  "DepartmentDetail-allocateCSVButtonText": "Subir CSV",
  "DepartmentDetail-allocateManualButtonText": "Asignar",
  "DepartmentDetail-deleteAlertMessage": "¿Está seguro de que desea eliminar este departamento?",
  "DepartmentDetail-deleteAlertTitle": "Eliminar departamento",
  "DepartmentDetail-deleteError": "El departamento no se pudo eliminar",
  "DepartmentDetail-devices": "Dispositivos",
  "DepartmentDetail-differentSiteError": "Este departamento pertenece a otro sitio",
  "DepartmentDetail-fetchDataError": "Se produjo un error al intentar obtener los datos del departamento",
  "DepartmentDetail-notFoundError": "Departamento no encontrado",
  "DepartmentDetail-updateError": "Se produjo un error al intentar obtener los datos del departamento",
  "DepartmentDetail-workerCreationCSVButtonText": "Subir CSV",
  "DepartmentDetail-workerCreationManualButtonText": "Agregar",
  "DepartmentDetail-workers": "Trabajadores",
  "DepartmentForm-createError": "Se produjo un error al intentar crear el departamento",
  "DepartmentForm-nameInputLabel": "Nombre",
  "DepartmentGatewaysDetail-deallocate": "cancelar asignación",
  "DepartmentGatewaysDetail-deallocateAlertMessage":
    "¿Está seguro de que desea cancelar la asignación de la puerta de enlace **{gatewaySerialNumber}** del departamento **{departmentName}**?",
  "DepartmentGatewaysDetail-deallocateAlertTitle": "Cancelar asignación de puerta de enlace",
  "DepartmentGatewaysDetail-deallocateError": "No se pudo cancelar la asignación de la puerta de enlace",
  "DepartmentGatewaysDetail-fetchGatewaysError": "Se produjo un error al intentar obtener los datos de las puertas de enlace",
  "DepartmentGatewaysDetail-gatewayItemFirmware": "Firmware de puerta de enlace:",
  "DepartmentGatewaysDetail-gatewayItemLastTimeConnected": "Conectado por última vez:",
  "DepartmentGatewaysDetail-gatewayItemStatus": "Estado:",
  "DepartmentGatewaysDetail-gatewayStatusDefault": "Desconocido",
  "DepartmentGatewaysDetail-title": "Puerta de enlace",
  "DepartmentModal-createError": "Se produjo un error al intentar crear el departamento",
  "DepartmentModal-createTitle": "Crear departamento",
  "DepartmentModal-deleteButtonText": "Eliminar departamento",
  "DepartmentModal-deleteError": "El departamento no se pudo eliminar",
  "DepartmentModal-editTitle": "Editar departamento",
  "DepartmentModal-updateError": "Se produjo un error al intentar crear el departamento",
  "DepartmentsTable-devicesColumnTitle": "# dispositivos",
  "DepartmentsTable-nameColumnTitle": "Nombre del departamento",
  "DepartmentWorkersTable-assignedDeviceColumnTitle": "Dispositivo asignado",
  "DepartmentWorkersTable-daysSinceFirstUsageColumnTitle": "Días desde el primer uso",
  "DepartmentWorkersTable-daysSinceLastUsageColumnTitle": "Días desde el último uso",
  "DepartmentWorkersTable-deviceColumnTitle": "Dispositivo",
  "DepartmentWorkersTable-disabledDeviceColumnValue": "El dispositivo no se puede cambiar para trabajadores no inscritos",
  "DepartmentWorkersTable-employeeIDColumnTitle": "ID de empleado",
  "DepartmentWorkersTable-enrolledColumnTitle": "Inscrito",
  "DepartmentWorkersTable-enrolledFalseLabel": "No",
  "DepartmentWorkersTable-enrolledTrueLabel": "Sí",
  "DepartmentWorkersTable-naLabel": "N/A",
  "DepartmentWorkersTable-nameColumnTitle": "Nombre",
  "DepartmentWorkersTable-report": "Informe",
  "DepartmentWorkersTable-reportColumnTitle": "Informe",
  "DepartmentWorkersTable-statusColumnTitle": "Estado",
  "DepartmentWorkersTable-totalHoursColumnTitle": "Horas totales",
  "DepartmentWorkersTable-totalLiftsColumnTitle": "Levantamientos totales",
  "DepartmentWorkersTable-unassignedDeviceLabel": "--",
  "DepartmentWorkersTable-idColumnTitle": "Id",
  "Device-formattedConfigFirmwareVersionUnknown": "Desconocido",
  "DeviceAllocation-allocateButtonText": "Asignar",
  "DeviceAllocation-allocateDeviceToDepartmentError": "No se han podido asignar los dispositivos",
  "DeviceAllocation-allocationManySuccessfulText": "{numberOfDevices} dispositivos asignados a **{siteName} - {departmentName}**",
  "DeviceAllocation-allocationOneSuccessfulText": "1 dispositivo asignado a **{siteName} - {departmentName}**",
  "DeviceAllocation-allocationSuccessfulTitle": "Asignación exitosa",
  "DeviceAllocation-contentText": "Seleccione los números de serie que desea asignar a **{siteName}** - **{departmentName}**",
  "DeviceAllocation-noDevicesText": "No se encontraron dispositivos",
  "DeviceAllocation-title": "Asignación de dispositivo",
  "DeviceAllocationModal-allocateDeviceToDepartmentError": "No se han podido asignar los dispositivos",
  "DeviceAllocationModal-CVSDeviceAllocationButtonText": "Asignar",
  "DeviceAllocationModal-CSVDeviceAllocationSuccessfulMessage": "El archivo CSV se cargó correctamente",
  "DeviceAllocationModal-errorMessage": "Se produjo un error al intentar obtener los dispositivos disponibles",
  "DeviceAllocationModal-manualDeviceAllocationOkButtonText": "Asignar",
  "DeviceAllocationModal-manualDeviceAllocationSuccessfulMessage": "Dispositivos asignados correctamente a {department}.",
  "DeviceAllocationModal-title": "Asignar dispositivo",
  "DeviceDetail-assignedWorker": "Trabajador:",
  "DeviceDetail-available": "Disponible",
  "DeviceDetail-dateFirstUsed": "Usado por primera vez:",
  "DeviceDetail-dateLastConnected": "Última conexión:",
  "DeviceDetail-dateLastUsed": "Utilizado por última vez:",
  "DeviceDetail-deallocate": "cancelar asignación",
  "DeviceDetail-deallocateAlertMessage":
    "¿Eliminar **{deviceTag}** (n.º de serie **{serialNumber}**) de **{siteName} - {departmentName}**?",
  "DeviceDetail-deallocateAlertTitle": "Cancelación de asignación del dispositivo",
  "DeviceDetail-deallocateDevice": "Cancelar asignación de dispositivo",
  "DeviceDetail-deallocateDeviceMessage":
    "¿Está seguro de que desea eliminar **{deviceTag}** (número de serie del sistema {systemSerialNumber}) de {departmentName}?",
  "DeviceDetail-deallocateError": "Se produjo un error al intentar cancelar la asignación del dispositivo",
  "DeviceDetail-deallocateSuccessMessage": "Se canceló la asignación del dispositivo {deviceTag} exitosamente de {departmentName}.",
  "DeviceDetail-department": "Departamento:",
  "DeviceDetail-deviceProfile": "Perfil del dispositivo",
  "DeviceDetail-deviceStatistics": "Estadísticas del dispositivo",
  "DeviceDetail-drawerTitle": "Detalles del dispositivo",
  "DeviceDetail-firmware": "Firmware:",
  "DeviceDetail-inService": "En servicio",
  "DeviceDetail-none": "Ninguno",
  "DeviceDetail-organization": "Organización:",
  "DeviceDetail-outOfService": "Fuera de servicio",
  "DeviceDetail-rev": "Revisión:",
  "DeviceDetail-site": "Sitio:",
  "DeviceDetail-status": "Estado:",
  "DeviceDetail-systemSerialNumber": "El N.° de serie del sistema:",
  "DeviceDetail-tag": "Etiqueta de dispositivo:",
  "DeviceDetail-title": "Detalles del dispositivo",
  "DeviceDetail-totalHours": "Horas totales:",
  "DeviceDetail-totalLifts": "Levantamientos totales:",
  "DeviceDetail-updateError": "Se produjo un error al intentar obtener los datos del dispositivo",
  "DeviceDetail-workerNoLongerHasDevice": "El trabajador ya no tiene dispositivo",
  "DeviceDetailOld-assignedWorker": "Trabajador asignado:",
  "DeviceDetailOld-dateLastConnected": "Última conexión:",
  "DeviceDetailOld-deviceStatistics": "Estadísticas del dispositivo",
  "DeviceEditModal-archived": "Archivado:",
  "DeviceEditModal-archiveDeviceSuccess": "El dispositivo se archivó correctamente",
  "DeviceEditModal-archivedTrueMessage":
    "Cambiar el dispositivo a **Archivado: verdadero** hará que el dispositivo sea visible sólo desde la sección Administración de dispositivos.",
  "DeviceEditModal-inService": "En servicio",
  "DeviceEditModal-none": "Ninguno",
  "DeviceEditModal-outOfService": "Fuera de servicio",
  "DeviceEditModal-status": "Estado:",
  "DeviceEditModal-systemSerialNumber": "El N.° de serie del sistema:",
  "DeviceEditModal-title": "Editar perfil del dispositivo",
  "DeviceEditModal-updateDeviceSuccess": "Los detalles del dispositivo se actualizaron correctamente",
  "DeviceEditModal-updateError": "Se produjo un error al intentar obtener los datos del dispositivo",
  "DeviceEditModal-worker": "Trabajador:",
  "DeviceManagementDevices-bulkUpdateDevices": "Dispositivos de actualización masiva",
  "DeviceManagementDevices-createDeviceButtonText": "Crear dispositivo",
  "DeviceManagementDevices-deviceConfig": "Dispositivos",
  "DeviceManagementDevices-gateways": "Puertas de enlace",
  "DeviceManagementDevices-searchDevices": "Realizar una búsqueda para empezar a ver Dispositivos",
  "DeviceManagementDevices-searchGateways": "Realizar una búsqueda para empezar a ver Puertas de enlace",
  "DeviceManagementDevices-subheaderTitle": "Gestión de dispositivos",
  "DeviceManagementDevices-title": "Gestión de dispositivos",
  "DeviceManagementDevicesTable-activeConfig": "Configuración activa",
  "DeviceManagementDevicesTable-archivedColumnTitle": "Archivado",
  "DeviceManagementDevicesTable-archivedDate": "Fecha de Archivado:",
  "DeviceManagementDevicesTable-button1ColumnTitle": "Botón 1",
  "DeviceManagementDevicesTable-button2ColumnTitle": "Botón 2",
  "DeviceManagementDevicesTable-button3ColumnTitle": "Botón 3:",
  "DeviceManagementDevicesTable-firmwareRevColumnTitle": "Revisión de firmware",
  "DeviceManagementDevicesTable-firmwareRevColumnTooltip":
    "Solo se muestran las versiones de firmware presentes en la organización/sitio/departamento actual",
  "DeviceManagementDevicesTable-firstDateUsed": "Primera Fecha de Uso:",
  "DeviceManagementDevicesTable-hardwareRevColumnTitle": "Revisión de hardware",
  "DeviceManagementDevicesTable-hardwareRevColumnTooltip":
    "Solo muestra los tipos de dispositivos presentes en la organización/sitio/departamento actual",
  "DeviceManagementDevicesTable-location": "Ubicación:",
  "DeviceManagementDevicesTable-pendingUpdate": "Actualización pendiente",
  "DeviceManagementDevicesTable-updateConfig": "Actualizar configuración",
  "DeviceManagementDevicesTable-updateConfigColumnTitle": "Actualizar configuración",
  "DeviceManagementSearchBar-deviceTag": "Etiqueta de dispositivo:",
  "DeviceManagementSearchBar-deviceTagPlaceholder": "Buscar por etiqueta de dispositivo",
  "DeviceManagementSearchBar-filter": "Filtrar:",
  "DeviceManagementSearchBar-loadingOrganizations": "Cargando organizaciones...",
  "DeviceManagementSearchBar-searchButtonTooltip": "Utilice al menos uno de los filtros antes de realizar una búsqueda",
  "DeviceManagementSearchBar-selectOrganization": "Seleccione una organización...",
  "DeviceManagementSearchBar-serialNumber": "Número de serie:",
  "DeviceManagementSearchBar-serialNumberPlaceholder": "Buscar por número de serie",
  "DeviceManagementSearchBar-unallocated": "No asignado",
  "DeviceManagementStats-connected": "Conectado: {connectedDevices}",
  "DeviceManagementStats-connectedColumnTitle": "Visto por última vez",
  "DeviceManagementStats-csvDevices": "dispositivos - {entityName}.csv",
  "DeviceManagementStats-csvGateways": "Puertas de enlace - {entityName}.csv",
  "DeviceManagementStats-currentSoftwareColumnTitle": "Software actual",
  "DeviceManagementStats-deviceConnectionStatusLabel": "Estado de conexión del dispositivo:",
  "DeviceManagementStats-disconnected": "Desconectado: {disconnectedDevices}",
  "DeviceManagementStats-down": "Abajo: {downGateways}",
  "DeviceManagementStats-downColumn": "Abajo",
  "DeviceManagementStats-failed": "Error",
  "DeviceManagementStats-firmwareColumnTitle": "Firmware",
  "DeviceManagementStats-forceUpdatePending": "Forzar actualización pendiente",
  "DeviceManagementStats-includeArchivedLabel": "Incluir archivado",
  "DeviceManagementStats-intermittent": "Intermitente: {intermittentGateways}",
  "DeviceManagementStats-intermittentColumn": "Intermitente",
  "DeviceManagementStats-latestSoftware": "Software más reciente: {latestVersionGateway}",
  "DeviceManagementStats-matchTarget": "El software coincide con el objetivo",
  "DeviceManagementStats-neverSeen": "Nunca visto: {neverSeenDevices}",
  "DeviceManagementStats-neverSeenColumn": "Nunca visto",
  "DeviceManagementStats-pendingConfigurationUpdatesLabel": "Actualizaciones de configuración pendientes:",
  "DeviceManagementStats-scheduleUpdatePending": "Programar actualización pendiente",
  "DeviceManagementStats-serialNumberColumnTitle": "N.º de serie de la puerta de enlace",
  "DeviceManagementStats-systemSerialNumberColumnTitle": "El N.° de serie del sistema",
  "DeviceManagementStats-totalDevicesLabel": "Dispositivos totales:",
  "DeviceManagementStats-totalGatewaysLabel": "Total de puertas de enlace:",
  "DeviceManagementStats-up": "Arriba: {upGateways}",
  "DeviceManagementStats-upColumn": "Arriba",
  "DeviceManagementStats-updateStatusColumnTitle": "Estado de actualización",
  "DeviceNoWorker-assignErrorMessage": "Se produjo un error al intentar asignar el dispositivo al trabajador",
  "DeviceNoWorker-assignNewWorker": "Asignar dispositivo a un nuevo trabajador",
  "DeviceNoWorker-assignNewWorker-placeholder": "Seleccione trabajador",
  "DeviceNoWorker-assignSuccessMessage": "El dispositivo {deviceTag} se asignó correctamente a {workerName}",
  "DeviceNoWorker-changeStatusErrorMessage": "Se produjo un error al intentar obtener los datos del dispositivo",
  "DeviceNoWorker-changeStatusOutOfService": "Cambiar estado a 'Fuera de servicio'",
  "DeviceNoWorker-changeStatusSuccessMessage": "El estado del dispositivo {deviceTag} se cambió correctamente a Fuera de servicio",
  "DeviceNoWorker-firstSubtitle": "**{name}** ya no tiene un trabajador asignado.",
  "DeviceNoWorker-leaveUnassigned": "Dejar sin asignar",
  "DeviceNoWorker-leaveUnassignedSuccessMessage": "El estado del dispositivo {deviceTag} se cambió exitosamente a No asignado",
  "DeviceNoWorker-secondSubtitle": "¿Qué te gustaría hacer con este dispositivo?",
  "DeviceNoWorker-title": "Estado del dispositivo",
  "DeviceNoWorker-worker": "Trabajador:",
  "DevicesTabContent-allocateDeviceButton": "Asignar dispositivo",
  "DevicesTabContent-allTagText": "Todo",
  "DevicesTabContent-assignedTagText": "Asignados",
  "DevicesTabContent-availableTagText": "Disponible",
  "DevicesTabContent-connected": "Conectado",
  "DevicesTabContent-connectedTagText": "Conectado",
  "DevicesTabContent-csv": "{tag} Dispositivos.csv",
  "DevicesTabContent-deviceTag": "Etiqueta de dispositivo",
  "DevicesTabContent-deviceUsageReassignmentButton": "Reasignación de uso del dispositivo",
  "DevicesTabContent-disconnected": "Desconectado",
  "DevicesTabContent-disconnectedTagText": "Desconectado",
  "DevicesTabContent-inService": "En servicio",
  "DevicesTabContent-lastSeen": "Visto por última vez",
  "DevicesTabContent-neverSeen": "Nunca visto",
  "DevicesTabContent-outOfService": "Fuera de servicio",
  "DevicesTabContent-outOfServiceTagText": "Fuera de servicio",
  "DevicesTabContent-searchButtonText": "Buscar etiqueta de dispositivo",
  "DevicesTabContent-status": "Estado",
  "DevicesTabContent-worker": "Trabajador",
  "DeviceLiveCheckoutFilters-allTagText": "Todo",
  "DeviceLiveCheckoutFilters-bottomDisclaimer": "*Solo se muestran dispositivos en servicio",
  "DeviceLiveCheckoutFilters-checkedOutRecentlyDescription":
    "Desconectado en las últimas 12 horas o datos registrados en las últimas 12 horas",
  "DeviceLiveCheckoutFilters-checkedOutRecentlyTagText": "Controlado recientemente",
  "DeviceLiveCheckoutFilters-idleDescription": "Desconectado por más de 12 horas",
  "DeviceLiveCheckoutFilters-idleTagText": "Inactivo",
  "DeviceLiveCheckoutFilters-notCheckedOutDescription": "Actualmente conectado y no se han registrado datos en las últimas 12 horas",
  "DeviceLiveCheckoutFilters-notCheckedOutTagText": "No verificado",
  "DeviceLiveCheckoutFilters-searchButtonText": "Buscar por trabajador o etiqueta de dispositivo",
  "DeviceLiveCheckoutFilters-seeOnlyAssigned": "Ver solo dispositivos asignados",
  "DeviceLiveCheckoutTab-checkedOutColumn": "Controlado",
  "DeviceLiveCheckoutTab-checkedOutRecentlyTagText": "Controlado recientemente",
  "DeviceLiveCheckoutTab-connected": "Conectado",
  "DeviceLiveCheckoutTab-csv": "{tag} Dispositivos.csv",
  "DeviceLiveCheckoutTab-dataUploadedColumn": "Datos cargados hoy",
  "DeviceLiveCheckoutTab-deviceColumn": "Etiqueta de dispositivo",
  "DeviceLiveCheckoutTab-idleTagText": "Inactivo",
  "DeviceLiveCheckoutTab-lastSeenColumn": "Visto por última vez",
  "DeviceLiveCheckoutTab-neverSeen": "Nunca visto",
  "DeviceLiveCheckoutTab-notCheckedOutTagText": "No verificado",
  "DeviceLiveCheckoutTab-workerColumn": "Trabajador",
  "DeviceUsageReassignment-assignedDataSwitch": "Ver también datos asignados",
  "DeviceUsageReassignment-deviceColumn": "Dispositivos",
  "DeviceUsageReassignment-endDate": "Fecha de finalización de la semana",
  "DeviceUsageReassignment-errorFetchingWorkers": "Error al buscar trabajadores",
  "DeviceUsageReassignment-errorMessage": "Se produjo un error al intentar obtener los datos del dispositivo",
  "DeviceUsageReassignment-title": "Reasignación de uso del dispositivo",
  "DeviceUsageReassignment-worker": "Trabajador:",
  "DeviceUsageReassignment-location": "Ubicación",
  "DeviceUsageReassignment-unallocated": "No asignado",
  "DeviceUsageReassignment-totalUnallocated": "Total no asignado",
  "DeviceUsageReassignment-loadingOrganizations": "Cargando organizaciones...",
  "DeviceUsageReassignment-selectOrganization": "Seleccione una organización...",
  "LiveCheckedOutTabContent-allTagText": "Todo",
  "LiveCheckedOutTabContent-allocateDeviceButton": "Asignar dispositivo",
  "LiveCheckedOutTabContent-assignedTagText": "Asignados",
  "LiveCheckedOutTabContent-availableTagText": "Disponible",
  "LiveCheckedOutTabContent-connectedTagText": "Conectado",
  "LiveCheckedOutTabContent-disconnectedTagText": "Desconectado",
  "LiveCheckedOutTabContent-outOfServiceTagText": "Fuera de servicio",
  "LiveCheckedOutTabContent-searchButtonText": "Buscar etiqueta de dispositivo",
  "DevicesTable-assignedWorkerColumnTitle": "Trabajador asignado",
  "DevicesTable-assignedWorkerFilter-assigned": "Asignados",
  "DevicesTable-assignedWorkerFilter-available": "Disponible",
  "DevicesTable-assignedWorkerFilter-outOfService": "Fuera de servicio",
  "DevicesTable-connectedColumnTitle": "Visto por última vez",
  "DevicesTable-connectedLabel": "Conectado",
  "DevicesTable-daysSinceFirstUsedColumnTitle": "Usado por primera vez",
  "DevicesTable-daysSinceLastUsedColumnTitle": "Utilizado por última vez",
  "DevicesTable-departmentColumnTitle": "Departamento",
  "DevicesTable-deviceTagColumnTitle": "Etiqueta de dispositivo",
  "DevicesTable-disconnectedLabel": "Desconectado",
  "DevicesTable-firmwareColumnTitle": "Firmware",
  "DevicesTable-neverSeenLabel": "Nunca visto",
  "DevicesTable-organizationColumnTitle": "Organización",
  "DevicesTable-revColumnTitle": "Revisión",
  "DevicesTable-siteColumnTitle": "Sitio",
  "DevicesTable-systemSerialNumberColumnTitle": "El N.° de serie del sistema",
  "DevicesTable-totalHoursColumnTitle": "Horas totales",
  "DevicesTable-totalLiftsColumnTitle": "Levantamientos totales",
  "DeviceStatusChart-activeDevices": "Activo",
  "DeviceStatusChart-allocatedDevices": "Asignado",
  "DeviceStatusChart-inactiveDevices": "Inactivo",
  "DeviceStatusChart-noDevices": "No hay dispositivos para mostrar",
  "DeviceStatusChart-outOfServiceDevices": "Fuera de servicio",
  "DeviceStatusChart-unassignedDevices": "No asignado",
  "DeviceStatusChartOld-activeDevices": "Dispositivos activos",
  "DeviceStatusChartOld-allocatedDevices": "Dispositivos asignados",
  "DeviceStatusChartOld-inactiveDevices": "Dispositivos inactivos",
  "DeviceStatusChartOld-noDevices": "No hay dispositivos para mostrar",
  "DeviceStatusChartOld-outOfServiceDevices": "Dispositivos fuera de servicio:",
  "DeviceStatusChartOld-unassignedDevices": "Dispositivos no asignados:",
  "DeviceManagementDeviceDetail-archivedAt": "Archivado en:",
  "DeviceManagementDeviceDetail-assignedWorker": "Trabajador asignado:",
  "DeviceManagementDeviceDetail-button1": "Botón 1:",
  "DeviceManagementDeviceDetail-button2": "Botón 2:",
  "DeviceManagementDeviceDetail-button3": "Botón 3:",
  "DeviceManagementDeviceDetail-configurationTitle": "Configuración",
  "DeviceManagementDeviceDetail-diagnosticsPath": "Gestión de dispositivos",
  "DeviceManagementDeviceDetail-ConnectedStatus-connected": "Conectado",
  "DeviceManagementDeviceDetail-ConnectedStatus-disconnected": "Desconectado",
  "DeviceManagementDeviceDetail-ConnectedStatus-neverSeen": "Nunca visto",
  "DeviceManagementDeviceDetail-connectionStatus": "Estado de conexión:",
  "DeviceManagementDeviceDetail-customerInfoTitle": "Información del cliente",
  "DeviceManagementDeviceDetail-dateFirstUsed": "Usado por primera vez:",
  "DeviceManagementDeviceDetail-dateLastConnected": "Última conexión:",
  "DeviceManagementDeviceDetail-dateLastUsed": "Utilizado por última vez:",
  "DeviceManagementDeviceDetail-department": "Departamento:",
  "DeviceManagementDeviceDetail-deviceDetailsTitle": "Detalles",
  "DeviceManagementDeviceDetail-deviceStatisticsTitle": "Estadísticas",
  "DeviceManagementDeviceDetail-deviceTag": "Etiqueta de dispositivo:",
  "DeviceManagementDeviceDetail-firmwareRev": "Revisión de firmware:",
  "DeviceManagementDeviceDetail-hardwareRev": "Revisión de hardware:",
  "DeviceManagementDeviceDetail-inService": "En servicio",
  "DeviceManagementDeviceDetail-mcuId": "MCU ID:",
  "DeviceManagementDeviceDetail-organization": "Organización:",
  "DeviceManagementDeviceDetail-outOfService": "Fuera de servicio",
  "DeviceManagementDeviceDetail-pendingUpdateTooltip": "Actualización pendiente: Mostrando detalles de configuración pendientes.",
  "DeviceManagementDeviceDetail-revision": "Revisión:",
  "DeviceManagementDeviceDetail-sessions": "Sesiones",
  "DeviceManagementDeviceDetail-site": "Sitio:",
  "DeviceManagementDeviceDetail-status": "Estado:",
  "DeviceManagementDeviceDetail-systemSerialNumber": "El N.° de serie del sistema:",
  "DeviceManagementDeviceDetail-title": "Dispositivo {systemSerialNumber}",
  "DeviceManagementDeviceDetail-totalHours": "Horas totales:",
  "DeviceManagementDeviceDetail-totalLifts": "Levantamientos totales:",
  "DeviceManagementDeviceDetail-updatePending": "Actualización pendiente:",
  "DeviceManagementDeviceDetail-workerErrorMessage": "Se produjo un error al intentar cargar los datos del trabajador",
  "DownloadAllData-columnNameActiveWorkers": "Trabajadores activos",
  "DownloadAllData-columnNameActiveWorkersPercentage": "Porcentaje de trabajadores activos",
  "DownloadAllData-columnNameAssignedWorkers": "Trabajadores asignados",
  "DownloadAllData-columnNameEndDate": "Fecha de finalización",
  "DownloadAllData-columnNameEndTime": "Hora de finalización",
  "DownloadAllData-columnNameEnrolledWorkers": "Trabajadores inscritos",
  "DownloadAllData-columnNameExcessiveForwardLifts": "Levantamientos excesivos hacia adelante",
  "DownloadAllData-columnNameInactiveWorkers": "Trabajadores inactivos",
  "DownloadAllData-columnNameInactiveWorkersPercentage": "Porcentaje de trabajadores inactivos",
  "DownloadAllData-columnNameProlongedBendLifts": "Levantamientos con flexión prolongada",
  "DownloadAllData-columnNameResourceId": "ID de recurso",
  "DownloadAllData-columnNameResourceName": "Nombre del recurso",
  "DownloadAllData-columnNameResourceType": "Tipo de recurso",
  "DownloadAllData-columnNameRiskyLifts": "Levantamientos de riesgo",
  "DownloadAllData-columnNameRiskyLiftsPercentage": "Porcentaje de levantamientos de riesgo",
  "DownloadAllData-columnNameSafeLifts": "Levantamientos seguros",
  "DownloadAllData-columnNameSafeLiftsPercentage": "Porcentaje de levantamientos seguros",
  "DownloadAllData-columnNameSideBendLifts": "Levantamientos con  flexión lateral",
  "DownloadAllData-columnNameStartDate": "Fecha de inicio",
  "DownloadAllData-columnNameStartTime": "Hora de inicio",
  "DownloadAllData-columnNameTimeZone": "Zona horaria",
  "DownloadAllData-columnNameTotalLifts": "Levantamientos totales",
  "DownloadAllData-columnNameTwistedLifts": "Levantamientos con torsión",
  "DownloadAllData-columnNameHoursUsed": "Horas utilizadas",
  "DownloadAllData-columnNameWeightOffloaded": "Peso descargado",
  "DownloadAllData-dateRange": "Rango de fechas",
  "DownloadAllData-department": "Departamento",
  "DownloadAllData-download": "Descargar",
  "DownloadAllData-interval": "Intervalo",
  "DownloadAllData-organization": "Organización",
  "DownloadAllData-reportName": "Informe de todos los datos",
  "DownloadAllData-scope": "Alcance",
  "DownloadAllData-site": "Sitio",
  "EditableDevice-fetchDevicesError": "Se produjo un error al intentar obtener los dispositivos disponibles",
  "EditableWorker-fetchWorkersError": "Se produjo un error al intentar obtener los trabajadores disponibles",
  "EditableWorker-none": "Ninguno",
  "EditCustomPermissionsModal-admin": "Admin",
  "EditCustomPermissionsModal-errorOrganizationMessage": "Se produjo un error al intentar obtener la organización del usuario.",
  "EditCustomPermissionsModal-infoMessage": "Todas las filas deben tener una selección para poder guardar la configuración.",
  "EditCustomPermissionsModal-manager": "Gerente",
  "EditCustomPermissionsModal-none": "Ninguno",
  "EditCustomPermissionsModal-readonly": "Solo lectura",
  "EditCustomPermissionsModal-subtitle":
    "Primero, seleccione la organización, luego los sitios y, por último, las configuraciones de los departamentos.",
  "EditCustomPermissionsModal-title": "Editar permisos personalizados",
  "EditSessionAssignment-newWorker": "Nuevo trabajador:",
  "EditSessionAssignment-none": "Ninguno",
  "EditSessionAssignment-previousWorker": "Trabajador anterior:",
  "EditSessionAssignment-sessionID": "ID de sesión:",
  "EditSessionAssignment-startTime": "Hora de inicio:",
  "EditSessionAssignment-subtitle": "Seleccione al trabajador al que desea asignar la siguiente sesión:",
  "EditSessionAssignment-systemSerialNumber": "El N.° de serie del sistema:",
  "EditSessionAssignment-title": "Editar asignación de sesión",
  "EditSessionAssignment-updateError": "Se produjo un error al intentar actualizar el trabajador asignado a la sesión",
  "EntityDetail-address": "Dirección:",
  "EntityDetail-assignedDevices": "# dispositivos asignados:",
  "EntityDetail-availableDevices": "# dispositivos disponibles:",
  "EntityDetail-devices": "# de dispositivos:",
  "EntityDetail-disconnectedDevices": "# dispositivos desconectados:",
  "EntityDetail-gatewayStatus": "Estado de la puerta de enlace:",
  "EntityDetail-iconUrl": "URL del icono:",
  "EntityDetail-name": "Nombre:",
  "EntityDetail-siteName": "Nombre del sitio:",
  "EntityDetail-departmentName": "Nombre del departamento:",
  "EntityDetail-noGateway": "Sin puerta de enlace",
  "EntityDetail-outOfServiceDevices": "# dispositivos fuera de servicio:",
  "EntityDetail-shortName": "Nombre corto:",
  "EntityDetail-timezone": "Zona horaria:",
  "EntityDetail-title": "Detalles de {entity}",
  "EntityDetail-title-department": "Departamento",
  "EntityDetail-title-organization": "Organización",
  "EntityDetail-title-site": "Sitio",
  "EntityDetailsSider-createDepartmentButton": "Crear departamento",
  "EntityDetailsSider-createSiteButton": "Crear sitio",
  "EntityDetailsSider-departmentsTitle": "Departamentos",
  "EntityDetailsSider-sitesTitle": "Sitios",
  "EntityDetailsSiderCard-departmentAndDeviceCount": "{departments} Departamentos/{devices} dispositivos",
  "EntityDetailsSiderCard-descriptionAddress": "Dirección: {address}",
  "EntityDetailsSiderCard-descriptionTimezone": "Zona horaria: {tz}",
  "EntityDetailsSiderCard-deviceCount": "{devices} dispositivos",
  "EntityDetailsSiderCard-gatewayStatus": "Estado de la puerta de enlace",
  "EntityDetailsSiderCard-gatewayDateSinceOffline": "({date})",
  "EntityDetailsSiderCard-DepartmentAndDeviceCount": "{departments} Departamentos/{devices} dispositivos",
  "EntityDetailsSiderCard-siteDepartmentAndDeviceCount": "{sites} sitios / {departments} departamentos / {devices} dispositivos",
  "EntityDetailsSiderCard-siteDepartmentInformationOrg":
    "Dado que esta organización tiene un solo sitio y departamento, la información se muestra para ambos a nivel de departamento.",
  "EntityDetailsSiderCard-siteDepartmentInformation":
    "Dado que este sitio solo tiene un departamento, la información se muestra para ambos a nivel de departamento",
  "EntityDetailsSiderCard-view": "Vista",
  "EntityDetails-analyticsTabName": "Analítica",
  "EntityDetails-dashboardTabName": "Panel",
  "EntityDetails-devicesTabName": "Dispositivos",
  "EntityDetails-navigationMenu": "Menú de Navegación",
  "EntityDetails-workerReportGroupsTabName": "Grupos de informes de trabajadores",
  "EntityDetails-workersTabName": "Trabajadores",
  "EntityDetails-liveCheckedOutTabName": "Verificado en vivo",
  "ErrorScreen-backHomeButton": "Volver al inicio",
  "ErrorScreen-correlationId": "(ID de correlación: {correlationID})",
  "ErrorScreen-defaultErrorText": "Error",
  "ErrorScreen-internalServerErrorDescription": "Se produjo un error al cargar los datos de la página",
  "ErrorScreen-internalServerErrorText": "Error del servidor",
  "ErrorScreen-notFoundErrorDescription": "{entityType} no existe",
  "ErrorScreen-notFoundErrorText": "Error: no encontrado",
  "ErrorScreen-reloadPageButton": "Volver a cargar página",
  "FetchAllocateGatewayData-errorMessage": "Se produjo un error al intentar obtener las puertas de enlace disponibles",
  "FetchAnalyticsDashboardWorkersData-errorMessage": "Se produjo un error al intentar obtener los datos de los trabajadores",
  "FetchDashboardDailyUsageBreakdownSectionWorkersData-errorMessage":
    "Se produjo un error al intentar obtener los datos de los trabajadores",
  "FetchDepartmentDetailPageData-errorMessage": "Se produjo un error al intentar obtener los datos del departamento",
  "FetchDeviceAllocationData-errorMessage": "Se produjo un error al intentar obtener los dispositivos disponibles",
  "FetchDeviceManagementDeviceDetailPageData-errorMessage": "Se produjo un error al intentar obtener los datos del dispositivo",
  "FetchDeviceManagementDeviceDetailPageData-refreshErrorMessage":
    "Los datos de las sesiones no se pudieron actualizar después de la actualización",
  "FetchDiagnosticsDevicesPageData-errorMessage": "Se produjo un error al intentar obtener los datos del dispositivo",
  "FetchOrganizationsPageData-errorMessage": "Se produjo un error al intentar obtener los datos de la organización",
  "FetchStatistics-errorMessage": "Se produjo un error al intentar obtener las estadísticas",
  "FetchWorkersData-errorMessage": "Se produjo un error al intentar obtener los datos requeridos",
  "GatewayAlertBlock-intermittentGateway":
    "La puerta de enlace {serialNumber} en {siteName}-{departmentName} tiene una conexión intermitente. El estado de la conexión y los datos de uso de los dispositivos en esta puerta de enlace pueden ser inexactos",
  "GatewayAlertBlock-offlineGateway":
    "La puerta de enlace {serialNumber} en {siteName}-{departmentName} ha estado desconectada desde el {lastEventDate}. El estado de la conexión y los datos de uso de los dispositivos en esta puerta de enlace pueden ser inexactos",
  "GatewayAlertBlock-offlineIntermitentGateways":
    "Las puertas de enlace {listsText} tienen problemas de conexión. El estado de la conexión y los datos de uso de los dispositivos en esas puertas de enlace pueden ser inexactos",
  "GatewayAlertBlock-offlineIntermitentGatewaysList": "{serialNumberList} en {siteName}-{departmentName}",
  "GatewayEditModal-currentSoftware": "Software actual:",
  "GatewayEditModal-editTargetSoftware": "Editar software objetivo",
  "GatewayEditModal-fetchVersionsDataError": "Se produjo un error al intentar obtener los datos de las versiones del software",
  "GatewayEditModal-gatewaySerial": "N.º de serie de la puerta de enlace:",
  "GatewayEditModal-latest": "Último",
  "GatewayEditModal-scheduleButton": "Cronograma",
  "GatewayEditModal-selectTargetSoftware": "Seleccione software objetivo",
  "GatewayEditModal-targetSoftware": "Software objetivo:",
  "GatewayEditModal-title": "Editar puerta de enlace",
  "GatewayEditModal-updateError": "Se produjo un error al intentar actualizar la versión del software de la puerta de enlace",
  "GatewayEditModal-updateNowButton": "Actualizar ahora",
  "GatewayEditModal-updateSuccessForced": "Ahora hay una actualización forzada del software de la puerta de enlace pendiente.",
  "GatewayEditModal-updateSuccessScheduled": "La puerta de enlace está programada para actualizarse durante la noche.",
  "GatewayItem-deallocateGateway": "Cancelar asignación de puerta de enlace",
  "GatewayDetails-departmentLabel": "Departamento:",
  "GatewayDetails-gatewaySoftwareLabel": "Software actual:",
  "GatewayDetails-gatewaySerialNumber": "N.º de serie de la puerta de enlace {serialNumber}",
  "GatewayDetails-lastTimeConnectedLabel": "Conectado por última vez:",
  "GatewayDetails-location": "Ubicación",
  "GatewayDetails-organizationLabel": "Organización:",
  "GatewayDetails-siteLabel": "Sitio:",
  "GatewayDetails-title": "Detalles de la puerta de enlace",
  "GatewaysTable-currentSoftwareColumnTitle": "Software actual",
  "GatewaysTable-downLabel": "Abajo",
  "GatewaysTable-failed": "Error",
  "GatewaysTable-firmwareColumnTitle": "Firmware",
  "GatewaysTable-forceUpdatePending": "Forzar actualización pendiente",
  "GatewaysTable-gatewayDetailsDrawerTitle": "Puerta de enlace",
  "GatewaysTable-intermittentLabel": "Intermitente",
  "GatewaysTable-lastSeenColumnTitle": "Visto por última vez",
  "GatewaysTable-matchTarget": "El software coincide con el objetivo",
  "GatewaysTable-neverSeenLabel": "Nunca visto",
  "GatewaysTable-scheduleUpdatePending": "Actualización de programación pendiente",
  "GatewaysTable-serialNumberColumnTitle": "N.º de serie de la puerta de enlace",
  "GatewaysTable-updateStatusColumnTitle": "Estado de actualización",
  "GatewaysTable-upLabel": "Arriba",
  "GatewaysTableLastSeenColumnRender-neverSeen": "Nunca visto",
  "GatewayUpdateConfigurationModal-currentSoftware": "Software actual:",
  "GatewayUpdateConfigurationModal-description":
    "Si desea actualizar la siguiente puerta de enlace al software objetivo, seleccione actualizar ahora.",
  "GatewayUpdateConfigurationModal-gatewaySerial": "N.º de serie de la puerta de enlace:",
  "GatewayUpdateConfigurationModal-targetSoftware": "Software objetivo:",
  "GatewayUpdateConfigurationModal-title": "Actualizar configuración de puerta de enlace",
  "GatewayUpdateConfigurationModal-updateError":
    "Se produjo un error al intentar actualizar la versión del software de la puerta de enlace",
  "GatewayUpdateConfigurationModal-updateNowButton": "Actualizar ahora",
  "GatewayUpdateConfigurationModal-updatePending": "Ahora hay una actualización forzada del software de la puerta de enlace pendiente.",
  "Header-acknowledgeErrorMessage": "Se produjo un error al intentar reconocer las notificaciones",
  "Header-clearNotifications": "Borrar",
  "Header-errorMessage": "Se produjo un error al intentar cargar las notificaciones",
  "Header-noNotifications": "Sin Notifications",
  "Header-notifications": "Notificaciones",
  "Header-today": "Hoy",
  "Header-unallocatedData": "Datos No Asignados",
  "Header-unallocatedDataDevices": "{devices} dispositivos tienen cada uno 8+ horas de datos no asignados",
  "Header-unallocatedDataOneDevice": "1 Dispositivo tiene 8+ horas de datos no asignados",
  "Header-viewAll": "Ver Todas",
  "IndividualWorkerReportDrawer-contentMessage":
    "El informe de trabajador de **{worker}** cubre un período de 7 días. Seleccione la fecha de finalización deseada para el informe.",
  "IndividualWorkerReportDrawer-drawerTitle": "Informe del trabajador",
  "IndividualWorkerReportDrawer-fileName": "Informe del trabajador",
  "LeaderboardDownloadDropdown-downloadAll": "Todo",
  "LeaderboardDownloadDropdown-downloadBottom": "Oportunidades de coaching",
  "LeaderboardDownloadDropdown-downloadData": "Descargar datos",
  "LeaderboardDownloadDropdown-downloadError": "Se produjo un error al intentar descargar el informe",
  "LeaderboardDownloadDropdown-downloadTop": "Tabla de clasificación superior",
  "LeaderboardDownloadDropdown-title": "Tabla de clasificación",
  "LeaderboardDownloadDropdown-workerMetrics": "Métricas del trabajador",
  "LiveCheckedOutDeviceTable-dataUploadedTodayColumnTitle": "Datos cargados hoy",
  "LiveCheckedOutDeviceTable-deviceTagColumnTitle": "Etiqueta de dispositivo",
  "LiveCheckedOutDeviceTable-workerColumnTitle": "Trabajador",
  "LiveCheckedOutDeviceTable-checkedOutColumnTitle": "Controlado",
  "LiveCheckedOutDeviceTable-lastSeenColumnTitle": "Visto por última vez",
  "ManualDeviceAllocation-description": "Seleccione los números de serie que desea asignar a **{site}** - **{department}**.",
  "ManualDeviceAllocation-devicesLabel": "Dispositivos:",
  "ManualWorkerCreation-bothNamesRequiredValidation": "Si se especifica nombre o apellido, ambos deben ser",
  "ManualWorkerCreation-contentText": "Enumere los trabajadores que le gustaría crear en **{siteName}** - **{departmentName}**",
  "ManualWorkerCreation-empIdAlreadyExistsValidation": "El trabajador con esta ID de empleado ya existe en el sitio {siteName}",
  "ManualWorkerCreation-ifNoNameRequireEmpIdValidation":
    "Si se omiten tanto el nombre como el apellido, se debe especificar el ID del empleado",
  "ManualWorkerCreation-nameAlreadyExistsValidation": "Ya existe un grupo con este nombre en el sitio {siteName}",
  "ManualWorkerCreation-title": "Creación del trabajador",
  "Notifications-title": "Notificaciones",
  "Notifications-unallocatedData": "Datos no asignados",
  "Organizations-allOrganizationsPath": "Todas las organizaciones",
  "Organizations-allOrganizationsTitle": "Todas las organizaciones",
  "Organizations-allTagText": "Todo",
  "Organizations-createOrganizationButtonText": "Crear organización",
  "Organizations-downloadData": "Descargar datos",
  "Organizations-favoriteTagText": "Favoritos",
  "Organizations-organizationsListEndMessage": "No hay más organizaciones para mostrar",
  "Organizations-searchButtonText": "Buscar",
  "OrganizationCard-deviceStatusChartTitle": "Estado del dispositivo",
  "OrganizationCard-oneDeviceText": "1 dispositivo en total",
  "OrganizationCard-siteDepartmentAndDeviceCount":
    "{sites} {sites, plural, =1 {Sitio} other {Sitios}} / {departments} {departments, plural, =1 {Departmento} other {Departmentos}} / {devices} {devices, plural, =1 {Dispositivo} other {Dispositivos}}",
  "OrganizationCard-totalDevicesText": "{devices} dispositivos totales",
  "OrganizationCard-usageChartTitle": "Total de horas de uso por semana",
  "OrganizationDetail-deleteAlertMessage": "¿Está seguro de que desea eliminar esta organización?",
  "OrganizationDetail-deleteAlertTitle": "Eliminar organización",
  "OrganizationDetail-deleteError": "La organización no se pudo eliminar",
  "OrganizationDetail-fetchDataError": "Se produjo un error al intentar obtener los datos de la organización",
  "OrganizationDetail-notFoundError": "Organización no encontrada",
  "OrganizationDetail-tableTitle": "Lista de sitios",
  "OrganizationDetail-updateError": "Se produjo un error al intentar obtener los datos de la organización",
  "OrganizationForm-addressInputLabel": "Dirección",
  "OrganizationForm-createError": "Se produjo un error al intentar crear la organización",
  "OrganizationForm-iconUrlInputLabel": "URL del icono",
  "OrganizationForm-nameInputLabel": "Nombre",
  "OrganizationForm-shortNameInputLabel": "Nombre corto",
  "OrganizationForm-shortNameInputPatternRuleMessage":
    "El nombre corto solo puede contener letras minúsculas, números o, pero no como carácter inicial o final",
  "OrganizationModal-createError": "Se produjo un error al intentar crear la organización",
  "OrganizationModal-createTitle": "Crear organización",
  "OrganizationModal-deleteButtonText": "Eliminar organización",
  "OrganizationModal-deleteError": "La organización no se pudo eliminar",
  "OrganizationModal-editTitle": "Editar organización",
  "OrganizationModal-updateError": "Se produjo un error al intentar crear la organización",
  "OrganizationsTable-departmentsColumnTitle": "# departamentos",
  "OrganizationsTable-devicesColumnTitle": "# dispositivos",
  "OrganizationsTable-nameColumnTitle": "Nombre de la organización",
  "OrganizationsTable-sitesColumnTitle": "# sitios",
  "OrganizationSiteModal-editTitle": "Editar organización y sitio",
  "OrganizationSiteModal-deleteAlertMessage": "¿Está seguro de que desea eliminar este sitio?",
  "OrganizationSiteModal-deleteAlertTitle": "Eliminar sitio",
  "OrganizationSiteModal-deleteButtonText": "Eliminar sitio",
  "OrganizationSiteModal-deleteError": "Se produjo un error al intentar eliminar el sitio",
  "OrganizationSiteModal-updateOrganizationError": "Se produjo un error al intentar crear la organización",
  "OrganizationSiteModal-updateSiteError": "Se produjo un error al intentar crear el sitio",
  "OverridePendingUpdateConfirmationModal-message": "¿Está seguro de que desea anular la actualización pendiente?",
  "OverridePendingUpdateConfirmationModal-title": "Anular actualización pendiente",
  "PendingUpdateDetail-buttonOne": "Botón 1:",
  "PendingUpdateDetail-buttonThree": "Botón 3:",
  "PendingUpdateDetail-buttonTwo": "Botón 2:",
  "PendingUpdateDetail-drawerSubtitle": "Actualización pendiente para el siguiente dispositivo:",
  "PendingUpdateDetail-drawerTitle": "Actualización pendiente",
  "PendingUpdateDetail-firmware": "Revisión de firmware:",
  "PendingUpdateDetail-hardwareRev": "Revisión de hardware:",
  "PendingUpdateDetail-overridePendingUpdateButton": "Anular actualización pendiente",
  "PendingUpdateDetail-systemSerialNumber": "N.° de serie del sistema:",
  "RemoveFromFavoritesModal-doNotShowMessageAgainText": "No mostrar este mensaje de nuevo.",
  "RemoveFromFavoritesModal-message": "¿Está seguro de que desea eliminar **{organization}** de favoritos?",
  "RenameWorkerGroupModal-errorMessage": "Se produjo un error al intentar crear el grupo de informes de trabajadores",
  "RenameWorkerGroupModal-inputLabel": "Nombre del grupo:",
  "RenameWorkerGroupModal-nameLengthValidator": "Los nombres de los grupos están limitados a 40 caracteres. Reduzca el nombre.",
  "RenameWorkerGroupModal-rename": "Cambiar nombre",
  "RenameWorkerGroupModal-successMessage": "El nombre del grupo se cambió correctamente",
  "RenameWorkerGroupModal-title": "Cambiar nombre de grupo",
  "ReportDownload-allButtonText": "Descargar informe de todos los trabajadores",
  "ReportDownload-allMessage":
    "El informe de todos los trabajadores cubre un período de 7 días. Seleccione la fecha de finalización deseada del informe que desea descargar.",
  "ReportDownload-allTitle": "Informe de todos los trabajadores",
  "ReportDownload-demoButtonText": "Descargar informe de demostración",
  "ReportDownload-demoMessage":
    "El informe de demostración cubre un período de 7 días. Seleccione la fecha de finalización deseada del informe que desea descargar.",
  "ReportDownload-demoTitle": "Informe de demostración",
  "ReportDownload-department": "Departamento:",
  "ReportDownload-downloadError": "Se produjo un error al intentar descargar el informe",
  "ReportDownload-individualButtonText": "Descargar informe de trabajador único",
  "ReportDownload-individualMessage":
    "El informe de trabajador cubre un período de 7 días. Seleccione la fecha de finalización deseada del informe que desea descargar.",
  "ReportDownload-individualTitle": "Informe del trabajador",
  "ReportDownload-noDepartmentError": "No hay departamentos en este sitio para descargar el informe.",
  "ReportDownload-reportEndDate": "Fecha de finalización del informe:",
  "ReportDownload-summaryButtonText": "Descargar informe del departamento",
  "ReportDownload-summaryMessage":
    "El informe resumido del departamento cubre un período de 7 días. Seleccione la fecha de finalización deseada del informe que desea descargar.",
  "ReportDownload-summaryTitle": "Informe resumido",
  "ReportDownload-worker": "**Trabajador: ** {displayName}",
  "ReportDrawer-fetchReportError": "Se produjo un error al intentar recuperar los datos del informe",
  "ReportDrawerContent-downloadButtonText": "Descargar",
  "ReportDrawerContent-previewButtonText": "Vista previa",
  "ReportDrawerContent-generateReportButtonText": "Generar informe",
  "ReportDrawerContent-reportEndDate": "Fecha de finalización del informe:",
  "ReportDrawerContent-filterReport": "Filtrar informe:",
  "ReportDrawerContent-allWorkers": "Todos los trabajadores",
  "ReportDrawerContent-workerReportGroups": "Grupos",
  "ReportPreviewModal-title": "Vista previa",
  "ReportPreviewModal-searchButtonText": "Buscar",
  "ReportPreviewModal-download": "Descargar",
  "WorkerSafeliftReportPreviewModal-all": "Descargar todos los trabajadores activos e inactivos",
  "WorkerSafeliftReportPreviewModal-allActive": "Descargar todos los trabajadores activos",
  "WorkerSafeliftReportPreviewModal-allWorkers": "Todos los trabajadores",
  "WorkerSafeliftReportPreviewModal-allWorkersNoData": "No hubo trabajadores de {departmentName} activos entre {startDate} y {endDate}",
  "WorkerSafeliftReportPreviewModal-displayInactiveWorkers": "Mostrar trabajadores inactivos:",
  "WorkerSafeliftReportPreviewModal-download": "Descargar",
  "WorkerSafeliftReportPreviewModal-downloadError": "Se produjo un error al intentar descargar el informe",
  "WorkerSafeliftReportPreviewModal-generatingReport": "Generando informe...",
  "WorkerSafeliftReportPreviewModal-groupNoData":
    "No hubo trabajadores de {departmentName} - {groupName} activos entre {startDate} y {endDate}",
  "WorkerSafeliftReportPreviewModal-individual": "Trabajador seleccionado",
  "WorkerSafeliftReportPreviewModal-internalError":
    "Lo sentimos, algo salió mal. Ocurrió un error interno. Por favor, inténtalo de nuevo más tarde.",
  "WorkerSafeliftReportPreviewModal-safeliftReport": "Informe de Safelift",
  "WorkerSafeliftReportPreviewModal-searchButtonText": "Buscar",
  "WorkerSafeliftReportPreviewModal-timeoutError":
    "Lo sentimos, algo salió mal. Se agotó el tiempo de espera. Por favor, inténtalo de nuevo más tarde.",
  "WorkerSafeliftReportPreviewModal-title": "Vista previa",
  "WorkerSafeliftReportPreviewModal-workerNoData": "{workerName} no estuvo activo entre {startDate} y {endDate}",
  "SearchFilter-inputPlaceholder": "Buscar texto",
  "SearchFilter-resetButton": "Reiniciar",
  "SearchFilter-searchButton": "Buscar",
  "SessionLog-download": "Descargar",
  "SessionLog-duration": "Duración:",
  "SessionLog-entries": "Entradas:",
  "SessionLog-errors": "Errores",
  "SessionLog-fetchDataError": "Se produjo un error al intentar obtener los datos de los registros",
  "SessionLog-lifts": "Levantamientos:",
  "SessionLog-log": "Registro:",
  "SessionLog-none": "Ninguno",
  "SessionLog-sessionID": "ID de sesión:",
  "SessionLog-startTime": "Hora de inicio:",
  "SessionLog-systemSerialNumber": "El N.° de serie del sistema:",
  "SessionLog-title": "Registros JSON",
  "SessionLog-worker": "Trabajador:",
  "SessionsTable-createdLabel": "Creado",
  "SessionsTable-duration": "Duración",
  "SessionsTable-entries": "Entradas",
  "SessionsTable-errors": "Errores",
  "SessionsTable-hourAbbreviation": "h",
  "SessionsTable-id": "ID de sesión",
  "SessionsTable-lifts": "Levantamientos",
  "SessionsTable-logs": "Registros",
  "SessionsTable-minuteAbbreviation": "m",
  "SessionsTable-none": "Ninguno",
  "SessionsTable-processedLabel": "Procesado",
  "SessionsTable-secondAbbreviation": "s",
  "SessionsTable-startTime": "Hora de inicio",
  "SessionsTable-status": "Estado",
  "SessionsTable-uploadedLabel": "Subido",
  "SessionsTable-uuid": "UUID de sesión",
  "SessionsTable-worker": "Trabajador",
  "SignIn-emailInputLabel": "Correo electrónico",
  "SignIn-emailInputRequiredRuleMessage": "El campo de correo electrónico es obligatorio",
  "SignIn-errorMessage": "No pudo iniciar sesión",
  "SignIn-passwordInputLabel": "Contraseña",
  "SignIn-passwordInputRequiredRuleMessage": "El campo de contraseña es obligatorio",
  "SignIn-submit": "Iniciar sesión",
  "SiteDepartmentModal-deleteAlertMessage": "¿Está seguro de que desea eliminar este departamento?",
  "SiteDepartmentModal-deleteAlertTitle": "Eliminar departamento",
  "SiteDepartmentModal-deleteButtonText": "Eliminar departamento",
  "SiteDepartmentModal-title": "Editar sitio y departamento",
  "SiteDepartmentModal-orgIncludedTitle": "Editar organización, sitio y departamento",
  "SiteDetail-deleteAlertMessage": "¿Está seguro de que desea eliminar este sitio?",
  "SiteDetail-deleteAlertTitle": "Eliminar sitio",
  "SiteDetail-deleteError": "El sitio no se pudo eliminar",
  "SiteDetail-differentOrganizationError": "Este sitio pertenece a otra organización",
  "SiteDetail-fetchDataError": "Se produjo un error al intentar obtener los datos del sitio",
  "SiteDetail-notFoundError": "Sitio no encontrado",
  "SiteDetail-tableTitle": "Lista de departamentos",
  "SiteDetail-updateError": "Se produjo un error al intentar obtener los datos del sitio",
  "SiteForm-addressInputLabel": "Dirección",
  "SiteForm-createError": "Se produjo un error al intentar crear el sitio",
  "SiteForm-nameInputLabel": "Nombre",
  "SiteForm-timezoneInputLabel": "Zona horaria",
  "SiteModal-createError": "Se produjo un error al intentar crear el sitio",
  "SiteModal-createTitle": "Crear sitio",
  "SiteModal-deleteButtonText": "Eliminar sitio",
  "SiteModal-editTitle": "Editar sitio",
  "SiteModal-updateError": "Se produjo un error al intentar crear el sitio",
  "SiteSummaryLeaderboardPage-departmentAverage": "Promedio del departamento",
  "SiteSummaryLeaderboardPage-kilogramsUnit": "kg",
  "SiteSummaryLeaderboardPage-poundsUnit": "lb",
  "SiteSummaryLeaderboardPage-safeLiftScore": "Puntuación de Safelift",
  "SiteSummaryLeaderboardPage-weightOffloaded": "Peso descargado",
  "SiteSummaryReportDrawer-activeInactiveWorkersCheckbox": "Lista de trabajadores activos/inactivos",
  "SiteSummaryReportDrawer-contentMessage":
    "El informe resumido del departamento cubre un período de 7 días. Seleccione la fecha de finalización deseada para el informe.",
  "SiteSummaryReportDrawer-displayWorkersWeightOffloadedCheckbox": "Mostrar el peso de los trabajadores descargados",
  "SiteSummaryReportDrawer-editConfigurationButton": " Editar configuración predeterminada ",
  "SiteSummaryReportDrawer-errorMessage": "Se produjo un error al obtener los datos",
  "SiteSummaryReportDrawer-featuresToIncludeMessage": "Características para incluir en el informe.",
  "SiteSummaryReportDrawer-includeWorkerReportGroups": "Incluir grupos de informes de trabajador:",
  "SiteSummaryReportDrawer-leaderboardCheckbox": "Tabla de clasificación",
  "SiteSummaryReportDrawer-onlyAdminsMessage": "Solo los administradores pueden editar.",
  "SiteSummaryReportDrawer-reportEndDate": "Fecha de finalización del informe:",
  "SiteSummaryReportDrawer-safeliftScoreCheckbox": "Mostrar puntuación de Safelift",
  "SiteSummaryReportDrawer-select": "Seleccionar",
  "SiteSummaryReportDrawer-updateConfigButton": "Actualizar configuración",
  "SiteSummaryReportDrawer-updateError": "Se produjo un error al intentar actualizar la configuración del informe resumido del sitio",
  "SiteSummaryReportDrawer-updateSuccess": "La actualización de la configuración del informe resumido del sitio se realizó correctamente",
  "SiteSummaryReportModal-activeInactiveWorkersCheckbox": "Lista de trabajadores activos/inactivos",
  "SiteSummaryReportModal-contentMessage":
    "El informe resumido del departamento cubre un período de 7 días. Seleccione la fecha de finalización deseada para el informe.",
  "SiteSummaryReportModal-displayWorkersWeightOffloadedCheckbox": "Mostrar el peso de los trabajadores descargados",
  "SiteSummaryReportModal-errorMessage": "Se produjo un error al obtener los datos",
  "SiteSummaryReportModal-featuresToIncludeMessage": "Características para incluir en el informe.",
  "SiteSummaryReportModal-includeWorkerReportGroups": "Incluir grupos de informes de trabajador:",
  "SiteSummaryReportModal-leaderboardCheckbox": "Tabla de clasificación",
  "SiteSummaryReportModal-onlyAdminsMessage": "Solo los administradores pueden editar.",
  "SiteSummaryReportModal-title": "Editar configuración predeterminada",
  "SiteSummaryReportModal-safeliftScoreCheckbox": "Mostrar puntuación de Safelift",
  "SiteSummaryReportModal-save": "Guardar",
  "SiteSummaryReportModal-select": "Seleccionar:",
  "SiteSummaryReportModal-updateError": "Se produjo un error al intentar actualizar la configuración del informe resumido del sitio",
  "SiteSummaryReportModal-updateSuccess": "La actualización de la configuración del informe resumido del sitio se realizó correctamente",
  "SiteSummaryReportModal-workerGroupsPlaceholder": "Grupos de trabajadores",
  "SiteSummaryReportPDF-aboveTarget": "Acerca del objetivo",
  "SiteSummaryReportPDF-active": "Activo",
  "SiteSummaryReportPDF-activeWorker": "Trabajador activo",
  "SiteSummaryReportPDF-belowTarget": "Por debajo del objetivo",
  "SiteSummaryReportPDF-exempt": "Exento/sin definir",
  "SiteSummaryReportPDF-fileName": "InformeResumidoDelSitio.pdf",
  "SiteSummaryReportPDF-footer": "Página {currentPage} de {totalPages}",
  "SiteSummaryReportPDF-headerDates": "{startDate} al {endDate}",
  "SiteSummaryReportPDF-inactive": "Inactivo",
  "SiteSummaryReportPDF-inactiveWorker": "Trabajador inactivo",
  "SiteSummaryReportPDF-kilogramsUnit": "kg",
  "SiteSummaryReportPDF-onTarget": "En objetivo",
  "SiteSummaryReportPDF-point": "•",
  "SiteSummaryReportPDF-poundsUnit": "lb",
  "SiteSummaryReportPDF-totalUsageHours": "Total de horas de uso",
  "SiteSummaryReportPDF-usageHoursTargetUtilization": "Estado de Horas de Uso del Trabajador",
  "SiteSummaryReportPDF-totalWeightOffloaded": "Peso descargado de todos los tiempos:",
  "SiteSummaryReportPDF-weeklySummaryReport": "Informe Resumido Semanal",
  "SiteSummaryReportPDF-weightOffloadedTitle": "Peso descargado",
  "SiteSummaryReportPDF-workerReportGroups": "Grupos de informes de trabajadores",
  "SiteSummaryReportPDF-workerStatusCounts": "El estado del trabajador cuenta",
  "SiteSummaryReportPreviewModal-download": "Descargar",
  "SiteSummaryReportPreviewModal-title": "Vista previa",
  "SitesTable-departmentsColumnTitle": "# departamentos",
  "SitesTable-devicesColumnTitle": "# dispositivos",
  "SitesTable-nameColumnTitle": "Nombre del sitio",
  "SiteWorkersTable-assignedDeviceColumnTitle": "Dispositivo asignado",
  "SiteWorkersTable-daysSinceLastUsageColumnTitle": "Días desde el último uso",
  "SiteWorkersTable-departmentColumnTitle": "Departamento",
  "SiteWorkersTable-employeeIDColumnTitle": "ID de empleado",
  "SiteWorkersTable-naLabel": "N/A",
  "SiteWorkersTable-nameColumnTitle": "Nombre",
  "SiteWorkersTable-report": "Informe",
  "StatusChart-activeWorkers": "Activo",
  "StatusChart-assignedDevices": "Asignados",
  "StatusChart-availableDevices": "Disponible",
  "StatusChart-deviceStatusTitle": "Dispositivos",
  "StatusChart-inactiveWorkers": "Inactivo",
  "StatusChart-moreInformationText": "*Toque para obtener más información",
  "StatusChart-outOfServiceDevices": "Fuera de servicio",
  "StatusChart-statusChartTitle":
    "{entityType, plural, =0 {Organization} =1 {Site} =2 {Department} =3 {Worker Report Group} other {Organization}} Estado",
  "StatusChart-waitlistWorkers": "Lista de espera",
  "StatusChart-workerStatusTitle": "Trabajadores",
  "Subheader-actions-allWorkersReport": "Informe de todos los trabajadores",
  "Subheader-actions-demoReport": "Informe de demostración",
  "Subheader-actions-summaryReport": "Informe resumido",
  "SummaryReportDrawer-contentMessage":
    "El informe resumido del departamento cubre un período de 7 días. Seleccione la fecha de finalización deseada para el informe.",
  "SummaryReportDrawer-drawerTitle": "Informe resumido",
  "SummaryReportDrawer-fileName": "Informe resumido",
  "TargetUtilizationHoursModal-adminMessage": "Solo los administradores pueden editar estos objetivos.",
  "TargetUtilizationHoursModal-afterWeek": "Después de la semana {week}:",
  "TargetUtilizationHoursModal-configurationErrorMessage": "Se produjo un error al intentar crear la organización",
  "TargetUtilizationHoursModal-configurationSuccessMessage": "Los criterios del objetivo se configuraron correctamente",
  "TargetUtilizationHoursModal-constantTarget": "Objetivo constante",
  "TargetUtilizationHoursModal-constantTargetLabel": "Seleccione un objetivo fijo de horas por día para todos los trabajadores:",
  "TargetUtilizationHoursModal-departmentConfiguration": "Sus administradores han configurado el departamento {configName} como:",
  "TargetUtilizationHoursModal-departmentConfigurationConstantTarget":
    "Sus administradores han establecido un objetivo constante para el departamento de {hours} horas/día.",
  "TargetUtilizationHoursModal-departmentConfigurationNoTarget":
    "Sus administradores no han configurado las horas de utilización objetivo para este departamento.",
  "TargetUtilizationHoursModal-departmentConfigurationOnboardingCalendar":
    "Sus administradores han configurado el calendario de incorporación del departamento como:",
  "TargetUtilizationHoursModal-hoursDay": "horas/día",
  "TargetUtilizationHoursModal-hoursPerDay": "Horas al día",
  "TargetUtilizationHoursModal-infoMessage":
    "El cálculo de utilización objetivo supone una semana laboral de 5 días. Actualmente no se admiten otros cronogramas",
  "TargetUtilizationHoursModal-noTarget": "No hay objetivos",
  "TargetUtilizationHoursModal-onboardingCalendar": "Calendario de incorporación",
  "TargetUtilizationHoursModal-onboardingCalendarLabel":
    "Seleccione un objetivo de horas por día que cambie según un calendario de incorporación de 4 semanas.",
  "TargetUtilizationHoursModal-subtitle": "Seleccione cómo el departamento quiere utilizar el objetivo.",
  "TargetUtilizationHoursModal-title": "Utilización del dispositivo objetivo",
  "TargetUtilizationHoursModal-week": "Semana {week}:",
  "UnenrollmentReason-other": "Otro",
  "UnenrollmentReason-subtitle": "Marque todas las razones por las cuales se canceló la inscripción de **{workerIdentifier}** al programa",
  "UnenrollmentReason-title": "Razón de la cancelación de la inscripción",
  "UnenrollmentReason-unenrollmentReasonError": "Se produjo un error al intentar registrar los motivos de baja",
  "UnenrollmentReasons-finished": "Finalizado",
  "UnenrollmentReasons-inProgress": "En curso",
  "UnenrollmentReasons-openGlossary": "Abrir glosario",
  "UnenrollmentReasons-openGlossaryExtraDesktop":
    "Pase el cursor para ver los detalles de los motivos de cancelación de inscripción individuales o",
  "UnenrollmentReasons-primaryReason": "Razón principal",
  "UnenrollmentReasons-sceondaryReasons": "Razones secundarias",
  "UnenrollmentReasons-subtitleFirstStep":
    "Marque una única **razón principal** por la cual se cancela la inscripción de **{workerIdentifier}** en el programa.",
  "UnenrollmentReasons-subtitleSecondStep":
    "Marque tantas **razones secundarias** como sean necesarias para que se cancele la inscripción del trabajador **{workerIdentifier}** del programa (opcional).",
  "UnenrollmentReasons-waiting": "Espera",
  "UnenrollmentReasonsGlossary-title": "Glosario de razones de cancelación de la inscripción",
  "UnenrollmentReasonsGlossary-description": "Descripción",
  "UnenrollmentReasonsGlossary-descriptionTerminatedQuit": "El usuario abandonó la empresa (renunció o fue despedido)",
  "UnenrollmentReasonsGlossary-descriptionUnknown": "Perdí contacto con el usuario. No sé por qué abandonó el programa.",
  "UnenrollmentReasonsGlossary-descriptionBack": "Malestar en la espalda (inferior o superior)",
  "UnenrollmentReasonsGlossary-descriptionSuitHeavyShoulderPressure":
    "Malestar general en el hombro por la presión causada por el peso/tirones del traje",
  "UnenrollmentReasonsGlossary-descriptionShoulderStrapIrritationPointPressure":
    "Irritación específica/presión puntual resultante de la correa para el hombro que roza al usuario",
  "UnenrollmentReasonsGlossary-descriptionHeat": "El usuario tiene demasiado calor con el traje",
  "UnenrollmentReasonsGlossary-descriptionConstriction":
    "El usuario simplemente siente una contracción, ya sea en el pecho o simplemente con una aprensión general de usar algo a lo que no está acostumbrado",
  "UnenrollmentReasonsGlossary-descriptionGeneral":
    "El usuario simplemente encuentra el traje incómodo en general y no puede señalar un solo lugar de incomodidad",
  "UnenrollmentReasonsGlossary-descriptionDoesntNeedIt":
    "El usuario simplemente siente que personalmente no se beneficia del traje (pero su función laboral sigue siendo adecuada)",
  "UnenrollmentReasonsGlossary-descriptionTimeHabit":
    "El usuario siente que el traje requiere demasiado tiempo o le resulta difícil desarrollar el hábito de ponérselo",
  "UnenrollmentReasonsGlossary-descriptionPreexistingCondition":
    "El usuario tiene alguna afección preexistente que cree que no se adapta bien al traje (lesión en el hombro, lesión en la rodilla, etc.)",
  "UnenrollmentReasonsGlossary-descriptionOnLeave": "El usuario se encuentra de licencia por un período prolongado de tiempo",
  "UnenrollmentReasonsGlossary-descriptionLimitsOverheadReaching":
    "El traje limita el alcance de los usuarios por encima de la cabeza o lo hace incómodo.",
  "UnenrollmentReasonsGlossary-descriptionThighWrapsDropping":
    "El usuario necesita reajustar constantemente las correas para los muslos y es un gran inconveniente",
  "UnenrollmentReasonsGlossary-descriptionExtraHeightWidthOfSuitOnBack":
    "El grosor del traje impide que el usuario se mueva como lo haría normalmente (es decir, tiene que agacharse más en estantes bajos, no puede meterse en espacios pequeños)",
  "UnenrollmentReasonsGlossary-descriptionAssistanceTimingWrongJerky":
    "El usuario percibe que la asistencia del traje no está sincronizada con sus movimientos",
  "UnenrollmentReasonsGlossary-descriptionJobFunctionChange":
    "El usuario cambió de trabajo y su nuevo trabajo ya no requiere un exotraje (por ejemplo, conductor de montacargas)",
  "UnenrollmentReasonsGlossary-descriptionPerformanceProductivityConcerns":
    "El usuario percibe que el traje lo está ralentizando o impactando negativamente en sus métricas de productividad.",
  "UnenrollmentReasonsGlossary-descriptionJobIsNotGoodUseCase":
    "El usuario percibe que la función laboral (que originalmente pensábamos que podría mejorarse) no se beneficia del traje.",
  "UnenrollmentReasonsGlossary-descriptionOther":
    "No encaja en ninguna de las categorías anteriores o desea registrar información/descripción adicional",
  "UnenrollmentReasonsGlossary-exitReason": "Motivo de salida",
  "UnenrollmentReasonsGlossary-labelTerminatedQuit": "Despedido/renunció",
  "UnenrollmentReasonsGlossary-labelUnknown": "Desconocido",
  "UnenrollmentReasonsGlossary-labelBack": "Volver",
  "UnenrollmentReasonsGlossary-labelSuitHeavyShoulderPressure": "Traje pesado/presión en los hombros",
  "UnenrollmentReasonsGlossary-labelShoulderStrapIrritationPointPressure":
    "Irritación en el hombro por la correa/presión puntual en el hombro",
  "UnenrollmentReasonsGlossary-labelHeat": "Calor",
  "UnenrollmentReasonsGlossary-labelConstriction": "Constricción",
  "UnenrollmentReasonsGlossary-labelGeneral": "General",
  "UnenrollmentReasonsGlossary-labelDoesntNeedIt": "No lo necesita",
  "UnenrollmentReasonsGlossary-labelTimeHabit": "Tiempo/Hábito",
  "UnenrollmentReasonsGlossary-labelPreexistingCondition": "Condición preexistente",
  "UnenrollmentReasonsGlossary-labelOnLeave": "De licencia",
  "UnenrollmentReasonsGlossary-labelLimitsOverheadReaching": "Limita el alcance por encima de la cabeza",
  "UnenrollmentReasonsGlossary-labelThighWrapsDropping": "Las correas para los muslos se caen",
  "UnenrollmentReasonsGlossary-labelExtraHeightWidthOfSuitOnBack": "Altura/ancho adicional del traje en la espalda",
  "UnenrollmentReasonsGlossary-labelAssistanceTimingWrongJerky": "Sincronización de asistencia incorrecta/entrecortada",
  "UnenrollmentReasonsGlossary-labelJobFunctionChange": "Cambio de función laboral",
  "UnenrollmentReasonsGlossary-labelPerformanceProductivityConcerns": "Preocupaciones por el rendimiento/productividad",
  "UnenrollmentReasonsGlossary-labelJobIsNotGoodUseCase": "El trabajo no es un buen caso de uso",
  "UnenrollmentReasonsGlossary-labelOther": "Otro",
  "UnenrollmentReasonsGlossary-subTitleUnknown": "Desconocido",
  "UnenrollmentReasonsGlossary-subTitleTerminatedQuit": "Despedido/renunció",
  "UnenrollmentReasonsGlossary-subTitleOther": "Otro",
  "UnenrollmentReasonsGlossary-subTitleDiscomfort": "Malestar",
  "UnenrollmentReasonsGlossary-subTitleConvenience": "Conveniencia",
  "UnenrollmentReasonsGlossary-subTitlePersonal": "Personal",
  "UnenrollmentReasonsGlossary-subTitleSuitLimitations": "Limitaciones del traje",
  "UnenrollmentReasonsGlossary-subTitleJob": "Trabajo",
  "UnenrollWorkerMainReason-other": "Otro",
  "UnenrollWorkerMainReason-terminated": "Despedido/renunció",
  "UnenrollWorkerMainReason-unknown": "Desconocido",
  "UnenrollWorkerOtherReason-assistance_time_wrong": "Sincronización de asistencia incorrecta/entrecortada",
  "UnenrollWorkerOtherReason-back": "Volver",
  "UnenrollWorkerOtherReason-constriction": "Constricción",
  "UnenrollWorkerOtherReason-convenience": "Conveniencia",
  "UnenrollWorkerOtherReason-discomfort": "Malestar",
  "UnenrollWorkerOtherReason-extra_height_width": "Altura/ancho adicional del traje en la espalda",
  "UnenrollWorkerOtherReason-general": "General",
  "UnenrollWorkerOtherReason-heat": "Calor",
  "UnenrollWorkerOtherReason-heavy_shoulder_pressure": "Traje pesado/presión en los hombros",
  "UnenrollWorkerOtherReason-job": "Trabajo",
  "UnenrollWorkerOtherReason-job_function_change": "Cambio de función laboral",
  "UnenrollWorkerOtherReason-limits_overhead_reaching": "Limita el alcance por encima de la cabeza",
  "UnenrollWorkerOtherReason-no_need": "No lo necesita",
  "UnenrollWorkerOtherReason-on_leave": "De licencia",
  "UnenrollWorkerOtherReason-performance_concern": "Preocupaciones por el rendimiento/productividad",
  "UnenrollWorkerOtherReason-personal": "Personal",
  "UnenrollWorkerOtherReason-preexisting_condition": "Condición preexistente",
  "UnenrollWorkerOtherReason-shoulder_point_pressure": "Irritación en el hombro por la correa/presión puntual en el hombro",
  "UnenrollWorkerOtherReason-suitLimitations": "Limitaciones del traje",
  "UnenrollWorkerOtherReason-thigh_wraps_dropping": "Las correas para los muslos se caen",
  "UnenrollWorkerOtherReason-time_habit": "Tiempo/Hábito",
  "UnenrollWorkerOtherReason-use_case": "El trabajo no es un buen caso de uso",
  "Unsubscribe-accountSettings": "Configuraciones de la cuenta",
  "Unsubscribe-subText":
    "Haga clic en el botón a continuación para cancelar la suscripción a los correos electrónicos administrativos en Verve Logic.",
  "Unsubscribe-title": "Cancelar suscripción a correos electrónicos administrativos",
  "Unsubscribe-unsubscribe": "Cancelar suscripción",
  "Unsubscribe-unsubscribedSubText":
    "Se ha cancelado correctamente la suscripción a los correos electrónicos administrativos en Verve Logic. Para volver a suscribirse, edite su",
  "UpdateDeviceConfigurationForm-buttonLabel": "Botón {number}:",
  "UpdateDeviceConfigurationForm-buttonSettingsSubtitle": "Configuración de botones",
  "UpdateDeviceConfigurationForm-description": "Seleccione la configuración a la que desea actualizar el dispositivo:",
  "UpdateDeviceConfigurationForm-firmwareVersionLabel": "Versión de firmware:",
  "UpdateDeviceConfigurationForm-hardwareRevLabel": "Revisión de hardware:",
  "UpdateDeviceConfigurationForm-noButtonSettings": "No se encontraron configuraciones de botones",
  "UpdateDeviceConfigurationForm-noFirmwareVersions": "No se encontraron versiones de firmware",
  "UpdateDeviceConfigurationForm-systemSerialNumberLabel": "El N.° de serie del sistema:",
  "UpdateDeviceConfigurationModal-scheduleUpdateButtonText": "Programar actualización",
  "UpdateDeviceConfigurationModal-title": "Actualizar configuración del dispositivo",
  "UpdateDeviceConfigurationModal-updateError": "Se produjo un error al intentar actualizar la configuración del dispositivo",
  "UpdateDeviceConfigurationModal-updateSuccess": "La actualización de la configuración del dispositivo se programó correctamente.",
  "UsageChart-averageUsageHoursLabel": "Horas promedio",
  "UsageChart-totalUsageHoursLabel": "Total de horas de uso",
  "UserPermissions-accessLevel": "Nivel de acceso",
  "UserPermissions-active": "Activo",
  "UserPermissions-admin": "Admin",
  "UserPermissions-changeAccessLevelError": "Se produjo un error al intentar actualizar el nivel de acceso del usuario.",
  "UserPermissions-changeAccessLevelText":
    "Confirme que desea cambiar el nivel de acceso de **{givenName} {familyName}** para la organización **{organizationName}** de **{previousLevel}** a **{newLevel}**",
  "UserPermissions-changeAccessLevelTitle": "Cambiar nivel de acceso",
  "UserPermissions-changeActiveError": "Se produjo un error al intentar crear el sitio",
  "UserPermissions-confirm": "Confirmar",
  "UserPermissions-custom": "Clientes",
  "UserPermissions-deactivateUser": "Desactivar usuario",
  "UserPermissions-deactivateUserText":
    "Confirme que desea desactivar **{givenName} {familyName}** como parte de la organización **{organizationName}**.",
  "UserPermissions-email": "Correo electrónico",
  "UserPermissions-fetchDataError": "Se produjo un error al intentar obtener los datos de los usuarios",
  "UserPermissions-firstName": "Nombre",
  "UserPermissions-global": "GLOBAL",
  "UserPermissions-inactive": "Inactivo",
  "UserPermissions-lastName": "Apellido",
  "UserPermissions-manager": "Gerente",
  "UserPermissions-needToBeAdminOrTrustedUserError": "Es necesario ser administrador o usuario de confianza",
  "UserPermissions-needToBeAnAdminError": "Debe ser usuario administrador",
  "UserPermissions-none": "Ninguno",
  "UserPermissions-organization": "Organización",
  "UserPermissions-reactivateUser": "Reactivar usuario",
  "UserPermissions-reactivateUserText":
    "Confirme que desea reactivar **{givenName} {familyName}** para unirse a la organización **{organizationName}**.",
  "UserPermissions-readOnly": "Solo lectura",
  "UserPermissions-statusColumnTitle": "Estado",
  "UserPermissions-title": "Permisos de usuario",
  "Validation-confirmPasswordError": "Confirmar contraseña no coincide con la contraseña",
  "WorkerCreationCSVModal-columns": "Columnas: Nombre, Apellido, ID de empleado",
  "WorkerCreationCSVModal-createWorkers": "Crear trabajadores",
  "WorkerCreationCSVModal-description":
    "Cargue un archivo CSV de los trabajadores que desea crear en **{siteName}** - **{departmentName}**.",
  "WorkerCreationCSVModal-successMessage": "El archivo CSV se cargó correctamente",
  "WorkerCreationManualModal-createAnotherWorker": "Crear otro trabajador",
  "WorkerCreationManualModal-createError": "Se produjo un error al intentar crear el trabajador",
  "WorkerCreationManualModal-createdSuccessMessage": "Trabajador creado exitosamente",
  "WorkerCreationManualModal-createWorkers": "Crear trabajadores",
  "WorkerDetail-assignedDevice": "Dispositivo:",
  "WorkerDetail-cannotDeleteMessage":
    "Este trabajador no se puede eliminar ya que está asignado a un dispositivo o sesiones. Elimine estas asignaciones e inténtelo de nuevo.",
  "WorkerDetail-dateFirstUsage": "Usado por primera vez:",
  "WorkerDetail-dateLastUsage": "Utilizado por última vez:",
  "WorkerDetail-deleteAlertTitle": "Eliminar trabajador",
  "WorkerDetail-deleteAlertMessage": "¿Está seguro de que desea eliminar a este trabajador?",
  "WorkerDetail-deleteError": "Se produjo un error al intentar eliminar al trabajador",
  "WorkerDetail-deleteWorker": "Eliminar trabajador",
  "WorkerDetail-deviceAssignment": "Asignación de dispositivo",
  "WorkerDetail-deviceAssignmentError":
    "Se produjo un error al intentar actualizar el dispositivo asignado a este trabajador, los demás detalles se actualizaron correctamente",
  "WorkerDetail-deviceNoLongerHasWorker": "El dispositivo ya no tiene trabajador",
  "WorkerDetail-disabledDevice": "El dispositivo no se puede cambiar para trabajadores no inscritos",
  "WorkerDetail-drawerTitle": "Detalles del trabajador",
  "WorkerDetail-empIdAlreadyExistsValidation": "El trabajador con esta ID de empleado ya existe en el sitio {siteName}",
  "WorkerDetail-employeeId": "ID de empleado:",
  "WorkerDetail-enrolled": "Inscrito",
  "WorkerDetail-errorFetchingWorkers": "Error al buscar trabajadores",
  "WorkerDetail-exemptFromUsageTarget": "Exento de objetivo de uso:",
  "WorkerDetail-firstName": "Nombre:",
  "WorkerDetail-fetchSessionsDataError": "Se produjo un error al obtener los datos",
  "WorkerDetail-ifFirstNameRequireLastNameValidation": "Si se especifica el nombre, el apellido también debería especificarse",
  "WorkerDetail-ifLastNameRequireFirstNameValidation": "Si se especifica el apellido, el nombre también debería especificarse",
  "WorkerDetail-ifNoNameRequireEmpIdValidation": "Si se omiten tanto el nombre como el apellido, se debe especificar el ID del empleado",
  "WorkerDetail-lastName": "Apellido:",
  "WorkerDetail-logReasonWorkerUnenrolling": "Registre la razón por la cual este trabajador canceló su inscripción:",
  "WorkerDetail-name": "Nombre:",
  "WorkerDetail-nameAlreadyExistsValidation": "Ya existe un grupo con este nombre en el sitio {siteName}",
  "WorkerDetail-noneOption": "Ninguno",
  "WorkerDetail-preferredLanguage": "Idioma preferido:",
  "WorkerDetail-reportGroups": "Grupos de informes:",
  "WorkerDetail-reportGroupsNumber": "{quantity} más...",
  "WorkerDetail-status": "Estado:",
  "WorkerDetail-targetHoursStartDate": "Fecha de inicio de horas objetivo:",
  "WorkerDetail-title": "Detalles del trabajador",
  "WorkerDetail-totalHours": "Horas totales:",
  "WorkerDetail-totalLifts": "Levantamientos totales:",
  "WorkerDetail-unenrolled": "No inscrito",
  "WorkerDetail-updateError": "Se produjo un error al intentar crear los trabajadores",
  "WorkerDetail-usageTargetEnabled": "Activado",
  "WorkerDetail-usageTargetDisabled": "Desactivado",
  "WorkerDetail-workerProfile": "Perfil del trabajador",
  "WorkerDetail-workerStatistics": "Estadísticas del  trabajador",
  "WorkerDetail-workerStatus": "Estado del trabajador",
  "WorkerDetailOld-assignedDevice": "Dispositivo asignado:",
  "WorkerDetailOld-dateFirstUsage": "Primer uso:",
  "WorkerDetailOld-dateLastUsage": "Último uso:",
  "WorkerDetailOld-employeeId": "ID de empleado:",
  "WorkerDetailOld-firstName": "Nombre:",
  "WorkerDetailOld-lastName": "Apellido:",
  "WorkerDetailOld-status": "Estado:",
  "WorkerDetailOld-totalHours": "Horas totales:",
  "WorkerDetailOld-totalLifts": "Levantamientos totales:",
  "WorkerDetailOld-workerStatistics": "Estadísticas del trabajador",
  "WorkerDeviceAssignmentModal-changeDeviceError": "Se produjo un error al intentar crear los trabajadores",
  "WorkerDeviceAssignmentModal-device": "Dispositivo:",
  "WorkerDeviceAssignmentModal-subtitle": "Seleccione dispositivo para **{workerName}**",
  "WorkerDeviceAssignmentModal-updateSuccessMessage": "Trabajador actualizado exitosamente",
  "WorkerEditModal-assignedDevice": "Dispositivo:",
  "WorkerEditModal-changeStatusError": "Se produjo un error al intentar cambiar el estado",
  "WorkerEditModal-employeeId": "ID de empleado:",
  "WorkerEditModal-enrolled": "Inscrito",
  "WorkerEditModal-exemptFromUsageTarget": "Exento de objetivo de uso:",
  "WorkerEditModal-firstName": "Nombre:",
  "WorkerEditModal-lastName": "Apellido:",
  "WorkerEditModal-reason": "Razón:",
  "WorkerEditModal-status": "Estado:",
  "WorkerEditModal-targetHoursStartDate": "Fecha de inicio de horas objetivo:",
  "WorkerEditModal-title": "Editar perfil de trabajador",
  "WorkerEditModal-unenrolled": "No inscrito",
  "WorkerEditModal-updateError": "Se produjo un error al intentar crear los trabajadores",
  "WorkerEditModal-updateSuccessMessage": "Trabajador actualizado exitosamente",
  "WorkerEditModal-workerReportGroups": "Grupos de informes:",
  "WorkersFilters-allTagText": "Todo",
  "WorkersFilters-assignedTagText": "Asignados",
  "WorkersFilters-enrolledTagText": "Inscrito",
  "WorkersFilters-searchButtonText": "Buscar trabajadores",
  "WorkersFilters-unenrolledTagText": "No inscrito",
  "WorkersFilters-waitlistTagText": "Lista de espera",
  "WorkerForm-createError": "Se produjo un error al intentar crear los trabajadores",
  "WorkerForm-deviceInputLabel": "Dispositivo",
  "WorkerForm-employeeIdInputLabel": "ID de empleado",
  "WorkerForm-exemptFromUsageTarget": "Exento de objetivo de uso",
  "WorkerForm-firstNameInputLabel": "Nombre",
  "WorkerForm-lastNameInputLabel": "Apellido",
  "WorkerForm-noneOption": "Ninguno",
  "WorkerForm-preferredLanguageInputLabel": "Idioma preferido",
  "WorkerForm-targetHoursStartDate": "Fecha de inicio de horas objetivo",
  "WorkerForm-workerReportGroupsInputLabel": "Grupos de informes",
  "WorkerNoDevice-assignErrorMessage": "Se produjo un error al intentar asignar al trabajador al dispositivo",
  "WorkerNoDevice-assignNewDevice": "Asignar un nuevo dispositivo",
  "WorkerNoDevice-assignNewDevice-placeholder": "Seleccionar",
  "WorkerNoDevice-assignNewDeviceSuccessMessage": "Al trabajador {workerName} se le asignó correctamente el dispositivo {deviceTag}",
  "WorkerNoDevice-assignSuccessMessage": "Asignado exitosamente",
  "WorkerNoDevice-device": "Dispositivo:",
  "WorkerNoDevice-firstSubtitle": "**{name}** ya no tiene un dispositivo asignado.",
  "WorkerNoDevice-leaveUnassigned": "Dejar sin asignar",
  "WorkerNoDevice-leaveUnassignedSuccessMessage": "El trabajador {workerName} se quedó sin un dispositivo asignado",
  "WorkerNoDevice-reason": "Razón:",
  "WorkerNoDevice-secondSubtitle": "¿Qué te gustaría hacer con este trabajador?",
  "WorkerNoDevice-title": "Estado del trabajador",
  "WorkerNoDevice-unenrollWorker": "Cancelar la inscripción del trabajador",
  "WorkerNoDevice-unenrollWorkerSuccessMessage": "Se canceló la inscripción del trabajador {workerName} exitosamente",
  "WorkerNoDeviceOld-unenrollWorker": "Cancelar la inscripción del trabajador. Razón:",
  "WorkerPreferredLanguage-enUS": "Inglés - Estados Unidos",
  "WorkerPreferredLanguage-esMX": "Español - México",
  "WorkerPreferredLanguage-frCA": "Francés - Canadá",
  "WorkerPreferredLanguage-htHT": "Criollo - Haití",
  "WorkerPreferredLanguage-none": "Ninguno",
  "WorkerPreferredLanguage-ptBR": "Portugués - Brasil",
  "WorkerReportGroupCard-numberOfWorkers":
    "{numberOfWorkers} {numberOfWorkers, plural, =0 {trabajadores} =1 {trabajador} other {trabajadores}}",
  "WorkerReportGroupDetail-workerColumn": "Trabajador",
  "WorkerReportGroupDetail-departmentColumn": "Departamento",
  "WorkerReportGroupDetail-statusColumn": "Estado",
  "WorkerReportGroupDetail-allTagText": "Todo",
  "WorkerReportGroupDetail-enrolledTagText": "Inscrito",
  "WorkerReportGroupDetail-unenrolledTagText": "No inscrito",
  "WorkerReportGroupDetail-csv": "{tag} Grupos de informes de trabajadores {name}.csv",
  "WorkerReportGroupDetail-addWorkers": "Agregar trabajadores",
  "WorkerReportGroupDetail-allOrganizations": "Todas las organizaciones",
  "WorkerReportGroupDetail-deleteAlertMessage": "¿Está seguro de que desea eliminar **{groupName}**?",
  "WorkerReportGroupDetail-deleteAlertTitle": "Eliminar grupo",
  "WorkerReportGroupDetail-deleteError": "No se pudo eliminar el grupo de informes de trabajadores",
  "WorkerReportGroupDetail-deleteSuccess": "{groupName} se eliminó exitosamente",
  "WorkerGroupDetail-errorMessage": "Se produjo un error al intentar cargar los datos del trabajador",
  "WorkerReportGroupDetail-fetchDataError": "Se produjo un error al intentar obtener los datos del grupo de informes de trabajadores",
  "WorkerReportGroupDetail-removeWorkerAlertMessage": "¿Está seguro de que desea eliminar al trabajador **{workerName}** de este grupo?",
  "WorkerReportGroupDetail-removeWorkerError": "El trabajador no pudo ser eliminado del grupo",
  "WorkerReportGroupDetail-removeWorkerModalTitle": "Eliminar trabajador",
  "WorkerReportGroupDetail-removeWorkerSuccess": "El trabajador {workerName} fue eliminado exitosamente del grupo",
  "WorkerReportGroupDetail-rename": "Cambiar nombre",
  "WorkerReportGroupsDisplay-emptyStateText": "Aún no se han creado grupos, haga clic en Crear nuevo grupo para agregar uno nuevo",
  "WorkerReportGroupsDisplay-fetchWorkerReportGroupsDataError":
    "Se produjo un error al intentar obtener los datos de los grupos de informes de trabajadores",
  "WorkerReportGroupsDisplay-resultAltText": "Icono de grupo de usuarios",
  "WorkerReportGroupsTabContent-createNewGroupButton": "Crear nuevo grupo",
  "WorkerReportGroupWorkersTable-department": "Departamento",
  "WorkerReportGroupWorkersTable-remove": "Eliminar",
  "WorkerReportGroupWorkersTable-status": "Estado",
  "WorkerReportGroupWorkersTable-worker": "Trabajador",
  "WorkersTabContent-allTagText": "Todo",
  "WorkersTabContent-allWorkersReportButtonText": "Informe de todos los trabajadores",
  "WorkersTabContent-assignedTagText": "Asignados",
  "WorkersTabContent-createWorkersButtonText": "Crear trabajadores",
  "WorkersTabContent-csv": "{tag} trabajadores.csv",
  "WorkersTabContent-deleteSuccess": "Trabajador eliminado exitosamente",
  "WorkersTabContent-deviceColumnTitle": "Dispositivo",
  "WorkersTabContent-employeeIDColumnTitle": "ID de empleado",
  "WorkersTabContent-enrolledTagText": "Inscrito",
  "WorkersTabContent-fetchWorkersDataError": "Se produjo un error al intentar obtener los datos de los trabajadores",
  "WorkersTabContent-nameColumnTitle": "Nombre",
  "WorkersTabContent-statusColumnTitle": "Estado",
  "WorkersTabContent-unenrolledTagText": "No inscrito",
  "WorkersTabContent-waitlistTagText": "Lista de espera",
};

const coreLocale: Partial<CoreLocale> = {};

CoreHelper.mergeWith(baseCoreLocale, CoreHelper.mergeWith(coreLocale, uiLocale));
