import {SearchOutlined} from "@ant-design/icons";
import {Button, Input, Space, Tooltip, Typography} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {differenceInDays, differenceInWeeks} from "date-fns";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useMemo, useState} from "react";

import * as Models from "../../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../../core/stores/AuthenticationDataStore";
import useUserPreferences from "../../../../../hooks/useUserPreferences";
import {AppStore, SegmentKey} from "../../../../../stores/AppStore";
import {isNil} from "../../../../../utils/FunctionUtils";
import {dateSorter, mapAssignedDeviceToColumn} from "../../../../../utils/TableUtils";
import {WebHelper} from "../../../../../utils/WebHelper";
import {ChangeWorkerStatusButton} from "../../../../change-worker-status-button/ChangeWorkerStatusButton";
import {LinkButton} from "../../../../common/link-button/LinkButton";
import {Table} from "../../../../common/table/Table";
import {EditableDevice} from "../../../workers-tab-content/editable-device/EditableDevice";
import styles from "../DashboardWorkersTable.module.scss";

const defaultSettings: Models.DashboardWorkersTableSettings = {
  pageSize: 10,
  sortColumn: "fullName",
  sortOrder: "ascend",
};

export type InactiveWorkersTableProps = {
  workers: Models.AnalyticsDashboardWorker[];
  loading: boolean;
  onOpenChangeWorkerStatusModal: (worker: Models.WorkerShort | Models.Worker) => void;
  onChangeDevice: (worker: Models.WorkerShort | Models.Worker, device?: Models.DeviceShort) => void;
  onWorkerSelected: (worker: Models.WorkerShort) => void;
  showTarget: boolean;
};

export const InactiveWorkersTable: FunctionComponent<InactiveWorkersTableProps> = observer((props) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const [workersFiltered, setWorkersFiltered] = useState<Models.AnalyticsDashboardWorker[]>();
  const [workersFilterText, setWorkersFilterText] = useState<string>();

  const flags = useFlags();

  const handleWorkerSearch = useCallback(() => {
    workersFilterText &&
      setWorkersFiltered(
        props.workers?.filter(
          (worker) =>
            worker.worker.displayName.toLowerCase().includes(workersFilterText.toLowerCase()) ||
            worker.worker.employee_id.toLowerCase().includes(workersFilterText.toLowerCase())
        )
      );
  }, [workersFilterText, props.workers]);

  const dashboardInactiveWorkersTable: Models.DashboardWorkersTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.dashboardInactiveWorkersTable ?? {})}),
    [userPreferences]
  );

  const {pageSize, sortColumn, sortOrder} = dashboardInactiveWorkersTable;

  const handleResetWorkerSearch = () => {
    setWorkersFilterText(undefined);
    setWorkersFiltered(undefined);
  };

  const workerColumnSearchDropdown = (
    <div style={{padding: 8}}>
      <Input value={workersFilterText} onChange={(e) => setWorkersFilterText(e.target.value)} className={styles.searchInput} />
      <Space>
        <Button type="primary" onClick={handleWorkerSearch} icon={<SearchOutlined />} size="small">
          {WebHelper.formatMessage("Common-search")}
        </Button>
        <Button onClick={handleResetWorkerSearch} size="small">
          {WebHelper.formatMessage("Common-delete")}
        </Button>
      </Space>
    </div>
  );

  const workerColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-workerColumnName"),
    key: "worker",
    width: 135,
    sorter: (a, b) => a.worker.fullName.localeCompare(b.worker.fullName),
    sortOrder: sortColumn === "worker" ? sortOrder : undefined,
    filterIcon: <SearchOutlined className={!isNil(workersFiltered) ? styles.searchActive : ""} />,
    filterDropdown: workerColumnSearchDropdown,
    render: (_, record) => (
      <>
        {record.worker.fullName.trimAll() !== "" && record.worker.employee_id ? (
          <LinkButton onClick={() => props.onWorkerSelected(record.worker)}>
            {record.worker.displayName} ({record.worker.employee_id})
          </LinkButton>
        ) : (
          <LinkButton onClick={() => props.onWorkerSelected(record.worker)}>{record.worker.displayName}</LinkButton>
        )}
      </>
    ),
  };

  const statusColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-statusColumnTitle"),
    key: "status",
    width: 60,
    sorter: (a, b) => (a.worker.assignable === b.worker.assignable ? 0 : a.worker.assignable ? -1 : 1),
    sortOrder: sortColumn === "status" ? sortOrder : undefined,
    render: (_, record) => (
      <ChangeWorkerStatusButton worker={record.worker} onOpenChangeWorkerStatusModal={props.onOpenChangeWorkerStatusModal} />
    ),
  };

  const daysSinceLastUsageColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-daysSinceLastUsageColumnTitle"),
    key: "days_since_last_use",
    width: flags.target_utilization_hours && props.showTarget ? 415 : undefined,
    render: (_, record) =>
      record.worker.most_recent_session_start_at ? (
        <Typography.Text className={styles.lastUseText}>
          {WebHelper.formatMessage("DashboardWorkersTable-daysSinceLastUsage", {
            days: differenceInDays(new Date(), record.worker.most_recent_session_start_at),
          })}
        </Typography.Text>
      ) : null,
    sorter: (a, b) => dateSorter(a.worker.most_recent_session_start_at, b.worker.most_recent_session_start_at),
    sortOrder: sortColumn === "days_since_last_use" ? sortOrder : undefined,
  };

  const assignedDeviceColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-assignedDeviceColumnTitle"),
    key: "assigned_device",
    width: 160,
    render: (_, record) =>
      authenticationStore.permissionLevel(Models.UserScope.Department, record.worker.department?.id) === Models.UserAccessLevel.Admin ||
      authenticationStore.permissionLevel(Models.UserScope.Department, record.worker.department?.id) === Models.UserAccessLevel.Manager ? (
        record.worker.assignable ? (
          <EditableDevice worker={record.worker} onSave={props.onChangeDevice} />
        ) : (
          <Tooltip title={WebHelper.formatMessage("DashboardWorkersTable-disabledDeviceColumnValue")}>
            <Typography.Text disabled>
              {mapAssignedDeviceToColumn(record.worker, WebHelper.formatMessage("DashboardWorkersTable-unassignedDeviceLabel"))}
            </Typography.Text>
          </Tooltip>
        )
      ) : (
        mapAssignedDeviceToColumn(record.worker, WebHelper.formatMessage("DashboardWorkersTable-unassignedDeviceLabel"))
      ),
    sorter: (a, b) =>
      (a.worker.assigned_device ? a.worker.assigned_device.device_tag : "--").localeCompare(
        b.worker.assigned_device ? b.worker.assigned_device.device_tag : "--"
      ), //nalabel format
    sortOrder: sortColumn === "assigned_device" ? sortOrder : undefined,
  };

  const weeksSinceFirstUseColumn: ColumnType<Models.AnalyticsDashboardWorker> = {
    title: WebHelper.formatMessage("DashboardWorkersTable-weeksSinceFirstUseColumnTitle"),
    key: "first_session_start_at",
    sortOrder: sortColumn === "first_session_start_at" ? sortOrder : undefined,
    sorter: (a, b) =>
      differenceInWeeks(new Date(), a.worker.first_session_start_at ?? new Date()) -
      differenceInWeeks(new Date(), b.worker.first_session_start_at ?? new Date()),
    width: flags.target_utilization_hours && props.showTarget ? 80 : 130,
    render: (_, record) =>
      record.worker.first_session_start_at ? differenceInWeeks(new Date(), record.worker.first_session_start_at) : null,
  };

  const inactiveColumns: ColumnsType<Models.AnalyticsDashboardWorker> = [];

  inactiveColumns.push(workerColumn, assignedDeviceColumn, statusColumn, weeksSinceFirstUseColumn, daysSinceLastUsageColumn);

  const handleInactiveWorkersTableSettingsChange = (updatedTableSettings?: Partial<Models.DashboardWorkersTableSettings>) =>
    setUserPreferences({
      tableSettings: {
        dashboardInactiveWorkersTable: {
          ...dashboardInactiveWorkersTable,
          ...updatedTableSettings,
        },
      },
    });

  return (
    <Table
      rowsSingleColor
      columns={inactiveColumns}
      loading={props.loading}
      dataSource={workersFiltered ?? props.workers}
      onChange={(pagination, filters, sorter, extra) => {
        appStore.analytics.track(SegmentKey.WorkersTableSettingsChanged, {pagination, filters, sorter, action: extra.action});
        if (extra.action === "sort" && !Array.isArray(sorter)) {
          handleInactiveWorkersTableSettingsChange({
            sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
            sortOrder: sorter.order,
          });
        }
      }}
      pagination={{
        showSizeChanger: true,
        pageSize: pageSize,
        onShowSizeChange: (_, size) =>
          handleInactiveWorkersTableSettingsChange({
            pageSize: size,
          }),
      }}
    />
  );
});
