import {useQuery} from "@tanstack/react-query";
import {Drawer, Space, Typography} from "antd";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import {ActiveWorkersTable} from "./active-workers-table/ActiveWorkersTable";
import styles from "./DashboardWorkersTable.module.scss";
import {InactiveWorkersTable} from "./inactive-workers-table/InactiveWorkersTable";
import {TargetUtilizationHoursModal} from "./target-utilization-hours-modal/TargetUtilizationHoursModal";
import targetIcon from "../../../../../assets/images/common/Target_Icon.svg";
import * as Models from "../../../../../core/models";
import {DepartmentModule} from "../../../../../core/modules/DepartmentModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {isNil} from "../../../../utils/FunctionUtils";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../common/button/Button";
import {WorkerDetail} from "../../../worker-detail/WorkerDetail";
import {ChangeDeviceModal} from "../../workers-tab-content/change-device-modal/ChangeDeviceModal";
import {DeviceNoWorker} from "../../workers-tab-content/device-no-worker/DeviceNoWorker";

export type DashboardWorkersTableProps = {
  activeWorkers: Models.AnalyticsDashboardWorker[];
  inactiveWorkers: Models.AnalyticsDashboardWorker[];
  loading: boolean;
  onOpenChangeWorkerStatusModal: (worker: Models.WorkerShort | Models.Worker) => void;
  onEditDevice: () => void;
  onEditTarget: () => void;
  onEditWorker: () => void;
  department: Models.Department;
};

export const DashboardWorkersTable: FunctionComponent<DashboardWorkersTableProps> = observer((props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [showChangeDeviceModal, setShowChangeDeviceModal] = useState(false);
  const [editingWorker, setEditingWorker] = useState<Models.WorkerShort | Models.Worker | null>(null);
  const [newDevice, setNewDevice] = useState<Models.DeviceShort | undefined>(undefined);
  const [showDeviceNoLongerWorker, setShowDeviceNoLongerWorker] = useState(false);
  const [visibleWorker, setVisibleWorker] = useState<Models.WorkerShort>();
  const [isTargetUtilizationHoursModalVisible, setIsTargetUtilizationHoursModalVisible] = useState(false);

  const [department, setDepartment] = useState<Models.Department>(props.department);

  const flags = useFlags();

  const departmentQuery = useQuery({
    queryKey: ["DashboardWorkersTable-fetchDepartmentsData", props.department.id],
    queryFn: () => {
      return DepartmentModule.department({
        accessToken: authenticationStore.state.accessToken!,
        id: props.department.id!,
      });
    },
    enabled: !!props.department.id,
  });

  useEffect(() => {
    if (!departmentQuery.data) return;
    if (!departmentQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("DashboardWorkersTable-errorFetchingWorkers"), departmentQuery.data.correlationId);
      return;
    }
    setDepartment(departmentQuery.data.department);
  }, [departmentQuery.data]);

  const handleChangeDevice = (worker: Models.WorkerShort | Models.Worker, device?: Models.DeviceShort) => {
    setShowChangeDeviceModal(true);
    setEditingWorker(worker);
    setNewDevice(device);
  };

  const handleDrawerClose = () => {
    setVisibleWorker(undefined);
  };

  const handleDelete = () => {
    handleDrawerClose();
    props.onEditWorker();
  };

  return (
    <>
      <Space className={styles.collapseContent} direction="vertical" size={16}>
        <div className={styles.activeHeader}>
          <Typography.Text strong>{WebHelper.formatMessage("DashboardWorkersTable-activeWorkersTitle")}</Typography.Text>
          {flags.target_utilization_hours && (
            <Button
              style={{display: "flex", alignItems: "center", gap: "6px"}}
              icon={<img src={targetIcon} alt="" />}
              shape="round"
              type={ButtonType.Default}
              onClick={() => setIsTargetUtilizationHoursModalVisible(true)}>
              {WebHelper.formatMessage("DashboardWorkersTable-targetDeviceUtilization")}
            </Button>
          )}
        </div>
        <ActiveWorkersTable
          department={department}
          workers={props.activeWorkers}
          loading={props.loading}
          onOpenChangeWorkerStatusModal={props.onOpenChangeWorkerStatusModal}
          onChangeDevice={handleChangeDevice}
          onWorkerSelected={setVisibleWorker}
        />
        <Typography.Text strong>{WebHelper.formatMessage("DashboardWorkersTable-inactiveWorkersTitle")}</Typography.Text>
        <InactiveWorkersTable
          workers={props.inactiveWorkers}
          loading={props.loading}
          onOpenChangeWorkerStatusModal={props.onOpenChangeWorkerStatusModal}
          onChangeDevice={handleChangeDevice}
          onWorkerSelected={setVisibleWorker}
          showTarget={department.target_type !== Models.TargetType.None}
        />
        {editingWorker && (
          <ChangeDeviceModal
            worker={editingWorker}
            open={showChangeDeviceModal}
            onClose={() => setShowChangeDeviceModal(false)}
            newDevice={newDevice}
            refetchWorkers={props.onEditDevice}
            setShowDeviceNoLongerWorker={setShowDeviceNoLongerWorker}
            entryPoint={SegmentEntryPoint.ACTIVE_INACTIVE_TABLE}
          />
        )}
        {flags.target_utilization_hours && (
          <TargetUtilizationHoursModal
            open={isTargetUtilizationHoursModalVisible}
            onSave={() => {
              setIsTargetUtilizationHoursModalVisible(false);
              props.onEditTarget();
              departmentQuery.refetch();
            }}
            onCancel={() => setIsTargetUtilizationHoursModalVisible(false)}
            department={department}
          />
        )}
        {showDeviceNoLongerWorker && editingWorker && editingWorker.assigned_device?.id && editingWorker.department && (
          <DeviceNoWorker
            deviceId={editingWorker.assigned_device?.id}
            deviceTag={editingWorker.assigned_device?.device_tag}
            departmentId={editingWorker.department?.id}
            onOk={() => {
              setShowDeviceNoLongerWorker(false);
              props.onEditDevice();
            }}
            onClose={() => setShowDeviceNoLongerWorker(false)}
            open={showDeviceNoLongerWorker}
            entryPoint={SegmentEntryPoint.ACTIVE_INACTIVE_TABLE}
          />
        )}
      </Space>
      <Drawer
        open={!isNil(visibleWorker)}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("WorkerDetail-drawerTitle")}
        destroyOnClose
        onClose={handleDrawerClose}>
        {!isNil(visibleWorker) && <WorkerDetail workerId={visibleWorker.id} onDelete={handleDelete} onEdit={props.onEditWorker} />}
      </Drawer>
    </>
  );
});
