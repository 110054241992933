import {SiteAPIClient} from "../apiclient/site/SiteAPIClient";
import * as SiteTypes from "../apiclient/site/SiteAPIClient.types";

async function sites(request: SiteTypes.SitesRequest): Promise<SiteTypes.SitesResponse> {
  return await SiteAPIClient.sites(request);
}

async function site(request: SiteTypes.SiteRequest): Promise<SiteTypes.SiteResponse> {
  return await SiteAPIClient.site(request);
}

async function createSite(request: SiteTypes.CreateSiteRequest): Promise<SiteTypes.CreateSiteResponse> {
  return await SiteAPIClient.createSite(request);
}

async function deleteSite(request: SiteTypes.DeleteSiteRequest): Promise<SiteTypes.DeleteSiteResponse> {
  return await SiteAPIClient.deleteSite(request);
}

async function updateSite(request: SiteTypes.UpdateSiteRequest): Promise<SiteTypes.UpdateSiteResponse> {
  return await SiteAPIClient.updateSite(request);
}

async function updateSiteConfiguration(
  request: SiteTypes.UpdateSiteConfigurationRequest
): Promise<SiteTypes.UpdateSiteConfigurationResponse> {
  return await SiteAPIClient.updateSiteConfiguration(request);
}

export const SiteModule = {
  sites,
  site,
  createSite,
  deleteSite,
  updateSite,
  updateSiteConfiguration,
};
