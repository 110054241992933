import {useMutation} from "@tanstack/react-query";
import {Modal, Select, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import styles from "./ChangeWorkerStatusModal.module.scss";
import * as Models from "../../../../../core/models";
import {UnenrollWorkerOtherReason} from "../../../../../core/models";
import {WorkerModule} from "../../../../../core/modules/WorkerModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../common/button/Button";
import {UnenrollmentReasons} from "../../../unenrollment-reasons/UnenrollmentReasons";

export type ChangeWorkerStatusModalProps = {
  worker?: Models.Worker | Models.WorkerShort;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  entryPoint: SegmentEntryPoint;
};

type CheckboxValueType = string | number | boolean;

export const ChangeWorkerStatusModal: FunctionComponent<ChangeWorkerStatusModalProps> = observer(
  ({worker, open, onOk, onCancel, entryPoint}) => {
    const authenticationStore = AuthenticationDataStore.getInstance();
    const appStore = AppStore.getInstance();

    const [selectedUnenrollWorkerReason, setSelectedUnenrollWorkerReason] = useState<Models.UnenrollWorkerMainReason>(
      Models.UnenrollWorkerMainReason.Unknown
    );
    const [selectedOtherMainReason, setSelectedOtherMainReason] = useState<UnenrollWorkerOtherReason | undefined>(undefined);
    const [unenrollmentReasons, setUnenrollmentReasons] = useState<CheckboxValueType[]>([]);
    const [otherUnenrollmentReason, setOtherUnenrollmentReason] = useState<string | undefined>();
    const [otherUnenrollmentReasonSecondary, setOtherUnenrollmentReasonSecondary] = useState<string | undefined>();
    const [currentStep, setCurrentStep] = useState(Models.UnenrollmentStepsFlow.zero);
    const [glossaryPreviousStep, setGlossaryPreviousStep] = useState(Models.UnenrollmentStepsFlow.first);

    const changeWorkerStatusMutation = useMutation({
      mutationFn: (worker: Models.Worker | Models.WorkerShort) =>
        WorkerModule.assignDeviceToWorker({
          accessToken: authenticationStore.state.accessToken!,
          worker_id: worker.id,
          assignable: !worker.assignable,
          primary_unenrollment_reason: worker.assignable
            ? selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other
              ? otherUnenrollmentReason ?? selectedOtherMainReason
              : selectedUnenrollWorkerReason
            : undefined,
          unenrollment_reasons: worker.assignable
            ? selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other
              ? otherUnenrollmentReasonSecondary
                ? (unenrollmentReasons as string[]).concat(otherUnenrollmentReasonSecondary)
                : (unenrollmentReasons as string[])
              : undefined
            : undefined,
        }),
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("ChangeWorkerStatusModal-changeStatusError"), response.correlationId);
          return;
        }

        appStore.sendAnalyticTrack(SegmentKey.ChangeWorkerEnrollmentStatus, {
          departmentID: response.worker.department?.id,
          siteID: response.worker.site?.id,
          orgID: response.worker.organization?.id,
          endAssignable: response.worker.assignable,
          entryPoint,
        });

        appStore.showMessage(
          WebHelper.formatMessage(
            response.worker.assignable
              ? "ChangeWorkerStatusModal-successMessageEnrolled"
              : "ChangeWorkerStatusModal-successMessageUnenrolled",
            {
              worker: response.worker.displayName,
            }
          ),
          "success"
        );
        onOk();
      },
    });

    const handleCancel = () => {
      setSelectedUnenrollWorkerReason(Models.UnenrollWorkerMainReason.Unknown);
      onCancel();
    };

    const handleNext = () => {
      if (currentStep === Models.UnenrollmentStepsFlow.zero) {
        setCurrentStep(Models.UnenrollmentStepsFlow.first);
      } else if (currentStep === Models.UnenrollmentStepsFlow.first) setCurrentStep(Models.UnenrollmentStepsFlow.second);
    };

    const handleBack = () => {
      if (currentStep === Models.UnenrollmentStepsFlow.first) {
        setCurrentStep(Models.UnenrollmentStepsFlow.zero);
      }
      if (currentStep === Models.UnenrollmentStepsFlow.second) {
        setCurrentStep(Models.UnenrollmentStepsFlow.first);
      }
      if (currentStep === Models.UnenrollmentStepsFlow.glossary) {
        setCurrentStep(glossaryPreviousStep);
        setGlossaryPreviousStep(Models.UnenrollmentStepsFlow.first);
      }
    };

    const handleOpenGlossary = () => {
      setGlossaryPreviousStep(currentStep);
      setCurrentStep(Models.UnenrollmentStepsFlow.glossary);
    };

    return (
      <Modal
        centered
        open={open && !!worker}
        destroyOnClose={true}
        title={WebHelper.formatMessage("ChangeWorkerStatusModal-title")}
        onCancel={handleCancel}
        styles={{body: currentStep === Models.UnenrollmentStepsFlow.zero ? {} : {height: "70vh", overflowY: "scroll"}}}
        footer={
          <>
            {currentStep !== Models.UnenrollmentStepsFlow.glossary && (
              <Button shape="round" disabled={changeWorkerStatusMutation.isPending} onClick={handleCancel}>
                {WebHelper.formatMessage("Common-cancel")}
              </Button>
            )}
            {selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other &&
              currentStep !== Models.UnenrollmentStepsFlow.zero && (
                <Button
                  shape="round"
                  type={currentStep === Models.UnenrollmentStepsFlow.glossary ? ButtonType.Primary : ButtonType.Default}
                  disabled={changeWorkerStatusMutation.isPending}
                  onClick={handleBack}>
                  {WebHelper.formatMessage("Common-back")}
                </Button>
              )}
            {currentStep !== Models.UnenrollmentStepsFlow.second &&
              currentStep !== Models.UnenrollmentStepsFlow.glossary &&
              selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other && (
                <Button
                  disabled={
                    currentStep === Models.UnenrollmentStepsFlow.first && !selectedOtherMainReason && !otherUnenrollmentReason?.trim()
                  }
                  type={ButtonType.Primary}
                  shape="round"
                  loading={changeWorkerStatusMutation.isPending}
                  onClick={handleNext}>
                  {WebHelper.formatMessage("Common-next")}
                </Button>
              )}
            {(currentStep === Models.UnenrollmentStepsFlow.second ||
              selectedUnenrollWorkerReason !== Models.UnenrollWorkerMainReason.Other) &&
              currentStep !== Models.UnenrollmentStepsFlow.glossary && (
                <Button
                  type={ButtonType.Primary}
                  shape="round"
                  loading={changeWorkerStatusMutation.isPending}
                  onClick={() => (worker ? changeWorkerStatusMutation.mutate(worker) : null)}>
                  {WebHelper.formatMessage("Common-save")}
                </Button>
              )}
          </>
        }>
        {worker && (
          <>
            <Space className={styles.container} direction="vertical" size={24}>
              {currentStep === Models.UnenrollmentStepsFlow.zero && (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: WebHelper.parseMarkdown(
                        WebHelper.formatMessage(
                          worker.assignable ? "ChangeWorkerStatusModal-descriptionUnenroll" : "ChangeWorkerStatusModal-descriptionEnroll",
                          {
                            worker: worker.displayName,
                          }
                        )
                      ),
                    }}
                  />
                  {worker.assignable && (
                    <div className={styles.selectWrapper}>
                      <Typography.Text className={styles.reasonsLabel}>
                        {WebHelper.formatMessage("ChangeWorkerStatusModal-reasonsLabel")}
                      </Typography.Text>
                      <Select
                        disabled={changeWorkerStatusMutation.isPending}
                        className={styles.select}
                        value={selectedUnenrollWorkerReason}
                        optionFilterProp="label"
                        optionLabelProp="label"
                        onSelect={setSelectedUnenrollWorkerReason}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        options={Object.values(Models.UnenrollWorkerMainReason).map((option) => {
                          return {
                            key: option,
                            value: option,
                            label: WebHelper.getUnenrollWorkerMainReasonLabel(option),
                          };
                        })}
                        data-cy="unenrollReasonSelector"
                      />
                    </div>
                  )}
                </>
              )}

              {worker.assignable &&
                selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other &&
                currentStep !== Models.UnenrollmentStepsFlow.zero && (
                  <UnenrollmentReasons
                    worker={worker}
                    currentStep={currentStep}
                    onCheckedValuesChange={setUnenrollmentReasons}
                    onOtherReasonValueChange={
                      currentStep === Models.UnenrollmentStepsFlow.first ? setOtherUnenrollmentReason : setOtherUnenrollmentReasonSecondary
                    }
                    otherReasonValue={
                      currentStep === Models.UnenrollmentStepsFlow.first ? otherUnenrollmentReason : otherUnenrollmentReasonSecondary
                    }
                    mainReason={selectedOtherMainReason}
                    disabled={changeWorkerStatusMutation.isPending}
                    onChangeOtherMainReason={setSelectedOtherMainReason}
                    otherMainReasonValue={selectedOtherMainReason}
                    setGlossaryStep={handleOpenGlossary}
                  />
                )}
            </Space>
          </>
        )}
      </Modal>
    );
  }
);
