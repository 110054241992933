import {useMutation, useQuery} from "@tanstack/react-query";
import {Empty, Modal, Select, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo, useState} from "react";

import styles from "./AddWorkersModal.module.scss";
import * as Models from "../../../../../../core/models";
import {WorkerModule} from "../../../../../../core/modules/WorkerModule";
import {WorkerReportGroupModule} from "../../../../../../core/modules/WorkerReportGroupModule";
import {AuthenticationDataStore} from "../../../../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../../../../stores/AppStore";
import {isEmpty, isNil} from "../../../../../utils/FunctionUtils";
import {SegmentEntryPoint} from "../../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../../utils/WebHelper";
import {DiscardChangesModal} from "../../../../discard-changes-modal/DiscardChangesModal";

type AddWorkersModalProps = {
  open: boolean;
  onOk: (workerGroup: Models.WorkerReportGroup) => void;
  onCancel: () => void;
  workerReportGroup: Models.WorkerReportGroup;
};

export const AddWorkersModal: FunctionComponent<AddWorkersModalProps> = observer(({open, onOk, onCancel, workerReportGroup}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);

  const updateWorkerGroupMutation = useMutation({
    mutationFn: () =>
      WorkerReportGroupModule.modifyWorkerReportGroupWorkers({
        accessToken: authenticationStore.state.accessToken!,
        id: workerReportGroup.id,
        worker_ids: [...(workerReportGroup.workers?.map((worker) => worker.id) ?? []), ...selectedWorkers],
      }),
    onSuccess: (response) => {
      if (response.success) {
        appStore.sendAnalyticTrack(SegmentKey.AddWorkerFromGroupBulk, {
          siteID: response.workerReportGroup.site.id,
          orgID: response.workerReportGroup.organization.id,
          groupID: response.workerReportGroup.id,
          quantity: selectedWorkers.length,
          entryPoint: SegmentEntryPoint.BULK_ADD_WORKERS,
        });

        appStore.showMessage(WebHelper.formatMessage("AddWorkersModal-successMessage"), "success");
        onOk(response.workerReportGroup);
        setSelectedWorkers([]);
      } else WebHelper.showErrorMessage(WebHelper.formatMessage("AddWorkersModal-errorMessage"), response.correlationId);
    },
  });

  const {data: workersQueryData, isPending: workersQueryIsLoading} = useQuery({
    queryKey: ["AddWorkersModal-fetchWorkersData"],
    queryFn: () =>
      WorkerModule.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        site_id: workerReportGroup.site.id,
      }),
  });

  const handleCancel = () => {
    if (updateWorkerGroupMutation.isPending) return;

    if (!isDiscardChangesModalVisible && !isEmpty(selectedWorkers)) setIsDiscardChangesModalVisible(true);
    else {
      setSelectedWorkers([]);
      setIsDiscardChangesModalVisible(false);
      onCancel();
    }
  };

  const workersOptions: {key: string; label: string; value: string}[] = useMemo(() => {
    const options: any = [];

    workersQueryData?.workers?.forEach(({id, displayName}) => {
      if (isNil(workerReportGroup.workers?.find((worker) => worker.id === id))) options.push({key: id, label: displayName, value: id});
    });

    return options;
  }, [workerReportGroup.workers, workersQueryData?.workers]);

  return (
    <>
      <Modal
        centered
        zIndex={1001}
        destroyOnClose={true}
        open={open}
        title={WebHelper.formatMessage("AddWorkersModal-title")}
        okText={WebHelper.formatMessage("Common-add")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onOk={() => updateWorkerGroupMutation.mutate()}
        onCancel={handleCancel}
        okButtonProps={{
          shape: "round",
          disabled: selectedWorkers.length === 0,
          loading: updateWorkerGroupMutation.isPending,
        }}
        cancelButtonProps={{shape: "round", disabled: updateWorkerGroupMutation.isPending}}>
        <div className={styles.container}>
          <Typography.Text>{WebHelper.formatMessage("AddWorkersModal-selectWorkersLabel")}</Typography.Text>
          <div className={styles.selectWrapper}>
            <Typography.Text className={styles.workersLabel}>{WebHelper.formatMessage("AddWorkersModal-workers")}</Typography.Text>
            <Select
              value={selectedWorkers}
              className={styles.select}
              mode="multiple"
              allowClear
              loading={workersQueryIsLoading}
              disabled={workersQueryIsLoading}
              options={workersOptions}
              optionFilterProp="label"
              placeholder={WebHelper.formatMessage("Common-select")}
              notFoundContent={
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={WebHelper.formatMessage("AddWorkersModal-noWorkersText")} />
              }
              onChange={(values) => setSelectedWorkers(values)}
              filterSort={(a, b) => a.label.localeCompare(b.label)}
            />
          </div>
        </div>
      </Modal>
      <DiscardChangesModal
        onDiscard={handleCancel}
        onClose={() => setIsDiscardChangesModalVisible(false)}
        open={isDiscardChangesModalVisible}
      />
    </>
  );
});
