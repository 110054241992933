import {WorkerAPIClient} from "../apiclient/worker/WorkerAPIClient";
import * as WorkerTypes from "../apiclient/worker/WorkerAPIClient.types";

async function workers(request: WorkerTypes.WorkersRequest): Promise<WorkerTypes.WorkersResponse> {
  return await WorkerAPIClient.workers(request);
}

async function workersShort(request: WorkerTypes.WorkersRequest): Promise<WorkerTypes.WorkersShortResponse> {
  return await WorkerAPIClient.workersShort(request);
}

async function worker(request: WorkerTypes.WorkerRequest): Promise<WorkerTypes.WorkerResponse> {
  return await WorkerAPIClient.worker(request);
}

async function createWorker(request: WorkerTypes.CreateWorkerRequest): Promise<WorkerTypes.CreateWorkerResponse> {
  return await WorkerAPIClient.createWorker(request);
}

async function deleteWorker(request: WorkerTypes.DeleteWorkerRequest): Promise<WorkerTypes.DeleteWorkerResponse> {
  return await WorkerAPIClient.deleteWorker(request);
}

async function updateWorker(request: WorkerTypes.UpdateWorkerRequest): Promise<WorkerTypes.UpdateWorkerResponse> {
  return await WorkerAPIClient.updateWorker(request);
}

async function createWorkers(request: WorkerTypes.CreateWorkersRequest): Promise<WorkerTypes.CreateWorkersResponse> {
  return await WorkerAPIClient.createWorkers(request);
}

async function allocateWorkerToDepartment(
  request: WorkerTypes.AllocateWorkerToDepartmentRequest
): Promise<WorkerTypes.AllocateWorkerToDepartmentResponse> {
  return await WorkerAPIClient.allocateWorkerToDepartment(request);
}

async function assignDeviceToWorker(request: WorkerTypes.AssignDeviceToWorkerRequest): Promise<WorkerTypes.AssignDeviceToWorkerResponse> {
  return await WorkerAPIClient.assignDeviceToWorker(request);
}

async function reportWorkerIndividual(
  request: WorkerTypes.ReportWorkerIndividualRequest
): Promise<WorkerTypes.ReportWorkerIndividualResponse> {
  return await WorkerAPIClient.reportWorkerIndividual(request);
}

async function updateWorkerReportGroups(
  request: WorkerTypes.UpdateWorkerReportGroupsRequest
): Promise<WorkerTypes.UpdateWorkerReportGroupsResponse> {
  return await WorkerAPIClient.updateWorkerReportGroups(request);
}

async function activeWorkers(request: WorkerTypes.ActiveWorkersRequest): Promise<WorkerTypes.ActiveWorkersResponse> {
  return await WorkerAPIClient.activeWorkers(request);
}

export const WorkerModule = {
  workers,
  workersShort,
  worker,
  createWorker,
  deleteWorker,
  updateWorker,
  createWorkers,
  allocateWorkerToDepartment,
  assignDeviceToWorker,
  reportWorkerIndividual,
  updateWorkerReportGroups,
  activeWorkers,
};
