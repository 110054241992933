import {useQuery} from "@tanstack/react-query";
import {Modal, Space, Typography} from "antd";
import {minutesToMilliseconds} from "date-fns";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import styles from "./AboutUs.module.scss";
import companyIcon from "../../../assets/images/common/Verve_Logo_SafetyOrange.svg";
import {AppConfig} from "../../../core/AppConfig";
import * as Models from "../../../core/models";
import {DataStore} from "../../../core/stores/DataStore";
import {WebHelper} from "../../utils/WebHelper";
import {Loading} from "../loading/Loading";

export type AboutUsProps = {
  onClose?: () => void;
  visible: boolean;
};

export const AboutUs: FunctionComponent<AboutUsProps> = observer(({onClose, visible}) => {
  const dataStore = DataStore.getInstance();

  const [backendVersion, setBackendVersion] = useState<Models.BackendVersion>();

  const backendVersionQuery = useQuery({
    queryKey: ["AboutUs-FetchBackendVersion", visible],
    queryFn: () => dataStore.backendVersion({}),
    enabled: visible,
    staleTime: minutesToMilliseconds(1),
  });

  useEffect(() => {
    const responseData = backendVersionQuery.data;

    if (!responseData) return;

    if (!responseData.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("AboutUs-fetchError"), responseData.correlationId);
    } else {
      setBackendVersion(responseData.backendVersion);
    }
  }, [backendVersionQuery.data]);

  return (
    <Modal footer={null} onCancel={onClose} open={visible}>
      {backendVersionQuery.isPending ? (
        <Loading />
      ) : (
        <Space align="center" className={styles.space} direction="vertical">
          <img className={styles.companyIcon} src={companyIcon} alt="Company Logo" />
          <Typography.Text className={styles.subtitle}>{WebHelper.formatMessage("AboutUs-subtitle")}</Typography.Text>
          <Typography.Text>{WebHelper.formatMessage("AboutUs-copyright", {year: new Date().getFullYear()})}</Typography.Text>
          <Typography.Text>
            {WebHelper.formatMessage("AboutUs-appVersion", {
              currentCommitHash: AppConfig.Settings.Git.currentCommitHash,
              version: AppConfig.Settings.App.version,
            })}
          </Typography.Text>
          {backendVersion && (
            <Typography.Text>
              {WebHelper.formatMessage("AboutUs-backendVersion", {
                currentCommitHash: backendVersion.git_commit,
                version: backendVersion.git_tags,
              })}
            </Typography.Text>
          )}
        </Space>
      )}
    </Modal>
  );
});
