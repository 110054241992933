import {Form, FormInstance, Input, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {AppConfig} from "../../../AppConfig";
import {WebHelper} from "../../../utils/WebHelper";

export type DepartmentFormFields = {
  name: string;
};

type DepartmentFormProps = {
  initialValues?: Models.Department;
  form: FormInstance<DepartmentFormFields>;
  loading: boolean;
  onFieldsChange: () => void;
};

export const DepartmentForm: FunctionComponent<DepartmentFormProps> = observer(({initialValues, form, loading, onFieldsChange}) => {
  const {departmentForm: departmentInputValidation} = AppConfig.Modules.InputValidation;

  return (
    <Form
      form={form}
      labelWrap={true}
      labelCol={{span: 4}}
      wrapperCol={{span: 20}}
      disabled={loading}
      onFieldsChange={onFieldsChange}
      unselectable="off">
      <Form.Item
        colon={false}
        initialValue={initialValues?.name}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-name")}</Typography.Text>}
        name="name"
        rules={WebHelper.formatInputValidationRules(departmentInputValidation.name.rules)}>
        <Input id="departmentNameInput-departmentModal" />
      </Form.Item>
    </Form>
  );
});
