import {EditOutlined, FileTextOutlined, FilterOutlined} from "@ant-design/icons";
import {Drawer, Tooltip} from "antd";
import {ColumnsType} from "antd/lib/table";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useMemo, useState} from "react";

import styles from "./SessionsTable.module.scss";
import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import useUserPreferences from "../../../web/hooks/useUserPreferences";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {isNil, isEqual, uniqWith} from "../../utils/FunctionUtils";
import {dateSorter, getColumnSearchProps} from "../../utils/TableUtils";
import {WebHelper} from "../../utils/WebHelper";
import {Table} from "../common/table/Table";
import {EditSessionAssignment} from "../edit-session-assignment/EditSessionAssignment";
import {SessionLog} from "../session-log/SessionLog";

const defaultSettings: Models.SessionsTableSettings = {
  pageSize: 10,
  sortColumn: "start_time",
  sortOrder: "descend",
  statusFilteredValue: null,
  idFilteredValue: null,
  uuidFilteredValue: null,
  assignedWorkerFilteredValue: null,
};

export type SessionsTableProps = {
  sessions: Models.SessionShort[];
  device: Models.Device;
  loading: boolean;
  onUpdate: () => void;
};

export const SessionsTable: FunctionComponent<SessionsTableProps> = observer((props) => {
  const appStore = AppStore.getInstance();
  const authStore = AuthenticationDataStore.getInstance();

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const [sessionsTableSettings, setSessionsTableSettings] = useState<Models.SessionsTableSettings>(defaultSettings);

  const sessionsTablePreferences: Models.SessionsTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.sessions ?? {})}),
    [userPreferences]
  );

  const [selectedSession, setSelectedSession] = useState<Models.SessionShort | null>(null);
  const [isEditSessionAssignmentVisible, setIsEditSessionAssignmentVisible] = useState(false);
  const [isLogDrawerVisible, setIsLogDrawerVisible] = useState(false);

  const handleCloseEditSessionAssignment = useCallback(() => {
    setIsEditSessionAssignmentVisible(false);
    setSelectedSession(null);
  }, []);

  const handleCloseDownloadLog = useCallback(() => {
    setIsLogDrawerVisible(false);
    setSelectedSession(null);
  }, []);

  const filterOptionsForAssignedWorker = () => {
    const assignedWorkers = uniqWith(
      props.sessions
        .filter((session) => !isNil(session.worker_id))
        .map((session) => {
          return {text: session!.displayName, value: session!.displayName};
        }),
      isEqual
    );

    const defaultOptions = [
      {
        value: "",
        text: WebHelper.formatMessage("SessionsTable-none"),
      },
    ];

    return defaultOptions.concat(assignedWorkers.sort((a, b) => a.text.localeCompare(b.text)));
  };

  const {sortColumn, sortOrder, statusFilteredValue, idFilteredValue, uuidFilteredValue, assignedWorkerFilteredValue} =
    sessionsTableSettings;

  const {pageSize} = sessionsTablePreferences;

  const columns: ColumnsType<Models.SessionShort> = [
    {
      title: WebHelper.formatMessage("SessionsTable-status"),
      dataIndex: "status",
      filters: [
        {value: Models.SessionStatus.Processed, text: WebHelper.formatMessage("SessionsTable-processedLabel")},
        {value: Models.SessionStatus.Uploaded, text: WebHelper.formatMessage("SessionsTable-uploadedLabel")},
        {value: Models.SessionStatus.Created, text: WebHelper.formatMessage("SessionsTable-createdLabel")},
      ],
      filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
      filteredValue: statusFilteredValue,
      onFilter: (value, record) => record.status === value,
      filterSearch: true,
      render: (value) => {
        switch (value) {
          case Models.SessionStatus.Processed:
            return WebHelper.formatMessage("SessionsTable-processedLabel");
          case Models.SessionStatus.Uploaded:
            return WebHelper.formatMessage("SessionsTable-uploadedLabel");
          case Models.SessionStatus.Created:
            return WebHelper.formatMessage("SessionsTable-createdLabel");
          default:
            return null;
        }
      },
    },
    {
      title: WebHelper.formatMessage("SessionsTable-startTime"),
      dataIndex: "start_time",
      render: (_, record) => (record.start_time ? WebHelper.formatDate(record.start_time, props.device.site?.tz_location) : null),
      sorter: (a, b) => dateSorter(a.start_time, b.start_time),
      sortOrder: sortColumn === "start_time" ? sortOrder : undefined,
    },
    {
      title: WebHelper.formatMessage("SessionsTable-id"),
      dataIndex: "id",
      filteredValue: idFilteredValue,
      ...getColumnSearchProps<Models.SessionShort>("id", idFilteredValue ? idFilteredValue.toString() : ""),
      filterSearch: true,
    },
    {
      title: WebHelper.formatMessage("SessionsTable-uuid"),
      dataIndex: "session_uuid",
      filteredValue: uuidFilteredValue,
      ...getColumnSearchProps<Models.SessionShort>("session_uuid", uuidFilteredValue ? uuidFilteredValue.toString() : ""),
      filterSearch: true,
    },
    {
      title: WebHelper.formatMessage("SessionsTable-worker"),
      dataIndex: "assigned_worker",
      render: (value, record) => {
        const text = record.worker_id ? record.displayName : WebHelper.formatMessage("SessionsTable-none");

        return (
          <span>
            <span>{text}</span>
            {(authStore.permissionLevel(Models.UserScope.Department, props.device.department_id ?? undefined) ===
              Models.UserAccessLevel.Admin ||
              authStore.permissionLevel(Models.UserScope.Department, props.device.department_id ?? undefined) ===
                Models.UserAccessLevel.Manager) &&
              record.status === Models.SessionStatus.Processed && (
                <EditOutlined
                  className={styles.editWorkerIcon}
                  onClick={() => {
                    setSelectedSession(record);
                    setIsEditSessionAssignmentVisible(true);
                  }}
                  data-cy={`editAssignedButton-${record.id}`}
                />
              )}
          </span>
        );
      },
      filters: filterOptionsForAssignedWorker(),
      filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
      filteredValue: assignedWorkerFilteredValue,
      onFilter: (value, record) => {
        switch (value) {
          case "":
            return !record.worker_id;
          default:
            return !record.displayName === value;
        }
      },
      filterSearch: true,
    },
    {
      title: WebHelper.formatMessage("SessionsTable-lifts"),
      dataIndex: "total_lifts",
      sorter: (a, b) => a.total_lifts - b.total_lifts,
      sortOrder: sortColumn === "total_lifts" ? sortOrder : undefined,
    },
    {
      title: WebHelper.formatMessage("SessionsTable-duration"),
      dataIndex: "duration_sec",
      render: (duration_sec) => {
        if (!duration_sec || duration_sec < 0) return 0;
        return WebHelper.getDurationFormattedBySeconds(duration_sec);
      },
      sorter: (a, b) => a.duration_sec - b.duration_sec,
      sortOrder: sortColumn === "duration_sec" ? sortOrder : undefined,
    },
    {
      title: WebHelper.formatMessage("SessionsTable-entries"),
      dataIndex: "log_lines",
      sorter: (a, b) => a.log_lines - b.log_lines,
      sortOrder: sortColumn === "log_lines" ? sortOrder : undefined,
    },
    {
      title: WebHelper.formatMessage("SessionsTable-errors"),
      dataIndex: "error_count",
      sorter: (a, b) => a.error_count - b.error_count,
      sortOrder: sortColumn === "error_count" ? sortOrder : undefined,
      render: (_, session) => (
        <Tooltip
          title={
            session.errors_group ? (
              <>
                {session.errors_group.session_errors.map((error, index) => (
                  <div key={index}>
                    {error.segments
                      ? error.segments.map((segment, segIndex) => (
                          <div key={segIndex}>{`${segment.count}: ${error.code} - ${segment.name}`}</div>
                        ))
                      : `${error.count}: ${error.code}`}
                  </div>
                ))}
              </>
            ) : (
              ""
            )
          }>
          <span>{session.error_count}</span>
        </Tooltip>
      ),
    },
    {
      title: WebHelper.formatMessage("SessionsTable-logs"),
      dataIndex: "logs",
      render: (_, record) => (
        <FileTextOutlined
          id={`logsIcon_${record.id}`}
          onClick={() => {
            setIsLogDrawerVisible(true);
            setSelectedSession(record);
          }}
        />
      ),
    },
  ];

  const handleSettingsChange = (updatedSettings: Partial<Models.SessionsTableSettings>) =>
    setSessionsTableSettings({...sessionsTableSettings, ...updatedSettings});

  return (
    <>
      <Table
        columns={columns}
        loading={props.loading}
        dataSource={props.sessions}
        onChange={(pagination, filters, sorter, extra) => {
          appStore.sendAnalyticTrack(SegmentKey.SessionsTableSettingsChanged, {pagination, filters, sorter, action: extra.action});
          if (extra.action === "filter") {
            handleSettingsChange({
              idFilteredValue: filters.id,
              uuidFilteredValue: filters.session_uuid,
              assignedWorkerFilteredValue: filters.assigned_worker,
              statusFilteredValue: filters.status,
            });
          }
          if (extra.action === "sort" && !Array.isArray(sorter)) {
            handleSettingsChange({
              sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
              sortOrder: sorter.order,
            });
          }
        }}
        pagination={{
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (_, size) =>
            setUserPreferences({
              tableSettings: {
                sessions: {
                  ...sessionsTablePreferences,
                  ...{
                    pageSize: size,
                  },
                },
              },
            }),
        }}
      />

      {!isNil(selectedSession) && (
        <EditSessionAssignment
          open={isEditSessionAssignmentVisible}
          device={props.device}
          session={selectedSession}
          onClose={handleCloseEditSessionAssignment}
          onUpdate={props.onUpdate}
        />
      )}
      <Drawer
        className={styles.drawer}
        open={isLogDrawerVisible}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("SessionLog-title")}
        destroyOnClose
        onClose={handleCloseDownloadLog}>
        {selectedSession && <SessionLog session={selectedSession} device={props.device} />}
      </Drawer>
    </>
  );
});
