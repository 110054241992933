import {makeObservable, observable, computed, action, runInAction} from "mobx";
import intl, {ReactIntlUniversalOptions} from "react-intl-universal";

import {AuthenticationDataStore} from "./AuthenticationDataStore";
import {BaseAPIClient} from "../apiclient/BaseAPIClient";
import * as BaseTypes from "../apiclient/BaseAPIClient.types";
import {AppConfig} from "../AppConfig";
import {Locale} from "../locales/Locale";

export class DataStore {
  private static instance: DataStore | undefined;

  private initializing = false;
  private initialized = false;

  currentLocale: Locale = undefined as any;

  private authenticationStore = AuthenticationDataStore.getInstance();

  private constructor() {
    makeObservable<DataStore, "initializing" | "initialized" | "loadLocale">(this, {
      initializing: observable,
      initialized: observable,
      currentLocale: observable,
      isInitializing: computed,
      isInitialized: computed,
      initialize: action,
      loadLocale: action,
      setLocale: action,
      reset: action,
      backendVersion: action,
    });
  }

  static getInstance(): DataStore {
    if (!DataStore.instance) DataStore.instance = new DataStore();

    return DataStore.instance;
  }

  get isInitializing(): boolean {
    return this.initializing;
  }

  get isInitialized(): boolean {
    return this.initialized;
  }

  async initialize(): Promise<any> {
    this.initializing = true;

    await this.loadLocale();

    this.authenticationStore.initialize();

    runInAction(() => {
      this.initializing = false;
      this.initialized = true;
    });
  }

  private async loadLocale(locale?: Locale) {
    const {currentLocale} = this;

    const nextLocale = locale || AppConfig.Settings.Localization.defaultLocale;

    if (currentLocale && nextLocale && nextLocale.code === currentLocale.code) return;

    this.currentLocale = nextLocale;

    const hyphenIndex = nextLocale.code.indexOf("-");
    const dateFnsLocale = hyphenIndex === -1 ? nextLocale.code : nextLocale.code.substr(0, hyphenIndex);
    window.__localeId__ = dateFnsLocale;

    const locales: Record<string, Locale> = {};

    for (const l of AppConfig.Settings.Localization.locales as Locale[]) locales[l.code] = l;

    const intlOptions: ReactIntlUniversalOptions = {
      currentLocale: nextLocale.code,
      locales,
      warningHandler: (warning: string, errorMessage?: string) => {
        if (process.env.NODE_ENV === "development") {
          if (errorMessage) console.error(`${warning} (${errorMessage})`);
          else console.warn(`${warning}`);
        }
      },
    };
    await intl.init(intlOptions);
  }

  async setLocale(locale: Locale) {
    const {currentLocale} = this;

    if (!currentLocale || currentLocale.code !== locale.code) await this.loadLocale(locale);
  }

  reset() {
    this.authenticationStore.reset();
  }

  async backendVersion(request: BaseTypes.BackendVersionRequest): Promise<BaseTypes.BackendVersionResponse> {
    return await BaseAPIClient.backendVersion(request);
  }
}
