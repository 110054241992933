import {assign, isNil, isString} from "../../web/utils/FunctionUtils";

export class BaseModel {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  static fixObjectFromJSON(object: any, json: any) {}

  static fromJSON(json: any): any {
    const result = new this();

    assign(result, json);
    this.fixObjectFromJSON(result, json);

    return result;
  }

  static arrayFromJSON(json: any | string): any[] {
    const result: any = [];
    if (isNil(json)) return result;

    if (isString(json)) {
      const object = JSON.parse(json);

      return this.arrayFromJSON(object);
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    json.forEach((object: Object) => {
      const item = _this.fromJSON(object);
      result.push(item);
    });

    return result;
  }
}
