import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {DepartmentInSite} from "./DepartmentInSite";

export enum Weekday {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export type SiteConfiguration = {
  id: string;
  site_id: string;
  weekday_report: Weekday;
  time_report: string;
  active_list_report_included: boolean;
  leaderboard_report_included: boolean;
  weight_offloaded_report_included: boolean;
  safelift_score_report_included: boolean;
  override_groups: boolean;
  groups: {id: string; name: string}[];
};

export class Site extends BaseModel {
  id: string;
  name: string;
  address: string;
  organization_id: string;
  organization: {id: string; name: string};
  tz_location: string;
  site_configuration: SiteConfiguration | null;
  real_time_data_enabled: boolean;
  departments: DepartmentInSite[] | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  // the following fields are calculated
  numberOfDepartments?: number;
  numberOfDevices?: number;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
      address: observable,
      organization_id: observable,
      organization: observable,
      site_configuration: observable,
      real_time_data_enabled: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: Site, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
  }
}
