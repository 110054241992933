import {useQuery} from "@tanstack/react-query";
import {Form, FormInstance, Input, Select, Switch, Typography} from "antd";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useMemo, useState} from "react";

import styles from "./WorkerForm.module.scss";
import * as Models from "../../../../../../core/models";
import {DeviceModule} from "../../../../../../core/modules/DeviceModule";
import {WorkerReportGroupModule} from "../../../../../../core/modules/WorkerReportGroupModule";
import {AuthenticationDataStore} from "../../../../../../core/stores/AuthenticationDataStore";
import {isNil} from "../../../../../utils/FunctionUtils";
import {WebHelper} from "../../../../../utils/WebHelper";
import {DatePicker} from "../../../../date-picker/DatePicker";
import {WorkerFormFields} from "../WorkerCreationManualModal";

type WorkerFormProps = {
  siteId: string;
  department: Models.Department;
  siteName: string;
  workers: (Models.Worker | Models.WorkerShort)[];
  form: FormInstance<WorkerFormFields>;
  setIsCreateButtonDisabled: (disabled: boolean) => void;
  creationLoading: boolean;
};

export const WorkerForm: FunctionComponent<WorkerFormProps> = observer(
  ({workers, department, siteName, siteId, form, setIsCreateButtonDisabled, creationLoading}) => {
    const authenticationStore = AuthenticationDataStore.getInstance();

    const flags = useFlags();

    const [workerReportGroups, setWorkerReportGroups] = useState<Models.WorkerReportGroup[]>([]);
    const [availableDevices, setAvailableDevices] = useState<Models.DeviceShort[]>([]);
    const [exemptTarget, setExemptTarget] = useState(false);
    const [validationError, setValidationError] = useState<{
      message: string;
      formItemsWithError?: {firstName?: true; lastName?: true; employeeID?: true; device?: true};
    }>();

    const validator = useCallback(() => {
      const formFirstName = form.getFieldValue("first_name")?.trim().toLowerCase();
      const formLastName = form.getFieldValue("last_name")?.trim().toLowerCase();
      const formEmployeeID = form.getFieldValue("employee_id")?.trim().toLowerCase();
      const formExemptTarget = form.getFieldValue("exempt_target");
      const targetHoursStartDate = form.getFieldValue("targetHoursStartDate");

      if (!formFirstName && !formLastName && !formEmployeeID) {
        setValidationError({
          message: WebHelper.formatMessage("ManualWorkerCreation-ifNoNameRequireEmpIdValidation"),
          formItemsWithError: {employeeID: true},
        });
        setIsCreateButtonDisabled(true);
        return;
      }

      if ((!formFirstName && formLastName) || (!formLastName && formFirstName)) {
        setValidationError({
          message: WebHelper.formatMessage("ManualWorkerCreation-bothNamesRequiredValidation"),
          formItemsWithError: {firstName: true, lastName: true},
        });
        setIsCreateButtonDisabled(true);
        return;
      }

      if (
        flags.target_utilization_hours &&
        department.target_type &&
        department.target_type !== Models.TargetType.None &&
        !formExemptTarget &&
        !targetHoursStartDate
      ) {
        setIsCreateButtonDisabled(true);
        return;
      }

      if (
        workers.find(
          (worker) =>
            worker.first_name &&
            worker.first_name.toLowerCase() === formFirstName &&
            worker.last_name &&
            worker.last_name.toLowerCase() === formLastName
        )
      ) {
        setValidationError({
          message: WebHelper.formatMessage("ManualWorkerCreation-nameAlreadyExistsValidation", {
            siteName: siteName,
            departmentName: department.name,
          }),
          formItemsWithError: {firstName: true, lastName: true},
        });
        setIsCreateButtonDisabled(true);
        return;
      }

      if (workers.find((worker) => worker.employee_id && worker.employee_id.toLowerCase() === formEmployeeID)) {
        setValidationError({
          message: WebHelper.formatMessage("ManualWorkerCreation-empIdAlreadyExistsValidation", {
            siteName: siteName,
            departmentName: department.name,
          }),
          formItemsWithError: {employeeID: true},
        });
        setIsCreateButtonDisabled(true);
        return;
      }

      setIsCreateButtonDisabled(false);
      return setValidationError(undefined);
    }, [department.name, department.target_type, flags.target_utilization_hours, form, setIsCreateButtonDisabled, siteName, workers]);

    const {isFetching: devicesQueryIsFetching, refetch: devicesQueryRefetch} = useQuery({
      queryKey: ["WorkerForm-fetchDevicesData"],
      queryFn: () =>
        DeviceModule.devicesShort({
          accessToken: authenticationStore.state.accessToken!,
          department_id: department.id,
        }),
      enabled: false,
    });

    const fetchAvailableDevices = useCallback(async () => {
      const response = await devicesQueryRefetch();
      if (response.data?.success) {
        const availableDevicesResponse = response.data.devices.filter(
          (device) => device.assignable && isNil(device.assigned_worker?.id) && !device.archived_at
        );
        setAvailableDevices(availableDevicesResponse);
      }
    }, [devicesQueryRefetch]);

    const assignedDeviceItemOptions = useMemo(() => {
      return [
        getSelectOption(undefined, WebHelper.formatMessage("WorkerForm-noneOption")),
        ...availableDevices.map(({id, device_tag}) => getSelectOption(id, device_tag)),
      ];
    }, [availableDevices]);

    const {isFetching: workerReportGroupsQueryIsFetching, refetch: workerReportGroupsQueryRefetch} = useQuery({
      queryKey: ["WorkerForm-fetchReportGroupsData"],
      queryFn: () =>
        WorkerReportGroupModule.workerReportGroups({
          accessToken: authenticationStore.state.accessToken!,
          site_id: siteId,
        }),
      enabled: false,
    });

    const fetchWorkerReportGroups = useCallback(async () => {
      const response = await workerReportGroupsQueryRefetch();
      if (response.data?.success) {
        setWorkerReportGroups(response.data.workerReportGroups);
      }
    }, [workerReportGroupsQueryRefetch]);

    const workerReportGroupsItemOptions = useMemo(() => {
      return [...workerReportGroups.map(({id, name}) => getSelectOption(id, name))];
    }, [workerReportGroups]);

    const languageOptions = useMemo(() => {
      return [
        {
          key: Models.WorkerPreferredLanguage.en_US,
          label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.en_US),
          value: Models.WorkerPreferredLanguage.en_US,
        },
        {
          key: Models.WorkerPreferredLanguage.pt_BR,
          label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.pt_BR),
          value: Models.WorkerPreferredLanguage.pt_BR,
        },
        {
          key: Models.WorkerPreferredLanguage.fr_CA,
          label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.fr_CA),
          value: Models.WorkerPreferredLanguage.fr_CA,
        },
        {
          key: Models.WorkerPreferredLanguage.es_MX,
          label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.es_MX),
          value: Models.WorkerPreferredLanguage.es_MX,
        },
        {
          key: Models.WorkerPreferredLanguage.ht_HT,
          label: WebHelper.getLanguageText(Models.WorkerPreferredLanguage.ht_HT),
          value: Models.WorkerPreferredLanguage.ht_HT,
        },
      ];
    }, []);

    const handleOnFieldsChange = () => {
      validator();
    };

    return (
      <Form
        form={form}
        disabled={creationLoading}
        labelWrap={true}
        labelCol={{span: 7}}
        wrapperCol={{span: 17}}
        onFieldsChange={handleOnFieldsChange}>
        <Form.Item
          name="first_name"
          label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-firstNameInputLabel")}</Typography.Text>}
          validateStatus={validationError?.formItemsWithError?.firstName ? "error" : undefined}>
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-lastNameInputLabel")}</Typography.Text>}
          validateStatus={validationError?.formItemsWithError?.lastName ? "error" : undefined}>
          <Input />
        </Form.Item>
        <Form.Item
          name="employee_id"
          label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-employeeIdInputLabel")}</Typography.Text>}
          validateStatus={validationError?.formItemsWithError?.employeeID ? "error" : undefined}>
          <Input />
        </Form.Item>
        <Form.Item
          name="preferred_language"
          label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-preferredLanguageInputLabel")}</Typography.Text>}>
          <Select allowClear options={languageOptions} optionFilterProp="label" showSearch />
        </Form.Item>
        {(authenticationStore.permissionLevel(Models.UserScope.Site, siteId) === Models.UserAccessLevel.Admin ||
          authenticationStore.permissionLevel(Models.UserScope.Site, siteId) === Models.UserAccessLevel.Manager) && (
          <Form.Item
            name="group_ids"
            label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-workerReportGroupsInputLabel")}</Typography.Text>}>
            <Select
              mode="multiple"
              allowClear
              loading={workerReportGroupsQueryIsFetching}
              options={workerReportGroupsItemOptions}
              optionFilterProp="label"
              showSearch
              onDropdownVisibleChange={(open) => {
                if (open) fetchWorkerReportGroups();
              }}
              dropdownRender={(node) => (workerReportGroupsQueryIsFetching ? WebHelper.LoadingSpin : node)}
            />
          </Form.Item>
        )}
        <Form.Item
          name="assigned_device_id"
          label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-deviceInputLabel")}</Typography.Text>}>
          <Select
            loading={devicesQueryIsFetching}
            options={assignedDeviceItemOptions}
            optionFilterProp="label"
            showSearch
            onDropdownVisibleChange={(open) => {
              if (open) fetchAvailableDevices();
            }}
            dropdownRender={(node) => (devicesQueryIsFetching ? WebHelper.LoadingSpin : node)}
          />
        </Form.Item>
        {flags.target_utilization_hours && department.target_type && department.target_type !== Models.TargetType.None && (
          <>
            <Form.Item
              label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-exemptFromUsageTarget")}</Typography.Text>}
              name="exempt_target">
              <Switch value={exemptTarget} onChange={(checked) => setExemptTarget(checked)} />
            </Form.Item>
            <Form.Item
              label={<Typography.Text>{WebHelper.formatMessage("WorkerForm-targetHoursStartDate")}</Typography.Text>}
              name="targetHoursStartDate"
              className={`${exemptTarget ? styles.exemptTarget : styles.notExemptTarget}`}
              initialValue={new Date()}
              rules={!exemptTarget ? [{required: true, message: WebHelper.formatMessage("Common-fieldRequiredMessage")}] : []}>
              <DatePicker />
            </Form.Item>
          </>
        )}
        {validationError && <Typography.Text type="danger">{validationError.message}</Typography.Text>}
      </Form>
    );
  }
);

function getSelectOption(id: string | undefined, name: string) {
  return {key: id, label: name, value: id};
}
