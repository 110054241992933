import {Modal, Space} from "antd";
import React, {FunctionComponent} from "react";

import styles from "./DiscardChangesModal.module.scss";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";

export type DiscardChangesModalProps = {
  onDiscard: () => void;
  onClose: () => void;
  open: boolean;
};

export const DiscardChangesModal: FunctionComponent<DiscardChangesModalProps> = ({onDiscard, onClose, open}) => {
  return (
    <Modal
      centered
      zIndex={2000}
      className={styles.modal}
      open={open}
      destroyOnClose={true}
      title={WebHelper.formatMessage("Common-closeFormAlertTitle")}
      onCancel={onClose}
      footer={
        <Space>
          <Button shape="round" onClick={onClose}>
            {WebHelper.formatMessage("Common-cancel")}
          </Button>
          <Button type={ButtonType.Primary} shape="round" onClick={onDiscard}>
            {WebHelper.formatMessage("Common-discard")}
          </Button>
        </Space>
      }>
      {WebHelper.formatMessage("Common-closeFormAlertMessage")}
    </Modal>
  );
};
