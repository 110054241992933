import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {round} from "../../web/utils/FunctionUtils";

export class AnalyticsTimeSeries extends BaseModel {
  active_workers: number;
  assigned_workers: number;
  end_time: Date;
  enrolled_workers: number;
  excessive_forward_lifts: number;
  prolonged_bend_lifts: number;
  safe_lifts: number;
  side_bend_lifts: number;
  start_time: Date;
  time_zone: string;
  total_lifts: number;
  twisted_lifts: number;
  usage_sec: number;
  weight_offloaded_lbs: number;
  offset?: number;

  constructor() {
    super();
    makeObservable(this, {
      active_workers: observable,
      assigned_workers: observable,
      end_time: observable,
      enrolled_workers: observable,
      excessive_forward_lifts: observable,
      prolonged_bend_lifts: observable,
      safe_lifts: observable,
      side_bend_lifts: observable,
      start_time: observable,
      time_zone: observable,
      total_lifts: observable,
      twisted_lifts: observable,
      usage_sec: observable,
      weight_offloaded_lbs: observable,
      offset: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsTimeSeries, json: any) {
    if (json.start_time) {
      // This should be removed once the timezones rework is done
      object.offset = json.start_time.slice(-6);
      object.start_time = new Date(json.start_time.slice(0, -6));
    }
    if (json.end_time) {
      // This should be removed once the timezones rework is done
      object.offset = json.end_time.slice(-6);
      object.end_time = new Date(json.end_time.slice(0, -6));
    }
  }

  get riskyLifts(): number {
    return this.total_lifts - this.safe_lifts;
  }

  get safeLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.safe_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get riskyLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.riskyLifts / this.total_lifts) * 100, 1) : 0;
  }

  get excessiveForwardLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.excessive_forward_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get prolongedBendLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.prolonged_bend_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get twistedLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.twisted_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get sideBendLiftsPercentage(): number {
    return this.total_lifts > 0 ? round((this.side_bend_lifts / this.total_lifts) * 100, 1) : 0;
  }

  get activeWorkersPercentage(): number {
    return this.assigned_workers > 0
      ? round((this.active_workers / this.assigned_workers) * 100, 1) > 100
        ? 100
        : round((this.active_workers / this.assigned_workers) * 100, 1)
      : 0;
  }

  get inactiveWorkers(): number {
    return this.assigned_workers >= this.active_workers ? this.assigned_workers - this.active_workers : 0;
  }

  get inactiveWorkersPercentage(): number {
    return this.assigned_workers > 0
      ? round((this.inactiveWorkers / this.assigned_workers) * 100, 1) > 100
        ? 100
        : round((this.inactiveWorkers / this.assigned_workers) * 100, 1)
      : 0;
  }

  get averageSafeLifts(): number {
    return this.active_workers > 0 ? this.safe_lifts / this.active_workers : 0;
  }

  get averageRiskyLifts(): number {
    return this.active_workers > 0 ? this.riskyLifts / this.active_workers : 0;
  }

  get averageExcessiveForwardLifts(): number {
    return this.active_workers > 0 ? this.excessive_forward_lifts / this.active_workers : 0;
  }

  get averageProlongedBendLifts(): number {
    return this.active_workers > 0 ? this.prolonged_bend_lifts / this.active_workers : 0;
  }

  get averageTwistedLifts(): number {
    return this.active_workers > 0 ? this.twisted_lifts / this.active_workers : 0;
  }

  get averageSideBendLifts(): number {
    return this.active_workers > 0 ? this.side_bend_lifts / this.active_workers : 0;
  }
}

export class AnalyticsTimeSeriesData extends BaseModel {
  worker_count: number;
  worker_ids: string[];
  time_series_data: AnalyticsTimeSeries[];

  constructor() {
    super();
    makeObservable(this, {
      worker_count: observable,
      worker_ids: observable,
      time_series_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsTimeSeriesData, json: any) {
    if (json.time_series_data)
      object.time_series_data = json.time_series_data.map((timeSeries: AnalyticsTimeSeries) => {
        return AnalyticsTimeSeries.fromJSON(timeSeries);
      });
  }
}

export class AnalyticsTimeSeriesDataIndices extends BaseModel {
  worker_count: number;
  worker_ids: string[];
  time_series_indices: AnalyticsTimeSeries[];

  constructor() {
    super();
    makeObservable(this, {
      worker_count: observable,
      worker_ids: observable,
      time_series_indices: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsTimeSeriesDataIndices, json: any) {
    if (json.time_series_indices)
      object.time_series_indices = json.time_series_indices.map((timeSeries: AnalyticsTimeSeries) => {
        return AnalyticsTimeSeries.fromJSON(timeSeries);
      });
  }
}

export class AnalyticsMultiTimeSeriesData extends BaseModel {
  worker_count: number;
  worker_ids: string[];
  resource_type: string;
  name: string;
  id: string;
  time_series_data: AnalyticsTimeSeries[];

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      resource_type: observable,
      worker_count: observable,
      worker_ids: observable,
      time_series_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsTimeSeriesData, json: any) {
    if (json.time_series_data)
      object.time_series_data = json.time_series_data.map((timeSeries: AnalyticsTimeSeries) => {
        return AnalyticsTimeSeries.fromJSON(timeSeries);
      });
  }
}
