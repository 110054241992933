import {CloseOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Button, Space, Divider, Typography, Select} from "antd";
import {differenceInDays, addDays, endOfDay, startOfDay, format, isAfter} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import React, {FunctionComponent, MutableRefObject, useCallback, useMemo, useState} from "react";

import styles from "./ReportDownload.module.scss";
import * as Models from "../../../core/models";
import {DepartmentModule} from "../../../core/modules/DepartmentModule";
import {WorkerModule} from "../../../core/modules/WorkerModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {DatePicker} from "../date-picker/DatePicker";

export enum ReportType {
  Individual = "individual",
  SiteSummary = "site_summary",
  Summary = "summary",
  All = "all",
  Demo = "demo",
}

export type ReportDownloadProps = {
  requestCloseRef: MutableRefObject<(() => void) | undefined>;
  onOk: () => void;
  onCancel: () => void;
  type: ReportType;
  departments?: Models.DepartmentInSite[] | null;
  worker?: Models.Worker;
  departmentId?: string;
  tz_location?: string;
};

export const ReportDownload: FunctionComponent<ReportDownloadProps> = (props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [departmentId, setDepartmentId] = useState(props.departmentId);
  const [endDate, setEndDate] = useState<Date | null>(addDays(endOfDay(new Date()), -1));
  props.requestCloseRef.current = () => {
    props.onOk();
  };

  const {start_time, end_time} = useMemo<{start_time?: string; end_time?: string}>(() => {
    if (!endDate) return {};

    const formatDate = (date: Date) =>
      props.tz_location
        ? fromZonedTime(date, "UTC").toISOString()
        : format(date, WebHelper.formatMessage("Common-dateTimeFormatWithoutTimezone"));

    const start_time = formatDate(addDays(startOfDay(endDate), -6));
    const end_time = formatDate(endDate);

    return {start_time, end_time};
  }, [endDate, props.tz_location]);

  const {isFetching: reportWorkerIndividualIsFetching, refetch: reportWorkerIndividualRefetch} = useQuery({
    queryKey: ["ReportDownload-fetchReportWorkerIndividualData", props.worker?.id, start_time, end_time],
    queryFn: () => {
      if (start_time && end_time) {
        return WorkerModule.reportWorkerIndividual({
          accessToken: authenticationStore.state.accessToken!,
          worker_id: props.worker!.id,
          start_time,
          end_time,
        });
      } else return;
    },
    enabled: false,
  });

  const {isFetching: allWorkersReportIsFetching, refetch: allWorkersReportRefetch} = useQuery({
    queryKey: ["ReportDownload-fetchSummaryReportData", departmentId, start_time, end_time],
    queryFn: () => {
      if (start_time && end_time) {
        return DepartmentModule.allWorkersReport({
          accessToken: authenticationStore.state.accessToken!,
          department_id: departmentId!,
          start_time,
          end_time,
        });
      } else return;
    },
    enabled: false,
  });

  const {isFetching: demoReportIsFetching, refetch: demoReportRefetch} = useQuery({
    queryKey: ["ReportDownload-fetchDemoReportData", departmentId, start_time, end_time],
    queryFn: () => {
      if (start_time && end_time) {
        return DepartmentModule.demoReport({
          accessToken: authenticationStore.state.accessToken!,
          department_id: departmentId!,
          start_time,
          end_time,
        });
      } else return;
    },
    enabled: false,
  });

  const loading =
    (props.type === ReportType.Individual && reportWorkerIndividualIsFetching) ||
    (props.type === ReportType.All && allWorkersReportIsFetching) ||
    (props.type === ReportType.All && demoReportIsFetching);

  const downloadReport = useCallback(async () => {
    let response;
    let downloadName = "";

    if (props.type === ReportType.Individual) {
      response = await reportWorkerIndividualRefetch();
      downloadName = WebHelper.formatMessage("ReportDownload-individualTitle");
    } else if (props.type === ReportType.All) {
      response = await allWorkersReportRefetch();
      downloadName = WebHelper.formatMessage("ReportDownload-allTitle");
    } else if (props.type === ReportType.Demo) {
      response = await demoReportRefetch();
      downloadName = WebHelper.formatMessage("ReportDownload-demoTitle");
    } else return;

    if (!response.data) return;

    if (!response.data.success)
      WebHelper.showErrorMessage(WebHelper.formatMessage("ReportDownload-downloadError"), response.data.correlationId);
    else {
      WebHelper.downloadFile(response.data.file, downloadName, "application/pdf");
      props.onOk();
    }
  }, [props, reportWorkerIndividualRefetch, allWorkersReportRefetch, demoReportRefetch]);

  const handleCancel = () => {
    if (props.onCancel) props.onCancel();
  };

  let title;
  let message;
  let buttonText;

  switch (props.type) {
    case ReportType.All:
      title = WebHelper.formatMessage("ReportDownload-allTitle");
      message = WebHelper.formatMessage("ReportDownload-allMessage");
      buttonText = WebHelper.formatMessage("ReportDownload-allButtonText");
      break;
    case ReportType.Summary:
      title = WebHelper.formatMessage("ReportDownload-summaryTitle");
      message = WebHelper.formatMessage("ReportDownload-summaryMessage");
      buttonText = WebHelper.formatMessage("ReportDownload-summaryButtonText");
      break;
    case ReportType.SiteSummary:
      title = WebHelper.formatMessage("ReportDownload-summaryTitle");
      message = WebHelper.formatMessage("ReportDownload-summaryMessage");
      buttonText = WebHelper.formatMessage("ReportDownload-summaryButtonText");
      break;
    case ReportType.Individual:
      title = WebHelper.formatMessage("ReportDownload-individualTitle");
      message = WebHelper.formatMessage("ReportDownload-individualMessage");
      buttonText = WebHelper.formatMessage("ReportDownload-individualButtonText");
      break;
    case ReportType.Demo:
      title = WebHelper.formatMessage("ReportDownload-demoTitle");
      message = WebHelper.formatMessage("ReportDownload-demoMessage");
      buttonText = WebHelper.formatMessage("ReportDownload-demoButtonText");
      break;
  }

  return (
    <>
      <div className={styles.drawerHeader}>
        <Typography.Title level={4}>{title}</Typography.Title>

        <CloseOutlined className={styles.headerIcon} onClick={handleCancel} />
      </div>

      <Divider type="horizontal" />

      {(props.type === ReportType.SiteSummary || props.type === ReportType.All || props.type === ReportType.Demo) &&
      !props.departmentId &&
      !props.departments ? (
        <Typography>{WebHelper.formatMessage("ReportDownload-noDepartmentError")}</Typography>
      ) : (
        <Space className={styles.wrapper}>
          <Typography.Text className={styles.message}>{message}</Typography.Text>
          {props.type === ReportType.Individual && props.worker && (
            <div
              className={styles.workerName}
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(
                  WebHelper.formatMessage("ReportDownload-worker", {
                    displayName: props.worker.displayName,
                  })
                ),
              }}
            />
          )}

          {!props.departmentId && props.departments && (
            <Space className={styles.departmentSelectorWrapper}>
              <Typography.Text strong>{WebHelper.formatMessage("ReportDownload-department")}</Typography.Text>
              <Select
                showSearch
                optionFilterProp="label"
                optionLabelProp="label"
                onChange={setDepartmentId}
                className={styles.departmentSelect}
                value={departmentId}
                options={props.departments.map((department) => {
                  return {
                    label: department.name,
                    value: department.id,
                  };
                })}
              />
            </Space>
          )}

          <Space className={styles.dateSelectorWrapper}>
            <Typography.Text strong>{WebHelper.formatMessage("ReportDownload-reportEndDate")}</Typography.Text>

            <DatePicker
              allowClear={false}
              disabled={loading}
              value={endDate}
              onChange={(date) => setEndDate(date ? endOfDay(date) : date)}
              disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
              dateRender={(current) => {
                if (!endDate) return current.getDate();
                const isLastSevenDays = differenceInDays(endDate, current) > 0 && differenceInDays(endDate, current) <= 6;

                return <div className={`ant-picker-cell-inner ${isLastSevenDays && styles.selectedDay}`}>{current.getDate()}</div>;
              }}
            />
          </Space>

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={
              !endDate ||
              ((props.type === ReportType.Summary ||
                props.type === ReportType.SiteSummary ||
                props.type === ReportType.All ||
                props.type === ReportType.Demo) &&
                !departmentId)
            }
            onClick={downloadReport}>
            {buttonText}
          </Button>
        </Space>
      )}
    </>
  );
};
