import {EditOutlined} from "@ant-design/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Card, Modal, Space, Tag, Tooltip, Typography} from "antd";
import {useFlags} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useEffect, useState} from "react";

import styles from "./WorkerDetail.module.scss";
import * as Models from "../../../core/models";
import {DepartmentModule} from "../../../core/modules/DepartmentModule";
import {SessionModule} from "../../../core/modules/SessionModule";
import {WorkerModule} from "../../../core/modules/WorkerModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {cloneDeep, isEmpty, isNil} from "../../utils/FunctionUtils";
import {SegmentEntryPoint} from "../../utils/SegmentHelper";
import {WebHelper} from "../../utils/WebHelper";
import {LinkButton} from "../common/link-button/LinkButton";
import {ConfirmationModal} from "../confirmation-modal/ConfirmationModal";
import {ChangeWorkerStatusModal} from "../entity-details/workers-tab-content/change-worker-status-modal/ChangeWorkerStatusModal";
import {DeviceNoWorker} from "../entity-details/workers-tab-content/device-no-worker/DeviceNoWorker";
import {WorkerEditModal} from "../entity-details/workers-tab-content/worker-edit-modal/WorkerEditModal";
import {Loading} from "../loading/Loading";
import {WorkerDeviceAssignmentModal} from "../worker-device-assignment-modal/WorkerDeviceAssignmentModal";

export type WorkerDetailProps = {
  workerId: string;
  onDelete: () => void;
  onEdit: () => void;
};

export const WorkerDetail: FunctionComponent<WorkerDetailProps> = ({workerId, onDelete, onEdit}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const flags = useFlags();

  const [workersSessions, setWorkersSessions] = useState<Models.SessionShort[]>([]);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChangeDeviceModal, setShowChangeDeviceModal] = useState(false);
  const [showChangeWorkerStatusModal, setShowChangeWorkerStatusModal] = useState(false);
  const [currentWorker, setCurrentWorker] = useState<Models.Worker>();
  const [currentDepartment, setCurrentDepartment] = useState<Models.Department>();
  const [showDeviceNoWorker, setShowDeviceNoWorker] = useState(false);
  const [previousWorker, setPreviousWorker] = useState<Models.Worker>();

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.WorkerDetailOpened, {workerId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerId]);

  const workerQuery = useQuery({
    queryKey: ["WorkerDetail-fetchWorkerData"],
    queryFn: () => {
      return WorkerModule.worker({
        accessToken: authenticationStore.state.accessToken!,
        id: workerId,
      });
    },
  });

  useEffect(() => {
    if (!workerQuery.data) return;

    if (!workerQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerDetail-errorFetchingWorkers"), workerQuery.data.correlationId);
      return;
    }
    setCurrentWorker(workerQuery.data.worker);
  }, [workerQuery.data]);

  const {mutate: deleteWorkerMutate, isPending: deleteWorkerIsLoading} = useMutation({
    mutationFn: (workerId: string) =>
      WorkerModule.deleteWorker({
        accessToken: authenticationStore.state.accessToken!,
        id: workerId,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerDetail-deleteError"), response.correlationId);
      } else {
        appStore.showMessage(WebHelper.formatMessage("WorkersTabContent-deleteSuccess"), "success");
        setShowDeleteModal(false);
        onDelete();
      }
    },
  });

  const departmentQuery = useQuery({
    queryKey: ["WorkerDetails-fetchDepartmentData", workerQuery.data?.worker?.department.id],
    queryFn: () => {
      if (workerQuery.data?.worker) {
        return DepartmentModule.department({
          accessToken: authenticationStore.state.accessToken!,
          id: workerQuery.data.worker.department.id!,
        });
      }
      return;
    },
    enabled: !!workerQuery.data?.worker,
  });

  useEffect(() => {
    if (!departmentQuery.data) return;
    if (!departmentQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerDetail-errorFetchingWorkers"), departmentQuery.data.correlationId);
      return;
    }
    setCurrentDepartment(departmentQuery.data.department);
  }, [departmentQuery.data]);

  const sessionsQuery = useQuery({
    queryKey: ["sessions", workerId],
    queryFn: () =>
      SessionModule.sessions({
        accessToken: authenticationStore.state.accessToken!,
        worker_id: workerId,
      }),
  });

  useEffect(() => {
    if (!sessionsQuery.data) return;

    if (!sessionsQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerDetail-fetchSessionsDataError"), sessionsQuery.data.correlationId);
      return;
    }
    setWorkersSessions(sessionsQuery.data.sessions);
  }, [sessionsQuery.data]);

  const handleDeleteCancel = () => {
    if (deleteWorkerIsLoading) return;

    setShowDeleteModal(false);
  };

  const handleDeleteOk = () => {
    if (deleteWorkerIsLoading || !currentWorker) return;
    deleteWorkerMutate(currentWorker.id);
  };

  const handleWorkerStatusChange = (worker: Models.Worker) => {
    onEdit();
    workerQuery.refetch();
    if (worker.assigned_device) setShowDeviceNoWorker(true);
    setShowChangeWorkerStatusModal(false);
    setPreviousWorker(worker);
  };

  const getMoreGroupsName = () => {
    if (!currentWorker?.groups) return "";

    const groups = cloneDeep(currentWorker.groups);
    groups.splice(0, 1);

    return groups.map((group) => group.name).join(" - ");
  };

  if (workerQuery.isFetching) return <Loading />;

  return (
    <>
      <Space className={styles.cardsWrapper} direction="vertical" size={16}>
        <Card className={styles.card}>
          <Space className={styles.cardContent} direction="vertical" size={16}>
            <div className={styles.cardTitle}>
              <Typography.Text strong>{WebHelper.formatMessage("WorkerDetail-workerProfile")}</Typography.Text>
              {(authenticationStore.permissionLevel(Models.UserScope.Department, currentWorker?.department.id) ===
                Models.UserAccessLevel.Admin ||
                authenticationStore.permissionLevel(Models.UserScope.Department, currentWorker?.department.id) ===
                  Models.UserAccessLevel.Manager) && (
                <EditOutlined className={styles.editIcon} onClick={() => setShowEditModal(true)} data-cy="editButton" />
              )}
            </div>
            <Space size={8}>
              <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-firstName")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-lastName")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-employeeId")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-preferredLanguage")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-reportGroups")}</Typography.Text>
                {flags.target_utilization_hours &&
                  currentDepartment?.target_type &&
                  currentDepartment?.target_type !== Models.TargetType.None && (
                    <>
                      <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-exemptFromUsageTarget")}</Typography.Text>
                      <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-targetHoursStartDate")}</Typography.Text>
                    </>
                  )}
              </Space>
              <Space direction="vertical" size={16} align="start">
                <Typography.Text data-cy="firstNameText">
                  {currentWorker?.first_name.length === 0 ? <div className={styles.emptyValue} /> : currentWorker?.first_name}
                </Typography.Text>
                <Typography.Text data-cy="lastNameText">
                  {currentWorker?.last_name.length === 0 ? <div className={styles.emptyValue} /> : currentWorker?.last_name}
                </Typography.Text>
                <Typography.Text>
                  {currentWorker?.employee_id.length === 0 ? <div className={styles.emptyValue} /> : currentWorker?.employee_id}
                </Typography.Text>
                <Typography.Text>
                  {!currentWorker?.preferred_language ? (
                    <div className={styles.emptyValue} />
                  ) : (
                    WebHelper.getLanguageText(currentWorker?.preferred_language)
                  )}
                </Typography.Text>
                {!currentWorker?.groups ? (
                  <div className={styles.emptyValue} />
                ) : (
                  <div className={styles.groupWrapper}>
                    {currentWorker.groups.length > 3 ? (
                      <>
                        <Tooltip title={currentWorker.groups[0].name}>
                          <Tag className={styles.reportGroupTag} key={currentWorker.groups[0].id}>
                            {currentWorker.groups[0].name}
                          </Tag>
                        </Tooltip>
                        <Tooltip title={getMoreGroupsName}>
                          <Tag className={styles.reportGroupTag}>
                            {WebHelper.formatMessage("WorkerDetail-reportGroupsNumber", {quantity: currentWorker.groups.length - 1})}
                          </Tag>
                        </Tooltip>
                      </>
                    ) : currentWorker.groups.length === 3 ? (
                      <>
                        {currentWorker.groups.map((group) => (
                          <Tooltip title={group.name} key={group.id}>
                            <Tag className={styles.reportGroup3Tags}>{group.name}</Tag>
                          </Tooltip>
                        ))}
                      </>
                    ) : (
                      <>
                        {currentWorker.groups.map((group) => (
                          <Tooltip title={group.name} key={group.id}>
                            <Tag className={styles.reportGroupTag}>{group.name}</Tag>
                          </Tooltip>
                        ))}
                      </>
                    )}
                  </div>
                )}
                {flags.target_utilization_hours &&
                  currentDepartment?.target_type &&
                  currentDepartment?.target_type !== Models.TargetType.None && (
                    <>
                      <Typography.Text>
                        {currentWorker?.exempt_target
                          ? WebHelper.formatMessage("WorkerDetail-usageTargetEnabled")
                          : WebHelper.formatMessage("WorkerDetail-usageTargetDisabled")}
                        <div className={styles.emptyValue} />
                      </Typography.Text>
                      <Typography.Text>
                        {currentWorker?.target_hours_start_date ? (
                          WebHelper.formatDateNoTime(currentWorker.target_hours_start_date)
                        ) : (
                          <div className={styles.emptyValue} />
                        )}
                        <div className={styles.emptyValue} />
                      </Typography.Text>
                    </>
                  )}
              </Space>
            </Space>
          </Space>
        </Card>

        <Card className={styles.card}>
          <Space className={styles.cardContent} direction="vertical" size={16}>
            <div className={styles.cardTitle}>
              <Typography.Text strong>{WebHelper.formatMessage("WorkerDetail-workerStatus")}</Typography.Text>
              {(authenticationStore.permissionLevel(Models.UserScope.Department, currentWorker?.department.id) ===
                Models.UserAccessLevel.Admin ||
                authenticationStore.permissionLevel(Models.UserScope.Department, currentWorker?.department.id) ===
                  Models.UserAccessLevel.Manager) && (
                <EditOutlined className={styles.editIcon} onClick={() => setShowChangeWorkerStatusModal(true)} data-cy="editButton" />
              )}
            </div>
            <Space size={8}>
              <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-status")}</Typography.Text>
              </Space>
              <Space direction="vertical" size={16} align="start">
                <Typography.Text data-cy="statusText">
                  {currentWorker?.assignable
                    ? WebHelper.formatMessage("WorkerDetail-enrolled")
                    : WebHelper.formatMessage("WorkerDetail-unenrolled")}
                </Typography.Text>
              </Space>
            </Space>
          </Space>
        </Card>

        <Card className={styles.card}>
          <Space className={styles.cardContent} direction="vertical" size={16}>
            <div className={styles.cardTitle}>
              <Typography.Text strong>{WebHelper.formatMessage("WorkerDetail-deviceAssignment")}</Typography.Text>
              {authenticationStore.permissionLevel(Models.UserScope.Department, currentWorker?.department.id) ===
                Models.UserAccessLevel.Admin ||
              authenticationStore.permissionLevel(Models.UserScope.Department, currentWorker?.department.id) ===
                Models.UserAccessLevel.Manager ? (
                currentWorker?.assignable ? (
                  <EditOutlined className={styles.editIcon} onClick={() => setShowChangeDeviceModal(true)} data-cy="editButton" />
                ) : (
                  <Tooltip title={WebHelper.formatMessage("WorkerDetail-disabledDevice")}>
                    <Typography.Text disabled>
                      <EditOutlined className={`${styles.editIcon} ${styles.iconDisabled}`} data-cy="editButton" />
                    </Typography.Text>
                  </Tooltip>
                )
              ) : null}
            </div>
            <Space size={8}>
              <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-assignedDevice")}</Typography.Text>
              </Space>
              <Space direction="vertical" size={16} align="start">
                <Typography.Text data-cy="assignedDeviceTagText">
                  {!currentWorker?.assigned_device?.device_tag || currentWorker.assigned_device?.device_tag.length === 0 ? (
                    <div className={styles.emptyValue} />
                  ) : (
                    currentWorker.assigned_device?.device_tag
                  )}
                </Typography.Text>
              </Space>
            </Space>
          </Space>
        </Card>

        <Card className={styles.card}>
          <Space direction="vertical" size={16}>
            <Typography.Text strong>{WebHelper.formatMessage("WorkerDetail-workerStatistics")}</Typography.Text>
            <Space size={8}>
              <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-dateFirstUsage")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-dateLastUsage")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-totalHours")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("WorkerDetail-totalLifts")}</Typography.Text>
              </Space>
              <Space direction="vertical" size={16}>
                {currentWorker?.first_session_start_at ? (
                  <Typography.Text>
                    {WebHelper.formatDate(currentWorker.first_session_start_at, currentWorker.site?.tz_location)}
                  </Typography.Text>
                ) : (
                  <div className={styles.emptyValue} />
                )}
                {currentWorker?.most_recent_session_start_at ? (
                  <Typography.Text>
                    {WebHelper.formatDate(currentWorker.most_recent_session_start_at, currentWorker.site?.tz_location)}
                  </Typography.Text>
                ) : (
                  <div className={styles.emptyValue} />
                )}
                <Typography.Text>{WebHelper.secondsToHours(currentWorker?.total_time_used_seconds || 0)}</Typography.Text>
                <Typography.Text>{currentWorker?.total_lifts}</Typography.Text>
              </Space>
            </Space>
          </Space>
        </Card>

        {(authenticationStore.permissionLevel(Models.UserScope.Department, currentWorker?.department.id) === Models.UserAccessLevel.Admin ||
          (authenticationStore.permissionLevel(Models.UserScope.Global) === Models.UserAccessLevel.Admin &&
            authenticationStore.isUserGlobal)) && (
          <LinkButton
            onClick={() =>
              !isNil(currentWorker?.assigned_device) || !isEmpty(workersSessions)
                ? setShowCannotDeleteModal(true)
                : setShowDeleteModal(true)
            }>
            {WebHelper.formatMessage("WorkerDetail-deleteWorker")}
          </LinkButton>
        )}
      </Space>
      <ConfirmationModal
        showConfirmationModal={showDeleteModal}
        loading={deleteWorkerIsLoading}
        title={WebHelper.formatMessage("WorkerDetail-deleteAlertTitle")}
        message={<Typography.Text> {WebHelper.formatMessage("WorkerDetail-deleteAlertMessage")}</Typography.Text>}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        referenceConfirmationValue={WebHelper.formatMessage("Common-deleteConfirmationValue")}
        okButtonText={WebHelper.formatMessage("Common-delete")}
      />
      {(authenticationStore.permissionLevel(Models.UserScope.Site, currentWorker?.site?.id) === Models.UserAccessLevel.Admin ||
        authenticationStore.permissionLevel(Models.UserScope.Site, currentWorker?.site?.id) === Models.UserAccessLevel.Manager) &&
        currentWorker && (
          <WorkerDeviceAssignmentModal
            open={showChangeDeviceModal}
            worker={currentWorker}
            onClose={() => setShowChangeDeviceModal(false)}
            onUpdate={(updatedWorker?: Models.Worker) => {
              onEdit();
              if (updatedWorker) {
                setCurrentWorker(updatedWorker);
              }
            }}
          />
        )}

      {(authenticationStore.permissionLevel(Models.UserScope.Site, currentWorker?.site?.id) === Models.UserAccessLevel.Admin ||
        authenticationStore.permissionLevel(Models.UserScope.Site, currentWorker?.site?.id) === Models.UserAccessLevel.Manager) &&
        currentWorker && (
          <ChangeWorkerStatusModal
            worker={currentWorker}
            open={showChangeWorkerStatusModal}
            entryPoint={SegmentEntryPoint.WORKER_TABLE}
            onOk={() => handleWorkerStatusChange(currentWorker)}
            onCancel={() => setShowChangeWorkerStatusModal(false)}
          />
        )}

      {(authenticationStore.permissionLevel(Models.UserScope.Site, currentWorker?.site?.id) === Models.UserAccessLevel.Admin ||
        authenticationStore.permissionLevel(Models.UserScope.Site, currentWorker?.site?.id) === Models.UserAccessLevel.Manager) &&
        currentWorker &&
        currentDepartment && (
          <WorkerEditModal
            worker={currentWorker}
            department={currentDepartment}
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
            onUpdate={(updatedWorker?: Models.Worker) => {
              onEdit();
              if (updatedWorker) {
                setCurrentWorker(updatedWorker);
              }
            }}
          />
        )}
      <Modal
        centered
        open={showCannotDeleteModal}
        title={WebHelper.formatMessage("WorkerDetail-deleteAlertTitle")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onCancel={() => setShowCannotDeleteModal(false)}
        onOk={() => setShowCannotDeleteModal(false)}
        cancelButtonProps={{shape: "round"}}
        okButtonProps={{hidden: true}}>
        <Space className={styles.deleteWrapper}>
          <Typography.Text>{WebHelper.formatMessage("WorkerDetail-cannotDeleteMessage")}</Typography.Text>
        </Space>
      </Modal>
      {previousWorker?.assigned_device && (
        <DeviceNoWorker
          deviceId={previousWorker.assigned_device.id}
          deviceTag={previousWorker.assigned_device.device_tag}
          departmentId={previousWorker.department.id}
          onOk={() => {
            onEdit();
            setShowDeviceNoWorker(false);
          }}
          open={showDeviceNoWorker}
          onClose={() => setShowDeviceNoWorker(false)}
          entryPoint={SegmentEntryPoint.EDIT_WORKER_MODAL}
        />
      )}
    </>
  );
};
