import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button as AntdButton, Tabs} from "antd";
import {Content} from "antd/lib/layout/layout";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {WebHelper} from "web/utils/WebHelper";

import styles from "./Notification.module.scss";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {PageTitle} from "../../components/common/page-title/PageTitle";
import {Subheader, SubheaderBreadcrumbItem} from "../../components/common/subheader/Subheader";
import {DeviceUsageReassignment} from "../../components/device-usage-reassignment/DeviceUsageReassignment";

export enum NotificationPageTabKeys {
  UnallocatedData = "unallocated_data",
  SuitError = "suit_error",
}

const DEFAULT_ACTIVE_KEY = NotificationPageTabKeys.UnallocatedData;

type NotificationProps = {
  orgName?: string;
};

export const Notification: FunctionComponent<NotificationProps> = observer(({orgName}) => {
  const {isUserGlobal} = AuthenticationDataStore.getInstance();
  const location = useLocation();
  const navigate = useNavigate();

  const breadcrumbs: SubheaderBreadcrumbItem[] = [];

  const {orgId} = useParams();
  const [activeKey, setActiveKey] = useState(location.state?.activeKey ?? DEFAULT_ACTIVE_KEY);

  const handleBackButton = () => {
    navigate(location.pathname.substring(0, location.pathname.indexOf("/notifications")), {
      state: {activeKey: activeKey},
    });
  };

  const tabItems = [
    {
      label: WebHelper.formatMessage("Notifications-unallocatedData"),
      key: NotificationPageTabKeys.UnallocatedData,
      children: <DeviceUsageReassignment />,
    },
    // To be Implemented
    // {
    //   label: "Suit Error", //locale
    //   key: NotificationPageTabKeys.SuitError,
    //   children: (
    //     <SuitErrors />
    //   ),
    // },
  ];

  {
    isUserGlobal
      ? breadcrumbs.push({
          id: "allOrganizations",
          name: WebHelper.formatMessage("Organizations-allOrganizationsPath"),
          url: "/organizations",
        })
      : breadcrumbs.push({
          id: "organization",
          url: `/organizations/${orgId}`,
          name: orgName,
        });
  }
  breadcrumbs.push({
    id: "notifications",
    url: isUserGlobal ? `/organizations/${orgId}/notifications` : `/organizations/notifications`,
    name: WebHelper.formatMessage("Notifications-title"),
  });

  return (
    <>
      <Subheader breadcrumbItems={breadcrumbs} />
      <div className={styles.innerContentSpace}>
        <PageTitle
          title={WebHelper.formatMessage("Notifications-title")}
          icon={<AntdButton className={styles.backButton} onClick={handleBackButton} icon={<ArrowLeftOutlined />} />}
        />
        <Content className={styles.content}>
          <div className={styles.innerContentSpace}>
            <Tabs
              className={styles.tabContent}
              items={tabItems}
              activeKey={activeKey}
              destroyInactiveTabPane={true}
              onChange={(activeKey) => setActiveKey(activeKey as NotificationPageTabKeys)}
            />
          </div>
        </Content>
      </div>
    </>
  );
});
