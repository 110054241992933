import {ArrowLeftOutlined, QuestionOutlined} from "@ant-design/icons";
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {Button, Card, Col, Drawer, Row, Space, Tooltip, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {matchPath, useLocation, useNavigate, useParams} from "react-router-dom";

import styles from "./DeviceManagementDeviceDetail.module.scss";
import * as Models from "../../../../core/models";
import {DeviceModule} from "../../../../core/modules/DeviceModule";
import {SessionModule} from "../../../../core/modules/SessionModule";
import {WorkerModule} from "../../../../core/modules/WorkerModule";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {LinkButton} from "../../../components/common/link-button/LinkButton";
import {PageTitle} from "../../../components/common/page-title/PageTitle";
import {RowItem} from "../../../components/common/row-item/RowItem";
import {Subheader, SubheaderBreadcrumbItem} from "../../../components/common/subheader/Subheader";
import {ConnectedStatus} from "../../../components/diagnostics-device-detail/connected-status/ConnectedStatus";
import {Loading} from "../../../components/loading/Loading";
import {SessionsTable} from "../../../components/sessions-table/SessionsTable";
import {WorkerDetail} from "../../../components/worker-detail/WorkerDetail";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {isNil} from "../../../utils/FunctionUtils";
import {WebHelper} from "../../../utils/WebHelper";

export type DeviceManagementDeviceDetailProps = {};

export const DeviceManagementDeviceDetail: FunctionComponent<DeviceManagementDeviceDetailProps> = observer(() => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const navigate = useNavigate();

  const {deviceID} = useParams();
  const [device, setDevice] = useState<Models.Device>();
  const [sessions, setSessions] = useState<Models.SessionShort[]>([]);
  const [visibleWorkerId, setVisibleWorkerId] = useState<string | null>();
  const [visibleWorker, setVisibleWorker] = useState<Models.Worker | null>(null);

  const sessionsQuery = useQuery({
    queryKey: ["sessions", deviceID],
    queryFn: () => SessionModule.sessions({accessToken: authenticationStore.state.accessToken!, device_id: deviceID}),
  });

  const deviceQuery = useQuery({
    queryKey: ["DeviceManagementDeviceDetail-fetchDeviceData", deviceID],
    queryFn: () => DeviceModule.device({accessToken: authenticationStore.state.accessToken!, id: deviceID!}),
    placeholderData: keepPreviousData,
    refetchInterval: 5000,
  });

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.DiagnosticsDeviceDetailOpened, {deviceID});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sessionsQuery.data) return;

    if (!sessionsQuery.data?.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("FetchDeviceManagementDeviceDetailPageData-errorMessage"),
        sessionsQuery.data?.correlationId
      );
      return;
    }

    setSessions(sessionsQuery.data.sessions);
  }, [sessionsQuery.data]);

  useEffect(() => {
    if (!deviceQuery.data) return;

    if (!deviceQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("FetchDeviceManagementDeviceDetailPageData-errorMessage"),
        deviceQuery.data.correlationId
      );
      return;
    }
    setDevice(deviceQuery.data.device);
  }, [deviceQuery.data]);

  const workerQuery = useQuery({
    queryKey: ["DeviceManagementDeviceDetail-fetchWorkerData"],
    queryFn: () => {
      return WorkerModule.worker({
        accessToken: authenticationStore.state.accessToken!,
        id: visibleWorkerId!,
      });
    },
    enabled: !!visibleWorkerId,
  });

  useEffect(() => {
    if (!workerQuery.data || !visibleWorkerId) return;

    if (!workerQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("DeviceManagementDeviceDetail-workerErrorMessage"),
        workerQuery.data.correlationId
      );
      return;
    }

    setVisibleWorker(workerQuery.data.worker);
  }, [workerQuery, visibleWorkerId]);

  const location = useLocation();

  const generateBreadcrumbItems = useCallback((): SubheaderBreadcrumbItem[] => {
    const result: SubheaderBreadcrumbItem[] = [];
    result.push({
      id: "diagnostics",
      name: WebHelper.formatMessage("DeviceManagementDeviceDetail-diagnosticsPath"),
      url: WebHelper.getBreadcrumbItemUrl(location, "devices"),
    });

    const matchDeviceDetails = matchPath("/device_management/devices/:deviceID", location.pathname);

    if (!isNil(matchDeviceDetails)) {
      const {deviceID} = matchDeviceDetails.params;

      result.push({id: deviceID!, type: Models.EntityType.Device, url: WebHelper.getBreadcrumbItemUrl(location, deviceID!)});
    }

    return result;
  }, [location]);

  const [breadcrumbItems, setBreadcrumbItems] = useState<SubheaderBreadcrumbItem[]>(generateBreadcrumbItems());

  useEffect(() => {
    setBreadcrumbItems(generateBreadcrumbItems());
  }, [generateBreadcrumbItems]);

  const handleDrawerClose = () => {
    setVisibleWorkerId(null);
    setVisibleWorker(null);
  };

  const handleDeleteOk = () => {
    handleDrawerClose();
    deviceQuery.refetch();
  };

  const handleBackButton = () => {
    if (location.key === "default") {
      navigate("/device_management/devices");
    } else {
      navigate(-1);
    }
  };

  const colSpan = useMemo(() => {
    switch (appStore.state.mode) {
      case "mobileMd":
        return 12;
      case "mobileXs":
      case "mobileSm":
        return 24;
      case "desktop":
        return 6;
      default:
        return 6;
    }
  }, [appStore.state.mode]);

  const loading = deviceQuery.isPending || sessionsQuery.isPending;
  if (loading) return <Loading />;

  return device ? (
    <>
      <Subheader breadcrumbItems={breadcrumbItems} />
      <div className={styles.content}>
        <PageTitle
          title={WebHelper.formatMessage("DeviceManagementDeviceDetail-title", {systemSerialNumber: device.system_serial_number})}
          icon={[<Button className={styles.backButton} onClick={handleBackButton} icon={<ArrowLeftOutlined />} />]}
          marginBottom={16}
        />
        <Row gutter={[24, 24]}>
          <Col className={styles.col} span={colSpan}>
            <Card className={styles.firstCard}>
              <Space direction="vertical">
                <Typography.Title level={5}>{WebHelper.formatMessage("DeviceManagementDeviceDetail-deviceDetailsTitle")}</Typography.Title>
                <Space direction="vertical" size={16}>
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-systemSerialNumber")}
                    value={device.system_serial_number}
                  />
                  <RowItem label={WebHelper.formatMessage("DeviceManagementDeviceDetail-deviceTag")} value={device.device_tag} />
                  <RowItem label={WebHelper.formatMessage("DeviceManagementDeviceDetail-mcuId")} value={device.mcu_id} />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-hardwareRev")}
                    value={device.item_number_revision}
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-status")}
                    value={
                      device.assignable
                        ? WebHelper.formatMessage("DeviceManagementDeviceDetail-inService")
                        : WebHelper.formatMessage("DeviceManagementDeviceDetail-outOfService")
                    }
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-archivedAt")}
                    value={device.archived_at ? WebHelper.formatDate(device.archived_at, device?.site?.tz_location) : "-"}
                  />
                </Space>
              </Space>
            </Card>
          </Col>
          <Col className={styles.col} span={colSpan}>
            <Card className={styles.card}>
              <Space direction="vertical">
                <Typography.Title level={5}>
                  {WebHelper.formatMessage("DeviceManagementDeviceDetail-deviceStatisticsTitle")}
                </Typography.Title>
                <Space direction="vertical" size={16}>
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-connectionStatus")}
                    value={<ConnectedStatus status={device.status} />}
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-dateLastConnected")}
                    value={
                      device.most_recent_gateway_event_at
                        ? WebHelper.formatDate(device.most_recent_gateway_event_at, device?.site?.tz_location)
                        : ""
                    }
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-dateFirstUsed")}
                    value={
                      device.first_session_start_at ? WebHelper.formatDate(device.first_session_start_at, device?.site?.tz_location) : ""
                    }
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-dateLastUsed")}
                    value={
                      device.most_recent_session_start_at
                        ? WebHelper.formatDate(device.most_recent_session_start_at, device?.site?.tz_location)
                        : ""
                    }
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-totalHours")}
                    value={WebHelper.secondsToHours(device.total_time_used_seconds)}
                  />
                  <RowItem label={WebHelper.formatMessage("DeviceManagementDeviceDetail-totalLifts")} value={device.total_lifts} />
                </Space>
              </Space>
            </Card>
          </Col>
          <Col className={styles.col} span={colSpan}>
            <Card className={styles.card}>
              <Space direction="vertical">
                <Space direction="vertical">
                  <div className={styles.configurationTitleContainer}>
                    <Typography.Title level={5}>
                      {WebHelper.formatMessage("DeviceManagementDeviceDetail-configurationTitle")}
                    </Typography.Title>
                    {device.config?.pending && (
                      <Tooltip title={WebHelper.formatMessage("DeviceManagementDeviceDetail-pendingUpdateTooltip")}>
                        <QuestionOutlined />
                      </Tooltip>
                    )}
                  </div>
                </Space>
                <Space direction="vertical" size={16}>
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-updatePending")}
                    value={device.config?.pending ? WebHelper.formatMessage("Common-yes") : WebHelper.formatMessage("Common-no")}
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-firmwareRev")}
                    value={device.formatted_config_firmware_version ?? "-"}
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-button1")}
                    value={
                      device.config?.button_1_controller_setting
                        ? WebHelper.getControllerSettingsName(device.config.button_1_controller_setting.name)
                        : "-"
                    }
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-button2")}
                    value={
                      device.config?.button_2_controller_setting
                        ? WebHelper.getControllerSettingsName(device.config.button_2_controller_setting.name)
                        : "-"
                    }
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-button3")}
                    value={
                      device.config?.button_3_controller_setting
                        ? WebHelper.getControllerSettingsName(device.config.button_3_controller_setting.name)
                        : "-"
                    }
                  />
                </Space>
              </Space>
            </Card>
          </Col>
          <Col className={styles.col} span={colSpan}>
            <Card className={styles.lastCard}>
              <Space direction="vertical">
                <Typography.Title level={5}>{WebHelper.formatMessage("DeviceManagementDeviceDetail-customerInfoTitle")}</Typography.Title>
                <Space direction="vertical" size={16}>
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-organization")}
                    value={device.organization?.name ?? "-"}
                  />
                  <RowItem label={WebHelper.formatMessage("DeviceManagementDeviceDetail-site")} value={device.site?.name ?? "-"} />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-department")}
                    value={device.department?.name ?? "-"}
                  />
                  <RowItem
                    label={WebHelper.formatMessage("DeviceManagementDeviceDetail-assignedWorker")}
                    value={
                      device.assigned_worker ? (
                        <LinkButton onClick={() => setVisibleWorkerId(device.assigned_worker!.id)}>
                          {device.assigned_worker.displayName}
                        </LinkButton>
                      ) : (
                        "-"
                      )
                    }
                  />
                </Space>
              </Space>
            </Card>
          </Col>
        </Row>
        <div className={styles.subHeader}>
          <Typography.Text className={styles.sessionTitle}>
            {WebHelper.formatMessage("DeviceManagementDeviceDetail-sessions")}
          </Typography.Text>
        </div>
        <div className={styles.tableWrapper}>
          <SessionsTable sessions={sessions} device={device} loading={loading} onUpdate={sessionsQuery.refetch} />
        </div>
      </div>
      <Drawer
        open={!isNil(visibleWorker)}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("WorkerDetail-drawerTitle")}
        destroyOnClose
        onClose={handleDrawerClose}>
        {!isNil(visibleWorker) && (
          <WorkerDetail workerId={visibleWorker.id} onDelete={handleDeleteOk} onEdit={() => deviceQuery.refetch()} />
        )}
      </Drawer>
    </>
  ) : null;
});
