import {isValid} from "date-fns";
import {observable, makeObservable, computed, action} from "mobx";

import {BaseModel} from "./BaseModel";
import {UserPreferences} from "./UserPreferences";
import {isNil} from "../../web/utils/FunctionUtils";

export enum UserStatus {
  Active = "active",
  Deactivated = "deactivated",
  PendingApproval = "pending_approval",
}

export enum UserAccessLevel {
  None = "none",
  ReadOnly = "read_only",
  Admin = "admin",
  Manager = "manager",
  Custom = "custom",
}

export enum UserScope {
  Global = "global",
  Organization = "organization",
  Site = "site",
  Department = "department",
}

export type UserPermission = {
  level: UserAccessLevel;
  resource_id: string | null;
  scope: UserScope;
  user_id: string;
};

interface ConstructorProps {
  id: string;
  sub: string;
  active?: boolean;
  address?: string;
  cognito_status?: string;
  department?: string;
  email: string;
  family_name: string;
  given_name: string;
  job_title?: string;
  organization_id: string | null;
  organization_name: string;
  permissions: UserPermission[] | null;
  phone_number?: string;
  preferences?: UserPreferences;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export class User extends BaseModel {
  id: string;
  sub: string;
  active: boolean;
  address: string;
  cognito_status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  department: string;
  email: string;
  family_name: string;
  given_name: string;
  job_title: string;
  organization_id: string | null;
  organization_name: string;
  permissions: UserPermission[] | null;
  phone_number: string;
  subscribed: boolean;
  private preferences: string;

  constructor(props?: ConstructorProps) {
    super();

    if (props) {
      this.id = props.id;
      this.sub = props.sub;
      this.active = props.active ?? true;
      this.address = props.address ?? "";
      this.cognito_status = props.cognito_status ?? "CONFIRMED";
      this.department = props.department ?? "";
      this.email = props.email;
      this.family_name = props.family_name;
      this.given_name = props.given_name;
      this.job_title = props.job_title ?? "";
      this.organization_id = props.organization_id;
      this.organization_name = props.organization_name;
      this.permissions = props.permissions;
      this.phone_number = props.phone_number ?? "";
      this.preferences = props.preferences ? JSON.stringify(props.preferences) : "";
      this.created_at = props.created_at ?? new Date();
      this.updated_at = props.updated_at ?? new Date();
      this.deleted_at = props.deleted_at ?? null;
    }

    makeObservable(this, {
      id: observable,
      sub: observable,
      active: observable,
      address: observable,
      cognito_status: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
      department: observable,
      email: observable,
      family_name: observable,
      given_name: observable,
      job_title: observable,
      organization_id: observable,
      organization_name: observable,
      permissions: observable,
      phone_number: observable,
      status: computed,
      getPreferences: action,
      setPreferences: action,
    });
  }

  get status() {
    if (this.active) {
      if (this.permissions && this.permissions.length > 0) {
        return UserStatus.Active;
      } else {
        return UserStatus.PendingApproval;
      }
    } else {
      return UserStatus.Deactivated;
    }
  }

  get isUserGlobal(): boolean {
    return !isNil(this.permissions) && this.permissions.length > 0 && this.permissions[0].scope === UserScope.Global;
  }

  get accessLevel(): UserAccessLevel {
    if (!this.permissions) return UserAccessLevel.None;

    const accessLevel = this.permissions[0].level;

    for (let i = 1; i < this.permissions.length; i++) {
      if (this.permissions[i].level !== accessLevel) {
        return UserAccessLevel.Custom;
      }
    }

    return accessLevel;
  }

  getPreferences(): UserPreferences {
    const preferences: UserPreferences = this.preferences !== "" ? JSON.parse(this.preferences) : {};
    if (preferences.analyticsSettings) {
      preferences.analyticsSettings = {
        ...preferences.analyticsSettings,
        startDate:
          preferences.analyticsSettings.startDate && isValid(preferences.analyticsSettings.startDate)
            ? new Date(preferences.analyticsSettings.startDate)
            : null,
        endDate:
          preferences.analyticsSettings.endDate && isValid(preferences.analyticsSettings.endDate)
            ? new Date(preferences.analyticsSettings.endDate)
            : null,
      };
    }
    return preferences;
  }

  setPreferences(preferences: UserPreferences) {
    this.preferences = JSON.stringify(preferences);
  }

  static fixObjectFromJSON(object: User, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.permissions) {
      object.permissions = json.permissions.map((perm: any) => ({
        level: perm.level,
        resource_id: perm.resource_id,
        scope: perm.scope,
        user_id: perm.user_id,
      }));
    }
  }
}
