import {Input, Modal, Space} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import styles from "./ConfirmationModal.module.scss";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";

export enum ConfirmationModalSizes {
  Small = "400px",
  Default = "default",
}

type ConfirmationModalProps = {
  showConfirmationModal: boolean;
  loading: boolean;
  title: string;
  message: React.ReactNode;
  onOk: () => void;
  onCancel: () => void;
  referenceConfirmationValue: string;
  okButtonText: string;
  width?: ConfirmationModalSizes;
};

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = observer(
  ({
    showConfirmationModal,
    title,
    message,
    onOk,
    onCancel,
    loading,
    referenceConfirmationValue,
    okButtonText,
    width = ConfirmationModalSizes.Default,
  }) => {
    const [confirmationValue, setConfirmationValue] = useState("");

    const disableConfirmationButton = confirmationValue !== referenceConfirmationValue && !loading;

    const handleCancel = () => {
      if (loading) return;

      setConfirmationValue("");
      onCancel();
    };

    const handleOk = () => {
      onOk();
      setConfirmationValue("");
    };

    return (
      <Modal
        centered
        zIndex={1001}
        destroyOnClose={true}
        width={width === ConfirmationModalSizes.Default ? undefined : width}
        open={showConfirmationModal}
        title={title}
        onCancel={handleCancel}
        footer={
          <Space>
            <Button shape="round" disabled={loading} onClick={handleCancel}>
              {WebHelper.formatMessage("Common-cancel")}
            </Button>
            <Button
              danger
              id={`${referenceConfirmationValue}ConfirmButton-confirmationModal`}
              type={ButtonType.Primary}
              shape="round"
              loading={loading}
              disabled={disableConfirmationButton}
              onClick={handleOk}>
              {okButtonText}
            </Button>
          </Space>
        }>
        <Space className={styles.confirmationWrapper}>
          {message}
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(
                WebHelper.formatMessage("Common-confirmation", {
                  confirmationValue: referenceConfirmationValue,
                })
              ),
            }}
          />
          <Input
            id={`${referenceConfirmationValue}Input-confirmationModal`}
            disabled={loading}
            value={confirmationValue}
            className={styles.confirmationInput}
            onChange={(event) => setConfirmationValue(event.currentTarget.value)}
          />
        </Space>
      </Modal>
    );
  }
);
