import {DownloadOutlined, PlusOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Drawer, Dropdown, Space} from "antd";
import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from "react";
import {GatewayAlertBlock} from "web/components/gateway-alert-block/GatewayAlertBlock";

import {ChangeWorkerStatusModal} from "./change-worker-status-modal/ChangeWorkerStatusModal";
import {DepartmentWorkersTable} from "./department-workers-table/DepartmentWorkersTable";
import {DeviceNoWorker} from "./device-no-worker/DeviceNoWorker";
import {WorkerCreationCSVModal} from "./worker-creation-csv-modal/WorkerCreationCSVModal";
import {WorkerCreationManualModal} from "./worker-creation-manual-modal/WorkerCreationManualModal";
import {WorkersFilters} from "./workers-filters/WorkersFilters";
import styles from "./WorkersTabContent.module.scss";
import * as Models from "../../../../core/models";
import {WorkerModule} from "../../../../core/modules/WorkerModule";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../../../web/stores/AppStore";
import {isNil} from "../../../utils/FunctionUtils";
import {SegmentEntryPoint} from "../../../utils/SegmentHelper";
import {TableSettings} from "../../../utils/TableUtils";
import {WebHelper} from "../../../utils/WebHelper";
import {Button} from "../../common/button/Button";
import {AllWorkersReportDrawer} from "../../report-drawers/AllWorkersReportDrawer";
import {WorkerDetail} from "../../worker-detail/WorkerDetail";

type WorkersTabContentProps = {
  orgId?: string;
  siteId: string;
  department: Models.Department;
  siteName: string;
  tz_location?: string;
};

export const WorkersTabContent: FunctionComponent<WorkersTabContentProps> = ({orgId, siteId, department, siteName, tz_location}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [visibleWorker, setVisibleWorker] = useState<Models.Worker | Models.WorkerShort>();
  const [workers, setWorkers] = useState<Models.WorkerShort[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [showManualCreateModal, setShowManualCreateModal] = useState(false);
  const [unenrolledWorker, setUnenrolledWorker] = useState<Models.Worker | Models.WorkerShort>();
  const [showCSVCreateModal, setShowCSVCreateModal] = useState(false);
  const [workersFiltered, setWorkersFiltered] = useState<Models.WorkerShort[]>([]);
  const [workerTagCount, setWorkerTagCount] = useState<{
    assigned: number;
    waitlist: number;
    unenrolled: number;
    all: number;
  }>({assigned: 0, waitlist: 0, unenrolled: 0, all: 0});
  const [selectedTag, setSelectedTag] = useState<Models.WorkersTagsKey>(Models.WorkersTagsKey.Assigned);
  const [tableSettings, setTableSettings] = useState<TableSettings>();
  const [showChangeWorkerStatusModal, setShowChangeWorkerStatusModal] = useState(false);
  const [isReportDrawerVisible, setIsReportDrawerVisible] = useState(false);

  const createWorkersOptions = [
    {
      key: "manually",
      label: WebHelper.formatMessage("Common-createOptionsManually"),
      onClick: () => setShowManualCreateModal(true),
    },
    {key: "uploadCSV", label: WebHelper.formatMessage("Common-createOptionsUploadCSV"), onClick: () => setShowCSVCreateModal(true)},
  ];

  const {
    data: workersQueryData,
    isFetching: workersQueryIsFetching,
    refetch: workersQueryRefetch,
  } = useQuery({
    queryKey: ["DepartmentDetail-fetchWorkersData", department.id],
    queryFn: () => {
      return WorkerModule.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: department.id,
      });
    },
    enabled: !!department.id,
  });

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.WorkersTabOpened, {orgID: orgId, siteID: siteId, departmentID: department.id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!workersQueryData) return;

    if (workersQueryData.success) {
      setWorkers(workersQueryData.workers);
      const tagCounts = workersQueryData.workers
        ? {
            assigned: workersQueryData.workers.filter((worker) => worker.assigned_device).length,
            waitlist: workersQueryData.workers.filter((worker) => worker.onWaitlist).length,
            unenrolled: workersQueryData.workers.filter((worker) => !worker.assignable).length,
            all: workersQueryData.workers.length,
          }
        : {assigned: 0, waitlist: 0, unenrolled: 0, all: 0};
      setWorkerTagCount(tagCounts);
    } else WebHelper.showErrorMessage(WebHelper.formatMessage("WorkersTabContent-fetchWorkersDataError"), workersQueryData.correlationId);
  }, [workersQueryData]);

  useEffect(() => {
    if (!workers) return;
    switch (selectedTag) {
      case Models.WorkersTagsKey.All:
        setWorkersFiltered(
          workers.filter(
            (worker) =>
              worker.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
              worker.employee_id.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
        break;
      case Models.WorkersTagsKey.Assigned:
        setWorkersFiltered(
          workers.filter(
            (worker) =>
              worker.assigned_device &&
              (worker.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
                worker.employee_id.toLowerCase().includes(searchValue.toLowerCase()))
          )
        );
        break;
      case Models.WorkersTagsKey.Waitlist:
        setWorkersFiltered(
          workers.filter(
            (worker) =>
              worker.onWaitlist &&
              (worker.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
                worker.employee_id.toLowerCase().includes(searchValue.toLowerCase()))
          )
        );
        break;
      case Models.WorkersTagsKey.Unenrolled:
        setWorkersFiltered(
          workers.filter(
            (worker) =>
              !worker.assignable &&
              (worker.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
                worker.employee_id.toLowerCase().includes(searchValue.toLowerCase()))
          )
        );
        break;
    }
  }, [searchValue, selectedTag, workers]);

  const handleTagChange = (tag: Models.WorkersTagsKey) => {
    setSelectedTag(tag);
  };

  const requestDrawerCloseRef = useRef<() => boolean>();
  const handleDrawerClose = () => {
    setVisibleWorker(undefined);
    if (requestDrawerCloseRef.current) requestDrawerCloseRef.current();
  };

  const handleDeleteOk = () => {
    handleDrawerClose();
    workersQueryRefetch();
  };

  const handleChangeWorkerStatusModalOpen = (worker: Models.Worker | Models.WorkerShort) => {
    setVisibleWorker(worker);
    setShowChangeWorkerStatusModal(true);
  };

  const handleWorkerStatusChange = (worker: Models.Worker | Models.WorkerShort) => {
    workersQueryRefetch();
    setShowChangeWorkerStatusModal(false);
    setVisibleWorker(undefined);
    setUnenrolledWorker(worker);
  };

  const handleWorkerStatusChangeCancel = () => {
    setShowChangeWorkerStatusModal(false);
    setVisibleWorker(undefined);
  };

  const handleWorkerCreationCSV = () => {
    setShowCSVCreateModal(false);
    workersQueryRefetch();
  };

  const handleSettingsChange = (action: string, newSettings?: {quickFilter?: Models.WorkersTagsKey; tableSettings?: TableSettings}) => {
    if (action) {
      appStore.sendAnalyticTrack(SegmentKey.WorkersTableSettingsChanged, {
        siteID: siteId,
        departmentID: siteId,
        pagination: newSettings?.tableSettings ? newSettings?.tableSettings?.pagination : tableSettings?.pagination,
        sorter: newSettings?.tableSettings ? newSettings?.tableSettings?.sorter : tableSettings?.sorter,
        filters: newSettings?.tableSettings ? newSettings?.tableSettings?.filters : tableSettings?.filters,
        quickFilter: newSettings?.quickFilter ?? selectedTag,
        action: action,
      });
    }
  };

  const handleDownloadData = useCallback(() => {
    const headers = [
      [
        WebHelper.formatMessage("WorkersTabContent-nameColumnTitle"),
        WebHelper.formatMessage("WorkersTabContent-employeeIDColumnTitle"),
        WebHelper.formatMessage("WorkersTabContent-deviceColumnTitle"),
        WebHelper.formatMessage("WorkersTabContent-statusColumnTitle"),
      ],
    ];

    const dataRows = workersFiltered.map((worker) => [
      worker.fullName.replaceAll(",", "") ?? "_",
      worker.employee_id ?? "_",
      worker.device_tag ?? "_",
      worker.assignable
        ? WebHelper.formatMessage("WorkersTabContent-enrolledTagText")
        : WebHelper.formatMessage("WorkersTabContent-unenrolledTagText"),
    ]);

    const csvContent = headers
      .concat(dataRows)
      .map((row) => row.join(","))
      .join("\n");

    const tag =
      selectedTag === Models.WorkersTagsKey.All
        ? WebHelper.formatMessage("WorkersTabContent-allTagText")
        : selectedTag === Models.WorkersTagsKey.Assigned
          ? WebHelper.formatMessage("WorkersTabContent-assignedTagText")
          : selectedTag === Models.WorkersTagsKey.Unenrolled
            ? WebHelper.formatMessage("WorkersTabContent-unenrolledTagText")
            : WebHelper.formatMessage("WorkersTabContent-waitlistTagText");
    WebHelper.downloadFile(csvContent, WebHelper.formatMessage("WorkersTabContent-csv", {tag}), "text/csv;charset=utf-8;");
  }, [workersFiltered, selectedTag]);

  return (
    <>
      <GatewayAlertBlock />
      <Space className={styles.buttonsContainer}>
        {(authenticationStore.permissionLevel(Models.UserScope.Department, department.id) === Models.UserAccessLevel.Admin ||
          authenticationStore.permissionLevel(Models.UserScope.Department, department.id) === Models.UserAccessLevel.Manager) && (
          <Dropdown menu={{items: createWorkersOptions}} placement="bottom" trigger={["click"]}>
            <Button shape="round" icon={<PlusOutlined />}>
              {WebHelper.formatMessage("WorkersTabContent-createWorkersButtonText")}
            </Button>
          </Dropdown>
        )}
        <Button shape="round" icon={<DownloadOutlined />} onClick={() => setIsReportDrawerVisible(true)}>
          {WebHelper.formatMessage("WorkersTabContent-allWorkersReportButtonText")}
        </Button>
      </Space>
      <WorkersFilters
        selectedTag={selectedTag}
        setSelectedTag={handleTagChange}
        tagCounts={workerTagCount}
        setSearchValue={setSearchValue}
        entityType={Models.EntityType.Site}
        onFilterChange={handleSettingsChange}
        handleDownloadData={handleDownloadData}
        loading={workersQueryIsFetching}
      />
      <DepartmentWorkersTable
        workers={workersFiltered}
        selectWorker={setVisibleWorker}
        loading={workersQueryIsFetching}
        filterValue={searchValue}
        refetchWorkers={workersQueryRefetch}
        onOpenChangeWorkerStatusModal={handleChangeWorkerStatusModalOpen}
        tz_location={tz_location}
        departmentId={department.id}
        siteId={siteId}
        selectedTag={selectedTag}
        setTableSettings={setTableSettings}
        onTableChange={handleSettingsChange}
      />
      <Drawer
        open={!isNil(visibleWorker) && !showChangeWorkerStatusModal}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("WorkerDetail-drawerTitle")}
        destroyOnClose
        onClose={handleDrawerClose}>
        {!isNil(visibleWorker) && <WorkerDetail workerId={visibleWorker.id} onDelete={handleDeleteOk} onEdit={workersQueryRefetch} />}
      </Drawer>
      {visibleWorker && (
        <ChangeWorkerStatusModal
          worker={visibleWorker}
          open={showChangeWorkerStatusModal}
          entryPoint={SegmentEntryPoint.WORKER_TABLE}
          onOk={() => handleWorkerStatusChange(visibleWorker)}
          onCancel={handleWorkerStatusChangeCancel}
        />
      )}
      <WorkerCreationManualModal
        open={showManualCreateModal}
        onCancel={() => setShowManualCreateModal(false)}
        siteId={siteId}
        department={department}
        siteName={siteName}
        workers={workers}
        onCreate={workersQueryRefetch}
      />
      <WorkerCreationCSVModal
        open={showCSVCreateModal}
        onCancel={() => setShowCSVCreateModal(false)}
        siteId={siteId}
        departmentId={department.id}
        siteName={siteName}
        departmentName={department.name}
        workers={workers}
        onCreate={handleWorkerCreationCSV}
      />
      <AllWorkersReportDrawer
        departmentID={department.id}
        siteID={siteId}
        open={isReportDrawerVisible}
        onClose={() => setIsReportDrawerVisible(false)}
        tz_location={tz_location}
      />
      {unenrolledWorker?.assigned_device && unenrolledWorker.department && (
        <DeviceNoWorker
          deviceId={unenrolledWorker.assigned_device.id}
          deviceTag={unenrolledWorker.assigned_device.device_tag}
          departmentId={unenrolledWorker.department.id}
          onOk={() => {
            setUnenrolledWorker(undefined);
            workersQueryRefetch();
          }}
          onClose={() => {
            setUnenrolledWorker(undefined);
            setVisibleWorker(undefined);
            workersQueryRefetch();
          }}
          open={!isNil(unenrolledWorker)}
          entryPoint={SegmentEntryPoint.WORKER_TAB}
        />
      )}
    </>
  );
};
export default WorkersTabContent;
