import {isToday} from "date-fns";
import {makeObservable, observable} from "mobx";

import {AnalyticsTimeDevices} from "./AnalyticsTimeDevicesData";
import {BaseModel} from "./BaseModel";
import {DeviceShort} from "./DeviceShort";

export class AnalyticsDevices extends BaseModel {
  device_short: DeviceShort;
  time_devices_data: AnalyticsTimeDevices[];

  constructor() {
    super();
    makeObservable(this, {
      device_short: observable,
      time_devices_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsDevices, json: any) {
    if (json.device) object.device_short = DeviceShort.fromJSON(json.device);
    if (json.time_devices_data)
      object.time_devices_data = json.time_devices_data.map((timeSeries: AnalyticsTimeDevices) => {
        return AnalyticsTimeDevices.fromJSON(timeSeries);
      });
  }

  get data_uploaded_today(): boolean {
    const timeSeries = this.time_devices_data.find((timeSeries) => isToday(timeSeries.start_time));

    if (!timeSeries) return false;

    return timeSeries.usage_hours > 0;
  }
}

export class AnalyticsDevicesData extends BaseModel {
  devices_data: AnalyticsDevices[];
  paginated: boolean;
  limit?: number;
  offset?: number;
  total_device_count: number;

  constructor() {
    super();
    makeObservable(this, {
      devices_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsDevicesData, json: any) {
    if (json.department_devices_data) {
      object.devices_data = json.devices_data.map((device: AnalyticsDevices) => AnalyticsDevices.fromJSON(device));
    }
  }
}
