export enum CustomTranslationKeys {
  BackToSignIn = "BacktoSignIn",
  Email = "Email",
  EmailAlreadyExistsError = "EmailAlreadyExistsError",
  EmailDescriptiveText = "EmailDescriptiveText",
  FirstName = "FirstName",
  LastName = "LastName",
  Organization = "Organization",
  OrganizationNotFoundError = "OrganizationNotFoundError",
  Password = "Password",
  PasswordValidationLength = "PasswordValidationLength",
  PasswordValidationLowercase = "PasswordValidationLowercase",
  PasswordValidationNumber = "PasswordValidationNumber",
  PasswordValidationSpecialChar = "PasswordValidationSpecialChar",
  PasswordValidationUppercase = "PasswordValidationUppercase",
  SignInHeaderText = "SignInHeaderText",
  SignUpHeaderText = "SignUpHeaderText",
}

export const customTranslations = {
  en: {
    [CustomTranslationKeys.BackToSignIn]: "Back to Sign In",
    [CustomTranslationKeys.Email]: "Email",
    [CustomTranslationKeys.EmailAlreadyExistsError]: "An account with the given email already exists",
    [CustomTranslationKeys.EmailDescriptiveText]: "You will use your email address to sign in to the Verve Logic",
    [CustomTranslationKeys.FirstName]: "First Name",
    [CustomTranslationKeys.LastName]: "Last Name",
    [CustomTranslationKeys.Organization]: "Organization",
    [CustomTranslationKeys.OrganizationNotFoundError]: "The given Organization was not found",
    [CustomTranslationKeys.Password]: "Password",
    [CustomTranslationKeys.PasswordValidationLength]: "Password must contain at least 8 characters",
    [CustomTranslationKeys.PasswordValidationLowercase]: "Password must contain a lower case letter",
    [CustomTranslationKeys.PasswordValidationNumber]: "Password must contain a number",
    [CustomTranslationKeys.PasswordValidationSpecialChar]: "Password must contain a special character",
    [CustomTranslationKeys.PasswordValidationUppercase]: "Password must contain an upper case letter",
    [CustomTranslationKeys.SignInHeaderText]: "Sign In with your Email and Password",
    [CustomTranslationKeys.SignUpHeaderText]: "Sign Up for a New Account",
  },
  es: {
    [CustomTranslationKeys.BackToSignIn]: "Volver a inicio de sesión",
    [CustomTranslationKeys.Email]: "Email",
    [CustomTranslationKeys.EmailAlreadyExistsError]: "Una cuenta con el email ingresado ya existe",
    [CustomTranslationKeys.EmailDescriptiveText]: "Usará su email para iniciar sesión en Verve Logic",
    [CustomTranslationKeys.FirstName]: "Nombre",
    [CustomTranslationKeys.LastName]: "Apellido",
    [CustomTranslationKeys.Organization]: "Organización",
    [CustomTranslationKeys.OrganizationNotFoundError]: "La Organización ingresada no fue encontrada",
    [CustomTranslationKeys.Password]: "Contraseña",
    [CustomTranslationKeys.PasswordValidationLength]: "La contraseña debe contener al menos 8 letras",
    [CustomTranslationKeys.PasswordValidationLowercase]: "La contraseña debe contener una letra minúscula",
    [CustomTranslationKeys.PasswordValidationNumber]: "La contraseña debe contener un número",
    [CustomTranslationKeys.PasswordValidationSpecialChar]: "La contraseña debe contener un caracter especial",
    [CustomTranslationKeys.PasswordValidationUppercase]: "La contraseña debe contener una letra mayúscula",
    [CustomTranslationKeys.SignInHeaderText]: "Iniciar sesión con Email y Contraseña",
    [CustomTranslationKeys.SignUpHeaderText]: "Registrar una Cuenta Nueva",
    // Amplify's own translations are missing for the below keys
    "Cannot reset password for the user as there is no registered/verified email or phone_number":
      "No se puede resetear la contraseña para el usuario ya que no hay email o teléfono registrado/verificado",
    "It may take a minute to arrive.": "Puede demorar un momento en llegar.",
    "User does not exist.": "El usuario ingresado no existe.",
    "Username/client id combination not found.": "Combinación de nombre de usuario/id de cliente no encontrada",
    "We Emailed You": "Te enviamos un Email",
    "We Sent A Code": "Te enviamos un código",
    "Your code is on the way. To log in, enter the code we emailed to":
      "Tu código está en camino. Para iniciar sesión, ingresa el código que enviamos a",
    "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
      "Tu código está en camino. Para iniciar sesión, ingresa el código que te enviamos. Puede demorar un momento en llegar.",
    "Your code is on the way. To log in, enter the code we texted to":
      "Tu código está en camino. Para iniciar sesión, ingresa el código que enviamos a",
  },
  fr: {
    [CustomTranslationKeys.BackToSignIn]: "Retour à la connexion",
    [CustomTranslationKeys.Email]: "Email",
    [CustomTranslationKeys.EmailAlreadyExistsError]: "Un compte avec l'email saisi existe déjà",
    [CustomTranslationKeys.EmailDescriptiveText]: "Vous utiliserez votre email pour vous connecter à Verve Logic",
    [CustomTranslationKeys.FirstName]: "Prénom",
    [CustomTranslationKeys.LastName]: "Nom",
    [CustomTranslationKeys.Organization]: "Organisation",
    [CustomTranslationKeys.OrganizationNotFoundError]: "L'organisation saisie n'a pas été trouvée",
    [CustomTranslationKeys.Password]: "Mot de passe",
    [CustomTranslationKeys.PasswordValidationLength]: "Le mot de passe doit contenir au moins 8 caractères",
    [CustomTranslationKeys.PasswordValidationLowercase]: "Le mot de passe doit contenir une lettre minuscule",
    [CustomTranslationKeys.PasswordValidationNumber]: "Le mot de passe doit contenir un chiffre",
    [CustomTranslationKeys.PasswordValidationSpecialChar]: "Le mot de passe doit contenir un caractère spécial",
    [CustomTranslationKeys.PasswordValidationUppercase]: "Le mot de passe doit contenir une lettre majuscule",
    [CustomTranslationKeys.SignInHeaderText]: "Se connecter avec Email et Mot de passe",
    [CustomTranslationKeys.SignUpHeaderText]: "Créer un nouveau compte",
    "Cannot reset password for the user as there is no registered/verified email or phone_number":
      "Impossible de réinitialiser le mot de passe pour l'utilisateur car il n'y a pas d'email ou de numéro de téléphone enregistré/vérifié",
    "It may take a minute to arrive.": "Cela peut prendre un moment pour arriver.",
    "User does not exist.": "L'utilisateur saisi n'existe pas.",
    "Username/client id combination not found.": "Combinaison nom d'utilisateur/id client non trouvée",
    "We Emailed You": "Nous vous avons envoyé un email",
    "We Sent A Code": "Nous vous avons envoyé un code",
    "Your code is on the way. To log in, enter the code we emailed to":
      "Votre code est en route. Pour vous connecter, entrez le code que nous avons envoyé à",
    "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
      "Votre code est en route. Pour vous connecter, entrez le code que nous vous avons envoyé. Cela peut prendre un moment pour arriver.",
    "Your code is on the way. To log in, enter the code we texted to":
      "Votre code est en route. Pour vous connecter, entrez le code que nous avons envoyé à",
    "Enter your Password": "Entrez votre mot de passe",
  },
};
