import {Card, Space, Table, Typography} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import styles from "./LeaderboardSiteSummaryTable.module.scss";
import * as Models from "../../../../../../core/models";
import useUserPreferences from "../../../../../hooks/useUserPreferences";
import {WebHelper, lbsToKgs} from "../../../../../utils/WebHelper";

export type LeaderboardSiteSummaryTableProps = {
  workersByScore: WorkerData[];
  workersByWeight: WorkerData[];
  scoreAverage: number;
  weightAverage: number;
  department: Models.DepartmentInSite;
};

export type WorkerData = {
  worker: Models.WorkerShort;
  time_series_data: Models.AnalyticsTimeSeries[];
  weightOffloaded: number;
  position: number;
  score: number | null;
};

export const LeaderboardSiteSummaryTable: FunctionComponent<LeaderboardSiteSummaryTableProps> = observer(
  ({department, workersByScore, workersByWeight, scoreAverage, weightAverage}) => {
    const [userPreferences] = useUserPreferences();
    const [workersByScoreToShow, setWorkersByScoreToShow] = useState<WorkerData[]>([]);

    // x <= 10 show all, if 10 < x < 29 show 5, if x > 30 show 10
    const workerQuantity = workersByScore.length <= 10 ? workersByScore.length : workersByScore.length >= 30 ? 10 : 5;

    useEffect(() => {
      if (workersByScore.length <= 5 || workersByScore.length > 10) {
        setWorkersByScoreToShow(workersByScore.slice(0, workerQuantity));
      } else {
        setWorkersByScoreToShow(workersByScore.slice(0, workersByScore.length));
      }
    }, [workersByScore, workerQuantity]);

    //adapt the row height to the quantity of workers to mantain the table size constant
    const rowHeight = 16 + ((10 - Math.max(workerQuantity, 5)) / 5) * 16;

    const nameColumn: ColumnType<WorkerData> = {
      key: "name",
      width: "60%",
      render: (_, record, index) => (
        <div className={styles.nameContainer}>
          <div className={styles.positionContainer}>
            <Typography.Title
              level={index > 0 ? 4 : 3}
              style={index !== 0 && workerQuantity < 10 ? {height: rowHeight, width: "45px", borderRadius: "15px", paddingTop: "3px"} : {}}
              className={`${styles.position}`}>{`${record.position}${WebHelper.getOrdinal(record.position)}`}</Typography.Title>
          </div>
          <div className={`${styles.nameWorker}`}> {record.worker.displayName} </div>
        </div>
      ),
    };

    const scoreColumns: ColumnsType<WorkerData> = [
      nameColumn,
      {
        key: "score",
        width: "10%",
        render: (record) => (
          <div className={`${styles.workerItem} ${styles.value}`} key={`weight-${record.worker.id}`}>
            <Typography.Text strong>{record.score.toLocaleString()}</Typography.Text>
          </div>
        ),
      },
    ];

    const weightColumns: ColumnsType<WorkerData> = [
      nameColumn,
      {
        key: "weightOffloaded",
        width: "10%",
        render: (record) => (
          <div className={`${styles.workerItem} ${styles.value}`} key={`weight-${record.worker.id}`}>
            <Typography.Text strong>
              {userPreferences.data.metricMeasurementUnits
                ? `${lbsToKgs(record.weightOffloaded).toLocaleString()} ${WebHelper.formatMessage("DashboardWorkersMetric-kilogramsUnit")}`
                : `${record.weightOffloaded.toLocaleString()} ${WebHelper.formatMessage("DashboardWorkersMetric-poundsUnit")}`}
            </Typography.Text>
          </div>
        ),
      },
    ];

    return (
      <div className={styles.wrapper}>
        <Card className={styles.card}>
          <Card className={styles.cardContent}>
            <div className={styles.cardHeader}>
              <Typography.Title level={5} className={styles.cardTitle}>
                {WebHelper.formatMessage("DashboardWorkersMetric-safeLiftScore")}
              </Typography.Title>
              <div className={styles.averageContainer}>
                <Space size={"small"} className={styles.averageText}>
                  <Typography.Text>{WebHelper.formatMessage("DashboardWorkersMetric-departmentAverage")}</Typography.Text>
                  <Typography.Text strong>{scoreAverage.toLocaleString()}</Typography.Text>
                </Space>
              </div>
              <Table
                dataSource={workersByScoreToShow}
                showHeader={false}
                pagination={false}
                className={styles.table}
                rowKey={(record) => record.worker.id}
                rowClassName={(_, index) => `${index === 0 ? styles.firstRow : ""} ${styles.cellsBorder} `}
                columns={scoreColumns}
              />
            </div>
          </Card>
        </Card>
        <Card className={styles.card}>
          <Card className={styles.cardContent}>
            <div className={styles.cardHeader}>
              <Typography.Title level={5} className={styles.cardTitle}>
                {WebHelper.formatMessage("DashboardWorkersMetric-weightOffloaded")}
              </Typography.Title>
              <div className={styles.averageContainer}>
                <Space size={"small"} className={styles.averageText}>
                  <Typography.Text>{WebHelper.formatMessage("DashboardWorkersMetric-departmentAverage")}</Typography.Text>
                  <Typography.Text strong>
                    {userPreferences.data.metricMeasurementUnits
                      ? `${lbsToKgs(weightAverage).toLocaleString()}   ${WebHelper.formatMessage("DashboardWorkersMetric-kilogramsUnit")}`
                      : `${weightAverage.toLocaleString()}   ${WebHelper.formatMessage("DashboardWorkersMetric-poundsUnit")}`}
                  </Typography.Text>
                </Space>
              </div>
            </div>
            <Table
              dataSource={workersByWeight.slice(0, workerQuantity)}
              showHeader={false}
              pagination={false}
              className={styles.table}
              rowKey={(record) => record.worker.id}
              rowClassName={(_, index) => `${index === 0 ? styles.firstRow : ""} ${styles.cellsBorder}`}
              columns={weightColumns}
            />
          </Card>
        </Card>
      </div>
    );
  }
);
