import {FileTextOutlined, EditOutlined} from "@ant-design/icons";
import {Card, Checkbox, Select, Space, Switch, Typography} from "antd";
import {addDays, differenceInDays, endOfDay, format, isAfter} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";

import {SiteSummaryReportEditConfigurationModal} from "./site-summary-report-edit-configuration-modal/SiteSummaryReportEditConfigurationModal";
import styles from "./SiteSummaryReportDrawerContent.module.scss";
import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../common/button/Button";
import {DatePicker} from "../../../date-picker/DatePicker";

type SiteSummaryReportDrawerContentProps = {
  orgId: string;
  siteId: string;
  onChange: () => void;
  workerReportGroups: Models.WorkerReportGroup[];
  siteConfig: Models.SiteConfiguration | null;
  personalSiteConfig: {
    active_list_report_included: boolean;
    leaderboard_report_included: boolean;
    weight_offloaded_report_included: boolean;
    safelift_score_report_included: boolean;
    groups: {
      id: string;
      name: string;
    }[];
  } | null;
  handleChangePersonalSiteConfig: (
    active_list_report_included: boolean,
    leaderboard_report_included: boolean,
    weight_offloaded_report_included: boolean,
    safelift_score_report_included: boolean,
    groups: {id: string; name: string}[]
  ) => void;
  setSelectedWorkerReportGroups: (groups: Models.WorkerReportGroup[]) => void;
  loadingDownload: boolean;
  loadingPreview: boolean;
  onPreview: (endDateFormatted: string) => void;
  tz_location?: string;
};

export const SiteSummaryReportDrawerContent: FunctionComponent<SiteSummaryReportDrawerContentProps> = ({
  orgId,
  siteId,
  onChange,
  workerReportGroups,
  siteConfig,
  personalSiteConfig,
  handleChangePersonalSiteConfig,
  setSelectedWorkerReportGroups,
  loadingDownload,
  loadingPreview,
  onPreview,
  tz_location,
}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [endDate, setEndDate] = useState<Date>(addDays(endOfDay(new Date()), -1));
  const [includeWorkerReportGroups, setIncludeWorkerReportGroups] = useState<boolean>(siteConfig && siteConfig.groups ? true : false);
  const [leaderboard, setLeaderboard] = useState<boolean>(siteConfig ? siteConfig.leaderboard_report_included : false);
  const [activeInactiveWorkersList, setActiveInactiveWorkersList] = useState<boolean>(
    siteConfig ? siteConfig.active_list_report_included : false
  );
  const [weigthOffloaded, setWeigthOffloaded] = useState<boolean>(siteConfig ? siteConfig.weight_offloaded_report_included : false);
  const [safeliftScore, setSafeliftScore] = useState<boolean>(siteConfig ? siteConfig.safelift_score_report_included : false);
  const [showEditConfigurationModal, setShowEditConfigurationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const options = workerReportGroups?.map((group) => {
    return {value: group.id, label: group.name};
  });

  const {endDateFormatted} = useMemo<{endDateFormatted: string}>(() => {
    const formatDate = (date: Date) =>
      tz_location
        ? fromZonedTime(date, "UTC").toISOString()
        : format(date, WebHelper.formatMessage("Common-dateTimeFormatWithoutTimezone"));

    const endDateFormatted = formatDate(endDate);

    return {endDateFormatted};
  }, [endDate, tz_location]);

  const handleSwitchChange = (value: boolean) => {
    setIncludeWorkerReportGroups(value);
    if (!value) setSelectedWorkerReportGroups([]);
    handleChange("groups", []);
  };

  const handleSelectChange = (value: string[]) => {
    setSelectedWorkerReportGroups(workerReportGroups.filter((group) => value.includes(group.id)));
    handleChange("groups", value);
  };

  const handleChange = (field: string, value: boolean | string | string[]) => {
    if (personalSiteConfig) {
      const updatedConfig = {
        ...personalSiteConfig,
        [field]: value,
      };
      handleChangePersonalSiteConfig(
        updatedConfig.active_list_report_included,
        updatedConfig.leaderboard_report_included,
        updatedConfig.weight_offloaded_report_included,
        updatedConfig.safelift_score_report_included,
        updatedConfig.groups
      );
    }
  };

  useEffect(() => {
    if (siteConfig) {
      setIncludeWorkerReportGroups(siteConfig.groups ? true : false);
      setLeaderboard(siteConfig.leaderboard_report_included);
      setSafeliftScore(siteConfig.safelift_score_report_included);
      siteConfig.groups
        ? setSelectedWorkerReportGroups(workerReportGroups.filter((group) => siteConfig.groups.includes({id: group.id, name: group.name})))
        : setSelectedWorkerReportGroups([]);
      setActiveInactiveWorkersList(siteConfig.active_list_report_included);
      setWeigthOffloaded(siteConfig.weight_offloaded_report_included);
    }
    setLoading(false);
  }, [siteConfig, setSelectedWorkerReportGroups, workerReportGroups]);

  const handleOnChange = async () => {
    setLoading(true);
    await onChange();
  };

  return (
    <>
      <Card>
        <Space className={styles.cardContent} direction="vertical" size="large">
          <Typography.Text>
            <div
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(WebHelper.formatMessage("SiteSummaryReportDrawer-contentMessage")),
              }}
            />
          </Typography.Text>
          <Space size="large">
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-reportEndDate")}</Typography.Text>
            <DatePicker
              style={{width: "100%"}}
              allowClear={false}
              disabled={loadingDownload || loadingPreview}
              value={endDate}
              onChange={(date) => date && setEndDate(endOfDay(date))}
              disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
              cellRender={(current) => {
                if (typeof current === "string" || typeof current === "number") {
                  current = new Date(current);
                }
                const isLastSevenDays = differenceInDays(endDate, current) > 0 && differenceInDays(endDate, current) <= 6;

                return <div className={`ant-picker-cell-inner ${isLastSevenDays && styles.selectedDay}`}>{current.getDate()}</div>;
              }}
            />
          </Space>
          {authenticationStore.isUserAdmin && (
            <Space size="large" className={styles.editButton}>
              <Button
                style={{borderColor: "#E2E8F0", width: "100%"}}
                type={ButtonType.Default}
                shape="round"
                icon={<EditOutlined />}
                onClick={() => setShowEditConfigurationModal(true)}>
                {WebHelper.formatMessage("SiteSummaryReportDrawer-editConfigurationButton")}
              </Button>
            </Space>
          )}
          <Space className={styles.grayWrapper} size="middle" direction="vertical">
            <Space className={styles.switchWrapper} align="center" size="middle" direction="horizontal">
              <Typography.Text className={styles.switchLabel}>
                {WebHelper.formatMessage("SiteSummaryReportDrawer-includeWorkerReportGroups")}
              </Typography.Text>
              <Switch checked={includeWorkerReportGroups} onChange={(value) => handleSwitchChange(value)} disabled={loadingPreview} />
            </Space>
            {includeWorkerReportGroups && (
              <Space className={styles.selectWrapper} align="center" size="middle" direction="horizontal">
                <Typography.Text className={styles.selectLabel}>
                  {WebHelper.formatMessage("SiteSummaryReportDrawer-select")}
                </Typography.Text>
                <Select
                  options={options}
                  onChange={(value) => handleSelectChange(value)}
                  defaultValue={siteConfig && siteConfig.groups ? siteConfig.groups.map((group) => group.id) : undefined}
                  className={styles.groupName}
                  placeholder="Worker groups"
                  mode="multiple"
                  disabled={loadingPreview}
                  allowClear
                />
              </Space>
            )}
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-featuresToIncludeMessage")}</Typography.Text>
            <Space className={styles.checkboxWrapper} align="center" size="middle" direction="horizontal">
              <Checkbox
                checked={leaderboard}
                onChange={(e) => {
                  setLeaderboard(e.target.checked);
                  handleChange("leaderboard_report_included", e.target.checked);
                }}
              />
              <Typography.Text className={styles.checkboxLabel}>
                {WebHelper.formatMessage("SiteSummaryReportDrawer-leaderboardCheckbox")}
              </Typography.Text>
            </Space>
            <Space className={styles.checkboxWrapper} align="center" size="middle" direction="horizontal">
              <Checkbox
                checked={activeInactiveWorkersList}
                onChange={(e) => {
                  setActiveInactiveWorkersList(e.target.checked);
                  handleChange("active_list_report_included", e.target.checked);
                }}
              />
              <Typography.Text className={styles.checkboxLabel}>
                {WebHelper.formatMessage("SiteSummaryReportDrawer-activeInactiveWorkersCheckbox")}
              </Typography.Text>
            </Space>
            <Space className={styles.subCheckboxWrapper} align="center" size="middle" direction="horizontal">
              <Checkbox
                disabled={!activeInactiveWorkersList}
                checked={weigthOffloaded && activeInactiveWorkersList}
                onChange={(e) => {
                  setWeigthOffloaded(e.target.checked);
                  handleChange("weight_offloaded_report_included", e.target.checked);
                }}
              />
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-displayWorkersWeightOffloadedCheckbox")}</Typography.Text>
            </Space>
            <Space className={styles.subCheckboxWrapper} align="center" size="middle" direction="horizontal">
              <Checkbox
                disabled={!activeInactiveWorkersList}
                checked={safeliftScore && activeInactiveWorkersList}
                onChange={(e) => {
                  setSafeliftScore(e.target.checked);
                  handleChange("safelift_score_report_included", e.target.checked);
                }}
              />
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-safeliftScoreCheckbox")}</Typography.Text>
            </Space>
          </Space>
          <Space className={styles.buttonWrapper} align="center" size="middle">
            <Button
              shape="round"
              type={ButtonType.Primary}
              icon={<FileTextOutlined />}
              loading={loadingPreview}
              disabled={loadingDownload}
              onClick={() => onPreview(endDateFormatted)}>
              {WebHelper.formatMessage("ReportDrawerContent-generateReportButtonText")}
            </Button>
          </Space>
        </Space>
      </Card>
      <SiteSummaryReportEditConfigurationModal
        orgId={orgId}
        siteId={siteId}
        siteConfig={siteConfig}
        workerReportGroups={workerReportGroups}
        showEditConfigurationModal={showEditConfigurationModal}
        onOk={() => setShowEditConfigurationModal(false)}
        onCancel={() => setShowEditConfigurationModal(false)}
        onChange={handleOnChange}
        loading={loading}
      />
    </>
  );
};
