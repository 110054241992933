import {EditOutlined} from "@ant-design/icons";
import {Select, Typography} from "antd";
import React, {useState, useRef, useEffect} from "react";

import {EditCustomPermissionsModal} from "./edit-custom-permissions-modal/EditCustomPermissionsModal";
import styles from "./EditableAccessLevel.module.scss";
import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";

interface EditableAccessLevelProps {
  actualAccessLevel: Models.UserAccessLevel;
  user: Models.User;
  onSave: (user: Models.User, newLevel: Models.UserAccessLevel, previousLevel: Models.UserAccessLevel) => void;
  updateUser: (user: Models.User) => void;
  updateAccessLevel: (user: Models.UserAccessLevel) => void;
  onUpdateMultiple: () => void;
}

export const EditableAccessLevel: React.FC<EditableAccessLevelProps> = ({
  actualAccessLevel,
  onSave,
  user,
  updateUser,
  updateAccessLevel,
  onUpdateMultiple,
}) => {
  const [editing, setEditing] = useState(false);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const selectRef = useRef<any>(null);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  useEffect(() => {
    if (editing) {
      selectRef.current.focus();
    }
  }, [editing]);

  const handleSave = async (value: Models.UserAccessLevel) => {
    if (value !== Models.UserAccessLevel.Custom) {
      updateAccessLevel(value);
      toggleEdit();
      if (actualAccessLevel !== value) onSave(user, value, actualAccessLevel);
    } else {
      setIsCustomModalOpen(true);
    }
  };

  return (
    <>
      {editing ? (
        <Select
          className={styles.select}
          defaultValue={actualAccessLevel ?? Models.UserAccessLevel.None}
          onChange={handleSave}
          ref={selectRef}
          onBlur={toggleEdit}>
          {!user.permissions && (
            <Select.Option value={Models.UserAccessLevel.None}>{WebHelper.formatMessage("UserPermissions-none")}</Select.Option>
          )}
          <Select.Option value={Models.UserAccessLevel.ReadOnly}>{WebHelper.formatMessage("UserPermissions-readOnly")}</Select.Option>
          <Select.Option value={Models.UserAccessLevel.Admin}>{WebHelper.formatMessage("UserPermissions-admin")}</Select.Option>
          <Select.Option value={Models.UserAccessLevel.Manager}>{WebHelper.formatMessage("UserPermissions-manager")}</Select.Option>
          {/*Hide this because of VP-915*/}
          {/*{!user.isUserGlobal && (*/}
          {/*  <Select.Option value={Models.UserAccessLevel.Custom}>{WebHelper.formatMessage("UserPermissions-custom")}</Select.Option>*/}
          {/*)}*/}
        </Select>
      ) : (
        <div className={styles.accessLevel}>
          <Typography.Text
            onClick={() => {
              toggleEdit();
              updateUser(user);
            }}
            className={styles.accessLevelText}>
            {WebHelper.getAccessLevelText(actualAccessLevel)}
          </Typography.Text>
          {actualAccessLevel === Models.UserAccessLevel.Custom && (
            <EditOutlined
              onClick={() => {
                setIsCustomModalOpen(true);
              }}
            />
          )}
        </div>
      )}
      {isCustomModalOpen && (
        <EditCustomPermissionsModal
          open={isCustomModalOpen}
          onOk={() => {
            onUpdateMultiple();
            setIsCustomModalOpen(false);
          }}
          onCancel={() => setIsCustomModalOpen(false)}
          user={user}
        />
      )}
    </>
  );
};
