import {InfoCircleOutlined} from "@ant-design/icons";
import {Avatar, Card, Space, Tooltip, Typography} from "antd";
import React, {FunctionComponent, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";

import styles from "./EntityDetailsSiderCard.module.scss";
import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {GatewayInfo} from "../gateway-info/GatewayInfo";

type EntityDetailsSiderCardProps = {
  entity: Models.Organization | Models.Site | Models.Department;
  departmentInSite?: Models.Department;
  oneSite?: boolean;
  siteName?: string;
  oneSiteOneDepartment?: boolean;
  path?: string;
  active?: boolean;
  gateways?: Models.Gateway[];
  setIsGatewaysDetailDrawerVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EntityDetailsSiderCard: FunctionComponent<EntityDetailsSiderCardProps> = ({
  entity,
  departmentInSite,
  oneSite,
  siteName,
  oneSiteOneDepartment,
  path,
  active,
  gateways,
  setIsGatewaysDetailDrawerVisible,
}) => {
  const navigate = useNavigate();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const {siteId, departmentId} = useParams();

  const selectedResourceType = useMemo((): Models.EntityType => {
    return departmentId ? Models.EntityType.Department : siteId ? Models.EntityType.Site : Models.EntityType.Organization;
  }, [departmentId, siteId]);

  const activeEntityClass = useMemo(() => {
    switch (selectedResourceType) {
      case Models.EntityType.Department:
        return entity instanceof Models.Department && styles.activeEntity;
      case Models.EntityType.Site:
        return entity instanceof Models.Site && styles.activeEntity;
      case Models.EntityType.Organization:
        return entity instanceof Models.Organization && styles.activeEntity;
      default:
        return "";
    }
  }, [entity, selectedResourceType]);

  const description = useMemo(() => {
    if (entity instanceof Models.Organization) {
      return (
        <Typography.Text className={styles.entityData}>
          {entity.numberOfSites !== undefined && entity.numberOfDepartments !== undefined && entity.numberOfDevices !== undefined
            ? oneSite
              ? WebHelper.formatMessage("EntityDetailsSiderCard-DepartmentAndDeviceCount", {
                  departments: entity.numberOfDepartments,
                  devices: entity.numberOfDevices,
                })
              : WebHelper.formatMessage("EntityDetailsSiderCard-siteDepartmentAndDeviceCount", {
                  sites: entity.numberOfSites,
                  departments: entity.numberOfDepartments,
                  devices: entity.numberOfDevices,
                })
            : null}
        </Typography.Text>
      );
    } else if (entity instanceof Models.Site) {
      return (
        <Space direction={"vertical"}>
          <Typography.Text className={styles.entityData}>
            {entity.numberOfDepartments !== undefined && entity.numberOfDevices !== undefined
              ? entity.numberOfDepartments === 1 && departmentInSite
                ? WebHelper.formatMessage("EntityDetailsSiderCard-deviceCount", {
                    devices: entity.numberOfDevices,
                  })
                : WebHelper.formatMessage("EntityDetailsSiderCard-departmentAndDeviceCount", {
                    departments: entity.numberOfDepartments,
                    devices: entity.numberOfDevices,
                  })
              : null}
          </Typography.Text>
          {entity.numberOfDepartments !== 1 && !departmentInSite && (
            <Space size={0} direction={"vertical"}>
              <>
                <Typography.Text className={styles.descriptionText}>
                  {WebHelper.formatMessage("EntityDetailsSiderCard-descriptionAddress", {address: entity.address})}
                </Typography.Text>
                <Typography.Text className={styles.descriptionText}>
                  {WebHelper.formatMessage("EntityDetailsSiderCard-descriptionTimezone", {tz: entity.tz_location})}
                </Typography.Text>
              </>
            </Space>
          )}
        </Space>
      );
    } else if (entity instanceof Models.Department) {
      return (
        <Typography.Text className={styles.entityData}>
          {WebHelper.formatMessage("EntityDetailsSiderCard-deviceCount", {
            devices: entity.device_count - entity.archived_device_count,
          })}
        </Typography.Text>
      );
    } else return;
  }, [entity, departmentInSite, oneSite]);

  return (
    <Card
      className={`${styles.card} ${activeEntityClass}`}
      size={"small"}
      style={active ? {} : {cursor: "pointer"}}
      onClick={path ? () => navigate(path) : undefined}>
      <Space className={styles.content} direction={"vertical"}>
        <Space size={20} direction={"vertical"}>
          <Space align={"center"} direction={"horizontal"}>
            {entity instanceof Models.Organization && entity.icon_url && <Avatar size="large" shape="square" src={entity.icon_url} />}
            <div className={styles.titleWrapper}>
              {departmentInSite && (
                <Tooltip
                  title={WebHelper.formatMessage(
                    oneSiteOneDepartment && authenticationStore.isUserGlobal
                      ? "EntityDetailsSiderCard-siteDepartmentInformationOrg"
                      : "EntityDetailsSiderCard-siteDepartmentInformation"
                  )}>
                  <InfoCircleOutlined className={styles.infoIcon} />
                </Tooltip>
              )}
              <Typography.Paragraph className={styles.title}>
                {departmentInSite ? (
                  <>
                    {entity.name} - <span>{departmentInSite.name}</span>
                  </>
                ) : oneSite ? (
                  <>
                    {entity.name} - <span>{siteName}</span>
                  </>
                ) : (
                  entity.name
                )}
              </Typography.Paragraph>
            </div>
          </Space>
          {description}
        </Space>
        {(entity instanceof Models.Department || departmentInSite) && gateways && (
          <GatewayInfo gateways={gateways} setIsGatewaysDetailDrawerVisible={setIsGatewaysDetailDrawerVisible} />
        )}
      </Space>
    </Card>
  );
};
