import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {TargetUtilization} from "./Department";
import {WorkerPreferredLanguage} from "./Worker";

export enum OnTarget {
  Meets = "Meets",
  Unset = "Unset",
  Below = "Below",
  Overused = "Overused",
  Exempt = "Exempt",
  Unenrolled = "Unenrolled",
  Inactive = "Inactive",
}

export class WorkerShort extends BaseModel {
  id: string;
  department_id: string;
  first_name: string;
  last_name: string;
  employee_id: string;
  assignable: boolean;
  preferred_language: WorkerPreferredLanguage;
  target_hours_start_date: Date;
  department_name: string;
  department_target_hours: TargetUtilization;
  exempt_target: boolean;
  site_id: string;
  site_name: string;
  site_tz_location: string;
  organization_id: string;
  organization_name: string;
  device_id: string | null;
  device_tag: string;
  device_system_serial_number: string;
  device_most_recent_gateway_event_at: Date | null;
  total_time_used_seconds: number;
  total_lifts: number;
  first_session_start_at: Date | null;
  most_recent_session_start_at: Date | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  actual_hours: number;
  on_target: OnTarget;
  lower_bound: number | null;
  upper_bound: number | null;
  days_active_last_7_days: number;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      department_id: observable,
      first_name: observable,
      last_name: observable,
      employee_id: observable,
      assignable: observable,
      preferred_language: observable,
      department_name: observable,
      site_id: observable,
      site_name: observable,
      site_tz_location: observable,
      organization_id: observable,
      organization_name: observable,
      device_id: observable,
      device_tag: observable,
      device_system_serial_number: observable,
      device_most_recent_gateway_event_at: observable,
      total_time_used_seconds: observable,
      total_lifts: observable,
      first_session_start_at: observable,
      most_recent_session_start_at: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
      actual_hours: observable,
      on_target: observable,
      lower_bound: observable,
      upper_bound: observable,
    });
  }

  static fixObjectFromJSON(object: WorkerShort, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.first_session_start_at) object.first_session_start_at = new Date(json.first_session_start_at);
    if (json.most_recent_session_start_at) object.most_recent_session_start_at = new Date(json.most_recent_session_start_at);
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get displayName(): string {
    return this.first_name || this.last_name ? this.fullName : this.employee_id;
  }

  get onWaitlist(): boolean {
    return this.assignable && !this.device_id && !this.deleted_at;
  }

  get assigned_device(): {
    id: string;
    device_tag: string;
    system_serial_number: string;
    most_recent_gateway_event_at: Date | null;
  } | null {
    return this.device_id && this.device_tag
      ? {
          id: this.device_id,
          device_tag: this.device_tag,
          system_serial_number: this.device_system_serial_number,
          most_recent_gateway_event_at: this.device_most_recent_gateway_event_at,
        }
      : null;
  }

  get department(): {id: string; name: string} | null {
    return {id: this.department_id, name: this.department_name};
  }

  get site() {
    return {id: this.site_id, name: this.site_name, tz_location: this.site_tz_location};
  }
}
