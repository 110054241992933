import {useQuery} from "@tanstack/react-query";
import {Card, Divider, Result, Space, Typography} from "antd";
import {addDays, nextSunday, startOfDay} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import styles from "./DashboardCardWrapper.module.scss";
import * as Models from "../../../../../core/models";
import {AnalyticsModule} from "../../../../../core/modules/AnalyticsModule";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {AppStore} from "../../../../stores/AppStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {DashboardCardUsageChart} from "../../../dashboard-card/dashboard-card-usage-chart/DashboardCardUsageChart";
import {StatusChart} from "../../../dashboard-card/status-chart/StatusChart";
import {Loading} from "../../../loading/Loading";

type DashboardCardWrapperProps = {
  entityId: string;
  entityType: Models.EntityType;
  onListsDrawerOpen?: (
    key: string,
    drawerType: "devices" | "workers",
    entityType: Models.EntityType,
    id: string,
    timezone: string | undefined
  ) => void;
  name: string;
  showName?: boolean;
  timeZone: string | undefined;
  setTimeZone?: (timeZone: string) => void;
  childrenCard?: true;
  bordered?: false;
};

export const DashboardCardWrapper: FunctionComponent<DashboardCardWrapperProps> = observer(
  ({entityId, entityType, timeZone, setTimeZone, name, showName = false, onListsDrawerOpen, childrenCard = false, bordered = true}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();

    const [cardData, setCardData] = useState<Models.AnalyticsDashboardCard>();
    const [dashboardDataError, setDashboardDataError] = useState(false);

    const analyticsDashboardCardQuery = useQuery({
      queryKey: ["DashboardCardWrapper-fetchDashboardCardData", entityId],
      queryFn: () => {
        appStore.hideAllMessages();

        const endTimeSeries = fromZonedTime(startOfDay(nextSunday(new Date())), "UTC").toISOString();
        const endTimeStatus = fromZonedTime(startOfDay(addDays(new Date(), 1)), "UTC").toISOString();
        const startTimeStatus = fromZonedTime(startOfDay(addDays(new Date(), -7)), "UTC").toISOString();

        return AnalyticsModule.analyticsDashboardCard({
          accessToken: authenticationStore.state.accessToken!,
          resource_id: entityId,
          resource_type: entityType,
          status_interval_bounds: {
            offset_minutes: -new Date().getTimezoneOffset(),
            start_time: startTimeStatus,
            end_time: endTimeStatus,
          },
          time_series_config: {
            offset_minutes: -new Date().getTimezoneOffset(),
            end_time: endTimeSeries,
            interval_type: Models.AnalyticsInterval.Week,
            interval_count: 8,
          },
        });
      },
    });

    useEffect(() => {
      if (!analyticsDashboardCardQuery.data) return;

      if (!analyticsDashboardCardQuery.data.success) {
        setDashboardDataError(true);
        return;
      }
      setCardData(analyticsDashboardCardQuery.data.data);
      if (setTimeZone) setTimeZone(analyticsDashboardCardQuery.data.data.time_series_data[0].time_zone);
    }, [analyticsDashboardCardQuery.data, setTimeZone]);

    return (
      <Card className={styles.card} bordered={bordered}>
        {analyticsDashboardCardQuery.isFetching ? (
          <Loading />
        ) : (
          <>
            {showName && <Typography.Text className={styles.entityName}>{name}</Typography.Text>}

            {dashboardDataError ? (
              <Result
                className={styles.errorResult}
                icon={null}
                extra={
                  <Typography.Text type="secondary">{WebHelper.formatMessage("DashboardCardWrapper-errorResultMessage")}</Typography.Text>
                }
              />
            ) : (
              cardData && (
                <div className={styles.contentWrapper}>
                  <Space className={styles.statusChartContainer} direction="vertical">
                    <StatusChart
                      workerStatus={{
                        active_workers: cardData.worker_status.interval_active_count,
                        waitlist_workers: cardData.worker_status.current_waitlist_count,
                        inactive_workers: cardData.worker_status.interval_inactive_count,
                      }}
                      deviceStatus={
                        cardData.device_status
                          ? {
                              available_devices: cardData.device_status?.current_unassigned_count,
                              assigned_devices: cardData.device_status?.current_assigned_count,
                              out_of_service_devices: cardData.device_status?.current_out_of_service_count,
                            }
                          : undefined
                      }
                      entityType={entityType}
                      entityID={entityId}
                      timezone={timeZone}
                      onListsDrawerOpen={onListsDrawerOpen}
                      childrenCard={childrenCard}
                    />
                  </Space>
                  {appStore.state.mode !== "mobileXs" && (
                    <div className={styles.dividerContainer}>
                      <Divider className={styles.divider} type="vertical" />
                    </div>
                  )}
                  <Space className={styles.usageChartContainer} direction="vertical">
                    <DashboardCardUsageChart
                      utilizationHistory={cardData.time_series_data.map((d) => {
                        return {
                          interval_start_time: d.start_time,
                          interval_end_time: d.end_time,
                          total_usage_sec: d.usage_sec,
                        };
                      })}
                      enrolledWorkersCount={cardData.worker_status.current_enrolled_worker_count}
                      entityType={entityType}
                    />
                  </Space>
                </div>
              )
            )}
          </>
        )}
      </Card>
    );
  }
);
