import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {Notification} from "web/pages/notifications/Notification";

import {EntityDetails} from "./entity-details/EntityDetails";
import {Organizations} from "./Organizations";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {WorkerReportGroupDetail} from "../../components/entity-details/worker-report-groups-tab-content/worker-report-group-detail/WorkerReportGroupDetail";

export const OrganizationsRouting: FunctionComponent = observer(() => {
  const {
    isUserGlobal,
    state: {user},
  } = AuthenticationDataStore.getInstance();

  if (!user) return null;

  const flags = useFlags();

  return (
    <Routes>
      <Route index element={isUserGlobal ? <Organizations /> : <Navigate to={`/organizations/${user.organization_id}`} />} />
      <Route path=":orgId" element={<EntityDetails />} />
      <Route path=":orgId/sites/:siteId" element={<EntityDetails />} />
      <Route path=":orgId/sites/:siteId/groups/:groupId" element={<WorkerReportGroupDetail />} />
      <Route path=":orgId/sites/:siteId/departments/:departmentId" element={<EntityDetails />} />
      {flags.device_usage_reassignment_button && (
        <Route path=":orgId/notifications" element={<Notification orgName={user.organization_name} />} />
      )}
      {flags.device_usage_reassignment_button && <Route path="/notifications" element={<Notification />} />}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});
