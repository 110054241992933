import {makeObservable, observable} from "mobx";

import {BaseModel} from "./BaseModel";

export enum NotificationType {
  UnassignedSession = "unassigned_session",
}

export class Notification extends BaseModel {
  id: string;
  user_id: string;
  notification_type: NotificationType;
  event_hash: string;
  details: {origin?: string; device_id?: string};
  acknowledged: boolean;
  relevant: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      user_id: observable,
      notification_type: observable,
      event_hash: observable,
      details: observable,
      acknowledged: observable,
      relevant: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: Notification, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
  }
}

export class UserNotifications extends BaseModel {
  limit?: number;
  offset?: number;
  total_record_count?: number;
  records: Notification[];

  constructor() {
    super();
    makeObservable(this, {
      limit: observable,
      offset: observable,
      total_record_count: observable,
      records: observable,
    });
  }

  static fixObjectFromJSON(object: UserNotifications, json: any) {
    if (json.records) {
      object.records = json.records.map((notification: Notification) => Notification.fromJSON(notification));
    }
  }
}
