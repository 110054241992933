module.exports = {
  colors: {
    primaryColor: "#F04A1D",
    secondaryColor: "#E6E5E3",
    auxiliaryColor: "#C2FF7D",
    antdPrimaryBlack: "#323435", // $ol-secondaryColor-3
    antdSelectedItemBackground: "#dcdcdd", // $ol-secondaryColor-6
    singleColorChart: "#D8431AB2",
    singleColorChartLessOpacity: "#D8431A4C", // 30% opacity
    secondarySingleColorChart: "#50D10066",
    secondarySingleColorChartLessOpacity: "#50D10033", // 30% opacity
    analytics: {
      green: ["#B7DB57", "#C0DF6C", "#D2E797", "#DDEDB4", "#E0E7CF"],
      yellow: ["#FFCE54", "#F8D274", "#F6DB94", "#F0DDAE", "#EBE1CA"],
      greenDark: ["#38584B", "#548571", "#79AA96", "#A6C6B9", "#D2E4DD"],
      blue: ["#0076A8", "#00A6EC", "#32C2FF", "#75D3FB", "#B4E2F5"],
      primaryOrange: ["#D8431A", "#E86440", "#EE8B6F", "#F3B19F", "#F9D8CF"],
      secondaryOrange: ["#FD7F6F", "#F79981", "#F9B3A0", "#FBCCC0", "#F6DAD2"],
    },
    chart: [
      "#38584BB2",
      "#0076A8B2",
      "#B7DB57B2",
      "#38584B",
      "#FD7F6F",
      "#808080",
      "#0076A8",
      "#B7DB57",
      "#FFCE54",
      "#DCDCDD",
      "#FFCE54B2",
      "#F58061B2",
    ],
    progressBar: {
      red: "#C03B17",
      green: "#55A000",
      yellow: "#FFCE54",
    },
  },
};
