import {format} from "date-fns";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";
import {Bar} from "react-chartjs-2";

import styles from "./UsageChart.module.scss";
import * as Models from "../../../core/models";
import {WebHelper} from "../../utils/WebHelper";

const constants = require("assets/stylesheets/constants");

type UsageChartProps = {
  utilizationHistory: Omit<Models.AnalyticsDashboardHistory, "active_devices">[];
  height: number;
  entityType: Models.EntityType;
  enrolledWorkersCount: number;
};

export const UsageChart: FunctionComponent<UsageChartProps> = observer(({utilizationHistory, height, entityType, enrolledWorkersCount}) => {
  return (
    <Bar
      className={styles.barChart}
      height={height}
      data={{
        labels: utilizationHistory.map(
          ({interval_start_time, interval_end_time}) =>
            `${format(interval_start_time, WebHelper.formatMessage("Common-dateFormatMonthDayOnly"))} - ${format(
              interval_end_time,
              WebHelper.formatMessage("Common-dateFormatMonthDayOnly")
            )}`
        ),
        datasets: [
          {
            label:
              entityType === Models.EntityType.WorkerReportGroup
                ? WebHelper.formatMessage("UsageChart-averageUsageHoursLabel")
                : WebHelper.formatMessage("UsageChart-totalUsageHoursLabel"),
            data: utilizationHistory.map(
              ({total_usage_sec}) =>
                total_usage_sec / 3600 / (entityType === Models.EntityType.WorkerReportGroup ? enrolledWorkersCount : 1)
            ),
            backgroundColor: utilizationHistory.map((item, index) =>
              //last bar with less opacity
              index === utilizationHistory.length - 1 ? constants.colors.singleColorChartLessOpacity : constants.colors.singleColorChart
            ),
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              display: false,
              font: {size: 14},
            },
          },
          y: {
            ticks: {
              font: {size: 14},
            },
          },
        },
      }}
    />
  );
});
