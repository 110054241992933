import {LeftOutlined, RightOutlined, SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Button as Button, Cascader, Input, Space, Switch, Tooltip, Typography} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {DeviceManagementSelectOptions} from "core/apiclient/device/DeviceAPIClient.types";
import {addDays, endOfDay, format, isAfter, startOfDay} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import useUserPreferences from "web/hooks/useUserPreferences";
import {WebHelper} from "web/utils/WebHelper";

import styles from "./DeviceUsageReassignment.module.scss";
import * as Models from "../../../core/models";
import {AnalyticsModule} from "../../../core/modules/AnalyticsModule";
import {DepartmentModule} from "../../../core/modules/DepartmentModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {isNil} from "../../utils/FunctionUtils";
import {AllocateSelectedDataModal} from "../allocate-selected-data-modal/AllocateSelectedDataModal";
import {LinkButton} from "../common/link-button/LinkButton";
import {Table} from "../common/table/Table";
import {DatePicker} from "../date-picker/DatePicker";

const defaultSettings: Models.DeviceUsageReassignmentTableSettings = {
  pageSize: 10,
  sortColumn: "device",
  sortOrder: undefined,
};

type Option = {
  value: string | null;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
};

type DeviceUsageReassignmentProps = {};

export const DeviceUsageReassignment: FunctionComponent<DeviceUsageReassignmentProps> = observer(({}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const appStore = AppStore.getInstance();

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const {orgId} = useParams();
  const [options, setOptions] = useState<Option[]>([]);
  const [endDate, setEndDate] = useState<Date | null>(addDays(endOfDay(new Date()), -1));
  const [showAssigned, setShowAssigned] = useState(false);
  const [devices, setDevices] = useState<Models.AnalyticsDevices[]>();
  const [openAllocateModal, setOpenAllocateModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<Models.DeviceShort>();
  const [deviceFilterText, setDeviceFilterText] = useState<string | undefined>(undefined);
  const [deviceSearchText, setDeviceSearchText] = useState<string | undefined>(undefined);
  const [devicesFiltered, setDevicesFiltered] = useState<Models.AnalyticsDevices[]>();
  const [filter, setFilter] = useState<{key: string | null; value: string | null}>({key: null, value: null});
  const [entityValue, setEntityValue] = useState<(string | null)[]>([]);
  const [deviceSortOrder, setDeviceSortOrder] = useState<"ascend" | "descend" | undefined>(undefined);
  const [paginationConfig, setPaginationConfig] = useState({
    showSizeChanger: false,
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const resetFilters = () => {
    setEntityValue([]);
    setFilter({key: null, value: null});
    setDeviceFilterText(undefined);
    setDeviceSearchText(undefined);
  };

  const handleSettingsChange = (updatedSettings: Partial<Models.DeviceUsageReassignmentTableSettings>) =>
    setUserPreferences({tableSettings: {deviceUsageReassignmentTable: {...deviceUsageReassignmentTable, ...updatedSettings}}});

  const deviceUsageReassignmentTable: Models.DeviceUsageReassignmentTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.deviceUsageReassignmentTable ?? {})}),
    [userPreferences]
  );

  const {pageSize, sortColumn, sortOrder} = deviceUsageReassignmentTable;

  const dropdownDataQuery = useQuery({
    queryKey: ["DeviceUsageReassignment-fetchDropdownData"],
    queryFn: () => DepartmentModule.departmentsDropdown({accessToken: authenticationStore.state.accessToken!}),
  });

  useEffect(() => {
    if (!dropdownDataQuery.data) return;
    if (dropdownDataQuery.data.success) {
      setOptions([
        {value: DeviceManagementSelectOptions.Unallocated, label: WebHelper.formatMessage("DeviceUsageReassignment-unallocated")},
        ...dropdownDataQuery.data.organizations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((org) => ({
            value: org.id,
            label: org.name,
            isLeaf: !org.sites_info,
            children: org.sites_info
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((site) => ({
                value: site.id,
                label: site.name,
                isLeaf: !site.departments_info,
                children: site.departments_info
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((department) => ({
                    value: department.id,
                    label: department.name,
                    isLeaf: true,
                  })),
              })),
          })),
      ]);
    }
  }, [dropdownDataQuery.data]);

  const analyticsDevicesQuery = useQuery({
    queryKey: [
      "DeviceUsageReassignment-fetchDevicesData",
      authenticationStore.isUserGlobal ? (showAssigned ? [filter.value, deviceSortOrder, deviceFilterText, paginationConfig] : "") : orgId,
      endDate,
    ],
    queryFn: () => {
      const end_time = fromZonedTime(startOfDay(addDays(endDate!, 1)), "UTC").toISOString();
      return AnalyticsModule.analyticsDevicesData({
        accessToken: authenticationStore.state.accessToken!,
        end_time,
        interval_type: Models.AnalyticsInterval.Day,
        interval_count: 7,
        resource_id: authenticationStore.isUserGlobal ? (showAssigned && filter.value ? filter.value : undefined) : orgId,
        resource_type: authenticationStore.isUserGlobal
          ? showAssigned && filter.value
            ? filter.key === "organization_id"
              ? Models.EntityType.Organization
              : filter.key === "site_id"
                ? Models.EntityType.Site
                : Models.EntityType.Department
            : undefined
          : Models.EntityType.Organization,
        limit: authenticationStore.isUserGlobal ? paginationConfig.pageSize : undefined,
        offset: authenticationStore.isUserGlobal ? paginationConfig.pageSize * (paginationConfig.current - 1) : undefined,
        included_assigned_data: authenticationStore.isUserGlobal ? showAssigned : undefined,
        device_tag: authenticationStore.isUserGlobal && showAssigned ? deviceFilterText ?? undefined : undefined,
        device_tag_sort_order: authenticationStore.isUserGlobal && showAssigned ? deviceSortOrder ?? undefined : undefined,
      });
    },
    enabled: !!endDate,
  });

  useEffect(() => {
    if (!analyticsDevicesQuery.data) return;

    if (!analyticsDevicesQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("DeviceUsageReassignment-errorMessage"), analyticsDevicesQuery.data.correlationId);
      return;
    }

    showAssigned
      ? setDevices(
          analyticsDevicesQuery.data.data.devices_data?.filter((device) => {
            return device.time_devices_data.some((data) => data?.usage_hours > 0);
          })
        )
      : setDevices(
          analyticsDevicesQuery.data.data.devices_data?.filter((device) => {
            return device.time_devices_data.some((data) => data?.usage_hours > 0 && !data.allocated_data);
          })
        );
    setDevicesFiltered(undefined);
    setPaginationConfig((prev) => ({
      ...prev,
      total: analyticsDevicesQuery.data.data!.total_device_count,
    }));
  }, [analyticsDevicesQuery.data, showAssigned]);

  const handlePageChange = (page: number, pageSize: number) => {
    setPaginationConfig((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const days = useMemo(() => {
    const lastDay = endDate ?? fromZonedTime(new Date(), WebHelper.getLocalTimezoneFromBrowser());

    const endDateFormatted = format(lastDay, WebHelper.formatMessage("Common-dateFormatMonthDayYear"));

    const days = [endDateFormatted];

    for (let i = 1; i < 7; i++) {
      days.unshift(format(addDays(lastDay, -i), WebHelper.formatMessage("Common-dateFormatMonthDayYear")));
    }

    return days;
  }, [endDate]);

  const handleResetDeviceSearch = () => {
    setDeviceFilterText(undefined);
    setDeviceSearchText(undefined);
    setDevicesFiltered(undefined);
  };

  const handleCascaderChange = () => {
    let filterKey = null;
    const filterValue = entityValue ? entityValue[entityValue.length - 1] : null;
    if (entityValue) {
      switch (entityValue.length) {
        case 1:
          filterKey = "organization_id";
          break;
        case 2:
          filterKey = "site_id";
          break;
        case 3:
          filterKey = "department_id";
          break;
        default:
          break;
      }
    }
    setDeviceSortOrder(undefined);
    setFilter({key: filterKey, value: filterValue});
  };

  const handleDeviceSearch = useCallback(() => {
    setDeviceSortOrder(undefined);
    setDeviceFilterText(deviceSearchText);
    setDevicesFiltered(
      devices?.filter((device) => device.device_short.device_tag.toLowerCase().includes(deviceSearchText?.toLowerCase() || ""))
    );

    appStore.analytics.track(SegmentKey.DeviceUsageReassignmentSettingsChanged, {
      orgID: orgId,
      action: "filter",
      filter: deviceSearchText,
    });
  }, [deviceSearchText, devices, appStore.analytics, orgId]);

  const formatLocation = (record: Models.AnalyticsDevices) => {
    if (record.device_short.organization_name) {
      return `${record.device_short.organization_name} / ${record.device_short.site_name} / ${record.device_short.department_name}`;
    }
    return WebHelper.formatMessage("DeviceUsageReassignment-unallocated");
  };

  const deviceColumnSearchDropdown = (
    <div style={{padding: 8}}>
      <Input value={deviceSearchText} onChange={(e) => setDeviceSearchText(e.target.value)} className={styles.searchInput} />
      <Space>
        <Button type="primary" onClick={handleDeviceSearch} icon={<SearchOutlined />} size="small">
          {WebHelper.formatMessage("Common-search")}
        </Button>
        <Button onClick={handleResetDeviceSearch} size="small">
          {WebHelper.formatMessage("Common-delete")}
        </Button>
      </Space>
    </div>
  );

  const deviceColumn: ColumnType<Models.AnalyticsDevices> = {
    title: WebHelper.formatMessage("DeviceUsageReassignment-deviceColumn"),
    key: "device",
    sorter: !(authenticationStore.isUserGlobal && showAssigned)
      ? (a, b) => a.device_short.device_tag.localeCompare(b.device_short.device_tag)
      : true,
    sortOrder: sortColumn === "device" ? (authenticationStore.isUserGlobal && showAssigned ? deviceSortOrder : sortOrder) : undefined,
    render: (_, record) => (
      <LinkButton
        onClick={() => {
          setSelectedDevice(record.device_short);
          setOpenAllocateModal(true);
        }}>
        {record.device_short.device_tag}
      </LinkButton>
    ),
    filterIcon: <SearchOutlined className={!isNil(deviceFilterText) ? styles.searchActive : ""} />,
    filterDropdown: deviceColumnSearchDropdown,
    onHeaderCell: (column) => ({
      onLoad: () => {
        if (authenticationStore.isUserGlobal && showAssigned) {
          setDeviceSortOrder(sortOrder === "ascend" ? "ascend" : sortOrder === "descend" ? "descend" : undefined);
        }
      },
      onClick: () => {
        if (authenticationStore.isUserGlobal && showAssigned) {
          setDeviceSortOrder(sortOrder === "ascend" ? "ascend" : sortOrder === "descend" ? "descend" : undefined);
        }
      },
    }),
  };

  const locationColumn: ColumnType<Models.AnalyticsDevices> = {
    title: WebHelper.formatMessage("DeviceUsageReassignment-location"),
    key: "location",
    className: styles.locationColumn,
    sorter: !(authenticationStore.isUserGlobal && showAssigned)
      ? (a, b) => {
          const orgA = a.device_short.organization_name || WebHelper.formatMessage("DeviceUsageReassignment-unallocated");
          const orgB = b.device_short.organization_name || WebHelper.formatMessage("DeviceUsageReassignment-unallocated");
          if (orgA.localeCompare(orgB) !== 0) {
            return orgA.localeCompare(orgB);
          }
          const siteA = a.device_short.site_name || WebHelper.formatMessage("DeviceUsageReassignment-unallocated");
          const siteB = b.device_short.site_name || WebHelper.formatMessage("DeviceUsageReassignment-unallocated");
          if (siteA.localeCompare(siteB) !== 0) {
            return siteA.localeCompare(siteB);
          }
          const deptA = a.device_short.department_name || WebHelper.formatMessage("DeviceUsageReassignment-unallocated");
          const deptB = b.device_short.department_name || WebHelper.formatMessage("DeviceUsageReassignment-unallocated");
          return deptA.localeCompare(deptB);
        }
      : undefined,
    sortOrder: sortColumn === "location" ? sortOrder : undefined,
    render: (_, record) => (
      <Tooltip title={formatLocation(record)}>
        <Typography className={styles.locationData}>{formatLocation(record)}</Typography>
      </Tooltip>
    ),
    filterIcon: <SearchOutlined className={!isNil(filter.value) ? styles.searchActive : ""} />,
    filterDropdown: ({confirm}) => (
      <Cascader
        placeholder={
          dropdownDataQuery.isPending
            ? WebHelper.formatMessage("DeviceUsageReassignment-loadingOrganizations")
            : WebHelper.formatMessage("DeviceUsageReassignment-selectOrganization")
        }
        changeOnSelect
        loading={dropdownDataQuery.isPending}
        disabled={dropdownDataQuery.isPending}
        displayRender={(labels) =>
          dropdownDataQuery.isPending ? WebHelper.formatMessage("DeviceUsageReassignment-loadingOrganizations") : labels.join(" / ")
        }
        onBlur={() => {
          handleCascaderChange();
          confirm();
        }}
        onChange={(value) => {
          setEntityValue(value);
        }}
        className={styles.cascader}
        options={options}
        popupClassName={styles.cascaderPopup}
        showSearch
      />
    ),
    onFilter: (value, record) => {
      if (!filter.key) return true;
      const {key, value: filterValue} = filter;
      return record.device_short[key] === filterValue;
    },
  };

  const daysColumns: ColumnType<Models.AnalyticsDevices>[] = days.map((day, index) => {
    return {
      title: day,
      key: "day_${index}",
      sorter: !(authenticationStore.isUserGlobal && showAssigned)
        ? (a, b) => {
            return a.time_devices_data[index]?.usage_hours - b.time_devices_data[index]?.usage_hours;
          }
        : undefined,
      sortOrder: authenticationStore.isUserGlobal && showAssigned && sortColumn === day ? sortOrder : undefined,
      render: (_, record) => {
        const usageInHours = record.time_devices_data[index]?.usage_hours;
        return record.time_devices_data[index]?.usage_hours > 0 &&
          (showAssigned || (!showAssigned && !record.time_devices_data[index].allocated_data)) ? (
          <Tooltip title={WebHelper.getDurationFormattedBySeconds(usageInHours * 60 * 60)}>
            <Typography.Text
              className={`${styles.numberDisplay} ${record.time_devices_data[index].allocated_data ? styles.allocatedData : record.time_devices_data[index].unallocated_data ? "" : styles.partiallyAllocatedData}`}>
              {usageInHours}
            </Typography.Text>
          </Tooltip>
        ) : (
          "-"
        );
      },
    };
  });

  const calculateTotalUnallocatedHours = (timeDevicesData: Models.AnalyticsTimeDevices[]) => {
    return timeDevicesData.filter((data) => data.unallocated_data).reduce((total, data) => total + data.usage_hours, 0);
  };

  const totalUnallocatedColumn: ColumnType<Models.AnalyticsDevices> = {
    title: WebHelper.formatMessage("DeviceUsageReassignment-totalUnallocated"),
    key: "total_unallocated",
    sorter: !(authenticationStore.isUserGlobal && showAssigned)
      ? (a, b) => {
          const totalA = calculateTotalUnallocatedHours(a.time_devices_data);
          const totalB = calculateTotalUnallocatedHours(b.time_devices_data);
          return totalA - totalB;
        }
      : undefined,
    sortOrder: authenticationStore.isUserGlobal && showAssigned && sortColumn === "total_unallocated" ? sortOrder : undefined,
    render: (_, record) => {
      const totalUnallocated = calculateTotalUnallocatedHours(record.time_devices_data);
      return totalUnallocated > 0 ? (
        <Tooltip title={WebHelper.getDurationFormattedBySeconds(totalUnallocated * 60 * 60)}>
          <Typography.Text>{totalUnallocated.toFixed(1)}</Typography.Text>
        </Tooltip>
      ) : (
        "-"
      );
    },
  };

  const columns: ColumnsType<Models.AnalyticsDevices> = [
    deviceColumn,
    ...(authenticationStore.isUserGlobal ? [locationColumn] : []),
    ...daysColumns,
    totalUnallocatedColumn,
    {
      title: (
        <Space className={styles.arrowButtons}>
          <Button
            key="leftButton"
            onClick={() => (endDate ? handleChangeEndDate(addDays(endDate, -1)) : "")}
            icon={<LeftOutlined className={styles.arrows} />}
            className={styles.button}
          />
          <Button
            key="rightButton"
            onClick={() =>
              endDate && !isAfter(addDays(endDate, +1), endOfDay(new Date())) ? handleChangeEndDate(addDays(endDate, +1)) : ""
            }
            icon={<RightOutlined className={styles.arrows} />}
            className={styles.button}
            disabled={endDate ? isAfter(addDays(endDate, +1), new Date()) : false}
          />
        </Space>
      ),
    },
  ];

  const handleChangeEndDate = (newDate: Date) => {
    setEndDate(newDate);
    analyticsDevicesQuery.refetch();
  };

  const handleSwitchSeeAssigned = (value: boolean) => {
    setShowAssigned(value);
    resetFilters();
    analyticsDevicesQuery.refetch();
  };

  const loading = analyticsDevicesQuery.isPending || analyticsDevicesQuery.isFetching || analyticsDevicesQuery.isRefetching;

  return (
    <>
      <Space className={styles.spaceContainer}>
        <div className={styles.filterItem}>
          <Typography.Text className={styles.dateSelector}>{WebHelper.formatMessage("DeviceUsageReassignment-endDate")}</Typography.Text>
          <DatePicker
            allowClear={false}
            value={endDate}
            onChange={(date) => (date ? handleChangeEndDate(date) : null)}
            disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
          />
        </div>
        <div className={styles.filterItem}>
          <Typography.Text className={styles.assignedDataSelector}>
            {WebHelper.formatMessage("DeviceUsageReassignment-assignedDataSwitch")}
          </Typography.Text>
          <Switch defaultChecked={showAssigned} onChange={(checked) => handleSwitchSeeAssigned(checked)} />
        </div>
      </Space>
      <div className={styles.tableContainer}>
        <Table
          className={styles.table}
          columns={columns}
          loading={loading}
          dataSource={devicesFiltered ?? devices}
          onChange={(pagination, filters, sorter, extra) => {
            if (authenticationStore.isUserGlobal && showAssigned && pagination.current && pagination.pageSize)
              handlePageChange(pagination.current, pagination.pageSize);
            appStore.sendAnalyticTrack(SegmentKey.DeviceUsageReassignmentSettingsChanged, {
              orgID: orgId,
              pagination,
              filters,
              sorter,
              action: extra.action,
            });
            if (extra.action === "sort" && !Array.isArray(sorter)) {
              handleSettingsChange({
                sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
                sortOrder: sorter.order,
              });
            }
          }}
          pagination={
            authenticationStore.isUserGlobal && showAssigned
              ? paginationConfig
              : {
                  showSizeChanger: true,
                  pageSize: pageSize,
                  onShowSizeChange: (_, size) =>
                    handleSettingsChange({
                      pageSize: size,
                    }),
                }
          }
        />
      </div>
      {selectedDevice && (
        <AllocateSelectedDataModal
          open={openAllocateModal}
          onClose={() => setOpenAllocateModal(false)}
          onConfirm={() => analyticsDevicesQuery.refetch()}
          device={selectedDevice}
          departmentId={selectedDevice.department_id}
        />
      )}
    </>
  );
});
